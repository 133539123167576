import React from "react";
import AppLayout from "../../../components/common/Layout/AppLayout";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import { routes } from "../../../routes";
import StorePage from "../../../components/WebComponents/Store/StorePage";

function MobileStorePage() {
  return (
    <AppLayout>
      <MobileNavigationBack page={"Store"} route={routes.dashboard} />
      <StorePage />
    </AppLayout>
  );
}

export default MobileStorePage;
