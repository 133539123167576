import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import SuccessCheckIcon from '../../../../assets/images/success-check-icon.svg';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import BullionzLogoText from '../../../../assets/images/bullionz-logo-text.svg';
import '../../../../styles/global.fonts.css';
import { useAppContext } from '../../../../context/DataContext';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../routes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import { useState } from 'react';
import PleaseLoginPopUp from '../PleaseLoginPopup/PleaseLoginPopup';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const NotificationMessagePopup = ({
  type,
  message,
  isPopupOpen,
  setIsPopupOpen,
  showTitle = true,
  handleCloseAutoInvestPopup = () => {},
  closeAfterDepositBtnClick = () => {},
  hasOkButton,
  hasDepositFundsBtn = true,
  isCrypto = false,
}) => {
  const classes = useStyles({ type });
  const navigate = useNavigate();
  const { isMobile } = useAppContext();
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const isInsufficientFunds = message?.includes('insufficient funds');
  const { isAppView } = useAppContext();

  const isDemo = sessionStorage.getItem('isDemo') === 'true';

  const handleClose = () => {
    setIsPopupOpen(false);
    handleCloseAutoInvestPopup();
  };

  function messageJoiner(msg) {
    return msg.join();
  }

  function navigateToDeposit() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    setIsPopupOpen(false);
    closeAfterDepositBtnClick();

    if (isAppView) {
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "Deposit"}');
    } else {
      navigate(routes.deposit);
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent} style={{ height: type === 'error' && !isMobile && '309px' }}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.popupTitle}>
            {showTitle && (type === 'success' ? 'Successful' : 'Error')}
          </BootstrapDialogTitle>
          <DialogContent className={classes.contentContainer} style={{ overflow: hasOkButton && 'hidden' }}>
            <div className={classes.message}>
              <div className={classes.checkIcon}>
                <img
                  style={{ borderRadius: '24px', width: '25px' }}
                  src={type === 'success' ? SuccessCheckIcon : ErrorIcon}
                  alt={type === 'success' ? 'Success check icon' : 'Error icon'}
                />
              </div>
              <Typography className={classes.popupSubtitle} gutterBottom>
                {typeof message === 'string' ? (
                  message.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                ) : (
                  <React.Fragment>{Array.isArray(message) ? messageJoiner(message) : 'Error'}</React.Fragment>
                )}
              </Typography>
            </div>
            <div className={classes.bullionzLogoTextContainer}>
              <img className={classes.bullionzLogoText} src={BullionzLogoText} alt="Bullionz logo text" />
            </div>
              
            {isInsufficientFunds && hasDepositFundsBtn && !isCrypto && (
              <div className={classes.depositBtnWrapper}>
                <Button
                  className={classes.depositFundsBtn}
                  onClick={navigateToDeposit}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  DEPOSIT FUNDS
                </Button>
              </div>
            )}
          </DialogContent>
          {hasOkButton && (
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                sx={{
                  color: 'white !important',
                  backgroundColor: 'black',
                  '&:hover': {
                    backgroundColor: 'black',
                  },
                }}
                variant="contained"
                onClick={handleClose}
              >
                Ok
              </Button>
            </div>
          )}
        </div>
      </BootstrapDialog>
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
    </div>
  );
};

export default NotificationMessagePopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      maxWidth: '1000px',
      width: '100%',

      [theme.breakpoints.down('1800')]: {
        width: '600px',
      },
      [theme.breakpoints.down('1500')]: {
        width: '500px',
        height: '308px',
      },
      [theme.breakpoints.down('1300')]: {
        width: '460px',
        height: '289px',
      },
      [theme.breakpoints.down('768')]: {
        width: 'unset',
        height: 'unset',
        minWidth: '302px',
      },
    },
  },
  popupContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  message: {
    position: 'relative',
    display: 'flex',
    backgroundColor: '#F4F4F4',
    zIndex: '1',
    padding: '17px',
    top: '37px',
    [theme.breakpoints.down('768')]: {
      top: '12px',
      marginTop: '5%',
    },
  },
  popupTitle: {
    borderRadius: '24px',
    fontSize: 'var(--font-size-19)',
    paddingLeft: '3%',
    position: 'relative',
    top: '6px',
    paddingBottom: '0px',
    height: '27px',
    [theme.breakpoints.down('1500')]: {
      fontSize: 'var(--font-size-18)',
    },
    [theme.breakpoints.down('1300')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('768')]: {
      fontSize: 'var(--font-size-14)',
      fontWeight: '600',
    },
  },
  checkIcon: {
    marginRight: '7px',
    display: 'flex',
    alignItems: 'baseline',
  },
  popupSubtitle: {
    color: '#767676',
    fontWeight: 400,
    fontSize: 'var(--font-size-14)',
    [theme.breakpoints.down('1300')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('768')]: {
      fontSize: 'var(--font-size-16)',
      fontWeight: '400',
    },
  },
  bullionzLogoTextContainer: {
    width: '100%',
    position: 'relative',
  },
  bullionzLogoText: {
    width: '100%',
    position: 'relative',
  },
  contentContainer: {
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  button: {
    color: 'black',
    border: '2px solid black',
    borderRadius: '60px',
    fontSize: '18px',
    fontWeight: '600',
    minWidth: '150px',
    maxHeight: '40px',
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0px 3.1852872371673584px 0px 0px #76767633',
    '&:hover': {
      border: '1px solid black',
    },
  },
  depositBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  depositFundsBtn: {
    width: '10rem',
    cursor: 'pointer',
    margin: '0 auto',
    minWidth: '75px',
    backgroundColor: 'var(--gold)',
    color: 'var(--white)',
    fontWeight: 600,
    fontSize: '0.8rem',
    borderRadius: '1.2rem',
    '&:hover': {
      background: 'var(--gold) !important',
    },
    '& svg': {
      backgroundColor: 'var(--gold)',
      color: 'var(--white)',
    },
  },
}));
