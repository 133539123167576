import { Box } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Tab, Tabs } from "@mui/material";
import CustomButton from "../../../common/CustomButton/CustomButton";
import { tooltipContent } from "../../../../constants/tooltipTexts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MobileTotalMonthlyPoolInStaking({
  chart1,
  chart2,
  classTitle,
  classTab,
}) {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "space-between",
            width: "50%",
            alignItems: "center",
          }}
        >
          <Box className={classTitle}>TOTAL MONTHLY POOL</Box>
          <div className={classes.tooltipContainer}>
            <CustomButton
              type="moreinfo"
              onClick={() => {}}
              buttonText="i"
              tooltipTitle={tooltipContent.totalMonthlyPool}
            />
          </div>
        </Box>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classTab}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            sx={{ minHeight: "1.2rem" }}
          >
            <Tab label="THIS MONTH" {...a11yProps(0)} />
            <Tab label="ALL TIME" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>

      <Box className={classes.chartContainer}>
        <TabPanel value={value} index={0}>
          {chart1}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {chart2}
        </TabPanel>
      </Box>
    </Box>
  );
}

export default MobileTotalMonthlyPoolInStaking;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    bottom: "9px",
  },
  tooltipContainer: {
    position: "relative",
    bottom: "20px",
    marginLeft: "2px",
  },
  chartContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
