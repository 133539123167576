import { userService } from "../service/user";

export async function handleGetUserDetails(setUserDetails, fn) {
  try {
    let details = await userService.getUserDetails(fn);
    setUserDetails(details);
    return details;
  } catch (error) {
    console.log("Error in handleGetUserDetails:", error);
  }
}
