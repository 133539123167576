import React from 'react';
import { Button } from '@mui/material';

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const AssetsTabs = ({ tab, setTab }) => {
  const classes = useStyles();

  const handleAllAssetsClicked = () => {
    setTab('assets');
  };

  const handleWatchlistClicked = () => {
    setTab('watchlist');
  };
  return (
    <div className={classes.container}>
      <div>
        <Button
          onClick={handleAllAssetsClicked}
          className={clsx(classes.tabButton, tab === 'assets' ? classes.selectedTab : classes.unselectedTab)}
        >
          ALL ASSETS
        </Button>
      </div>
      <div>
        <Button
          className={clsx(classes.tabButton, tab === 'watchlist' ? classes.selectedTab : classes.unselectedTab)}
          onClick={handleWatchlistClicked}
        >
          WATCHLIST
        </Button>
      </div>
    </div>
  );
};

export default AssetsTabs;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    paddingBottom: '20px',
  },

  tabButton: {
    height: '22px',
    fontSize: '14px',
    textWrap: 'nowrap',
  },

  selectedTab: {
    background: '#E0C38B',
    color: 'white',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#E0C38B',
    },
  },

  unselectedTab: {
    background: 'transparent',
    color: '#2D2D49',
    fontWeight: '400',
  },
}));
