import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../../context/DataContext';
import { routes } from '../../../../../routes';
import IButton from '../../../../common/IButton/IButton';
import { tooltipContent } from '../../../../../constants/tooltipTexts';

function MobileNavigationBack({ appView, page, children }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { isInHistory, setIsInHistory } = useAppContext();

  const hasIdParam = new URLSearchParams(location.search).has('id');

  const handleBackClick = () => {
    const appView = sessionStorage.getItem('isNative');
    const isDemo = sessionStorage.getItem('isDemo') === 'true';

    if (page === 'Profile Settings' && appView && !isDemo) {
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "UserProfileBack"}');
    }

    if (page === 'Store' && hasIdParam) {
      navigate(routes.store);
      setIsInHistory(false);
      return;
    }

    if (page === 'Store') {
      navigate(routes.dashboard);
      setIsInHistory(false);
      return;
    }

    if (page === 'Crypto') {
      navigate(routes.dashboard);
      setIsInHistory(false);
      return;
    }

    if (page === 'Crypto Transactions') {
      navigate(routes.crypto);
      setIsInHistory(false);
      return;
    }

    setIsInHistory(false);
    navigate(-1);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.buttonContainer}>
        {!appView && (
          <Button onClick={() => handleBackClick()} className={classes.backButton} startIcon={<ArrowBackIosIcon />}>
            Back
          </Button>
        )}
      </Box>
      <Box className={classes.title}>{page}</Box>

      <Box className={classes.title1}>{children}</Box>
      {page === 'Crypto Transactions' && <IButton tooltipTitle={tooltipContent.cryptoTransactions} />}
    </Box>
  );
}

export default MobileNavigationBack;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'var(--mobile-grey)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    height: '40px',
  },
  backButton: {
    color: 'var(--black)',
    fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-15))',
    '& .MuiSvgIcon-root': {
      fontSize: 'var(--font-size-12)',
    },
  },
  title: {
    fontWeight: 'bold',
    paddingBottom: '0.3rem',
    width: '48%',
    fontSize: 'clamp(var(--font-size-10), var(--font-size-11), var(--font-size-13))',
  },
  title1: {
    fontWeight: 'bold',
    paddingBottom: '0.3rem',
    width: '25%',
    fontSize: 'clamp(var(--font-size-10), var(--font-size-11), var(--font-size-13))',
  },
  buttonContainer: {
    width: '25%',
    textAlign: 'left',
  },
}));
