const moment = require('moment-timezone');

// Get the user's current timezone
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Create a moment in New York time
const nowNY = moment().tz('America/New_York');

// Set the times in New York time
const dailyBreakStartNY = nowNY.clone().hour(16).minute(59).second(0); // Daily break start 4:59 PM New York time
const dailyBreakEndNY = nowNY.clone().hour(18).minute(0).second(0); // Daily break end 6:00 PM New York time
const weekendBreakStartNY = nowNY.clone().day(5).hour(16).minute(59).second(0); // 5:00 PM on Friday
const weekendBreakEndNY = nowNY.clone().day(0).hour(18).minute(0).second(0);

// Convert New York times to local time
const dailyBreakStartLocal = dailyBreakStartNY.clone().tz(userTimezone);
const dailyBreakEndLocal = dailyBreakEndNY.clone().tz(userTimezone);
const weekendBreakStartLocal = weekendBreakStartNY.clone().tz(userTimezone);
const weekendBreakEndLocal = weekendBreakEndNY.clone().tz(userTimezone);

// Get the day of the week for the local times
const weekendBreakStartDay = weekendBreakStartLocal.day();
const weekendBreakEndDay = weekendBreakEndLocal.day();

// Map the day of the week numbers to names
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const messages = {
  orderSuccessful: 'Your order has been successfully initiated. Please, follow up the status.',

  insufficientFunds:
    'Your order can not be executed due to insufficient funds. Please top-up the account and try again.',

  insufficientCoins:
    'Your order can not be executed due to insufficient free coins. Please buy coins or lower the order volume.',

  insufficientBullionzCoins: 'Your order can not be executed at this time. Please try to lower the order volume.',

  noBullionzCoins: 'Your order can not be executed at this time. Please try again later.',

  marketChanges: 'Your order could not be executed due to market changes (according to our T&C). Please try again.',

  somethingWentWrong: 'Something went wrong. Please try again.',

  notEnoughCoinsFromGoldBars: 'Something went wrong. Please try with lower order volume.',

  addressField: 'Your personal address in not fulfilled. Please add a delivery address.',

  marketClosed: `Your order cannot be executed outside of market working hours.\n\nMarket Working Hours:\n• ${
    daysOfWeek[weekendBreakEndDay]
  } ${dailyBreakEndLocal.format('HH:mm')} to ${daysOfWeek[weekendBreakStartDay]} ${dailyBreakStartLocal.format(
    'HH:mm',
  )}\n• Daily Break: ${dailyBreakStartLocal.format('HH:mm')} to ${dailyBreakEndLocal.format('HH:mm')}`,

  marketClosedHTML: `Your order cannot be executed outside of market working hours. <br><br> Market Working Hours:<br>• ${
    daysOfWeek[weekendBreakEndDay]
  } ${dailyBreakEndLocal.format('HH:mm')} to ${daysOfWeek[weekendBreakStartDay]} ${dailyBreakStartLocal.format(
    'HH:mm',
  )}<br>• Daily Break: ${dailyBreakStartLocal.format('HH:mm')} to ${dailyBreakEndLocal.format('HH:mm')}`,

  orderClosed: 'This order is now closed',

  successTransferFunds: 'Your transfer has been successfully executed.',

  errorTransferFunds:
    'Your transfer can not be executed due to insufficient funds. Please, top-up the account and try again.',

  createCryptoAccount: 'Are you sure you want to create a crypto account for USDT?',

  cryptoAccountReady: 'Your crypto account is ready.',

  accountNotVerified: 'Your account is not verified. Please upload requested documents.',

  errorTransferAmount: 'The amount you try to transfer should be bigger than 0.00',

  processingOrder: 'Processing your order. Please wait...',

  processingClose: 'Processing will take longer. Please check the order later.',

  deliveryToAddressNotAvailable:
    'Delivery to this address is not available at this time. Use the "Add New Address" button on the previous step to change the delivery address.',
};
