import { Box } from "@mui/system";
import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import WebLayout from "../../../components/common/Layout/WebLayout";
import SumSubComponent from "../../../components/WebComponents/SumSubComponent/SumSubComponent";

function SumSub() {
  const classes = useStyles();
  return (
    <WebLayout>
      <Box className={classes.container}>
        <h1>Verification</h1>
        <SumSubComponent />
      </Box>
    </WebLayout>
  );
}

export default SumSub;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      minHeight: "34.1rem !important",
    },
  })
);
