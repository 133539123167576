import { Box, createStyles } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import CustomButton from "../../common/CustomButton/CustomButton";
import { makeStyles } from "@mui/styles";
import { useAppContext } from "../../../context/DataContext";
import MobileAddAddressPopUp from "../popups/AddAddressPopUp/MobileAddAddressPopUp";
import { countryService } from "../../../service/countries";
import { handleGetUserDetails } from "../../../helpers/handleGetUserDetails";
import { useEffect } from "react";
import LoadingBar from "../../common/LoadingBar/LoadingBar";
import PleaseLoginPopUp from "../../common/Popups/PleaseLoginPopup/PleaseLoginPopup";
import { handlePleaseLogin } from "../../../helpers/pleaseLoginOrRegisterForDemo";
import { updateBucket } from "../../../helpers/update-client-bucket";
import { userService } from "../../../service/user";
import { handleGetUserCart } from "../../../helpers/handleGetUserCart";

function MobileDeliveryAddress() {
  const classes = useStyles();
  const {
    isAppView,
    setIsCartFetched,
    setProductsBucket,
    proceedAddNewAddressClicked,
  } = useAppContext();

  const [isAddressPopUpOpen, setIsAddressPopUpOpen] = useState(false);
  const [userCartData, setUserCartData] = useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const [countries, setCountries] = useState([]);

  const getAllCountries = async () => {
    try {
      const countries = await countryService.getAllCountries();
      setCountries(countries);
    } catch (error) {
      console.error("An error occurred while fetching countries:", error);
    }
  };

  const changeDeliveryAddress = () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    getAllCountries();
    setIsAddressPopUpOpen(true);
  };

  useEffect(() => {
    handleGetUserDetails(setUserDetails, setLoading);
  }, [proceedAddNewAddressClicked]);

  useEffect(() => {
    handleGetUserCart(setUserCartData, () => {});
  }, []);

  useEffect(() => {
    updateBucket(
      isAppView,
      userCartData,
      setIsCartFetched,
      setProductsBucket,
      userService?.updateShoppingCart
    );
  }, [userCartData]);

  const {
    address,
    city,
    country,
    shippingAddress,
    shippingCity,
    shippingCountry,
    shippingPostalCode,
    shippingState,
    state,
    postalCode,
  } = userDetails;

  const userAddressArr = [
    shippingAddress,
    shippingCity,
    shippingState,
    shippingCountry?.name,
    shippingPostalCode,
  ];

  if (!shippingAddress) {
    userAddressArr[0] = address;
    userAddressArr[1] = city;
    userAddressArr[2] = state;
    userAddressArr[3] = country?.name;
    userAddressArr[4] = postalCode;
  }

  for (let i = 0; i < userAddressArr.length; i++) {
    if (
      userAddressArr[i] === null ||
      userAddressArr[i] === "" ||
      !userAddressArr[i]
    ) {
      userAddressArr.splice(i, 1);
      i--;
    }
  }

  return (
    <Box
      className={clsx(classes.marginProvider, classes.deliveryAddressWrapper)}
    >
      <Box className={classes.title}>Delivery Address</Box>
      <Box
        className={clsx(classes.valueAndLabelHolder, classes.addressWrapper)}
      >
        <Box className={classes.address}>
          {userAddressArr.join(", ") || "No address added"}
        </Box>
      </Box>
      <Box className={clsx(classes.buttonWrapper, classes.addNew)}>
        <CustomButton
          type="black"
          buttonText="Change"
          width={window.innerWidth > window.innerHeight ? "20%" : "45%"}
          height="100%"
          fontSize="10px"
          onClick={changeDeliveryAddress}
        ></CustomButton>
      </Box>
      <LoadingBar open={loading} />
      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
      {isAddressPopUpOpen && (
        <MobileAddAddressPopUp
          isPopupOpen={isAddressPopUpOpen}
          setIsPopupOpen={setIsAddressPopUpOpen}
          countries={countries}
          userDetails={userDetails}
        />
      )}
    </Box>
  );
}

export default MobileDeliveryAddress;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      alignItems: "center",
      justifyContent: "center",
      fontFamily: theme.typography.fontFamily,
      margin: "auto",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      lineHeight: "1.2rem",
      marginTop: "1rem",
      marginBottom: "1rem",
      fontSize:
        "clamp(var(--font-size-15), var(--font-size-14), var(--font-size-13)) !important",
      fontWeight: "normal",
      [theme.breakpoints.down("350")]: {
        fontSize: "var(--font-size-11) !important",
      },
      [theme.breakpoints.down("330")]: {
        fontSize: "var(--font-size-10) !important",
      },
    },
    buttonWrapper: {
      marginTop: "1rem",
      maxHeight: "2rem",
      height: "3rem",
    },
    addressWrapper: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      height: "3rem",
    },
    address: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "80%",
      height: "100%",
      backgroundColor: "#F7F7F7",
      border: "1px solid #D2D5D8",
      borderRadius: "4px",
    },
    title: {
      fontSize:
        "clamp(var(--font-size-20), var(--font-size-18), var(--font-size-16)) !important",
      fontWeight: 600,
      paddingLeft: "2rem",
      paddingRight: "2rem",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  })
);
