import { makeStyles } from "@mui/styles";
import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAppContext } from "../../../context/DataContext";
import { userService } from "../../../service/user";
import { ReactComponent as BullionzBackgroundIcon } from "../../../assets/images/bullionz-tutorial-modal-icon.svg";

const TutorialWelcomeModal = ({ isMobile, isNative }) => {
  const { tutorialStep, setTutorialStep } = useAppContext();

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleTakeTour = (e) => {
    e?.preventDefault();
    setTutorialStep(1);

    sessionStorage.setItem("tutorialStep", 1);
  };

  const BTXG = document.getElementById("btxg-container");
  const newsContainer = document.getElementById("news-container");
  const buyGoldIcon = document.getElementById("buy-gold-icon");
  const portfolio = document.getElementById("portfolio-container");
  const transactions = document.getElementById("transactions-container");

  const balanceContainer = document.getElementById("balance-container");
  const depositBtn = document.getElementById("deposit-btn");
  const redeemIcon = document.getElementById("redeem-icon");
  const yieldingIcon = document.getElementById("yielding-icon");
  const vaultingIcon = document.getElementById("vaulting-icon");
  const monthlyPool = document.getElementById("monthly-pool");
  const mobileHeader = document.getElementById("mobile-header");
  const buyCloseContainer = useMemo(() =>
    document.getElementById("buyclose-container")
  );

  const blurAllItems = () => {
    balanceContainer?.classList?.remove("except");
    depositBtn?.classList?.remove("except");
    redeemIcon?.classList?.remove("except");
    buyGoldIcon?.classList?.remove("except");
    yieldingIcon?.classList?.remove("except");
    vaultingIcon?.classList?.remove("except");
    monthlyPool?.classList?.remove("except");
    buyCloseContainer?.classList?.remove("except");
    BTXG?.classList?.remove("except");
    portfolio?.classList?.remove("except");
    transactions?.classList?.remove("except");
    newsContainer?.classList?.remove("except");
    mobileHeader?.classList?.remove("except");
  };

  const handleSkipTour = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    if (params.get("tutorial") === "true") {
      params.delete("tutorial");
      url.search = params.toString();
      window.history.replaceState({}, "", url.toString());
    }

    blurAllItems();

    setTutorialStep(15);

    if (isNative) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        '{"Event": "Tutorial", "Action": "passed"}'
      );

      sessionStorage.removeItem("tutorialStep");
    } else if (isMobile) {
      userService.updateTutorialStatus("MOBILE", "SKIPPED");
      sessionStorage.setItem("tutorialStep", 15);
    } else {
      userService.updateTutorialStatus("WEB", "SKIPPED");
      sessionStorage.setItem("tutorialStep", 15);
    }
  };

  return (
    <Modal
      open={open}
      className="except"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalBoxWrapper}>
        <BullionzBackgroundIcon className={classes.modalBoxBackgroundInner} />
        <p className={classes.paragraphs}>
          <span className={classes.bolderText}>Welcome to Bullionz</span>, where
          your digital gold investment journey and real gold ownership begins.
        </p>

        <p className={classes.paragraphs}>
          Are you ready to explore all that our platform has to offer? Let us
          guide you through a quick tour of our dashboard to help you get
          started.
        </p>

        <div className={classes.btnWrapper}>
          <p></p>

          <button className={classes.nextBtn} onClick={handleTakeTour}>
            Take Tour
          </button>

          <button className={classes.skipBtn} onClick={handleSkipTour}>
            Skip
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalBoxWrapper: {
    position: "fixed",
    top: "50%",
    left: "50%",
    right: "50%",
    marginLeft: "auto",
    marginRight: "auto",

    transform: "translate(-50%, -50%)",

    width: 372,
    padding: 14,
    background: "white",
    borderRadius: "12px",
    border: "0.5px #D2D5D8",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",

    "&:focus-visible": {
      outline: "none",
    },

    [theme.breakpoints.down("400")]: {
      width: "calc(100vw - 68px)",
    },
  },

  modalBoxBackgroundInner: {
    position: "absolute",
    top: "20px",
    left: "-20px",
    height: "calc(100% - 20px)",
    zIndex: -1,
    fill: "white",
  },

  btnWrapper: {
    display: "grid",
    justifyItems: "end",
    gridTemplateColumns: "1fr 130px 1fr",

    marginTop: "16px",

    [theme.breakpoints.down("768")]: {
      gridTemplateColumns: "1fr",
      justifyItems: "center",
      gap: "14px",
    },
  },

  skipBtn: {
    cursor: "pointer",

    color: "grey",
    border: "none",
    fontSize: "14px",
    marginRight: "8px",
    borderRadius: "28px",
    background: "transparent",
    textDecoration: "underline",
  },

  paragraphs: {
    marginBottom: "16px",
    letterSpacing: "0.5px",
  },

  bolderText: {
    fontWeight: "bold",
  },

  nextBtn: {
    cursor: "pointer",

    color: "white",
    fontSize: "14px",
    marginRight: "8px",
    fontWeight: "bold",
    padding: "12px 26px",
    borderRadius: "28px",
    background: "#48D0A4",
    border: "1px solid white",
  },
}));

export default TutorialWelcomeModal;
