import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, createStyles } from '@mui/material';

import Asset from './components/Asset';
import { useAppContext } from '../../../context/DataContext';
import IButton from '../../common/IButton/IButton';
import { tooltipContent } from '../../../constants/tooltipTexts';

const MobilePortfolioAssets = () => {
  const classes = useStyles();
  const { selectedCryptoAccount } = useAppContext();
  const assets = selectedCryptoAccount?.assets?.filter((asset) => {
    return asset?.amount > 0 && asset?.assetId !== 'usdt';
  });

  return (
    <div className={classes.assetsList}>
      {assets?.length > 0 && (
        <div className={classes.tooltipContainer}>
          <IButton tooltipTitle={tooltipContent.cryptoPortfolio} />
        </div>
      )}
      {assets?.length > 0 &&
        assets?.map((asset) => {
          return <Asset key={asset.assetId} asset={asset} />;
        })}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    assetsList: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
      padding: '8px',
      overflowX: 'auto',
      marginBottom: '8px',
    },

    tooltipContainer: {
      position: 'absolute',
      right: '-6px',
      top: '90px',
    },
  }),
);

export default MobilePortfolioAssets;
