import { Box } from '@mui/system';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/material';
import AppLayout from '../../../components/common/Layout/AppLayout';
import MobileNavigationBack from '../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack';
import { routes } from '../../../routes';
import { useAppContext } from '../../../context/DataContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { getWithDrawIframe } from '../../../helpers/getWithDrawIframe';
import LoadingBar from '../../../components/common/LoadingBar/LoadingBar';

function MobileWithdraw() {
  const classes = useStyles();
  const { withdrawIframeUrl, setWithDrawIframeUrl, isMobile, activeTradeAccountId } = useAppContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWithDrawIframe(setWithDrawIframeUrl, setLoading);
  }, [activeTradeAccountId]);
  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileNavigationBack page={'Withdrawal'} route={routes.dashboard} />
        <>
          {withdrawIframeUrl?.url ? (
            <iframe
              src={withdrawIframeUrl?.url}
              className={!isMobile ? classes.withdraw : classes.withdrawMobile}
            ></iframe>
          ) : null}
        </>
        <LoadingBar open={loading} />
      </Box>
    </AppLayout>
  );
}

export default MobileWithdraw;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
    withdrawMobile: {
      width: '100%',
      height: '92.2%',
      border: '0px !important',
    },
  }),
);
