import { userService } from "../service/user";

async function handleGetTotalMonthlyPool(setPool, setLoading) {
  try {
    const data = await userService.getTotalMonthlyPool(setLoading);
    setPool(data);
    // if (!data) {
    //   sessionStorage.setItem(data, "pool");
    // }
    return data;
  } catch (error) {
    console.error(error);
  }
}

export default handleGetTotalMonthlyPool;
