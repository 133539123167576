import { Box, createStyles } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import React from "react";

function PersonalDetails({ userDetails }) {
  const classes = useStyles();
  const {
    city,
    email,
    country,
    address,
    lastName,
    firstName,
    postalCode,
    phoneNumber,
    state,
  } = userDetails;

  return (
    <Box className={clsx(classes.container, classes.personalDetailsWrapper)}>
      <Box className={classes.title}>Personal Details</Box>
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>First Name</Box>
        <Box className={classes.value}>{firstName || ""}</Box>
      </Box>
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>Last Name</Box>
        <Box className={classes.value}>{lastName || ""}</Box>
      </Box>
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>Address</Box>
        <Box className={classes.value}>{address || ""}</Box>
      </Box>
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>City</Box>
        <Box className={classes.value}>{city || ""}</Box>
      </Box>
      {state !== null && (
        <Box className={classes.valueAndLabelHolder}>
          <Box className={classes.label}>State</Box>
          <Box className={classes.value}>{state || ""}</Box>
        </Box>
      )}
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>Country</Box>
        <Box className={classes.value}>{country?.name || ""}</Box>
      </Box>
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>Zip Code</Box>
        <Box className={classes.value}>{postalCode || ""}</Box>
      </Box>
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>Email</Box>
        <a className={classes.value} href={`mailto:${email}`}>
          <Box>{email || ""}</Box>
        </a>
      </Box>
      <Box className={classes.valueAndLabelHolder}>
        <Box className={classes.label}>Phone number</Box>
        <Box className={classes.value}>{phoneNumber || ""}</Box>
      </Box>
    </Box>
  );
}

export default PersonalDetails;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      alignItems: "center",
      justifyContent: "center",
      fontFamily: theme.typography.fontFamily,
      margin: "auto",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      lineHeight: "1.2rem",
      marginTop: "1rem",
      marginBottom: "1rem",
      fontSize:
        "clamp(var(--font-size-15), var(--font-size-14), var(--font-size-13)) !important",
      fontWeight: "normal",
      [theme.breakpoints.down("350")]: {
        fontSize: "var(--font-size-11) !important",
      },
      [theme.breakpoints.down("330")]: {
        fontSize: "var(--font-size-10) !important",
      },
    },
    title: {
      fontSize:
        "clamp(var(--font-size-20), var(--font-size-18), var(--font-size-16)) !important",
      fontWeight: 600,
      paddingLeft: "2rem",
      paddingRight: "2rem",
      marginTop: "2rem",
    },
    valueAndLabelHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "1rem",
    },
    value: {
      display: "flex",
      alignItems: "center",
      width: "40%",
      textAlign: "left",
      fontWeight: 500,
      color: "var(--black)",
      marginTop: "0.5rem",
      wordBreak: "break-word",
    },
    label: {
      color: "var(--grey-secondary)",
      marginTop: "0.5rem",
    },
  })
);
