import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../context/DataContext";
import { handleGetUserDetails } from "../../../helpers/handleGetUserDetails";
import {
  getElementMiddlePosition,
  getElementPosition,
} from "../../../utils/get-element-position";

const getOrientation = () => window.screen.orientation.type;

export const useTutorialMobileDashboard = () => {
  const [windowProps, setWindowProps] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [orientation, setOrientation] = useState(getOrientation());
  const isOnHomePage = window.location.pathname === "/";

  const [elementProps, setElementProps] = useState({
    left: 0,
    top: 0,
    right: 0,
    width: 0,
  });

  const {
    isMobile,
    isAppView,
    tutorialStep,
    userTradeAccounts,
    userBrokerAccounts,
    setArrowProps,
    setTutorialStep,
    tooltipDimensions,
    isBuySellPopupOpen,
    isAccountMenuOpened,
    setTutorialElmOffset,
    isRedeemGoldPopupOpen,
    setIsAccountMenuOpened,
    setArrowPositionUpdated,
    setTooltipPositionUpdated,
  } = useAppContext();

  const reportWindowSize = () => {
    setWindowProps({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const getUserData = async () => {
    setIsLoading(true);

    await handleGetUserDetails(setUserDetails, () => {});

    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  };
  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [tutorialStep]);

  const updateOrientation = (event) => {
    const depositIconCopy = document.getElementById("deposit-icon-copy");

    if (depositIconCopy) {
      depositIconCopy.remove();
    }

    if (isAccountMenuOpened) {
      setIsAccountMenuOpened(false);
    }

    setOrientation(getOrientation());
  };

  useEffect(() => {
    if (!isBuySellPopupOpen && tutorialStep === 10 && isOnHomePage) {
      tradeGoldBtn?.classList?.add("except");
    } else if (isBuySellPopupOpen && tutorialStep === 10 && isOnHomePage) {
      tradeGoldBtn?.classList?.remove("except");
    }
  }, [isBuySellPopupOpen]);

  useEffect(() => {
    if (isRedeemGoldPopupOpen && isOnHomePage) {
      redeemGoldIcon?.classList?.remove("except");
    } else if (
      !isRedeemGoldPopupOpen &&
      isOnHomePage &&
      (tutorialStep > 0 || tutorialStep > 12)
    ) {
      redeemGoldIcon?.classList?.add("except");
    }
  }, [isRedeemGoldPopupOpen]);

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  useEffect(() => {
    const isDemo = sessionStorage.getItem("isDemo") === "true";
    if (isAppView && !isDemo) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const searchParams = url.searchParams;
      const isTutorialEnabled =
        searchParams.has("tutorial") && searchParams.get("tutorial") === "true";
      const activeTutorialStep = Number(sessionStorage.getItem("tutorialStep"));

      if (!isTutorialEnabled && sessionStorage.getItem("tutorialStep")) {
        sessionStorage.removeItem("tutorialStep");
      }

      if (isTutorialEnabled) {
        if (activeTutorialStep && activeTutorialStep < 15) {
          setTutorialStep(activeTutorialStep);
        } else {
          setTutorialStep(0);
          sessionStorage.setItem("tutorialStep", 0);
        }
      }
    } else if (userDetails?.webTutorialPassed && !isDemo) {
      let isMobileTutorialPassed =
        userDetails?.mobileTutorialPassed === "PASSED" ||
        userDetails?.mobileTutorialPassed === "SKIPPED" ||
        userDetails?.mobileTutorialPassed === "CLOSED";

      const activeStep = Number(sessionStorage.getItem("tutorialStep"));

      if (sessionStorage.getItem("tutorialStep") && activeStep < 15) {
        isMobileTutorialPassed = false;
      }

      if (isMobileTutorialPassed) {
        setTutorialStep(15);
        sessionStorage.removeItem("tutorialStep");
        window?.webkit?.messageHandlers?.notifyNative?.postMessage(
          '{"Event": "Tutorial", "Action": "passed"}'
        );
      } else if (!isMobileTutorialPassed && tutorialStep < 1) {
        setTutorialStep(0);
        sessionStorage.setItem("tutorialStep", 0);
      }
    }
  }, [userDetails]);

  const userHeader = document.getElementById("mobile-header");
  const buyCloseContainer = document.getElementById("buyclose-container");
  const BTXGCoins = document.getElementById("header-btxg-coins");
  const cashBalance = document.getElementById("header-cash-balance");
  const portfolioPL = document.getElementById("portfolio-pl");
  const accountIcon = document.getElementById("account-icon");
  const goldHistory = document.getElementById("mobile-gold-history");
  const accountMenu = document.getElementById("account-side-menu");
  const transactionsCard = document.getElementById("transactions-card");
  const newsCard = document.getElementById("news-card");
  const accountBalance = document.getElementById("account-balance");
  const goldHoldingsLabel = document.getElementById(
    "header-gold-holdings-label"
  );
  const goldHoldingsValue = document.getElementById(
    "header-gold-holdings-value"
  );
  const redeemGoldIcon = document.getElementById("redeem-gold-icon");
  const stakingIcon = document.getElementById("stake-icon");
  const buyGoldIcon = document.getElementById("buy-gold-icon");
  const buyGoldBtn = document.getElementById("buy-gold-btn");
  const yieldingIcon = document.getElementById("yield-icon");
  const autoInvestBtn = document.getElementById("autoinvest-btn");
  const tradeGoldBtn = document.getElementById("trade-gold-btn");
  const tradeGoldBtnTrigger = document.getElementById("trade-gold-btn-trigger");
  const portfolioAllocation = document.getElementById("portfolio-allocation");
  const portfolioAllocationDoughnut = document.getElementById(
    "portfolio-allocation-doughnut"
  );
  const totalMonthly = document.getElementById("total-monthly-pool");
  const totalMonthlyDoughnut = document.getElementById(
    "total-monthly-pool-doughnut"
  );
  const monthlyPoolCanvas = document.getElementById("chartCanvas-2");

  const depositIcon = document.getElementById("deposit-icon");
  const notificationsIcon = document.getElementById("notifications-icon");

  const blurAllItems = () => {
    if (userHeader) {
      buyGoldBtn?.classList?.remove("except");
      buyGoldIcon?.classList?.remove("except");
      BTXGCoins?.classList?.remove("except");
      userHeader?.classList?.remove("except");
      cashBalance?.classList?.remove("except");
      goldHoldingsLabel?.classList?.remove("except");
      goldHoldingsValue?.classList?.remove("except");
      portfolioPL?.classList?.remove("except");
      accountIcon?.classList?.remove("except");
      accountMenu?.classList?.remove("except");
      accountBalance?.classList?.remove("except");
      redeemGoldIcon?.classList?.remove("except");
      stakingIcon?.classList?.remove("except");
      yieldingIcon?.classList?.remove("except");
      autoInvestBtn?.classList?.remove("except");
      tradeGoldBtn?.classList?.remove("except");
      portfolioAllocation?.classList?.remove("except");
      portfolioAllocationDoughnut?.classList?.remove("except");
      totalMonthly?.classList?.remove("except");
      depositIcon?.classList?.remove("except");
      notificationsIcon?.classList.remove("except");
      newsCard?.classList?.remove("except");
      transactionsCard?.classList?.remove("except");
      goldHistory?.classList?.remove("except");
      tradeGoldBtnTrigger?.classList?.remove("except");
      monthlyPoolCanvas?.classList?.remove("except");

      const depositIconCopy = document.getElementById("deposit-icon-copy");

      if (depositIconCopy) {
        depositIconCopy.remove();
      }

      if (tutorialStep !== 3 && document.getElementById("side-menu-drawer")) {
        setIsAccountMenuOpened(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === "tutorialStep") {
        setTutorialStep(Number(e.newValue));
      }
    });

    return () => {
      window.removeEventListener("storage", () => {});
    };
  }, []);

  const getModalPosition = (
    elm,
    scrollable = false,
    position = "top",
    type = "normal",
    isCustomElm = false,
    secondElm
  ) => {
    let classList = elm?.classList;

    blurAllItems();

    let positionProps;

    if (isCustomElm) {
      positionProps = elm;
    } else if (secondElm) {
      positionProps = getElementMiddlePosition(secondElm, position);
    } else {
      positionProps = getElementMiddlePosition(elm, position);
    }

    let modalPosition = {
      top: 0.0,
      left: 0.0,
    };

    const tooltipWidth =
      tooltipDimensions?.width < 250 ? 400 : tooltipDimensions?.width;

    switch (position) {
      case "top":
        modalPosition.top = positionProps?.top - tooltipDimensions?.height - 40;
        modalPosition.left = positionProps?.left - tooltipWidth / 2 - 20;
        break;
      case "bottom":
        modalPosition.top = positionProps?.top + 6;
        modalPosition.left = positionProps?.left - tooltipWidth / 2 - 20;
        positionProps?.left - tooltipWidth / 2 - 20;
        break;
      case "left":
        modalPosition.top = positionProps?.top - tooltipDimensions?.height / 2;
        modalPosition.left = positionProps?.left - tooltipWidth - 20;
        break;
      case "right":
        modalPosition.top =
          positionProps?.top - tooltipDimensions?.height / 2 - 20;
        modalPosition.left = positionProps?.left;
        break;
    }

    const width = document.getElementById("root").offsetWidth;

    if (modalPosition?.left > width - tooltipWidth - 40) {
      const newLeftDistance = width - tooltipWidth - 40;
      modalPosition.left = newLeftDistance;
    }

    if (modalPosition?.left < 0) {
      modalPosition.left = 0;
    }

    setTooltipPositionUpdated(true);
    setTutorialElmOffset({
      top: Number(modalPosition.top?.toFixed(2)),
      left: Number(modalPosition?.left?.toFixed(2)),
    });
    setArrowPositionUpdated(true);

    setArrowProps({ positionProps, position, type: type });

    if (scrollable === true) {
      let rect;
      const isNaturalElm = !(
        isAppView &&
        (tutorialStep === 6 || tutorialStep === 7)
      );

      if (isNaturalElm) {
        rect = elm?.getBoundingClientRect();
      } else {
        setTimeout(() => {
          document.getElementById("content-wrapper").scrollTo(0, 9999);
          window.scrollTo(0, 9999);
        }, 400);
      }

      if (window.innerHeight <= 700 && rect && isNaturalElm) {
        if (isAppView && (tutorialStep === 7 || tutorialStep === 8)) {
          document.getElementById("content-wrapper").scrollTo(0, 9999);
          window.scrollTo(0, 9999);
        }
        if (tutorialStep >= 12) {
          document.getElementById("content-wrapper").scrollTo(0, 9999);
          window.scrollTo(0, 9999);
        } else if (tutorialStep === 11) {
          window.scrollTo(0, 0);
          document.getElementById("content-wrapper").scrollTo(0, 0);
        } else if (tutorialStep === 8) {
          window.scrollTo(0, rect.top);
          document.getElementById("content-wrapper").scrollTo(0, 0);
        } else if (tutorialStep >= 6) {
          window.scrollTo(0, rect.top + rect.height + window.scrollY);
          document.getElementById("content-wrapper").scrollTo(0, 0);
        } else if (tutorialStep >= 4) {
          if (tutorialStep === 4) {
            const stakingIcon = document.getElementById("stake-icon");
            rect = stakingIcon?.getBoundingClientRect();
          }

          window.scrollTo(
            0,
            rect.top +
              rect.height +
              tooltipDimensions?.height +
              50 +
              window.scrollY -
              window.innerHeight
          );
          document.getElementById("content-wrapper").scrollTo(0, 0);
        } else if (tutorialStep < 3) {
          document.getElementById("content-wrapper").scrollTo(0, 9999);
          window.scrollTo(0, 0);
        }
      } else if (position === "bottom") {
        setTimeout(function () {
          window.scrollTo(
            0,
            rect.top +
              rect.height +
              tooltipDimensions?.height +
              40 +
              window.scrollY
          );
        }, 200);
      }
    }

    if (classList) {
      classList.add("except");
    }
  };

  const setDepositModalPosition = (
    elm,
    scrollable = false,
    type = "normal"
  ) => {
    let classList = elm?.classList;
    const depositIconImg = document.getElementById("deposit-icon-img");

    accountIcon?.classList?.remove("except");
    accountMenu?.classList?.remove("except");
    redeemGoldIcon?.classList?.remove("except");

    let positionProps = elm;

    let modalPosition = {
      top: 0.0,
      left: 0.0,
    };

    positionProps = getElementMiddlePosition(depositIconImg, "bottom");

    const tooltipWidth =
      tooltipDimensions?.width < 270 ? 400 : tooltipDimensions?.width ?? 400;

    modalPosition.top = positionProps?.top + 6;
    modalPosition.left = positionProps?.left - tooltipWidth / 2 - 20;

    const width = document.getElementById("root").offsetWidth;

    if (modalPosition?.left > width - tooltipWidth - 72) {
      modalPosition.left = width - tooltipWidth - 72;
    }

    if (modalPosition?.left < 0) {
      modalPosition.left = 0;
    }

    setTooltipPositionUpdated(true);
    setTutorialElmOffset({
      top: Number(modalPosition.top?.toFixed(2)),
      left: Number(modalPosition?.left?.toFixed(2)),
    });
    setArrowPositionUpdated(true);
    if (scrollable === true) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.scrollTo(0, 100);
      }, 400);
    }

    setArrowProps({ positionProps, position: "bottom", type });

    if (classList) {
      classList.add("except");
    }
  };

  useEffect(() => {
    const isOnHomePage = window.location.pathname === "/";
    if (userHeader && isOnHomePage && !isRedeemGoldPopupOpen) {
      if (tutorialStep === 1) getModalPosition(userHeader, true, "bottom");
      else if (tutorialStep === 2)
        getModalPosition(accountIcon, true, "bottom", "slim"); // slim
      // refactor
      else if (tutorialStep === 3) {
        const drawerSideMenu = document.getElementById("side-menu-drawer");
        const accountsHeight =
          (userTradeAccounts?.length + userBrokerAccounts?.length) * 54 +
          160 +
          50;

        if (!drawerSideMenu) {
          setIsAccountMenuOpened(true);
        }

        const elm = document.getElementById("deposit-icon");
        console.log("elm", elm);

        setDepositModalPosition(
          {
            top: accountsHeight,
            left: window?.innerWidth - (innerWidth || 190) / 2,
          },
          true,
          "bottom",
          "normal",
          true
        );

        setTimeout(() => {
          const depositIconRef = document.getElementById("deposit-icon");
          if (depositIconRef && !document.getElementById("deposit-icon-copy")) {
            const innerWidth = depositIconRef?.getBoundingClientRect()?.width;
            const depositIconCopy = depositIconRef?.cloneNode(true);
            depositIconCopy.id = "deposit-icon-copy";
            let existingStyles = depositIconCopy.getAttribute("style");
            depositIconCopy.setAttribute(
              "style",
              `${existingStyles}; width: ${innerWidth}px !important;`
            );
            depositIconCopy.classList.remove("MuiListItem-root");
            depositIconCopy.classList.add("specialDepositStyle");
            depositIconCopy.style.top = `${accountsHeight - 20}px`;
            depositIconCopy.onclick = () => {
              setIsAccountMenuOpened(false);

              if (depositIconCopy) {
                depositIconCopy.remove();
              }

              window.location.href = "/deposit";
            };
            document.getElementById("root")?.appendChild(depositIconCopy);
            setDepositModalPosition(
              {
                top: accountsHeight,
                left: window?.innerWidth - (innerWidth || 190) / 2,
              },
              true,
              "bottom",
              "normal",
              true
            );
          }
        }, 330);
      } else if (tutorialStep === 4) {
        let position = "bottom";

        if (isAppView) {
          position = "top";
          getModalPosition(redeemGoldIcon, true, position);
        } else {
          getModalPosition(buyGoldIcon, true, position);
        }
      } else if (tutorialStep === 5) {
        let position = "bottom";

        if (isAppView) {
          position = "top";
          getModalPosition(buyGoldBtn, true, position);
        } else {
          getModalPosition(redeemGoldIcon, true, position);
        }
      } else if (tutorialStep === 6) {
        if (isAppView) {
          const navElementWidth = window.innerWidth / 5;

          getModalPosition(
            {
              top: window.innerHeight + window.scrollY - 6,
              left: navElementWidth + navElementWidth / 2,
            },
            true,
            "top",
            "normal",
            true
          );
        } else if (stakingIcon?.classList) {
          getModalPosition(stakingIcon, true, "bottom");
        }
      } else if (tutorialStep === 7) {
        if (isAppView) {
          const navElementWidth = window.innerWidth / 5;

          if (window.innerWidth < 452) {
            getModalPosition(
              {
                top: window.innerHeight + window.scrollY - 6,
                left: navElementWidth * 4 + navElementWidth / 2,
              },
              true,
              "top",
              "slim",
              true
            );
          } else {
            getModalPosition(
              {
                top: window.innerHeight + window.scrollY - 6,
                left: navElementWidth * 4 + navElementWidth / 2,
              },
              true,
              "top",
              "normal",
              true
            );
          }
        } else if (yieldingIcon?.classList)
          getModalPosition(yieldingIcon, true, "bottom");
      } else if (tutorialStep === 8 && totalMonthly?.classList)
        getModalPosition(
          totalMonthly,
          true,
          "bottom",
          "normal",
          false,
          totalMonthlyDoughnut
        );
      else if (tutorialStep === 9)
        getModalPosition(
          portfolioAllocation,
          true,
          "bottom",
          "normal",
          false,
          portfolioAllocationDoughnut
        );
      else if (tutorialStep === 10 && (tradeGoldBtnTrigger || tradeGoldBtn)) {
        if (isAppView && tradeGoldBtn) {
          getModalPosition(tradeGoldBtn, true, "top");
        } else if (tradeGoldBtnTrigger && tradeGoldBtnTrigger?.classList) {
          getModalPosition(tradeGoldBtnTrigger, true, "top");
        }
      } else if (tutorialStep === 11) {
        getModalPosition(goldHistory, true, "bottom");
      } else if (tutorialStep === 12 && transactionsCard?.classList) {
        getModalPosition(transactionsCard, true, "top");
      } else if (tutorialStep === 13 && newsCard?.classList) {
        getModalPosition(newsCard, true, "top");
      } else if (tutorialStep === 14) {
        blurAllItems();
      }
    }
  }, [
    tutorialStep,
    isLoading,
    userHeader,
    depositIcon,
    orientation,
    windowProps.width,
    windowProps.height,
    tooltipDimensions,
  ]);

  return {
    isMobile,
    isAppView,
    isLoading,
    isOnHomePage,
    elementProps,
    tutorialStep,
    setTutorialStep,
  };
};
