import * as React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useAppContext } from "../../../context/DataContext";
import "../../../styles/global.styles.css";
import "../../../styles/global.fonts.css";
import CustomButton from "../../common/CustomButton/CustomButton";
import BullionzLogo from "../../../assets/images/bullionz-logo-coin.svg";
import TextField from "@mui/material/TextField";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import {
  CURRENCY_TYPE,
  coinName,
  CURRENCY_SYMBOL,
} from "../../../constants/globalconstants";
import {
  handleInputKeyUp,
  handleInputKeyDown,
} from "../../../helpers/inputValidator";
import ArrowUp from "../../../assets/images/black-arrow-up-icon.svg";
import ArrowDown from "../../../assets/images/black-arrow-down-icon.svg";
import SpecialOfferIcon from "../../../assets/images/special-offer-icon.png";
import IButton from "../../common/IButton/IButton";
import { tooltipContent } from "../../../constants/tooltipTexts";
import { generateCurrencySymbol } from "../../../helpers/generateCurrencySymbol";

const btnValues = [1, 5, 10, 20, 100, 500];

const TransactionPanel = ({
  type,
  errorMessage,
  quantity,
  handleInputChange,
  handleChosenGrams,
  totalBuy,
  totalBuyForGroup,
  totalSell,
  totalSellForGroup,
  handleIncrement,
  handleDecrement,
  setBuyingQuantity,
  setSellingQuantity,
  showSpecialPriceFieldForBuy,
  showSpecialPriceFieldForSell,
}) => {
  const classes = useStyles();
  const { isMobile, goldBidPrice, goldAskPrice } = useAppContext();

  const handleInputFocus = () => {
    if (quantity === 0) {
      if (type === "buy") {
        setBuyingQuantity("");
      } else if (type === "sell") {
        setSellingQuantity("");
      }
    }
  };
  return (
    <Box sx={{ height: "100%" }}>
      <div>
        <div className={classes.fieldTitle}>
          <div>
            I want to {type === "buy" ? "buy" : "close order(s)"}
            {isMobile && (
              <img
                src={BullionzLogo}
                alt="Bullionz logo"
                style={{ width: "15px", position: "relative", left: "3%" }}
              />
            )}
          </div>
          <div className={classes.tooltipContainer}>
            {isMobile ? (
              <CustomButton
                type="moreinfo"
                buttonText="i"
                tooltipTitle={
                  type === "buy" ? tooltipContent.buy : tooltipContent.sell
                }
              />
            ) : (
              <IButton
                tooltipTitle={
                  type === "buy" ? tooltipContent.buy : tooltipContent.sell
                }
              />
            )}
          </div>
        </div>
        <Box className={classes.field}>
          <div className={classes.bullionzGoldContainerTop}>
            <div className={classes.bullionzTitleContainer}>
              {!isMobile && (
                <div className={classes.logoContainer}>
                  <img
                    className={classes.logo}
                    src={BullionzLogo}
                    alt="Bullionz logo"
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginLeft: "2%",
                }}
              >
                <div className={classes.title}>Bullionz Gold </div>
                <div className={classes.coinName}>{coinName}</div>
              </div>
            </div>
            <div className={classes.input}>
              {!isMobile && (
                <div className={classes.arrowButtonContainer}>
                  <img
                    className={classes.arrowButton}
                    src={ArrowUp}
                    alt="Arrow Up"
                    onClick={handleIncrement}
                  />
                  <img
                    className={classes.arrowButton}
                    src={ArrowDown}
                    alt="Arrow Down"
                    onClick={handleDecrement}
                  />
                </div>
              )}
              <div className={classes.inputField}>
                <TextField
                  className={classes.customInput}
                  onFocus={handleInputFocus}
                  autoComplete="off"
                  id="buy-sell-input"
                  value={quantity}
                  size="small"
                  inputProps={{
                    maxLength: 5,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  onKeyUp={handleInputKeyUp}
                  onKeyDown={handleInputKeyDown}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: isMobile ? "white" : "#CECECE",
                color: isMobile ? "#767676" : "black",
                minHeight: !isMobile && "53px",
                fontSize: isMobile
                  ? "var(--font-size-11)"
                  : "var(--font-size-14)",
                justifyContent: isMobile ? "flex-start" : "center",
              }}
              className={classes.grams}
            >
              gr
            </div>
          </div>
        </Box>
        <div
          style={{
            marginBottom: `${
              !isMobile &&
              (showSpecialPriceFieldForBuy || showSpecialPriceFieldForSell)
                ? "18px"
                : "35px"
            }`,
          }}
          className={classes.buttons}
        >
          <div
            className={
              isMobile ? classes.buttonContainerMobile : classes.buttonContainer
            }
          >
            {btnValues.map((value) => (
              <div key={value} className={classes.greyButton}>
                <CustomButton
                  type={
                    quantity === value
                      ? "secondaryBlack"
                      : type === "buy"
                      ? "gramBtnGrey"
                      : "gramBtnDarkGrey"
                  }
                  buttonText={currencyFormatter(value, "gr")}
                  width="100%"
                  height="100%"
                  onClick={() => handleChosenGrams(value)}
                />
              </div>
            ))}
          </div>
          <div className={classes.priceField}>
            <strong>Price&nbsp;</strong>

            {type === "buy"
              ? currencyFormatter(goldAskPrice, CURRENCY_TYPE) + "/1gr"
              : currencyFormatter(goldBidPrice, CURRENCY_TYPE) + "/1gr"}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor:
              type === "buy"
                ? !showSpecialPriceFieldForBuy
                  ? "#66E3BB26 !important"
                  : "#F7F7F7 !important"
                : !showSpecialPriceFieldForSell
                ? "#E3666626 !important"
                : "#F7F7F7 !important",
            border: "none !important",
          }}
          className={classes.totalCurrencyField}
        >
          <div className={classes.bullionzGoldContainer}>
            <div className={classes.bullionzGoldField}>
              <div style={{ fontWeight: "600" }}>Total:</div>
            </div>
          </div>
          <div
            className={classes.dynamicField}
            style={{
              fontSize: isMobile
                ? "var(--font-size-11)"
                : "var(--font-size-16)",
              color: "black",
            }}
          >
            {type === "buy"
              ? generateCurrencySymbol() + totalBuy.toFixed(2)
              : generateCurrencySymbol() + totalSell.toFixed(2)}
          </div>
        </Box>
        {(showSpecialPriceFieldForBuy || showSpecialPriceFieldForSell) && (
          <Box
            sx={{
              backgroundColor:
                type === "buy"
                  ? "#66E3BB26 !important"
                  : "#E3666626 !important",
              border: "none !important",
            }}
            className={classes.totalCurrencyField}
          >
            <div className={classes.bullionzGoldContainer}>
              <div>
                <img src={SpecialOfferIcon} alt="Special Offer Icon" />
              </div>
              <div className={classes.bullionzGoldField}>
                <div style={{ fontWeight: "600" }}>Your Price:</div>
              </div>
            </div>
            <div
              className={classes.dynamicField}
              style={{
                fontSize: isMobile
                  ? "var(--font-size-11)"
                  : "var(--font-size-16)",
                color: "black",
              }}
            >
              {type === "buy"
                ? generateCurrencySymbol() + totalBuyForGroup.toFixed(2)
                : generateCurrencySymbol() + totalSellForGroup.toFixed(2)}
            </div>
            <div className={classes.yourPriceTooltip}>
              {isMobile ? (
                <CustomButton
                  type="moreinfo"
                  buttonText="i"
                  tooltipTitle={
                    type === "buy"
                      ? tooltipContent.buyOrderSpecialPrice
                      : tooltipContent.closeOrderSpecialPrice
                  }
                />
              ) : (
                <IButton
                  tooltipTitle={
                    type === "buy"
                      ? tooltipContent.buyOrderSpecialPrice
                      : tooltipContent.closeOrderSpecialPrice
                  }
                />
              )}
            </div>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default TransactionPanel;

const useStyles = makeStyles((theme) => ({
  fieldTitle: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.9rem",
    marginBottom: "4px",
    fontSize: "var(--font-size-16)",
    fontWeight: "400",
    fontFamily: theme.typography.fontFamily,
    color: "var(--grey-secondary)",
    [theme.breakpoints.down("1400")]: {
      fontSize: "var(--font-size-13)",
    },
    [theme.breakpoints.down("1150")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("1060")]: {
      fontSize: "var(--font-size-10)",
    },
  },
  tooltipContainer: {
    position: "relative",
    left: "10px",
    bottom: "15px",
    [theme.breakpoints.down("1150")]: {
      bottom: "18px",
    },
  },
  tooltip: {
    position: "relative",
    left: "8px ",
    bottom: "24px",
  },
  field: {
    border: "0.5px solid #D2D5D8",
    boxShadow: "0px 3px 0px 0px #F7F7F7 inset",
    borderRadius: "3px",
    display: "flex",
    position: "relative",
    width: "100%",
    minHeight: "53px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "var(--font-size-16)",
    fontFamily: theme.typography.fontFamily,
    marginBottom: "10px",
    [theme.breakpoints.down("768")]: {
      minHeight: "35px",
    },
  },
  totalCurrencyField: {
    display: "flex",
    position: "relative",
    width: "100%",
    border: "0.5px solid #D2D5D8",
    borderRadius: "3px",
    alignItems: "center",
    fontSize: "var(--font-size-16)",
    backgroundColor: "white",
    fontFamily: theme.typography.fontFamily,
    height: "32px",
    marginBottom: "10px",
    [theme.breakpoints.down("768")]: {
      height: "35px",
    },
  },
  yourPriceTooltip: {
    position: "relative",
    left: "9px",
    bottom: "20px",
  },
  bullionzGoldContainerTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  bullionzTitleContainer: {
    flex: "68%",
    display: "flex",
    [theme.breakpoints.down("1730")]: {
      flex: "55%",
    },
    [theme.breakpoints.down("1189")]: {
      flex: "85%",
    },
    [theme.breakpoints.down("768")]: {
      flex: "110%",
    },
  },
  bullionzGoldContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "flex-start",
    left: "2%",
    width: "50%",
  },
  bullionzGoldField: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: "var(--font-size-14)",
    fontFamily: theme.typography.fontFamily,
    marginLeft: "4px",
    [theme.breakpoints.down("1400")]: {
      fontSize: "var(--font-size-12)",
    },
    [theme.breakpoints.down("1150")]: {
      fontSize: "var(--font-size-11)",
    },
    [theme.breakpoints.down("1060")]: {
      fontSize: "var(--font-size-10)",
    },
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "2%",
  },
  logo: {
    width: "19px",
  },
  dynamicField: {
    color: "var(--grey-secondary)",
    position: "relative",
    marginLeft: "auto",
    right: "7%",
    display: "flex",
    fontSize: "var(--font-size-15)",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("1400")]: {
      fontSize: "var(--font-size-13)",
    },
    [theme.breakpoints.down("1150")]: {
      fontSize: "0.7rem",
    },
  },
  customInput: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    color: "white",
    "& .MuiInputBase-input": {
      fontSize: "var(--font-size-22)",
      fontWeight: "400",
      textAlign: "right",
      [theme.breakpoints.down("1280")]: {
        fontSize: "var(--font-size-19)",
      },
      [theme.breakpoints.down("1058")]: {
        fontSize: "var(--font-size-16)",
      },
      [theme.breakpoints.down("840")]: {
        fontSize: "var(--font-size-14)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
  },
  grams: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: "2px",
    borderBottomRightRadius: "2px",
    width: "30px",
  },
  buttons: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    flexDirection: "row",
    top: "3px",
    overflow: "hidden",
    [theme.breakpoints.down("1880")]: {
      flexDirection: "column",
      overflow: "unset",
      top: "0px",
    },
  },
  priceField: {
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: theme.typography.fontFamily,
    color: "var(--grey-secondary)",
    [theme.breakpoints.down("1880")]: {
      alignSelf: "flex-end",
      marginTop: "0.3rem",
    },
    [theme.breakpoints.down("768")]: {
      alignSelf: "flex-end",
      fontSize: "var(--font-size-10)",
      fontWeight: "400",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "4%",
    [theme.breakpoints.down("1880")]: {
      width: "100%",
      order: "2",
    },
    [theme.breakpoints.down("1850")]: {
      gap: "3%",
    },
    [theme.breakpoints.down("1500")]: {
      gap: "4%",
    },
    [theme.breakpoints.down("1200")]: {
      marginTop: "21px",
      marginBottom: "5px",
    },
    [theme.breakpoints.down("768")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "12%",
      marginBottom: "2%",
    },
    "@media (orientation: landscape)": {
      [theme.breakpoints.down("768")]: {
        marginTop: "3%",
      },
    },
  },
  buttonContainerMobile: {
    display: "flex",
    flex: "1",
    gap: "4%",
    [theme.breakpoints.down("1880")]: {
      width: "100%",
      order: "2",
    },
    [theme.breakpoints.down("1850")]: {
      gap: "3%",
    },
    [theme.breakpoints.down("1500")]: {
      gap: "4%",
    },
    [theme.breakpoints.down("768")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "12%",
      marginBottom: "2%",
    },
    "@media (orientation: landscape)": {
      [theme.breakpoints.down("768")]: {
        marginTop: "3%",
      },
    },
  },
  greyButton: {
    width: "13%",
    height: "22px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("768")]: {
      width: "25.2%",
      height: "25px",
      paddingBottom: "4%",
    },
  },
  title: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: "600",
    fontSize: "var(--font-size-16)",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("973")]: {
      fontSize: "var(--font-size-16)",
    },
    [theme.breakpoints.down("955")]: {
      fontSize: "var(--font-size-14)",
    },
    [theme.breakpoints.down("865")]: {
      fontSize: "var(--font-size-12)",
    },
  },
  coinName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: 400,
    fontSize: "var(--font-size-14) !important",
    color: "#767676",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("955")]: {
      fontSize: "var(--font-size-14) !important",
    },
    [theme.breakpoints.down("865")]: {
      fontSize: "var(--font-size-13) !important",
    },
  },
  input: {
    flex: "20%",
    display: "flex",
    borderLeft: "1px solid #D2D5D8",
    [theme.breakpoints.down("1610")]: {
      flex: "22%",
    },
    [theme.breakpoints.down("1480")]: {
      flex: "27%",
    },
    [theme.breakpoints.down("1180")]: {
      flex: "33%",
    },
    [theme.breakpoints.down("932")]: {
      flex: "39%",
    },
    [theme.breakpoints.down("785")]: {
      flex: "42%",
    },
  },
  arrowButtonContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "53px",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "5%",
  },
  arrowButton: {
    margin: "58%",
    [theme.breakpoints.down("980")]: {
      width: "10px",
    },
  },
  inputField: {
    display: "flex",
    alignItems: "center",
  },
}));
