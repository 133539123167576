import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.fonts.css";
import IconButton from "@mui/material/IconButton";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import useId from "@mui/utils/useId/useId";
import Autocomplete from "@mui/material/Autocomplete";
import { CanadaStates } from "../../../../constants/CanadaStates";
import { redeemService } from "../../../../service/redeem";
import NotificationMessagePopup from "../NotificationMessagePopup/NotificationMessagePopup";
import LoadingBar from "../../LoadingBar/LoadingBar";
import { errorHandlingWrapper } from "../../../../helpers/errorHandlingWrapper";

const AddressFormWeb = ({
  handleFormSubmit,
  handleCancel,
  countries,
  products,
}) => {
  const classes = useStyles();

  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState({});
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const ID = useId();

  const checkZipCode = async (payload) => {
    setLoading(true);
    try {
      const response = await redeemService.calculateFees(payload, true);
      if (response) {
        return true;
      }
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);
      if (customError?.statusCode === 400) {
        return false;
      }
      console.error("An error occurred while fetching fees:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      ID,
      addressLine,
      city,
      countryName: country?.name,
      countryCode: country?.countryCode,
      shippingState: country?.name === "Canada" ? state?.code : null,
      zipCode,
    };

    const payload = {
      shippingInformation: {
        countryCode: country?.countryCode,
        zip: zipCode,
      },
      products: products,
    };

    try {
      const validZipCode = await checkZipCode(payload);
      if (validZipCode) {
        handleFormSubmit(formData);
      } else {
        setErrorPopup(true);
        setErrorMessage("Invalid Zip Code");
      }
    } catch (error) {
      console.error("An error occurred while checking zip code:", error);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.cancelBtn}>
        <IconButton onClick={handleCancel} aria-label="cancel">
          <HighlightOffOutlinedIcon className={classes.cancelIcon} />
          <div className={classes.cancelText}>Cancel</div>
        </IconButton>
      </div>
      <div className={classes.formTitle}>Add new address</div>
      <form onSubmit={handleSubmit}>
        <TextField
          className={classes.textField}
          label="Address Line 1"
          fullWidth
          required
          value={addressLine}
          onChange={(e) => setAddressLine(e.target.value)}
          autoComplete="off"
          inputProps={{
            maxLength: 120,
          }}
        />
        <TextField
          className={classes.textField}
          label="City"
          fullWidth
          required
          value={city}
          onChange={(e) => setCity(e.target.value)}
          autoComplete="off"
          inputProps={{
            maxLength: 50,
          }}
        />
        <Autocomplete
          id="country-select"
          options={countries}
          autoHighlight
          className={classes.textField}
          getOptionLabel={(option) =>
            option.name && option.countryCode
              ? `${option.name} (${option.countryCode})`
              : ""
          }
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.name} {"(" + option.countryCode + ")"}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="Country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
            />
          )}
          value={country}
          onChange={(event, newValue) => setCountry(newValue)}
        />
        {country?.name === "Canada" && (
          <Autocomplete
            id="state-select"
            options={CanadaStates}
            autoHighlight
            className={classes.textField}
            getOptionLabel={(option) =>
              option.name && option.code
                ? `${option.name} (${option.code})`
                : ""
            }
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name} {"(" + option.code + ")"}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="State"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
            onChange={(event, newValue) => setState(newValue)}
          />
        )}
        <TextField
          className={classes.textFieldZipCode}
          label="ZIP / Post Code"
          fullWidth
          required
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e?.key === "Enter") {
              e.target.blur();
            }
          }}
          inputProps={{
            maxLength: 20,
          }}
        />
        <Box className={classes.btnContainer} mt={1}>
          <Button className={classes.saveBtn} type="submit" variant="contained">
            Save
          </Button>
        </Box>
      </form>
      {errorPopup && (
        <NotificationMessagePopup
          type={"error"}
          isPopupOpen={errorPopup}
          setIsPopupOpen={() => setErrorPopup(false)}
          message={errorMessage}
        />
      )}
      <LoadingBar open={loading} />
    </div>
  );
};

export default AddressFormWeb;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "0.5px solid #D2D5D8",
    boxShadow: "0px 3px 0px 0px #F7F7F7 inset",
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    maxHeight: "20px",
    marginBottom: "5px",
    marginTop: "1%",
  },
  cancelIcon: {
    fontSize: "clamp(16px, 3vw, 24px)",
    color: "#767676",
    marginRight: "4px",
  },
  cancelText: {
    fontSize: "clamp(10px, 2vw, 14px)",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "600",
    color: "#767676",
  },
  formTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "clamp(14px, 2vw, 16px)",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "400",
    color: "#303030",
    paddingBottom: "1%",
  },
  textField: {
    "& .MuiInputLabel-root": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
      fontSize: "var(--font-size-16)",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      marginBottom: "10px",
      color: "#A0A0A0",
      maxHeight: "50px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CFCFCF",
    },
  },
  textFieldZipCode: {
    "& .MuiInputLabel-root": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
      fontSize: "var(--font-size-16)",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      marginBottom: "10px",
      color: "#A0A0A0",
      maxHeight: "50px",
      width: "50%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CFCFCF",
    },
  },
  btnContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "3%",
  },
  saveBtn: {
    backgroundColor: "black",
    borderRadius: "38px",
    fontSize: "clamp(12px, 2vw, 18px)",
    width: "clamp(30%, 5vw, 41%)",
    boxShadow: "0px 4px 0px 0px #00000026;",
    "&:hover": {
      backgroundColor: "black",
    },
  },
}));
