import clsx from 'clsx';
import { useEffect } from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomButton from '../../common/CustomButton/CustomButton';
import TransactionPanel from './TransactionPanel';
import { useAppContext } from '../../../context/DataContext';
import { useCryptoContext } from '../../../context/CryptoContext';
import NotificationMessagePopup from '../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import { messages } from '../../../constants/popupMessages';
import NonVerifiedUserPopUp from '../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import { NON_VERIFIED } from '../../../constants/globalconstants';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import { errorHandlingWrapper } from '../../../helpers/errorHandlingWrapper';
import brokerService from '../../../service/broker';

import CloseIcon from '@mui/icons-material/Close';
import isVerified from '../../../helpers/isVerified';

const BuySellCardCrypto = ({ onClose }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('1');
  const [fiatBuyPrice, setFiatBuyPrice] = useState();
  const [fiatSellPrice, setFiatSellPrice] = useState(0);
  const [cryptoBuyPrice, setCryptoBuyPrice] = useState(0);
  const [cryptoSellPrice, setCryptoSellPrice] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [sellingQuantity, setSellingQuantity] = useState(0);
  const [notificationPopupType, setNotificationPopupType] = useState('');
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const {
    isMobile,
    isAppView,
    isBuySellLoading,
    setIsBuySellLoading,
    nonVerifiedPopUpOpen,
    selectedCryptoAccount,
    isBrokerOrderConfirmed,
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
    setNonVerifiedPopUpOpen,
    setProceedBuyOrderClicked,
    setProceedSellOrderClicked,
    setIsBrokerOrderConfirmed,
  } = useAppContext();
  const { marketDetails, activeInstrument, setResetSearch } = useCryptoContext();

  const [message, setMessage] = useState('');
  const minAmount = marketDetails?.minAmount;
  const priceScale = marketDetails?.priceScale;
  const amountScale = marketDetails?.amountScale;
  let isDisabledBtn = !activeInstrument || !selectedCryptoAccount;
  const isMobileScreen = isMobile || isAppView;

  if (tabValue === '1') {
    isDisabledBtn = !Number(fiatBuyPrice) || !Number(cryptoBuyPrice);
  } else {
    isDisabledBtn = !Number(fiatSellPrice) || !Number(cryptoSellPrice);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCryptoBuyPrice(0?.toFixed(amountScale));
    setCryptoSellPrice(0?.toFixed(amountScale));
    setErrorMessage('');
  };

  useEffect(() => {
    setFiatBuyPrice(0?.toFixed(priceScale));
    setCryptoBuyPrice(0?.toFixed(amountScale));
    setFiatSellPrice(0?.toFixed(priceScale));
    setCryptoSellPrice(0?.toFixed(amountScale));
    setErrorMessage('');
  }, [activeInstrument]);

  useEffect(() => {
    setFiatBuyPrice((old) => Number(old)?.toFixed(priceScale));
    setCryptoBuyPrice((old) => Number(old)?.toFixed(amountScale));
    setFiatSellPrice((old) => Number(old)?.toFixed(priceScale));
    setCryptoSellPrice((old) => Number(old)?.toFixed(amountScale));
    setErrorMessage('');
  }, [marketDetails]);

  const handleBuyFiatInputChange = (event, price, isBackspace) => {
    let value = event.target.value;
    let splittedValue = value.toString().split('.');
    let hasMoreThanOneDot = splittedValue?.length > 2;

    if (value === 'reset' || price === 0) {
      setFiatBuyPrice(0?.toFixed(priceScale));
      setCryptoBuyPrice(0?.toFixed(amountScale));

      return;
    }

    const pattern = priceScale > 0 ? `^(?!00)\\d{0,20}(?:\\.\\d{0,${priceScale}})?$` : `^(?!00)\\d{0,20}?$`;
    const isPassingRegEx = new RegExp(pattern).test(value);

    if ((hasMoreThanOneDot || !isPassingRegEx) && !isBackspace) {
      return;
    }

    setFiatBuyPrice(value);
    setCryptoBuyPrice((Number(value) / price)?.toFixed(amountScale));
  };

  const handleBuyCyptoInputChange = (event, price, isBackspace) => {
    let value = event.target.value;
    let splittedValue = value.toString().split('.');
    let hasMoreThanOneDot = splittedValue?.length > 2;

    const pattern = amountScale > 0 ? `^(?!00)\\d{0,20}(?:\\.\\d{0,${amountScale}})?$` : `^(?!00)\\d{0,20}?$`;
    const isPassingRegEx = new RegExp(pattern).test(value);
    if ((hasMoreThanOneDot || !isPassingRegEx) && !isBackspace) {
      return;
    }

    setCryptoBuyPrice(value);

    if (!isBackspace) {
      setFiatBuyPrice((Number(value) * price)?.toFixed(priceScale));
    }
  };

  const handleSellFiatInputChange = (event, price, isBackspace) => {
    let value = event.target.value;
    let splittedValue = value.toString().split('.');
    let hasMoreThanOneDot = splittedValue?.length > 2;

    if (value === 'reset' || price === 0) {
      setFiatSellPrice(0?.toFixed(priceScale));
      setCryptoSellPrice(0?.toFixed(amountScale));

      return;
    }

    const pattern = priceScale > 0 ? `^(?!00)\\d{0,20}(?:\\.\\d{0,${priceScale}})?$` : `^(?!00)\\d{0,20}?$`;
    const isPassingRegEx = new RegExp(pattern).test(value);

    if ((hasMoreThanOneDot || !isPassingRegEx) && !isBackspace) {
      return;
    }

    setFiatSellPrice(value);
    setCryptoSellPrice((Number(value) / price)?.toFixed(amountScale));
  };

  const handleSellCryptoInputChange = (event, price, isBackspace) => {
    let value = event.target.value;
    let splittedValue = value.toString().split('.');
    let hasMoreThanOneDot = splittedValue?.length > 2;

    const pattern = amountScale > 0 ? `^(?!00)\\d{0,20}(?:\\.\\d{0,${amountScale}})?$` : `^(?!00)\\d{0,20}?$`;
    const isPassingRegEx = new RegExp(pattern).test(value);

    if ((hasMoreThanOneDot || !isPassingRegEx) && !isBackspace) {
      return;
    }

    setCryptoSellPrice(value);
    setFiatSellPrice((Number(value) * price)?.toFixed(priceScale));
  };

  const handlePlaceBuyOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    const result = await isVerified(setNonVerifiedPopUpOpen, setIsBuySellLoading);

    if (isDemo || result === false) {
      return;
    }

    const newBuy = {
      side: 'Buy',
      marketId: activeInstrument?.marketId,
      requestedAmount: Number(cryptoBuyPrice),
      brokerAccountId: selectedCryptoAccount?.brokerAccountId,
    };

    waitForTenSeconds();
    setIsBuySellLoading(true);
    try {
      await brokerService.createTradeOrder(newBuy);
      setMessage(messages.processingOrder);
      setProceedBuyOrderClicked(true);
      setNotificationPopupType('success');
      setIsBuySellLoading(false);
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);

      setMessage(customError?.message);
      setProceedBuyOrderClicked(true);
      setNotificationPopupType('error');
      setIsBuySellLoading(false);
    } finally {
      setResetSearch((prev) => !prev);
    }
  };

  const rejectOrderExecutionWaiting = () => {
    setMessage(messages.processingClose);
    setNotificationPopupType('error');
    setIsBuySellLoading(false);
    setProceedBuyOrderClicked(false);
    setProceedSellOrderClicked(false);
  };

  const waitForTenSeconds = () => {
    setTimeout(() => {
      if (!isBrokerOrderConfirmed) {
        rejectOrderExecutionWaiting();
      }
    }, 10000);
    setCryptoBuyPrice(0);
    setFiatBuyPrice(0);
    setCryptoSellPrice(0);
    setFiatSellPrice(0);
  };

  useEffect(() => {
    if (isBrokerOrderConfirmed) {
      setMessage(messages.orderSuccessful);
      setNotificationPopupType('success');
      setIsBrokerOrderConfirmed(false);
    }
  }, [isBrokerOrderConfirmed]);

  const handlePlaceSellOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);

    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setIsBuySellLoading);

    if (result === false) {
      return;
    }

    const newSell = {
      side: 'Sell',
      marketId: activeInstrument?.marketId,
      requestedAmount: Number(cryptoSellPrice),
      brokerAccountId: selectedCryptoAccount?.brokerAccountId,
    };

    waitForTenSeconds();
    setIsBuySellLoading(true);

    try {
      await brokerService.createTradeOrder(newSell);
      setProceedSellOrderClicked(true);
      setMessage(messages.processingOrder);
      setNotificationPopupType('success');
      setSellingQuantity(0);
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);

      setMessage(customError?.message);
      setProceedSellOrderClicked(true);
      setNotificationPopupType('error');
    } finally {
      setIsBuySellLoading(false);
      setResetSearch((prev) => !prev);
    }
  };

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <Box
      sx={{
        background: 'white',
        '& .MuiTabs-indicator': {
          backgroundColor: tabValue === '1' ? '#589E7D' : '#D14949',
          height: '4px',
        },
      }}
      className={classes.container}
    >
      <TabContext value={tabValue}>
        <Box className={clsx(isMobileScreen ? classes.mobileTabContainer : classes.tabContainer)}>
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              '& .MuiTab-root.Mui-selected': {
                color: 'black',
                fontWeight: '600',
              },
            }}
          >
            <Tab label="BUY" className={classes.tab} value="1" />

            <Tab label="SELL" className={classes.tab} value="2" />

            {isMobileScreen && (
              <div>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    if (onClose) onClose();
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </TabList>
        </Box>
        <TabPanel value="1">
          <TransactionPanel
            type="buy"
            cryptoPrice={cryptoBuyPrice}
            setCryptoBuyPrice={setCryptoBuyPrice}
            fiatPrice={fiatBuyPrice}
            setFiatBuyPrice={setFiatBuyPrice}
            errorMessage={errorMessage}
            handleFiatInputChange={handleBuyFiatInputChange}
            handleCryptoInputChange={handleBuyCyptoInputChange}
            minAmount={minAmount}
            priceScale={priceScale}
            amountScale={amountScale}
          />
        </TabPanel>

        <TabPanel value="2">
          <TransactionPanel
            type="sell"
            cryptoPrice={cryptoSellPrice}
            setCryptoSellPrice={setCryptoSellPrice}
            fiatPrice={fiatSellPrice}
            setFiatSellPrice={setFiatSellPrice}
            errorMessage={errorMessage}
            handleFiatInputChange={handleSellFiatInputChange}
            handleCryptoInputChange={handleSellCryptoInputChange}
            minAmount={minAmount}
            priceScale={priceScale}
            amountScale={amountScale}
          />
        </TabPanel>
      </TabContext>
      <div className={classes.buttonContainer}>
        <div className={classes.helperContainer}>
          <div className={classes.blackButton}>
            <CustomButton
              type="black"
              buttonText={tabValue === '1' ? 'Place Buy Order' : 'Place Sell Order'}
              width="100%"
              height="100%"
              onClick={tabValue === '1' ? handlePlaceBuyOrder : handlePlaceSellOrder}
              disabled={isDisabledBtn}
            />
          </div>
        </div>
      </div>
      {proceedBuyOrderClicked && (
        <NotificationMessagePopup
          showTitle={false}
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedBuyOrderClicked}
          setIsPopupOpen={setProceedBuyOrderClicked}
          message={message}
          handleCloseAutoInvestPopup={() => {
            setIsBrokerOrderConfirmed(false);
          }}
          isCrypto={true}
        />
      )}
      {proceedSellOrderClicked && (
        <NotificationMessagePopup
          showTitle={false}
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedSellOrderClicked}
          setIsPopupOpen={setProceedSellOrderClicked}
          message={message}
          handleCloseAutoInvestPopup={() => {
            setIsBrokerOrderConfirmed(false);
          }}
          isCrypto={true}
        />
      )}
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
          handleCloseAutoInvestPopup={closeNonVerifiedPopUp}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={isBuySellLoading} />
    </Box>
  );
};

export default BuySellCardCrypto;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    width: '100%',
    margin: 0,
    height: '100%',
    border: '0.5px #D2D5D8',
    borderRadius: '24px',

    '& .MuiTabPanel-root': {
      paddingTop: '7px',
      [theme.breakpoints.down('1500')]: {
        paddingTop: '5px',
      },
      [theme.breakpoints.down('1200')]: {
        paddingTop: 0,
        minHeight: '250px',
      },
      [theme.breakpoints.down('1600')]: {
        paddingBottom: '0.5rem',
        paddingLeft: '4%',
        paddingRight: '4%',
      },
    },
  },
  tabContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    borderTopRightRadius: '24px',
    borderTopLeftRadius: '24px',
  },

  mobileTabContainer: {
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 20px',

      maxWidth: '100%',
      paddingRight: '20px',

      '& button': {
        width: '100%',
      },
    },
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
  },
  helperContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '10px',
    [theme.breakpoints.down('768')]: {
      paddingLeft: '9%',
      paddingRight: '9%',
    },
  },
  blackButton: {
    width: '10rem',
    height: '10%',
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'inline-block',
    fontSize: '20px',
    [theme.breakpoints.down('1200')]: {
      width: '9rem',
      height: '10%',
    },
    [theme.breakpoints.down('768')]: {
      paddingTop: '0.8rem',
      width: '10.4rem',
      height: '32px',
      marginBottom: '14%',
    },
  },
  tab: {
    minWidth: '30px',
    width: '50%',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'var(--font-size-20)',
    [theme.breakpoints.down('1600')]: {
      fontSize: 'var(--font-size-19)',
    },
    [theme.breakpoints.down('1440')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('830')]: {
      fontSize: 'var(--font-size-14)',
      fontWeight: '600',
    },
    '& .css-n9z7pb-MuiButtonBase-root-MuiTab-root.Mui-selected': {
      color: 'black',
    },
    '& .MuiTab-root': {
      minWidth: '30px',
    },
    '& .MuiTabs-flexContainer': {
      paddingLeft: '1rem',
    },
    '& button': {
      fontFamily: 'Poppins',
    },
  },
}));
