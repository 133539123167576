export const updateBucket = async (
  isAppView,
  userDetails,
  setIsCartFetched,
  setProductsBucket,
  updateShoppingCart
) => {
  try {
    if (userDetails) {
      setIsCartFetched(false);
      const serverCart = userDetails?.shoppingCart;
      const bucketData = JSON.parse(localStorage.getItem("bucket")) || {};

      if (!serverCart && Object.keys(bucketData).length > 0) {
        await updateShoppingCart(bucketData);
      } else if (serverCart) {
        localStorage.setItem("bucket", JSON.stringify(serverCart));
        setProductsBucket(serverCart);
      }

      if (isAppView) {
        let counter = 0;

        if (bucketData) {
          let totalQuantity = 0;

          for (const productId in bucketData) {
            totalQuantity += bucketData[productId].quantity;
          }

          counter = totalQuantity;
        }

        window?.webkit?.messageHandlers?.notifyNative?.postMessage(
          `{"Event": "CartBadge", "Action": "${counter}"}`
        );
      }

      setIsCartFetched(true);
    }
  } catch (error) {
    console.log("error", error);
    return;
  }
};
