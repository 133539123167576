import * as React from "react";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.styles.css";
import "../../../../styles/global.fonts.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import GoldenBar from "../../../../assets/images/gold-bar.svg";
import currencyFormatter from "../../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../../constants/globalconstants";
import CustomSpinner from "../../../common/CustomSpinner/CustomSpinner";

const ThirdStepContent = ({
  chosenAddress,
  quantity,
  serviceFee,
  deliveryFee,
  total,
  isRedeemFeeLoading,
  showTBC,
}) => {
  const classes = useStyles();
  const isDemo = sessionStorage.getItem("isDemo") === "true";

  const addressArr = [
    chosenAddress.addressLine,
    chosenAddress.city,
    chosenAddress.shippingState,
    chosenAddress.countryName,
    chosenAddress.zipCode,
  ];

  const addressString = addressArr
    .filter((item) => item)
    .join(", ")
    .trim();

  return (
    <div className={classes.container}>
      <div className={classes.orderTitle}>Your Order</div>
      <div className={classes.orderContent}>
        <Box className={classes.field}>
          <div className={classes.goldContainer}>
            <div className={classes.logoContainer}>
              <img
                src={GoldenBar}
                alt="Golden bar"
                className={classes.imageGoldenBar}
              />
            </div>

            <div className={classes.goldField}>
              <Box className={classes.title}>Physical PAMP Gold</Box>
            </div>
          </div>
          <div className={classes.dynamicField}>
            <TextField
              className={classes.customInput}
              type="number"
              id="filled-size-small"
              value={quantity}
              disabled
              size="small"
              inputProps={{
                inputMode: "numeric",
                type: "number",
                pattern: "[0-9]*",
                step: "1",
                min: "0",
                max: "99999",
              }}
              onKeyDown={(event) => {
                var value = event.target.value;
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "." ||
                  event?.key === "e" ||
                  event?.key === "," ||
                  (value === "" && event?.key === "0") ||
                  (value === "0" && event?.key === "0") ||
                  (value.length >= 5 &&
                    event?.key !== "Backspace" &&
                    event?.key !== "Delete" &&
                    event?.key !== "ArrowLeft" &&
                    event?.key !== "ArrowRight")
                ) {
                  event.preventDefault();
                }
              }}
              sx={{
                "& .MuiInputBase-input": {
                  fontWeight: "400",
                  textAlign: "right",
                  paddingRight: "3px",
                  fontSize: "var(--font-size-12)",
                  color: "#767676",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderStyle: "none",
                },
                "& .Mui-error": {
                  paddingLeft: "42px",
                },
              }}
            />
            <Box className={classes.gr}> gr</Box>
          </div>
        </Box>
      </div>
      <div className={classes.orderDetails}>
        <p>
          <strong>Address </strong>
          {addressString}
        </p>
        {showTBC ? (
          <p style={{ display: "flex", gap: 4 }}>{"TBC"}</p>
        ) : (
          <p style={{ display: "flex", gap: 4 }}>
            <strong>Service Fee </strong>
            {isRedeemFeeLoading ? (
              <CustomSpinner />
            ) : serviceFee === 0 ? (
              "TBC"
            ) : (
              currencyFormatter(serviceFee, CURRENCY_TYPE)
            )}
          </p>
        )}
        {showTBC ? (
          <p style={{ display: "flex", gap: 4 }}>
            <strong>Delivery Fee</strong> {"TBC"}
          </p>
        ) : (
          <p style={{ display: "flex", gap: 4 }}>
            <strong>Delivery Fee</strong>{" "}
            {isRedeemFeeLoading ? (
              <CustomSpinner />
            ) : deliveryFee === 0 ? (
              "TBC"
            ) : (
              currencyFormatter(deliveryFee, CURRENCY_TYPE)
            )}
          </p>
        )}
        {showTBC ? (
          <p style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <strong>Total </strong>
            {"TBC"}
          </p>
        ) : (
          <p style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <strong>Total </strong>
            {isRedeemFeeLoading ? (
              <CustomSpinner />
            ) : total === 0 ? (
              "TBC"
            ) : (
              <span className={classes.totalValue}>
                {currencyFormatter(total, CURRENCY_TYPE)}
              </span>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default ThirdStepContent;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  orderTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "var(--font-size-15)",
    fontWeight: "400",
    fontFamily: theme.typography.fontFamily,
    color: "black",
  },
  imageGoldenBar: {
    cursor: "pointer",
  },
  orderContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  field: {
    border: "0.5px solid #D2D5D8",
    boxShadow: "0px 3px 0px 0px #F7F7F7 inset",
    borderRadius: "3px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    fontSize: "var(--font-size-16)",
    fontFamily: theme.typography.fontFamily,
  },
  goldContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "flex-start",
    left: "2%",
    width: "50%",
  },
  logoContainer: {
    marginRight: "0.5rem",
  },
  goldField: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: "var(--font-size-14)",
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    fontWeight: "600",
    fontSize: "var(--font-size-10)",
    fontFamily: theme.typography.fontFamily,
  },
  dynamicField: {
    color: "var(--grey-secondary)",
    position: "relative",
    marginLeft: "auto",
    right: "2%",
    display: "flex",
    fontSize: "var(--font-size-15)",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
  },
  customInput: {
    borderLeft: "1px solid #EAEAEA",
    "& input": {
      width: "30px",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    fontSize: "var(--font-size-16)",
    fontFamily: theme.typography.fontFamily,
    "& .Mui-disabled": {
      "-webkitTextFillColor": "#767676",
    },
  },
  gr: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-10)",
    fontWeight: "400",
    color: "#767676",
  },
  orderDetails: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-10)",
    fontWeight: "400",
    color: "black",
    "& p": {
      margin: "4px 0",
    },
  },
  totalValue: {
    fontSize: "var(--font-size-13)",
    color: "#347362",
    fontWeight: "700",
  },
}));
