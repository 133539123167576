import React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useCallback } from "react";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../constants/globalconstants";
import { formatDateWrapper } from "../../../helpers/dateFormatter";
import { handleGetUserCrmTransactions } from "../../../helpers/handleGetUserCrmTransactions";
import GreenArrowDown from "../../../assets/images/green-arrow-down.svg";
import RedArrowUp from "../../../assets/images/red-arrow-up.svg";
import { useAppContext } from "../../../context/DataContext";
import { generateCurrencySymbol } from "../../../helpers/generateCurrencySymbol";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--mobile-grey)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const generateRows = (amount) => {
  const currentDate = new Date().toDateString();

  return Array.from(Array(amount)).map((_, i) => ({
    id: i + 1,
    date: currentDate,
    currency: "EUR",
    status: "APPROVED",
    amount: Math.floor(Math.random() * 50) + 1,
  }));
};

function NoHistoryRow() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell
          colSpan={4} // Span across all columns
          component="th"
          scope="row"
          align="center"
        >
          No Transactions History
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const classes = useStyles();

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  function removeFirstSymbol(formattedCurrency) {
    if (typeof formattedCurrency === "string" && formattedCurrency.length > 1) {
      return formattedCurrency.slice(1);
    }
    return formattedCurrency;
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={classes.tableRow}
      >
        <StyledTableCell
          component="th"
          scope="row"
          sx={{ textAlign: "center" }}
        >
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -12],
                    },
                  },
                ],
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={row.comment}
              placement="top"
              arrow
            >
              <img
                onClick={handleTooltipOpen}
                className={classes.icon}
                src={
                  row.transactionType === "Deposit"
                    ? GreenArrowDown
                    : RedArrowUp
                }
                alt="Transaction type"
              />
            </Tooltip>
          </ClickAwayListener>
        </StyledTableCell>
        <StyledTableCell
          component="th"
          scope="row"
          sx={{ width: "40%", textAlign: "center" }}
        >
          {formatDateWrapper(row?.date, "long") || ""}
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ width: "30%" }}>
          {generateCurrencySymbol(row?.currency)}
          {row?.amount}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "10%" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon style={{ color: "#347362" }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "#347362" }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <StyledTableRow className={classes.tableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className={classes.firstStyledTableCell}
                  >
                    Transaction
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center" }}
                    className={classes.firstStyledTableCell}
                  >
                    Account
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "right", paddingRight: "40px" }}
                    className={classes.firstStyledTableCell}
                  >
                    {row?.id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.tradeAccountId}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function MobileProfileTransactionHistoryTable() {
  const maxRows = 57;

  const classes = useStyles();
  const { refetch } = useAppContext();

  const tableEl = useRef();
  const [rows, setRows] = useState(generateRows(10));
  const [loading, setLoading] = useState(false);
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [hasMore, setHasMore] = useState(rows.length < maxRows);
  const [error, setError] = useState(false);

  useEffect(() => {
    handleGetUserCrmTransactions(setAccountTransactions, setLoading);
  }, [refetch]);

  //   useEffect(() => {
  //     handleGetMobileTransactions(
  //       setTransactions,
  //       page,
  //       20,
  //       setAllPages,
  //       setError
  //     );
  //     if (transactions.length === 0) {
  //       setLoading(true);
  //     }
  //   }, [page]);

  //   useEffect(() => {
  //     if (transactions.length || error) {
  //       setLoading(false);
  //     }
  //   }, [transactions, error]);
  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          const amount = rows.length + 7;
          const newRows = generateRows(amount > maxRows ? maxRows : amount);
          setRows(newRows);
          setLoading(false);
          setHasMore(newRows.length < maxRows);
          resolve();
        }, 1500)
      );
    };
    setLoading(true);
    loadItems();
  }, [rows]);
  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);
  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener("scroll", scrollListener);
    return () => {
      tableRef.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  return (
    <Box className={classes.container}>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ margin: "auto", maxHeight: "100%" }}
        ref={tableEl}
      >
        {loading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", zIndex: 2 }}
          />
        )}
        {accountTransactions ? (
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead className={classes.tableHead}>
              <StyledTableRow className={classes.tableRow}>
                <StyledTableCell
                  component="th"
                  className={classes.headerCell}
                  sx={{ textAlign: "center" }}
                ></StyledTableCell>
                <StyledTableCell
                  component="th"
                  className={classes.headerCell}
                  sx={{ textAlign: "center" }}
                >
                  Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  component="th"
                  className={classes.headerCell}
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  className={classes.headerCell}
                />
              </StyledTableRow>
            </TableHead>
            {accountTransactions.length === 0 ? (
              <TableBody className={classes.containerTableBody}>
                <NoHistoryRow />
              </TableBody>
            ) : (
              <TableBody className={classes.containerTableBody}>
                {accountTransactions.map((row, index) => (
                  <Row key={index} row={row} />
                ))}
              </TableBody>
            )}
          </Table>
        ) : (
          <></>
        )}
      </TableContainer>
    </Box>
  );
}

export default MobileProfileTransactionHistoryTable;

const useStyles = makeStyles((theme) => ({
  containerTableBody: {
    position: "relative",
    overflowY: "auto",
  },
  headerCell: {
    backgroundColor: "var(--mobile-grey)",
    zIndex: 2,
    height: "36px",
  },
  container: {
    "& .MuiTableCell-root.MuiTableCell-body": {
      fontSize:
        "clamp(var(--font-size-11), var(--font-size-12), var(--font-size-13)) !important",
    },
    height: "100%",
    "@media (orientation: landscape)": {
      height: "100%",
      maxHeight: "100%",
    },
  },
  tableHead: {
    position: "relative",
  },
  tableRow: {
    "& .MuiTableCell-root": {
      padding: "0px",
      maxHeight: "30px",
    },
    "& th": {
      fontSize:
        "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important",
    },
  },
  firstStyledTableCell: {
    textAlign: "center",
    [theme.breakpoints.up("500")]: {
      textAlign: "center",
    },
    width: "50%",
  },
  closeButton: {
    color: "var(--black)",
    borderRadius: "24px",
    border: "1px solid var(--red)",
    width: "70%",
    maxWidth: "5rem",
    minHeight: "15px",
    height: "1.7rem",
    marginBottom: "1.5rem",
    backgroundColor: "var(--white)",
    fontSize:
      "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important",
  },
  actionButtons: {
    position: "absolute",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    left: 0,
    width: "100%",
    top: "65%",
  },
  noTrans: {
    width: "100%",
    textAlign: "center",
    fontSize: "16px",
    [theme.breakpoints.down("1180")]: {
      fontSize: "var(--font-size-13) !important",
    },
    [theme.breakpoints.down("1119")]: {
      fontSize: "var(--font-size-12) !important",
    },
    [theme.breakpoints.down("950")]: {
      fontSize: "var(--font-size-9) !important",
    },
    [theme.breakpoints.down("880")]: {
      fontSize: "var(--font-size-8) !important",
    },
  },
}));
