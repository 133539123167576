import { Box, Grid } from "@mui/material";
import React from "react";
import StakingNavigation from "../../../components/WebComponents/StakingNavigation/StakingNavigation";
import StakeVaultCard from "../../../components/common/StakeVaultCard/StakeVaultCard";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import TotalBalanceInStaking from "../../../components/WebComponents/TotalBalanceInStaking/TotalBalanceInStaking";
import VaultingHistory from "../../../components/WebComponents/Vaulting/VaultingHistory/VaultingHistory";
import BullionzCertificates from "../../../components/WebComponents/BullionzCertificates/BullionzCertificates";
import GoldHistory from "../../../components/WebComponents/GoldHistory/GoldHistory";
import { coinName } from "../../../constants/globalconstants";
import WebLayout from "../../../components/common/Layout/WebLayout";

function WebVaulting() {
  const classes = useStyles();

  const isInVaulting = true;
  return (
    <WebLayout>
      <Box>
        <Box sx={{ marginBottom: 1, marginLeft: 2, marginRight: 2 }}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
              <Grid container>
                <Grid
                  container
                  alignItems="stretch"
                  direction="row"
                  marginBottom="1rem"
                  marginTop="0px"
                  spacing={"16px"}
                >
                  <Grid item xs={5} md={5} sm={5} xl={5} lg={5}>
                    <TotalBalanceInStaking
                      leftText={"Coins in Vaulting"}
                      rightText={"Value of Vaulted Coins"}
                      type={"vaulting"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    md={7}
                    sm={7}
                    xl={7}
                    lg={7}
                    style={{ height: "100%" }}
                  >
                    <StakeVaultCard type="vault" />
                  </Grid>
                </Grid>
                <Grid container alignItems="stretch" direction="row">
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    xl={12}
                    lg={12}
                    style={{ marginBottom: 12, height: "100%" }}
                  >
                    <VaultingHistory containerClassName={classes.container} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing="16px"
                  alignItems="stretch"
                  className={classes.downWrapper}
                >
                  <Grid
                    item
                    xs={4}
                    md={4}
                    sm={4}
                    xl={4}
                    lg={4}
                    className={classes.gridOfTheGoldHistory}
                  >
                    <GoldHistory title={coinName} isInVaulting={isInVaulting} />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={8}
                    sm={8}
                    xl={8}
                    lg={8}
                    style={{ height: "100%" }}
                  >
                    <Box className={classes.bulionzCertificates}>
                      <BullionzCertificates
                        containerClassName={classes.containerCertificates}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 1.5,
            marginBottom: 1.5,
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <Grid container>
            <Grid item xs={6} md={6} sm={6} xl={6} lg={6}>
              <StakingNavigation />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </WebLayout>
  );
}

export default WebVaulting;

const useStyles = makeStyles((theme) =>
  createStyles({
    gridOfTheGoldHistory: {
      height: "calc(100% - 32px)",
    },
    downWrapper: {
      [theme.breakpoints.up("2000")]: {
        minHeight: "550px",
      },
      [theme.breakpoints.down("2000")]: {
        minHeight: "470px",
      },
      [theme.breakpoints.down("1500")]: {
        minHeight: "360px",
      },
      [theme.breakpoints.down("1300")]: {
        minHeight: "320px",
      },
      [theme.breakpoints.down("1100")]: {
        minHeight: "300px",
      },
      [theme.breakpoints.down("1000")]: {
        minHeight: "280px",
      },
    },
    bulionzCertificates: {
      height: "100%",
      background: "var(--floral-white)",
      border: "2px",
      boxShadow: "0px 21px 43px 0px #0000001A;",
      borderRadius: "24px",
    },
    container: {
      width: "100%",
      backgroundColor: "white",
      height: "17.75rem",
      border: "1.41px",
      borderRadius: "24px",
      background: "linear-gradient(180deg, #E0E0E099, #FFFFFF,#FFFFFF,#FFFFFF)",
      boxShadow: "0px 20.772722244262695px 42.53462219238281px 0px #0000001A",
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
        height: "2px",
        width: "170px !important",
        left: "28px !important",
        [theme.breakpoints.down("1200")]: {
          width: "160px !important",
        },
        [theme.breakpoints.down("1150")]: {
          width: "150px !important",
        },
        [theme.breakpoints.down("1000")]: {
          width: "140px !important",
        },
        [theme.breakpoints.down("800")]: {
          width: "130px !important",
        },
      },
    },
    containerCertificates: {
      width: "100%",
      borderRadius: "24px",
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
        height: "3px",
        width: "182px !important",
        left: "22px !important",
      },
    },
  })
);
