import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  createStyles,
} from "@mui/material";
import background from "../../../assets/images/login-background.jpg";
import BullionzLogo from "../../../assets/images/bullionz-logo.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { loginService } from "../../../service/login";
import LoadingBar from "../../../components/common/LoadingBar/LoadingBar";
import { routes } from "../../../routes";
import ForgotPasswordPopup from "../ForgotPassword/ForgotPasswordPopup";
import RegisterPopup from "../Register/RegisterPopup";
import { useAppContext } from "../../../context/DataContext";
import { userService } from "../../../service/user";

function Login({ signup }) {
  const classes = useStyles();
  const { setIsLoggedIn } = useAppContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [forgotPasswordPopupOpen, setForgotPasswordPopupOpen] = useState(false);
  const [registerPopupOpen, setRegisterPopupOpen] = useState(false);
  const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isSignupParam = params.get("signup");
    if (isSignupParam) {
      setRegisterPopupOpen(true);
    } else if (signup) {
      let newUrlWithParams = "/login?signup=true";
      for (const [key, value] of params.entries()) {
        newUrlWithParams += `&${key}=${encodeURIComponent(value)}`;
      }
      window.location.href = newUrlWithParams;
    }
  }, [signup]);

  useEffect(() => {
    const hasAccessToken = localStorage.getItem("accessToken");
    if (hasAccessToken) {
      navigate(routes.dashboard);
    }
  }, [navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    const loginCredentials = {
      username: email,
      password: password,
    };
    setLoading(true);
    try {
      const login = await loginService.getUserToken(loginCredentials);
      const { accessToken, accessTokenExp } = login;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("accessTokenExp", accessTokenExp);
      setIsLoggedIn(true);
      setEmail("");
      setPassword("");
      setError("");
      navigate(routes.dashboard);
    } catch (error) {
      const errorMessageMatch = error.message.match(/"message":"([^"]+)"/);
      if (errorMessageMatch && errorMessageMatch.length > 1) {
        const errorMessage = errorMessageMatch[1];
        setError(errorMessage);
      } else {
        console.error(error.message);
        setError("Email or password are incorrect.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setForgotPasswordPopupOpen(true);
  };

  const handleTryDemo = () => {
    const baseUrl = window.location.origin;
    window.location.href = `${baseUrl}/#demo`;
  };

  const handleRegister = () => {
    setRegisterPopupOpen(true);
  };

  async function receiveMessage(event) {
    if (event.origin.toLowerCase() !== `${process.env.REACT_APP_CRM_BASE_URL}`)
      return;
    try {
      const eventData = JSON.parse(event.data);
      if (eventData != null && eventData.Event === "EndRegistration") {
        const accessToken = eventData.AccessToken;
        const refreshToken = eventData.RefreshToken;
        const levelName = eventData.verification_level;
        const externalUserId = eventData.external_user_id;

        localStorage.setItem("accessToken", accessToken);

        const resp = await userService.register({
          levelName,
          refreshToken,
          externalUserId,
        });

        localStorage.setItem("accessToken", resp.accessToken);
        localStorage.setItem("accessTokenExp", resp.accessTokenExp);

        setIsLoggedIn(true);
        setEmail("");
        setPassword("");
        setError("");

        navigate(routes.dashboard);
      }else if(eventData != null && eventData.Event === "OpenUrl") {
        window.open(eventData.Url);
      }
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    console.log("Message Listener Attached");
    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  useEffect(() => {
    window.receiveMessage = receiveMessage;
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.loginContainer}>
        <div className={classes.logoContainer}>
          <img
            src={BullionzLogo}
            className={classes.logo}
            alt="Bullionz logo"
          />
        </div>
        <div className={classes.quoteContainer}>
          <span className={classes.quote}>
            Smart money. Real <span style={{ color: "#F6D03F" }}>gold</span>
          </span>
        </div>
        <div className={classes.welcome}>Welcome</div>
        <form style={{ width: "100%" }} onSubmit={(e) => e.preventDefault()}>
          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              margin="normal"
              required
              fullWidth
              id="email"
              label={isEmailInputFocused || email !== "" ? "" : "Email"}
              name="email"
              autoComplete="email"
              value={email}
              onFocus={() =>
                setIsEmailInputFocused((prevIsFocused) => !prevIsFocused)
              }
              onBlur={() =>
                setIsEmailInputFocused((prevIsFocused) => !prevIsFocused)
              }
              onChange={(e) => handleEmailChange(e)}
            />
            <TextField
              className={classes.input}
              margin="normal"
              required
              fullWidth
              value={password}
              name="password"
              label={
                isPasswordInputFocused || password !== "" ? "" : "Password"
              }
              type={showPassword ? "text" : "password"}
              onChange={(e) => handlePasswordChange(e)}
              id="password"
              autoComplete="current-password"
              onFocus={() =>
                setIsPasswordInputFocused((prevIsFocused) => !prevIsFocused)
              }
              onBlur={() =>
                setIsPasswordInputFocused((prevIsFocused) => !prevIsFocused)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          sx={{
                            color: "#B7B7B7",
                          }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{
                            color: "#B7B7B7",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {error && <div className={classes.errorContainer}>{error}</div>}
          <div className={classes.forgotPass}>
            <div onClick={handleForgotPassword}>
              <LockOutlinedIcon />
              Forgot your Password?
            </div>
            <div style={{ cursor: "pointer" }} onClick={handleTryDemo}>
              Try Demo?
            </div>
          </div>
          <div className={classes.loginBtn}>
            <Button
              size="large"
              className={classes.button}
              variant="contained"
              onClick={handleLogin}
              type="submit"
            >
              LOGIN
            </Button>
          </div>
        </form>
        <div className={classes.register}>
          <div onClick={handleRegister}>FIRST TIME HERE? SIGN UP</div>
        </div>
      </div>
      {loading && <LoadingBar open={loading} />}
      {forgotPasswordPopupOpen && (
        <ForgotPasswordPopup
          isPopupOpen={forgotPasswordPopupOpen}
          setIsPopupOpen={setForgotPasswordPopupOpen}
        />
      )}
      {registerPopupOpen && (
        <RegisterPopup
          isPopupOpen={registerPopupOpen}
          setIsPopupOpen={setRegisterPopupOpen}
        />
      )}
    </div>
  );
}

export default Login;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: theme.typography.fontFamily,
    },
    loginContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "95vh",
      width: "45%",
      [theme.breakpoints.down("1200")]: {
        width: "65%",
      },
      [theme.breakpoints.down("768")]: {
        position: "relative",
        width: "92%",
      },
    },
    errorContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      color: "red",
    },
    logoContainer: {
      display: "flex",
      position: "relative",
      bottom: "80px",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "@media (max-height: 678px)": {
        bottom: "15px",
      },
      "@media (max-height: 391px)": {
        bottom: "unset",
      },
    },
    logo: {
      width: "69%",
      height: "auto",
      [theme.breakpoints.down("500")]: {
        width: "85%",
      },
      "@media (max-height: 578px)": {
        width: "200px",
      },
    },
    quoteContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      position: "relative",
      bottom: "80px",
      "@media (max-height: 678px)": {
        bottom: "24px",
      },
      "@media (max-height: 391px)": {
        bottom: "unset",
      },
    },
    quote: {
      color: "white",
      fontSize: `clamp(14px, 3vw, 23px)`,
      fontWeight: "500",
      fontFamily: theme.typography.fontFamily,
      "@media (max-height: 578px)": {
        fontSize: `clamp(2px, 3vw, 13px)`,
      },
    },
    welcome: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      bottom: "30px",
      width: "100%",
      color: "white",
      fontSize: `clamp(24px, 3vw, 36px)`,
      fontFamily: theme.typography.fontFamily,
      fontWeight: "600",
      "@media (max-height: 678px)": {
        bottom: "unset",
      },
      "@media (max-height: 578px)": {
        fontSize: `clamp(6px, 3vw, 21px)`,
      },
    },
    inputContainer: {
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      width: "100%",
    },
    input: {
      alignItems: "center",
      boxShadow: "0px 6.630250453948975px 0px 0px #F7F7F7 inset",
      background: "white",
      borderRadius: "5px",
      "& .MuiOutlinedInput-root": {
        height: "60px",
        "@media (max-height: 578px)": {
          height: "50px",
        },
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D2D5D8",
        boxShadow: "0px 6.630250453948975px 0px 0px white inset",
      },
      "& .MuiInputLabel-root": {
        fontSize: "18px",
        fontWeight: "400",
        color: "#B7B7B7",
        fontFamily: theme.typography.fontFamily,
        "@media (max-height: 578px)": {
          fontSize: "14px",
        },
      },
      "& input::-ms-reveal, & input::-ms-clear": {
        display: "none",
      },
    },
    forgotPass: {
      display: "flex",
      position: "relative",
      top: "26px",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      fontSize: `clamp(12px, 3vw, 16px)`,
      "@media (max-height: 578px)": {
        fontSize: `clamp(8px, 3vw, 13px)`,
      },
      fontFamily: theme.typography.fontFamily,
      color: "white",
      fontWeight: "400",
      "& > :first-child": {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
          color: "#dcdcdc",
        },
        "& > :first-child": {
          fontSize: `clamp(25px, 3vw, 30px)`,
          marginRight: "12px",
          "@media (max-height: 578px)": {
            fontSize: `clamp(11px, 3vw, 18px)`,
          },
        },
      },
      "@media (max-height: 678px)": {
        top: "unset",
      },
    },
    loginBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      top: "50px",
      width: "100%",
      "@media (max-height: 678px)": {
        top: "14px",
      },
      "@media (max-height: 602px)": {
        top: "0px",
      },
      "@media (max-height: 391px)": {
        top: "unset",
      },
    },
    button: {
      backgroundColor: "#F6D03F",
      color: "black",
      width: "35%",
      height: "50px",
      borderRadius: "60px",
      fontSize: "16px",
      fontFamily: theme.typography.fontFamily,
      fontWeight: "600",
      "&:hover": {
        backgroundColor: "#f7c70d",
      },
      [theme.breakpoints.down("768")]: {
        width: "100%",
      },
      "@media (max-height: 578px)": {
        width: "25%",
        height: "30px",
        fontSize: "12px",
      },
    },
    register: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      top: "60px",
      width: "100%",
      fontSize: "16px",
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
      marginBottom: "1rem",
      color: "white",
      "& > :first-child": {
        color: "white",
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
          color: "#dcdcdc",
        },
      },
      "@media (max-height: 678px)": {
        top: "35px",
      },
      "@media (max-height: 620px)": {
        top: "25px",
      },
      "@media (max-height: 602px)": {
        top: "5px",
        fontSize: "12px",
      },
      "@media (max-height: 391px)": {
        top: "unset",
      },
      "@media (max-height: 578px)": {
        fontSize: "10px",
      },
    },
  })
);
