import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import LoadingBar from "../../../components/common/LoadingBar/LoadingBar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RegisterPopup = ({ isPopupOpen, setIsPopupOpen }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  let suffix = "";

  const params = new URLSearchParams(window.location.search);

  for (const [key, value] of params.entries()) {
    if (key === "signup") {
      continue;
    }
    if (suffix === "") {
      suffix += "?";
    } else {
      suffix += "&";
    }
    suffix += `${key}=${encodeURIComponent(value)}`;
  }

  const iframeUrl = `${process.env.REACT_APP_CRM_BASE_URL}/RegisterRealSteps/Step1${suffix}`;

  const handleClose = () => {
    setIsPopupOpen(false);
  };
  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
        // disableEscapeKeyDown
      >
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: "2000",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {loading && <LoadingBar open={loading} />}
        <iframe
          title="Register"
          src={iframeUrl}
          width="100%"
          height="650px"
          frameBorder="0"
          style={{
            top: "11%",
            left: "0",
            bottom: "0",
            right: "0",
            width: "100%",
            height: "670px",
            border: "none",
            margin: "0",
            padding: "0",
            overflow: "hidden",
            zIndex: "1000",
          }}
          onLoad={() => setLoading(false)}
        />
      </BootstrapDialog>
    </div>
  );
};

export default RegisterPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backdropFilter: "blur(5px)",
    "& .MuiDialog-container": {
      width: "100%",
    },
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      width: "100%",
    },
  },
}));
