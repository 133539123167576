import React from 'react';
import { Box } from '@mui/material';

import { useStyles } from '../CryptoAssets';
import IButton from '../../../common/IButton/IButton';
import { tooltipContent } from '../../../../constants/tooltipTexts';

const AssetsHeader = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.title}>
        <Box className={classes.heading}>
          <span className={classes.portfolioText}>CRYPTO ASSETS</span>

          <div className={classes.iButtonContainer}>
            <IButton tooltipTitle={tooltipContent.cryptoAssets} />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default AssetsHeader;
