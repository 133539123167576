import { fetchWrapper } from '../../utils/fetchWrapper';

const getLiveGoldPrice = async () => {
  try {
    return await fetchWrapper.get(``); //  API endpoint for live gold prices
  } catch (error) {
    throw new Error();
  }
};

const getGoldHistoryPriceForPeriod = async (period) => {
  try {
    return await fetchWrapper.get(`/gold-price/history?period=${period}`);
  } catch (error) {
    throw new Error(error);
  }
};

const getLatestAvailableEURPrice = async () => {
  try {
    return await fetchWrapper.get(`/gold-price/latest-eur-price`);
  } catch (error) {
    throw new Error(error);
  }
};

const goldPriceService = {
  getLiveGoldPrice,
  getGoldHistoryPriceForPeriod,
  getLatestAvailableEURPrice,
};

export default goldPriceService;
