import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/index.js';
import { makeStyles } from '@mui/styles';
import { userNotifications } from '../../../service/notifications';
import { useAppContext } from '../../../context/DataContext';
import { useNotificationsContext } from '../../../context/NotificationsContext';

import { Box, Pagination, Tab, Tabs } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';

import LoadingBar from '../../../components/common/LoadingBar/LoadingBar';
import WebLayout from '../../common/Layout/WebLayout';
import CustomButton from '../../common/CustomButton/CustomButton';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup.js';
import UserNotificationPopup from '../../../components/common/Popups/UserNotificationPopup/UserNotificationPopup';
import SystemMessageIcon from '../../../assets/images/system-message-notification-icon.svg';
import NotificationMessageIcon from '../../../assets/images/notification-message-icon.svg';
import { formatDistanceToNowStrict } from 'date-fns';

function NotificationsWeb() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { notifications, setNotifications } = useNotificationsContext();
  const { unreadNotificationsCounter, setUnreadNotificationsCounter } = useAppContext();
  const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    notificationId: null,
    icon: '',
    title: '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState();

  const fetchUserNotifications = async () => {
    setLoading(true);
    try {
      const fetchedNotifications = await userNotifications.getUserNotifications({ page, pageSize });

      setNotifications(fetchedNotifications.notifications);
      setAllPages(Number(fetchedNotifications?.pages));
      setError('');
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError('Error fetching user notifications');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserNotifications();
  }, [page]);

  const handleNotificationClicked = async (notificationId, type, title, message) => {
    setLoading(true);
    try {
      await userNotifications.updateUserNotifications({
        notificationId,
      });
      const updatedNotifications = notifications.map((notification) => {
        if (notification.id === notificationId) {
          return {
            ...notification,
            isRead: true,
          };
        }
        return notification;
      });
      if (unreadNotificationsCounter > 0) {
        setUnreadNotificationsCounter((prevCounter) => prevCounter - 1);
      }
      setNotifications(updatedNotifications);
      setError('');
    } catch (error) {
      console.error('Error updating notification', error);
      setError('Error updating user notifications');
    } finally {
      setLoading(false);
    }
    setNotificationPopupOpen(true);
    setNotificationContent({
      notificationId: notificationId,
      icon: type === 'NEWS' ? NotificationMessageIcon : SystemMessageIcon,
      title: title,
      message: message,
    });
  };

  const getTimeAgo = (date) => {
    return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
  };

  function navigateToDashboard() {
    setPage(0);
    navigate(routes.dashboard);
    window.scrollTo(0, 0);
  }

  return (
    <WebLayout>
      <Box className={classes.containerTransactionHistory}>
        <Box className={classes.transactionsContainerWrapper}>
          <Box className={classes.container}>
            <Box sx={{ width: '100%' }}>
              <Box>
                <Tabs
                  value={notifications}
                  onChange={''}
                  aria-label="basic tabs example"
                  indicatorColor="secondary"
                  className={classes.tabsTitle}
                >
                  <Tab className={classes.transactionsLabelSeeAll} label={'NOTIFICATIONS'} />
                </Tabs>
                <Divider className={classes.headerDivider} />
              </Box>
              <div className={classes.tableContainerSeeAll}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">Content</TableCell>
                        <TableCell align="left">Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {notifications.length > 0 ? (
                        notifications.map((row) => {
                          return (
                            <TableRow
                              className={classes.row}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                              key={row.id}
                              onClick={() =>
                                handleNotificationClicked(
                                  row.id,
                                  row.group.type,
                                  row.group.title,
                                  row.group.description,
                                )
                              }
                            >
                              <TableCell align="left" component="th" scope="row">
                                <img
                                  src={row.group.type === 'NEWS' ? NotificationMessageIcon : SystemMessageIcon}
                                  alt={row.title}
                                  className={classes.icon}
                                />
                              </TableCell>
                              <TableCell align="left">{row.group.title}</TableCell>

                              <TableCell align="left">{row.group.shortDescription}</TableCell>

                              <TableCell align="left">{getTimeAgo(row.group.date)}</TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={6}>
                            No Recent Notifications
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Box>
          </Box>
          <Box className={classes.buttonsWrapper}>
            <Box>
              <Box className={classes.btn}>
                <CustomButton
                  type="black"
                  width="100%"
                  height="24px"
                  onClick={navigateToDashboard}
                  buttonText="&#9664;&nbsp;&nbsp;Back to Dashboard"
                ></CustomButton>
              </Box>
            </Box>
            {notifications.length > 9 && (
              <Box>
                <Box className={classes.paginationRoot}>
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={allPages}
                    page={page}
                    onChange={(event, newPage) => setPage(newPage)}
                  />
                </Box>
              </Box>
            )}
            <Box></Box>
            <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
          </Box>
        </Box>
      </Box>
      {loading && <LoadingBar open={loading} />}
      {notificationPopupOpen && (
        <UserNotificationPopup
          icon={notificationContent.icon}
          title={notificationContent.title}
          message={notificationContent.message}
          isPopupOpen={notificationPopupOpen}
          setIsPopupOpen={setNotificationPopupOpen}
        />
      )}
    </WebLayout>
  );
}
export default NotificationsWeb;

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    display: 'flex',
    marginBottom: '3%',
    backgroundColor: 'white',
    width: '100%',
    height: '44rem',
    paddingTop: '1%',
    border: '0.5px #D2D5D8',
    borderRadius: '24px',
    justifyContent: 'space-between',
    position: 'relative',
    '& .MuiTabs-indicator': {
      backgroundColor: 'black',
      height: '2px',
      width: '141px !important',
      left: '20px !important',
    },
    '& .MuiTab-wrapper': {
      fontWeight: 'bold',
    },
    '& .MuiTabs-root': {
      width: '500px',
    },
  },
  containerTransactionHistory: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '1rem',
  },
  transactionsContainerWrapper: {
    height: '100%',
  },
  tableContainer: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    '& .MuiTableCell-root': {
      fontWeight: '600',
      fontSize: '16px',
      [theme.breakpoints.down('1680')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('1520')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down('1380')]: {
        fontSize: '10px',
      },
    },
    '& .MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },
  rows: (props) => ({
    paddingLeft: '8px',
    [theme.breakpoints.down('1380')]: {
      paddingLeft: '5px',
    },
  }),
  dateRows: (props) => ({
    paddingLeft: '8px',
    [theme.breakpoints.down('1380')]: {
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('950')]: {
      paddingLeft: props.tradeAccountsLength ? '0px' : '',
      paddingRight: props.tradeAccountsLength ? '0px' : '',
      fontSize: props.tradeAccountsLength > 1 ? 'var(--font-size-14)' : '',
    },
  }),
  tableContainerSeeAll: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    '& .MuiTableCell-root': {
      fontWeight: '600',
      fontSize: 'var(--font-size-18)',
      [theme.breakpoints.down('1400')]: {
        fontSize: 'var(--font-size-17)',
      },
      [theme.breakpoints.down('1000')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('950')]: {
        paddingLeft: '0px',
        paddingRight: '0px',
        fontSize: 'var(--font-size-14)',
      },
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      color: '#767676',
    },
  },
  tabsTitle: {
    minHeight: '7px',
    [theme.breakpoints.down('1200')]: {
      height: '2.5rem',
    },
  },
  date: {
    width: '10%',
  },
  transactionsLabel: {
    height: 20,
    fontSize: 'var(--font-size-18)',
    paddingLeft: '24px',
    fontWeight: '600',
    color: 'var(--black) !important',
    fontFamily: 'Poppins',
    [theme.breakpoints.down('1645')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('1465')]: {
      fontSize: 'var(--font-size-14)',
    },
    [theme.breakpoints.down('1290')]: {
      fontSize: 'var(--font-size-12)',
    },
  },
  transactionsLabelSeeAll: {
    height: 20,
    fontSize: 'var(--font-size-18)',
    paddingLeft: '24px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    color: 'var(--black) !important',
  },
  seeAll: {
    textDecoration: 'none',
    color: 'var(--black)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: '1rem',
    opacity: 1,
    fontSize: 'var(--font-size-14)',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1465')]: {
      fontSize: 'var(--font-size-10)',
    },
  },
  headerDivider: {
    position: 'absolute',
    width: '100%',
  },
  paginationRoot: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingBottom: '3%',
    '& .MuiPagination-ul': {
      color: 'white',
      flexWrap: 'nowrap',
      '& li:first-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > button::after': {
          marginLeft: '2px',
          content: "'Prev'",
        },
      },
      '& li:last-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > button::before': {
          marginRight: '2px',
          content: "'Next'",
        },
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'var(--black)',
      color: 'var(--white)',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },

  storeCell: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  row: {
    cursor: 'pointer',
  },
  btn: {
    textAlign: 'left',
    width: '12rem',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  icon: {
    maxWidth: '1.3rem',
    maxHeight: '1.3rem',
  },
}));
