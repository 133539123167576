import * as React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";
import clsx from "clsx";
import RegisterImage from "../../../../assets/images/illustration-demo.png";
import { useAppContext } from "../../../../context/DataContext";

export default function PleaseLoginPopUp({ open, setOpen, handleClosePopups }) {
  const navigate = useNavigate();

  const { isMobile } = useAppContext();

  const handleClose = () => {
    if (handleClosePopups) {
      handleClosePopups();
    }
    setOpen(false);
  };
  const gotToLoginPage = () => {
    const appView = sessionStorage.getItem("isNative");
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        '{"Event": "Logout"}'
      );
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate(routes.login);
    }
  };
  const goToRegisterPage = () => {
    const appView = sessionStorage.getItem("isNative");
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        '{"Event": "Logout", "Action": "signup"}'
      );
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate(routes.signUp);
    }
  };
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { borderRadius: "24px", padding: 1 },
        }}
      >
        <Box className={clsx(classes.titleContainer, classes.separator)}>
          <DialogTitle sx={{ p: 0, justifyContent: "center", display: "flex" }}>
            <span className={classes.title}>BULLIONZ DEMO</span>
          </DialogTitle>
          <Button className={classes.closeButton} onClick={handleClose}>
            &times;
          </Button>
        </Box>
        <DialogContent sx={{ pl: 2, pr: 2 }}>
          <DialogContentText
            sx={{ p: 0, justifyContent: "center", display: "flex" }}
          >
            <span className={classes.text}>
              Thank you for using Bullionz! You must be registered to purchase.
            </span>
          </DialogContentText>
          <Box className={classes.imgContainer}>
            <img
              className={classes.webImg}
              src={RegisterImage}
              alt="reg logo"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className={classes.btnContainer}>
            <Box className={classes.buttonBox}>
              <Button
                className={classes.loginButton}
                variant="outlined"
                onClick={gotToLoginPage}
              >
                Login
              </Button>
            </Box>
            <Box className={classes.buttonBox}>
              <Button
                onClick={goToRegisterPage}
                className={classes.registerButton}
              >
                Register
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "300px",
    maxHeight: "300px",
    borderRadius: "24px",
  },
  separator: {
    marginTop: "0.5rem",
  },
  title: {
    fontSize:
      "clamp(var(--font-size-18), var(--font-size-22), var(--font-size-24))",
    paddingLeft: "1rem",
  },
  text: {
    fontSize:
      "clamp(var(--font-size-12), var(--font-size-14), var(--font-size-16))",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("420")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  buttonBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("420")]: {
      width: "100%",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  closeButton: {
    color: "var(--black)",
    fontSize: "24px",
    fontWeight: "normal",
    padding: "0px",
    "&:hover": {
      backgroundColor: "var(--white)",
    },
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  webImg: {
    width: "200px",
    height: "200px",
  },
  mobileImage: {
    width: "200px",
    height: "200px",
  },
  loginButton: {
    color: "black",
    border: "1px solid black",
    borderRadius: "40px",
    fontSize: "12px",
    fontWeight: "bold",
    width: "7rem",
    fontFamily: theme.typography.fontFamily,
    boxShadow: "0px 3.1852872371673584px 0px 0px #76767633",
    "&:hover": {
      border: "1px solid black",
    },
    maxHeight: "2rem",
    [theme.breakpoints.down("1120")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("950")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("420")]: {
      width: "70%",
      marginBottom: "1rem",
    },
  },
  registerButton: {
    backgroundColor: "var(--black)",
    borderRadius: "40px",
    fontSize: "12px",
    color: "var(--white)",
    border: 0,
    cursor: "pointer",
    textAlign: "left",
    boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
    "&:hover": {
      backgroundColor: "var(--black)",
    },
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    width: "7rem",
    maxHeight: "2rem",
    [theme.breakpoints.down("420")]: {
      width: "70%",
      marginBottom: "1rem",
    },
  },
}));
