import { set } from "date-fns";
import brokerService from "../service/broker";
import serviceTransaction from "../service/transactions";

export const handleGetWebCryptoTransactions = async (
  fn,
  traderAccountId,
  setAllPages,
  setError,
  pageSize,
  page,
  setLoading
) => {
  try {
    const response = await brokerService.getCryptoOrders(traderAccountId, { page, pageSize });

    if (!response.orders.length) {
      setError(true);
    }
    const filteredTransactions = response?.orders.map(({ ...rest }) => {

      return {
        ...rest,
        id: "",
        marketId: rest?.xrp_usdt,
        productItemCode: rest.product_item_code,
        date: rest.createdAt,
        status: rest.status,
        volume: rest.requestedAmount,
        brokerAccountId: rest?.brokerAccountId,
        currency: rest.currency,
        price: rest.executionPrice,
        side: rest.side,
      };
    });
    filteredTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));
    fn(filteredTransactions);
    setAllPages(response.totalPages);

    if (setLoading) {
      setLoading(false);
    }
    
  } catch (e) {
    setError(false);
  }
};

export const handleGetMobileCryptoTransactions = async (
  fn,
  page,
  pageSize,
  setAllPages,
  setError,
  setLoading
) => {
  setLoading(true);
  try {
    const response = await serviceTransaction.getAllTransactions(
      page,
      pageSize
    );
    if (!response.data.length) {
      setError(true);
    }
    const newTransactions = response?.data.map(({ ...rest }) => {
      let changeId = (1000000000 + rest.id).toString();

      if (rest.product_item_code === "P001") {
        changeId = (1000000000 + rest.id).toString();
      } else if (rest.product_item_code === "P005") {
        changeId = (5000000000 + rest.id).toString();
      } else if (rest.product_item_code === "P004") {
        changeId = (4000000000 + rest.id).toString();
      } else {
        changeId = rest.id;
      }

      return {
        ...rest,
        id: changeId,
        productItemCode: rest.product_item_code,
        date: rest.transaction_date,
        status: rest.status,
        volume: rest.volume,
        tradeAccountId: rest?.trade_account_id,
        currency: rest.currency,
        price: rest.price,
      };
    });
    newTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));
    fn((prevTransactions) =>
      [...prevTransactions, ...newTransactions].filter(
        (transaction, index, self) =>
          self.findIndex((t) => t.id === transaction.id) === index
      )
    );
    setAllPages(response.pages);
  } catch (e) {
    setError(true);
  } finally {
    setError(false);
    setLoading(false);
  }
};
