import { Box } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Tab, Tabs } from "@mui/material";
import clsx from "clsx";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MobileTotalMonthlyPool({ chart1, chart2, classTitle, classTab }) {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const isActiveTutorial = Number(sessionStorage.getItem("tutorialStep")) === 8;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className={classes.container} id="total-monthly-pool">
      <Box
        className={clsx(classTitle, isActiveTutorial && classes.tutorialTitle)}
      >
        TOTAL MONTHLY POOL
      </Box>
      <Box>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classTab}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            sx={{ minHeight: "1.2rem" }}
          >
            <Tab label="THIS MONTH" {...a11yProps(0)} />
            <Tab label="ALL TIME" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {chart1}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {chart2}
        </TabPanel>
      </Box>
    </Box>
  );
}

export default MobileTotalMonthlyPool;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    paddingLeft: "0.5rem",
  },
  tutorialTitle: {
    color: "white",
  },
}));
