import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, createStyles } from "@mui/material";
import AppLayout from "../../../components/common/Layout/AppLayout";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import { routes } from "../../../routes";
import PersonalDetails from "../../../components/MobileComponents/PersonalDetails/PersonalDetails";
import ChangePassword from "../../../components/MobileComponents/ChangePassword/ChangePassword";
import MobileDeliveryAddress from "../../../components/MobileComponents/MobileDeliveryAddress/MobileDeliveryAddress";
import MobileProfileTransactionHistoryTable from "../../../components/MobileComponents/MobileProfileTransactionTable/MobileProfileTransactionTable";
import { handleGetUserDetails } from "../../../helpers/handleGetUserDetails";
import { useState } from "react";
import { useEffect } from "react";
import { useAppContext } from "../../../context/DataContext";
import DeleteAccountPopup from "../../../components/common/Popups/DeleteAccountPopup/DeleteAccountPopup";
import { handlePleaseLogin } from "../../../helpers/pleaseLoginOrRegisterForDemo";
import PleaseLoginPopUp from "../../../components/common/Popups/PleaseLoginPopup/PleaseLoginPopup";
import { updateBucket } from "../../../helpers/update-client-bucket";
import { userService } from "../../../service/user";
import MobileUserSessionHistoryPopUp from "../../../components/MobileComponents/popups/UserSessionHistoryPopUp/MobileUserSessionHistoryPopUp";
import CustomButton from "../../../components/common/CustomButton/CustomButton";
import MobileTradingAccounts from "../../../components/MobileComponents/MobileTradingAccounts/MobileTradingAccounts";
import VerifyAccountBtn from "../../../assets/images/verify-account-btn.png";
import { useNavigate } from "react-router-dom";
import { handleGetUserCart } from "../../../helpers/handleGetUserCart";

function MobileProfile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userCartData, setUserCartData] = useState(null);

  const {
    isAppView,
    setIsCartFetched,
    setProductsBucket,
    proceedAddNewAddressClicked,
    isUserVerified,
    userDetails,
    setUserDetails,
    userTradeAccounts,
    userBrokerAccounts,
  } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [invalidate, setInvalidate] = useState(false);
  const [isDeleteAccountPopupOpen, setIsDeleteAccountPopupOpen] =
    useState(false);
  const [isUserSessionLoading, setIsUserSessionLoading] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [userSessionsData, setUserSessionsData] = useState([]);
  const [issSessionHistoryPopUpOpen, setIssSessionHistoryPopUpOpen] =
    useState(false);

  const showTradingAccountsBox =
    userTradeAccounts?.length + userBrokerAccounts?.length > 1;

  useEffect(() => {
    handleGetUserDetails(setUserDetails, setLoading);
  }, [proceedAddNewAddressClicked]);

  useEffect(() => {
    handleGetUserCart(setUserCartData, () => {});
  }, []);

  useEffect(() => {
    updateBucket(
      isAppView,
      userCartData,
      setIsCartFetched,
      setProductsBucket,
      userService?.updateShoppingCart
    );
  }, [userCartData]);

  useEffect(() => {
    if (invalidate) {
      handleGetUserSessionsClicked();
      setInvalidate(false);
    }
  }, [invalidate]);

  const handleDeleteAccountClicked = () => {
    setIsDeleteAccountPopupOpen(true);
  };

  const handleGetUserSessionsClicked = async () => {
    try {
      setLoading(true);
      setIsUserSessionLoading(true);
      setIssSessionHistoryPopUpOpen(true);

      const response = await userService.getUserSessions();

      setUserSessionsData(response);
      setLoading(false);

      setTimeout(() => {
        setIsUserSessionLoading(false);
      }, 650);
    } catch (e) {
      console.log("error", e);
    }
  };

  function navigateToSumSubPage() {
    navigate(routes.sumsub);
  }

  function navigateToSumSub() {
    const appView = sessionStorage.getItem("isNative");
    const externalUserId = sessionStorage.getItem("externalUserId");
    const levelName = sessionStorage.getItem("levelName");
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        `{"Event": "NotVerified", "ExternalUserId": "${externalUserId}", "VerificationLevel": "${levelName}"}`
      );
    } else {
      navigateToSumSubPage();
    }
  }

  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileNavigationBack
          page={"Profile Settings"}
          route={routes.dashboard}
        />
        {isUserVerified === "false" && (
          <div className={classes.verifyAccountInfoContainer}>
            <span>Before you continue we need to verify your account</span>
            <div className={classes.verifyAccountBtnContainer}>
              <img
                src={VerifyAccountBtn}
                alt="Account Icon"
                style={{ cursor: "pointer" }}
                onClick={navigateToSumSub}
              />
            </div>
          </div>
        )}
        <PersonalDetails userDetails={userDetails} />
        <Box className={classes.sessionHistoryContainer}>
          <CustomButton
            type="black"
            buttonText="Session History"
            onClick={handleGetUserSessionsClicked}
          />
        </Box>
        {showTradingAccountsBox && <MobileTradingAccounts />}
        <ChangePassword />
        <MobileDeliveryAddress />
        <h3> Transactions History</h3>
        <Box className={classes.table}>
          <MobileProfileTransactionHistoryTable
            className={classes.tableContainer}
          />
        </Box>

        <Box className={classes.buttonContainer}>
          <Button
            className={classes.deleteButton}
            variant="outlined"
            onClick={handleDeleteAccountClicked}
          >
            Delete my account
          </Button>
        </Box>
      </Box>
      {isDeleteAccountPopupOpen && (
        <DeleteAccountPopup
          isPopupOpen={isDeleteAccountPopupOpen}
          setIsPopupOpen={setIsDeleteAccountPopupOpen}
        />
      )}

      {issSessionHistoryPopUpOpen && (
        <MobileUserSessionHistoryPopUp
          setInvalidate={setInvalidate}
          isLoading={isUserSessionLoading}
          userSessions={userSessionsData}
          isOpen={issSessionHistoryPopUpOpen}
          setIsOpen={setIssSessionHistoryPopUpOpen}
        />
      )}

      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
    </AppLayout>
  );
}

export default MobileProfile;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      position: "relative",
      overflowY: "scroll",
      margin: 0,
    },
    verifyAccountInfoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "89%",
      border: "1px solid #FFC1C1",
      borderRadius: "20px",
      backgroundColor: "#FFEFEF",
      color: "#D2492B",
      height: "80px",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontWeight: "400",
      gap: "25px",
      marginTop: "20px",
      marginBottom: "20px",
      marginLeft: "20px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    verifyAccountBtnContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    table: {
      width: "95%",
      margin: "auto",
      marginTop: "1rem",
      borderRadius: "12px",
      border: "1px solid #D2D5D8",
      overflowY: "auto",
      marginBottom: "0.5rem",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100px",
    },
    deleteButton: {
      color: "black",
      border: "1px solid black",
      borderRadius: "38px",
      fontSize: "14px",
      fontWeight: "600",
      fontFamily: theme.typography.fontFamily,
      boxShadow: "0px 3.1852872371673584px 0px 0px #76767633",
      "&:hover": {
        border: "1px solid black",
      },
    },

    sessionHistoryContainer: {
      display: "flex",
      justifyContent: "center",

      "& button": {
        width: "calc(50% - 2rem)",
      },
    },
  })
);
