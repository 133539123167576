export const CURRENCY_TYPE = localStorage.getItem("currency") || "USD";
export const CURRENCY_SYMBOL = CURRENCY_TYPE === "EUR" ? "€" : "$";
export const LORREM_IPSUM =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";
export const euro = 54.52; // gold: 1gr = 54.52$ // should be dynamic
export const coinName = "BTXG";

export const GOLD_PRICE_DENSITY = 180;

export const statesOfChip = {
  success: `12.2 % ▲`,
  error: `12.2 % ▼`,
  info: `12.2 % ●`,
};

export const NON_VERIFIED = "User is not verified";

export const descriptions = {
  pamp: "PAMP (Produits Artistiques Métaux Précieux) has been curating bullions, bars, and coins within the precious metals industry for almost 50 years. Together with the MKS Group, a precious metals trading house, the combined entity sources only industry-leading experts in sourcing, supplying, refining, fabricating, and trading precious metal products. Underpinned by high-quality standards, craftsmanship, a commitment to sustainability and ethical practices, PAMP products remain to this day as a symbol of quality and Swiss excellence.",
  valcambi:
    "Founded by a group of private Swiss investors, Valcambi has specialized in precious metal refining for over 60 years. By employing some of the industry’s top assaying, refining, and manufacturing methods, the company has been able to produce a vast range of finished and semi-finished precious metal products. Catering to a wide array of markets and client types, Valcambi also prides itself on its transportation, financing, security, and storage services.",
  argorHeraeus:
    "Part of the Global Business Unit Heraeus Precious Metals (HPM), Argor-Heraeus has been transforming precious metals for 70 years. With international locations across Switzerland, Italy, and Germany, the company specializes in refining gold, silver, platinum, and palladium. By working with globally recognized mines, watchmakers, jewelers, banks, trading houses, and electronic and chemical enterprises, Argor-Heraeus is able to offer cast and minted investment products, semifinished products and alloys, and precious metals trading, hedging and transformation services.",
  fritz:
    "Born in Le Locle, Switzerland, Fritz Ulysse Landry was a renowned medalist, sculptor and engraver. Artistic works of his include medals, reliefs, statuettes, tombs, and small bronze figures. Overall, he is best known for his design of the famous Swiss coin, the Vreneli gold francs, which diverged from conventional styles of coins at the time.",
  coin: "Born in Le Locle, Switzerland, Fritz Ulysse Landry was a renowned medalist, sculptor and engraver. Artistic works of his include medals, reliefs, statuettes, tombs, and small bronze figures. Overall, he is best known for his design of the famous Swiss coin, the Vreneli gold francs, which diverged from conventional styles of coins at the time.",
};
