import { Box } from "@mui/material";
import React from "react";
import TotalMonthlyPoolTop from "./TotalMonthlyPoolTop/TotalMonthlyPoolTop";
import DashBoardTotalMonthlyCharts2 from "../../common/Charts/DashBoardTotalMonthlyCharts2";
import DashBoardTotalMonthlyCharts1 from "../../common/Charts/DashBoardTotalMonthlyCharts1";
import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";

function TotalMonthlyPool({ pool }) {
  const classes = useStyles();
  
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <TotalMonthlyPoolTop
        chart1={<DashBoardTotalMonthlyCharts1 pool={pool} />}
        chart2={<DashBoardTotalMonthlyCharts2 pool={pool} />}
        containerClass={classes.container}
        tabClass={classes.tab}
        pool={pool}
      />
    </Box>
  );
}

export default TotalMonthlyPool;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "755px",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      position: "relative",
      backgroundColor: "var(--white)",
      borderRadius: "24px",
      fontFamily: theme.typography.fontFamily,
    },
    tab: {
      minWidth: "20px",
      paddingTop: "0.5rem",
      "& .MuiTab-root": {
        minWidth: "30px",
        width: "37%",
        minHeight: "22px",
        height: "100%",
        [theme.breakpoints.down("1600")]: {
          minHeight: "18px",
        },
      },
      "& .MuiTabs-flexContainer": {
        paddingLeft: "1rem",
      },
      "& button": {
        borderRadius: "8px",
        color: "var(--black)",
        fontWeight: 500,
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem",
        lineHeight: "3px",
        [theme.breakpoints.down("1700")]: {
          fontSize: "var(--font-size-12)",
        },
        [theme.breakpoints.down("1600")]: {
          fontSize: "var(--font-size-10)",
          width: "35%",
          paddingLeft: "0.18rem",
          paddingRight: "0.18rem",
        },
        [theme.breakpoints.down("1280")]: {
          fontSize: "0.5rem",
        },
        [theme.breakpoints.down("1200")]: {
          fontSize: "var(--font-size-10)",
        },
        [theme.breakpoints.down("1060")]: {
          fontSize: "0.6rem",
        },
        [theme.breakpoints.down("950")]: {
          fontSize: "0.5rem",
        },
        [theme.breakpoints.down("900")]: {
          fontSize: "0.45rem",
        },
        [theme.breakpoints.down("830")]: {
          fontSize: "0.4rem",
        },
        fontFamily: theme.typography.fontFamily,
      },
      "& button.Mui-selected": {
        backgroundColor: "var(--beige) !important",
        color: "var(--white)",
        fontWeight: "bold",
        fontFamily: theme.typography.fontFamily,
      },
    },
  })
);
