import { fetchWrapper } from "../../utils/fetchWrapper";
import { transformInputToOutput } from "../../helpers/userCartInputTransform";

const getUserPortfolio = async () => {
  try {
    return await fetchWrapper.get("/user/portfolio");
  } catch (error) {
    throw new Error(error);
  }
};

const getUserDepositToken = async () => {
  try {
    const token = await fetchWrapper.post("/deposit/checkout", {});
    return token;
  } catch (error) {
    throw new Error(error);
  }
};

const register = async (data) => {
  try {
    const token = await fetchWrapper.post("/user/register", data);
    return token;
  } catch (error) {
    throw new Error(error);
  }
};

const getWithDraw = async (setLoading) => {
  try {
    setLoading(true);
    const withdrawUrl = await fetchWrapper.get("/user/withdrawal-iframe");
    setLoading(false);
    return withdrawUrl;
  } catch (error) {
    console.error("Error making withdraw:", error);
    setLoading(false);
    throw new Error(error);
  }
};

const getUserDetails = async (setLoading) => {
  try {
    setLoading(true);
    const details = await fetchWrapper.get("/user");
    setLoading(false);
    return details;
  } catch (error) {
    console.error("Error making user details:", error);
    setLoading(false);
    throw new Error(error);
  }
};

const changeUserDetails = async (newDetails, setLoading) => {
  try {
    setLoading(true);
    const details = await fetchWrapper.put("/user/details", newDetails);
    setLoading(false);
    return details;
  } catch (error) {
    console.error("Error changing user details:", error);
    setLoading(false);
    throw new Error(error);
  }
};

const changePassword = async (pass) => {
  try {
    const newPass = await fetchWrapper.patch("/user/update-password", pass);
    return newPass;
  } catch (error) {
    console.error("Error changing the password:", error.message);
    throw new Error(error.message);
  }
};

const getUserDeliveryAddress = async (setLoading) => {
  try {
    setLoading(true);
    const address = await fetchWrapper.get("/user/delivery-address");
    setLoading(false);
    return address;
  } catch (error) {
    console.error("Error taking user delivery address:", error);
    setLoading(false);
    throw new Error(error);
  }
};

const updateUserDeliveryAddress = async (newAddress, setLoading) => {
  try {
    setLoading(true);
    const address = await fetchWrapper.patch(
      "/user/update-delivery-address",
      newAddress
    );
    setLoading(false);
    return address;
  } catch (error) {
    setLoading(false);
    throw new Error(error);
  }
};

const getUserCrmTransactions = async (setLoading) => {
  try {
    setLoading(true);
    const transactions = await fetchWrapper.get("/user/crm/transactions");
    setLoading(false);
    return transactions;
  } catch (error) {
    setLoading(false);
    throw new Error(error);
  }
};

const isUserVerified = async (setLoading) => {
  try {
    setLoading(true);
    const isVerified = await fetchWrapper.get("/user/verified");
    setLoading(false);
    return isVerified;
  } catch (error) {
    setLoading(false);
    throw new Error(error);
  }
};

const getSumSubAccessToken = async (setLoading) => {
  try {
    setLoading(true);
    const sumSubAccessToken = await fetchWrapper.post(`/user/sumsub-token`, {});
    setLoading(false);
    return sumSubAccessToken;
  } catch (error) {
    console.error("Error making withdraw:", error);
    setLoading(false);
    throw new Error(error);
  }
};

const getTotalMonthlyPool = async (setLoading) => {
  try {
    setLoading(true);
    const pool = await fetchWrapper.get("/user/total-monthly-staking");
    setLoading(false);
    return pool;
  } catch (e) {
    console.error("Error getting pool:", e);
    setLoading(false);
    throw new Error(e);
  }
};

const logout = async () => {
  try {
    await updateShoppingCart({});
    await fetchWrapper.post("/user/logout");
  } catch (error) {
    throw new Error(error);
  }
};

const deleteUserAccount = async () => {
  try {
    const result = await fetchWrapper.delete("/user");
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const updateTutorialStatus = async (type, status) => {
  try {
    let queryParams = `?type=${type}`;

    if (status) {
      queryParams += `&status=${status}`;
    }

    const result = await fetchWrapper.patch(
      `/user/tutorial-status${queryParams}`
    );
    return result;
  } catch (error) {
    console.log("Error updating tutorial status:", error);
  }
};

const getShoppingCart = async () => {
  try {
    let result = await fetchWrapper.get("/user/shopping-cart");

    if (Object.keys(result).length > 0) {
      result = transformInputToOutput(result);
    }

    return result;
  } catch (error) {
    console.log("Error getting shopping cart:", error);
  }
};

const syncShoppingCart = async () => {
  try {
    let result = await fetchWrapper.get("/user/sync-cart");

    return result;
  } catch (error) {
    console.log("Error sync shopping cart:", error);
  }
};

const updateShoppingCart = async (body) => {
  try {
    const result = await fetchWrapper.patch(
      "/user/shopping-cart",
      transformInputToOutput(body)
    );

    return result;
  } catch (error) {
    console.log("Error updating shopping cart:", error);
  }
};

const getUserSessions = async () => {
  try {
    const result = await fetchWrapper.get("/user/sessions");

    return result;
  } catch (error) {
    console.log("Error getting user sessions", error);
  }
};

const logoutUserSession = async (sessionId) => {
  try {
    const result = await fetchWrapper.patch(`/user/logout-session`, {
      sessionId,
    });

    return result;
  } catch (error) {
    console.log("Error logging out user session", error);
  }
};

export const userService = {
  register,
  getUserPortfolio,
  getUserDepositToken,
  getWithDraw,
  getUserDetails,
  changePassword,
  getUserSessions,
  logoutUserSession,
  changeUserDetails,
  updateTutorialStatus,
  getUserDeliveryAddress,
  updateUserDeliveryAddress,
  getUserCrmTransactions,
  logout,
  isUserVerified,
  getShoppingCart,
  syncShoppingCart,
  deleteUserAccount,
  updateShoppingCart,
  getTotalMonthlyPool,
  getSumSubAccessToken,
};
