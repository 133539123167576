import React from "react";
import { Box, createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import {
  CURRENCY_SYMBOL,
  CURRENCY_TYPE,
} from "../../../constants/globalconstants";
import { useEffect } from "react";
import { getTheNamesOfPreviousMonths } from "../../../helpers/getTheNamesOfPreviousMonths";
import { generateCurrencySymbol } from "../../../helpers/generateCurrencySymbol";
import { useAppContext } from "../../../context/DataContext";

ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

const options = {
  plugins: {
    legend: {
      display: true,
      align: "start",
      onClick: () => {},
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
      },
    },
    tooltip: {
      enabled: true,
      yAlign: "bottom",
      displayColors: false,
      backgroundColor: "#E0C38B",
      bodyFont: { size: 16, weight: "bold" },
      callbacks: {
        label: (context) => {
          const datasetLabel = context.dataset.label || "";
          const value = context.parsed.y || 0;
          const fixedValue = value.toFixed(2);
          let currencySymbol = generateCurrencySymbol();
          return `${datasetLabel}: ${currencySymbol}${fixedValue}`;
        },
      },
    },
  },
  indexAxis: "x",
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      barPercentage: 0.6,
      categoryPercentage: 0.7,
    },
    y: {
      position: "right",
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value) {
          return value;
        },
      },
    },
  },
  animation: false,
  borderRadius: 100,
  barThickness: 18,
};
const options1 = {
  cutout: "70%",
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      enabled: true,
      displayColors: false,
      backgroundColor: "#E0C38B",
      yAlign: "bottom",
      callbacks: {
        label: (context) => {
          let value = context.parsed;
          const datasetIndex = context.dataIndex;
          let currencySymbol = generateCurrencySymbol();
          if (value == -1) {
            value = 0;
          }
          return datasetIndex
            ? `${currencySymbol}${value.toFixed(2)}`
            : `Dividend ${currencySymbol}${value.toFixed(2)}`;
        },
      },
    },
  },
  height: "100%",
};

function StakingTotalMonthlyCharts2({ pool }) {
  const classes = useStyles();

  let allUserAllTime = pool?.all?.total || 0;
  let userAllTime = pool?.user?.total || 0;

  const isDemoMode = sessionStorage.getItem("isDemo") === "true";

  const currencySymbol = generateCurrencySymbol();

  if (isDemoMode && currencySymbol === "€") {
    allUserAllTime = allUserAllTime * 0.93;
    userAllTime = userAllTime * 0.93;
  }

  const adjustData = (data) => {
    if (isDemoMode && currencySymbol === "€") {
      return data.map(value => value * 0.93);
    }
    return data;
  };

  const userDividends = adjustData([
    pool?.user?.month6,
    pool?.user?.month5,
    pool?.user?.month4,
    pool?.user?.month3,
    pool?.user?.month2,
    pool?.user?.month1,
  ]);

  const totalDividends = adjustData([
    pool?.all?.month6,
    pool?.all?.month5,
    pool?.all?.month4,
    pool?.all?.month3,
    pool?.all?.month2,
    pool?.all?.month1,
  ]);

  const { IsSocketReconnecting } = useAppContext();

  useEffect(() => {}, [pool, IsSocketReconnecting, isDemoMode,currencySymbol]);

  let rest = allUserAllTime - userAllTime;

  if (!allUserAllTime && !userAllTime) {
    rest = -1;
  }

  const data6 = {
    labels: getTheNamesOfPreviousMonths(new Date(), 6),
    datasets: [
      {
        label: "Dividend",
        data: userDividends,
        backgroundColor: ["black", "black", "black", "black", "black", "black"],
        borderWidth: 3,
        borderColor: "#f2f2f2",
      },
      {
        label: "Total",
        data: totalDividends,
        backgroundColor: [
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
        ],
        borderWidth: 3,
        borderColor: "#f2f2f2",
      },
    ],
  };

  const texInDoughnut = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      const chartHeight = chart.chartArea.height;
      const fontSize = Math.floor(chartHeight * 0.1);
      ctx.font = `bolder ${fontSize}px Poppins`;
      ctx.fillStyle = "#d7d7d7cc";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.fillText(`TOTAL`, xCoor, yCoor - 18);
      ctx.font = `bolder ${fontSize}px Poppins`;
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      let total = data?.datasets[0]?.data[1] + data?.datasets[0]?.data[0];

      const currencySymbol = generateCurrencySymbol();

      // if (isDemoMode) {
      //   if (currencySymbol === "€") {
      //     total = Number((total * 0.93).toFixed(2));
      //   }
      // }
      
      if (
        data?.datasets[0]?.data[1] === -1 ||
        !data?.datasets[0]?.data[0] === -1
      ) {
        total = 0;
      }
      ctx.fillText(
        `${currencyFormatter(total, CURRENCY_TYPE)}`,
        xCoor,
        yCoor + 15
      );
    },
  };

  const data = {
    datasets: [
      {
        data: [userAllTime, rest],
        backgroundColor: ["black", "#d7d7d7cc"],
        borderWidth: 0,
      },
    ],
    labels: [],
  };
  return (
    <Box className={classes.progressContainer}>
      <Box className={classes.chartWrapper}>
        <Doughnut data={data} options={options1} plugins={[texInDoughnut]} />
      </Box>
      <Box className={classes.barchartWrapper}>
        <Bar data={data6} options={options} />
      </Box>
    </Box>
  );
}

export default StakingTotalMonthlyCharts2;

const useStyles = makeStyles((theme) =>
  createStyles({
    progressContainer: {
      display: "flex",
      position: "relative",
      top: "50px",
      justifyContent: "space-between",
      width: "94%",
      height: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      alignItems: "baseline",
      fontFamily: theme.typography.fontFamily,
    },
    chartWrapper: {
      position: "relative",
      left: "30px",
      paddingBottom: "20px",
      marginRight: "1rem",
      width: "25% !important",
      height: "70% !important",
      aspectRatio: 1 / 1,
      [theme.breakpoints.down("880")]: {
        width: "30% !important",
      },
    },
    barchartWrapper: {
      width: "50% !important",
      height: "100% !important",
      aspectRatio: 2 / 1,
      textAlign: "center",
      paddingBottom: "2%",
      [theme.breakpoints.down("880")]: {
        width: "60% !important",
      },
    },
  })
);
