import './App.css';
import '../src/styles/global.styles.css';
import '../src/styles/global.fonts.css';
import WebStaking from './pages/Web/Staking/WebStaking';
import { Route, Routes, useLocation } from 'react-router-dom';
import WebTransactionCardInSeeAll from './components/WebComponents/TansactionCardInSeeAll/WebTransactionCardInSeeAll';
import WebStakingHistorySeeAll from './components/WebComponents/StakingHistorySeeAll/WebStakingHistorySeeAll';
import WebVaultingHistorySeeAll from './components/WebComponents/VaultingHistorySeeAll/WebVaultingHistorySeeAll';
import WebCertificatesHistorySeeAll from './components/WebComponents/CertificatesHistorySeeAll/WebCertificatesHistorySeeAll';
import WebDashboard from './pages/Web/Dashboard/WebDashboard';
import WebVaulting from './pages/Web/Vaulting/WebVaulting';
import { useAppContext } from './context/DataContext';
import MobileDashboard from './pages/Mobile/MobileDashboard/MobileDashboard';
import MobileStaking from './pages/Mobile/MobileStaking/MobileStaking';
import MobileTransactionsHistory from './pages/Mobile/MobileTransactionsHistory/MobileTransactionsHistory';
import MobileStakingHistory from './pages/Mobile/MobileStakingHistory/MobileStakingHistory';
import MobileVaulting from './pages/Mobile/MobileVaulting/MobileVaulting';
import MobileVaultingHistory from './pages/Mobile/MobileVaultingHistory/MobileVaultingHistory';
import MobileCertificatesHistory from './pages/Mobile/MobileCertificatesHistory/MobileCertificatesHistory';
import MobileAutoInvest from './pages/Mobile/MobileAutoInvest/MobileAutoInvest';
import WebAutoInvest from './pages/Web/AutoInvest/WebAutoInvest';
import AutoInvestHistorySeeAll from './components/WebComponents/AutoInvestHistorySeeAll/AutoInvestHistorySeeAll';
import MobileAutoInvestHistory from './pages/Mobile/MobileAutoInvestHistory/MobileAutoInvestHistory';
import ErrorPage from './pages/Web/Error/Error';
import isApp from './helpers/isNative';
import AppDashboard from './pages/App/AppDashboard/AppDashboard';
import AppVaulting from './pages/App/AppVaulting/AppVaulting';
import AppStaking from './pages/App/AppStaking/AppStaking';
import AppTransactionsHistory from './pages/App/AppTransactionsHistory/AppTransactionsHistory';
import AppStakingHistory from './pages/App/AppStakingHistory/AppStakingHistory';
import AppVaultingHistory from './pages/App/AppVaultingHistory/AppVaultingHistory';
import AppCertificatesHistory from './pages/App/AppCertificatesHistory/AppCertificatesHistory';
import AppAutoInvest from './pages/App/AppAutoInvest/AppAutoInvest';
import AppAutoInvestHistory from './pages/App/AppAutoInvestHistory/AppAutoInvestHistory';
import Notifications from './pages/Mobile/Notifications/Notifications';
import WebDeposit from './pages/Web/Deposit/WebDeposit';
import MobileDeposit from './pages/Mobile/MobileDeposit/MobileDeposit';
import Profile from './pages/Web/Profile/Profile';
import AppDeposit from './pages/App/AppDeposit/AppDeposit';
import Login from './pages/Web/Login/Login';
import MobileProfile from './pages/Mobile/MobileProfile/MobileProfile';
import Withdraw from './pages/Web/Withdraw/Withdraw';
import MobileWithdraw from './pages/Mobile/MobileWithdraw/MobileWithdraw';
import AppWithdraw from './pages/App/AppWithdraw/AppWithdraw';
import isVerified from './helpers/isVerified';
import { act, useEffect, useState } from 'react';
import LoadingBar from './components/common/LoadingBar/LoadingBar';
import NonVerifiedUserPopUp from './components/common/Popups/NonVerifiedUser/NonVerifiedUser';
import SumSub from './pages/Web/SumSub/SumSub';
import MobileSumSub from './pages/Mobile/MobileSumSub/MobileSumSub';
import handleGetTotalMonthlyPool from './helpers/handleGetTotalMonthlyPool';
import SeeAllNews from './components/WebComponents/SeeAllNews/SeeAllNews';
import MobileNewsPage from './pages/Mobile/MobileNewsPage/MobileNewsPage';
import WebLayout from './components/common/Layout/WebLayout';
import { routes } from './routes';
import { ProtectedRoute } from './components/common/ProtectedRoute/ProtectedRoute';
import StorePage from './components/WebComponents/Store/StorePage';
import MobileStorePage from './pages/Mobile/MobileStorePage/MobileStorePage';
import CartPage from './components/common/CartPage/CartPage';
import MobileCartPage from './pages/Mobile/MobileCartPage/MobileStorePage';
import WebStoreLayout from './components/common/Layout/WebStoreLayout';
import { messages } from './constants/popupMessages';
import WebCryptoDashboard from './pages/Web/CryptoDashboard/WebCryptoDashboard';
import brokerService from './service/broker';
import AppCryptoDashboard from './pages/App/AppCryptoDashboard/AppCryptoDashboard';
import MobileCryptoDashboard from './pages/Mobile/MobileCryptoDashboard/MobileCryptoDashboard';
import { useCryptoContext } from './context/CryptoContext';
import WebTransactionCryptoCardInSeeAll from './components/WebComponents/TansactionCryptoCardInSeeAll/WebTransactionCryptoCardInSeeAll';
import MobileCryptoTransactionsHistory from './pages/Mobile/MobileCryptoTransactionsHistory/MobileCryptoTransactionsHistory';
import AppCryptoTransactionHistory from './pages/App/AppCryptoTransactionsHistory/AppCryptoTransactionsHistory';
import NotificationsWeb from './components/WebComponents/Notifications/Notifications';

const urlBalance = process.env.REACT_APP_BBP_BALANCE_WEBSOCKET;

function App() {
  const [pool, setPool] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const {
    isWeb,
    isMobile,
    currency,
    setRefetch,
    isLoggedIn,
    tutorialStep,
    userBrokerAccounts,
    nonVerifiedPopUpOpen,
    setNonVerifiedPopUpOpen,
    setIsBrokerOrderConfirmed,
  } = useAppContext();

  const { activeInstrument, setMarketDetails } = useCryptoContext();

  const location = useLocation();
  isApp();
  let previousWidth = window.innerWidth;

  const accessToken = localStorage.getItem('accessToken');
  const accessTokenExp = localStorage.getItem('accessTokenExp');
  const isNativeInSessionStorage = sessionStorage.getItem('isNative') === 'true';
  const isDemoMode = sessionStorage.getItem('isDemo') === 'true';
  const isVerifiedPopUpShown = sessionStorage.getItem('isVerifiedPopUpShown');

  const getMarketDetails = async (marketSymbol) => {
    let details;
    try {
      if(isDemoMode) {
        details = await brokerService.getDemoMarketDetails(marketSymbol);
      }else {
        details = await brokerService.getMarketDetails(marketSymbol);
      }
      setMarketDetails(details);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (activeInstrument?.marketId) {
      getMarketDetails(activeInstrument?.marketId);
    }
  }, [activeInstrument]);

  // if there are some problems this whole useEffect could be commented
  useEffect(() => {
    const isDemo = sessionStorage.getItem('isDemo');

    if (location.pathname !== '/login' && location.pathname !== '/signup') {
      handleGetTotalMonthlyPool(setPool, setLoading);
      if (!isDemo && !isVerifiedPopUpShown) {
        isVerified(setNonVerifiedPopUpOpen, setLoading);
        sessionStorage.setItem('isVerifiedPopUpShown', true);
      }
    }
  }, [isLoggedIn, currency]);

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/signup') {
      if (isDemoMode) {
        localStorage.clear();
        sessionStorage.clear();
      }
    }
  }, [window.location.pathname]);

  let isAuthenticated;
  if (accessToken && accessTokenExp) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  const handleResize = () => {
    const currentWidth = window.innerWidth;

    if (isWeb && tutorialStep === 3) {
      const depositIconCopy = document.getElementById('deposit-icon-copy');

      if (depositIconCopy) {
        depositIconCopy.remove();
      }
    }

    // Update previousWidth for the next resize event
    previousWidth = currentWidth;

    const depositIconCopy = document.getElementById('deposit-icon-copy');

    if (depositIconCopy) {
      depositIconCopy.remove();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // based on this if state -------isMobile------- we render the relevant Layout with the Relevant Components  element={isMobile ? <MobileDashboard /> : <WebDashboard />}
  return (
    <div className="App">
      <Routes>
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login signup={true} />} />
        <Route
          exact
          path={routes.dashboard}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.dashboard}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppDashboard
                  pool={pool}
                  isPleaseLoginPopupOpen={isPleaseLoginPopupOpen}
                  setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
                />
              ) : isMobile ? (
                <MobileDashboard pool={pool} />
              ) : (
                <WebDashboard pool={pool} />
              )}
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path={routes.crypto}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.crypto}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppCryptoDashboard />
              ) : isMobile ? (
                <MobileCryptoDashboard />
              ) : (
                <WebCryptoDashboard />
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path={routes.yielding}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.yielding}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppStaking pool={pool} />
              ) : isMobile ? (
                <MobileStaking pool={pool} />
              ) : (
                <WebStaking pool={pool} />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.vaulting}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              isDemo={isDemoMode}
              path={routes.vaulting}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? <AppVaulting /> : isMobile ? <MobileVaulting /> : <WebVaulting />}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.transactions}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              isDemo={isDemoMode}
              path={routes.transactions}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppTransactionsHistory />
              ) : isMobile ? (
                <MobileTransactionsHistory />
              ) : (
                <WebTransactionCardInSeeAll />
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path={routes.cryptoTransactions}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              isDemo={isDemoMode}
              path={routes.transactions}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppCryptoTransactionHistory />
              ) : isMobile ? (
                <MobileCryptoTransactionsHistory />
              ) : (
                <WebTransactionCryptoCardInSeeAll />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.yieldingHistory}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              isDemo={isDemoMode}
              path={routes.yieldingHistory}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppStakingHistory />
              ) : isMobile ? (
                <MobileStakingHistory />
              ) : (
                <WebStakingHistorySeeAll />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.vaultingHistory}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.vaultingHistory}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppVaultingHistory />
              ) : isMobile ? (
                <MobileVaultingHistory />
              ) : (
                <WebVaultingHistorySeeAll />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.certificatesHistory}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.certificatesHistory}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppCertificatesHistory />
              ) : isMobile ? (
                <MobileCertificatesHistory />
              ) : (
                <WebCertificatesHistorySeeAll />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.autoInvest}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.autoInvest}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? <AppAutoInvest /> : isMobile ? <MobileAutoInvest /> : <WebAutoInvest />}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.autoInvestHistory}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.autoInvestHistory}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppAutoInvestHistory />
              ) : isMobile ? (
                <MobileAutoInvestHistory />
              ) : (
                <AutoInvestHistorySeeAll />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.notifications}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.notifications}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage || isMobile ? <Notifications /> : <NotificationsWeb />}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.deposit}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.deposit}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppDeposit isMobile={isMobile} />
              ) : isMobile ? (
                <MobileDeposit isMobile={isMobile} />
              ) : (
                <WebDeposit isMobile={isMobile} />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.profile}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.profile}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? <MobileProfile /> : isMobile ? <MobileProfile /> : <Profile />}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.withdraw}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.withdraw}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? <AppWithdraw /> : isMobile ? <MobileWithdraw /> : <Withdraw />}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.sumsub}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.sumsub}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? <MobileSumSub /> : isMobile ? <MobileSumSub /> : <SumSub />}
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.news}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.news}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <MobileNewsPage />
              ) : isMobile ? (
                <MobileNewsPage />
              ) : (
                <WebLayout>
                  <SeeAllNews />
                </WebLayout>
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path={routes.store}
          element={
            <ProtectedRoute
              path={routes.store}
              isDemo={isDemoMode}
              isMobile={isMobile}
              isAuthenticated={isAuthenticated}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
            >
              {isNativeInSessionStorage || isMobile ? (
                <MobileStorePage />
              ) : (
                <WebStoreLayout>
                  <StorePage />
                </WebStoreLayout>
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path={routes.cart}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={routes.cart}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <MobileCartPage />
              ) : isMobile ? (
                <MobileCartPage />
              ) : (
                <WebLayout>
                  <CartPage />
                </WebLayout>
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path="*"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              path={'*'}
              isDemo={isDemoMode}
              setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
              isMobile={isMobile}
            >
              {isNativeInSessionStorage ? (
                <AppDashboard pool={pool} />
              ) : isMobile ? (
                <MobileDashboard pool={pool} />
              ) : (
                <WebDashboard pool={pool} />
              )}
            </ProtectedRoute>
          }
        />
      </Routes>
      <LoadingBar open={loading} />
      {nonVerifiedPopUpOpen && !isVerifiedPopUpShown && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
        />
      )}
    </div>
  );
}

export default App;
