import { Box, Grid } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import StakingNavigation from '../../../components/WebComponents/StakingNavigation/StakingNavigation';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/material';
import { coinName } from '../../../constants/globalconstants';
import WebLayout from '../../../components/common/Layout/WebLayout';
import News from '../../../components/WebComponents/News/CardNews/News';
import AutoInvestHistory from '../../../components/WebComponents/AutoInvestHistory/AutoInvestHistory';
import TotalBalanceInAutoInvest from '../../../components/WebComponents/AutoInvestHistory/TotalBalanceInAutoInvest/TotalBalanceInAutoInvest';
import AutoInvestCard from '../../../components/WebComponents/AutoInvestHistory/AutoInvestCard/AutoInvestCard';
import AutoInvestGoldHistory from '../../../components/WebComponents/AutoInvestHistory/AutoInvestGoldHistory/AutoInvestGoldHistory';
import serviceAutoInvest from '../../../service/autoInvest';
import { useAppContext } from '../../../context/DataContext';
import NotificationMessagePopup from '../../../components/common/Popups/NotificationMessagePopup/NotificationMessagePopup';

function WebAutoInvest() {
  const classes = useStyles();
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTransactions, setActiveTransactions] = useState(0);
  const [upcomingCoins, setUpcomingCoins] = useState(0);
  const { proceedAutoInvestOrderClicked, isInStaking, setIsInStaking } = useAppContext();

  const closePopups = () => {
    setErrorPopup(false);
  };

  const getAllActiveAutoInvestTransactions = useCallback(async () => {
    try {
      const response = await serviceAutoInvest.getAllActiveAutoInvest();
      const allAutoInvestedCoins = response?.activeTransaction;
      if (!allAutoInvestedCoins) {
        setActiveTransactions(0);
      }
      setActiveTransactions(allAutoInvestedCoins);
    } catch (error) {
      const customError = JSON.parse(error?.message);
      setErrorMessage(customError?.message);
      setErrorPopup(true);
    }
  }, []);

  const getAllUpcomingCoins = useCallback(async () => {
    try {
      const response = await serviceAutoInvest.getAllAutoInvestUpcomingCoinsTillEndOfCurrentMonth();
      const upcomingCoins = response.upcomingCoins;
      if (!upcomingCoins) {
        setUpcomingCoins(0);
      }
      setUpcomingCoins(upcomingCoins);
    } catch (error) {
      const customError = JSON.parse(error?.message);

      setErrorPopup(true);
      setErrorMessage(customError.message);
    }
  }, []);

  useEffect(() => {
    getAllUpcomingCoins(setUpcomingCoins);
    getAllActiveAutoInvestTransactions(setActiveTransactions);
  }, [proceedAutoInvestOrderClicked, getAllUpcomingCoins, getAllActiveAutoInvestTransactions]);

  useEffect(() => {
    setIsInStaking(false);
  }, []);

  return (
    <WebLayout>
      {errorPopup && (
        <NotificationMessagePopup
          type={'error'}
          isPopupOpen={errorPopup}
          setIsPopupOpen={closePopups}
          message={errorMessage}
          handleCloseAutoInvestPopup={closePopups}
        />
      )}

      <Box>
        <Box sx={{ marginLeft: 2, marginRight: 2 }}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
              <Grid container>
                <Grid container alignItems="stretch" direction="row" marginBottom="1rem" marginTop="0px" spacing="16px">
                  <Grid item xs={5} md={5} sm={5} xl={5} lg={5}>
                    <TotalBalanceInAutoInvest
                      leftText={'Active Auto Invest Orders'}
                      rightText={'Upcoming Monthly Coins'}
                      rightValue={activeTransactions}
                      leftValue={upcomingCoins}
                    />
                  </Grid>
                  <Grid item xs={7} md={7} sm={7} xl={7} lg={7} style={{ height: '100%' }}>
                    <AutoInvestCard />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
                    <AutoInvestHistory containerClassName={classes.container} />
                  </Grid>
                </Grid>
                <Grid container alignItems="stretch" direction="row" marginBottom="1rem" marginTop="1rem">
                  <Grid item xs={7.5} md={7.5} sm={7.5} xl={8.2} lg={7.5} style={{ height: '100%' }}>
                    <Box className={classes.historyBox}>
                      <AutoInvestGoldHistory title={coinName} isInStaking={isInStaking} />
                    </Box>
                  </Grid>
                  <Grid item xs={4.5} md={4.5} sm={4.5} xl={3.8} lg={4.5} style={{ height: '100%' }}>
                    <Box className={classes.latestNewsContainer}>
                      <News isAutoInvest={true} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 1, marginLeft: 2, marginRight: 2 }}>
          <Grid container>
            <Grid item xs={6} md={6} sm={6} xl={6} lg={6}>
              <StakingNavigation />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </WebLayout>
  );
}

export default WebAutoInvest;

const useStyles = makeStyles((theme) =>
  createStyles({
    historyBox: {
      aspectRatio: 'auto',
      marginRight: '1rem',
      height: 'calc(100% - 34px)',
    },
    container: {
      width: '100%',
      backgroundColor: 'white',
      border: '1.41px',
      height: '17.75rem',
      borderRadius: '24px',
      background: 'linear-gradient(180deg, #E0E0E099, #FFFFFF,#FFFFFF,#FFFFFF)',
      boxShadow: '0px 20.772722244262695px 42.53462219238281px 0px #0000001A',
      '& .MuiTabs-indicator': {
        backgroundColor: 'black',
        height: '2px',
        width: '12rem !important',
        left: '1.8rem !important',
        [theme.breakpoints.down('1200')]: {
          width: '10rem !important',
        },
        [theme.breakpoints.down('1150')]: {
          width: '9rem !important',
        },
        [theme.breakpoints.down('1000')]: {
          width: '8.5rem !important',
        },
        [theme.breakpoints.down('800')]: {
          width: '8rem !important',
        },
      },
    },
    latestNewsContainer: {
      height: '96%',
    },
  }),
);
