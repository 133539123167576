import React from 'react';
import { Box } from '@mui/material';
import { createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import Wallet from '../../../../assets/images/wallet.svg';
import IButton from '../../../common/IButton/IButton';
import PLGold from '../../../common/PLGold/PLGold';
import Gauge1 from '../Gauge/Gauge1';

function PortfolioGauge() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <div className={classes.iButtonContainer}>
        <IButton tooltipTitle={tooltipContent.portfolioAllocation} />
      </div>
      <Box className={classes.title}>
        <Box>
          <img className={classes.imageWrapper} src={Wallet} alt="Wallet"></img>
        </Box>
        <Box>
          <span className={classes.portfolioText}>Portfolio</span>
        </Box>
      </Box>
      <div className={classes.portfolioPLContainer}>
        <div className={classes.text}>Portfolio P/L:</div>
        <PLGold />
      </div>
      <Gauge1 />
    </Box>
  );
}

export default PortfolioGauge;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      background: '#FFFFFF',
      fontFamily: theme.typography.fontFamily,
      height: '21%',
      top: '4%',
      borderRadius: '24px',
    },
    iButtonContainer: {
      position: 'relative',
      bottom: '19px',
    },
    title: {
      paddingLeft: '1rem',
      marginBottom: '1rem',
      letterSpacing: '0.5px',
      display: 'flex',
      justifyContent: 'flex-start',
      fontFamily: theme.typography.fontFamily,
    },
    portfolioText: {
      paddingLeft: '0.4rem',
      fontSize: '1.025rem',
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('1400')]: {
        fontSize: 'var(--font-size-13)',
      },
    },
    imageWrapper: {
      width: '1.2rem',
      height: '100%',
      [theme.breakpoints.down('1600')]: {
        width: '1rem',
      },
      [theme.breakpoints.down('1400')]: {
        width: '0.9rem',
      },
      [theme.breakpoints.down('1300')]: {
        width: '0.8rem',
      },
    },
    portfolioPLContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      justifyContent: 'left',
      marginLeft: '17px'
    },
    text: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '14px',
      [theme.breakpoints.down('1300')]: {
        fontSize: 'var(--font-size-13)',
      },
    },
  }),
);
