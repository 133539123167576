import { Box, Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import IButton from "../../../common/IButton/IButton";
import { tooltipContent } from "../../../../constants/tooltipTexts";
import { getTheNamesOfPreviousMonths } from "../../../../helpers/getTheNamesOfPreviousMonths";
import { generateCurrencySymbol } from "../../../../helpers/generateCurrencySymbol";

ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const options = {
  plugins: {
    legend: {
      display: true,
      align: "start",
      onClick: () => {},
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
        font: (context) => {
          const containerHeight = context.chart.height;
          const fontSize = Math.floor(containerHeight / 9);
          return {
            size: fontSize,
          };
        },
      },
    },
    tooltip: {
      enabled: true,
      yAlign: "bottom",
      displayColors: false,
      backgroundColor: "#E0C38B",
      callbacks: {
        label: (context) => {
          const datasetLabel = context.dataset.label || "";
          const value = context.parsed.y || 0;
          const fixedValue = value.toFixed(2);
          let currencySymbol = generateCurrencySymbol();
          return `${datasetLabel}: ${currencySymbol}${fixedValue}`;
        },
      },
    },
  },
  indexAxis: "x",
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      barPercentage: 0.6,
      categoryPercentage: 0.7,
      ticks: {
        font: (context) => {
          const containerHeight = context.chart.height;
          const fontSize = Math.floor(containerHeight / 10);
          return {
            size: fontSize,
          };
        },
      },
    },
    y: {
      position: "right",
      ticks: {
        callback: function (value) {
          return value;
        },
        font: (context) => {
          const containerHeight = context.chart.height;
          const fontSize = Math.floor(containerHeight / 12);
          return {
            size: fontSize,
          };
        },
      },
    },
  },
  borderRadius: 100,
  barThickness: 10,
};

function TotalMonthlyPoolTop({
  chart1,
  chart2,
  containerClass,
  tabClass,
  isInStaking,
  pool,
}) {
  const [value, setValue] = useState(0);
  const classes = useStyles();

  const isDemoMode = sessionStorage.getItem("isDemo") === "true";
  let currencySymbol = generateCurrencySymbol();

  const adjustData = (data) => {
    if (isDemoMode && currencySymbol === "€") {
      return data.map(value => value * 0.93);
    }
    return data;
  };

  const userDividends = adjustData([
    pool?.user?.month6,
    pool?.user?.month5,
    pool?.user?.month4,
    pool?.user?.month3,
    pool?.user?.month2,
    pool?.user?.month1,
  ]);

  const totalDividends = adjustData([
    pool?.all?.month6,
    pool?.all?.month5,
    pool?.all?.month4,
    pool?.all?.month3,
    pool?.all?.month2,
    pool?.all?.month1,
  ]);

  const data7 = {
    labels: getTheNamesOfPreviousMonths(new Date(), 6),
    datasets: [
      {
        label: "Dividend",
        data: userDividends,
        backgroundColor: ["black", "black", "black", "black", "black", "black"],
        borderWidth: 3,
        borderColor: "white",
      },
      {
        label: "Total",
        data: totalDividends,
        backgroundColor: [
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
          "#E0C38B",
        ],
        borderWidth: 3,
        borderColor: "white",
      },
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={containerClass}>
      <Box className={classes.header}>
        <Box className={classes.title}>TOTAL MONTHLY POOL</Box>
        {!isInStaking && (
          <IButton tooltipTitle={tooltipContent.totalMonthlyPool} />
        )}
      </Box>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={tabClass}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <Tab label="THIS MONTH" {...a11yProps(0)} />
          <Tab label="ALL TIME" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {chart1}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {chart2}
      </TabPanel>
      {!isInStaking && <Divider className={classes.divider} />}
      {!isInStaking ? (
        <Box className={classes.barchartWrapper}>
          <Bar data={data7} options={options} />
        </Box>
      ) : null}
    </Box>
  );
}

export default TotalMonthlyPoolTop;

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
      fontFamily: theme.typography.fontFamily,
    },
    title: {
      fontWeight: "bolder",
      fontSize: "var(--font-size-20)",
      letterSpacing: "0.8px",
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down("1750")]: {
        fontSize: "var(--font-size-18)",
      },
      [theme.breakpoints.down("1700")]: {
        fontSize: "var(--font-size-16)",
      },
      [theme.breakpoints.down("1500")]: {
        fontSize: "var(--font-size-14)",
      },
      [theme.breakpoints.down("1250")]: {
        fontSize: "var(--font-size-12)",
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: "var(--font-size-16)",
      },
      [theme.breakpoints.down("900")]: {
        fontSize: "var(--font-size-14)",
      },
      [theme.breakpoints.down("810")]: {
        fontSize: "var(--font-size-12)",
      },
    },
    divider: {
      marginTop: "26%",
      marginBottom: "26%",
    },
    barchartWrapper: {
      marginTop: "1rem",
      height: "153px",
      paddingBottom: "1rem",
      display: "flex",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      alignItems: "center",
      [theme.breakpoints.down("1550")]: {
        height: "10rem",
        paddingLeft: "0.7rem",
        paddingRight: "0.7rem",
      },
      [theme.breakpoints.down("1500")]: {
        height: "11rem",
        paddingLeft: "0.2rem",
        paddingRight: "0.2rem",
      },
      [theme.breakpoints.down("1400")]: {
        height: "11rem",
        width: "14rem",
        paddingLeft: "0.2rem",
        paddingRight: "0.2rem",
        paddingBottom: "0px",
        marginTop: "0rem",
      },
      [theme.breakpoints.down("1353")]: {
        height: "11rem",
        width: "13rem",
        paddingLeft: "0.2rem",
        paddingRight: "0.2rem",
        paddingBottom: "0px",
        marginTop: "0rem",
      },
      [theme.breakpoints.down("1285")]: {
        height: "11rem",
        width: "12rem",
        alignItems: "center",
        textAlign: "center",
        paddingLeft: "0.2rem",
        paddingRight: "0.2rem",
        paddingBottom: "0px",
        marginTop: "0rem",
      },
      [theme.breakpoints.down("1200")]: {
        height: "11.6rem",
        width: "auto",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "2rem",
      },
      [theme.breakpoints.down("1050")]: {
        height: "13rem",
      },
      [theme.breakpoints.down("950")]: {
        height: "13.5rem",
      },
      [theme.breakpoints.down("905")]: {
        height: "12rem",
      },
      [theme.breakpoints.down("900")]: {
        height: "9rem",
      },
      [theme.breakpoints.down("850")]: {
        height: "8.8rem",
        width: "11rem",
      },
      [theme.breakpoints.down("820")]: {
        height: "7.8rem",
        width: "11rem",
      },
      [theme.breakpoints.down("810")]: {
        height: "9rem",
        width: "11rem",
      },
    },
  })
);
