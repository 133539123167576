import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MobileGoldHistory from "../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import MobileStakingButtons from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileStakingButtons/MobileStakingButtons";
import StakeInfo from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/StakeInfo/StakeInfo";
import MobileStakingHistoryTable from "../../../components/MobileComponents/MobileStakingHistory/MobileStakingHistory";
import { useAppContext } from "../../../context/DataContext";
import { useEffect } from "react";
import { routes } from "../../../routes";
import { useState } from "react";
import AppLayout from "../../../components/common/Layout/AppLayout";

function AppStakingHistory() {
  const classes = useStyles();
  const { setIsInHistory } = useAppContext();
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    setIsInHistory(true);
    setShowComponent(true);
  }, []);
  return (
    <AppLayout>
      {showComponent && (
        <Box className={classes.container}>
          <MobileNavigationBack
            page={"Yielding History"}
            route={routes.yielding}
          />
          <MobileGoldHistory />
          <StakeInfo />
          <div className={classes.wrapper}>
            <Box className={classes.table}>
              <MobileStakingHistoryTable />
            </Box>
            <div className={classes.helperContainer}>
              <Box className={classes.actionButtons}>
                <MobileStakingButtons
                  firstButtonName={"YIELD GOLD"}
                  category="stake"
                />
              </Box>
            </div>
          </div>
        </Box>
      )}
    </AppLayout>
  );
}

export default AppStakingHistory;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  wrapper: {
    height: "calc(100vh - 260px)",
  },
  table: {
    height: "calc(100vh - 307px)",
    minHeight: "200px",
  },
  helperContainer: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    bottom: "6px",
    height: "146px",
    "@media (orientation: landscape) and (max-height: 608px)": {
      position: "relative",
      bottom: "55px",
    },
  },
  actionButtons: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    left: 0,
    width: "100%",
    background: "var(--linear-gradient-white)",
  },
}));
