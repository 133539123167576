import React from "react";
import WebLayout from "../../../components/common/Layout/WebLayout";
import { makeStyles } from "@mui/styles";
import { Box, createStyles } from "@mui/material";
import PaytikoDeposit from "../../../components/WebComponents/PaytikoDeposit/PaytikoDeposit";
import StakingNavigation from "../../../components/WebComponents/StakingNavigation/StakingNavigation";

function WebDeposit({ isMobile }) {
  const classes = useStyles();
  return (
    <WebLayout>
      <div className={classes.container}>
        <Box sx={{ height: "100%" }}>
          <PaytikoDeposit isMobile={isMobile} />
        </Box>
        <div className={classes.backButton}>
          <StakingNavigation />
        </div>
      </div>
    </WebLayout>
  );
}

export default WebDeposit;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "98%",
      height: "calc(100dvh - 130px)",
    },
    backButton: {
      paddingLeft: "2rem",
    },
  })
);
