import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../CustomButton/CustomButton";
import { makeStyles } from "@mui/styles";
import AutoInvestCardPopup from "./AutoInvestCardPopup/AutoInvestCardPopup";
import "../../../../styles/global.fonts.css";
import { useAppContext } from "../../../../context/DataContext";
import { GoldToMoneyConverter } from "../../../../helpers/goldToMoneyConverter";
import isMobileDevice from "../../../../helpers/currencyFormatter/isMobileDevice";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AutoInvestPopup({ type, isPopupOpen, setIsPopupOpen }) {
  const classes = useStyles({ type });
  const [quantity, setQuantity] = useState(0);
  const [stakingPeriod, setStakingPeriod] = useState(0);
  const { goldBidPriceForGroup } = useAppContext();
  const total = GoldToMoneyConverter(quantity, goldBidPriceForGroup);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleSetQuantity = (quantity) => {
    setQuantity(quantity);
  };

  const handleStakingPeriod = (period) => {
    setStakingPeriod(period);
  };
  function handleAutoInvestClicked() {
    isMobileDevice();
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div
          className={classes.popupContent}
          style={{ backgroundColor: type === "buy" ? "white" : "#DCDCDC" }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.popupTitle}
          >
            <AutoInvestCardPopup
              quantity={quantity}
              handleSetQuantity={handleSetQuantity}
              stakingPeriod={stakingPeriod}
              handleStakingPeriod={handleStakingPeriod}
              price={goldBidPriceForGroup}
              total={total}
              setQuantity={setQuantity}
            />
          </BootstrapDialogTitle>
          <DialogActions className={classes.proceedBtnContainer}>
            <div className={classes.greenButton}>
              <CustomButton
                type="green"
                buttonText="Auto Invest"
                width="100%"
                height="100%"
                onClick={handleAutoInvestClicked}
              />
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default AutoInvestPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "1000px",
      width: "650px",
      [theme.breakpoints.down("1800")]: {
        width: "600px",
      },
      [theme.breakpoints.down("1500")]: {
        width: "500px",
      },
      [theme.breakpoints.down("1300")]: {
        width: "460px",
      },
    },
  },
  popupContent: {
    position: "relative",
  },
  popupTitle: {
    borderRadius: "24px",
    fontSize: "var(--font-size-19)",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "0px",
    fontWeight: "bold",
    height: "100%",
    zIndex: 1,
    fontFamily: theme.typography.fontFamily,
  },
  proceedBtnContainer: {
    position: "relative",
    justifyContent: "center",
    height: "50px",
    paddingBottom: "20px !important",
  },
  greenButton: {
    width: "9rem",
    height: "2.5rem",
    display: "inline-block",
    justifyContent: "center",
    position: "relative",
    bottom: "-1%",
    "& .MuiTypography-button": {
      fontSize: "var(--font-size-16) !important",
      [theme.breakpoints.down("1300")]: {
        fontSize: "var(--font-size-14) !important",
      },
    },
    [theme.breakpoints.down("1300")]: {
      width: "8rem",
    },
  },
}));
