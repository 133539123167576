import React, { createContext, useContext, useState } from "react";

const NotificationsContext = createContext({});

export function useNotificationsContext() {
  return useContext(NotificationsContext);
}

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        page,
        setPage,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
