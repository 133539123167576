import { Tooltip } from '@mui/material';
import React, { memo } from 'react';

const InstrumentNameTooltip = ({ name, className }) => (
  <Tooltip title={name} placement="top">
    <span className={className}>{name}</span>
  </Tooltip>
);

export default memo(InstrumentNameTooltip);
