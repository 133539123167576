import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useAppContext } from '../../../context/DataContext';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/material';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import { useState } from 'react';
import { getWithDrawIframe } from '../../../helpers/getWithDrawIframe';

function WithdrawComponent() {
  const { withdrawIframeUrl, setWithDrawIframeUrl, isMobile, activeTradeAccountId } = useAppContext();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getWithDrawIframe(setWithDrawIframeUrl, setLoading);
  }, [activeTradeAccountId]);

  return withdrawIframeUrl?.url ? (
    <Box className={classes.container}>
      <iframe src={withdrawIframeUrl?.url} className={!isMobile ? classes.withdraw : classes.withdrawMobile}></iframe>
      <LoadingBar open={loading} />
    </Box>
  ) : null;
}

export default WithdrawComponent;

const useStyles = makeStyles(() =>
  createStyles({
    withdraw: {
      container: {
        width: '100%',
        height: '95%',
        borderWidth: 0,
        position: 'relative',
        border: 'none !important',
      },
      paddingTop: '1rem',
      width: '100%',
      height: '550px',
      border: 'none !important',
    },
    withdrawMobile: {
      width: '100%',
      height: '100%',
      border: 'none !important',
    },
  }),
);
