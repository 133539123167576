import { Box, TextField, createStyles } from "@mui/material";
import React from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import CustomButton from "../../common/CustomButton/CustomButton";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import clsx from "clsx";
import { useAppContext } from "../../../context/DataContext";
import { handleChangeUserPassword } from "../../../helpers/handleChangeUserPassword";
import NotificationMessagePopup from "../../common/Popups/NotificationMessagePopup/NotificationMessagePopup";
import LoadingBar from "../../common/LoadingBar/LoadingBar";
import { handlePleaseLogin } from "../../../helpers/pleaseLoginOrRegisterForDemo";
import PleaseLoginPopUp from "../../common/Popups/PleaseLoginPopup/PleaseLoginPopup";
import { errorHandlingWrapper } from "../../../helpers/errorHandlingWrapper";

function ChangePassword() {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    popUpMessage,
    setPopUpMessage,
    notificationPopupType,
    setNotificationPopupType,
    proceedChangePasswordClicked,
    setProceedChangePasswordClicked,
  } = useAppContext();

  async function handleSubmit() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    if (newPassword !== confirmPassword) {
      setPopUpMessage("New Password and Confirm password do not match");
      setNotificationPopupType("error");
      setProceedChangePasswordClicked(true);
      return;
    }

    if (oldPassword === newPassword) {
      setPopUpMessage("New password must be different than the Old password");
      setNotificationPopupType("error");
      setProceedChangePasswordClicked(true);
      return;
    }

    const changedPassword = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    setLoading(true);
    try {
      const response = await handleChangeUserPassword(changedPassword);
      if (response.IsSuccessed) {
        setOldPassword("");
        setConfirmPassword("");
        setNewPassword("");
        setPopUpMessage("You successfully changed the password");
        setNotificationPopupType("success");
        setProceedChangePasswordClicked(true);
      } else {
        setOldPassword("");
        setConfirmPassword("");
        setNewPassword("");
        setPopUpMessage("Error occurred");
        setNotificationPopupType("error");
        setProceedChangePasswordClicked(true);
      }
    } catch (e) {
      setOldPassword("");
      setConfirmPassword("");
      setNewPassword("");
      let customError = null;
      customError = errorHandlingWrapper(e, customError);
      setPopUpMessage(customError?.message);
      setNotificationPopupType("error");
      setProceedChangePasswordClicked(true);
    } finally {
      setLoading(false);
    }
    return 0;
  }
  return (
    <Box className={clsx(classes.container, classes.changePasswordWrapper)}>
      <Box className={classes.title}>Change Password</Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          className={classes.passwordField}
          label="Old Password"
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          fullWidth
          required
        />
        <TextField
          className={classes.passwordField}
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          required
        />
        <PasswordStrengthBar
          password={newPassword}
          className={classes.passwordStrengthBar}
        />
        <TextField
          className={classes.passwordField}
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          required
        />
        <Box className={classes.buttonWrapper}>
          <CustomButton
            type="black"
            buttonText="Change"
            width={window.innerWidth > window.innerHeight ? "20%" : "50%"}
            height="100%"
            actionType="submit"
          ></CustomButton>
        </Box>
      </form>
      <LoadingBar open={loading} />
      {proceedChangePasswordClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === "success" ? "success" : "error"}
          isPopupOpen={proceedChangePasswordClicked}
          setIsPopupOpen={setProceedChangePasswordClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
    </Box>
  );
}

export default ChangePassword;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      alignItems: "center",
      justifyContent: "center",
      fontFamily: theme.typography.fontFamily,
      margin: "auto",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      lineHeight: "1.2rem",
      marginTop: "1rem",
      fontSize:
        "clamp(var(--font-size-15), var(--font-size-14), var(--font-size-13)) !important",
      fontWeight: "normal",
      [theme.breakpoints.down("350")]: {
        fontSize: "var(--font-size-11) !important",
      },
      [theme.breakpoints.down("330")]: {
        fontSize: "var(--font-size-10) !important",
      },
    },
    title: {
      fontSize:
        "clamp(var(--font-size-20), var(--font-size-18), var(--font-size-16)) !important",
      fontWeight: 600,
      paddingLeft: "2rem",
      paddingRight: "2rem",
      marginTop: "2rem",
    },
    passwordField: {
      "& .MuiInputBase-input": {
        height: "1rem",
        [theme.breakpoints.down("100")]: {
          height: "0.7rem",
        },
      },
      "& .MuiInputBase-root": {
        padding: 0,
        margin: 0,
      },
      marginTop: "1rem",
    },
    buttonWrapper: {
      marginTop: "1rem",
      maxHeight: "2rem",
      height: "3rem",
      "@media (orientation: landscape)": {},
    },
    passwordStrengthBar: {
      width: "100%",
      paddingRight: "1rem",
      "& p": {
        display: "none",
      },
    },
  })
);
