import { makeStyles } from "@mui/styles";

import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import clsx from "clsx";

export const ViewSelector = ({ type, setProductsView, isWeb }) => {
  const classes = useStyles();

  if (isWeb) {
    return (
      <div className={classes.views}>
        <GridViewIcon
          onClick={() => {
            localStorage.setItem("storeView", "grid");
            setProductsView("grid");
          }}
          className={clsx(
            classes.viewIcon,
            type === "grid" && classes.activeViewIcon
          )}
        />
        <ViewListIcon
          onClick={() => {
            localStorage.setItem("storeView", "list");
            setProductsView("list");
          }}
          className={clsx(
            classes.viewIcon,
            type === "list" && classes.activeViewIcon
          )}
        />
      </div>
    );
  }
  if (type === "list") {
    return (
      <div className={classes.views}>
        <GridViewIcon
          onClick={() => {
            setProductsView("grid");
            localStorage.setItem("storeView", "grid");
          }}
          className={classes.viewIcon}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.views}>
        <ViewListIcon
          onClick={() => {
            setProductsView("list");
            localStorage.setItem("storeView", "list");
          }}
          className={classes.viewIcon}
        />
      </div>
    );
  }
};

const useStyles = makeStyles(() => ({
  viewIcon: {
    fontSize: "30px",
  },

  views: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 20,
    marginBottom: "10px",
    cursor: "pointer",
  },

  activeViewIcon: {
    color: "#48D0A4",
  },
}));
