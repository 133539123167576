import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import snsWebSdk from "@sumsub/websdk";
import { useEffect } from "react";
import LoadingBar from "../../common/LoadingBar/LoadingBar";
import { userService } from "../../../service/user";

function renderSumSubIframe(sumSubCallBack, loading, accessToken) {
  loading(true);
  try {
    sumSubCallBack(accessToken);
  } catch (e) {
    console.log("Some error occured with loading sum sub", e);
  } finally {
    loading(false);
  }
}

function launchWebSdk(accessToken) {
  let snsWebSdkInstance = snsWebSdk
    .init(accessToken, () => this.getNewAccessToken())
    .withConf({
      lang: "en",
    })
    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
    .on("idCheck.onStepCompleted", (payload) => {
      console.log("onStepCompleted", payload);
    })
    .on("idCheck.onError", (error) => {
      console.log("onError", error);
    })
    .onMessage((type, payload) => {
      console.log("onMessage", type, payload);
    })
    .build();
  snsWebSdkInstance.launch("#sumsub-websdk-container");
}

function SumSubComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getSumSubToken = async () => {
      try {
        const tokenFromRes = await userService.getSumSubAccessToken(setLoading);
        setToken(tokenFromRes?.token);
      } catch (error) {
        console.error("Error fetching SumSub access token:", error);
      } finally {
        setLoading(false);
      }
    };

    getSumSubToken();
  }, []);

  useEffect(() => {
    if (token) {
      renderSumSubIframe(launchWebSdk, setLoading, token);
    }
  }, [token]);

  return (
    <Box className={classes.container}>
      <div id="sumsub-websdk-container"></div>
      <LoadingBar open={loading} />
    </Box>
  );
}

export default SumSubComponent;

const useStyles = makeStyles(() =>
  createStyles({
    sumSub: {
      paddingTop: "1rem",
      width: "100%",
      height: "88vh",
      border: "none !important",
    },
    sumSubMobile: {
      width: "100%",
      height: "100%",
      border: "none !important",
    },
    container: {
      width: "100%",
      height: "100%",
      borderWidth: 0,
      position: "relative",
    },
  })
);
