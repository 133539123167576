import React, { useState, useEffect, useCallback } from "react";
import { getFees } from "../../../service/fees";

export const useUserFees = () => {
  const [stakeFee, setStakeFee] = useState();
  const [stakeFeeFlat, setStakeFeeFlat] = useState();
  const [stakeFeeForGroup, setStakeFeeForGroup] = useState();
  const [stakeFeeForGroupFlat, setStakeFeeForGroupFlat] = useState();

  const [vaultFee, setVaultFee] = useState();
  const [vaultFeeFlat, setVaultFeeFlat] = useState();
  const [vaultFeeForGroup, setVaultFeeForGroup] = useState();
  const [vaultFeeForGroupFlat, vaultStakeFeeForGroupFlat] = useState();

  const getAllFees = useCallback(async () => {
    try {
      const response = await getFees();
      setStakeFee(response?.stakeFee?.feePercentage);
      setStakeFeeFlat(response?.stakeFee?.feeFlat);
      setStakeFeeForGroup(response?.stakeFeeForGroup?.feePercentage);
      setStakeFeeForGroupFlat(response?.stakeFeeForGroup?.feeFlat);

      setVaultFee(response?.vaultFee?.feePercentage);
      setVaultFeeFlat(response?.vaultFee?.feeFlat);
      setVaultFeeForGroup(response?.vaultFeeForGroup?.feePercentage);
      vaultStakeFeeForGroupFlat(response?.vaultFeeForGroup?.feeFlat);
      // TODO: cache request
      // from the req. return userGroupId and currentDay
      // save all fees to local storage together with current day, save userGroupId to local storage
      // when opening StakeVaultCard:
      //  check userGroupId: if it is different, make the request. If it's same :
      //  check if current day === now, if not make the request, If yes, don't make the req and read values from localStorage
      // currentDay format: 2024-03-27
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getAllFees();
  }, [getAllFees]);

  return {
    stakeFee,
    stakeFeeFlat,
    stakeFeeForGroup,
    stakeFeeForGroupFlat,
    vaultFee,
    vaultFeeFlat,
    vaultFeeForGroup,
    vaultFeeForGroupFlat,
  };
};
