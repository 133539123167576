import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import "../../../../styles/global.styles.css";
import "../../../../styles/global.fonts.css";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import { useState } from "react";
import NotificationMessagePopup from "../NotificationMessagePopup/NotificationMessagePopup";
import { messages } from "../../../../constants/popupMessages";

let steps = ["Step 1", "Step 2", "Step 3"];

const HorizontalStepperWeb = ({
  stepsComponents,
  activeStep,
  setActiveStep,
  handleSubmit,
  quantity,
  getDeliveryFee,
  chosenAddress,
  setIsAddNewAddress,
  partNumber,
  deliveryFee,
  isRedeemFeeLoading,
  isStoreStepper = false,
}) => {
  const classes = useStyles();
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);

  if (isStoreStepper) {
    steps = ["", ""];
  }

  const handleNext = () => {
    if (!isStoreStepper) {
      if (activeStep === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 1) {
        if (
          chosenAddress?.addressLine &&
          chosenAddress?.city &&
          chosenAddress?.countryName &&
          chosenAddress?.zipCode
        ) {
          getDeliveryFee(
            chosenAddress?.countryCode,
            chosenAddress?.zipCode,
            partNumber
          );
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setErrorPopupOpen(true);
        }
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsAddNewAddress(false);
  };

  const handleSubmitClicked = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleSubmit();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step
            style={{
              display: "none",
            }}
            key={label}
          >
            <StepLabel className={classes.stepLabel}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.contentContainer}>
        <Typography
          variant="div"
          component="div"
          sx={{
            mt: 5,
            mb: 1,
          }}
        >
          {stepsComponents[activeStep]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: activeStep === 0 ? "center" : "space-between",
            alignItems: "center",
            pt: 2,
          }}
        >
          {activeStep !== 0 && (
            <Button
              color="inherit"
              variant="contained"
              startIcon={<ArrowLeftOutlinedIcon />}
              onClick={handleBack}
              sx={{
                mr: 1,
                color: "white",
                boxShadow: "0px 4px 0px 0px #00000026;",
                width: "100px !important",
              }}
              className={classes.backButton}
            >
              Back
            </Button>
          )}
          {activeStep !== steps.length - 1 ? (
            <Button
              variant="contained"
              endIcon={<ArrowRightOutlinedIcon />}
              disabled={isStoreStepper ? false : quantity === 0}
              onClick={handleNext}
              className={classes.nextButton}
              sx={{
                color: "white !important",
                backgroundColor: "black !important",
                boxShadow: "0px 4px 0px 0px #00000026 !important",
              }}
            >
              Next Step
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmitClicked}
              className={classes.nextButton}
              disabled={isRedeemFeeLoading}
              sx={{
                color: "white !important",
                backgroundColor: "black !important",
                width: "100px !important",
              }}
            >
              Submit
            </Button>
          )}
        </Box>
      </div>
      {errorPopupOpen && (
        <NotificationMessagePopup
          type={"error"}
          isPopupOpen={errorPopupOpen}
          setIsPopupOpen={setErrorPopupOpen}
          message={messages.addressField}
          hasOkButton={true}
        />
      )}
    </Box>
  );
};

export default HorizontalStepperWeb;

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    "& .Mui-active, & .Mui-completed ": {
      color: "#1AC88D !important",
    },
    "& .MuiStepLabel-alternativeLabel": {
      fontSize: "var(--font-size-16)",
      fontWeight: "400",
      fontFamily: theme.typography.fontFamily,
      marginTop: "0px",
    },
    "& .MuiStepIcon-root": {
      width: "43px",
      zIndex: "1",
    },
    "& .MuiStepIcon-completed": {
      fill: "white",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "280px",
  },
  nextButton: {
    borderRadius: "38px",
    fontSize: "clamp(12px, 2vw, 18px)",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
    width: "160px",
    boxShadow: "0px 4px 0px 0px #00000026;",
  },
  backButton: {
    backgroundColor: "#989898",
    borderRadius: "60px",
    fontSize: "clamp(12px, 2vw, 18px)",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      backgroundColor: "#989898",
    },
  },
}));
