import * as React from "react";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.styles.css";
import "../../../../styles/global.fonts.css";
import RedeemGoldCoin from "../../../../assets/images/redeem-gold-coin.svg";
import Button from "@mui/material/Button";
import { messages } from "../../../../constants/popupMessages";
import { routes } from "../../../../routes";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/DataContext";

const MessageContentWeb = ({ type, error, handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView } = useAppContext();
  const [showDepositButton, setShowDepositButton] = React.useState(false);

  React.useEffect(() => {
    if (
      error ===
      "Your order can not be executed due to insufficient funds. Please top-up the account and try again."
    ) {
      setShowDepositButton(true);
    }
  }, []);

  function navigateToDeposit() {
    handleClose();

    if (isAppView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        '{"Event": "Deposit"}'
      );
    } else {
      navigate(routes.deposit);
    }
  }

  return (
    <div className={classes.container}>
      <div
        style={{
          color: type === "success" ? "#589E7D" : "red",
          fontSize:
            type === "success"
              ? "clamp(14px, 2vw, 18px)"
              : "clamp(18px, 2vw, 25px)",
        }}
        className={classes.message}
      >
        {type === "success" ? "Redeem Successful" : "Redeem Unsuccessful"}
      </div>
      <div
        className={classes.info}
        dangerouslySetInnerHTML={{
          __html: type === "success" ? messages.orderSuccessful : error,
        }}
      ></div>
      {type === "success" && (
        <div className={classes.imageContainer}>
          <img
            src={RedeemGoldCoin}
            alt="Redeem gold icon"
            className={classes.imageIcon}
          />
        </div>
      )}
      <div className={classes.buttonContainer}>
        {showDepositButton && (
          <Button
            className={classes.depositFundsBtn}
            onClick={navigateToDeposit}
            startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            DEPOSIT FUNDS
          </Button>
        )}
      </div>
    </div>
  );
};

export default MessageContentWeb;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    fontWeight: "700",
    fontFamily: theme.typography.fontFamily,
    marginBottom: "15px",
  },
  info: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    fontSize: "clamp(14px, 2vw, 18px)",
    fontWeight: "400",
    fontFamily: theme.typography.fontFamily,
    color: "black",
    marginBottom: "30px",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    marginBottom: "40px",
    minHeight: "106px",
  },
  imageIcon: {
    width: "16%",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  closeBtn: {
    borderRadius: "60px",
    fontSize: "clamp(14px, 2vw, 18px)",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
    width: "clamp(180px, 5vw, 200px)",
    boxShadow: "0px 4px 0px 0px #00000026;",

    "&:hover": {
      backgroundColor: "black",
    },
  },

  depositFundsBtn: {
    width: "10rem",
    cursor: "pointer",
    minWidth: "75px",
    backgroundColor: "var(--gold)",
    color: "var(--white)",
    fontWeight: 600,
    fontSize: "0.8rem",
    borderRadius: "1.2rem",
    "&:hover": {
      background: "var(--gold) !important",
    },
    "& svg": {
      backgroundColor: "var(--gold)",
      color: "var(--white)",
    },
  },
}));
