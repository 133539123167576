import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import AppHeader from "../Header/AppHeader";
import { useTutorialMobileDashboard } from "../../../pages/Mobile/MobileDashboard/use-tutorial-mobile-dashboard";
import TutorialWelcomeModal from "../tutorialWelcomeModal/tutorialWelcomeModal";
import TutorialTooltipMobile from "../tutorialTooltipMobile/tutorialTooltipMobile";
import TutorialEndModal from "../tutorialEndModal/tutorialEndModal";
import { useAppContext } from "../../../context/DataContext";
import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as CartIcon } from "../../../assets/images/cart-icon-mobile.svg";
import { useNavigate } from "react-router-dom";

function AppLayout({ children, isInNotificationsComponent = false }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [bucketCount, setBucketCount] = React.useState(0);

  const {
    isMobile,
    isAppView,
    isLoading,
    tutorialStep,
    elementProps,
    isOnHomePage,
    setTutorialStep,
  } = useTutorialMobileDashboard();
  const {
    isTextUpdated,
    isHeightUpdated,
    arrowPositionUpdated,
    isRedeemGoldPopupOpen,
    tooltipPositionUpdated,
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
    isBuySellPopupOpen,
    productsBucket,
  } = useAppContext();
  const isDemo = sessionStorage.getItem("isDemo") === "true";

  useEffect(() => {
    if (isBuySellPopupOpen) {
      const tradeGoldBtn = document.getElementById("trade-gold-btn-trigger");

      if (tradeGoldBtn && tradeGoldBtn?.classList) {
        tradeGoldBtn?.classList.remove("except");
      }
    }
  }, [isBuySellPopupOpen]);

  useEffect(() => {
    if (productsBucket) {
      let totalQuantity = 0;

      for (const productId in productsBucket) {
        totalQuantity += productsBucket[productId].quantity;
      }
      setBucketCount(totalQuantity);
    }
    if (isDemo) {
      setBucketCount(4);
    }
  }, [productsBucket, isDemo]);

  const navigateToCartsPage = () => {
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        '{"Event": "Cart"}'
      );
    } else {
      navigate("/cart");
    }
  };

  const isInCart = location.pathname === "/cart";

  return (
    <Box id="main-container" className={classes.container}>
      {tutorialStep > 0 &&
        isOnHomePage &&
        sessionStorage?.getItem("tutorialStep") &&
        tutorialStep < 14 &&
        !isDemo &&
        arrowPositionUpdated &&
        isHeightUpdated &&
        tooltipPositionUpdated &&
        !isRedeemGoldPopupOpen &&
        !isBuySellPopupOpen &&
        !proceedSellOrderClicked &&
        !proceedBuyOrderClicked &&
        isTextUpdated && (
          <>
            <TutorialTooltipMobile
              isRedeemGoldPopupOpen={isRedeemGoldPopupOpen}
            />
          </>
        )}
      <TutorialTooltipMobile isFakeTooltip />

      {isOnHomePage &&
        Number(tutorialStep) === 0 &&
        sessionStorage?.getItem("tutorialStep") &&
        !isDemo &&
        !isLoading && (
          <TutorialWelcomeModal isNative={isAppView} isMobile={!isAppView} />
        )}

      {isOnHomePage &&
        sessionStorage?.getItem("tutorialStep") &&
        Number(tutorialStep) === 14 &&
        !isDemo &&
        !isLoading && (
          <TutorialEndModal isNative={isAppView} isMobile={isMobile} />
        )}

      <Box className={classes.gridItem}>
        <AppHeader />
      </Box>
      <Box
        id="content-wrapper"
        className={
          isInNotificationsComponent
            ? clsx(classes.gridItem, classes.notificationsMain)
            : clsx(classes.gridItem, classes.main)
        }
      >
        {children}
      </Box>
      {!isAppView && !isInCart && (
        <div className={classes.cartIconWrapper} onClick={navigateToCartsPage}>
          <CartIcon
            style={{
              position: !bucketCount && "relative",
              left: !bucketCount && "5px",
            }}
          />

          {bucketCount && (
            <span className={classes.cartCounter}>{bucketCount}</span>
          )}
        </div>
      )}
    </Box>
  );
}

export default AppLayout;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // "@media min-width": {
    //   background: "var(--linear-gradient-grey-background-mobile)",
    // },
    "@media (min-height: 450px)": {
      height: "100%",
    },
    "@media (orientation: landscape)": {
      background: "var(--linear-gradient-grey-background-mobile)",
    },
  },
  gridItem: {
    width: "100%",
    height: "110px",
    minHeight: "86px",
    "@media (orientation: landscape)": {
      minHeight: "90px",
    },
  },
  main: {
    maxWidth: "900px",
    [theme.breakpoints.up("900")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    height: "calc(100% - 110px)",
    "@media only screen and (max-height: 400px)": {
      background: "var(--linear-gradient-grey-background-mobile)",
    },
    overflow: "hidden",
    overflowY: "auto",
    background: "var(--linear-gradient-grey-background-mobile)",
  },
  notificationsMain: {
    maxWidth: "900px",
    [theme.breakpoints.up("900")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    height: "85%",
    "@media (orientation: landscape)": {
      height: "80%",
      paddingBottom: "0.5rem",
    },
    overflow: "hidden",
    overflowY: "hidden",
  },
  cartIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    position: "fixed",
    bottom: "60px",
    right: "10px",

    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "black",
    boxShadow: "1px 1px 20px 0px rgba(0,0,0,0.75)",
    zIndex: 1000,
  },

  cartCounter: {
    position: "absolute",
    bottom: 40,
    right: 3,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: 16,
    height: 16,
    color: "white",
    fontWeight: 600,
    fontSize: "10px",
    borderRadius: "50%",
    background: "#FF4228",
  },
}));
