export function generateCurrencyType() {
  let currencyType = localStorage.getItem("currency") || "USD";

  if (currencyType === "EUR") {
    currencyType = "EUR";
  } else if (currencyType === "USD") {
    currencyType = "USD";
  }
  return currencyType;
}
