import * as React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '../../../context/DataContext';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import CustomButton from '../../common/CustomButton/CustomButton';
import TextField from '@mui/material/TextField';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import IButton from '../../common/IButton/IButton';
import { tooltipContent } from '../../../constants/tooltipTexts';
import clsx from 'clsx';
import { useCryptoContext } from '../../../context/CryptoContext';
import { useStore } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import InstrumentNameTooltip from '../PortfolioAssets/components/InstrumentNameTooltip';

const TransactionPanel = ({
  type,
  minAmount,
  fiatPrice,
  cryptoPrice,
  handleFiatInputChange,
  handleCryptoInputChange,
  setCryptoBuyPrice,
  setCryptoSellPrice,
  setFiatBuyPrice,
  setFiatSellPrice,
}) => {
  const classes = useStyles();
  const { isMobile } = useAppContext();
  const { activeInstrument } = useCryptoContext();
  const [assetPriceAsk, setAssetPriceAsk] = useState(0);
  const [assetPriceBid, setAssetPriceBid] = useState(0);

  const isBuy = type === 'buy';
  const displayName = activeInstrument?.displayName?.split('/')?.[0];
  const url = activeInstrument?.url;

  const store = useStore();

  useEffect(() => {
    const fetchPriceFromRedux = () => {
      const price = store.getState().crypto.instruments[activeInstrument?.marketId];

      setAssetPriceAsk(price?.ask);
      setAssetPriceBid(price?.bid);
    };

    fetchPriceFromRedux(); // Initial fetch
    const intervalId = setInterval(fetchPriceFromRedux, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [store, activeInstrument?.marketId]);

  const decimalsAsk = (assetPriceAsk && assetPriceAsk?.split('.')?.[1]?.length) || 0;
  const decimalsBid = (assetPriceBid && assetPriceBid?.split('.')?.[1]?.length) || 0;
  const assetPriceType = isBuy ? Number(assetPriceAsk || 0) : Number(assetPriceBid || 0);

  const handleCryptoInputFocus = () => {
    if (Number(cryptoPrice) === 0) {
      if (isBuy) {
        setCryptoBuyPrice('');
      } else {
        setCryptoSellPrice('');
      }
    }
  };

  const handleFiatInputFocus = () => {
    if (Number(fiatPrice) === 0) {
      if (isBuy) {
        setFiatBuyPrice('');
      } else {
        setFiatSellPrice('');
      }
    }
  };

  const handleInputBlur = () => {
    let counter = 0;

    if (Number(fiatPrice) === 0) {
      counter++;
      handleFiatInputChange({ target: { value: 'reset' } }, assetPriceType);
    }

    if (Number(cryptoPrice) === 0 && !counter) {
      handleCryptoInputChange({ target: { value: 0 } }, assetPriceType);
    }
  };

  const handleCryptoChange = (e) => {
    e.target.value = e?.target.value.replace(/,/g, '.');

    if (e.key === ',') {
      // e.preventDefault();
      e.target.value += '.';
      e.key = '.';
      e.preventDefault();
    }

    const isBackspace = e?.key === 'Backspace';
    const val = e.target.value;
    const leftPart = val.split('.')?.[0];

    const hasLeadingZero = leftPart?.startsWith('0') && leftPart?.length > 1 && cryptoPrice.length > 1;
    if (hasLeadingZero) {
      handleCryptoInputChange({ target: { value: val.slice(1) } }, assetPriceType);
    } else {
      handleCryptoInputChange(e, assetPriceType, isBackspace);
    }
  };

  const handleFiatChange = (e) => {
    e.target.value = e?.target.value.replace(/,/g, '.');

    const isBackspace = e?.key === 'Backspace';
    const val = e.target.value;
    const leftPart = val.split('.')?.[0];

    const hasLeadingZero = leftPart?.startsWith('0') && leftPart?.length > 1 && cryptoPrice.length > 1;
    if (hasLeadingZero) {
      handleFiatInputChange({ target: { value: val.slice(1) } }, assetPriceType);
    } else {
      handleFiatInputChange(e, assetPriceType, isBackspace);
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      <div>
        <div className={classes.fieldTitle}>
          <div className={classes.actionLabelWrapper}>
            I want to {type === 'buy' ? 'buy' : 'sell'}
            {isMobile && activeInstrument?.url && (
              <img
                alt="Bullionz logo"
                src={activeInstrument?.url}
                style={{ width: '15px', position: 'relative', left: '3%' }}
              />
            )}
          </div>
          <div className={classes.tooltipContainer}>
            {isMobile ? (
              <CustomButton
                type="moreinfo"
                buttonText="i"
                tooltipTitle={type === 'buy' ? tooltipContent.cryptoBuy : tooltipContent.cryptoSell}
              />
            ) : (
              <IButton tooltipTitle={type === 'buy' ? tooltipContent.cryptoBuy : tooltipContent.cryptoSell} />
            )}
          </div>
        </div>
        <Box className={classes.field}>
          <div className={classes.bullionzGoldContainerTop}>
            <div className={classes.bullionzTitleContainer}>
              {!isMobile && url && (
                <div className={classes.logoContainer}>
                  <img className={classes.logo} src={url} alt="Bullionz logo" />
                </div>
              )}

              <div
                style={{
                  display: 'grid',
                  gridTemplateRows: '1fr 1fr',
                  gridTemplateColumns: '1fr',
                }}
              >
                <InstrumentNameTooltip name={activeInstrument?.name} className={classes.title} />

                <div className={classes.coinName}>{displayName}</div>
              </div>
            </div>
            <div className={classes.inputsWrapper}>
              <div className={classes.labelWrapper}>
                <span>Amount</span>
              </div>

              <div className={clsx(classes.input, classes.borderBottom)}>
                <div className={classes.inputField}>
                  <TextField
                    className={classes.customInput}
                    onFocus={handleCryptoInputFocus}
                    onBlur={handleInputBlur}
                    autoComplete="off"
                    id="buy-sell-input"
                    value={cryptoPrice}
                    size="small"
                    inputProps={{
                      // placeholder: '0.000',
                      inputMode: 'decimal',
                      step: '0.001', // Allows precision up to three decimal places
                      // max: "99999.999",
                      min: minAmount,
                    }}
                    onChange={handleCryptoChange}
                  />
                </div>

                <div
                  style={{
                    width: '115px',
                    backgroundColor: isBuy ? '#66E3BB26' : '#E3666626',
                    color: isMobile ? '#767676' : 'black',
                    minHeight: !isMobile && '53px',
                    fontSize: isMobile ? 'var(--font-size-11)' : 'var(--font-size-14)',
                    justifyContent: isMobile ? 'flex-start' : 'center',
                  }}
                  className={classes.grams}
                >
                  {displayName}
                </div>
              </div>

              <div className={classes.labelWrapper}>
                <span>Value</span>
              </div>

              <div className={classes.input}>
                <div className={classes.inputField}>
                  <TextField
                    className={classes.customInput}
                    onFocus={handleFiatInputFocus}
                    onBlur={handleInputBlur}
                    autoComplete="off"
                    id="buy-sell-input"
                    value={fiatPrice}
                    size="small"
                    inputProps={{
                      pattern: '[0-9]*',
                      inputMode: 'decimal',
                      step: '0.001', // Allows precision up to three decimal places
                      // max: "99999.999",
                    }}
                    // onKeyUp={handleInputKeyUp}
                    // onKeyDown={handleInputKeyDown}
                    onChange={handleFiatChange}
                  />
                </div>

                <div
                  style={{
                    width: '115px',
                    backgroundColor: isBuy ? '#66E3BB26' : '#FBE8E8',
                    color: isMobile ? '#767676' : 'black',
                    minHeight: !isMobile && '53px',
                    fontSize: isMobile ? 'var(--font-size-11)' : 'var(--font-size-14)',
                    justifyContent: isMobile ? 'flex-start' : 'center',
                  }}
                  className={classes.grams}
                >
                  USDT
                </div>
              </div>
            </div>
          </div>
        </Box>
        <div
          style={{
            marginBottom: '34px',
          }}
          className={classes.buttons}
        >
          <div className={classes.priceField}>
            <strong>Price&nbsp;</strong>

            {type === 'buy'
              ? currencyFormatter(Number(assetPriceAsk || 0), 'transactions', 'USD', false, false, decimalsAsk) +
                ` / 1 ${displayName}`
              : currencyFormatter(Number(assetPriceBid || 0), 'transactions', 'USD', false, false, decimalsBid) +
                ` / 1 ${displayName}`}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default TransactionPanel;

const useStyles = makeStyles((theme) => ({
  fieldTitle: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.9rem',
    marginBottom: '4px',
    fontSize: 'var(--font-size-16)',
    fontWeight: '400',
    fontFamily: theme.typography.fontFamily,
    color: 'var(--grey-secondary)',
    [theme.breakpoints.down('1400')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('1150')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down('1060')]: {
      fontSize: 'var(--font-size-10)',
    },
  },
  tooltipContainer: {
    position: 'relative',
    left: '10px',
    bottom: '17px',
    [theme.breakpoints.down('1150')]: {
      bottom: '18px',
    },
  },
  tooltip: {
    position: 'relative',
    left: '8px ',
    bottom: '24px',
  },
  field: {
    border: '0.5px solid #D2D5D8',
    boxShadow: '0px 3px 0px 0px #F7F7F7 inset',
    borderRadius: '3px',
    display: 'flex',
    position: 'relative',
    width: '100%',
    minHeight: '53px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'var(--font-size-16)',
    fontFamily: theme.typography.fontFamily,
    marginBottom: '10px',
    [theme.breakpoints.down('768')]: {
      minHeight: '35px',
    },
  },
  totalCurrencyField: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    border: '0.5px solid #D2D5D8',
    borderRadius: '3px',
    alignItems: 'center',
    fontSize: 'var(--font-size-16)',
    backgroundColor: 'white',
    fontFamily: theme.typography.fontFamily,
    height: '32px',
    marginBottom: '10px',
    [theme.breakpoints.down('768')]: {
      height: '35px',
    },
  },
  yourPriceTooltip: {
    position: 'relative',
    left: '9px',
    bottom: '20px',
  },
  bullionzGoldContainerTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  bullionzTitleContainer: {
    flex: '72%',
    display: 'flex',
    [theme.breakpoints.down('1189')]: {
      flex: '85%',
    },
    [theme.breakpoints.down('768')]: {
      flex: '110%',
    },
  },
  bullionzGoldContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'flex-start',
    left: '2%',
    width: '50%',
  },
  bullionzGoldField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: 'var(--font-size-14)',
    fontFamily: theme.typography.fontFamily,
    marginLeft: '4px',
    [theme.breakpoints.down('1400')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1150')]: {
      fontSize: 'var(--font-size-11)',
    },
    [theme.breakpoints.down('1060')]: {
      fontSize: 'var(--font-size-10)',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '6px',
  },

  logo: {
    width: '32px',
    height: '32px',
  },

  dynamicField: {
    color: 'var(--grey-secondary)',
    position: 'relative',
    marginLeft: 'auto',
    right: '7%',
    display: 'flex',
    fontSize: 'var(--font-size-15)',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('1400')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('1150')]: {
      fontSize: '0.7rem',
    },
  },
  customInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    color: 'white',
    '& .MuiInputBase-input': {
      fontSize: 'var(--font-size-22)',
      fontWeight: '400',
      textAlign: 'right',
      [theme.breakpoints.down('1280')]: {
        fontSize: 'var(--font-size-19)',
      },
      [theme.breakpoints.down('1058')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('840')]: {
        fontSize: 'var(--font-size-14)',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
  },
  grams: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
  },
  buttons: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    flexDirection: 'column',
    top: '3px',
    overflow: 'hidden',
  },
  priceField: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: theme.typography.fontFamily,
    color: 'var(--grey-secondary)',
    [theme.breakpoints.down('1880')]: {
      alignSelf: 'flex-end',
      marginTop: '0.3rem',
    },
    [theme.breakpoints.down('768')]: {
      alignSelf: 'flex-end',
      fontSize: 'var(--font-size-10)',
      fontWeight: '400',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '4%',
    [theme.breakpoints.down('1880')]: {
      width: '100%',
      order: '2',
    },
    [theme.breakpoints.down('1850')]: {
      gap: '3%',
    },
    [theme.breakpoints.down('1500')]: {
      gap: '4%',
    },
    [theme.breakpoints.down('1200')]: {
      marginTop: '21px',
      marginBottom: '5px',
    },
    [theme.breakpoints.down('768')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '12%',
      marginBottom: '2%',
    },
    '@media (orientation: landscape)': {
      [theme.breakpoints.down('768')]: {
        marginTop: '3%',
      },
    },
  },
  buttonContainerMobile: {
    display: 'flex',
    flex: '1',
    gap: '4%',
    [theme.breakpoints.down('1880')]: {
      width: '100%',
      order: '2',
    },
    [theme.breakpoints.down('1850')]: {
      gap: '3%',
    },
    [theme.breakpoints.down('1500')]: {
      gap: '4%',
    },
    [theme.breakpoints.down('768')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '12%',
      marginBottom: '2%',
    },
    '@media (orientation: landscape)': {
      [theme.breakpoints.down('768')]: {
        marginTop: '3%',
      },
    },
  },
  greyButton: {
    width: '13%',
    height: '22px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('768')]: {
      width: '25.2%',
      height: '25px',
      paddingBottom: '4%',
    },
  },
  title: {
    // display: "flex",
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: '600',
    fontSize: 'var(--font-size-16)',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('973')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('955')]: {
      fontSize: 'var(--font-size-14)',
    },
    [theme.breakpoints.down('865')]: {
      fontSize: 'var(--font-size-12)',
    },
  },
  coinName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 400,
    fontSize: 'var(--font-size-14) !important',
    color: '#767676',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('955')]: {
      fontSize: 'var(--font-size-14) !important',
    },
    [theme.breakpoints.down('865')]: {
      fontSize: 'var(--font-size-13) !important',
    },
  },
  input: {
    flex: '20%',
    display: 'flex',
    borderLeft: '1px solid #D2D5D8',
    [theme.breakpoints.down('1610')]: {
      flex: '22%',
    },
    [theme.breakpoints.down('1480')]: {
      flex: '27%',
    },
    [theme.breakpoints.down('1180')]: {
      flex: '33%',
    },
    [theme.breakpoints.down('932')]: {
      flex: '39%',
    },
    [theme.breakpoints.down('785')]: {
      flex: '42%',
    },
  },

  borderBottom: {
    borderBottom: '1px solid #D2D5D8',
  },

  arrowButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '53px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5%',
  },
  arrowButton: {
    margin: '58%',
    [theme.breakpoints.down('980')]: {
      width: '10px',
    },
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
  },

  inputsWrapper: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr',
    gridTemplateRows: '1fr 1fr',
  },

  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    color: '#767676',
    fontSize: '14px',
    paddingRight: '4px',
  },

  actionLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));
