// export function errorHandlingWrapper(func) {
//   return function (...args) {
//     try {
//       func(...args);
//     } catch (error) {
//       console.error("An error occurred:", error);
//     }
//   };
// }

export function errorHandlingWrapper(err, customErr) {
  try {
    customErr = JSON.parse(err.message);
  } catch (e) {
    customErr = { message: "No internet connection. Please try again later" };
  }
  if (!customErr) {
    customErr = { message: "Something went wrong. Please try again." };
  }
  return customErr;
}
