import { Box } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { CURRENCY_TYPE } from '../../../../constants/globalconstants';
import currencyFormatter from '../../../../helpers/currencyFormatter/currencyFormatter';
import { useAppContext } from '../../../../context/DataContext';
import BuyGoldCoin from '../../../../assets/images/buy-gold-coin.svg';
import CryptoGoldCoin from '../../../../assets/images/button_crypto.svg';
import StakingGoldCoin from '../../../../assets/images/staking-gold-coin.svg';
import VaultingGoldCoin from '../../../../assets/images/vaulting-gold-coin.svg';
import { GoldToMoneyConverter } from '../../../../helpers/goldToMoneyConverter';
import { routes } from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import RedeemGoldPopupMobile from '../../popups/RedeemGoldPopup/RedeemGoldPopupMobile';
import { redeemService } from '../../../../service/redeem';
import NotificationMessagePopup from '../../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import { handleGetUserDetails } from '../../../../helpers/handleGetUserDetails';
import { useEffect } from 'react';
import PleaseLoginPopUp from '../../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { messages } from '../../../../constants/popupMessages';
import { updateBucket } from '../../../../helpers/update-client-bucket';
import { userService } from '../../../../service/user';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import CryptoPopup from '../../../common/Popups/CryptoPopup/CryptoPopup';
import { handleGetUserCart } from '../../../../helpers/handleGetUserCart';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import NonVerifiedUserPopUp from '../../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import isVerified from '../../../../helpers/isVerified';

const unavailableProductMessage = 'The selected product is unavailable.';

const NavigationBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [availableGoldVariants, setAvailableGoldVariants] = useState(null);
  const [redeemGoldDeliveryFee, setRedeemGoldDeliveryFee] = useState(0);
  const [isRedeemFeeLoading, setIsRedeemFeeLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [userCartData, setUserCartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [showTBC, setShowTBC] = useState(false);
  const [openCryptoPopup, setOpenCryptoPopup] = useState(false);
  const [cryptoPopupMessage, setCryptoPopupMessage] = useState(false);
  const [cryptoPopupStep, setCryptoPopupStep] = useState(0);

  const contextValues = useAppContext();
  const tutorialStep = contextValues?.tutorialStep;
  const goldBidPriceForGroup = contextValues?.goldBidPriceForGroup;
  const setIsInHistory = contextValues?.setIsInHistory;
  const stakedCoins = contextValues?.stakedCoins || 0;
  const vaultedCoins = contextValues?.vaultedCoins || 0;
  const stakingMoney = GoldToMoneyConverter(stakedCoins, goldBidPriceForGroup);
  const vaultingMoney = GoldToMoneyConverter(vaultedCoins, goldBidPriceForGroup);
  const isRedeemGoldPopupOpen = contextValues?.isRedeemGoldPopupOpen;
  const setIsRedeemGoldPopupOpen = contextValues?.setIsRedeemGoldPopupOpen;
  const userBrokerAccounts = contextValues?.userBrokerAccounts;
  const isPortfolioLoading = contextValues?.isPortfolioLoading;
  const nonVerifiedPopUpOpen = contextValues?.nonVerifiedPopUpOpen;
  const setNonVerifiedPopUpOpen = contextValues?.setNonVerifiedPopUpOpen;

  useEffect(() => {
    handleGetUserDetails(setUserDetails, setLoading);
  }, [isRedeemGoldPopupOpen]);

  useEffect(() => {
    handleGetUserCart(setUserCartData, () => {});
  }, []);

  useEffect(() => {
    updateBucket(
      contextValues?.isAppView,
      userCartData,
      contextValues?.setIsCartFetched,
      contextValues?.setProductsBucket,
      userService?.updateShoppingCart,
    );
  }, [userCartData]);

  const getStoreFees = async (feesPayload) => {
    try {
      setRedeemGoldDeliveryFee(0);
      setIsRedeemFeeLoading(true);

      const storeFees = await redeemService.calculateFees(feesPayload);

      const isDemo = sessionStorage.getItem('isDemo') === 'true';
      const isFeeGreaterThanZero = storeFees?.deliveryFee > 0;

      if (storeFees?.error) {
        setErrorPopup(true);
        setIsRedeemFeeLoading(false);
        setErrorMessage(`Delivering to your country is still not available.
        Go one step back and use the "Add New Address" button to change the delivery address.`);
        return;
      } else if (!isFeeGreaterThanZero && !isDemo) {
        setErrorPopup(true);
        setIsRedeemFeeLoading(false);
        setErrorMessage(`Delivery to this address is not available at this time.
        Go one step back and use the "Add New Address" button to change the delivery address.`);
        return;
      } else if (storeFees?.usedCoins !== storeFees?.totalGrams && !isDemo) {
        setErrorPopup(true);
        setIsRedeemFeeLoading(false);
        setErrorMessage(messages.insufficientFunds);
      } else {
        setIsRedeemFeeLoading(false);
        setServiceFee(storeFees?.serviceFeeCoins);
        setRedeemGoldDeliveryFee(storeFees?.deliveryFee);
        setTotal(storeFees?.totalSumWithCoins);
      }
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);
      if (customError?.statusCode === 400) {
        setErrorPopup(true);
        if (customError?.message === unavailableProductMessage) {
          setErrorMessage(customError?.message);
        } else {
          setErrorMessage(`Delivery to this address is not available at this time.
          Use the "Add New Address" button on the previous step to change the delivery address.`);
        }
        setShowTBC(true);
      }
      console.error('An error occurred while fetching fees:', error);
    }
  };
  const getAvailableGold = async () => {
    setLoading(true);
    setAvailableGoldVariants(null);
    try {
      const availableGold = await redeemService.getAvailableGoldVariants();
      if (availableGold === null) {
        setErrorPopup(true);
        setErrorMessage('An error occurred while fetching available gold variants');
      }
      setAvailableGoldVariants(availableGold);
    } catch (error) {
      console.error('An error occurred while fetching available gold variants:', error);
      setErrorPopup(true);
      setErrorMessage('An error occurred while fetching available gold variants');
    } finally {
      setLoading(false);
    }
  };

  const handleCryptoClicked = async () => {
    const isDemo = sessionStorage.getItem('isDemo') === 'true';

    if (isDemo) {
      navigate(routes.crypto);
      return;
    }

    if (!isPortfolioLoading) {
      if (userBrokerAccounts?.length > 0) {
        navigate(routes.crypto);
      } else {
        setCryptoPopupMessage(messages.createCryptoAccount);
        setOpenCryptoPopup(true);
        setCryptoPopupStep(1);
      }
    }
  };

  const handleBuyGoldClicked = () => {
    navigate(routes.store);
  };

  const handleRedeemGoldClicked = () => {
    setIsRedeemGoldPopupOpen(true);
    getAvailableGold();
  };

  function navigateToStaking() {
    setIsInHistory(false);
    navigate(routes.yielding);
  }

  function navigateToVaulting() {
    navigate(routes.vaulting);
  }

  const closePopups = () => {
    setErrorPopup(false);

    if (errorMessage?.includes('Delivering to your country') || errorMessage?.includes('Delivery to this address')) {
      setActiveStep(1);
    } else if (
      errorMessage?.includes(
        'Your order can not be executed due to insufficient funds. Please top-up the account and try again.',
      )
    ) {
      setActiveStep(0);
    } else {
      setActiveStep(0);
      setIsRedeemGoldPopupOpen(false);
    }
    setShowTBC(false);
  };

  const closeAfterDepositBtnClick = () => {
    setActiveStep(0);
    setIsRedeemGoldPopupOpen(false);
  };

  if (tutorialStep === 3) {
    const buyGoldIcon = document.getElementById('buy-gold-icon');
    buyGoldIcon?.classList?.remove('except');
  }

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <Box className={classes.container}>
      <div className={classes.redeemGoldButtonContainer} id="crypto-gold-icon">
        <div className={classes.buttonGroup}>
          <Button onClick={handleCryptoClicked} disableRipple>
            <div className={classes.buttonContainer}>
              <img src={CryptoGoldCoin} alt="Crypto gold icon" className={classes.goldImageIcon} />
            </div>
          </Button>
          <div className={classes.infoBtn}>
            <CustomButton
              type="moreinfo"
              buttonText="i"
              tooltipTitle={tooltipContent.crypto}
              isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
            ></CustomButton>
          </div>
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.title}>CRYPTO</div>
        </div>
      </div>

      <div className={classes.redeemGoldButtonContainer} id="buy-gold-icon">
        <div className={classes.buttonGroup}>
          <Button onClick={handleBuyGoldClicked} disableRipple>
            <div className={classes.buttonContainer}>
              <img src={BuyGoldCoin} alt="Buy gold icon" className={classes.goldImageIcon} />
            </div>
          </Button>
          <div className={classes.infoBtn}>
            <CustomButton
              type="moreinfo"
              buttonText="i"
              tooltipTitle={tooltipContent.storeBuyGold}
              isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
            ></CustomButton>
          </div>
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.title}>BUY GOLD</div>
        </div>
      </div>

      <div className={classes.stakingButtonContainer} id="stake-icon">
        <div className={classes.buttonGroup}>
          <Button onClick={navigateToStaking} disableRipple>
            <div className={classes.buttonContainer}>
              <img src={StakingGoldCoin} alt="Staking gold icon" className={classes.imageIcon} />
            </div>
          </Button>
          <div className={classes.infoBtn}>
            <CustomButton
              type="moreinfo"
              buttonText="i"
              tooltipTitle={tooltipContent.staking}
              isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
            ></CustomButton>
          </div>
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.title}>YIELDING</div>
          <span className={classes.state}>
            {currencyFormatter(stakedCoins, 'gr')} /<strong>{currencyFormatter(stakingMoney, CURRENCY_TYPE)}</strong>
          </span>
        </div>
      </div>

      <div className={classes.vaultingButtonContainer} id="yield-icon">
        <div className={classes.buttonGroup}>
          <Button onClick={navigateToVaulting} disableRipple>
            <div className={classes.buttonContainer}>
              <img src={VaultingGoldCoin} alt="Vaulting gold icon" className={classes.imageIcon} />
            </div>
          </Button>
          <div className={classes.infoBtn}>
            <CustomButton
              type="moreinfo"
              buttonText="i"
              tooltipTitle={tooltipContent.vaulting}
              isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
            ></CustomButton>
          </div>
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.title}>VAULTING</div>
          <span className={classes.state}>
            {currencyFormatter(vaultedCoins, 'gr')} /<strong>{currencyFormatter(vaultingMoney, CURRENCY_TYPE)}</strong>
          </span>
        </div>
      </div>
      {isRedeemGoldPopupOpen && userDetails && (
        <RedeemGoldPopupMobile
          userDetails={userDetails}
          isPopupOpen={isRedeemGoldPopupOpen}
          setIsPopupOpen={setIsRedeemGoldPopupOpen}
          availableGoldVariants={availableGoldVariants}
          deliveryFee={redeemGoldDeliveryFee}
          getStoreFees={getStoreFees}
          serviceFee={serviceFee}
          total={total}
          isRedeemFeeLoading={isRedeemFeeLoading}
          activeStep={activeStep}
          setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
          setActiveStep={setActiveStep}
          showTBC={showTBC}
        />
      )}
      {errorPopup && (
        <NotificationMessagePopup
          type={'error'}
          isPopupOpen={errorPopup}
          setIsPopupOpen={closePopups}
          message={errorMessage}
          handleCloseAutoInvestPopup={closePopups}
          closeAfterDepositBtnClick={closeAfterDepositBtnClick}
        />
      )}
      <LoadingBar open={loading} />
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      {openCryptoPopup && (
        <CryptoPopup
          isPopupOpen={openCryptoPopup}
          setIsPopupOpen={setOpenCryptoPopup}
          message={cryptoPopupMessage}
          setCryptoPopupMessage={setCryptoPopupMessage}
          cryptoPopupStep={cryptoPopupStep}
          setCryptoPopupStep={setCryptoPopupStep}
        />
      )}
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
        />
      )}
    </Box>
  );
};

export default NavigationBar;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'var(--white)',
    height: '14%',
    minHeight: '104px',
    maxHeight: '160px',
    '@media (orientation: landscape)': {
      height: '37%',
      minHeight: '100px',
    },
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    border: '1.5px solid #E0E0E099',
    [theme.breakpoints.down('768')]: {
      height: '19%',
    },
    [theme.breakpoints.down('431')]: {
      height: '14%',
    },
  },
  redeemGoldButtonContainer: {
    flex: '1',
    textAlign: 'center',
    position: 'relative',
    bottom: '0px',
    marginTop: '-1rem',
  },
  stakingButtonContainer: {
    flex: '1',
    textAlign: 'center',
    position: 'relative',
    top: '6px',
    marginTop: '-1rem',
  },
  vaultingButtonContainer: {
    flex: '1',
    textAlign: 'center',
    position: 'relative',
    top: '6px',
    marginTop: '-1rem',
  },
  buttonContainer: {
    position: 'relative',
    top: '0.75rem',
  },
  goldImageIcon: {
    width: '2.7rem',
    [theme.breakpoints.up('450')]: {
      width: '3.125rem',
      height: '3.125rem',
    },
    [theme.breakpoints.up('550')]: {
      width: '3.5rem',
      height: '3.5rem',
    },
    [theme.breakpoints.up('700')]: {
      width: '3.75rem',
      height: '3.75rem',
    },
  },
  imageIcon: {
    width: '2.75rem',
    [theme.breakpoints.up('450')]: {
      width: '3.125rem',
    },
    [theme.breakpoints.up('550')]: {
      width: '3.5rem',
    },
    [theme.breakpoints.up('700')]: {
      width: '3.75rem',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  infoBtn: {
    zIndex: '1',
    position: 'relative',
    left: '20px',
    bottom: '2px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: '500',
    fontSize: 'var(--font-size-10)',
  },
  state: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 'var(--font-size-8)',
  },
}));
