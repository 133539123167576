import * as React from "react";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.styles.css";
import "../../../../styles/global.fonts.css";
import { Box, Button, Pagination, Tab, Tabs, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CURRENCY_SYMBOL } from "../../../../constants/globalconstants";
import { routes } from "../../../../routes";
import CustomButton from "../../../common/CustomButton/CustomButton";
import VaultIcon from "../../../../assets/images/vault-icon.svg";
import { useAppContext } from "../../../../context/DataContext";
import { handleGetOrderWebTransactions } from "../../../../helpers/handleGetOrderTransactions";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateWrapper } from "../../../../helpers/dateFormatter";
import LoadingBar from "../../../common/LoadingBar/LoadingBar";
import serviceVaulting from "../../../../service/vaulting";
import ConfirmPopUp from "../../../common/Popups/ConfirmPopUp/ConfirmPopUp";
import NotificationMessagePopup from "../../../common/Popups/NotificationMessagePopup/NotificationMessagePopup";
import IButton from "../../../common/IButton/IButton";
import { tooltipContent } from "../../../../constants/tooltipTexts";
import { messages } from "../../../../constants/popupMessages";
import { isMarketOpen } from "../../../../helpers/isMarketOpen";
import { handlePleaseLogin } from "../../../../helpers/pleaseLoginOrRegisterForDemo";
import PleaseLoginPopUp from "../../../common/Popups/PleaseLoginPopup/PleaseLoginPopup";
import { errorHandlingWrapper } from "../../../../helpers/errorHandlingWrapper";
import { generateCurrencySymbol } from "../../../../helpers/generateCurrencySymbol";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="span" component="span">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const VaultingHistory = ({ isSeeAll = true, containerClassName }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [allPages, setAllPages] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [vaultingTransactionsHistory, setVaultingTransactionsHistory] =
    useState([]);
  const [marketInfoMessagePopupOpen, setMarketInfoMessagePopupOpen] =
    useState(false);
  const [marketInfoMessage, setMarketInfoMessage] = useState("");
  const {
    proceedVaultOrderClicked,
    proceedStakeOrderClicked,
    setProceedVaultOrderClicked,
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
    setGoldHistoryPeriod,
    setChosenPeriod,
    userTradeAccounts,
  } = useAppContext();
  const [page, setPage] = useState(1);

  const isDemoValue = sessionStorage.getItem("isDemo");
  const isDemo1 = isDemoValue === "true";

  function navigateToVaultingHistory(e) {
    e.preventDefault();
    navigate(routes.vaultingHistory);
  }

  function navigateBackToVaulting() {
    setGoldHistoryPeriod("1H");
    setChosenPeriod("1H");
    navigate(routes.vaulting);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (!isSeeAll) {
      handleGetOrderWebTransactions(
        setVaultingTransactionsHistory,
        page,
        setAllPages,
        "vault",
        setError
      );
    } else {
      handleGetOrderWebTransactions(
        setVaultingTransactionsHistory,
        0,
        setAllPages,
        "vault",
        setError
      );
    }
  }, [page, proceedVaultOrderClicked, proceedStakeOrderClicked, cancelOrderId]);

  useEffect(() => {
    if (vaultingTransactionsHistory.length || error) {
      setLoading(false);
    }
  }, [vaultingTransactionsHistory, error]);

  function handlePopUpOpen(id) {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    const marketOpen = isMarketOpen();
    if (marketOpen) {
      setCancelOrderId(id);
      setOpen(true);
    } else {
      setMarketInfoMessagePopupOpen(true);
      setMarketInfoMessage(messages.marketClosed);
    }
  }

  function handlePopUpClosed() {
    setOpen(false);
  }

  const handleCancelVaultOrder = async (orderId) => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    handlePopUpClosed();
    const closedVault = {
      orderId: orderId,
    };
    handlePopUpClosed();
    setLoading(true);
    try {
      await serviceVaulting.cancelVault(closedVault);
      setPopUpMessage(messages.orderClosed);
      setProceedVaultOrderClicked(true);
      setNotificationPopupType("success");
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);
      setPopUpMessage(customError?.message);
      setProceedVaultOrderClicked(true);
      setNotificationPopupType("error");
      console.error("Error close vault order:", customError?.message);
    } finally {
      handlePopUpClosed();
      setLoading(false);
    }
  };

  const tradeAccountsLength = userTradeAccounts?.length;

  return (
    <Box>
      <Box className={containerClassName}>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="secondary"
              textColor="inherit"
            >
              <Tab
                className={classes.title}
                label="VAULTING HISTORY"
                indicator={false}
              />
              {isSeeAll && (
                <Tab
                  className={classes.seeAll}
                  href="/vaulting-history"
                  onClick={navigateToVaultingHistory}
                  label="See All"
                  disabled={isDemo1}
                ></Tab>
              )}
              {isSeeAll && (
                <IButton tooltipTitle={tooltipContent.vaultingHistory} />
              )}
            </Tabs>
            {!isSeeAll && (
              <IButton tooltipTitle={tooltipContent.vaultingHistory} />
            )}
            <Divider />
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className={classes.tableContainer}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        style={{
                          width: 300,
                          fontWeight: "600",
                          whiteSpace: "nowrap",
                          "& th": {
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                          },
                        }}
                      >
                        Transaction
                      </TableCell>
                      {tradeAccountsLength > 1 && (
                        <TableCell style={{ fontWeight: "600" }} align="left">
                          Account
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          width: 200,
                          fontWeight: "600",
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        align="left"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        style={{ width: 200, fontWeight: "600" }}
                        align="center"
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        style={{ width: 100, fontWeight: "600" }}
                        align="center"
                      >
                        Fee
                      </TableCell>
                      <TableCell
                        style={{ width: 200, fontWeight: "600" }}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "600" }}
                        align="center"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vaultingTransactionsHistory.length > 0 ? (
                      vaultingTransactionsHistory.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <img
                              src={VaultIcon}
                              alt="Sell Arrow Icon"
                              className={classes.img}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          {tradeAccountsLength > 1 && (
                            <TableCell component="th" scope="row">
                              {row.tradeAccountId}
                            </TableCell>
                          )}
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.dateRow}
                          >
                            {!isSeeAll
                              ? formatDateWrapper(
                                  new Date(row.date).getTime(),
                                  "long"
                                )
                              : formatDateWrapper(
                                  new Date(row.date).getTime(),
                                  "long"
                                )}
                          </TableCell>
                          <TableCell align="center">{row.volume}gr</TableCell>
                          <TableCell align="center">
                            {generateCurrencySymbol(row?.currency)}
                            {row.fee}
                          </TableCell>
                          <TableCell align="center">{row.status}</TableCell>
                          <TableCell align="center">
                            <Button
                              className={classes.closeButton}
                              variant="outlined"
                              endIcon={<CloseIcon style={{ fontSize: 14 }} />}
                              onClick={() => handlePopUpOpen(row.transactionId)}
                              disabled={row.status !== "ACTIVE"}
                            >
                              Close
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow className={classes.noData}>
                        <TableCell>No Vaulting History</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
        </Box>
      </Box>
      {!isSeeAll && (
        <Box className={classes.buttonsWrapper}>
          <Box>
            <Box className={classes.btn}>
              <CustomButton
                type="black"
                width="100%"
                height="24px"
                onClick={navigateBackToVaulting}
                buttonText="&#9664;&nbsp;&nbsp;Back to Vaulting"
              ></CustomButton>
            </Box>
          </Box>
          {vaultingTransactionsHistory.length > 0 && (
            <Box>
              <Box className={classes.paginationRoot}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={allPages}
                  page={page}
                  onChange={(event, newPage) => setPage(newPage)}
                />
              </Box>
            </Box>
          )}
          <Box></Box>
        </Box>
      )}
      <LoadingBar open={loading} />
      {proceedVaultOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === "success" ? "success" : "error"}
          isPopupOpen={proceedVaultOrderClicked}
          setIsPopupOpen={setProceedVaultOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      {marketInfoMessagePopupOpen && (
        <NotificationMessagePopup
          type={"error"}
          isPopupOpen={marketInfoMessagePopupOpen}
          setIsPopupOpen={setMarketInfoMessagePopupOpen}
          message={marketInfoMessage}
        />
      )}
      <ConfirmPopUp
        open={open}
        setOpen={setOpen}
        orderId={cancelOrderId}
        message={"Please confirm closing vaulting"}
        save={handleCancelVaultOrder}
      />
      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
    </Box>
  );
};

export default VaultingHistory;

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: "28px",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-18)",
    [theme.breakpoints.down("1200")]: {
      fontSize: "var(--font-size-17)",
    },
    [theme.breakpoints.down("1150")]: {
      fontSize: "var(--font-size-16)",
    },
    [theme.breakpoints.down("1000")]: {
      fontSize: "var(--font-size-15)",
    },
    [theme.breakpoints.down("800")]: {
      fontSize: "var(--font-size-14)",
    },
  },
  tableContainer: {
    fontFamily: theme.typography.fontFamily,
    "& .MuiTableCell-root": {
      fontSize: "var(--font-size-18)",
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-14)",
      },
      [theme.breakpoints.down("1280")]: {
        fontSize: "var(--font-size-13)",
      },
      [theme.breakpoints.down("1040")]: {
        fontSize: "var(--font-size-12)",
      },
      [theme.breakpoints.down("960")]: {
        fontSize: "var(--font-size-10)",
      },
    },
  },
  noData: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    width: "97%",
    color: "var(--grey-secondary)",
    fontFamily: theme.typography.fontFamily,
    border: "none",
    "& td": {
      border: "none",
      color: "var(--grey-secondary)",
      fontFamily: theme.typography.fontFamily,
      fontSize: "var(--font-size-16)",
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-14)",
      },
      [theme.breakpoints.down("1280")]: {
        fontSize: "var(--font-size-13)",
      },
    },
  },
  seeAll: {
    textDecoration: "none",
    color: "var(--black)",
    display: "flex",
    alignItems: "center",
    position: "relative",
    minWidth: "1rem",
    opacity: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-14)",
    fontWeight: "400",
    [theme.breakpoints.down("1150")]: {
      fontSize: "var(--font-size-13)",
    },
    [theme.breakpoints.down("1000")]: {
      fontSize: "var(--font-size-12)",
    },
    [theme.breakpoints.down("800")]: {
      fontSize: "var(--font-size-10)",
    },
  },
  paginationRoot: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    paddingBottom: "3%",
    "& .MuiPagination-ul": {
      color: "white",
      flexWrap: "nowrap",
      "& li:first-child": {
        flexBasis: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& > button::after": {
          marginLeft: "2px",
          content: "'Prev'",
        },
      },
      "& li:last-child": {
        flexBasis: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "& > button::before": {
          marginRight: "2px",
          content: "'Next'",
        },
      },
    },
    "& .Mui-selected": {
      backgroundColor: "var(--black)",
      color: "var(--white)",
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
  btn: {
    textAlign: "left",
    width: "12rem",
    [theme.breakpoints.down("1750")]: {
      width: "10rem",
    },
    [theme.breakpoints.down("1280")]: {
      width: "8rem",
    },
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  closeButton: {
    color: "var(--black)",
    borderRadius: "24px",
    border: "1px solid var(--red)",
    width: "70%",
    maxWidth: "5rem",
    minHeight: "15px",
    height: "1.7rem",
    backgroundColor: "var(--white)",
    fontSize:
      "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important",
  },
  img: {
    maxWidth: "24px",
    maxHeight: "24px",
  },
  dateRow: {
    minWidth: "110px",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("880")]: {
      minWidth: "100px",
    },
  },
}));
