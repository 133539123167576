import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";

function DashboardWebLayout({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.main}>
        <div style={{ height: "100%", width: "100%" }}>{children}</div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default DashboardWebLayout;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      overflowY: "auto",
      "@media(max-width: 859px)": {
        display: "block",
        width: "100%",
      },
    },
    header: {
      height: "6%",
      display: "flex",
      flex: "0 auto",
      "@media(max-width: 859px)": {
        display: "block",
        width: "100%",
      },
    },
    main: {
      height: "70%",
      display: "flex",
      maxWidth: "1920px",
      marginBottom: "2rem",
      flex: "1",

      "@media(max-width: 859px)": {
        display: "block",
        width: "100%",
      },

      "@media(min-width: 1924px)": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    footer: {
      height: "6%",
      display: "flex",
      flex: "0 1",
      "@media(max-width: 859px)": {
        display: "block",
        width: "100%",
      },
    },
  })
);
