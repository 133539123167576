import { makeStyles } from "@mui/styles";
import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAppContext } from "../../../context/DataContext";
import { userService } from "../../../service/user";
import { ReactComponent as BullionzBackgroundIcon } from "../../../assets/images/bullionz-tutorial-modal-icon.svg";

const TutorialEndModal = ({ isMobile, isNative }) => {
  const { setTutorialStep } = useAppContext();

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const BTXG = document.getElementById("btxg-container");
  const newsContainer = document.getElementById("news-container");
  const portfolio = document.getElementById("portfolio-container");
  const transactions = document.getElementById("transactions-container");

  const balanceContainer = document.getElementById("balance-container");
  const depositBtn = document.getElementById("deposit-btn");
  const buyGoldIcon = document.getElementById("buy-gold-icon");
  const redeemIcon = document.getElementById("redeem-icon");
  const yieldingIcon = document.getElementById("yielding-icon");
  const vaultingIcon = document.getElementById("vaulting-icon");
  const monthlyPool = document.getElementById("monthly-pool");
  const buyCloseContainer = useMemo(() =>
    document.getElementById("buyclose-container")
  );

  const blurAllItems = () => {
    balanceContainer?.classList?.remove("except");
    depositBtn?.classList?.remove("except");
    buyGoldIcon?.classList?.remove("except");
    redeemIcon?.classList?.remove("except");
    yieldingIcon?.classList?.remove("except");
    vaultingIcon?.classList?.remove("except");
    monthlyPool?.classList?.remove("except");
    buyCloseContainer?.classList?.remove("except");
    BTXG?.classList?.remove("except");
    portfolio?.classList?.remove("except");
    transactions?.classList?.remove("except");
    newsContainer?.classList?.remove("except");
  };

  const handleSkipTour = () => {
    setTutorialStep(15);
    blurAllItems();
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    sessionStorage.setItem("tutorialStep", 15);

    if (params.get("tutorial") === "true") {
      params.delete("tutorial");
      url.search = params.toString();
      window.history.replaceState({}, "", url.toString());
    }

    if (isNative) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        '{"Event": "Tutorial", "Action": "passed"}'
      );

      setTutorialStep(15);
    } else if (isMobile) {
      userService.updateTutorialStatus("MOBILE", "PASSED");
    } else {
      userService.updateTutorialStatus("WEB", "PASSED");
    }
  };

  return (
    <Modal
      open={open}
      className="except"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalBoxWrapper}>
        <BullionzBackgroundIcon className={classes.modalBoxBackgroundInner} />
        <p className={classes.paragraphs}>
          <span className={classes.bolderText}>Thank you for joining us!</span>
        </p>

        <p className={classes.paragraphs}>
          Now that you have a better grasp of how our platform works, start by
          funding your account (deposit) to begin enjoying all that we have to
          offer in the world of gold!
        </p>

        <div className={classes.btnWrapper}>
          <button className={classes.skipBtn} onClick={handleSkipTour}>
            Close
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalBoxWrapper: {
    position: "fixed",
    top: "50%",
    left: "50%",
    right: "50%",
    marginLeft: "auto",
    marginRight: "auto",

    transform: "translate(-50%, -50%)",

    width: 550,
    padding: 24,
    background: "white",
    borderRadius: "12px",
    border: "0.5px #D2D5D8",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",

    "&:focus-visible": {
      outline: "none",
    },

    [theme.breakpoints.down("768")]: {
      width: "78vw",
    },
  },

  modalBoxBackgroundInner: {
    position: "absolute",
    top: "20px",
    left: "-20px",
    height: "calc(100% - 20px)",
    zIndex: -1,
    fill: "white",
  },

  btnWrapper: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr",

    marginTop: "16px",

    [theme.breakpoints.down("768")]: {
      gridTemplateColumns: "1fr",
      justifyItems: "center",
      gap: "14px",
    },
  },

  skipBtn: {
    cursor: "pointer",

    color: "grey",
    border: "none",
    fontSize: "16px",
    marginRight: "8px",
    borderRadius: "28px",
    background: "transparent",
    textDecoration: "underline",
  },

  paragraphs: {
    marginBottom: "16px",
    letterSpacing: "0.5px",
  },

  bolderText: {
    fontWeight: "bold",
  },

  nextBtn: {
    cursor: "pointer",

    color: "white",
    fontSize: "14px",
    marginRight: "8px",
    fontWeight: "bold",
    padding: "12px 26px",
    borderRadius: "28px",
    background: "#48D0A4",
    border: "1px solid white",
  },
}));

export default TutorialEndModal;
