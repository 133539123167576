let error = {
  message: '',
};

function currencyFormatter(currencyValue, type, currency, isDecimal = false, isWholeNumber = false, decimals) {
  const valid = isValid(currencyValue);

  let currencyType = localStorage.getItem('currency') || 'USD';

  if (type === 'gr') {
    currencyType = type;
  }

  if (type === 'transactions' || type === 'crypto') {
    currencyType = currency;
  }

  if (!valid) {
    error.message = 'Invalid data type. The currency is not a number.';
    return error.message;
  }

  let formattedCurrency = '';
  let result = '';

  currencyValue = currencyValue.toString();
  let preCurrencyForLenght = Math.trunc(currencyValue);
  const currencyLength = preCurrencyForLenght.toString().length;

  if (currencyLength === 0) {
    error.message = 'Invalid money value.';
    return error.message;
  }

  if (currencyType === 'gr') {
    result = getFormattedGrams(currencyValue);
    return result;
  }
  result = getFormattedMoney(
    formattedCurrency,
    currencyLength,
    currencyValue,
    isDecimal ? 'custom' : currencyType,
    isWholeNumber,
    decimals,
  );

  const lastChar = result.slice(-1);
  const firstChar = result.slice(0, 1);

  if ('€' === lastChar) {
    let euroPrice = result.slice(result.length - 1) + result;
    euroPrice = euroPrice.slice(0, -1);
    return euroPrice;
  }

  if (isDecimal || decimals) {
    if (firstChar === '€' || firstChar === '$' || firstChar === '₮') {
      return result;
    } else {
      result = `${result} ${currency}`;
    }
  }

  return result;
}

export default currencyFormatter;

function moneyFormatter(currencyValue, currencyType, maximumFractionDigits) {
  let formattedCurrency;
  switch (true) {
    case currencyType === 'USD':
      formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    case currencyType === 'USDT':
      formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      formattedCurrency = `₮${formattedCurrency}`;
      break;
    case currencyType === 'custom':
      formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    case currencyType === 'EUR':
      formattedCurrency = new Intl.NumberFormat('sfb', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    case currencyType === 'INR':
      formattedCurrency = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    case currencyType === 'GBP':
      formattedCurrency = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    case currencyType === 'CNY':
      formattedCurrency = new Intl.NumberFormat('zh-CN', {
        style: 'currency',
        currency: 'CNY',
        maximumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    case currencyType === 'BGN':
      formattedCurrency = new Intl.NumberFormat('bg-BG', {
        style: 'currency',
        currency: 'BGN',
        maximumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    case currencyType === 'CHF':
      formattedCurrency = new Intl.NumberFormat('fr-CH', {
        style: 'currency',
        currency: 'CHF',
        maximumFractionDigits: maximumFractionDigits,
      }).format(currencyValue);
      break;
    default:
      error.message = 'Unsupported currency format.';
      return error.message;
  }

  return formattedCurrency;
}

function isValid(currencyValue) {
  if (typeof currencyValue !== 'number' || isNaN(currencyValue)) {
    return false;
  }
  if (currencyValue < 0) {
    error.message = 'The balance is negative.';
    return false;
  }
  return true;
}

function getFormattedMoney(
  formattedCurrency,
  currencyLength,
  currencyValue,
  currencyType,
  isWholeNumber = false,
  decimals = 2,
) {
  switch (true) {
    case currencyLength < 6:
      formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      break;
    case currencyLength === 6:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000).toFixed(1);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, 1);

      if (isWholeNumber) {
        formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      }

      formattedCurrency = setIt(currencyValue, formattedCurrency, 'k');
      break;
    case currencyLength === 7:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000).toFixed(3);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, 3);

      if (isWholeNumber) {
        formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      }

      formattedCurrency = setIt(currencyValue, formattedCurrency, 'm');
      break;
    case currencyLength === 8:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000).toFixed(2);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      formattedCurrency = setIt(currencyValue, formattedCurrency, 'm');
      break;
    case currencyLength === 9:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000).toFixed(1);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, 1);

      if (isWholeNumber) {
        formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      }

      formattedCurrency = setIt(currencyValue, formattedCurrency, 'm');
      break;
    case currencyLength === 10:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000000).toFixed(3);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, 3);

      if (isWholeNumber) {
        formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      }

      formattedCurrency = setIt(currencyValue, formattedCurrency, 'b');
      break;
    case currencyLength === 11:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000000).toFixed(2);
      }
      formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      formattedCurrency = setIt(currencyValue, formattedCurrency, 'b');
      break;
    case currencyLength === 12:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000000).toFixed(1);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, 1);

      if (isWholeNumber) {
        formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      }

      formattedCurrency = setIt(currencyValue, formattedCurrency, 'b');
      break;
    case currencyLength === 13:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000000000).toFixed(3);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, 3);

      if (isWholeNumber) {
        formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      }

      formattedCurrency = setIt(currencyValue, formattedCurrency, 't');
      break;
    case currencyLength === 14:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000000000).toFixed(2);
      }
      formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      formattedCurrency = setIt(currencyValue, formattedCurrency, 't');
      break;
    case currencyLength === 15:
      if (!isWholeNumber) {
        currencyValue = (currencyValue / 1000000000000).toFixed(1);
      }

      formattedCurrency = moneyFormatter(currencyValue, currencyType, 1);

      if (isWholeNumber) {
        formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      }

      formattedCurrency = setIt(currencyValue, formattedCurrency, 't');
      break;
    case currencyLength > 15:
      formattedCurrency = moneyFormatter(currencyValue, currencyType, decimals);
      break;
    default:
      error.message = 'Something went wrong for now.';
      return error.message;
  }
  return formattedCurrency;
}

function getFormattedGrams(currencyValue) {
  currencyValue = Math.trunc(currencyValue);
  currencyValue = currencyValue + 'gr';
  return currencyValue;
}

function setIt(str1, str2, suffix) {
  str1 = str1.toString();
  str2 = str2.toString();
  suffix = suffix.toString();
  let str = str1 + suffix;
  return str2.replace(str1, str);
}
