export const getElementPosition = (
  element,
  horizontalAlignment,
  verticalAlignment = "middle"
) => {
  const rect = element.getBoundingClientRect();
  let left = 0;
  let top = 0;

  // Horizontal alignment
  switch (horizontalAlignment) {
    case "left":
      left = rect.left + window.scrollX;
      break;
    case "center":
      left = rect.left + rect.width / 2 + window.scrollX;
      break;
    case "right":
      left = rect.left + rect.width + 26 + window.scrollX;
      break;
  }

  // Vertical alignment
  switch (verticalAlignment) {
    case "top":
      top = rect.top - 8 + window.scrollY;
      break;
    case "middle":
      top = rect.top + rect.height / 2 + window.scrollY;
      break;
    case "bottom":
      top = rect.top + rect.height + 8 + window.scrollY;
      break;
  }

  return {
    top: Number(top?.toFixed(2)),
    left: Number(left?.toFixed(2)),
  };
};

export const getElementMiddlePosition = (elm, position) => {
  if (elm) {
    switch (position) {
      case "top":
        return getElementPosition(elm, "center", "top");
      case "bottom":
        return getElementPosition(elm, "center", "bottom");
      case "left":
        return getElementPosition(elm, "left", "middle");
      case "right":
        return getElementPosition(elm, "right", "middle");
    }
  }

  return { top: 0, left: 0 };
};
