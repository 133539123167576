import { testMarkets } from '../../constants/testMarkets';
import { fetchWrapper } from '../../utils/fetchWrapper';

// only for testing
const isProdEnv = process.env?.REACT_APP_PAYTIKO_ENVIRONMENT === 'PRODUCTION';

const createBrokerAccount = async () => {
  try {
    const response = await fetchWrapper.post(`/broker/create-accounts`);
    return response;
  } catch (error) {
    throw error;
  }
};

const transferWalletToBroker = async (data) => {
  try {
    return await fetchWrapper.post(`/broker/transfers/wallet-to-broker`, data);
  } catch (error) {
    throw error;
  }
};

const transferBrokerToWallet = async (data) => {
  try {
    return await fetchWrapper.post(`/broker/transfers/broker-to-wallet`, data);
  } catch (error) {
    throw error;
  }
};

const transferBrokerToBroker = async (data) => {
  try {
    return await fetchWrapper.post(`/broker/transfers/broker-to-broker`, data);
  } catch (error) {
    throw error;
  }
};

const getBrokerUserData = async () => {
  try {
    return await fetchWrapper.get(`/broker/user-data`);
  } catch (error) {
    throw error;
  }
};

const getDemoBrokerUserData = async () => {
  try {
    return await fetchWrapper.get(`/broker/demo-access-token`);
  } catch (error) {
    throw error;
  }
};

const getTradeMarkets = async (traderAccountId) => {
  try {
    // only for testing
    // if (!isProdEnv) {
    //   return testMarkets;
    // }
    return await fetchWrapper.get(`/broker/get-markets?brokerAccountId=${traderAccountId}`);
  } catch (error) {
    throw error;
  }
};

const getUserWatchlist = async () => {
  try {
    return await fetchWrapper.get(`/broker/watchlist`);
  } catch (error) {
    throw error;
  }
};

const addAssetToWatchlist = async (data) => {
  try {
    return await fetchWrapper.post(`/broker/watchlist`, data);
  } catch (error) {
    throw error;
  }
};

const deleteAssetFromWatchlist = async (assetId) => {
  try {
    return await fetchWrapper.delete(`/broker/watchlist/${assetId}`);
  } catch (error) {
    throw error;
  }
};

const refreshUserBrokerBalance = async (userId) => {
  try {
    return await fetchWrapper.get(`/broker/refresh-balance/${userId}`);
  } catch (error) {
    throw error;
  }
};

const createTradeOrder = async (data) => {
  try {
    return await fetchWrapper.post(`/broker/trade-order`, data);
  } catch (error) {
    throw error;
  }
};

const getMarketDetails = async (marketSymbol) => {
  try {
    return await fetchWrapper.get(`/broker/get-markets/${marketSymbol}`);
  } catch (error) {
    throw error;
  }
};

const getDemoMarketDetails = async (marketSymbol) => {
  try {
    return await fetchWrapper.get(`/broker/demo-get-markets/${marketSymbol}`);
  } catch (error) {
    throw error;
  }
};

const confirmOrder = async (data) => {
  try {
    return await fetchWrapper.post(`/broker/confirm-order`, data);
  } catch (error) {
    throw error;
  }
};
('');
const getMarketHistory = async (instrument = 'btc_usdt', period = '1D') => {
  try {
    return await fetchWrapper.get(`/broker/get-market-history?marketSymbol=${instrument}&period=${period}`);
  } catch (error) {
    return error;
  }
};

const getDemoMarketHistory = async (instrument = 'btc_usdt', period = '1D') => {
  try {
    return await fetchWrapper.get(`/broker/get-demo-market-history?marketSymbol=${instrument}&period=${period}`);
  } catch (error) {
    return error;
  }
};

const getCryptoOrders = async (trader_account_id, body) => {
  try {
    return await fetchWrapper.post(`/broker/get-orders?brokerAccountId=${trader_account_id}`, body);
  } catch (error) {
    throw error;
  }
};

const brokerService = {
  confirmOrder,
  getCryptoOrders,
  getTradeMarkets,
  getDemoMarketHistory,
  getMarketHistory,
  getMarketDetails,
  getDemoMarketDetails,
  createTradeOrder,
  getBrokerUserData,
  getDemoBrokerUserData,
  createBrokerAccount,
  transferWalletToBroker,
  transferBrokerToWallet,
  transferBrokerToBroker,
  refreshUserBrokerBalance,
  getUserWatchlist,
  addAssetToWatchlist,
  deleteAssetFromWatchlist,
};

export default brokerService;
