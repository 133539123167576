import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles, Typography } from "@mui/material";
import DashBoardPortfolioAllocationChart from "../../../common/Charts/DashBoardPortfolioAllocationChart";

function PortfolioAllocationMiddle() {
  const classes = useStyles();
  return (
    <div className={classes.proggressContainer}>
      <Typography className={classes.title}>PORTFOLIO ALLOCATION</Typography>
      <DashBoardPortfolioAllocationChart />
    </div>
  );
}

export default PortfolioAllocationMiddle;

const useStyles = makeStyles((theme) =>
  createStyles({
    proggressContainer: {
      top: "20%",
      position: "relative",
      paddingTop: "0.5rem",
      width: "auto",
      alignItems: "center",
      height: "46%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      fontFamily: theme.typography.fontFamily,
    },
    title: {
      letterSpacing: "0.3px",
      display: "flex",
      justifyContent: "flex-start",
      fontFamily: theme.typography.fontFamily,
      fontSize: "var(--font-size-17)",
      paddingTop: "3rem",
      marginBottom: "2rem",
      [theme.breakpoints.down("1850")]: {
        fontSize: "var(--font-size-16)",
      },
      [theme.breakpoints.down("1770")]: {
        fontSize: "var(--font-size-15)",
      },
      [theme.breakpoints.down("1650")]: {
        fontSize: "var(--font-size-14)",
      },
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-13)",
      },
      [theme.breakpoints.down("1510")]: {
        fontSize: "var(--font-size-12)",
        letterSpacing: "0px",
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: "var(--font-size-12)",
        letterSpacing: "0px",
        paddingTop: "1.6rem",
      },
      [theme.breakpoints.down("1000")]: {
        fontSize: "var(--font-size-11)",
        letterSpacing: "0px",
        paddingTop: "1rem",
      },
      [theme.breakpoints.down("1000")]: {
        fontSize: "var(--font-size-10)",
        letterSpacing: "0px",
        paddingTop: "0rem",
      },
    },
  })
);
