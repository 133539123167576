import { Box } from "@mui/system";
import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import AppLayout from "../../../components/common/Layout/AppLayout";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import { routes } from "../../../routes";
import SumSubComponent from "../../../components/WebComponents/SumSubComponent/SumSubComponent";

function MobileSumSub() {
  const classes = useStyles();

  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileNavigationBack page={"Verification"} route={routes.dashboard} />
        <SumSubComponent />
      </Box>
    </AppLayout>
  );
}

export default MobileSumSub;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
    },
    withdrawMobile: {
      width: "100%",
      height: "92.2%",
      border: "0px !important",
    },
  })
);
