import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import '../../../../styles/global.fonts.css';
import { useState } from 'react';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';
import NotificationMessagePopup from '../../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import ArrowTransferFunds from '../../../../assets/images/transfer-arrow.png';
import TrendingUpSharpIcon from '@mui/icons-material/TrendingUpSharp';
import { Button, FormControl, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { customValidation } from '../../../../helpers/inputValidator';
import { paymentsService } from '../../../../service/payments';
import { messages } from '../../../../constants/popupMessages';
import { useAppContext } from '../../../../context/DataContext';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import brokerService from '../../../../service/broker';
import currencyFormatter from '../../../../helpers/currencyFormatter/currencyFormatter';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import isVerified from '../../../../helpers/isVerified';
import NonVerifiedUserPopUp from '../../../common/Popups/NonVerifiedUser/NonVerifiedUser';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const calculateConversion = (fromAccount, toAccount, amount, type, transferRate) => {
  if (!fromAccount || !toAccount || !amount) return 0;

  // Normalize currencies: treat USDT as USD
  const fromCurrency = fromAccount.currency === 'USDT' ? 'USD' : fromAccount.currency;
  const toCurrency = toAccount.currency === 'USDT' ? 'USD' : toAccount.currency;

  if (type === 'from') {
    if (fromCurrency === 'USD' && toCurrency === 'EUR') {
      return amount / transferRate;
    } else if (fromCurrency === 'EUR' && toCurrency === 'USD') {
      return amount * transferRate;
    } else if (fromCurrency === 'USD' && toCurrency === 'USD') {
      return amount; // USD to USD (including USDT to USD or USD to USDT)
    }
  } else if (type === 'to') {
    if (toCurrency === 'USD' && fromCurrency === 'EUR') {
      return amount / transferRate;
    } else if (toCurrency === 'EUR' && fromCurrency === 'USD') {
      return amount * transferRate;
    } else if (toCurrency === 'USD' && fromCurrency === 'USD') {
      return amount; // USD to USD (including USDT to USD or USD to USDT)
    }
  }

  return Number(amount);
};

const TransferFundsPopup = ({
  isPopupOpen,
  setIsPopupOpen,
  userTradeAccounts,
  userBrokerAccounts,
  selectedCryptoAccount,
}) => {
  const classes = useStyles();
  const { setRefetch, eurToUsd, nonVerifiedPopUpOpen, setNonVerifiedPopUpOpen, setIsBuySellLoading } = useAppContext();
  const [transferRate, setTransferRate] = useState(); // EUR to USD

  const getWalletLabel = (index) => {
    return index === 0 ? 'Crypto Wallet' : `Crypto Wallet ${index + 1}`;
  };

  let allUserAccounts = [...userTradeAccounts, ...userBrokerAccounts]?.map((account) => {
    const assets = account?.assets;

    if (assets) {
      const balance = assets?.find((a) => a?.assetId === 'usdt')?.amount || 0;
      return { ...account, balance, currency: 'USDT' };
    }

    return account;
  });

  const [initialFromAccount, initialToAccount] = React.useMemo(() => {
    if (allUserAccounts.length > 0) {
      const fromAccount = allUserAccounts[0];
      const toAccount = selectedCryptoAccount
        ? allUserAccounts.find((account) => account.brokerAccountId === selectedCryptoAccount.brokerAccountId)
        : allUserAccounts.length > 1
        ? allUserAccounts[1]
        : {};
      return [fromAccount, toAccount];
    } else {
      return [{}, {}];
    }
  }, [allUserAccounts, selectedCryptoAccount]);

  const [fieldType, setFieldType] = useState(null);

  const [fromAccount, setFromAccount] = useState(initialFromAccount || '');
  const [toAccount, setToAccount] = useState(initialToAccount || '');

  const [previousFromAccount, setPreviousFromAccount] = useState(null);
  const [previousToAccount, setPreviousToAccount] = useState(null);

  const [amountFrom, setAmountFrom] = useState();
  const [amountTo, setAmountTo] = useState();

  const [loading, setLoading] = useState(false);
  const [messagePopupOpen, setMessagePopupOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [popupType, setPopupType] = useState('');

  const cachedEurToUsd = sessionStorage.getItem('eurToUsd');

  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const isSameCurrency =
    fromAccount?.currency === toAccount?.currency ||
    (fromAccount.currency === 'USDT' && fromAccount.currency === 'USD') ||
    (fromAccount.currency === 'USD' && fromAccount.currency === 'USDT');

  let exchangeRate = 0;
  if ((fromAccount.currency === 'USD' || fromAccount.currency === 'USDT') && toAccount.currency === 'EUR') {
    exchangeRate = 1 / transferRate;
  } else if (fromAccount.currency === 'EUR' && (toAccount.currency === 'USD' || toAccount.currency === 'USDT')) {
    exchangeRate = 1 * transferRate;
  } else if (
    (fromAccount.currency === 'USD' && toAccount.currency === 'USDT') ||
    (fromAccount.currency === 'USDT' && toAccount.currency === 'USD')
  ) {
    exchangeRate = 1;
  }

  const getTransferRate = async () => {
    setLoading(true);
    try {
      const transferRate = await paymentsService.getEURtoUSDTransferRate();
      setTransferRate(transferRate?.rateEurToUsd);
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);
      if (customError?.message === 'Trading in Euro is not available at this time. Please try again later.') {
        setTransferRate(eurToUsd ?? cachedEurToUsd ?? 0);
      } else {
        setMessagePopupOpen(true);
        setMessage(customError?.message);
        setPopupType('error');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransferRate();
  }, []);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleFromChange = (e) => {
    const account = e.target.value;
    const price = account?.assets?.find((a) => a?.assetId === 'usdt')?.amount || 0;
    const currency = 'USDT';
    setFieldType('from');
    if (account?.brokerAccountId) {
      setFromAccount({
        ...account,
        currency,
        balance: price,
      });
    } else {
      setFromAccount(account);
    }
  };

  const handleToChange = (e) => {
    const account = e.target.value;
    const price = account?.assets?.find((a) => a?.assetId === 'usdt')?.amount || 0;
    const currency = 'USDT';
    setFieldType('to');
    if (account?.brokerAccountId) {
      setToAccount({
        ...account,
        currency,
        balance: price,
      });
    } else {
      setToAccount(account);
    }
  };

  const handleAmountFromChange = (e) => {
    let amount = e.target.value;
    const leftpart = amount?.slice('.')?.[0];

    if ((amount > 0 && amount.length > 4 && leftpart === 0 && amount.startsWith('0')) || amount.startsWith('00')) {
      amount = amount.substring(1);
      setAmountFrom(amount);
      return;
    } else if (amount.startsWith('.')) {
      setAmountFrom((prev) => prev);
      return;
    } else if (amount === '00') {
      setAmountTo(0);
      e.target.value = '0';
      return;
    } else {
      if (amount === '') {
        setAmountFrom('');
        setAmountTo('');
      } else {
        setAmountFrom(Number(amount));
        const convertedAmount = calculateConversion(fromAccount, toAccount, Number(amount), 'from', transferRate);

        setAmountTo(convertedAmount?.toFixed(2));
      }
    }
  };

  const handleAmountToChange = (e) => {
    let amount = e.target.value;
    const leftpart = amount?.slice('.')?.[0];

    if ((amount > 0 && amount.length > 4 && leftpart === 0 && amount.startsWith('0')) || amount.startsWith('00')) {
      amount = amount.substring(1);
      setAmountTo(amount);
      return;
    } else if (amount.startsWith('.')) {
      setAmountTo((prev) => prev);
      return;
    } else if (amount === '00') {
      setAmountTo(0);
      e.target.value = '0';
      return;
    } else {
      if (amount === '') {
        setAmountTo('');
        setAmountFrom('');
      } else {
        setAmountTo(Number(amount));
        const convertedAmount = calculateConversion(fromAccount, toAccount, Number(amount), 'to', transferRate);

        setAmountFrom(convertedAmount?.toFixed(2));
      }
    }
  };

  useEffect(() => {
    const fromAccountId = fromAccount?.tradeAccountId || fromAccount?.brokerAccountId;
    const toAccountId = toAccount?.tradeAccountId || toAccount?.brokerAccountId;

    if (fromAccountId === toAccountId) {
      if (fieldType === 'from') {
        setToAccount(previousFromAccount);
        const convertedAmount = calculateConversion(
          fromAccount,
          previousFromAccount,
          Number(amountFrom),
          'from',
          transferRate,
        );
        setAmountTo(convertedAmount?.toFixed(2));
      } else if (fieldType === 'to') {
        setFromAccount(previousToAccount);
        const convertedAmount = calculateConversion(previousToAccount, toAccount, Number(amountTo), 'to', transferRate);
        setAmountFrom(convertedAmount.toFixed(2));
      }
    } else {
      if (fieldType === 'from') {
        const convertedAmount = calculateConversion(fromAccount, toAccount, Number(amountFrom), 'from', transferRate);
        setAmountTo(convertedAmount?.toFixed(2));
      } else if (fieldType === 'to') {
        const convertedAmount = calculateConversion(fromAccount, toAccount, Number(amountTo), 'to', transferRate);
        setAmountFrom(convertedAmount.toFixed(2));
      }
    }
    setFieldType(null);
    setPreviousFromAccount(fromAccount);
    setPreviousToAccount(toAccount);
  }, [fieldType, fromAccount, toAccount, amountFrom, amountTo]);

  async function handleSubmit() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    const result = await isVerified(setNonVerifiedPopUpOpen, setIsBuySellLoading);

    if (isDemo || result === false) {
      return;
    }
    setLoading(true);

    const walletToWalletTransferData = {
      fromTaid: fromAccount?.tradeAccountId,
      toTaid: toAccount?.tradeAccountId,
      fromAmount: Number(amountFrom),
    };

    const walletToBrokerTransferData = {
      tradeAccountId: fromAccount?.tradeAccountId,
      brokerAccountId: toAccount?.brokerAccountId,
      fromAmount: Number(amountFrom),
    };

    const brokerToWalletTransferData = {
      brokerAccountId: fromAccount?.brokerAccountId,
      tradeAccountId: toAccount?.tradeAccountId,
      fromAmount: Number(amountFrom),
    };

    const brokerToBrokerTransferData = {
      fromBrokerAccountId: fromAccount?.brokerAccountId,
      toBrokerAccountId: toAccount?.brokerAccountId,
      fromAmount: Number(amountFrom),
    };

    try {
      if (Number(amountFrom) === 0 || amountFrom === undefined) {
        setMessagePopupOpen(true);
        setMessage(messages.errorTransferAmount);
        setPopupType('error');
        return;
      }
      let response;

      const fromAccountType = fromAccount?.currency === 'USDT' ? 'broker' : 'wallet';
      const toAccountType = toAccount?.currency === 'USDT' ? 'broker' : 'wallet';

      const transactionType = `${fromAccountType.toLowerCase()}to${toAccountType.toLowerCase()}`;

      switch (transactionType) {
        case 'wallettowallet':
          response = await paymentsService.transferFunds(walletToWalletTransferData);
          break;
        case 'wallettobroker':
          response = await brokerService.transferWalletToBroker(walletToBrokerTransferData);
          break;
        case 'brokertowallet':
          response = await brokerService.transferBrokerToWallet(brokerToWalletTransferData);
          break;
        case 'brokertobroker':
          response = await brokerService.transferBrokerToBroker(brokerToBrokerTransferData);
          break;
        default:
          throw new Error('Invalid account type combination');
      }
      if (response) {
        setMessagePopupOpen(true);
        setMessage(messages.successTransferFunds);
        setPopupType('success');
        setRefetch((prev) => !prev);
      }
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);
      console.error(error);
      setMessagePopupOpen(true);
      setMessage(customError?.message);
      setPopupType('error');
    } finally {
      setLoading(false);
    }
  }

  const showTransferRate =
    transferRate &&
    !(
      (fromAccount.currency === 'USD' && toAccount.currency === 'USDT') ||
      (fromAccount.currency === 'USDT' && toAccount.currency === 'USD')
    );

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.popupTitle}>
            TRANSFER FUNDS
          </BootstrapDialogTitle>
          <DialogContent>
            <Box className={classes.fieldsWrapper}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                className={classes.form}
              >
                <FormControl className={classes.transferContainer}>
                  <Select
                    value={fromAccount}
                    onChange={handleFromChange}
                    defaultValue={initialFromAccount}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className={classes.selectField}
                    sx={{
                      backgroundColor: '#1E9C71',
                    }}
                    renderValue={(selected) => {
                      let index;
                      if (selected.brokerAccountId) {
                        index = userBrokerAccounts.findIndex(
                          (account) => account.brokerAccountId === selected.brokerAccountId,
                        );
                      }
                      return (
                        <Box>
                          <Box>{selected?.tradeAccountId ? 'Gold Wallet' : getWalletLabel(index)}</Box>
                          <Box className="menuItemBalance">
                            <span>Balance </span>

                            {currencyFormatter(selected.balance, '', selected.currency, 'custom', true, 2)}
                          </Box>
                        </Box>
                      );
                    }}
                  >
                    {userTradeAccounts.map((account) => {
                      const price = account?.balance;
                      const currency = account?.currency;

                      return (
                        <MenuItem className={classes.menuItemSelect} key={account.tradeAccountId} value={account}>
                          <div style={{ marginRight: '4px' }}>{'Gold Wallet'}</div>
                          <div className={classes.menuItemBalance}>
                            <span>Balance </span>
                            {currencyFormatter(price, '', currency, true)}
                          </div>
                        </MenuItem>
                      );
                    })}
                    {userBrokerAccounts.map((account, index) => {
                      const price = account?.assets?.find((a) => a?.assetId === 'usdt')?.amount || 0;
                      const currency = 'USDT';

                      return (
                        <MenuItem className={classes.menuItemSelect} key={account.brokerAccountId} value={account}>
                          <div style={{ marginRight: '4px' }}>{getWalletLabel(index)}</div>
                          <div className={classes.menuItemBalance}>
                            <span>Balance </span>
                            {currencyFormatter(price, '', currency, true)}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <TextField
                    type="number"
                    placeholder="0.00"
                    className={classes.amountField}
                    autoComplete="off"
                    id="transfer-funds-from-input"
                    value={amountFrom}
                    onChange={(e) => handleAmountFromChange(e)}
                    onKeyDown={customValidation}
                    onFocus={(e) =>
                      e.target.addEventListener('wheel', (e) => {
                        e.preventDefault();
                      })
                    }
                    inputProps={{
                      step: '0.1',
                    }}
                  />
                  <div style={{ backgroundColor: '#1E9C71' }} className={classes.currency}>
                    <span id="basic-addon2">{fromAccount.currency}</span>
                  </div>
                </FormControl>
                <img src={ArrowTransferFunds} alt="Arrow Icon" style={{ width: '115px' }} />
                <FormControl className={classes.transferContainer}>
                  <Select
                    value={toAccount}
                    onChange={handleToChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className={classes.selectField}
                    sx={{
                      backgroundColor: '#4C7290',
                    }}
                    renderValue={(selected) => {
                      let index;
                      if (selected.brokerAccountId) {
                        index = userBrokerAccounts.findIndex(
                          (account) => account.brokerAccountId === selected.brokerAccountId,
                        );
                      }
                      return (
                        <Box>
                          <Box>{selected?.tradeAccountId ? 'Gold Wallet' : getWalletLabel(index)}</Box>
                          <Box className="menuItemBalance">
                            <span>Balance </span>

                            {currencyFormatter(selected.balance, '', selected.currency, 'custom', true, 2)}
                          </Box>
                        </Box>
                      );
                    }}
                  >
                    {userTradeAccounts.map((account) => {
                      const price = account?.balance;
                      const currency = account?.currency;

                      return (
                        <MenuItem className={classes.menuItemSelect} key={account.tradeAccountId} value={account}>
                          <div style={{ marginRight: '4px' }}>{'Gold Wallet'}</div>
                          <div className={classes.menuItemBalance}>
                            <span>Balance </span>
                            {currencyFormatter(price, '', currency, true)}
                          </div>
                        </MenuItem>
                      );
                    })}
                    {userBrokerAccounts.map((account, index) => {
                      const price = account?.assets?.find((a) => a?.assetId === 'usdt')?.amount || 0;
                      const currency = 'USDT';

                      return (
                        <MenuItem className={classes.menuItemSelect} key={account.brokerAccountId} value={account}>
                          <div style={{ marginRight: '4px' }}>{getWalletLabel(index)}</div>
                          <div className={classes.menuItemBalance}>
                            <span>Balance </span>
                            {currencyFormatter(price, '', currency, true)}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <TextField
                    type="number"
                    placeholder="0.00"
                    className={classes.amountField}
                    autoComplete="off"
                    id="transfer-funds-to-input"
                    value={amountTo}
                    onChange={(e) => handleAmountToChange(e)}
                    onKeyDown={customValidation}
                    onFocus={(e) =>
                      e.target.addEventListener('wheel', (e) => {
                        e.preventDefault();
                      })
                    }
                  />
                  <div style={{ backgroundColor: '#4C7290' }} className={classes.currency}>
                    <span id="basic-addon2">{toAccount.currency}</span>
                  </div>
                </FormControl>
                {!isSameCurrency && (
                  <div className={classes.conversionRate}>
                    {showTransferRate && (
                      <>
                        <TrendingUpSharpIcon fontSize="medium" color="success" />
                        <span>
                          1 {fromAccount.currency} = {exchangeRate.toFixed(5)} {toAccount.currency}
                        </span>
                      </>
                    )}
                  </div>
                )}
                <Box>
                  <Button type="submit" className={classes.transferBtn} variant="contained">
                    Transfer
                  </Button>
                </Box>
              </form>
            </Box>
          </DialogContent>
        </div>
      </BootstrapDialog>
      <LoadingBar open={loading} />
      {messagePopupOpen && (
        <NotificationMessagePopup
          type={popupType}
          isPopupOpen={true}
          setIsPopupOpen={handleClose}
          message={message}
          hasDepositFundsBtn={false}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
        />
      )}
    </div>
  );
};

export default TransferFundsPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      maxWidth: '777px',
      maxHeight: '450px',
      height: '100%',
      width: '100%',
      minWidth: '300px',
    },
    fontFamily: theme.typography.fontFamily,
  },
  popupContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    height: '100%',
  },
  popupTitle: {
    fontSize: 'var(--font-size-22)',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: '1rem',
    paddingLeft: '2rem',
    [theme.breakpoints.down('400')]: {
      fontSize: 'var(--font-size-17)',
      paddingLeft: '1rem',
    },
  },
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    minHeight: '315px',
    '& .MuiFormHelperText-root': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '17px',
      fontWeight: 500,
      color: '#303030',
      marginLeft: 'unset',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    height: '100%',
  },
  transferContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectField: {
    height: '56px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 400,
    color: '#FFFFFF',
    borderRadius: '10px 0px 0px 10px',
    [theme.breakpoints.down('567')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('515')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('435')]: {
      fontSize: '8px',
    },
    '& span': {
      fontWeight: 600,
    },
    '& .MuiOutlinedInput-input.MuiSelect-select': {
      textAlign: 'left',
      paddingLeft: '32px',
      width: '250px',
      [theme.breakpoints.down('768')]: {
        width: '240px',
      },
      [theme.breakpoints.down('515')]: {
        width: '155px',
      },
      [theme.breakpoints.down('435')]: {
        width: '100px',
      },
    },
    '& .MuiSvgIcon-root': {
      right: 'unset',
      left: '7px',
      color: '#FFFFFF',
    },
  },
  amountField: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiOutlinedInput-root': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '18px',
      fontWeight: 400,
      borderRadius: 'unset',
      width: '160px',
      height: '56px',
      [theme.breakpoints.down('768')]: {
        width: '115px',
      },
      [theme.breakpoints.down('567')]: {
        fontSize: '13px',
        width: '80px',
      },
      [theme.breakpoints.down('415')]: {
        fontSize: '10px',
        width: '63px',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '56px',
    backgroundColor: '#CECECE',
    color: '#FFFFFF',
    borderRadius: '0px 10px 10px 0px',
    [theme.breakpoints.down('567')]: {
      fontSize: '12px',
      width: '30px',
    },
  },
  conversionRate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 500,
    gap: '8px',
    marginTop: '15px',
    height: '24px',
  },
  transferBtn: {
    width: '160px',
    height: '40px',
    color: 'white !important',
    backgroundColor: 'black !important',
    borderRadius: '60px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 600,
  },

  menuItemSelect: {},

  menuItemBalance: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
