export const getUserShoppingCartForDemo = async (
  fn,
  demoServerCart,
  isAppView
) => {
  try {
    const bucketData = JSON.parse(localStorage.getItem("bucket")) || {};

    localStorage.setItem("bucket", JSON.stringify(demoServerCart));
    fn(demoServerCart);

    if (isAppView) {
      let counter = 0;

      if (bucketData) {
        let totalQuantity = 0;

        for (const productId in bucketData) {
          totalQuantity += bucketData[productId].quantity;
        }

        counter = totalQuantity;
      }

      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        `{"Event": "CartBadge", "Action": "${counter}"}`
      );
    }
  } catch (error) {
    console.log(error);
  }
};
