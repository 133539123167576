import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, MenuItem, Radio, Select, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import DepositIcon from '../../../assets/images/deposit-icon.svg';

import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';

import { useAppContext } from '../../../context/DataContext';
import TransferFundsPopup from '../../MobileComponents/popups/TransferFundsPopup/TransferFundsPopup';

import CryptoAccIcon from '../../../assets/images/crypto-account-icon.png';
import clsx from 'clsx';
import IButton from '../../common/IButton/IButton';
import { tooltipContent } from '../../../constants/tooltipTexts';

const CryptoAccounts = () => {
  const classes = useStyles();
  const {
    isMobile,
    isAppView,
    userBrokerAccounts,
    selectedCryptoAccount,
    userTradeAccounts,
    setSelectedCryptoAccount,
  } = useAppContext();
  const [isTransferFundsPopUpOpen, setIsTransferFundsPopUpOpen] = useState(false);

  const isSmallViewport = isMobile || isAppView;
  const usdtBalance = selectedCryptoAccount?.assets?.find((asset) => asset?.assetId === 'usdt')?.amount;

  const handleSelectAcc = (account) => {
    setSelectedCryptoAccount(account);
  };

  const getWalletLabel = (index) => {
    return index === 0 ? 'Crypto Wallet' : `Crypto Wallet ${index + 1}`;
  };

  return (
    <div className={clsx(isSmallViewport ? classes.mobileWrapper : classes.wrapper)}>
      <Select
        value={''}
        defaultValue=""
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        className={classes.selectField}
        renderValue={() => {
          if (!selectedCryptoAccount) return 'Select Wallet';
          const index = userBrokerAccounts.findIndex(
            (acc) => acc.brokerAccountId === selectedCryptoAccount.brokerAccountId,
          );
          return (
            <Box className={classes.leftSelect}>
              <img src={CryptoAccIcon} alt="crypto-icon" />
              <div className={classes.walletInfo}>
                <Box className={classes.selectLabel}>{getWalletLabel(index)}</Box>
                <Tooltip title={selectedCryptoAccount.brokerAccountId}>
                  <Box className={classes.selectValue}>
                    <span>{selectedCryptoAccount.brokerAccountId}</span>
                  </Box>
                </Tooltip>
              </div>
            </Box>
          );
        }}
        sx={{
          '& .MuiSvgIcon-root': {
            right: 'unset',
            left: '7px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {userBrokerAccounts?.map((acc, idx) => {
          const balance = acc?.assets?.filter((asset) => asset?.assetId === 'usdt')?.[0];

          return (
            <MenuItem className={classes.menuItemSelect} key={idx} onClick={() => handleSelectAcc(acc)}>
              <div className={classes.left}>
                <img src={CryptoAccIcon} alt="crypto-icon" />

                <div className={classes.menuItemBalance}>
                  <span className={classes.walletDetailsValue}>
                    <span className={classes.walletDetailsLabel}>{getWalletLabel(idx)}</span>
                    <span>{acc?.brokerAccountId}</span>
                  </span>

                  <Tooltip title={acc?.brokerAccountId || ''} placement="top">
                    <span> </span>
                  </Tooltip>
                  <div>
                    <span>{currencyFormatter(balance?.amount || 0, '', 'USDT', 'custom', true, 2)}</span>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <Radio checked={selectedCryptoAccount?.brokerAccountId === acc?.brokerAccountId} />
                </div>
              </div>
            </MenuItem>
          );
        })}
      </Select>
      <div className={classes.verticalLineWrapper}>
        {!isSmallViewport && <div className={classes.verticalLine}></div>}
      </div>
      <div className={classes.rightBalance}>
        <div className={classes.leftSection}>
          <span className={classes?.balanceLabel}>Balance</span>
          <span className={classes?.balanceValue}>
            {currencyFormatter(usdtBalance || 0, '', 'USDT', 'custom', true, 2)}
          </span>
        </div>
        <div className={classes.rightSection} onClick={() => setIsTransferFundsPopUpOpen(true)}>
          <ListItemIcon>
            <img src={DepositIcon} alt="Deposit" />
          </ListItemIcon>
        </div>
        <div style={{ position: 'absolute', bottom: isMobile ? '77px' : '65px', right: '-7px' }}>
          <IButton tooltipTitle={tooltipContent.cryptoWalletAccounts} />
        </div>
      </div>
      {isTransferFundsPopUpOpen &&
        (userTradeAccounts?.length || userBrokerAccounts?.length || selectedCryptoAccount) && (
          <TransferFundsPopup
            isPopupOpen={isTransferFundsPopUpOpen}
            setIsPopupOpen={setIsTransferFundsPopUpOpen}
            userTradeAccounts={userTradeAccounts}
            userBrokerAccounts={userBrokerAccounts}
            selectedCryptoAccount={selectedCryptoAccount}
          />
        )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 10px 1fr',

    height: '100%',
    marginTop: '10px',
    borderRadius: '10px',
    border: '1px solid #ECECEC',
    background: 'linear-gradient(#FFFAFA, #F9F9F9)', // Modified gradient direction
  },

  mobileWrapper: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 10px 1fr',

    height: '100%',
    marginTop: '10px',
    borderRadius: '10px',
    border: '1px solid transparent',
    background: 'transparent', // Modified gradient direction
  },

  transferContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },

  selectField: {
    height: '62px',
    border: 'none',
    overflow: 'hidden',
    paddingLeft: '18px',
  },

  leftSelect: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '40px 1fr',

    '@media (max-width: 768px)': {
      gridTemplateColumns: '30px 1fr',
    },
  },

  walletInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  menuItemSelect: {
    display: 'grid',
    gridTemplateColumns: '1fr 50px',

    height: '100%',
    fontSize: '12px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  left: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '50px 1fr',

    height: '62px',
  },

  rightBalance: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '62px',
    gap: '30px',
  },

  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  rightSection: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  balanceLabel: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#767676',
    paddingRight: '4px',
  },

  balanceValue: {
    fontSize: '14px',
    color: '#767676',
  },

  verticalLineWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  verticalLine: {
    width: '1px',
    height: '40px',
    backgroundColor: '#C9C9C9',
  },

  selectLabel: {
    fontWeight: 600,
    color: '#767676',
    fontSize: '14px',

    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '@media (max-width: 768px)': {
      fontSize: 12,
    },
  },

  idLabel: {
    fontWeight: 600,
    fontSize: '10px',
    color: '#767676',

    '@media (max-width: 768px)': {
      fontSize: 8,
    },
  },

  selectValue: {
    fontWeight: 400,
    fontSize: '10px',
    color: '#767676',
  },

  walletDetailsLabel: {
    fontSize: 14,
    width: '200px',
    minWidth: '170px',
    fontWeight: 600,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',

    '@media (max-width: 350px)': {
      width: '150px',
    },
  },

  walletDetailsValue: {
    fontWeight: 400,
    fontSize: '12px',
  },
}));

export default CryptoAccounts;
