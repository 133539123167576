import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import SuccessCheckIcon from "../../../../assets/images/success-check-icon.svg";
import ErrorIcon from "../../../../assets/images/error-icon.svg";
import BullionzLogoText from "../../../../assets/images/bullionz-logo-text.svg";
import "../../../../styles/global.fonts.css";
import { useAppContext } from "../../../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const NonVerifiedUserPopUp = ({
  type,
  isPopupOpen,
  setIsPopupOpen,
  message,
  handleCloseAutoInvestPopup = () => {},
}) => {
  const classes = useStyles({ type });
  const { isMobile } = useAppContext();
  const navigate = useNavigate();

  function navigateToSumSubPage() {
    navigate(routes.sumsub);
  }

  const handleClose = () => {
    setIsPopupOpen(false);
    handleCloseAutoInvestPopup();
  };

  function navigateToSumSub() {
    const appView = sessionStorage.getItem("isNative");
    const externalUserId = sessionStorage.getItem("externalUserId");
    const levelName = sessionStorage.getItem("levelName");
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        `{"Event": "NotVerified", "ExternalUserId": "${externalUserId}", "VerificationLevel": "${levelName}"}`
      );
    } else {
      navigateToSumSubPage();
      handleClose();
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div
          className={classes.popupContent}
          style={{ height: type === "error" && !isMobile && "309px" }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.popupTitle}
          >
            {type === "success" ? "Successful" : ""}
          </BootstrapDialogTitle>
          <DialogContent className={classes.contentContainer}>
            <div className={classes.message}>
              <div className={classes.checkIcon}>
                <img
                  style={{ borderRadius: "24px", width: "25px" }}
                  src={type === "success" ? SuccessCheckIcon : ErrorIcon}
                  alt={type === "success" ? "Success check icon" : "Error icon"}
                />
              </div>
              <Typography className={classes.popupSubtitle} gutterBottom>
                {message}
              </Typography>
            </div>
            <div className={classes.verify}>
              <span className={classes.link} onClick={navigateToSumSub}>
                Please Verify
              </span>
            </div>
            <div className={classes.bullionzLogoTextContainer}>
              <img
                className={classes.bullionzLogoText}
                src={BullionzLogoText}
                alt="Bullionz logo text"
              />
            </div>
          </DialogContent>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default NonVerifiedUserPopUp;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "1000px",
      width: "100%",

      [theme.breakpoints.down("1800")]: {
        width: "600px",
      },
      [theme.breakpoints.down("1500")]: {
        width: "500px",
        height: "308px",
      },
      [theme.breakpoints.down("1300")]: {
        width: "460px",
        height: "289px",
      },
      [theme.breakpoints.down("768")]: {
        width: "unset",
        height: "unset",
        minWidth: "302px",
      },
    },
  },
  popupContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  link: {
    cursor: "pointer",
    color: "var(--blue)",
    textDecoration: "underline",
  },
  message: {
    position: "relative",
    display: "flex",
    backgroundColor: "#F4F4F4",
    zIndex: "1",
    padding: "17px",
    top: "37px",
    [theme.breakpoints.down("768")]: {
      top: "12px",
      marginTop: "5%",
    },
  },
  verify: {
    position: "relative",
    display: "flex",
    backgroundColor: "#F4F4F4",
    zIndex: "1",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("768")]: {
      top: 37,
    },
    color: "#767676",
    fontWeight: 400,
    paddingTop: "0.5rem",
    fontSize: "var(--font-size-14)",
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-13)",
    },
    [theme.breakpoints.down("768")]: {
      fontSize: "var(--font-size-16)",
      fontWeight: "400",
    },
  },
  popupTitle: {
    borderRadius: "24px",
    fontSize: "var(--font-size-19)",
    paddingLeft: "3%",
    position: "relative",
    top: "6px",
    paddingBottom: "0px",
    height: "27px",
    [theme.breakpoints.down("1500")]: {
      fontSize: "var(--font-size-18)",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-17)",
    },
    [theme.breakpoints.down("768")]: {
      fontSize: "var(--font-size-14)",
      fontWeight: "600",
    },
  },
  checkIcon: {
    marginRight: "7px",
    display: "flex",
    alignItems: "baseline",
  },
  popupSubtitle: {
    color: "#767676",
    fontWeight: 400,
    fontSize: "var(--font-size-14)",
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-13)",
    },
    [theme.breakpoints.down("768")]: {
      fontSize: "var(--font-size-16)",
      fontWeight: "400",
    },
  },
  bullionzLogoTextContainer: {
    width: "100%",
    position: "relative",
  },
  bullionzLogoText: {
    width: "100%",
    position: "relative",
  },
  contentContainer: {
    position: "relative",
  },
}));
