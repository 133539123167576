import * as React from 'react';
import { makeStyles } from '@mui/styles';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import { Box, Button, IconButton, Pagination, Tab, Tabs, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import InvestIcon from '../../../assets/images/investment-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateWrapper } from '../../../helpers/dateFormatter';
import CustomButton from '../../common/CustomButton/CustomButton';
import { handleGetAllAutoInvestTransactions } from '../../../helpers/handleGetAutoInvestTransactions';
import { useEffect } from 'react';
import { useAppContext } from '../../../context/DataContext';
import ConfirmPopUp from '../../common/Popups/ConfirmPopUp/ConfirmPopUp';
import NotificationMessagePopup from '../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import serviceAutoInvest from '../../../service/autoInvest';
import IButton from '../../common/IButton/IButton';
import { tooltipContent } from '../../../constants/tooltipTexts';
import { messages } from '../../../constants/popupMessages';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="span" component="span">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const AutoInvestHistory = ({ isSeeAll = true, containerClassName }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [autoInvestTransactions, setAutoInvestTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allPages, setAllPages] = useState();
  const [error, setError] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState('');
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
    proceedAutoInvestOrderClicked,
    setProceedAutoInvestOrderClicked,
    userTradeAccounts,
  } = useAppContext();
  const isDemoMode = sessionStorage.getItem('isDemo');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  function navigateToAutoInvestHistory(e) {
    e.preventDefault();
    navigate(routes.autoInvestHistory);
    window.scrollTo(0, 0);
  }
  function navigateToAutoInvest() {
    navigate(routes.autoInvest);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (!isSeeAll) {
      handleGetAllAutoInvestTransactions(setAutoInvestTransactions, page, setAllPages, setError, setLoading);
    } else {
      handleGetAllAutoInvestTransactions(setAutoInvestTransactions, 0, setAllPages, setError, setLoading);
    }
  }, [page, proceedAutoInvestOrderClicked, cancelOrderId]);

  function handlePopUpOpen(id) {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    setCancelOrderId(id);
    setOpen(true);
  }

  function handlePopUpClosed() {
    setOpen(false);
  }

  const handleCancelAutoInvestOrder = async (orderId) => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    handlePopUpClosed();
    setLoading(true);
    const closedAutoInvest = {
      orderId: orderId,
    };
    try {
      const closeTransaction = await serviceAutoInvest.cancelAutoInvest(closedAutoInvest);
      setPopUpMessage(messages.orderClosed);
      setProceedAutoInvestOrderClicked(true);
      setNotificationPopupType('success');
    } catch (error) {
      const strError = error.message.toString();
      setPopUpMessage(strError);
      setProceedAutoInvestOrderClicked(true);
      setNotificationPopupType('error');
      console.error('Error close autoinvest order:', strError);
    } finally {
      handlePopUpClosed();
      setLoading(false);
    }
  };

  const tradeAccountsLength = userTradeAccounts?.length;

  return (
    <Box>
      <Box className={containerClassName}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="secondary"
              textColor="inherit"
            >
              <Tab className={classes.title} label="AUTO INVEST HISTORY" />
              {isSeeAll && (
                <Tab
                  className={classes.seeAll}
                  onClick={navigateToAutoInvestHistory}
                  label="See All"
                  disabled={isDemoMode}
                ></Tab>
              )}
              {isSeeAll && <IButton tooltipTitle={tooltipContent.autoInvestHistory} />}
            </Tabs>
            {!isSeeAll && <IButton tooltipTitle={tooltipContent.autoInvestHistory} />}
            <Divider />
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className={classes.tableContainer}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: isSeeAll && 70,
                          whiteSpace: 'nowrap',
                          fontWeight: '600',
                          '& th': {
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                          },
                        }}
                      ></TableCell>
                      {tradeAccountsLength > 1 && (
                        <TableCell
                          style={{
                            width: isSeeAll ? 220 : 'auto',
                            fontWeight: '600',
                          }}
                          align="center"
                        >
                          Account
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          width: isSeeAll ? 220 : 'auto',
                          fontWeight: '600',
                        }}
                        align="center"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        style={{
                          width: isSeeAll ? 183 : 'auto',
                          fontWeight: '600',
                        }}
                        align="center"
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        style={{
                          width: isSeeAll ? 250 : 'auto',
                          fontWeight: '600',
                        }}
                        align="center"
                      >
                        Period
                      </TableCell>
                      <TableCell
                        style={{
                          width: isSeeAll ? 265 : 'auto',
                          fontWeight: '600',
                        }}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell style={{ fontWeight: '600' }} align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {autoInvestTransactions.length > 0 ? (
                      autoInvestTransactions.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="auto-invest"
                              size="small"
                              disableRipple
                              sx={{
                                cursor: 'default',
                                '&.MuiButtonBase-root:hover': {
                                  bgcolor: 'transparent',
                                },
                              }}
                            >
                              <img src={InvestIcon} alt="Invest Icon" style={{ width: 18, height: 18, padding: 0 }} />
                            </IconButton>
                          </TableCell>
                          {tradeAccountsLength > 1 && (
                            <TableCell component="th" scope="row" align="center">
                              {row.tradeAccountId}
                            </TableCell>
                          )}
                          <TableCell component="th" scope="row" align="center">
                            {formatDateWrapper(new Date(row.date).getTime(), 'long')}
                          </TableCell>
                          <TableCell align="center">{row.volume}</TableCell>
                          <TableCell align="center">
                            {row.period} {row.period === 1 ? 'month' : 'months'}
                          </TableCell>
                          <TableCell align="center">{row.status}</TableCell>

                          <TableCell align="right">
                            <Button
                              className={classes.closeButton}
                              variant="outlined"
                              endIcon={<CloseIcon style={{ fontSize: 14 }} />}
                              onClick={() => handlePopUpOpen(row.id)}
                              disabled={row.status !== 'ACTIVE'}
                            >
                              Stop
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow className={classes.noData}>
                        <TableCell>No Auto Invest History</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
        </Box>
      </Box>
      {!isSeeAll && (
        <Box className={classes.buttonsWrapper}>
          <Box>
            <Box className={classes.btn}>
              <CustomButton
                type="black"
                width="100%"
                height="24px"
                onClick={navigateToAutoInvest}
                buttonText="&#9664;&nbsp;&nbsp;Back to Auto Invest"
              ></CustomButton>
            </Box>
          </Box>
          {autoInvestTransactions.length > 0 && (
            <Box>
              <Box className={classes.paginationRoot}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={allPages}
                  page={page}
                  onChange={(event, newPage) => setPage(newPage)}
                />
              </Box>
            </Box>
          )}
          <Box></Box>
        </Box>
      )}
      {proceedAutoInvestOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedAutoInvestOrderClicked}
          setIsPopupOpen={setProceedAutoInvestOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      <ConfirmPopUp
        open={open}
        setOpen={setOpen}
        orderId={cancelOrderId}
        message={'Please confirm closing'}
        save={handleCancelAutoInvestOrder}
      />
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={loading} />
    </Box>
  );
};

export default AutoInvestHistory;

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: '28px',
    fontWeight: '600',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'var(--font-size-18)',
    [theme.breakpoints.down('1200')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('1150')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('1000')]: {
      fontSize: 'var(--font-size-15)',
    },
    [theme.breakpoints.down('800')]: {
      fontSize: 'var(--font-size-14)',
    },
  },
  tableContainer: {
    fontFamily: theme.typography.fontFamily,
    '& .MuiTableCell-root': {
      fontSize: 'var(--font-size-18)',
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-14)',
      },
      [theme.breakpoints.down('1280')]: {
        fontSize: 'var(--font-size-13)',
      },
      [theme.breakpoints.down('1040')]: {
        fontSize: 'var(--font-size-12)',
      },
      [theme.breakpoints.down('960')]: {
        fontSize: 'var(--font-size-10)',
      },
    },
  },
  noData: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '97%',
    color: 'var(--grey-secondary)',
    '& td': {
      border: 'none',
      color: 'var(--grey-secondary)',
      fontFamily: theme.typography.fontFamily,
      fontSize: 'var(--font-size-16)',
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-14)',
      },
      [theme.breakpoints.down('1280')]: {
        fontSize: 'var(--font-size-13)',
      },
    },
  },
  seeAll: {
    textDecoration: 'none',
    color: 'var(--black)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: '1rem',
    opacity: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 'var(--font-size-14)',
    fontWeight: '400',
    [theme.breakpoints.down('1150')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('1000')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('800')]: {
      fontSize: 'var(--font-size-10)',
    },
  },
  paginationRoot: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    '& .MuiPagination-ul': {
      color: 'white',
      flexWrap: 'nowrap',
      '& li:first-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > button::after': {
          marginLeft: '2px',
          content: "'Prev'",
        },
      },
      '& li:last-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > button::before': {
          marginRight: '2px',
          content: "'Next'",
        },
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'var(--black)',
      color: 'var(--white)',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  closeButton: {
    color: 'var(--black)',
    borderRadius: '24px',
    border: '1px solid var(--red)',
    width: '70%',
    maxWidth: '5rem',
    minHeight: '15px',
    height: '1.7rem',
    backgroundColor: 'var(--white)',
    fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
  },
  btn: {
    textAlign: 'left',
    width: '14rem',
    [theme.breakpoints.down('1750')]: {
      width: '13rem',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
}));
