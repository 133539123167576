function dateFormatter(timestamp, format) {
  if (typeof timestamp !== "number" || isNaN(timestamp)) {
    return;
  }

  const dateObj = new Date(timestamp);

  if (isNaN(dateObj.getTime())) {
    throw new Error("Invalid date");
  }

  if (format === "short") {
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    return `${day}.${month}.${dateObj.getFullYear()}`;
  } else if (format === "long") {
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    return `${day}.${month}.${dateObj.getFullYear()} ${hours}:${minutes}:${seconds}`;
  } else {
    throw new Error("Invalid format");
  }
}

export function formatDateWrapper(timestamp, format) {
  try {
    const formattedDate = dateFormatter(timestamp, format);
    return formattedDate;
  } catch (error) {
    console.error("Error:", error);
    return "Invalid Date"; // You can customize the error message here
  }
}
