import io from 'socket.io-client';

const socketWrapper = {
  connect,
  disconnect,
  on,
  off,
};

const socketUrl = process.env.REACT_APP_GOLD_PRICE_SOCKET_URL;
const token = 'FR4x16x9r02V1WIGHPtilrMO2XwfSMxUtHrqtMjKKOi6vhcheAWrIJsQ63fVtoYDVlqEw';

function connect(userGroupId, currency) {
  const socket = io(socketUrl, {
    query: {
      token: token,
      userGroupId: userGroupId,
      currency: currency,
    },
    extraHeaders: {
      'Access-Control-Allow-Origin': '*',
    },
  });

  socket.on('connect', () => {
    console.log('Socket.IO connected');
  });

  socket.on('disconnect', () => {
    console.log('Socket.IO disconnected');
  });

  socket.on('error', (error) => {
    console.log('Socket.IO connection error:', error);
  });

  return socket;
}

function disconnect(socket) {
  if (socket) {
    socket.disconnect();
  }
}

function on(socket, event, callback) {
  if (socket) {
    socket.on(event, callback);
  }
}

function off(socket, event, callback) {
  if (socket) {
    socket.off(event, callback);
  }
}

export default socketWrapper;
