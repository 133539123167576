import { fetchWrapper } from "../../utils/fetchWrapper";

const getAllCountries = async () => {
  try {
    const countries = await fetchWrapper.get(`/countries/delivery`);
    const filteredCountries = countries.filter(
      (country) => country.countryCode !== null || country.id !== null
    );
    return filteredCountries;
  } catch (error) {
    throw new Error();
  }
};

export const countryService = {
  getAllCountries,
};
