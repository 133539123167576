import { fetchWrapper } from "../../utils/fetchWrapper";

const getEURtoUSDTransferRate = async () => {
  try {
    return await fetchWrapper.get(`/payments/transfer-rate`);
  } catch (error) {
    throw error;
  }
};

const transferFunds = async (data) => {
  try {
    return await fetchWrapper.post(`/payments/transfer-funds`, data);
  } catch (error) {
    throw error;
  }
};

export const paymentsService = {
  getEURtoUSDTransferRate,
  transferFunds,
};
