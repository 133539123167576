import { fetchWrapper } from "../../utils/fetchWrapper";

const storeEndpoint = "/store";

const getStoreCategories = async () => {
  try {
    const categoriesResp = await fetchWrapper.get(
      `${storeEndpoint}/categories`
    );

    return categoriesResp?.data;
  } catch (error) {
    console.error("Error token:", error);
  }
};

const getStoreProducts = async (body = {}) => {
  try {
    const productsResp = await fetchWrapper.post(
      `${storeEndpoint}/products`,
      body
    );

    return {
      data: productsResp?.data,
      page: productsResp?.page,
      pages: productsResp?.pages,
      maxPrice: productsResp?.maxPrice,
      minPrice: productsResp?.minPrice,
      totalProducts: productsResp?.totalProducts,
    };
  } catch (error) {
    console.error("Error token:", error);
  }
};

const getElementById = async (id) => {
  try {
    const productResp = await fetchWrapper.get(
      `${storeEndpoint}/product/${id}`
    );

    return {
      data: productResp?.data,
    };
  } catch (error) {
    console.error("Error token:", error);
  }
};

const getStoreTopProducts = async (currency) => {
  try {
    const topProducts = await fetchWrapper.get(`${storeEndpoint}/top-products?currency=${currency}`);
    return topProducts;
  } catch (error) {
    console.error("Error token:", error);
  }
};

export const storeService = {
  getElementById,
  getStoreProducts,
  getStoreCategories,
  getStoreTopProducts,
};
