import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import '../../../../styles/global.fonts.css';
import { useAppContext } from '../../../../context/DataContext';
import BullionzLogo from '../../../../assets/images/bullionz-logo-coin.svg';
import BackgroundLayer from '../../../../assets/images/stake-popup-background-layer-mobile.svg';
import { NON_VERIFIED, coinName } from '../../../../constants/globalconstants';
import TextField from '@mui/material/TextField';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { useState } from 'react';
import { CURRENCY_SYMBOL } from '../../../../constants/globalconstants';
import { GoldToMoneyConverter } from '../../../../helpers/goldToMoneyConverter';
import { handleInputKeyUp, handleInputKeyDown } from '../../../../helpers/inputValidator';
import serviceStaking from '../../../../service/staking';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';
import NotificationMessagePopup from '../../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import { messages } from '../../../../constants/popupMessages';
import { isMarketOpen } from '../../../../helpers/isMarketOpen';
import { useEffect } from 'react';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import SpecialOfferIconBlack from '../../../../assets/images/special-offer-icon-black.png';
import { useUserFees } from '../../../common/StakeVaultCard/user-fees';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import { generateCurrencySymbol } from '../../../../helpers/generateCurrencySymbol';
import isVerified from '../../../../helpers/isVerified';

const btnValues = [3, 6, 9, 12]; // months
const orderVolumeValues = [1, 5, 10, 20];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StakePopupMobile = ({ isPopupOpen, setIsPopupOpen }) => {
  const classes = useStyles();
  const [stakingQuantity, setStakingQuantity] = useState(0);
  const [stakingPeriod, setStakingPeriod] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stakingTransactionsHistory, setStakingTransactionsHistory] = useState([]);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const { stakeFee, stakeFeeForGroup } = useUserFees();

  const {
    goldBidPrice,
    goldBidPriceForGroup,
    proceedStakeOrderClicked,
    setProceedStakeOrderClicked,
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
    setNonVerifiedPopUpOpen,
  } = useAppContext();

  const isDemoMode = sessionStorage.getItem('isDemo') === 'true';

  // const totalStake = GoldToMoneyConverter(stakingQuantity, goldBidPrice);

  const showSpecialProfitField = goldBidPrice !== goldBidPriceForGroup;

  const handleClose = () => {
    setIsPopupOpen(false);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    var intValue = parseInt(value); // parsing for preventing entering 0 at the front
    if (isNaN(intValue)) {
      intValue = 0;
    }
    setStakingQuantity(intValue);
  };
  const handleChosenMonths = (months) => {
    setStakingPeriod(months);
  };
  const handleStakeGoldButton = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const stakeData = {
        volume: stakingQuantity,
        stakePeriodMonths: stakingPeriod,
      };
      setLoading(true);
      try {
        await serviceStaking.createStake(stakeData);
        setPopUpMessage(messages.orderSuccessful);
        setLoading(false);
        setProceedStakeOrderClicked(true);
        setNotificationPopupType('success');
        setStakingQuantity(0);
        setStakingPeriod(0);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setPopUpMessage(customError?.message);
        setProceedStakeOrderClicked(true);
        setNotificationPopupType('error');
        setStakingQuantity(0);
        setStakingPeriod(0);
      } finally {
        setLoading(false);
      }
    } else {
      setPopUpMessage(messages.marketClosed);
      setProceedStakeOrderClicked(true);
      setNotificationPopupType('error');
      setStakingQuantity(0);
      setStakingPeriod(0);
    }
  };

  const isStakeGoldButtonDisabled = () => {
    return (
      stakingQuantity === '' ||
      stakingQuantity === '0' ||
      stakingQuantity === 0 ||
      isNaN(stakingQuantity) ||
      stakingPeriod === 0
    );
  };

  const handleInputFocus = () => {
    stakingQuantity === 0 && setStakingQuantity('');
  };
  const handleChosenQuantity = (value) => {
    setStakingQuantity(value);
  };

  let expectedRewardPerCoin = (stakeFee * goldBidPrice).toFixed(2);
  const expectedRewardForGroupPerCoin = (stakeFeeForGroup * goldBidPriceForGroup).toFixed(2);

  if (isDemoMode) {
    expectedRewardPerCoin = (0.01 * goldBidPrice).toFixed(2);
  }

  const expectedReward = expectedRewardPerCoin * stakingQuantity * stakingPeriod || 0; // standard

  const expectedRewardForGroup = expectedRewardForGroupPerCoin * stakingQuantity * stakingPeriod || 0; // special

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.popupTitle}>
            Yield
          </BootstrapDialogTitle>
          <DialogContent>
            <div className={classes.buttonContainer}>
              {btnValues.map((value) => (
                <div key={value} className={classes.button}>
                  <CustomButton
                    type={stakingPeriod === value ? 'monthBtnWhite' : 'monthBtnDarkGreen'}
                    buttonText={value + ' M'}
                    width="100%"
                    height="100%"
                    onClick={() => handleChosenMonths(value)}
                  />
                </div>
              ))}
            </div>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipButton}>
                <CustomButton type="moreinfo" buttonText="i" tooltipTitle={tooltipContent.staking} />
              </div>
            </div>
            <Box>
              <div className={classes.goldFieldContainer}>
                <div className={classes.bullionzGold}>
                  <div className={classes.logoContainer}>
                    <img src={BullionzLogo} alt="Bullionz logo" />
                  </div>
                  <div>
                    <Box className={classes.title}>Bullionz Gold</Box>
                    <Box className={classes.coin}>{coinName}</Box>
                  </div>
                </div>
                <div className={classes.input}>
                  <TextField
                    className={classes.customInput}
                    onFocus={handleInputFocus}
                    autoComplete="off"
                    id="stake-input"
                    value={stakingQuantity}
                    size="small"
                    inputProps={{
                      maxLength: 5,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onKeyUp={handleInputKeyUp}
                    onKeyDown={handleInputKeyDown}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <Box className={classes.gr}>gr</Box>
              </div>
            </Box>
            <div className={classes.buttonContainer}>
              {orderVolumeValues.map((value) => (
                <div key={value} className={classes.button}>
                  <CustomButton
                    type={stakingQuantity === value ? 'monthBtnWhite' : 'monthBtnDarkGreen'}
                    buttonText={value + ' gr'}
                    width="100%"
                    height="100%"
                    onClick={() => handleChosenQuantity(value)}
                  />
                </div>
              ))}
            </div>
            <div className={classes.container}>
              <div
                style={{
                  fontSize: !showSpecialProfitField && '15px',
                  fontWeight: !showSpecialProfitField && '700',
                }}
                className={classes.totalValueContainer}
              >
                Estimated Profit
                <div>{generateCurrencySymbol() + expectedReward.toFixed(2)}</div>
              </div>
              {showSpecialProfitField && (
                <div
                  style={{
                    fontSize: '15px',
                    fontWeight: '700',
                    marginTop: '2%',
                    position: 'relative',
                  }}
                  className={classes.totalValueContainer}
                >
                  <img src={SpecialOfferIconBlack} alt="Special Offer" />
                  Your Profit
                  <div>{generateCurrencySymbol() + expectedRewardForGroup.toFixed(2)} </div>
                  <div className={classes.yourProfitTooltip}>
                    <CustomButton type="moreinfo" buttonText="i" tooltipTitle={tooltipContent.yieldingYourProfit} />
                  </div>
                </div>
              )}
            </div>
            <div className={classes.helperContainer}>
              <div className={classes.stakeGoldButton}>
                <CustomButton
                  type="lightGreen"
                  disabled={isStakeGoldButtonDisabled()}
                  buttonText={'YIELD GOLD'}
                  width="100%"
                  height="100%"
                  onClick={handleStakeGoldButton}
                />
              </div>
            </div>
          </DialogContent>
        </div>
      </BootstrapDialog>
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={loading} />
      {proceedStakeOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedStakeOrderClicked}
          setIsPopupOpen={setProceedStakeOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={handleClose}
        />
      )}
    </div>
  );
};

export default StakePopupMobile;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      maxWidth: '1000px',
      width: '100%',
      minWidth: '300px',
    },
  },
  popupContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundImage: `url(${BackgroundLayer})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    overflow: 'hidden',
  },
  tooltipContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '20px',
  },
  tooltipButton: {
    position: 'relative',
    left: '7px',
    bottom: '15px',
  },
  popupTitle: {
    fontSize: 'var(--font-size-22)',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  goldFieldContainer: {
    display: 'flex',
    position: 'relative',
    top: '4px',
    minHeight: '35px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0.5px solid #D2D5D8',
    borderRadius: '3px',
    marginBottom: '7%',
    backgroundColor: 'white',
    '@media (orientation: landscape)': {
      marginBottom: '2%',
    },
  },
  bullionzGold: {
    display: 'flex',
    flex: '3',
    alignItems: 'center',
    paddingLeft: '8px',
    gap: '10px',
    color: 'black',
    fontFamily: theme.typography.fontFamily,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: '400',
    fontSize: 'var(--font-size-8)',
  },
  coin: {
    fontSize: 'var(--font-size-10)',
    fontWeight: '400',
  },
  input: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    position: 'relative',
    marginLeft: 'auto',
    width: '16%',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#64AD94',
    padding: '1.5px',
  },
  customInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input': {
      fontSize: 'var(--font-size-11)',
      fontWeight: '600',
      textAlign: 'right',
      color: 'white',
      paddingRight: '3px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
    '& .Mui-error': {
      paddingLeft: '42px',
    },
  },
  gr: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'var(--font-size-11)',
    fontWeight: '400',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    color: '#347362',
    width: '20px',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#b2cfc5',
  },
  buttonContainer: {
    position: 'relative',
    bottom: '9px',
    display: 'flex',
    justifyContent: 'center',
    gap: '4%',
    [theme.breakpoints.up('380')]: {
      bottom: '-2px',
    },
  },
  button: {
    width: '60px',
    height: '25px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-10)',
      fontWeight: '600',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    margin: 'auto',
  },
  totalValueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'var(--font-size-12)',
    fontWeight: '400',
    marginTop: '8%',
    gap: '5px',
    '@media (orientation: landscape)': {
      marginTop: '4%',
    },
  },
  yourProfitTooltip: {
    position: 'relative',
    left: '30px',
    bottom: '20px',
  },
  helperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stakeGoldButton: {
    width: '245px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: '12px',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-14) !important',
      fontWeight: '600',
    },
    '@media (orientation: landscape)': {
      marginBottom: '0px',
    },
  },
}));
