import db from '../db-demo/db.json';

export function demoResponseHandler(url, data) {
  // console.log(url);
  switch (url) {
    case '/user':
      return db.userRes;
    case '/user/total-monthly-staking':
      return db.userTotalMonthlyStaking;
    case '/user/portfolio':
      return db.userPortfolio;
    case '/gold-price/history?period=1H':
      return db.goldPriceHistoryForPeriod1H;
    case '/gold-price/history?period=1D':
      return db.goldPriceHistoryForPeriod1D;
    case '/gold-price/history?period=3D':
      return db.goldPriceHistoryForPeriod3D;
    case '/gold-price/history?period=1W':
      return db.goldPriceHistoryForPeriod1W;
    case '/gold-price/history?period=1M':
      return db.goldPriceHistoryForPeriod1M;
    case '/user/crm/transactions':
      return db.userCrmTransactions;
    case '/redeem-transaction?page=1&pageSize=20':
      return db.redeemTransactionMobile;
    case '/redeem-transaction':
      return db.redeemTransaction;
    case '/notification/widgets':
      return db.notificationWidgets;
    case '/order-transaction/stake':
      return db.stakeTransactions;
    case '/order-transaction/stake?page=1&pageSize=20':
      return db.stakeTransactionsMobile;
    case '/fee/vault-fee':
      return db.feeVaultFee;
    case '/order-transaction/vault':
      return db.orderTransactionVault;
    case '/order-transaction/vault?page=1&pageSize=20':
      return db.orderTransactionVaultMobile;
    case '/order-transaction/certificates':
      return db.orderTransactionCertificates;
    case '/order-transaction/certificates?page=1&pageSize=20':
      return db.orderTransactionCertificatesMobile;
    case '/autoinvest-transaction/active':
      return db.activeAutoInvestTransaction;
    case '/autoinvest-transaction/':
      return db.autoInvestTransaction;
    case '/autoinvest-transaction?page=1&pageSize=20':
      return db.autoInvestTransactionMobile;
    case '/autoinvest-transaction/upcoming-coins':
      return db.upcomingCoinsAutoInvestTransaction;
    case '/redeem-transaction/gold-variants':
      return db.redeemTransactionGoldVariants;
    case '/redeem-transaction/delivery-fee':
      return db.redeemTransactionDeliveryFee;
    case '/countries':
      return db.redeemCountries;
    case '/user/notifications?page=1&pageSize=10':
      return db.userNotifications;
    case '/user/notifications?page=1&pageSize=20':
      return db.userNotifications;
    case '/fee/stake-fee':
      return db.stakeFee;
    case '/user/shopping-cart':
      return db.shoppingCart;
    case '/redeem-transaction/fees':
      return db.redeemTransactionFees;
    case '/payments/transfer-rate':
      return db.transferRate;
    case '/broker/get-orders?brokerAccountId=6666e8c48160c0155b75c3ae':
      return db.orderTransaction;
    case '/get-markets':
      return db.markets;
    case '/broker/user-data':
      return db.brokerUserData;
    case '/watchlist':
      return db.watchList;
    case `/broker/get-market-history?marketSymbol=${data}`:
      return db.marketHistory;
    default:
      return;
  }
}
