import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.fonts.css";
import { Button } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ErrorPopup = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        className={classes.popupContainer}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.popupTitle}
        >
          DELETE YOUR ACCOUNT
        </BootstrapDialogTitle>
        <DialogContent>
          <Box className={classes.popupContent}>
            <div className={classes.subtitle}>
              <p>
                Dear Valued Client,
                <br />
                <br />
                In order to delete the account from our system, you should close
                your positions and withdraw the remaining funds. Once these two
                conditions are fulfilled, we can proceed with the closure of the
                account.
                <br />
                <br />
                If you have additional inquiries, please do not hesitate to
                contact us at{" "}
                <a
                  style={{
                    color: "#347362",
                  }}
                  href="mailto:support@bullionz.com"
                >
                  support@bullionz.com
                </a>
              </p>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                sx={{
                  color: "white !important",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                variant="contained"
                onClick={handleClose}
              >
                Ok
              </Button>
            </div>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ErrorPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "670px",
      maxHeight: "480px",
      height: "100%",
      width: "100%",
      minWidth: "200px",
      [theme.breakpoints.down("768")]: {
        maxHeight: "486px",
        width: "76%",
      },
    },
    fontFamily: theme.typography.fontFamily,
  },
  popupTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: "600",
    justifyContent: "left",
    [theme.breakpoints.down("768")]: {
      fontSize: "19px",
    },
  },
  popupContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  subtitle: {
    display: "flex",
    width: "100%",
    fontSize: "18px",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "30px",
    [theme.breakpoints.down("480")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("420")]: {
      fontSize: "15px",
    },
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    color: "black",
    border: "2px solid black",
    borderRadius: "60px",
    fontSize: "18px",
    fontWeight: "600",
    minWidth: "150px",
    maxHeight: "40px",
    fontFamily: theme.typography.fontFamily,
    boxShadow: "0px 3.1852872371673584px 0px 0px #76767633",
    [theme.breakpoints.down("768")]: {
      width: "85%",
    },
    "&:hover": {
      border: "1px solid black",
    },
  },
}));
