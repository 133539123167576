import * as React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import FacebookIcon from '../../../assets/images/facebook-icon.svg';
import TwitterIcon from '../../../assets/images/twitter-icon.svg';
import InstagramIcon from '../../../assets/images/instagram-icon.svg';
import LinkedInIcon from '../../../assets/images/linkedIn-icon.svg';
import YouTubeIcon from '../../../assets/images/youtube-icon.svg';
import GooglePlayBadge from '../../../assets/images/google-play-badge.png';
import AppStoreBadge from '../../../assets/images/app_store_badge.svg';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';

const Footer = React.memo(() => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  const handleAppStoreClicked = () => {
    window.open('https://apps.apple.com/il/app/bullionz/id6470910631', '_blank');
  };

  const handleGooglePlayClicked = () => {
    window.open('https://play.google.com/store/apps/details?id=com.agbullionltd.bullionz', '_blank');
  };

  return (
    <Box className={classes.footer}>
      <div className={classes.copyright}>
        <p>Copyright {currentYear} © Bullionz. All rights reserved</p>
      </div>
      <div className={classes.socialMedia}>
        <Button href="https://www.facebook.com/BullionZGold" target="_blank" className={classes.button}>
          <img src={FacebookIcon} alt="Facebook icon" />
        </Button>
        <Button
          href="https://twitter.com/i/flow/login?redirect_after_login=%2Fbullionzgold"
          target="_blank"
          className={classes.button}
        >
          <img src={TwitterIcon} alt="Twitter icon" />
        </Button>
        <Button
          href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fbullionzgold%2F"
          target="_blank"
          className={classes.button}
        >
          <img src={InstagramIcon} alt="Instagram icon" />
        </Button>
        <Button href="https://www.linkedin.com/company/bullionzgold" target="_blank" className={classes.button}>
          <img src={LinkedInIcon} alt="LinkedIn icon" />
        </Button>
        <Button
          href="https://www.youtube.com/channel/UCTlZrhPYcisNtnx8UOoE0gQ"
          target="_blank"
          className={classes.button}
        >
          <img src={YouTubeIcon} alt="YouTube icon" />
        </Button>
      </div>
      <div className={classes.badgesContainer}>
        <img
          className={classes.appStoreBadge}
          src={AppStoreBadge}
          alt="App Store Badge"
          onClick={handleAppStoreClicked}
        />
        <img
          className={classes.googlePlayBadge}
          src={GooglePlayBadge}
          alt="Google Play Badge"
          onClick={handleGooglePlayClicked}
        />
      </div>
    </Box>
  );
});

Footer.displayName = 'Footer';

export default Footer;

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    width: '100%',
    height: '65px',
    backgroundColor: 'var(--grey-light)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  copyright: {
    position: 'absolute',
    fontSize: 'var(--font-size-16)',
    left: '10px',
    fontFamily: theme.typography.fontFamily,
    color: '#ACACAC',
    [theme.breakpoints.down('1800')]: {
      fontSize: 'var(--font-size-14)',
    },
    [theme.breakpoints.down('1300')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('770')]: {
      fontSize: 'var(--font-size-10)',
    },
  },
  socialMedia: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '27px',
      height: '27px',
      [theme.breakpoints.down('1800')]: {
        width: '25px',
        height: '25px',
      },
      [theme.breakpoints.down('1300')]: {
        width: '23px',
        height: '23px',
      },
    },
  },
  button: {
    minWidth: '50px',
    [theme.breakpoints.down('1800')]: {
      minWidth: '45px',
    },
    [theme.breakpoints.down('1300')]: {
      minWidth: '40px',
    },
  },
  badgesContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: '10px',
  },
  appStoreBadge: {
    height: '40px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  googlePlayBadge: {
    height: '40px',
    cursor: 'pointer',
  },
}));
