import clsx from 'clsx';
import React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/material';
import { CURRENCY_TYPE } from '../../../../constants/globalconstants';
import currencyFormatter from '../../../../helpers/currencyFormatter/currencyFormatter';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import BuyArrows from '../../../../assets/images/TransactionsSell.svg';
import SellArrows from '../../../../assets/images/sellArrows.svg';
import RedeemIcon from '../../../../assets/images/redeem-icon.svg';
import RedeemStoreIcon from '../../../../assets/images/redeem-store-icon.svg';

import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import { formatDateWrapper } from '../../../../helpers/dateFormatter';
import { useEffect } from 'react';
import { handleGetMobileTransactions } from '../../../../helpers/handleGetTransactions';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import { useAppContext } from '../../../../context/DataContext';
import InvoiceGoldPopup from '../../../common/Popups/InvoiceGoldPopup/InvoiceGoldPopup';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--mobile-grey)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { setIsGoldInvoiceModalOpen, userTradeAccounts } = useAppContext();

  let totalQuantity = 0;
  let storePrice = row?.price;
  const hasRealOrder = row?.order_id;
  const isStoreTransaction = row?.use_fiat === true;

  if (isStoreTransaction && row?.volume) {
    storePrice = row?.service_price + row?.deliver_price;
  }

  const tradeAccountsLength = userTradeAccounts?.length;

  if (row?.products) {
    totalQuantity = row?.products?.reduce((total, product) => total + product.quantity, 0);
  }

  const formatCurrencySymbol = (currency) => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return currency;
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.tableRow}>
        <StyledTableCell sx={{ width: '10%' }}>
          <IconButton
            disableRipple
            sx={{
              cursor: 'default',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            aria-label="transaction-table-arrow"
            size="small"
          >
            {row.productItemCode === 'P001' ? (
              <img src={BuyArrows} alt="Buy Arrow Icon" />
            ) : row.productItemCode === 'P004' ? (
              <img
                src={isStoreTransaction ? RedeemStoreIcon : RedeemIcon}
                alt="Redeem Icon"
                className={classes.sellArrows}
              />
            ) : (
              <img src={SellArrows} alt="Sell Arrow Icon" className={classes.sellArrows} />
            )}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" sx={{ width: '30%' }}>
          {formatDateWrapper(new Date(row.date).getTime(), 'long')}
        </StyledTableCell>

        <StyledTableCell sx={{ width: '15%' }}>
          {`${isStoreTransaction ? totalQuantity || '-' : row.volume || '-'}${!isStoreTransaction ? 'gr' : ''}`}
        </StyledTableCell>

        <StyledTableCell align="center" sx={{ width: '30%' }}>
          {row.productItemCode === 'P001' &&
            `${formatCurrencySymbol(row.currency)}${(row.price * row.volume).toFixed(2)}`}
          {row.productItemCode === 'P005' &&
            `${formatCurrencySymbol(row.currency)}${(row.price * row.volume).toFixed(2)}`}
          {row.productItemCode === 'P004' && `${formatCurrencySymbol(row.currency)}${row.price.toFixed(2)}`}
        </StyledTableCell>

        <StyledTableCell sx={{ width: '10%' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon style={{ color: '#347362' }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: '#347362' }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <StyledTableRow className={classes.tableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell className={classes.firstStyledTableCell}>Transaction</StyledTableCell>
                  {tradeAccountsLength > 1 && <StyledTableCell align="center">Account</StyledTableCell>}
                  <StyledTableCell align="center">
                    &nbsp; &nbsp; &nbsp; &nbsp; Status &nbsp; &nbsp; &nbsp; &nbsp;
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={clsx(
                      isStoreTransaction && hasRealOrder && classes.storeCell,
                      classes.firstStyledTableCell,
                    )}
                    align="left"
                    onClick={() => {
                      if (isStoreTransaction && hasRealOrder) {
                        setIsGoldInvoiceModalOpen(row);
                      }
                    }}
                  >
                    {row.id}
                  </StyledTableCell>
                  {tradeAccountsLength > 1 && <StyledTableCell align="center">{row.tradeAccountId}</StyledTableCell>}
                  <StyledTableCell align="center">{row.status}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function MobileTransactionsHistoryTable() {
  const classes = useStyles();
  const tableEl = useRef();
  const [loading, setLoading] = useState(true);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [allPages, setAllPages] = useState(1);
  const [page, setPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(page <= allPages);
  const [error, setError] = useState(false);

  useEffect(() => {
    handleGetMobileTransactions(setTransactions, page, 20, setAllPages, setError, setLoading);
  }, [page]);

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          if (page < allPages) {
            setPage((prev) => prev + 1);
          }
          setLoading(false);
          setHasMore(page < allPages);
          resolve();
        }, 1500),
      );
    };
    setLoading(true);
    if (page === allPages) {
      setLoading(false);
      return;
    }
    loadItems();
  }, [transactions, page]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener('scroll', scrollListener);
    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  return (
    <Box className={classes.container}>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ margin: 'auto', maxHeight: '100%' }}
        ref={tableEl}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead className={classes.tableHead}>
            <StyledTableRow className={classes.tableRow}>
              <StyledTableCell component="th" className={classes.headerCell} sx={{ width: '10%' }}></StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell} sx={{ width: '30%' }}>
                Date
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Qty
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell} sx={{ width: '30%' }} align="center">
                Price
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                <CustomButton type="moreinfo" buttonText="i" tooltipTitle={tooltipContent.transactionsTable} />
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody className={classes.containerTableBody}>
            {!transactions.length && !loading ? (
              <React.Fragment>
                <TableRow>
                  <TableCell colSpan={5} style={{ whiteSpace: 'nowrap' }} align="center">
                    No recent transactions
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ) : page === allPages && !loading ? (
              <React.Fragment>
                {transactions.map((row) => (
                  <Row key={row.id} row={row} />
                ))}
                {[1, 2, 3].map((value, subIndex) => (
                  <TableRow key={`sub-row-${value}-${subIndex}`}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ))}
              </React.Fragment>
            ) : (
              transactions.map((row) => <Row key={row.id} row={row} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InvoiceGoldPopup />
      <LoadingBar open={loading} />
    </Box>
  );
}

export default MobileTransactionsHistoryTable;

const useStyles = makeStyles((theme) => ({
  containerTableBody: {
    position: 'relative',
    overflowY: 'auto',
  },
  headerCell: {
    backgroundColor: 'var(--mobile-grey)',
    zIndex: 2,
    height: '36px',
  },
  container: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      fontSize: 'clamp(var(--font-size-11), var(--font-size-12), var(--font-size-13)) !important',
    },
    height: '100%',
    '@media (orientation: landscape)': {
      maxHeight: '100%',
    },
  },
  tableHead: {
    position: 'relative',
  },
  tableRow: {
    '& .MuiTableCell-root': {
      padding: '0px',
      maxHeight: '30px',
    },
    '& th': {
      fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
    },
  },
  firstStyledTableCell: {
    textAlign: 'center',
    [theme.breakpoints.up('500')]: {
      textAlign: 'center',
    },
  },
  closeButton: {
    color: 'var(--black)',
    borderRadius: '24px',
    border: '1px solid var(--red)',
    width: '70%',
    maxWidth: '5rem',
    minHeight: '15px',
    height: '1.7rem',
    marginBottom: '1.5rem',
    backgroundColor: 'var(--white)',
    fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
  },
  actionButtons: {
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    width: '100%',
    top: '65%',
  },
  sellArrows: {
    maxWidth: '0.9rem',
    maxHeight: '0.9rem',
  },
  storeCell: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
