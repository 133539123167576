import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MobileGoldHistory from "../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import MobileTransactionsHistoryTable from "../../../components/MobileComponents/MobileTranscationsHistory/TransactionsHistoryTable/MobileTransactionsHistoryTable";
import { routes } from "../../../routes";
import AppLayout from "../../../components/common/Layout/AppLayout";
import AppActionButtons from "../../../components/AppComponents/AppTradeGoldRedeemGoldButtons.js/AppActionButtons";
import CryptoTransactionsHistoryTable from "../../../components/MobileComponents/CryptoTransactionsHistoryTable/CryptoTransactionsHistoryTable";

function AppCryptoTransactionHistory() {
  const classes = useStyles();
  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileNavigationBack page={"Crypto Transactions"} route={routes.dashboard} />

        <Box className={classes.table}>
          <CryptoTransactionsHistoryTable />
        </Box>
      </Box>
    </AppLayout>
  );
}

export default AppCryptoTransactionHistory;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    position: "relative",
    height: "100%",
    overflow: "hidden",
    overflowY: "auto",
  },
  table: {
  height: "calc(100dvh - 152px)"
  },
  actionButtons: {
    position: "absolute",
    textAlign: "center",
    display: "none",
    justifyContent: "center",
    left: 0,
    width: "100%",
    bottom: "0%",
    background: "var(--linear-gradient-white)",
  },
}));
