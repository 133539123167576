export function getLastWord(inputString) {
  if (!inputString.length) {
    return "";
  }

  let substrings = inputString.split("|");
  let lastSubstring = substrings[substrings.length - 1];
  let lastWord = lastSubstring.trim();

  return lastWord;
}
