import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import CustomButton from "../../../common/CustomButton/CustomButton";
import clsx from "clsx";
import BuySellPopupMobile from "../../popups/BuySellPopup/BuySellPopupMobile";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";
import { useAppContext } from "../../../../context/DataContext";

function TradeGoldAutoInvestButtons() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isBuySellPopupOpen, setIsBuySellPopupOpen } = useAppContext();

  const handleTradeGoldClicked = () => {
    setIsBuySellPopupOpen(true);
    let element = document.getElementById("redeem-gold-icon");
    if (element && element.classList.contains("except")) {
      element.classList.remove("except");
    }
  };

  function navigateToAutoInvest() {
    navigate(routes.autoInvest);
  }

  return (
    <Box className={classes.container}>
      <Box
        className={clsx(classes.buttonContainer, classes.goldButton)}
        id="trade-gold-btn"
      >
        <CustomButton
          type="green"
          onClick={handleTradeGoldClicked}
          buttonText="TRADE GOLD"
          width="90%"
          id="trade-gold-btn-trigger"
          ignoreTutorial
        ></CustomButton>
      </Box>
      <Box className={classes.buttonContainer} id="autoinvest-btn">
        <CustomButton
          type="green"
          onClick={navigateToAutoInvest}
          buttonText="AUTO INVEST"
          width="90%"
          ignoreTutorial
        ></CustomButton>
      </Box>
      {isBuySellPopupOpen && (
        <BuySellPopupMobile
          isPopupOpen={isBuySellPopupOpen}
          setIsPopupOpen={setIsBuySellPopupOpen}
        />
      )}
    </Box>
  );
}

export default TradeGoldAutoInvestButtons;

const useStyles = makeStyles(() => ({
  container: {
    flex: "1 1 auto",
  },
  buttonContainer: {
    marginTop: "0.5rem",
    width: "17rem",
    height: "2.5rem",
    textAlign: "center",
    marginBottom: "0.6rem",
    "& button": {
      marginTop: "0px !important",
      fontWeight: "bold !important",
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  goldButton: {
    "& button": {
      backgroundColor: "#DDB97C !important",
    },
  },
}));
