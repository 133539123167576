export const testMarkets = [
  {
    marketId: 'ada_usdt',
    displayName: 'ADA/USDT',
  },
  {
    marketId: 'adx_usdt',
    displayName: 'ADX/USDT',
  },
  {
    marketId: 'agld_usdt',
    displayName: 'AGLD/USDT',
  },
  {
    marketId: 'akro_usdt',
    displayName: 'AKRO/USDT',
  },
  {
    marketId: 'alcx_usdt',
    displayName: 'ALCX/USDT',
  },
  {
    marketId: 'algo_usdt',
    displayName: 'ALGO/USDT',
  },
  {
    marketId: 'alice_usdt',
    displayName: 'ALICE/USDT',
  },
  {
    marketId: 'alpaca_usdt',
    displayName: 'ALPACA/USDT',
  },
  {
    marketId: 'alpha_usdt',
    displayName: 'ALPHA/USDT',
  },
  {
    marketId: 'alpine_usdt',
    displayName: 'ALPINE/USDT',
  },
  {
    marketId: 'amp_usdt',
    displayName: 'AMP/USDT',
  },
  {
    marketId: 'ankr_usdt',
    displayName: 'ANKR/USDT',
  },
  {
    marketId: 'ape_usdt',
    displayName: 'APE/USDT',
  },
  {
    marketId: 'api3_usdt',
    displayName: 'API3/USDT',
  },
  {
    marketId: 'apt_usdt',
    displayName: 'APT/USDT',
  },
  {
    marketId: 'ar_usdt',
    displayName: 'AR/USDT',
  },
  {
    marketId: 'arb_usdt',
    displayName: 'ARB/USDT',
  },
  {
    marketId: 'ardr_usdt',
    displayName: 'ARDR/USDT',
  },
  {
    marketId: 'arpa_usdt',
    displayName: 'ARPA/USDT',
  },
  {
    marketId: 'asr_usdt',
    displayName: 'ASR/USDT',
  },
  {
    marketId: 'astr_usdt',
    displayName: 'ASTR/USDT',
  },
  {
    marketId: 'ata_usdt',
    displayName: 'ATA/USDT',
  },
  {
    marketId: 'atm_usdt',
    displayName: 'ATM/USDT',
  },
  {
    marketId: 'atom_usdt',
    displayName: 'ATOM/USDT',
  },
  {
    marketId: 'auction_usdt',
    displayName: 'AUCTION/USDT',
  },
  {
    marketId: 'audio_usdt',
    displayName: 'AUDIO/USDT',
  },
  {
    marketId: 'ava_usdt',
    displayName: 'AVA/USDT',
  },
  {
    marketId: 'avax_usdt',
    displayName: 'AVAX/USDT',
  },
  {
    marketId: 'axs_usdt',
    displayName: 'AXS/USDT',
  },
  {
    marketId: 'badger_usdt',
    displayName: 'BADGER/USDT',
  },
  {
    marketId: 'bake_usdt',
    displayName: 'BAKE/USDT',
  },
  {
    marketId: 'bal_usdt',
    displayName: 'BAL/USDT',
  },
  {
    marketId: 'band_usdt',
    displayName: 'BAND/USDT',
  },
  {
    marketId: 'bar_usdt',
    displayName: 'BAR/USDT',
  },
  {
    marketId: 'bch_usdt',
    displayName: 'BCH/USDT',
  },
  {
    marketId: 'bel_usdt',
    displayName: 'BEL/USDT',
  },
  {
    marketId: 'beta_usdt',
    displayName: 'BETA/USDT',
  },
  {
    marketId: 'bico_usdt',
    displayName: 'BICO/USDT',
  },
  {
    marketId: 'bifi_usdt',
    displayName: 'BIFI/USDT',
  },
  {
    marketId: 'blz_usdt',
    displayName: 'BLZ/USDT',
  },
  {
    marketId: 'bnb_usdt',
    displayName: 'BNB/USDT',
  },
  {
    marketId: 'bnt_usdt',
    displayName: 'BNT/USDT',
  },
  {
    marketId: 'bnx_usdt',
    displayName: 'BNX/USDT',
  },
  {
    marketId: 'bome_usdt',
    displayName: 'BOME/USDT',
  },
  {
    marketId: 'bsw_usdt',
    displayName: 'BSW/USDT',
  },
  {
    marketId: 'btc_usdt',
    displayName: 'BTC/USDT',
  },
  {
    marketId: 'burger_usdt',
    displayName: 'BURGER/USDT',
  },
  {
    marketId: 'c98_usdt',
    displayName: 'C98/USDT',
  },
  {
    marketId: 'cake_usdt',
    displayName: 'CAKE/USDT',
  },
  {
    marketId: 'celo_usdt',
    displayName: 'CELO/USDT',
  },
  {
    marketId: 'celr_usdt',
    displayName: 'CELR/USDT',
  },
  {
    marketId: 'cfx_usdt',
    displayName: 'CFX/USDT',
  },
  {
    marketId: 'chess_usdt',
    displayName: 'CHESS/USDT',
  },
  {
    marketId: 'chr_usdt',
    displayName: 'chr_usdt',
  },
  {
    marketId: 'chz_usdt',
    displayName: 'CHZ/USDT',
  },
  {
    marketId: 'city_usdt',
    displayName: 'CITY/USDT',
  },
  {
    marketId: 'ckb_usdt',
    displayName: 'CKB/USDT',
  },
  {
    marketId: 'clv_usdt',
    displayName: 'CLV/USDT',
  },
  {
    marketId: 'comp_usdt',
    displayName: 'COMP/USDT',
  },
  {
    marketId: 'cos_usdt',
    displayName: 'COS/USDT',
  },
  {
    marketId: 'coti_usdt',
    displayName: 'COTI/USDT',
  },
  {
    marketId: 'crv_usdt',
    displayName: 'CRV/USDT',
  },
  {
    marketId: 'ctk_usdt',
    displayName: 'CTK/USDT',
  },
  {
    marketId: 'ctsi_usdt',
    displayName: 'CTSI/USDT',
  },
  {
    marketId: 'ctxc_usdt',
    displayName: 'CTXC/USDT',
  },
  {
    marketId: 'cvc_usdt',
    displayName: 'CVC/USDT',
  },
  {
    marketId: 'cvp_usdt',
    displayName: 'CVP/USDT',
  },
  {
    marketId: 'cvx_usdt',
    displayName: 'CVX/USDT',
  },
  {
    marketId: 'dar_usdt',
    displayName: 'DAR/USDT',
  },
  {
    marketId: 'dash_usdt',
    displayName: 'DASH/USDT',
  },
  {
    marketId: 'data_usdt',
    displayName: 'DATA/USDT',
  },
  {
    marketId: 'dcr_usdt',
    displayName: 'DCR/USDT',
  },
  {
    marketId: 'dego_usdt',
    displayName: 'DEGO/USDT',
  },
  {
    marketId: 'dent_usdt',
    displayName: 'DENT/USDT',
  },
  {
    marketId: 'dexe_usdt',
    displayName: 'DEXE/USDT',
  },
  {
    marketId: 'df_usdt',
    displayName: 'DF/USDT',
  },
  {
    marketId: 'dgb_usdt',
    displayName: 'DGB/USDT',
  },
  {
    marketId: 'dia_usdt',
    displayName: 'DIA/USDT',
  },
  {
    marketId: 'dodo_usdt',
    displayName: 'DODO/USDT',
  },
  {
    marketId: 'doge_usdt',
    displayName: 'DOGE/USDT',
  },
  {
    marketId: 'dot_usdt',
    displayName: 'DOT/USDT',
  },
  {
    marketId: 'dusk_usdt',
    displayName: 'DUSK/USDT',
  },
  {
    marketId: 'dydx_usdt',
    displayName: 'DYDX/USDT',
  },
  {
    marketId: 'egld_usdt',
    displayName: 'EGLD/USDT',
  },
  {
    marketId: 'elf_usdt',
    displayName: 'ELF/USDT',
  },
  {
    marketId: 'ena_usdt',
    displayName: 'ENA/USDT',
  },
  {
    marketId: 'enj_usdt',
    displayName: 'ENJ/USDT',
  },
  {
    marketId: 'eos_usdt',
    displayName: 'EOS/USDT',
  },
  {
    marketId: 'epx_usdt',
    displayName: 'EPX/USDT',
  },
  {
    marketId: 'ern_usdt',
    displayName: 'ERN/USDT',
  },
  {
    marketId: 'etc_usdt',
    displayName: 'ETC/USDT',
  },
  {
    marketId: 'eth_usdt',
    displayName: 'ETH/USDT',
  },
  {
    marketId: 'farm_usdt',
    displayName: 'FARM/USDT',
  },
  {
    marketId: 'fet_usdt',
    displayName: 'FET/USDT',
  },
  {
    marketId: 'fida_usdt',
    displayName: 'FIDA/USDT',
  },
  {
    marketId: 'fil_usdt',
    displayName: 'FIL/USDT',
  },
  {
    marketId: 'fio_usdt',
    displayName: 'FIO/USDT',
  },
  {
    marketId: 'firo_usdt',
    displayName: 'FIRO/USDT',
  },
  {
    marketId: 'fis_usdt',
    displayName: 'FIS/USDT',
  },
  {
    marketId: 'flm_usdt',
    displayName: 'FLM/USDT',
  },
  {
    marketId: 'floki_usdt',
    displayName: 'FLOKI/USDT',
  },
  {
    marketId: 'flow_usdt',
    displayName: 'FLOW/USDT',
  },
  {
    marketId: 'flux_usdt',
    displayName: 'FLUX/USDT',
  },
  {
    marketId: 'for_usdt',
    displayName: 'FOR/USDT',
  },
  {
    marketId: 'forth_usdt',
    displayName: 'FORTH/USDT',
  },
  {
    marketId: 'front_usdt',
    displayName: 'FRONT/USDT',
  },
  {
    marketId: 'ftm_usdt',
    displayName: 'FTM/USDT',
  },
  {
    marketId: 'ftt_usdt',
    displayName: 'FTT/USDT',
  },
  {
    marketId: 'fun_usdt',
    displayName: 'FUN/USDT',
  },
  {
    marketId: 'fxs_usdt',
    displayName: 'FXS/USDT',
  },
  {
    marketId: 'gala_usdt',
    displayName: 'GALA/USDT',
  },
  {
    marketId: 'ghst_usdt',
    displayName: 'GHST/USDT',
  },
  {
    marketId: 'glmr_usdt',
    displayName: 'GLMR/USDT',
  },
  {
    marketId: 'gmt_usdt',
    displayName: 'GMT/USDT',
  },
  {
    marketId: 'gmx_usdt',
    displayName: 'GMX/USDT',
  },
  {
    marketId: 'gno_usdt',
    displayName: 'GNO/USDT',
  },
  {
    marketId: 'grt_usdt',
    displayName: 'GRT/USDT',
  },
  {
    marketId: 'gtc_usdt',
    displayName: 'GTC/USDT',
  },
  {
    marketId: 'hard_usdt',
    displayName: 'HARD/USDT',
  },
  {
    marketId: 'hbar_usdt',
    displayName: 'HBAR/USDT',
  },
  {
    marketId: 'high_usdt',
    displayName: 'HIGH/USDT',
  },
  {
    marketId: 'hive_usdt',
    displayName: 'HIVE/USDT',
  },
  {
    marketId: 'hot_usdt',
    displayName: 'HOT/USDT',
  },
  {
    marketId: 'icp_usdt',
    displayName: 'ICP/USDT',
  },
  {
    marketId: 'icx_usdt',
    displayName: 'ICX/USDT',
  },
  {
    marketId: 'idex_usdt',
    displayName: 'IDEX/USDT',
  },
  {
    marketId: 'ilv_usdt',
    displayName: 'ILV/USDT',
  },
  {
    marketId: 'imx_usdt',
    displayName: 'IMX/USDT',
  },
  {
    marketId: 'inj_usdt',
    displayName: 'INJ/USDT',
  },
  {
    marketId: 'iost_usdt',
    displayName: 'IOST/USDT',
  },
  {
    marketId: 'iota_usdt',
    displayName: 'IOTA/USDT',
  },
  {
    marketId: 'iotx_usdt',
    displayName: 'IOTX/USDT',
  },
  {
    marketId: 'iris_usdt',
    displayName: 'IRIS/USDT',
  },
  {
    marketId: 'jasmy_usdt',
    displayName: 'JASMY/USDT',
  },
  {
    marketId: 'joe_usdt',
    displayName: 'JOE/USDT',
  },
  {
    marketId: 'jst_usdt',
    displayName: 'JST/USDT',
  },
  {
    marketId: 'juv_usdt',
    displayName: 'JUV/USDT',
  },
  {
    marketId: 'kava_usdt',
    displayName: 'KAVA/USDT',
  },
  {
    marketId: 'kda_usdt',
    displayName: 'KDA/USDT',
  },
  {
    marketId: 'key_usdt',
    displayName: 'KEY/USDT',
  },
  {
    marketId: 'klay_usdt',
    displayName: 'KLAY/USDT',
  },
  {
    marketId: 'kmd_usdt',
    displayName: 'KMD/USDT',
  },
  {
    marketId: 'knc_usdt',
    displayName: 'KNC/USDT',
  },
  {
    marketId: 'kp3r_usdt',
    displayName: 'KP3R/USDT',
  },
  {
    marketId: 'ksm_usdt',
    displayName: 'KSM/USDT',
  },
  {
    marketId: 'lazio_usdt',
    displayName: 'LAZIO/USDT',
  },
  {
    marketId: 'ldo_usdt',
    displayName: 'LDO/USDT',
  },
  {
    marketId: 'lever_usdt',
    displayName: 'LEVER/USDT',
  },
  {
    marketId: 'lina_usdt',
    displayName: 'LINA/USDT',
  },
  {
    marketId: 'link_usdt',
    displayName: 'LINK/USDT',
  },
  {
    marketId: 'lit_usdt',
    displayName: 'LIT/USDT',
  },
  {
    marketId: 'loka_usdt',
    displayName: 'LOKA/USDT',
  },
  {
    marketId: 'lpt_usdt',
    displayName: 'LPT/USDT',
  },
  {
    marketId: 'lrc_usdt',
    displayName: 'LRC/USDT',
  },
  {
    marketId: 'lsk_usdt',
    displayName: 'LSK/USDT',
  },
  {
    marketId: 'ltc_usdt',
    displayName: 'LTC/USDT',
  },
  {
    marketId: 'lto_usdt',
    displayName: 'LTO/USDT',
  },
  {
    marketId: 'lunc_usdt',
    displayName: 'LUNC/USDT',
  },
  {
    marketId: 'mana_usdt',
    displayName: 'MANA/USDT',
  },
  {
    marketId: 'mask_usdt',
    displayName: 'MASK/USDT',
  },
  {
    marketId: 'matic_usdt',
    displayName: 'MATIC/USDT',
  },
  {
    marketId: 'mbl_usdt',
    displayName: 'MBL/USDT',
  },
  {
    marketId: 'mbox_usdt',
    displayName: 'MBOX/USDT',
  },
  {
    marketId: 'mdt_usdt',
    displayName: 'MDT/USDT',
  },
  {
    marketId: 'mina_usdt',
    displayName: 'MINA/USDT',
  },
  {
    marketId: 'mkr_usdt',
    displayName: 'MKR/USDT',
  },
  {
    marketId: 'mln_usdt',
    displayName: 'MLN/USDT',
  },
  {
    marketId: 'movr_usdt',
    displayName: 'MOVR/USDT',
  },
  {
    marketId: 'mtl_usdt',
    displayName: 'MTL/USDT',
  },
  {
    marketId: 'near_usdt',
    displayName: 'NEAR/USDT',
  },
  {
    marketId: 'neo_usdt',
    displayName: 'NEO/USDT',
  },
  {
    marketId: 'nexo_usdt',
    displayName: 'NEXO/USDT',
  },
  {
    marketId: 'nmr_usdt',
    displayName: 'NMR/USDT',
  },
  {
    marketId: 'nuls_usdt',
    displayName: 'NULS/USDT',
  },
  {
    marketId: 'og_usdt',
    displayName: 'OG/USDT',
  },
  {
    marketId: 'ogn_usdt',
    displayName: 'OGN/USDT',
  },
  {
    marketId: 'om_usdt',
    displayName: 'OM/USDT',
  },
  {
    marketId: 'one_usdt',
    displayName: 'ONE/USDT',
  },
  {
    marketId: 'ong_usdt',
    displayName: 'ONG/USDT',
  },
  {
    marketId: 'ont_usdt',
    displayName: 'ONT/USDT',
  },
  {
    marketId: 'ooki_usdt',
    displayName: 'OOKI/USDT',
  },
  {
    marketId: 'op_usdt',
    displayName: 'OP/USDT',
  },
  {
    marketId: 'orn_usdt',
    displayName: 'ORN/USDT',
  },
  {
    marketId: 'osmo_usdt',
    displayName: 'OSMO/USDT',
  },
  {
    marketId: 'oxt_usdt',
    displayName: 'OXT/USDT',
  },
  {
    marketId: 'paxg_usdt',
    displayName: 'PAXG/USDT',
  },
  {
    marketId: 'people_usdt',
    displayName: 'PEOPLE/USDT',
  },
  {
    marketId: 'pepe_usdt',
    displayName: 'PEPE/USDT',
  },
  {
    marketId: 'perp_usdt',
    displayName: 'PERP/USDT',
  },
  {
    marketId: 'pha_usdt',
    displayName: 'PHA/USDT',
  },
  {
    marketId: 'polyx_usdt',
    displayName: 'POLYX/USDT',
  },
  {
    marketId: 'pond_usdt',
    displayName: 'POND/USDT',
  },
  {
    marketId: 'porto_usdt',
    displayName: 'PORTO/USDT',
  },
  {
    marketId: 'powr_usdt',
    displayName: 'POWR/USDT',
  },
  {
    marketId: 'psg_usdt',
    displayName: 'PSG/USDT',
  },
  {
    marketId: 'pundix_usdt',
    displayName: 'PUNDIX/USDT',
  },
  {
    marketId: 'pyr_usdt',
    displayName: 'PYR/USDT',
  },
  {
    marketId: 'qi_usdt',
    displayName: 'QI/USDT',
  },
  {
    marketId: 'qnt_usdt',
    displayName: 'QNT/USDT',
  },
  {
    marketId: 'qtum_usdt',
    displayName: 'QTUM/USDT',
  },
  {
    marketId: 'quick_usdt',
    displayName: 'QUICK/USDT',
  },
  {
    marketId: 'rad_usdt',
    displayName: 'RAD/USDT',
  },
  {
    marketId: 'rare_usdt',
    displayName: 'RARE/USDT',
  },
  {
    marketId: 'ray_usdt',
    displayName: 'RAY/USDT',
  },
  {
    marketId: 'reef_usdt',
    displayName: 'REEF/USDT',
  },
  {
    marketId: 'ren_usdt',
    displayName: 'REN/USDT',
  },
  {
    marketId: 'req_usdt',
    displayName: 'REQ/USDT',
  },
  {
    marketId: 'rif_usdt',
    displayName: 'RIF/USDT',
  },
  {
    marketId: 'rlc_usdt',
    displayName: 'RLC/USDT',
  },
  {
    marketId: 'rose_usdt',
    displayName: 'ROSE/USDT',
  },
  {
    marketId: 'rsr_usdt',
    displayName: 'RSR/USDT',
  },
  {
    marketId: 'rune_usdt',
    displayName: 'RUNE/USDT',
  },
  {
    marketId: 'rvn_usdt',
    displayName: 'RVN/USDT',
  },
  {
    marketId: 'sand_usdt',
    displayName: 'SAND/USDT',
  },
  {
    marketId: 'santos_usdt',
    displayName: 'SANTOS/USDT',
  },
  {
    marketId: 'sc_usdt',
    displayName: 'SC/USDT',
  },
  {
    marketId: 'scrt_usdt',
    displayName: 'SCRT/USDT',
  },
  {
    marketId: 'sfp_usdt',
    displayName: 'SFP/USDT',
  },
  {
    marketId: 'shib_usdt',
    displayName: 'SHIB/USDT',
  },
  {
    marketId: 'skl_usdt',
    displayName: 'SKL/USDT',
  },
  {
    marketId: 'slp_usdt',
    displayName: 'SLP/USDT',
  },
  {
    marketId: 'snx_usdt',
    displayName: 'SNX/USDT',
  },
  {
    marketId: 'sol_usdt',
    displayName: 'SOL/USDT',
  },
  {
    marketId: 'spell_usdt',
    displayName: 'SPELL/USDT',
  },
  {
    marketId: 'steem_usdt',
    displayName: 'STEEM/USDT',
  },
  {
    marketId: 'stg_usdt',
    displayName: 'STG/USDT',
  },
  {
    marketId: 'stmx_usdt',
    displayName: 'STMX/USDT',
  },
  {
    marketId: 'storj_usdt',
    displayName: 'STORJ/USDT',
  },
  {
    marketId: 'stpt_usdt',
    displayName: 'STPT/USDT',
  },
  {
    marketId: 'stx_usdt',
    displayName: 'STX/USDT',
  },
  {
    marketId: 'sun_usdt',
    displayName: 'SUN/USDT',
  },
  {
    marketId: 'super_usdt',
    displayName: 'SUPER/USDT',
  },
  {
    marketId: 'sushi_usdt',
    displayName: 'SUSHI/USDT',
  },
  {
    marketId: 'sxp_usdt',
    displayName: 'SXP/USDT',
  },
  {
    marketId: 'sys_usdt',
    displayName: 'SYS/USDT',
  },
  {
    marketId: 't_usdt',
    displayName: 'T/USDT',
  },
  {
    marketId: 'tfuel_usdt',
    displayName: 'TFUEL/USDT',
  },
  {
    marketId: 'theta_usdt',
    displayName: 'THETA/USDT',
  },
  {
    marketId: 'tko_usdt',
    displayName: 'TKO/USDT',
  },
  {
    marketId: 'tlm_usdt',
    displayName: 'TLM/USDT',
  },
  {
    marketId: 'trb_usdt',
    displayName: 'TRB/USDT',
  },
  {
    marketId: 'troy_usdt',
    displayName: 'TROY/USDT',
  },
  {
    marketId: 'tru_usdt',
    displayName: 'TRU/USDT',
  },
  {
    marketId: 'trx_usdt',
    displayName: 'TRX/USDT',
  },
  {
    marketId: 'twt_usdt',
    displayName: 'TWT/USDT',
  },
  {
    marketId: 'uma_usdt',
    displayName: 'UMA/USDT',
  },
  {
    marketId: 'unfi_usdt',
    displayName: 'UNFI/USDT',
  },
  {
    marketId: 'uni_usdt',
    displayName: 'UNI/USDT',
  },
  {
    marketId: 'utk_usdt',
    displayName: 'UTK/USDT',
  },
  {
    marketId: 'vet_usdt',
    displayName: 'VET/USDT',
  },
  {
    marketId: 'vite_usdt',
    displayName: 'VITE/USDT',
  },
  {
    marketId: 'voxel_usdt',
    displayName: 'VOXEL/USDT',
  },
  {
    marketId: 'vtho_usdt',
    displayName: 'VTHO/USDT',
  },
  {
    marketId: 'wan_usdt',
    displayName: 'WAN/USDT',
  },
  {
    marketId: 'waxp_usdt',
    displayName: 'WAXP/USDT',
  },
  {
    marketId: 'win_usdt',
    displayName: 'WIN/USDT',
  },
  {
    marketId: 'wing_usdt',
    displayName: 'WING/USDT',
  },
  {
    marketId: 'wld_usdt',
    displayName: 'WLD/USDT',
  },
  {
    marketId: 'woo_usdt',
    displayName: 'WOO/USDT',
  },
  {
    marketId: 'wrx_usdt',
    displayName: 'WRX/USDT',
  },
  {
    marketId: 'xai_usdt',
    displayName: 'XAI/USDT',
  },
  {
    marketId: 'xec_usdt',
    displayName: 'XEC/USDT',
  },
  {
    marketId: 'xlm_usdt',
    displayName: 'XLM/USDT',
  },
  {
    marketId: 'xno_usdt',
    displayName: 'XNO/USDT',
  },
  {
    marketId: 'xrp_usdt',
    displayName: 'XRP/USDT',
  },
  {
    marketId: 'xtz_usdt',
    displayName: 'XTZ/USDT',
  },
  {
    marketId: 'xvg_usdt',
    displayName: 'XVG/USDT',
  },
  {
    marketId: 'yfi_usdt',
    displayName: 'YFI/USDT',
  },
  {
    marketId: 'ygg_usdt',
    displayName: 'YGG/USDT',
  },
  {
    marketId: 'zec_usdt',
    displayName: 'ZEC/USDT',
  },
  {
    marketId: 'zen_usdt',
    displayName: 'ZEN/USDT',
  },
  {
    marketId: 'zil_usdt',
    displayName: 'ZIL/USDT',
  },
  {
    marketId: 'zrx_usdt',
    displayName: 'ZRX/USDT',
  },
];
