import React from "react";
import { Button } from "@mui/material";
import "../../../styles/global.fonts.css";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { routes } from "../../../routes";
import { useAppContext } from "../../../context/DataContext";

const ErrorPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { fetchGoldTradingPool } = useAppContext();

  const handleClick = () => {
    window.localStorage.setItem("accessToken", 1);
    navigate(routes.dashboard);
    fetchGoldTradingPool();
  };

  return (
    <div className={classes.errorPageContainer}>
      <div className={classes.errorMessage}>
        Something went wrong. Please try again later
      </div>
      <div className={classes.blackButton}>
        <Button
          className={classes.btn}
          variant="contained"
          onClick={handleClick}
        >
          Home Page
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;

const useStyles = makeStyles((theme) => ({
  errorPageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  errorMessage: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-16)",
    color: "red",
    marginBottom: "50px",
  },
  btn: {
    backgroundColor: "black",
    borderRadius: "24px",
    "&:hover": {
      backgroundColor: "black",
    },
  },
}));
