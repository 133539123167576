import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import '../../../../styles/global.fonts.css';
import { Button, DialogActions, DialogContentText } from '@mui/material';
import { messages } from '../../../../constants/popupMessages';
import brokerService from '../../../../service/broker';
import { useState } from 'react';
import LoadingBar from '../../LoadingBar/LoadingBar';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import { useAppContext } from '../../../../context/DataContext';
import PositiveCheckIcon from '../../../../assets/images/positiveCheck.svg';
import NegativeCheckIcon from '../../../../assets/images/negativeCheck.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../routes';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CryptoPopup = ({
  isPopupOpen,
  setIsPopupOpen,
  message,
  setCryptoPopupMessage,
  cryptoPopupStep,
  setCryptoPopupStep,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [brokerAccountInfo, setBrokerAccountInfo] = useState({});

  const { setRefetch } = useAppContext();

  const bbpTerminalUrl = process.env.REACT_APP_BBP_TERMINAL_B2TRADER;

  const handleCreateCryptoTradingAccount = async () => {
    setLoading(true);
    try {
      const response = await brokerService.createBrokerAccount();
      setBrokerAccountInfo(response);

      setRefetch((prev) => !prev);
      setCryptoPopupMessage(messages.cryptoAccountReady);
      setCryptoPopupStep(2);
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);
      setError(true);
      setCryptoPopupMessage(customError?.message);
      setCryptoPopupStep(3);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenCrypto = () => {
    navigate(routes.crypto);
    handleClose();
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <BootstrapDialogTitle onClose={handleClose} id="alert-dialog-title" className={classes.popupTitle}>
          CRYPTO
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.popupContent}
            style={{ color: error && 'red' }}
          >
            {cryptoPopupStep === 3 ? (
              <>
                <div className={classes.icon}>
                  <img
                    src={!error ? PositiveCheckIcon : NegativeCheckIcon}
                    alt="check icon"
                    className={classes.checkIcon}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={classes.text}>{message}</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          {cryptoPopupStep === 1 ? (
            <>
              <Button variant="contained" className={classes.btnYes} onClick={handleCreateCryptoTradingAccount}>
                Yes
              </Button>
              <Button onClick={handleClose} className={classes.btnCancel}>
                Cancel
              </Button>
            </>
          ) : cryptoPopupStep === 2 ? (
            <Button variant="contained" className={classes.openCrypto} onClick={handleOpenCrypto}>
              Open Crypto
            </Button>
          ) : (
            <Button variant="contained" className={error ? classes.btnYes : classes.btnClose} onClick={handleClose}>
              {error ? 'Close' : 'Go to account'}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
      <LoadingBar open={loading} />
    </div>
  );
};

export default CryptoPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      minHeight: '280px',
      maxWidth: '320px',
      width: '100%',
    },
  },
  popupTitle: {
    borderRadius: '24px',
    fontSize: 'var(--font-size-19)',
    position: 'relative',
    height: '27px',
    [theme.breakpoints.down('1500')]: {
      fontSize: 'var(--font-size-18)',
    },
    [theme.breakpoints.down('1300')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('768')]: {
      fontSize: 'var(--font-size-16)',
    },
  },
  popupContent: {
    alignItems: 'center',
    fontSize: '14px',
    color: '#374957',
    paddingLeft: '6px',
    [theme.breakpoints.down('400')]: {
      fontSize: '12px',
    },
    fontWeight: 'normal',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    marginBottom: '15px',
    flexDirection: 'column',
  },
  btnYes: {
    color: 'white',
    backgroundColor: 'black !important',
    borderRadius: '60px',
    width: '110px',
    height: '36px',
  },
  btnClose: {
    color: 'white',
    backgroundColor: 'black !important',
    borderRadius: '60px',
    width: '140px',
    height: '36px',
  },
  btnCancel: {
    color: 'black',
    borderRadius: '60px',
    border: '1px solid #989898',
    width: '110px',
    height: '36px',
    marginLeft: 'unset !important',
  },
  openCrypto: {
    color: 'white',
    backgroundColor: 'black !important',
    borderRadius: '60px',
    width: '130px',
    height: '36px',
  },
  icon: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    maxWidth: '33px',
    maxHeight: '33px',
  },
  text: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    marginTop: '8px',
  },
}));
