import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../CustomButton/CustomButton';
import { useAppContext } from '../../../context/DataContext';
import { BucketView } from '../../WebComponents/Store/components/BucketView';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import { messages } from '../../../constants/popupMessages';

import { routes } from '../../../routes';
import { handleGetUserDetails } from '../../../helpers/handleGetUserDetails';
import StoreGoldPopup from '../Popups/StoreGoldPopup/StoreGoldPopup';

import { ReactComponent as EmptyCartIcon } from '../../../assets/images/empty-cart-icon.svg';
import { transformInputToOutput } from '../../../helpers/userCartInputTransform';
import { userService } from '../../../service/user';
import { updateBucket } from '../../../helpers/update-client-bucket';
import { handleGetUserCart } from '../../../helpers/handleGetUserCart';
import NonVerifiedUserPopUp from '../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import isVerified from '../../../helpers/isVerified';
import LoadingBar from '../../common/LoadingBar/LoadingBar';

function calculateTotalPrice(data) {
  let totalPrice = 0;

  for (const productId in data) {
    const { product, quantity } = data[productId];
    totalPrice += product.price * quantity;
  }

  return totalPrice;
}

const CartPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [userDetails, setUserDetails] = useState();
  const [userCartData, setUserCartData] = useState();
  const [isTutorialLoading, setIsTutorialLoading] = useState(false);
  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);

  const {
    currency,
    isMobile,
    isAppView,
    isCartFetched,
    productsBucket,
    setIsCartFetched,
    setProductsBucket,
    isStoreModalOpened,
    setIsStoreModalOpened,
    nonVerifiedPopUpOpen,
    setNonVerifiedPopUpOpen,
  } = useAppContext();
  const totalCheckoutPrice = calculateTotalPrice(productsBucket || []);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isDemo = sessionStorage.getItem('isDemo');

  const hasProducts = Object.keys(productsBucket || {}).length > 0;

  function navigateToStore() {
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative.postMessage(`{"Event": "Store"}`);
    } else {
      navigate(routes.store);
      window.scrollTo(0, 0);
    }
  }

  async function openPurchaseModal() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }
    setIsStoreModalOpened(true);
  }

  const handleAddToCard = (product, quantity) => {
    if (isDemo) {
      return;
    }
    let bucket = JSON.parse(localStorage.getItem('bucket')) || {};

    // If the product already exists in the bucket, increment its quantity
    if (bucket[product.id]) {
      bucket[product.id].quantity = quantity;
    } else {
      bucket[product.id] = {
        product,
        quantity,
      };
    }

    localStorage.setItem('bucket', JSON.stringify(bucket));
    const updateObj = transformInputToOutput(bucket);
    setProductsBucket(bucket);
    userService.updateShoppingCart(updateObj);

    if (isAppView) {
      let counter = 0;

      if (bucket) {
        let totalQuantity = 0;

        for (const productId in bucket) {
          totalQuantity += bucket[productId].quantity;
        }
        counter = totalQuantity;
      }

      window?.webkit?.messageHandlers?.notifyNative?.postMessage(`{"Event": "CartBadge", "Action": "${counter}"}`);
    }
  };

  const handleRemoveFromCard = (productId) => {
    if (isDemo) {
      return;
    }
    let bucket = JSON.parse(localStorage.getItem('bucket')) || {};

    delete bucket[productId];
    localStorage.setItem('bucket', JSON.stringify(bucket));
    setProductsBucket(bucket);
    userService.updateShoppingCart(transformInputToOutput(bucket));

    if (isAppView) {
      let counter = 0;

      if (bucket) {
        let totalQuantity = 0;

        for (const productId in bucket) {
          totalQuantity += bucket[productId].quantity;
        }
        counter = totalQuantity;
      }

      window?.webkit?.messageHandlers?.notifyNative?.postMessage(`{"Event": "CartBadge", "Action": "${counter}"}`);
    }
  };

  useEffect(() => {
    handleGetUserDetails(setUserDetails, setIsTutorialLoading);
  }, [isStoreModalOpened, currency]);

  useEffect(() => {
    handleGetUserCart(setUserCartData, () => {});
  }, []);

  useEffect(() => {
    updateBucket(isAppView, userCartData, setIsCartFetched, setProductsBucket, userService?.updateShoppingCart);
  }, [userCartData]);

  useEffect(() => {
    const handleResize = () => {
      const isPortraitOrientation = window.matchMedia('(orientation: portrait)').matches;

      setIsPortrait(isPortraitOrientation);
      window.innerWidth < 600;
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  const TriangleArrowLeftBackToStore = () => (
    <>
      <span className={clsx(classes.backToStoreBtn, classes.marginer)}>&#x25C0;&#xFE0E;</span>
      <span>Back to Store</span>
    </>
  );

  return (
    <div className={classes.wrapper}>
      {isStoreModalOpened && userDetails?.shippingCity && (
        <StoreGoldPopup
          activeStep={activeStep}
          userDetails={userDetails}
          isPopupOpen={isStoreModalOpened}
          setIsPopupOpen={setIsStoreModalOpened}
          setActiveStep={setActiveStep}
        />
      )}
      {!isMobile && (
        <div className={classes.heading}>
          <p>YOUR CART</p>
        </div>
      )}
      {!hasProducts && isCartFetched && <EmptyCart />}
      {hasProducts && (
        <div className={classes.content}>
          <BucketView
            products={productsBucket}
            handleAddToCard={handleAddToCard}
            handleRemoveFromCard={handleRemoveFromCard}
          />
        </div>
      )}
      {(isCartFetched || isDemo) && (
        <div className={classes.checkoutInfo}>
          <span className={classes.subtotal}>
            Subtotal:
            {isDemo ? (
              <span className={classes.price}>$5,264.75</span>
            ) : (
              <span className={classes.price}>{currencyFormatter(totalCheckoutPrice, 'USD')}</span>
            )}
          </span>

          <span className={classes.info}>Delivery fee will be calculated on the next step</span>

          <div className={classes.btnWrapper}>
            <Button className={classes.blackButton} onClick={navigateToStore}>
              <TriangleArrowLeftBackToStore />
            </Button>

            {isMobile ? (
              // <CustomButton
              //   height="40"
              //   type="black"
              //   buttonText="CHECKOUT"
              //   disabled={!hasProducts}
              //   onClick={openPurchaseModal}
              // />
              <Button
                className={clsx(classes.backToStoreBtn, classes.blackButton)}
                disabled={!hasProducts}
                onClick={openPurchaseModal}
              >
                CHECKOUT
              </Button>
            ) : (
              <Button
                className={clsx(classes.backToStoreBtn, classes.blackButton)}
                disabled={!hasProducts}
                onClick={openPurchaseModal}
              >
                CHECKOUT
              </Button>
            )}
          </div>
          {nonVerifiedPopUpOpen && (
            <NonVerifiedUserPopUp
              type={'error'}
              isPopupOpen={nonVerifiedPopUpOpen}
              setIsPopupOpen={closeNonVerifiedPopUp}
              message={messages.accountNotVerified}
              handleCloseAutoInvestPopup={closeNonVerifiedPopUp}
            />
          )}
          <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
          <LoadingBar open={loading} />
        </div>
      )}
    </div>
  );
};

const EmptyCart = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView } = useAppContext();

  function navigateToStore() {
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(`{"Event": "Store"}`);
    } else {
      navigate(routes.store);
      window.scrollTo(0, 0);
    }
  }

  return (
    <div className={classes.emptyCartWrapper}>
      <span className={classes.descriptionEmptyScreen}>Your cart is empty</span>

      <EmptyCartIcon />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    margin: '0 auto',
    maxWidth: '1200px',
    height: 'calc(100dvh - 226px)',

    '@media (max-height: 530px)': {
      minHeight: '360px',
    },
  },

  backToStoreBtn: {
    fontSize: '14px',
    [theme.breakpoints.down('380')]: {
      fontSize: '12px',
    },
  },

  marginer: {
    marginRight: '8px',

    [theme.breakpoints.down('380')]: {
      marginRight: '4px',
    },
  },

  heading: {
    flex: '0 1',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textAlign: 'left',
    paddingInline: '48px',
  },

  emptyCart: {
    display: 'flex',
    fontWeight: 400,
    fontSize: '18px',
    textAlign: 'center',
    justifyContent: 'center',
  },

  content: {
    flex: '1 1 auto',

    overflowY: 'auto',
    marginInline: '20px',
    '@media (orientation: landscape)': {
      minHeight: '144px',
    },
  },

  checkoutInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBlock: '14px',
    paddingInline: '40px',
    textAlign: 'right',
  },

  subtotal: {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: '#303030',
  },
  price: {
    fontWeight: 700,
    color: '#347362',
    fontSize: '22px',
    paddingLeft: '8px',
  },

  info: {
    color: '#303030',
    paddingBottom: '16px',
  },

  footer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  btnWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '180px 180px',
    gap: 12,
    width: '100%',

    button: {
      width: '100%',
    },

    [theme.breakpoints.down(768)]: {
      // display: "flex",
      // justifyContent: "space-between",
    },
    [theme.breakpoints.down('450')]: {
      gap: 6,
      gridTemplateColumns: '140px 140px',
    },

    [theme.breakpoints.down('350')]: {
      gap: 6,
      gridTemplateColumns: '140px 100px',
    },
  },
  blackButton: {
    backgroundColor: 'var(--black)',
    borderRadius: '40px',
    fontSize: '14px',
    color: 'var(--white)',
    border: 0,
    cursor: 'pointer',
    textAlign: 'left',
    boxShadow: '0px 5px 0px 0px #76767633',
    '&:hover': {
      backgroundColor: 'var(--black)',
    },
    [theme.breakpoints.down('900')]: {
      '& .MuiTypography-button': {
        fontSize: '12px !important',
      },
    },
    '&.Mui-disabled': {
      background: '#eaeaea',
      color: '#c0c0c0',
    },
    opacity: 1,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },

  emptyCartWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 40,

    minHeight: '270px',

    [theme.breakpoints.down('678')]: {
      gap: 24,
    },
  },

  descriptionEmptyScreen: {
    fontSize: '32px',
    paddingTop: '20px',

    [theme.breakpoints.down('678')]: {
      fontSize: '22px',
    },
  },
}));

export default CartPage;
