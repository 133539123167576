function isApp() {
  const params = new URLSearchParams(window.location.search);
  const isNativeParam = params.get("isNative");

  if (isNativeParam && isNativeParam.toLowerCase() === "true") {
    sessionStorage.setItem("isNative", "true");
    return true;
  } else {
    return false;
  }
}

export default isApp;
