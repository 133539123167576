import { userService } from "../service/user";

export async function handleUpdateUserDeliveryAddress(newAddress, fn) {
  try {
    const response = await userService.updateUserDeliveryAddress(
      newAddress,
      fn
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
