import React, { useEffect } from "react";
import { Box, createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../constants/globalconstants";
import { generateCurrencySymbol } from "../../../helpers/generateCurrencySymbol";
import { useAppContext } from "../../../context/DataContext";

ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

const options1 = {
  cutout: "70%",
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      enabled: true,
      displayColors: false,
      backgroundColor: "#E0C38B",
      bodyFont: { size: 16, weight: "bold" },
      yAlign: "bottom",
      callbacks: {
        label: (context) => {
          let value = context.parsed;
          const datasetIndex = context.dataIndex;
          let currencySymbol = generateCurrencySymbol();
          if (value == -1) {
            value = 0;
          }
          return datasetIndex
            ? `${currencySymbol}${value.toFixed(2)}`
            : `Dividend ${currencySymbol}${value.toFixed(2)}`;
        },
      },
    },
  },
};

function DashBoardTotalMonthlyCharts1({ pool }) {
  const classes = useStyles();
  const isDemoMode = sessionStorage.getItem("isDemo") === "true";

  let allUsersThisMonth = pool?.all?.month0 || 0;
  let userThisMonth = pool?.user?.month0 || 0;
  let currencySymbol = generateCurrencySymbol();

  const { IsSocketReconnecting } = useAppContext();

  if (isDemoMode && currencySymbol === "€") {
    allUsersThisMonth = allUsersThisMonth * 0.93;
    userThisMonth = userThisMonth * 0.93;
  }

  useEffect(() => {}, [pool, IsSocketReconnecting, currencySymbol]);

  let rest = allUsersThisMonth - userThisMonth;
  if (!allUsersThisMonth && !allUsersThisMonth) {
    rest = -1;
  }
  const data = {
    datasets: [
      {
        data: [userThisMonth, rest, IsSocketReconnecting],
        backgroundColor: ["black", "#d7d7d7cc"],
        borderWidth: 0,
      },
    ],
    labels: [],
  };

  const texInDoughnut = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      const chartHeight = chart.chartArea.height;
      const fontSizeTotal = Math.floor(chartHeight * 0.1);
      ctx.font = `bolder ${fontSizeTotal}px sans-serif`;

      ctx.fillStyle = "#d7d7d7cc";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.fillText(`TOTAL`, xCoor, yCoor - 18);

      const fontSizeAmount = Math.floor(chartHeight * 0.1);
      ctx.font = `bolder ${fontSizeAmount}px sans-serif`;
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      let total = data?.datasets[0]?.data[1] + data?.datasets[0]?.data[0];
      const currencySymbol = generateCurrencySymbol();

      // if (isDemoMode) {
      //   if (currencySymbol === "€") {
      //     total = Number((total * 0.93).toFixed(2));
      //   }
      // }

      if (
        data?.datasets[0]?.data[1] === -1 ||
        !data?.datasets[0]?.data[0] === -1
      ) {
        total = 0;
      }
      ctx.fillText(
        `${
          data?.datasets[0]?.data?.[2]
            ? currencyFormatter(0, CURRENCY_TYPE)
            : currencyFormatter(total, CURRENCY_TYPE)
        }`,
        xCoor,
        yCoor + 15
      );
    },
  };

  //in useEffect to update the fontSize
  return (
    <Box className={classes.progressContainer}>
      <Box className={classes.chartWrapper}>
        <Doughnut data={data} options={options1} plugins={[texInDoughnut]} />
      </Box>
    </Box>
  );
}

export default DashBoardTotalMonthlyCharts1;

const useStyles = makeStyles((theme) =>
  createStyles({
    progressContainer: {
      width: "auto",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down("1600")]: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },
    chartWrapper: {
      paddingBottom: "10px",
      height: "240px",
    },
    barchartWrapper: {
      height: "150px",
      paddingBottom: "64px",
      [theme.breakpoints.down("900")]: {
        height: "120px",
        paddingBottom: "32px",
      },
    },
  })
);
