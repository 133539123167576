import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import QuantitySelector from "./QuantitySelector";
import { useAppContext } from "../../../../context/DataContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CustomButton from "../../../common/CustomButton/CustomButton";
import currencyFormatter from "../../../../helpers/currencyFormatter/currencyFormatter";

export const BucketView = ({
  products,
  handleAddToCard,
  handleRemoveFromCard,
}) => {
  const { isAppView, isMobile } = useAppContext();
  const productList =
    products &&
    Object.keys(products).map((productId) => ({
      id: productId,
      ...(products[productId] || {}),
    }));

  const isWebView = !isAppView && !isMobile;

  return (
    <div>
      {productList &&
        productList.map((product) => {
          return isWebView ? (
            <ListItem
              item={product}
              key={product.id}
              handleAddToCard={handleAddToCard}
              handleRemoveFromCard={handleRemoveFromCard}
            />
          ) : (
            <ListItemMobile
              item={product}
              key={product.id}
              handleAddToCard={handleAddToCard}
              handleRemoveFromCard={handleRemoveFromCard}
            />
          );
        })}
    </div>
  );
};

const ListItemMobile = ({
  item,
  onClick,
  handleAddToCard,
  handleRemoveFromCard,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView, isMobile } = useAppContext();
  const [quantity, setQuantity] = useState(item?.quantity || 1);
  const [isAfterInitialLoad, setIsAfterInitialLoad] = useState(false);

  const isWebView = !isAppView && !isMobile;
  const isDemo = sessionStorage.getItem("isDemo");
  let currencyInLocalStorage = localStorage.getItem("currency") || "USD";

  const itemTotalPrice = item?.product?.price * item?.quantity;
  const itemTotalPriceDemo = isDemo ? item?.product?.prices?.[currencyInLocalStorage] * item?.quantity : item?.product?.price * item?.quantity;

  useEffect(() => {
    if (isAfterInitialLoad) {
      handleAddToCard(item?.product, quantity);
    }

    setIsAfterInitialLoad(true);
  }, [quantity]);

  const handleViewProductDetails = () => {
    const isDemo = sessionStorage.getItem("isDemo");
    if (isDemo) {
      return;
    }
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        `{"Event": "Store", "Action": "${item?.product?.part_number}"}`
      );
    } else {
      window.open(
        `${window.location.origin}/store?id=${item?.product?.part_number}`
      );
    }
  };

  return (
    <div
      className={clsx(
        classes.listItemWrapper,
        !isWebView && classes.listItemWrapperMobile
      )}
      onClick={onClick}
    >
      <div
        className={classes.listItemWrapperLeft}
        onClick={handleViewProductDetails}
      >
        <img src={item.product?.images?.[0]} alt={item.product?.name} />
      </div>

      <div className={classes.mobileRight}>
        <span className={classes.nameMobile} onClick={handleViewProductDetails}>
          {item.product?.name}
        </span>

        <span className={classes.weight}>{item.product?.weight}gr</span>

        <div className={classes.middleRow}>
          <QuantitySelector
            quantity={quantity}
            setQuantity={setQuantity}
            availableInStock={item?.product?.quantity}
          />

          <span
            className={classes.deleteIconWrapper}
            onClick={() => handleRemoveFromCard(item.product?.id)}
          >
            <DeleteOutlineIcon />
          </span>
        </div>

        <div className={classes.price}>
          <span className={classes.totalPrice}>
            {currencyFormatter(isDemo ? itemTotalPriceDemo : itemTotalPrice, "USD")}
          </span>
        </div>
      </div>
      {/* 
      <div className={classes.listItemWrapperMiddle}>
        <div className={classes.details}>
          <p className={classes.name}>{item.product?.name}</p>
          <p className={classes.weight}>{item.product?.weight}gr</p>
        </div>

        <QuantitySelector
          quantity={quantity}
          setQuantity={setQuantity}
          availableInStock={item?.product?.quantity}
        />

        <div className={classes.price}>
          <span className={classes.totalPrice}>
            {currencyFormatter(item.product?.price, "USD")}
          </span>
        </div>
      </div> */}

      {/* <div className={classes.right}>
        <span></span>

        <span
          className={classes.deleteIconWrapper}
          onClick={() => handleRemoveFromCard(item.product?.id)}
        >
          <DeleteOutlineIcon />
        </span>
      </div> */}
    </div>
  );
};

const ListItem = ({ item, onClick, handleAddToCard, handleRemoveFromCard }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView, isMobile } = useAppContext();
  const [quantity, setQuantity] = useState(item?.quantity || 1);
  const [isAfterInitialLoad, setIsAfterInitialLoad] = useState(false);

  const isWebView = !isAppView && !isMobile;
  const isDemo = sessionStorage.getItem("isDemo");
  let currencyInLocalStorage = localStorage.getItem("currency") || "USD";

  const itemTotalPrice = item?.product?.price * item?.quantity;
  const itemTotalPriceDemo = isDemo ? item?.product?.prices?.[currencyInLocalStorage] * item?.quantity : item?.product?.price * item?.quantity;

  useEffect(() => {
    if (isAfterInitialLoad) {
      handleAddToCard(item?.product, quantity);
    }

    setIsAfterInitialLoad(true);
  }, [quantity]);

  useEffect(() => {
    setQuantity(item?.quantity);
  }, [item]);

  const handleViewProductDetails = () => {
    const isDemo = sessionStorage.getItem("isDemo");
    if (isDemo) {
      return;
    }
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        `{"Event": "Store", "Action": "${item?.product?.part_number}"}`
      );
    } else {
      window.open(
        `${window.location.origin}/store?id=${item?.product?.part_number}`
      );
    }
  };

  return (
    <div
      className={clsx(
        classes.listItemWrapper,
        !isWebView && classes.listItemWrapperMobile
      )}
      onClick={onClick}
    >
      <div
        className={classes.listItemWrapperLeft}
        onClick={handleViewProductDetails}
      >
        <img src={item.product?.images?.[0]} alt={item.product?.name} />
      </div>

      {isWebView ? (
        <>
          <div className={classes.listItemWrapperMiddle}>
            <div className={classes.details}>
              <p className={classes.name} onClick={handleViewProductDetails}>
                {item.product?.name}
              </p>
              <p className={classes.weight}>Weight {item.product?.weight}gr</p>
            </div>
          </div>

          <div className={classes.right}>
            <span></span>

            <div className={classes.middleWrapper}>
              <QuantitySelector
                quantity={quantity}
                setQuantity={setQuantity}
                availableInStock={item?.product?.quantity || 3}
              />
              <span className={classes.totalPrice}>
                {currencyFormatter(isDemo ? itemTotalPriceDemo : itemTotalPrice, "USD")}
              </span>
              <span
                className={classes.deleteIconWrapper}
                onClick={() => handleRemoveFromCard(item.product?.id)}
              >
                <DeleteOutlineIcon />
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.listItemWrapperMiddle}>
            <div className={classes.details}>
              <p className={classes.name} onClick={handleViewProductDetails}>
                {item.product?.name}
              </p>
              <p className={classes.weight}>{item.product?.weight}gr</p>
            </div>

            <QuantitySelector
              quantity={quantity}
              setQuantity={setQuantity}
              availableInStock={item?.product?.quantity}
            />

            <div className={classes.price}>
              <span className={classes.totalPrice}>
                {currencyFormatter(item.product?.price, "USD")}
              </span>
            </div>

            {/* <span>{currencyFormatter(itemTotalPrice, "USD")}</span> */}
          </div>

          <div className={classes.right}>
            <span></span>

            <span
              className={classes.deleteIconWrapper}
              onClick={() => handleRemoveFromCard(item.product?.id)}
            >
              <DeleteOutlineIcon />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  listItemsWrapper: {
    overflowY: "scroll",
    height: "calc(100vh - 344px)",
  },

  listItemWrapper: {
    display: "grid",
    gridTemplateColumns: "140px minmax(120px, 400px) 1fr",
    gap: 18,
    [theme.breakpoints.down(768)]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    height: "auto",
    paddingBlock: 8,
    borderBottom: "1px solid gray",

    [theme.breakpoints.down("sm")]: {
      gap: 6,
    },
  },

  listItemWrapperMobile: {
    display: "grid",
    gridTemplateColumns: "140px 1fr",
    gap: 10,

    textAlign: "left",
    paddingInline: "12px",
  },

  middleRow: {
    display: "flex",
    justifyContent: "space-between",
  },

  listItemWrapperLeft: {
    "& img": {
      width: "100%",
      height: "120px",
      minWidth: "80px",
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down(768)]: {
        height: "150px",
        minWidth: "80px",
        cursor: "pointer",
      },
      [theme.breakpoints.down(450)]: {
        height: "160px",
        minWidth: "80px",
        cursor: "pointer",
      },
    },
  },

  paddingClass: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },

  listItemWrapperMiddle: {
    display: "flex",
    textAlign: "left",
    "& p": {
      margin: 0,
    },
    [theme.breakpoints.down(768)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline",
      width: "75%",
    },
  },

  name: {
    margin: 0,
    color: "var(--black)",
    fontSize: "16px",
    height: "52px",
    lineClamp: 1,
    display: "block",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    [theme.breakpoints.down(768)]: {
      height: "auto",
    },
  },

  nameMobile: {
    margin: 0,
    color: "var(--black)",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: "4px",
    // display: 'block',
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    lineHeight: "16px", // Adjust as needed
    maxHeight: "28px", // Approximately 2 lines of text
    display: "-webkit-box",
    cursor: "pointer",
  },
  weight: {
    [theme.breakpoints.down(768)]: {
      paddingBottom: "8px",
      paddingTop: "8px",
    },
  },

  price: {
    margin: 0,
    fontWeight: "bold",
    [theme.breakpoints.down(768)]: {
      paddingTop: "8px",
      paddingBottom: "4px",
    },
  },

  right: {
    display: "grid",
    gridTemplateRows: "repeat(3, 1fr)",
    justifyContent: "flex-end",
  },

  middleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 30,
    [theme.breakpoints.down(800)]: {
      gap: 10,
    },
  },

  deleteIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    cursor: "pointer",
    borderRadius: "50%",
    background: "#ECECEC",
  },
  totalPrice: {
    minWidth: "124px",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1.275rem",
  },
}));
