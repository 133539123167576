import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function ConfirmPopUp({
  open,
  setOpen,
  message,
  save,
  orderId,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box className={classes.main}>{message}</Box>
        </DialogContent>
        <DialogActions>
          <Box className={classes.btnContainer}>
            <Button onClick={handleClose} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button
              onClick={async () => await save(orderId)}
              className={classes.confirmButton}
            >
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  cancelButton: {
    backgroundColor: "var(--black)",
    borderRadius: "40px",
    fontSize: "12px",
    color: "var(--white)",
    border: 0,
    cursor: "pointer",
    textAlign: "left",
    boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
    "&:hover": {
      backgroundColor: "var(--black)",
    },
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    maxWidth: "50%",
    width: "5rem",
    maxHeight: "28px",
  },
  confirmButton: {
    backgroundColor: "var(--green-light)",
    borderRadius: "40px",
    fontSize: "12px",
    color: "var(--white)",
    border: 0,
    textAlign: "left",
    boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
    "&:hover": {
      backgroundColor: "var(--green-light)",
    },
    cursor: "pointer",
    opacity: 1,
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    maxWidth: "50%",
    width: "5rem",
    maxHeight: "28px",
  },
  main: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
  },
}));
