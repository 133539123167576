import { useDispatch } from 'react-redux';
import { createContext, useContext, useEffect, useState } from 'react';

import brokerService from '../service/broker';
import { useAppContext } from './DataContext';
import { addManyInstruments } from '../store/slices/crypto-slice';
import { getCryptoIndexDetails } from '../helpers/getCryptoIndexDetails';
import { demoResponseHandler } from '../helpers/demoResponseHandler';

const CryptoContext = createContext();

const useCryptoContext = () => {
  const context = useContext(CryptoContext);
  if (!context) {
    throw new Error('useCryptoContext must be used within a CryptoProvider');
  }
  return context;
};

const urlBalance = process.env.REACT_APP_BBP_BALANCE_WEBSOCKET;
const urlMarketData = process.env.REACT_APP_BBP_MARKETDATA_WEBSOCKET;

const CryptoProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [userMarkets, setUserMarkets] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [marketDetails, setMarketDetails] = useState({});
  const [isChartOpened, setIsChartOpened] = useState(false);
  const [brokerAccountIds, setBrokerAccountIds] = useState([]);
  const [activeInstrument, setActiveInstrument] = useState({});
  const [isBuySellOpened, setIsBuySellOpened] = useState(false);
  const [brokerAccessToken, setBrokerAccessToken] = useState('');
  const [marketsDataMessage, setMarketsDataMessage] = useState(false);
  const [userBalanceMessage, setUserBalanceMessage] = useState(false);
  const [isUserMarketsLoading, setIsUserMarketsLoading] = useState(true);
  const [refetchAssets, setRefetchAssets] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [counter, setCounter] = useState(0);

  const { setRefetch, setIsBrokerOrderConfirmed, userBrokerAccounts, exchangeRate, selectedCryptoAccount } =
    useAppContext();

  const isDemo = sessionStorage.getItem('isDemo');
  const token = localStorage.getItem('accessToken');
  const expToken = localStorage.getItem('accessTokenExp');

  const userLoggedIn = !!token && !!expToken;

  const getBrokerUserData = async () => {
    if (userLoggedIn && !isDemo) {
      try {
        const response = await brokerService.getBrokerUserData();
        setBrokerAccessToken(response.brokerAccessToken); // token here
        setBrokerAccountIds(response.brokerAccountIds);

        if (!userBalanceMessage && response?.brokerAccountIds?.length) {
          let wsMessage = `{"protocol":"json","version":1}`;
          let counter = 0;

          response?.brokerAccountIds?.forEach((accountId) => {
            wsMessage += `{"arguments":["${accountId}"],"invocationId":"${counter}","target":"FullBalance","type":4}
          {"arguments":["${accountId}"],"invocationId":"${counter + 1}","target":"OpenOrders","type":4}`;

            counter += 2;
          });

          setUserBalanceMessage(wsMessage);
        }
      } catch (error) {
        console.error('An error occurred while fetching user broker data:', error);
      }
    } else if (isDemo) {
      try {
        const response = await brokerService.getDemoBrokerUserData();
        setBrokerAccessToken(response.accessToken);
      } catch (error) {
        console.error('An error occurred while fetching user broker data:', error);
      }
    }
  };

  useEffect(() => {
    getBrokerUserData();
  }, [userLoggedIn, isDemo, userBrokerAccounts]);

  useEffect(() => {
    if (userBrokerAccounts?.[0]?.brokerAccountId) {
      getUserTradeMarkets(userBrokerAccounts?.[0]?.brokerAccountId);
      getUserWatchlist();
    }
  }, [userBrokerAccounts, refetchAssets]);

  const getUserTradeMarkets = async (tradeAccountId) => {
    let markets;
    if (isDemo) {
      markets = demoResponseHandler('/get-markets');
    } else {
      markets = await brokerService.getTradeMarkets(tradeAccountId);
    }

    if (markets?.length) {
      let newMessage = `{ "protocol": "json", "version": 1 }]`;
      let invId = 0;
      const newMarkets = markets?.map((market) => {
        const cryptoDetails = getCryptoIndexDetails(market?.displayName?.split('/')?.[0]);
        return {
          ...market,
          name: cryptoDetails?.name,
          url: cryptoDetails?.url,
        };
      });

      if (!activeInstrument?.displayName) {
        setActiveInstrument(newMarkets[0]);
      }
      setUserMarkets(newMarkets);

      markets.forEach((market) => {
        newMessage += `{"arguments":["${market?.marketId}"],"invocationId":"${invId}","target":"Book","type":4}
                {"arguments":[["${market?.marketId}"]],"invocationId":"${invId + 1}","target":"Summary","type":4}`;

        invId += 2;
      });

      setMarketsDataMessage(newMessage); // needed for connection to ws
    }

    setTimeout(() => {
      if (isUserMarketsLoading === true) {
        setIsUserMarketsLoading(false);
      }
    }, 200);
  };

  setTimeout(() => {
    if (isUserMarketsLoading === true) {
      setIsUserMarketsLoading(false);
    }
  }, 200);

  const getUserWatchlist = async () => {
    try {
      if (isDemo) {
        setWatchlist(demoResponseHandler('/watchlist'));
        return;
      }
      const watchlist = await brokerService.getUserWatchlist();
      setWatchlist(watchlist);
    } catch (error) {
      console.error('An error occurred while fetching user watchlist:', error);
    }
  };

  const wsBalanceHandler = async (receivedJsonMessage, brokerAccountIds) => {
    const isBalanceUpdateMsg = receivedJsonMessage?.invocationId && receivedJsonMessage?.invocationId % 2 === 0;

    if (isBalanceUpdateMsg) {
      setCounter((prevCounter) => {
        const newCounter = prevCounter + 1;
        if (newCounter > brokerAccountIds?.length) {
          setRefetch((prev) => !prev);
        }
        return newCounter;
      });
    } else if (receivedJsonMessage?.item?.length > 0) {
      // logic for confirmOrder
      try {
        await brokerService.confirmOrder(receivedJsonMessage);
        setIsBrokerOrderConfirmed(true);
        setTimeout(() => {
          setRefetch((prev) => !prev);
        }, 500);
      } catch (error) {
        console.log('Error in confirmOrder', error);
      }
    }
  };

  useEffect(() => {
    if (marketsDataMessage && brokerAccessToken) {
      const worker = new Worker(new URL('../ws-worker.js', import.meta.url));

      worker.postMessage({
        type: 'CONNECT',
        url: urlMarketData,
        message: marketsDataMessage,
        brokerAccessToken,
      });

      worker.onmessage = (event) => {
        const { type, data } = event.data;
        if (type === 'MESSAGE_RECEIVED') {
          dispatch(addManyInstruments(data));
        }
      };
    }
  }, [marketsDataMessage, brokerAccessToken]);

  useEffect(() => {
    if (userBalanceMessage) {
      const worker = new Worker(new URL('../ws-worker-balance.js', import.meta.url));

      worker.postMessage({
        url: urlBalance,
        type: 'CONNECT',
        brokerAccessToken,
        message: userBalanceMessage,
      });

      worker.onmessage = (event) => {
        const { type, data } = event.data;

        if (type === 'MESSAGE_RECEIVED') {
          wsBalanceHandler(data);
        }
      };
    }
  }, [userBalanceMessage]);

  return (
    <CryptoContext.Provider
      value={{
        setUserMarkets,
        setMarketDetails,
        setIsChartOpened,
        setIsBuySellOpened,
        setActiveInstrument,
        setRefetchAssets,
        setResetSearch,

        userMarkets,
        isChartOpened,
        marketDetails,
        isBuySellOpened,
        activeInstrument,
        isUserMarketsLoading,
        watchlist,
        resetSearch,
      }}
    >
      {children}
    </CryptoContext.Provider>
  );
};

export { CryptoProvider, useCryptoContext };
