import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, createStyles } from "@mui/material";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useAppContext } from "../../../context/DataContext";

ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

let skipNextCallDataChart = false;
let skipNextCallNoDataChart = false;

function calculateOuterRadius(
  innerWidth,
  baseWidth,
  baseDivisor,
  step,
  decrement
) {
  const steps = Math.floor((baseWidth - innerWidth) / step);
  const newDivisor = baseDivisor - steps * decrement;
  return Math.ceil(innerWidth / newDivisor);
}

const linearGradientColor = (context) => {
  const chart = context.chart;
  const index = context.dataIndex;

  if (index === 0) {
    return "#E0C38B";
  } else if (index === 1) {
    const ctx = chart.ctx;
    const gradient = ctx.createLinearGradient(250, 50, 0, 0);
    gradient.addColorStop(0, "#FFFFFF");
    gradient.addColorStop(1, "#5B5959");
    return gradient;
  } else if (index === 2) {
    return "var(--black)";
  }
};

let myNewChart = {
  type: "doughnut",
  plugins: [
    {
      beforeDraw: (chart) => {
        if (window.innerHeight > window.innerWidth) {
          // Check if the orientation is landscape
          chart.getDatasetMeta(0).data.forEach((segment, index) => {
            if (index === 2) {
              let baseDivisor;
              const innerWidth = window.innerWidth;
              const baseWidth = 1024;
              const step = 12.5;
              const decrement = 0.1;

              if (innerWidth <= 1024 && innerWidth > 769) {
                baseDivisor = 10;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else if (innerWidth <= 768 && innerWidth > 531) {
                baseDivisor = 9.8;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else if (innerWidth <= 531 && innerWidth > 500) {
                baseDivisor = 10;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else if (innerWidth <= 500 && innerWidth > 473) {
                baseDivisor = 10.2;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else if (innerWidth <= 473 && innerWidth > 437) {
                baseDivisor = 10.5;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else if (innerWidth <= 437 && innerWidth > 395) {
                baseDivisor = 10.8;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else if (innerWidth <= 395 && innerWidth > 365) {
                baseDivisor = 11;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else if (innerWidth <= 365 && innerWidth > 335) {
                baseDivisor = 11.2;
                segment.outerRadius = calculateOuterRadius(
                  innerWidth,
                  baseWidth,
                  baseDivisor,
                  step,
                  decrement
                );
              } else {
                console.log("else");
              }
            }
          });
        }
      },
    },
    {
      afterDraw: (chart) => {
        if (chart.ctx) {
          const ctx = chart.ctx;
          ctx.save();
          const image = new Image();
          image.src =
            "https://storage.googleapis.com/b-admin-panel-bucket/public-images/coin2.png";
          image.alt = "Gold coin";
          const chartWidth = chart.chartArea.width;
          let imageSize = chartWidth * 0.6;
          const xCoor = chart.getDatasetMeta(0).data[0].x;
          const yCoor = chart.getDatasetMeta(0).data[0].y;
          chart.canvas.style.display = "inline";
          ctx.globalCompositeOperation = "destination-over";
          ctx.drawImage(
            image,
            xCoor - imageSize / 2,
            yCoor - imageSize / 2,
            imageSize,
            imageSize
          );
          ctx.restore();
        }
      },
    },
  ],
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: linearGradientColor,
      },
    ],
  },
  options: {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        displayColors: false,
        boxWidth: 40,
        backgroundColor: "#E0C38B",
        bodyFont: { size: 16, weight: "bold" },
        yAlign: "bottom",
        callbacks: {
          label: (context) => {
            const value = context.parsed;
            const index = context.dataIndex;
            let name = "";

            if (index === 0) {
              name = "VAULTING";
            } else if (index === 1) {
              name = "YIELDING";
            } else if (index === 2) {
              name = "FREE";
            } else {
              console.log("some error occurred");
            }
            return `${value}`;
          },
        },
        animation: {
          onComplete: () => {},
        },
      },
    },
    legend: {
      display: false,
    },
    cutout: "55%",
    borderWidth: 6,
  },
};

let noDataChart = {
  type: "doughnut",
  plugins: [
    {
      beforeDraw: (chart) => {
        chart.getDatasetMeta(0).data.forEach((segment, index) => {
          if (index === 2) {
            const innerWidth = window.innerWidth;
            let newOuterRadius = 66;
            if (innerWidth >= 450) {
              newOuterRadius = 78;
            } else {
              newOuterRadius = 66;
            }
            segment.outerRadius = newOuterRadius;
          }
        });
      },
    },
    {
      afterDraw: (chart) => {
        if (chart.ctx) {
          const ctx = chart.ctx;
          ctx.save();
          const image = new Image();
          image.src =
            "https://storage.googleapis.com/b-admin-panel-bucket/public-images/coin2.png";
          image.alt = "Gold coin";
          const chartWidth = chart.chartArea.width;
          let imageSize = chartWidth * 0.6;
          const xCoor = chart.getDatasetMeta(0).data[0].x;
          const yCoor = chart.getDatasetMeta(0).data[0].y;
          chart.canvas.style.display = "inline";
          ctx.globalCompositeOperation = "destination-over";
          ctx.drawImage(
            image,
            xCoor - imageSize / 2,
            yCoor - imageSize / 2,
            imageSize,
            imageSize
          );
          ctx.restore();
        }
      },
    },
  ],
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: linearGradientColor,
      },
    ],
  },
  options: {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        displayColors: false,
        boxWidth: 40,
        backgroundColor: "#E0C38B",
        bodyFont: { size: 16, weight: "bold" },
        yAlign: "bottom",
        callbacks: {
          label: (context) => {
            const value = context.parsed;
            const index = context.dataIndex;
            let name = "";

            if (index === 0) {
              name = "VAULTING";
            } else if (index === 1) {
              name = "YIELDING";
            } else if (index === 2) {
              name = "FREE";
            } else {
              console.log("some error occurred");
            }
            return `${value}`;
          },
        },
        animation: {
          onComplete: () => {},
        },
      },
    },
    legend: {
      display: false,
    },
    cutout: "55%",
    borderWidth: 6,
  },
};

function getDataChart(chart, data, fn, total) {
  chart.data.datasets[0].data = data;
  chart.options.plugins.tooltip.callbacks.label = (context) => {
    const value = context.parsed;
    const index = context.dataIndex;
    let name = "";
    if (index === 0) {
      name = "YIELDING";
    } else if (index === 1) {
      name = "FREE";
    } else if (index === 2) {
      name = "VAULTING";
    } else {
      console.log("some error occurred");
    }
    skipNextCallDataChart = true;
    fn(name, value);
    return `${value}gr`;
  };

  chart.options.plugins.tooltip.animation.onComplete = () => {
    if (!skipNextCallDataChart) {
      fn("TOTAL", total);
    }
    skipNextCallDataChart = false;
  };

  return chart;
}

function getNoDataChart(chart, data, fn, total) {
  chart.data.datasets[0].data = data;
  chart.options.plugins.tooltip.callbacks.label = (context) => {
    const value = context.parsed;
    const index = context.dataIndex;
    let name = "";
    if (index === 0) {
      name = "FREE";
    } else if (index === 1) {
      name = "FREE";
    } else if (index === 2) {
      name = "FREE";
    } else {
      console.log("some error occurred");
    }
    skipNextCallNoDataChart = true;
    fn(name, value);
    return "0gr";
  };

  chart.options.plugins.tooltip.animation.onComplete = () => {
    if (!skipNextCallNoDataChart) {
      fn("TOTAL", total);
    }
    skipNextCallNoDataChart = false;
  };
  return chart;
}
function MobilePorfolioAllocationChart() {
  const classes = useStyles();
  const {
    portfolioAllocationData,
    totalAllocatedCoins,
    stakedCoins,
    vaultedCoins,
    isErrorRemovedPA,
    setIsErrorRemovedPA,
    setNameAndValueInPA,
  } = useAppContext();

  const isTutorialStep = Number(sessionStorage.getItem("tutorialStep")) === 9;
  // const isTutorialStep =
  // Number(sessionStorage.getItem("tutorialStep")) > 0 &&
  // Number(sessionStorage.getItem("tutorialStep")) < 13;

  const [chartKey, setChartKey] = useState(0);
  const totalCoins = portfolioAllocationData
    .reduce((partialSum, a) => partialSum + a, 0)
    .toString();

  function handleNameOrValueOfPA(name, value) {
    setNameAndValueInPA((prev) => {
      if (prev.name !== name || prev.value !== value) {
        return {
          ...prev,
          name: name,
          value: value,
        };
      }
      return prev;
    });
  }

  function handleNoDataNameOrValueOfPA(name) {
    setNameAndValueInPA((prev) => {
      if (prev.name !== name) {
        return {
          ...prev,
          name: name,
          value: 0,
        };
      }
      return prev;
    });
  }
  const chartTrinity = getDataChart(
    myNewChart,
    portfolioAllocationData,
    handleNameOrValueOfPA,
    totalCoins
  );

  const noDataChartTrinity = getNoDataChart(
    noDataChart,
    [0, 100, 0],
    handleNoDataNameOrValueOfPA,
    0
  );

  useEffect(() => {
    refreshChart();
  }, [totalAllocatedCoins, stakedCoins, vaultedCoins]);

  function refreshChart() {
    setChartKey((prevKey) => prevKey + 1);
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsErrorRemovedPA(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const borderColor = isTutorialStep ? "rgba(125,125,125,1" : "white";
  const hoverBorderColor = isTutorialStep ? "#7c7c7c" : "white";

  return isErrorRemovedPA ? (
    <React.Fragment>
      {!stakedCoins && !vaultedCoins && !totalAllocatedCoins ? (
        <Box className={classes.progressContainer}>
          <Doughnut
            id="portfolio-allocation-doughnut"
            data={noDataChartTrinity.data}
            options={{
              ...noDataChartTrinity.options,
              borderColor,
              hoverBorderColor,
            }}
            plugins={noDataChartTrinity.plugins}
            key={chartKey}
          />
        </Box>
      ) : (
        <Box className={classes.progressContainer}>
          <Doughnut
            id="portfolio-allocation-doughnut"
            data={chartTrinity.data}
            options={{ ...chartTrinity.options, borderColor, hoverBorderColor }}
            plugins={chartTrinity.plugins}
            key={chartKey}
          />
        </Box>
      )}
    </React.Fragment>
  ) : null;
}

export default MobilePorfolioAllocationChart;

const useStyles = makeStyles((theme) =>
  createStyles({
    progressContainer: {
      width: "auto",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      fontFamily: theme.typography.fontFamily,

      "& canvas": {
        width: "34vw !important",
        height: "34vw !important",
        maxWidth: "210px !important",
        maxHeight: "210px !important",

        "@media (max-height: 860px)": {
          width: "34vw !important",
          height: "34vw !important",
          maxWidth: "200px !important",
          maxHeight: "200px !important",
        },

        "@media (max-height: 680px)": {
          width: "120px !important",
          height: "120px !important",
        },
      },
    },
    btn: {
      position: "absolute",
      transform: "translate(-170%, 170%)",
    },
  })
);
