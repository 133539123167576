import serviceAutoInvest from '../service/autoInvest';

export const handleGetAllAutoInvestTransactions = async (fn, page, setAllPages, setError, setLoading) => {
  setLoading(true);
  try {
    const response = await serviceAutoInvest.getAllAutoInvest(page);
    let autoInvestTransactions = response.data.map(({ ...rest }) => {
      return {
        id: rest.id,
        date: rest.date,
        volume: rest.volume,
        period: rest.period,
        status: rest.status,
        currency: rest.currency,
        tradeAccountId: rest.tradeAccountId,
      };
    });
    if (page === 0) {
      autoInvestTransactions = autoInvestTransactions.slice(0, 3);
    }
    autoInvestTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));
    fn(autoInvestTransactions);
    setAllPages(response.pages);
  } catch (e) {
    setError(true);
    console.log(e);
  } finally {
    setLoading(false);
  }
};
