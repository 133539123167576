import * as React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import "../../../styles/global.styles.css";
import CustomButton from "../../common/CustomButton/CustomButton";
import IButton from "../../common/IButton/IButton";
import { tooltipContent } from "../../../constants/tooltipTexts";
import Carousel from "react-material-ui-carousel";
import RightArrowNavigation from "../../../assets/images/right-arrow-navigation.svg";
import LeftArrowNavigation from "../../../assets/images/left-arrow-navigation.svg";
import { useEffect } from "react";
import { storeService } from "../../../service/store";
import { useState } from "react";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../constants/globalconstants";
import ProductNotFoundIcon from "../../../assets/images/product-not-found-icon.svg";
import { useAppContext } from "../../../context/DataContext";

const ECommerceCard = () => {
  const classes = useStyles();
  const { currency } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [topFiveProducts, setTopFiveProducts] = useState([]);

  const getTopProducts = async () => {
    setLoading(true);
    try {
      const topProducts = await storeService.getStoreTopProducts(currency);
      setTopFiveProducts(topProducts);
    } catch (error) {
      console.error("Error getting top 5 products", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTopFiveProducts([]);

    getTopProducts();
  }, [currency]);

  const handleClick = () => {
    window.location.href = "/store";
  };

  const handleProductClicked = (part_number) => {
    window.location.href = `/store?id=${part_number}`;
  };

  return (
    <Box className={classes.eCommerceCard}>
      <div className={classes.tooltipContainer}>
        <IButton tooltipTitle={tooltipContent.eCommerce} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.imageContainer}>
          <Carousel
            autoPlay={true}
            interval={5000}
            navButtonsAlwaysVisible
            indicators={false}
            className={classes.carousel}
            animation="slide"
            swipe={false}
            fullHeightHover={true}
            PrevIcon={
              <img
                style={{
                  width: "11px",
                  height: "12px",
                  paddingRight: "1px",
                  "&.MuiButtonBase-root": {
                    padding: "4px !important",
                  },
                }}
                src={LeftArrowNavigation}
                alt="Left arrow"
              />
            }
            NextIcon={
              <img
                style={{ width: "11px", height: "12px", paddingLeft: "1px" }}
                src={RightArrowNavigation}
                alt="Right arrow"
              />
            }
            navButtonsProps={{
              className: classes.navButton,
            }}
            navButtonsWrapperProps={{
              style: {
                bottom: "35px",
                top: "unset",
                display: !topFiveProducts && "none",
              },
            }}
            height="100%"
          >
            {topFiveProducts ? (
              topFiveProducts.map((item, i) => (
                <div key={i} className={classes.carouselImage}>
                  <div>
                    {
                      <img
                        src={item.image_url}
                        alt="Store product"
                        className={classes.image}
                        onClick={() => handleProductClicked(item.part_number)}
                      />
                    }
                  </div>
                  <div className={classes.description}>
                    <span
                      className={classes.productName}
                      onClick={() => handleProductClicked(item.part_number)}
                    >
                      {item.name}
                    </span>
                    <span className={classes.productPrice}>
                      {currencyFormatter(item.price, CURRENCY_TYPE)}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noProducts}>
                <img
                  src={ProductNotFoundIcon}
                  alt="No products icon"
                  className={classes.noProductsImg}
                />
                <span>No products found</span>
              </div>
            )}
          </Carousel>
        </div>
        <div className={classes.blackButton}>
          <CustomButton
            type="black"
            buttonText="Go To Shop"
            width="100%"
            height="100%"
            onClick={handleClick}
          />
        </div>
      </div>
    </Box>
  );
};

export default ECommerceCard;

const useStyles = makeStyles((theme) => ({
  eCommerceCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    flexDirection: "column",
    backgroundColor: "var(--white)",
    width: "100%",
    height: "100%",
    borderRadius: "28px",
  },
  tooltipContainer: {
    zIndex: 10,
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    flexDirection: "column",
    width: "100%",
    height: "90%",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "85%",
  },
  carousel: {
    position: "relative",
    flexDirection: "column",
    width: "100%",
    height: "100%",

    "& .MuiButtonBase-root": {
      padding: "6px !important",
    },
  },
  navButton: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#D1D1D1",
    },
  },
  carouselImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%",
  },
  noProducts: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "95%",
    color: "gray",
    fontSize: "14px",
    padding: "5px",
  },
  noProductsImg: {
    width: "150px",
  },
  image: {
    position: "relative",
    maxWidth: "112px",
    maxHeight: "112px",
    cursor: "pointer",
    objectFit: "contain",
  },
  description: {
    position: "relative",
    display: "flex",
    justifyContent: "end",
    flexDirection: "column",
    width: "95%",
    fontSize: "12px",
  },
  productName: {
    cursor: "pointer",
    color: "gray",
    fontSize: "14px",
    lineHeight: "16px",
    height: "32px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  productPrice: {
    fontSize: "16px",
    fontWeight: "500",
  },
  blackButton: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: "8rem",
    height: "2.6rem",
    zIndex: "1",
    "& .MuiTypography-button": {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("1600")]: {
      width: "7rem",
      height: "2rem",
      "& .MuiTypography-button": {
        fontSize: "15px !important",
      },
    },
    [theme.breakpoints.down("1300")]: {
      width: "5rem",
      height: "1.5rem",
      "& .MuiTypography-button": {
        fontSize: "10px !important",
      },
    },
  },
}));
