import goldPriceService from '../service/goldPrice';

export const handleGetLatestAvailableEURPrice = async () => {
  try {
    const response = await goldPriceService.getLatestAvailableEURPrice();
    return response;
  } catch (error) {
    console.error('Error fetching latest EUR prices:', error);
  }
};
