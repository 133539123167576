import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import "../../../../styles/global.styles.css";
import "../../../../styles/global.fonts.css";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import { useState } from "react";
import NotificationMessagePopup from "../NotificationMessagePopup/NotificationMessagePopup";
import { messages } from "../../../../constants/popupMessages";

const steps = ["Step 1", "Step 2", "Step 3"];

const HorizontalStepperWeb = ({
  stepsComponents,
  activeStep,
  setActiveStep,
  handleSubmit,
  quantity,
  getStoreFees,
  chosenAddress,
  setIsAddNewAddress,
  setServiceFee,
  partNumber,
  isRedeemFeeLoading,
}) => {
  const classes = useStyles();
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (
        chosenAddress?.addressLine &&
        chosenAddress?.city &&
        chosenAddress?.countryName &&
        chosenAddress?.zipCode
      ) {
        const feesPayload = {
          shippingInformation: {
            countryCode: chosenAddress?.countryCode,
            zip: chosenAddress?.zipCode,
          },
          products: [{ quantity: 1, partNumber: partNumber }],
        };
        getStoreFees(feesPayload);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setErrorPopupOpen(true);
      }
    }
  };

  const handleBack = () => {
    setServiceFee(0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsAddNewAddress(false);
  };

  const handleSubmitClicked = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleSubmit();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel className={classes.stepLabel}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography variant="div" component="div" sx={{ mt: 2, mb: 1 }}>
        {stepsComponents[activeStep]}
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "63%",
          justifyContent: activeStep === 0 ? "flex-end" : "space-between",
          alignItems: "center",
          pt: 2,
        }}
      >
        {activeStep !== 0 && (
          <Button
            color="inherit"
            variant="contained"
            startIcon={<ArrowLeftOutlinedIcon />}
            onClick={handleBack}
            sx={{
              mr: 1,
              color: "white",
              boxShadow: "0px 4px 0px 0px #00000026;",
            }}
            className={classes.backButton}
          >
            Back
          </Button>
        )}
        {activeStep !== steps.length - 1 ? (
          <Button
            variant="contained"
            endIcon={<ArrowRightOutlinedIcon />}
            disabled={!partNumber || quantity === 0}
            onClick={handleNext}
            className={classes.nextButton}
            sx={{
              color: (!partNumber || quantity === 0) && "white !important",
              backgroundColor:
                partNumber && quantity !== 0 && "black !important",
              boxShadow:
                (!partNumber || quantity === 0) &&
                "0px 4px 0px 0px #00000026 !important",
            }}
          >
            Next Step
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleSubmitClicked}
            className={classes.nextButton}
            disabled={!partNumber || isRedeemFeeLoading}
            sx={{
              color: "white !important",
              backgroundColor: "black !important",
            }}
          >
            Submit
          </Button>
        )}
      </Box>
      {errorPopupOpen && (
        <NotificationMessagePopup
          type={"error"}
          isPopupOpen={errorPopupOpen}
          setIsPopupOpen={setErrorPopupOpen}
          message={messages.addressField}
          hasOkButton={true}
        />
      )}
    </Box>
  );
};

export default HorizontalStepperWeb;

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    "& .Mui-active, & .Mui-completed ": {
      color: "#1AC88D !important",
    },
    "& .MuiStepLabel-alternativeLabel": {
      fontSize: "var(--font-size-16)",
      fontWeight: "400",
      fontFamily: theme.typography.fontFamily,
      marginTop: "0px",
    },
    "& .MuiStepIcon-root": {
      width: "43px",
      zIndex: "1",
    },
    "& .MuiStepIcon-completed": {
      fill: "white",
    },
  },
  nextButton: {
    borderRadius: "38px",
    fontSize: "clamp(12px, 2vw, 18px)",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
    width: "160px",
    boxShadow: "0px 4px 0px 0px #00000026;",
  },
  backButton: {
    backgroundColor: "#989898",
    borderRadius: "60px",
    fontSize: "clamp(12px, 2vw, 18px)",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      backgroundColor: "#989898",
    },
  },
}));
