import React, { useEffect, useState } from "react";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useAppContext } from "../../../context/DataContext";

ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

let skipNextCall = false;

const coinUrl =
  "https://storage.googleapis.com/b-admin-panel-bucket/public-images/coin2.png";

const linearGradientColor = (context) => {
  const index = context.dataIndex;
  const chart = context.chart;
  const innerWidth = window.innerWidth;

  if (index === 0) {
    return "#E0C38B";
  } else if (index === 1) {
    const ctx = chart.ctx;
    let gradient = ctx.createLinearGradient(250, 200, 40, 20);

    if (innerWidth >= 1600) {
      gradient.addColorStop(0, "#FFFFFF");
      gradient.addColorStop(0.5, "#AAAAAA");
      gradient.addColorStop(0.7, "#888888");
      gradient.addColorStop(1, "#5B5959");
    } else {
      gradient.addColorStop(0, "#FFFFFF");
      gradient.addColorStop(1, "#5B5959");
    }
    return gradient;
  } else if (index === 2) {
    return "var(--black)";
  }
};

let coinImageDrawn = false;

const coinImage = new Image();
coinImage.src = coinUrl;
coinImage.onload = function () {
  coinImageDrawn = true;
};

const myNewChart = {
  type: "doughnut",
  plugins: [
    {
      beforeDraw: (chart) => {
        chart.getDatasetMeta(0).data.forEach((segment, index) => {
          if (index === 1 || index === 0) {
            const innerWidth = window.innerWidth;
            let newOuterRadius = innerWidth / 25;
            if (innerWidth < 1200) {
              newOuterRadius = innerWidth / 25;
            } else if (innerWidth > 3100) {
              newOuterRadius = innerWidth / 10;
            } else if (innerWidth > 2690) {
              newOuterRadius = innerWidth / 10.9;
            } else if (innerWidth > 2450) {
              newOuterRadius = innerWidth / 11.5;
            } else if (innerWidth > 2180) {
              newOuterRadius = innerWidth / 12;
            } else if (innerWidth > 1980) {
              newOuterRadius = innerWidth / 13;
            } else if (innerWidth > 1920) {
              newOuterRadius = innerWidth / 13.3;
            } else if (innerWidth > 1905) {
              newOuterRadius = innerWidth / 14;
            } else {
              newOuterRadius = innerWidth / 14.3;
            }
            segment.outerRadius = innerWidth / 7.7 - newOuterRadius; // Subtract from innerWidth/2
          }
        });
      },
    },
    {
      id: "coinImage",
      afterDatasetsDraw(chart) {
        if (!coinImageDrawn) return;
        const { ctx } = chart;
        ctx.save();
        const chartWidth = chart.chartArea.width;
        let imageSize = chartWidth * 0.6;
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        ctx.drawImage(
          coinImage,
          xCoor - imageSize / 2,
          yCoor - imageSize / 2,
          imageSize,
          imageSize
        );
      },
    },
  ],
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: linearGradientColor,
      },
    ],
  },
  options: {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      tooltip: {
        // this enables the tooltip to be ON HOVER
        enabled: true,
        displayColors: false,
        boxWidth: 40,
        backgroundColor: "#E0C38B",
        bodyFont: { size: 16, weight: "bold" },
        yAlign: "bottom",
        callbacks: {
          label: (context) => {
            const value = context.parsed;
            const index = context.dataIndex;
            let name = "";

            if (index === 0) {
              name = "VAULTING";
            } else if (index === 1) {
              name = "YIELDING";
            } else if (index === 2) {
              name = "FREE";
            } else {
              console.log("some error occurred");
            }
            return `${value}`;
          },
        },
        animation: {
          onComplete: () => {},
        },
      },
    },
    legend: {
      display: false,
    },
    cutout: "55%",
    borderWidth: 6,
    borderColor: "rgb(255,255,255,1)",
    hoverBorderColor: "white",
    zIndex: 5,
  },
};

const noDataChart = {
  type: "doughnut",
  plugins: [
    {
      beforeDraw: (chart) => {
        chart.getDatasetMeta(0).data.forEach((segment, index) => {
          if (index === 2) {
            const innerWidth = window.innerWidth;
            let newOuterRadius = innerWidth / 1.3;
            if (innerWidth < 1200) {
              newOuterRadius = innerWidth / 10;
            } else {
              newOuterRadius = innerWidth / 15.3;
            }
            segment.outerRadius = newOuterRadius;
          }
        });
      },
    },
    {
      id: "coinImage",
      afterDatasetsDraw(chart) {
        if (!coinImageDrawn) return;
        const { ctx } = chart;
        ctx.save();
        const chartWidth = chart.chartArea.width;
        let imageSize = chartWidth * 0.6;
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        ctx.drawImage(
          coinImage,
          xCoor - imageSize / 2,
          yCoor - imageSize / 2,
          imageSize,
          imageSize
        );
      },
    },
  ],
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: linearGradientColor,
      },
    ],
  },
  options: {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      tooltip: {
        // this enables the tooltip to be ON HOVER
        enabled: true,
        displayColors: false,
        boxWidth: 40,
        backgroundColor: "#E0C38B",
        bodyFont: { size: 16, weight: "bold" },
        yAlign: "bottom",
        callbacks: {
          label: (context) => {
            const value = context.parsed;
            const index = context.dataIndex;
            let name = "";

            if (index === 0) {
              name = "VAULTING";
            } else if (index === 1) {
              name = "YIELDING";
            } else if (index === 2) {
              name = "FREE";
            } else {
              console.log("some error occurred");
            }
            return `${value}`;
          },
        },
        animation: {
          onComplete: () => {},
        },
      },
    },
    legend: {
      display: false,
    },
    cutout: "55%",
    borderWidth: 0,
    borderColor: "rgb(255,255,255,1)",
    hoverBorderColor: "white",
  },
};

function getDataChart(chart, data, fn, total) {
  chart.data.datasets[0].data = data;
  chart.options.plugins.tooltip.callbacks.label = (context) => {
    const value = context.parsed;
    const index = context.dataIndex;
    let name = "";
    if (index === 0) {
      name = "YIELDING";
    } else if (index === 1) {
      name = "FREE";
    } else if (index === 2) {
      name = "VAULTING";
    } else {
      console.log("some error occurred");
    }
    skipNextCall = true;
    fn(name, value);
    return `${value}gr`;
  };
  chart.options.plugins.tooltip.animation.onComplete = () => {
    if (!skipNextCall) {
      fn("TOTAL", total);
    }
    skipNextCall = false;
  };
  return chart;
}

function getNoDataChart(chart, data, fn, total) {
  chart.data.datasets[0].data = data;
  chart.options.plugins.tooltip.callbacks.label = (context) => {
    const value = context.parsed;
    const index = context.dataIndex;
    let name = "";
    if (index === 0) {
      name = "FREE";
    } else if (index === 1) {
      name = "FREE";
    } else if (index === 2) {
      name = "FREE";
    } else {
      console.log("some error occurred");
    }
    skipNextCall = true;
    fn(name, value);
    return "0gr";
  };
  chart.options.plugins.tooltip.animation.onComplete = () => {
    if (!skipNextCall) {
      fn("TOTAL", total);
    }
    skipNextCall = false;
  };
  return chart;
}

function DashBoardPortfolioAllocationChart() {
  const {
    setNameAndValueInPA,
    portfolioAllocationData,
    totalAllocatedCoins,
    stakedCoins,
    vaultedCoins,
    isErrorRemovedPA,
    setIsErrorRemovedPA,
  } = useAppContext();

  const [chartKey, setChartKey] = useState(0);
  const totalCoins = portfolioAllocationData
    .reduce((partialSum, a) => partialSum + a, 0)
    .toString();

  function handleNameOrValueOfPA(name, value) {
    setNameAndValueInPA((prev) => ({
      ...prev,
      name: name,
      value: value,
    }));
  }

  function handleNoDataNameOrValueOfPA(name) {
    setNameAndValueInPA((prev) => ({
      ...prev,
      name: name,
      value: 0,
    }));
  }
  const chartTrinity = getDataChart(
    myNewChart,
    portfolioAllocationData,
    handleNameOrValueOfPA,
    totalCoins
  );

  const noDataChartTrinity = getNoDataChart(
    noDataChart,
    [0, 100, 0],
    handleNoDataNameOrValueOfPA,
    0
  );

  useEffect(() => {
    refreshChart();
  }, [totalAllocatedCoins, stakedCoins, vaultedCoins]);

  function refreshChart() {
    setChartKey((prevKey) => prevKey + 1);
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsErrorRemovedPA(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);
  return isErrorRemovedPA ? (
    <React.Fragment>
      {!stakedCoins && !vaultedCoins && !totalAllocatedCoins ? (
        <Doughnut
          data={noDataChartTrinity.data}
          options={noDataChartTrinity.options}
          plugins={noDataChartTrinity.plugins}
          key={chartKey}
        />
      ) : (
        <Doughnut
          data={chartTrinity.data}
          options={chartTrinity.options}
          plugins={chartTrinity.plugins}
          key={chartKey}
        />
      )}
    </React.Fragment>
  ) : null;
}

export default DashBoardPortfolioAllocationChart;
