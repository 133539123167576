import React, { useEffect, useState } from "react";
import { Box, createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../constants/globalconstants";
import { useAppContext } from "../../../context/DataContext";
import { generateCurrencySymbol } from "../../../helpers/generateCurrencySymbol";

ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

const myNewChart = {
  type: "doughnut",
  plugins: [
    {
      id: "textCenter",
      beforeDatasetsDraw(chart) {
        const { ctx, data } = chart;
        ctx.save();
        const chartHeight = chart.chartArea.height;
        const fontSizeTotal = Math.floor(chartHeight * 0.1);
        ctx.font = `bolder ${fontSizeTotal}px sans-serif`;

        ctx.fillStyle = "#d7d7d7cc";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        ctx.fillText(`TOTAL`, xCoor, yCoor - 20);

        const fontSizeAmount = Math.floor(chartHeight * 0.12);
        ctx.font = `bolder ${fontSizeAmount}px sans-serif`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        const isSocketLoading = data?.datasets[0]?.data[2];
        let total = data?.datasets[0]?.data[1] + data?.datasets[0]?.data[0];

        const isDemoMode = sessionStorage.getItem("isDemo") === "true";

        const currencySymbol = generateCurrencySymbol();

        // if (isDemoMode) {
        //   if (currencySymbol === "€") {
        //     total = Number((total * 0.93).toFixed(2));
        //   }
        // }
        if (
          data?.datasets[0]?.data[1] === -1 ||
          !data?.datasets[0]?.data[0] === -1
        ) {
          total = 0;
        }
        ctx.fillText(
          `${
            isSocketLoading
              ? currencyFormatter(0, CURRENCY_TYPE)
              : currencyFormatter(total, CURRENCY_TYPE)
          }`,
          xCoor,
          yCoor + 5
        );
      },
    },
  ],
  data: {
    datasets: [
      {
        data: [100, 700],
        backgroundColor: ["black", "#d7d7d7cc"],
        borderWidth: 0,
      },
    ],
    labels: [],
  },
  options: {
    cutout: "65%",
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      duration: 0,
    },
    plugins: {
      tooltip: {
        enabled: true,
        displayColors: false,
        boxWidth: 40,
        backgroundColor: "#E0C38B",
        bodyFont: { size: 16, weight: "bold" },
        yAlign: "bottom",
        callbacks: {
          label: (context) => {
            let value = context.parsed;
            let currencySymbol = generateCurrencySymbol();
            if (value == -1) {
              value = 0;
            }
            return `${currencySymbol}${value.toFixed(2)}`;
          },
        },
      },
    },
  },
};

function getDataChart(chart, data) {
  chart.data.datasets[0].data = data;
  return chart;
}

function MobileDashBoardTotalMonthlyCharts2({ pool }) {
  const classes = useStyles();
  const [chartKey, setChartKey] = useState(0);

  let allUsersAllTime = pool?.all?.total || 0;
  let userAllTime = pool?.user?.total || 0;
  const isDemoMode = sessionStorage.getItem("isDemo") === "true";

  const currencySymbol = generateCurrencySymbol();

  if (isDemoMode && currencySymbol === "€") {
    allUsersAllTime = allUsersAllTime * 0.93;
    userAllTime = userAllTime * 0.93;
  }

  const {
    isErrorRemovedPA,
    setIsErrorRemovedPA,
    totalAllocatedCoins,
    stakedCoins,
    vaultedCoins,
    IsSocketReconnecting,
  } = useAppContext();

  const chartCanvasId = `chartCanvas-${chartKey}`;

  const calculateChartSize = () => {
    const widthFraction = 0.8;
    const heightFraction = 0.6;

    const screenWidth = window.innerWidth * widthFraction;
    const screenHeight = window.innerHeight * heightFraction;

    return {
      width: screenWidth,
      height: screenHeight,
    };
  };
  let rest = allUsersAllTime - userAllTime;
  if (!allUsersAllTime && !userAllTime) {
    rest = -1;
  }
  const chartTrinity = getDataChart(myNewChart, [
    userAllTime,
    rest,
    IsSocketReconnecting,
  ]);
  const chartSize = calculateChartSize();

  useEffect(() => {
    refreshChart();
  }, [IsSocketReconnecting]);

  useEffect(() => {}, [pool, IsSocketReconnecting, isDemoMode,currencySymbol]);

  function refreshChart() {
    const chartCanvas = document.getElementById(chartCanvasId);
    const existingChart = chartCanvas && chartCanvas.chart;

    if (existingChart) {
      existingChart.destroy();
    }
    setChartKey((prevKey) => prevKey + 1);
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsErrorRemovedPA(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [IsSocketReconnecting]);

  return isErrorRemovedPA ? (
    <React.Fragment>
      {!stakedCoins && !vaultedCoins && !totalAllocatedCoins ? (
        <Box className={classes.progressContainer}>
          <Box
            className={classes.chartWrapper}
            id="total-monthly-pool-doughnut"
          >
            <Doughnut
              data={chartTrinity.data}
              options={{
                ...chartTrinity.options,
                responsive: true,
                width: chartSize.width,
                height: chartSize.height,
              }}
              plugins={chartTrinity.plugins}
              key={chartKey}
              id={chartCanvasId}
            />
          </Box>
        </Box>
      ) : (
        <Box className={classes.progressContainer}>
          <Box className={classes.chartWrapper}>
            <Doughnut
              data={chartTrinity.data}
              options={{
                ...chartTrinity.options,
                responsive: true,
                width: chartSize.width,
                height: chartSize.height,
              }}
              plugins={chartTrinity.plugins}
              key={chartKey}
              id={chartCanvasId}
            />
          </Box>
        </Box>
      )}
    </React.Fragment>
  ) : null;
}

export default MobileDashBoardTotalMonthlyCharts2;

const useStyles = makeStyles((theme) =>
  createStyles({
    progressContainer: {
      width: "auto",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: theme.typography.fontFamily,
    },
    chartWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "0.5rem",
      height: "fit-content",
      "& canvas": {
        width: "34vw !important",
        height: "34vw !important",
        maxWidth: "220px !important",
        maxHeight: "200px !important",

        "@media (max-height: 915px)": {
          width: "34vw !important",
          height: "34vw !important",
          maxWidth: "200px !important",
          maxHeight: "200px !important",
        },

        "@media (max-height: 680px)": {
          width: "120px !important",
          height: "120px !important",
          maxWidth: "220px !important",
          maxHeight: "200px !important",
        },
      },
    },
  })
);
