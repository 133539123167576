import { fetchWrapper } from "../../utils/fetchWrapper";

const createBuyRequest = async (buyRequest) => {
  try {
    await fetchWrapper.post(`/orders/coins/buy`, buyRequest);
  } catch (e) {
    throw e;
  }
};

const buyServices = {
  createBuyRequest,
};

export default buyServices;
