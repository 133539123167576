import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import BuySellPopupMobile from '../../MobileComponents/popups/BuySellPopup/BuySellPopupMobile';
import NotificationMessagePopup from '../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import { useEffect } from 'react';
import { handleGetUserDetails } from '../../../helpers/handleGetUserDetails';
import { useAppContext } from '../../../context/DataContext';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { messages } from '../../../constants/popupMessages';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { updateBucket } from '../../../helpers/update-client-bucket';
import { userService } from '../../../service/user';
import NativeCryptoBtn from '../../../assets/images/native-crypto-btn-green.svg';
import NativeBuyGoldBtn from '../../../assets/images/native-buy-gold-btn.svg';
import NativeTradeGoldBtn from '../../../assets/images/native-trade-gold-btn.svg';
import NativeAutoinvestBtn from '../../../assets/images/native-autoinvest-btn.svg';
import CryptoPopup from '../../common/Popups/CryptoPopup/CryptoPopup';
import { handleGetUserCart } from '../../../helpers/handleGetUserCart';
import NonVerifiedUserPopUp from '../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import isVerified from '../../../helpers/isVerified';

function AppActionButtons() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userDetailsData, setUserDetailsData] = useState(null);
  const [userCartData, setUserCartData] = useState(null);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [openCryptoPopup, setOpenCryptoPopup] = useState(false);
  const [cryptoPopupMessage, setCryptoPopupMessage] = useState(false);
  const [cryptoPopupStep, setCryptoPopupStep] = useState(0);
  const navigate = useNavigate();
  const isDemo = sessionStorage.getItem('isDemo') === 'true';

  const {
    isBuySellPopupOpen,
    setIsBuySellPopupOpen,
    isAppView,
    setIsCartFetched,
    setProductsBucket,
    userBrokerAccounts,
    isPortfolioLoading,
    nonVerifiedPopUpOpen,
    setNonVerifiedPopUpOpen,
  } = useAppContext();

  useEffect(() => {
    handleGetUserDetails(setUserDetailsData, setLoading);
  }, []);

  useEffect(() => {
    handleGetUserCart(setUserCartData, () => {});
  }, []);

  useEffect(() => {
    updateBucket(isAppView, userCartData, setIsCartFetched, setProductsBucket, userService?.updateShoppingCart);
  }, [userCartData]);

  const handleCryptoClicked = async () => {
    const isDemo = sessionStorage.getItem('isDemo') === 'true';

    if (isDemo) {
      navigate(routes.crypto);
      return;
    }

    if (!isPortfolioLoading) {
      if (userBrokerAccounts?.length > 0) {
        navigate(routes.crypto);
      } else {
        setCryptoPopupMessage(messages.createCryptoAccount);
        setOpenCryptoPopup(true);
        setCryptoPopupStep(1);
      }
    }
  };

  const handleTradeGoldClicked = () => {
    setIsBuySellPopupOpen(true);
  };

  function navigateToAutoInvest() {
    navigate(routes.autoInvest);
  }

  const handleBuyGoldClicked = () => {
    navigate(routes.store);
    window.scrollTo(0, 0);
  };

  const closePopups = () => {
    setErrorPopup(false);
  };

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <Box className={classes.wrapper}>
      <div className={classes.buttonsContainer}>
        <div>
          <img src={NativeCryptoBtn} alt="Crypto btn" className={classes.button} onClick={handleCryptoClicked} />
          <p className={classes.btnText}>Crypto</p>
        </div>

        <div>
          <img src={NativeBuyGoldBtn} alt="Buy gold btn" className={classes.button} onClick={handleBuyGoldClicked} />
          <p className={classes.btnText}>Buy Gold</p>
        </div>

        <div>
          <img
            src={NativeTradeGoldBtn}
            alt="Trade gold icon"
            className={classes.button}
            onClick={handleTradeGoldClicked}
          />
          <p className={classes.btnText}>Trade Gold</p>
        </div>

        <div>
          <img
            src={NativeAutoinvestBtn}
            alt="Autoinvest btn"
            className={classes.button}
            onClick={navigateToAutoInvest}
          />
          <p className={classes.btnText}>Autoinvest</p>
        </div>
      </div>
      {errorPopup && (
        <NotificationMessagePopup
          type={'error'}
          isPopupOpen={errorPopup}
          setIsPopupOpen={closePopups}
          message={errorMessage}
          handleCloseAutoInvestPopup={closePopups}
        />
      )}
      {isBuySellPopupOpen && (
        <BuySellPopupMobile isPopupOpen={isBuySellPopupOpen} setIsPopupOpen={setIsBuySellPopupOpen} />
      )}
      <LoadingBar open={loading} />
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      {openCryptoPopup && (
        <CryptoPopup
          isPopupOpen={openCryptoPopup}
          setIsPopupOpen={setOpenCryptoPopup}
          message={cryptoPopupMessage}
          setCryptoPopupMessage={setCryptoPopupMessage}
          cryptoPopupStep={cryptoPopupStep}
          setCryptoPopupStep={setCryptoPopupStep}
        />
      )}
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
        />
      )}
    </Box>
  );
}

export default AppActionButtons;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    marginBottom: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '17px',
    [theme.breakpoints.down('290')]: {
      padding: '5px',
    },
  },
  button: {
    cursor: 'pointer',
  },
  btnText: {
    margin: 'unset',
    fontSize: '10px',
    fontWeight: '400',
    fontFamily: theme.typography.fontFamily,
    color: 'black',
  },
}));
