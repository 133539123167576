import { Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import currencyFormatter from "../../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../../constants/globalconstants";
import { useAppContext } from "../../../../context/DataContext";

function VaultInfo() {
  const classes = useStyles();

  const { goldBidPriceForGroup, vaultedCoins } = useAppContext();

  const valueOfVaultedCoins = vaultedCoins
    ? vaultedCoins * goldBidPriceForGroup
    : 0;
  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.info}>
          <Typography variant="body2">COINS IN VAULTING</Typography>
          <Box className={classes.fieldValue}>{vaultedCoins}</Box>
        </Box>
        <Box className={classes.info}>
          <Typography variant="body2">VALUE OF VAULTED COINS</Typography>
          <Box className={classes.fieldValue}>
            {currencyFormatter(valueOfVaultedCoins, CURRENCY_TYPE)}
          </Box>
        </Box>
        <Box className={classes.info}></Box>
      </Box>
    </Box>
  );
}

export default VaultInfo;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "var(--white)",
    paddingTop: "0.2rem",
    marginBottom: "0.2rem",
    height: "50px",
    minHeight: "50px",
    maxHeight: "50px",
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "var( --font-size-8)",
  },
  info: {
    display: "block",
    width: "33%",
  },
  fieldValue: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "var(--gold)",
    fontSize: "var(--font-size-14)",
    fontWeight: "700",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
  },
  buttonContainer: {
    marginTop: "3px",
    justifyContent: "center",
    display: "flex",
    "& .MuiChip-root": {
      height: "16px",
    },
    "& .MuiChip-label": {
      fontSize: "var(--font-size-10)",
      paddingLeft: "5px",
      paddingRight: "5px",
      fontWeight: "700",
    },
  },
  divider: {
    marginLeft: "1.4rem",
    marginRight: "1.4rem",
    marginTop: "0.5rem",
    marginBottom: "0.3rem",
  },
}));
