import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import MobileNavigationBack from '../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack';
import { routes } from '../../../routes';
import AppLayout from '../../../components/common/Layout/AppLayout';
import CryptoAccounts from '../../../components/WebComponents/CryptoAccounts/CryptoAccounts';
import MobilePortfolioAssets from '../../../components/MobileComponents/MobilePortfolioAssets/MobilePortfolioAssets';
import MobileCryptoDashboardTable from '../../../components/MobileComponents/MobileCryptoDashboardTable/MobileCryptoDashboardTable';
import { useNavigate } from 'react-router-dom';

function AppCryptoDashboard() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleViewTransactions = () => {
    navigate(routes.cryptoTransactions);
  };
  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileNavigationBack page={'Crypto'} route={routes.dashboard}>
          <span className={classes.viewHistoryLabel} onClick={handleViewTransactions}>
            Transactions
          </span>
        </MobileNavigationBack>

        <div>
          <CryptoAccounts />
        </div>

        <MobilePortfolioAssets />

        <Box className={classes.table}>
          <MobileCryptoDashboardTable />
        </Box>
      </Box>
    </AppLayout>
  );
}

export default AppCryptoDashboard;

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  table: {
    height: '86%',
    '@media (orientation: landscape)': {
      height: '76%',
    },
  },
  actionButtons: {
    position: 'absolute',
    textAlign: 'center',
    display: 'none',
    justifyContent: 'center',
    left: 0,
    width: '100%',
    bottom: '0%',
    background: 'var(--linear-gradient-white)',
  },

  viewHistoryLabel: {
    color: '#303030',
    fontSize: '11px',
    cursor: 'pointer',
    fontWeight: '600',
    paddingTop: '0.3rem',
    textDecoration: 'underline',
  },
}));
