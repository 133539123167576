import clsx from "clsx";
import * as React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../../CustomButton/CustomButton";
import currencyFormatter from "../../../../helpers/currencyFormatter/currencyFormatter";

import PropTypes from "prop-types";
import { useAppContext } from "../../../../context/DataContext";
import { formatDateWrapper } from "../../../../helpers/dateFormatter";

import "../../../../styles/global.fonts.css";
import { Badge, Chip, DialogContent } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const InvoiceGoldPopup = () => {
  const classes = useStyles();
  const {
    isMobile,
    isAppView,
    isGoldInvoiceModalOpen,
    setIsGoldInvoiceModalOpen,
  } = useAppContext();

  const usedCoins = isGoldInvoiceModalOpen?.volume || 0;
  const city = isGoldInvoiceModalOpen?.city;
  const address = isGoldInvoiceModalOpen?.address;
  const country = isGoldInvoiceModalOpen?.country;
  const zipCode = isGoldInvoiceModalOpen?.zip_code;
  const addressString = `${address}, ${city}, ${country}, ${zipCode}`;
  const totalClientPrice = isGoldInvoiceModalOpen?.client_operation_sum || 0;
  const productList = isGoldInvoiceModalOpen?.products || [];
  const serviceFee = isGoldInvoiceModalOpen?.service_price || 0;
  const deliveryFee = isGoldInvoiceModalOpen?.deliver_price || 0;
  const currency = isGoldInvoiceModalOpen?.currency || 0;

  let price = ``;
  let total = ``;

  if (usedCoins > 0) {
    price = `<span class='goldBtxg, goldBtxgRegular'>${usedCoins}gr BTXG</span>`;
    total = `<span class='goldBtxg, goldBtxgRegular'>${usedCoins}gr BTXG</span>`;
  }

  if (isGoldInvoiceModalOpen?.fiat_price > 0) {
    if (usedCoins > 0) {
      price += ` + `;
    }

    price += `<span>
    ${currencyFormatter(
      isGoldInvoiceModalOpen?.fiat_price,
      "transactions",
      currency
    )}
    </span>`;
  }

  if (totalClientPrice > 0) {
    if (usedCoins > 0) {
      total += `<span class='blackText'> + </span>`;
    }

    total += `<span>
    ${currencyFormatter(totalClientPrice, "transactions", currency)}
    </span>`;
  }

  const handleClose = () => {
    setIsGoldInvoiceModalOpen(false);
  };

  const handleViewProductDetails = (partNumber) => {
    const isDemo = sessionStorage.getItem("isDemo");
    if (isDemo) {
      return;
    }
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative.postMessage(
        `{"Event": "Store", "Action": "${partNumber}"}`
      );
    } else {
      window.open(`${window.location.origin}/store?id=${partNumber}`);
    }
  };

  const chipLabel = isGoldInvoiceModalOpen?.status;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isGoldInvoiceModalOpen}
        className={classes.popupContainer}
      >
        {isGoldInvoiceModalOpen && (
          <div className={classes.popupContent}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
              className={classes.popupTitle}
            >
              <div className={classes.stepTitle}>Purchase History </div>
              {
                <Divider
                  sx={{ height: "28px" }}
                  orientation="vertical"
                  flexItem
                />
              }
              <div className={classes.activeStep}>
                {/* {isGoldInvoiceModalOpen?.transaction_date} */}
                {formatDateWrapper(
                  new Date(isGoldInvoiceModalOpen?.transaction_date).getTime(),
                  "long"
                )}
              </div>
            </BootstrapDialogTitle>

            <DialogContent>
              <div className={classes.container}>
                <div className={classes.orderTitle}>
                  Order {isGoldInvoiceModalOpen?.id}
                  <Chip
                    color={
                      chipLabel === "COMPLETED"
                        ? "success"
                        : chipLabel === "REJECTED"
                        ? "error"
                        : "primary"
                    }
                    sx={{ marginLeft: "8px" }}
                    label={chipLabel}
                    variant="outlined"
                  />
                </div>

                <div className={classes.bucketList}>
                  {productList &&
                    productList?.map((elm) => {
                      return (
                        <div key={elm?.id} className={classes.cartItemWrapper}>
                          {isMobile ? (
                            <>
                              <div
                                className={classes.imgWrapper}
                                onClick={() =>
                                  handleViewProductDetails(elm?.part_number)
                                }
                              >
                                <img src={elm?.image_url} />
                              </div>

                              <div
                                className={classes.mobileCartProdWrapper}
                                onClick={() =>
                                  handleViewProductDetails(elm?.part_number)
                                }
                              >
                                <span>{elm?.name}</span>

                                <span className={classes.quantityWrapper}>
                                  {elm?.quantity}x {elm?.weight}gr
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className={classes.imgWrapper}
                                onClick={() =>
                                  handleViewProductDetails(elm?.part_number)
                                }
                              >
                                <img src={elm?.image_url} />

                                <span
                                  onClick={() =>
                                    handleViewProductDetails(elm?.part_number)
                                  }
                                >
                                  {elm?.name}
                                </span>
                              </div>
                              <span className={classes.quantityWrapper}>
                                {elm?.quantity}x {elm?.weight}gr
                              </span>
                            </>
                          )}
                        </div>
                      );
                    })}
                </div>

                <div className={classes.userDetails}>
                  <div className={classes.item}>
                    <span>Address </span>
                    <span>{addressString}</span>
                  </div>
                  <div className={classes.item}>
                    <span>Price </span>
                    <span dangerouslySetInnerHTML={{ __html: price }}></span>
                  </div>
                  <div className={classes.item}>
                    <span>Service Fee </span>
                    {currencyFormatter(serviceFee, "transactions", currency)}
                  </div>
                  <div className={classes.item}>
                    <span>Delivery Fee </span>
                    {currencyFormatter(deliveryFee, "transactions", currency)}
                  </div>
                  <div className={clsx(classes.useBTXG, classes.emptyBTXG)}>
                    <div className={classes.left}></div>
                  </div>
                  <div className={classes.total}>
                    <span>Total </span>
                    <span
                      className={classes.totalValue}
                      dangerouslySetInnerHTML={{ __html: total }}
                    ></span>
                  </div>

                  <div className={classes.btnsWrapper}>
                    <CustomButton
                      height="40"
                      width="160"
                      type="black"
                      buttonText="Close"
                      onClick={handleClose}
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
          </div>
        )}
      </BootstrapDialog>
    </div>
  );
};

export default InvoiceGoldPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "700px",
      width: "100%",
      minWidth: "302px",
    },
  },
  popupContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    minHeight: "360px",
  },
  popupTitle: {
    display: "flex",
    flexDirection: "row",
  },
  stepTitle: {
    fontSize: "clamp(16px, 2vw, 20px)",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: "10px",
  },
  activeStep: {
    display: "flex",
    fontSize: "clamp(12px, 2vw, 16px)",
    fontWeight: "400",
    fontFamily: theme.typography.fontFamily,
    color: "#BFBFBF",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10px",
  },

  stepperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  mainContentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  bucketList: {
    height: "170px",
    overflowY: "auto",
  },

  cartItemWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",

    padding: "8px",
    marginBottom: "6px",
    borderRadius: "8px",
    border: "1px solid #D2D5D8",

    "& img": {
      width: "60px",
      height: "60px",
      objectFit: "contain",
    },

    [theme.breakpoints.down("768")]: {
      justifyContent: "flex-start",
    },
  },

  orderTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    fontSize: "28px",
    textAlign: "center",
    paddingBottom: "14px",
  },

  imgWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 20,

    fontWeight: 600,
    "& span": {
      cursor: "pointer",
    },
    "& img": {
      cursor: "pointer",
    },
  },

  userDetails: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "flex-end",
  },

  item: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 8,
    fontWeight: 400,

    "& span:not(#goldBtxg):first-child": {
      fontWeight: 600,
    },
  },

  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,

    fontWeight: 600,
  },

  totalValue: {
    fontSize: "clamp(16px, 2vw, 23px)",
    color: "#347362",
    fontWeight: "700",
  },

  useBTXG: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "3fr minmax(220px, 1fr)",

    color: "#A37903",

    "& div:first-child": {
      height: "5px",
      background: "linear-gradient(90deg, rgba(61, 45, 1, 0) 0%, #A37903 100%)",
    },
  },

  emptyBTXG: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "1fr",

    width: "100%",
    height: "42px",
  },

  quantityWrapper: {
    fontWeight: 500,
    whiteSpace: "nowrap",
  },

  mobileCartProdWrapper: {
    display: "flex",
    flexDirection: "column",

    paddingLeft: "8px",
    "& > span:first-child": {
      cursor: "pointer",
    },
    "& img": {
      cursor: "pointer",
    },
  },

  btnsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "100%",
    paddingTop: "10px",
  },
}));
