import React from "react";

function coinNameFormatter(coinName, numCharactersToColor, customClassName) {
  const firstPart = coinName.slice(0, -numCharactersToColor);
  const lastCharacters = coinName.slice(-numCharactersToColor);

  return (
    <React.Fragment>
      {firstPart}
      <span className={customClassName}>{lastCharacters}</span>
    </React.Fragment>
  );
}

export default coinNameFormatter;
