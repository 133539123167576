import { fetchWrapper } from "../../utils/fetchWrapper";

const getAllVaultedGold = async () => {
  return await fetchWrapper.get(``);
};

const getVault = async () => {
  try {
    return await fetchWrapper.get(``);
  } catch (error) {
    throw new Error();
  }
};

const createVault = async (vault) => {
  try {
    return await fetchWrapper.post(`/orders/vault/create`, vault);
  } catch (e) {
    throw e;
  }
};

const cancelVault = async (id) => {
  try {
    return await fetchWrapper.post(`/orders/vault/cancel`, id);
  } catch (e) {
    throw e;
  }
};

const updateVault = async () => {
  try {
    return await fetchWrapper.put();
  } catch (e) {
    throw e;
  }
};

const deleteVault = async () => {
  try {
    return await fetchWrapper.delete(``);
  } catch (e) {
    throw e;
  }
};

const serviceVaulting = {
  getAllVaultedGold,
  getVault,
  createVault,
  updateVault,
  deleteVault,
  cancelVault,
};

export default serviceVaulting;
