import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import '../../../../styles/global.fonts.css';
import { useAppContext } from '../../../../context/DataContext';
import BullionzLogo from '../../../../assets/images/bullionz-logo-coin.svg';
import BackgroundLayer from '../../../../assets/images/autoInvest-popup-background-layer-mobile.svg';
import TextField from '@mui/material/TextField';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { useState } from 'react';
import { CURRENCY_SYMBOL, NON_VERIFIED } from '../../../../constants/globalconstants';
import { GoldToMoneyConverter } from '../../../../helpers/goldToMoneyConverter';
import { handleInputKeyUp, handleInputKeyDown } from '../../../../helpers/inputValidator';
import serviceAutoInvest from '../../../../service/autoInvest';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';
import NotificationMessagePopup from '../../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import buyServices from '../../../../service/buyService';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import { messages } from '../../../../constants/popupMessages';
import { isMarketOpen } from '../../../../helpers/isMarketOpen';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import SpecialOfferIconBlack from '../../../../assets/images/special-offer-icon-black.png';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import { generateCurrencySymbol } from '../../../../helpers/generateCurrencySymbol';
import isVerified from '../../../../helpers/isVerified';

const btnValues = [1, 2, 3]; // months
const orderVolumeValues = [1, 5, 10, 20];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AutoInvestPopupMobile = ({ isPopupOpen, setIsPopupOpen }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [investingQuantity, setInvestingQuantity] = useState(0);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    goldAskPrice,
    goldAskPriceForGroup,
    proceedAutoInvestOrderClicked,
    setProceedAutoInvestOrderClicked,
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
    setNonVerifiedPopUpOpen,
  } = useAppContext();
  const [investingPeriod, setInvestingPeriod] = useState(0);

  const showSpecialValueField = goldAskPrice !== goldAskPriceForGroup;

  // standard
  const autoInvestValue = GoldToMoneyConverter(investingQuantity, goldAskPrice);

  // special
  const autoInvestValueForGroup = GoldToMoneyConverter(investingQuantity, goldAskPriceForGroup);

  const handleClose = () => {
    setIsPopupOpen(false);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    var intValue = parseInt(value);
    if (isNaN(intValue)) {
      intValue = 0;
    }
    setInvestingQuantity(intValue);
  };
  const handleChosenMonths = (months) => {
    setInvestingPeriod(months);
  };

  const isInvestButtonDisabled = () => {
    return (
      investingQuantity === '' ||
      investingQuantity === '0' ||
      investingQuantity === 0 ||
      isNaN(investingQuantity) ||
      investingPeriod === 0
    );
  };

  const handleAutoInvestClicked = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const autoInvestData = {
        volume: investingQuantity,
        periodMonths: investingPeriod,
      };
      setLoading(true);
      try {
        const autoInvest = await serviceAutoInvest.createAutoInvest(autoInvestData);
        const newBuy = {
          volume: investingQuantity,
          clientPricePerAsset: goldAskPrice,
          autoInvestmentId: autoInvest.id,
        };
        await buyServices.createBuyRequest(newBuy);
        setInvestingPeriod(0);
        setInvestingQuantity(0);
        setProceedAutoInvestOrderClicked(true);
        setPopUpMessage(messages.orderSuccessful);
        setNotificationPopupType('success');
        setLoading(false);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setPopUpMessage(customError?.message);
        setNotificationPopupType('error');
        setProceedAutoInvestOrderClicked(true);
        setInvestingPeriod(0);
        setInvestingQuantity(0);
      } finally {
        setLoading(false);
      }
    } else {
      setPopUpMessage(messages.marketClosed);
      setNotificationPopupType('error');
      setProceedAutoInvestOrderClicked(true);
      setInvestingPeriod(0);
      setInvestingQuantity(0);
    }
  };
  const handleInputFocus = () => {
    investingQuantity === 0 && setInvestingQuantity('');
  };
  const handleChosenQuantity = (value) => {
    setInvestingQuantity(value);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.popupTitle}>
            Auto Invest
          </BootstrapDialogTitle>
          <DialogContent>
            <div className={classes.buttonContainer}>
              {btnValues.map((value) => (
                <div key={value} className={classes.button}>
                  <CustomButton
                    type={investingPeriod === value ? 'monthBtnWhiteAutoInvest' : 'monthBtnSteelBlue'}
                    buttonText={value + ' M'}
                    width="100%"
                    height="100%"
                    onClick={() => handleChosenMonths(value)}
                  />
                </div>
              ))}
            </div>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipButton}>
                <CustomButton type="moreinfo" buttonText="i" tooltipTitle={tooltipContent.autoInvest} />
              </div>
            </div>
            <Box>
              <div className={classes.goldFieldContainer}>
                <div className={classes.bullionzGold}>
                  <div className={classes.logoContainer}>
                    <img src={BullionzLogo} alt="Bullionz logo" />
                  </div>
                  <div>
                    <Box className={classes.title}>Bullionz Gold</Box>
                    <Box className={classes.coin}>BTXG</Box>
                  </div>
                </div>
                <div className={classes.input}>
                  <TextField
                    onFocus={handleInputFocus}
                    autoComplete="off"
                    className={classes.customInput}
                    id="auto-invest-input"
                    value={investingQuantity}
                    size="small"
                    inputProps={{
                      maxLength: 5,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onKeyUp={handleInputKeyUp}
                    onKeyDown={handleInputKeyDown}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <Box className={classes.gr}>gr</Box>
              </div>
            </Box>
            <div className={classes.buttonContainer}>
              {orderVolumeValues.map((value) => (
                <div key={value} className={classes.button}>
                  <CustomButton
                    type={investingQuantity === value ? 'monthBtnWhiteAutoInvest' : 'monthBtnSteelBlue'}
                    buttonText={value + ' gr'}
                    width="100%"
                    height="100%"
                    onClick={() => handleChosenQuantity(value)}
                  />
                </div>
              ))}
            </div>
            <div className={classes.container}>
              <div
                style={{
                  fontSize: !showSpecialValueField && '15px',
                  fontWeight: !showSpecialValueField && '700',
                }}
                className={classes.totalValueContainer}
              >
                Value
                <div>{generateCurrencySymbol() + autoInvestValue.toFixed(2)}</div>
              </div>
              {showSpecialValueField && (
                <div className={classes.yourValueContainer}>
                  <img src={SpecialOfferIconBlack} alt="Special Offer" />
                  Your Value
                  <div>{generateCurrencySymbol() + autoInvestValueForGroup.toFixed(2)} </div>
                  <div className={classes.yourValueTooltip}>
                    <CustomButton
                      type="moreinfo"
                      buttonText="i"
                      tooltipTitle={tooltipContent.autoInvestOrderSpecialValue}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={classes.helperContainer}>
              <div className={classes.investButton}>
                <CustomButton
                  type="lightGreen"
                  disabled={isInvestButtonDisabled()}
                  buttonText="INVEST"
                  width="100%"
                  height="100%"
                  onClick={handleAutoInvestClicked}
                />
              </div>
            </div>
          </DialogContent>
        </div>
      </BootstrapDialog>
      {proceedAutoInvestOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedAutoInvestOrderClicked}
          setIsPopupOpen={setProceedAutoInvestOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={handleClose}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={loading} />
    </div>
  );
};

export default AutoInvestPopupMobile;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      maxWidth: '1000px',
      width: '100%',
      minWidth: '300px',
    },
  },
  popupContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundImage: `url(${BackgroundLayer})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    overflow: 'hidden',
  },
  popupTitle: {
    fontSize: 'var(--font-size-22)',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  },
  tooltipContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '20px',
  },
  tooltipButton: {
    position: 'relative',
    left: '7px',
    bottom: '15px',
  },
  yourValueTooltip: {
    position: 'relative',
    left: '30px',
    bottom: '20px',
  },
  goldFieldContainer: {
    display: 'flex',
    position: 'relative',
    top: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0.5px solid #D2D5D8',
    borderRadius: '3px',
    marginBottom: '7%',
    backgroundColor: 'white',
    '@media (orientation: landscape)': {
      marginBottom: '2%',
    },
  },
  bullionzGold: {
    display: 'flex',
    flex: '3',
    alignItems: 'center',
    paddingLeft: '8px',
    gap: '10px',
    color: 'black',
    fontFamily: theme.typography.fontFamily,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: '400',
    fontSize: 'var(--font-size-8)',
  },
  coin: {
    fontSize: 'var(--font-size-10)',
    fontWeight: '400',
  },
  input: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    position: 'relative',
    marginLeft: 'auto',
    width: '16%',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#4C7290',
    padding: '1.5px',
  },
  customInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input': {
      fontSize: 'var(--font-size-11)',
      fontWeight: '600',
      textAlign: 'right',
      color: 'white',
      paddingRight: '3px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
    '& .Mui-error': {
      paddingLeft: '42px',
    },
  },
  gr: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'var(--font-size-11)',
    fontWeight: '400',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    color: '#345173',
    width: '20px',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#4c7290ad',
  },
  buttonContainer: {
    position: 'relative',
    bottom: '9px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '4%',
    [theme.breakpoints.up('380')]: {
      bottom: '-2px',
    },
    '@media (orientation: landscape)': {
      justifyContent: 'center',
    },
  },
  button: {
    width: '60px',
    height: '25px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-10)',
      fontWeight: '600',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    margin: 'auto',
  },
  totalValueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'var(--font-size-12)',
    fontWeight: '400',
    marginTop: '10%',
    gap: '5px',
    '@media (orientation: landscape)': {
      marginTop: '4%',
    },
  },
  yourValueContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    fontSize: '15px',
    fontWeight: '700',
    marginTop: '2%',
    gap: '5px',
    '@media (orientation: landscape)': {
      marginTop: '4%',
    },
  },
  orderValueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 'var(--font-size-12)',
    fontWeight: '400',
    marginTop: '10%',
    color: '#666666',
    '@media (orientation: landscape)': {
      marginTop: '4%',
    },
  },
  orderValue: {
    fontSize: 'var(--font-size-22)',
    fontWeight: '600',
  },
  helperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  investButton: {
    width: '245px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: '12px',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-14) !important',
      fontWeight: '600',
    },
    '@media (orientation: landscape)': {
      marginBottom: '0px',
    },
  },
}));
