import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import App from "./App";
import { store } from './store/store'
import { Provider } from 'react-redux'
import { CryptoProvider } from "./context/CryptoContext";
import { BrowserRouter as Router } from "react-router-dom";
import { DataProvider, useAppContext } from "./context/DataContext";
import { NotificationsProvider } from "./context/NotificationsContext";


import "./index.css";
import { theme } from "./theme";

const handleSideButtonClicked = (event) => {
  if (event.button === 3 || event.button === 4) {
    event.preventDefault();
  }
};
document.addEventListener("mouseup", handleSideButtonClicked);

const root = ReactDOM.createRoot(document.getElementById("root"));

const BlurWrapper = () => {
  const {
    isMobile,
    isAppView,
    tutorialStep,
    isOnHomePage,
    isTutorialLoading,
    isBuySellPopupOpen,
    nonVerifiedPopUpOpen,
    isRedeemGoldPopupOpen,
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
  } = useAppContext();
  const isNative = isAppView;
  const isDemo = sessionStorage.getItem("isDemo") === "true";
  const hasTutorialParam =
    new URLSearchParams(window.location.search) && tutorialStep < 14;
  const showBlur =
    isNative || isMobile || hasTutorialParam
      ? tutorialStep >= 0 && tutorialStep < 15
      : tutorialStep >= 0 && tutorialStep < 13;

  return (
    !isDemo &&
    showBlur &&
    isOnHomePage &&
    !isBuySellPopupOpen &&
    !nonVerifiedPopUpOpen &&
    !isRedeemGoldPopupOpen &&
    !proceedSellOrderClicked &&
    !proceedBuyOrderClicked &&
    sessionStorage?.getItem("tutorialStep") &&
    !isTutorialLoading && <div className="backdrop"></div>
  );
};

root.render(
  // <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <NotificationsProvider>
          <DataProvider>
            <CryptoProvider>
              <BlurWrapper />
              <App />
            </CryptoProvider>
          </DataProvider>
        </NotificationsProvider>
      </Router>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
  // </React.StrictMode>
);
