import clsx from 'clsx';
import React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/material';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import BuyArrows from '../../../assets/images/TransactionsSell.svg';
import SellArrows from '../../../assets/images/sellArrows.svg';
import RedeemIcon from '../../../assets/images/redeem-icon.svg';
import RedeemStoreIcon from '../../../assets/images/redeem-store-icon.svg';

import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import { formatDateWrapper } from '../../../helpers/dateFormatter';
import { useEffect } from 'react';
import { handleGetMobileTransactions } from '../../../helpers/handleGetTransactions';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import CustomButton from '../../common/CustomButton/CustomButton';
import { tooltipContent } from '../../../constants/tooltipTexts';
import { useAppContext } from '../../../context/DataContext';
import InvoiceGoldPopup from '../../common/Popups/InvoiceGoldPopup/InvoiceGoldPopup';
import { handleGetWebCryptoTransactions } from '../../../helpers/handleGetCryptoTransactions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--mobile-grey)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { setIsGoldInvoiceModalOpen, userTradeAccounts } = useAppContext();

  let totalQuantity = 0;
  let storePrice = row?.price;
  const hasRealOrder = row?.order_id;
  const isStoreTransaction = row?.use_fiat === true;

  if (isStoreTransaction && row?.volume) {
    storePrice = row?.service_price + row?.deliver_price;
  }

  const tradeAccountsLength = userTradeAccounts?.length;

  if (row?.products) {
    totalQuantity = row?.products?.reduce((total, product) => total + product.quantity, 0);
  }

  const handleTriggerRow = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.tableRow}>
        <StyledTableCell align="center">
          <IconButton
            disableRipple
            sx={{
              cursor: 'default',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            aria-label="transaction-table-arrow"
            size="small"
          >
            {row.side === 'Buy' ? (
              <img src={BuyArrows} alt="Buy Arrow Icon" className={classes.icon} />
            ) : (
              <img src={SellArrows} alt="Sell Arrow Icon" className={classes.icon} />
            )}
          </IconButton>
        </StyledTableCell>

        <StyledTableCell component="th" scope="row" align="center">
          {row?.marketDisplayName?.split('/')?.[0]?.toUpperCase()}
        </StyledTableCell>

        <StyledTableCell component="th" scope="row" align="center">
          {formatDateWrapper(new Date(row.date).getTime(), 'long')}
        </StyledTableCell>

        <StyledTableCell align="center">{row?.requestedAmount}</StyledTableCell>

        <StyledTableCell align="center">
          {currencyFormatter(Number(row?.price || 0), 'transactions', 'USD')}
        </StyledTableCell>

        <StyledTableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={handleTriggerRow}>
            {open ? (
              <KeyboardArrowUpIcon style={{ color: '#347362' }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: '#347362' }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <StyledTableRow className={classes.tableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell align="center">Account</StyledTableCell>

                  <StyledTableCell align="center">
                    &nbsp; &nbsp; &nbsp; &nbsp; Status &nbsp; &nbsp; &nbsp; &nbsp;
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell align="center">{row.brokerAccountId}</StyledTableCell>

                  <StyledTableCell align="center">{row?.status?.toUpperCase()}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function CryptoTransactionsHistoryTable() {
  const classes = useStyles();
  const tableEl = useRef();
  const [loading, setLoading] = useState(true);
  const [dashBoardTransactionsHistory, setDashBoardTransactionsHistory] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [allPages, setAllPages] = useState(1);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(page <= allPages);
  const [error, setError] = useState(false);
  const { selectedCryptoAccount } = useAppContext();
  const isSeeAll = true;

  const pageSize = 22;
  const traderAccountId = selectedCryptoAccount?.brokerAccountId;

  useEffect(() => {
    if (traderAccountId) {
      handleGetWebCryptoTransactions(
        setDashBoardTransactionsHistory,
        traderAccountId,
        setAllPages,
        setError,
        pageSize,
        page,
        setLoading,
      );
    }
  }, [page, traderAccountId]);

  useEffect(() => {
    if (dashBoardTransactionsHistory.length) {
      setTransactions((prev) => [...prev, ...dashBoardTransactionsHistory]);
    }
  }, [dashBoardTransactionsHistory]);

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          if (page < allPages) {
            setPage((prev) => prev + 1);
          }
          setLoading(false);
          setHasMore(page < allPages);
          resolve();
        }, 1500),
      );
    };
    setLoading(true);
    if (page === allPages) {
      setLoading(false);
      return;
    }
    loadItems();
  }, [dashBoardTransactionsHistory, page]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener('scroll', scrollListener);
    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  return (
    <Box className={classes.container}>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ margin: 'auto', maxHeight: '100%' }}
        ref={tableEl}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead className={classes.tableHead}>
            <StyledTableRow className={classes.tableRow}>
              <TableCell className={isSeeAll ? classes.rows : undefined} sx={{ width: '30px' }}></TableCell>

              <TableCell className={isSeeAll ? classes.rows : undefined} sx={{ width: '60px' }} align="center">
                Asset
              </TableCell>

              <TableCell className={isSeeAll ? classes.rows : classes.date} sx={{ minWidth: '120px' }} align="center">
                Date
              </TableCell>

              <TableCell className={isSeeAll ? classes.rows : classes.quantity} sx={{ width: '50%' }} align="center">
                Qty
              </TableCell>

              <TableCell className={isSeeAll ? classes.rows : undefined} sx={{ width: '50%' }} align="center">
                Price
              </TableCell>

              <TableCell
                className={isSeeAll ? classes.rows : undefined}
                sx={{ width: '30px' }}
                align="center"
              ></TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody className={classes.containerTableBody}>
            {!transactions.length && !loading ? (
              <React.Fragment>
                <TableRow>
                  <TableCell colSpan={5} style={{ whiteSpace: 'nowrap' }} align="center">
                    No recent transactions
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ) : page === allPages && !loading ? (
              <React.Fragment>
                {transactions.map((row) => (
                  <Row key={row.orderId} row={row} />
                ))}
              </React.Fragment>
            ) : (
              transactions.map((row) => <Row key={row.orderId} row={row} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InvoiceGoldPopup />
      <LoadingBar open={loading} />
    </Box>
  );
}

export default CryptoTransactionsHistoryTable;

const useStyles = makeStyles((theme) => ({
  containerTableBody: {
    position: 'relative',
    overflowY: 'auto',
  },
  headerCell: {
    backgroundColor: 'var(--mobile-grey)',
    zIndex: 2,
    height: '36px',
  },
  container: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      fontSize: 'clamp(var(--font-size-11), var(--font-size-12), var(--font-size-13)) !important',
    },
    height: '100%',
    '@media (orientation: landscape)': {
      maxHeight: '100%',
    },
  },
  tableHead: {
    position: 'relative',
  },
  tableRow: {
    '& .MuiTableCell-root': {
      padding: '0px',
      maxHeight: '30px',
    },
    '& th': {
      fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
    },
  },
  firstStyledTableCell: {
    textAlign: 'center',
    [theme.breakpoints.up('500')]: {
      textAlign: 'center',
    },
  },
  closeButton: {
    color: 'var(--black)',
    borderRadius: '24px',
    border: '1px solid var(--red)',
    width: '70%',
    maxWidth: '5rem',
    minHeight: '15px',
    height: '1.7rem',
    marginBottom: '1.5rem',
    backgroundColor: 'var(--white)',
    fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
  },
  actionButtons: {
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    width: '100%',
    top: '65%',
  },
  sellArrows: {
    maxWidth: '0.9rem',
    maxHeight: '0.9rem',
  },
  storeCell: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  icon: {
    width: '16px',
    height: '16px',
  },
}));
