import * as React from 'react';
import { makeStyles } from '@mui/styles';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import { Box, Pagination, Tab, Tabs, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/index.js';
import CustomButton from '../../common/CustomButton/CustomButton';
import BuyArrows from '../../../assets/images/TransactionsSell.svg';
import SellArrows from '../../../assets/images/sellArrows.svg';
import RedeemIcon from '../../../assets/images/redeem-icon.svg';
import RedeemStoreIcon from '../../../assets/images/redeem-store-icon.svg';
import { formatDateWrapper } from '../../../helpers/dateFormatter';
import { useEffect } from 'react';
import { handleGetWebTransactions } from '../../../helpers/handleGetTransactions';
import { useAppContext } from '../../../context/DataContext';
import IButton from '../../common/IButton/IButton';
import { tooltipContent } from '../../../constants/tooltipTexts.js';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup.js';
import InvoiceGoldPopup from '../../common/Popups/InvoiceGoldPopup/InvoiceGoldPopup.jsx';
import clsx from 'clsx';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="span" component="span">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const TransactionsCard = ({ isSeeAll = true, containerClassName }) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [dashBoardTransactionsHistory, setDashBoardTransactionsHistory] = useState([]);
  const [allPages, setAllPages] = useState();
  const [page, setPage] = useState(1);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
    proceedRedeemOrderClicked,
    setIsGoldInvoiceModalOpen,
    IsSocketReconnecting,
    userTradeAccounts,
  } = useAppContext();

  const tradeAccountsLength = userTradeAccounts?.length;

  const classes = useStyles({ isSeeAll, tradeAccountsLength });
  const [error, setError] = useState(false);

  const isDemoValue = sessionStorage.getItem('isDemo');
  const isDemo = isDemoValue === 'true';

  useEffect(() => {
    if (!isSeeAll) {
      handleGetWebTransactions(setDashBoardTransactionsHistory, page, setAllPages, setError, 10);
    } else {
      handleGetWebTransactions(setDashBoardTransactionsHistory, 0, setAllPages, setError, 10);
    }
  }, [page, IsSocketReconnecting, proceedBuyOrderClicked, proceedSellOrderClicked, proceedRedeemOrderClicked]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function navigateToTransactions(e) {
    e.preventDefault();
    navigate(routes.transactions);
    window.scrollTo(0, 0);
  }

  function navigateToDashboard() {
    setPage(0);
    navigate(routes.dashboard);
    window.scrollTo(0, 0);
  }

  const formatCurrencySymbol = (currency) => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return currency;
    }
  };

  return (
    <Box className={classes.transactionsContainerWrapper}>
      <Box className={containerClassName}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="secondary"
              className={isSeeAll ? classes.tabsTitle : undefined}
            >
              <Tab
                className={isSeeAll ? classes.transactionsLabel : classes.transactionsLabelSeeAll}
                label={'TRANSACTIONS'}
              />
              {isSeeAll && (
                <Tab
                  className={classes.seeAll}
                  href="/vaulting-history"
                  onClick={navigateToTransactions}
                  label="See All"
                  disabled={isDemo}
                ></Tab>
              )}
              {isSeeAll && <IButton tooltipTitle={tooltipContent.transactionsTable} />}
            </Tabs>
            {!isSeeAll && <IButton tooltipTitle={tooltipContent.transactionsTable} />}
            <Divider className={classes.headerDivider} />
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className={isSeeAll ? classes.tableContainer : classes.tableContainerSeeAll}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={isSeeAll ? classes.rows : undefined}></TableCell>
                      <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                        Transaction
                      </TableCell>
                      {!isSeeAll && tradeAccountsLength > 1 && (
                        <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                          Account
                        </TableCell>
                      )}
                      <TableCell className={isSeeAll ? classes.rows : classes.date} align="center">
                        Date
                      </TableCell>
                      <TableCell className={isSeeAll ? classes.rows : classes.quantity} align="center">
                        Quantity
                      </TableCell>
                      {!isSeeAll && (
                        <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                          Amount
                        </TableCell>
                      )}
                      <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                        Price
                      </TableCell>
                      <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {dashBoardTransactionsHistory.length > 0 ? (
                      dashBoardTransactionsHistory.map((row) => {
                        let totalQuantity = 0;
                        let storePrice = row?.price;
                        const hasRealOrder = row?.order_id;
                        const isStoreTransaction = row?.use_fiat === true;

                        if (isStoreTransaction && row?.volume) {
                          storePrice = row?.service_price + row?.deliver_price;
                        }

                        if (row?.products) {
                          totalQuantity = row?.products?.reduce((total, product) => total + product.quantity, 0);
                        }
                        return (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            key={row.id}
                          >
                            <TableCell
                              className={isSeeAll ? classes.rows : undefined}
                              align="left"
                              component="th"
                              scope="row"
                            >
                              {row.productItemCode === 'P001' ? (
                                <img src={BuyArrows} alt="Buy Arrow Icon" />
                              ) : row.productItemCode === 'P004' ? (
                                <img
                                  src={isStoreTransaction ? RedeemStoreIcon : RedeemIcon}
                                  alt="Redeem Icon"
                                  className={classes.icon}
                                />
                              ) : (
                                <img src={SellArrows} alt="Sell Arrow Icon" className={classes.icon} />
                              )}
                            </TableCell>
                            <TableCell
                              className={clsx(
                                isStoreTransaction && hasRealOrder && classes.storeCell,
                                isSeeAll && classes.rows,
                              )}
                              align="left"
                              onClick={() => {
                                if (isStoreTransaction && hasRealOrder) {
                                  setIsGoldInvoiceModalOpen(row);
                                }
                              }}
                            >
                              {row.id}
                            </TableCell>
                            {!isSeeAll && tradeAccountsLength > 1 && (
                              <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                                {row.tradeAccountId}
                              </TableCell>
                            )}
                            <TableCell className={isSeeAll ? classes.dateRows : classes.dateRows} align="left">
                              {!isSeeAll
                                ? formatDateWrapper(new Date(row.date).getTime(), 'long')
                                : formatDateWrapper(new Date(row.date).getTime(), 'short')}
                            </TableCell>
                            <TableCell className={isSeeAll ? classes.rows : undefined} align="center">
                              {isStoreTransaction ? totalQuantity || '-' : row?.volume || '-'}
                              {!isStoreTransaction && 'gr'}
                            </TableCell>
                            {!isSeeAll && (
                              <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                              {row.productItemCode === 'P001' &&
                                `${formatCurrencySymbol(row.currency)}${(row.price * row.volume).toFixed(2)}`}
                              {row.productItemCode === 'P005' &&
                                `${formatCurrencySymbol(row.currency)}${(row.price * row.volume).toFixed(2)}`}
                              {row.productItemCode === 'P004' &&
                                `${formatCurrencySymbol(row.currency)}${(row.client_operation_sum ? row.client_operation_sum : 0).toFixed(2)}`}
                            </TableCell>
                            )}
                            <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                              {`${formatCurrencySymbol(row.currency)}${row?.price.toFixed(2)}`}
                            </TableCell>
                            <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                              {row.status}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          No Recent Transactions
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
        </Box>
      </Box>

      <InvoiceGoldPopup />

      {!isSeeAll && (
        <Box className={classes.buttonsWrapper}>
          <Box>
            <Box className={classes.btn}>
              <CustomButton
                type="black"
                width="100%"
                height="24px"
                onClick={navigateToDashboard}
                buttonText="&#9664;&nbsp;&nbsp;Back to Dashboard"
              ></CustomButton>
            </Box>
          </Box>
          {dashBoardTransactionsHistory.length > 0 && (
            <Box>
              <Box className={classes.paginationRoot}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={allPages}
                  page={page}
                  onChange={(event, newPage) => setPage(newPage)}
                />
              </Box>
            </Box>
          )}
          <Box></Box>
          <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
        </Box>
      )}
    </Box>
  );
};

export default TransactionsCard;

const useStyles = makeStyles((theme) => ({
  transactionsContainerWrapper: {
    height: '100%',
  },
  tableContainer: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    '& .MuiTableCell-root': {
      fontWeight: '600',
      fontSize: '16px',
      [theme.breakpoints.down('1680')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('1520')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down('1380')]: {
        fontSize: '10px',
      },
    },
    '& .MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },
  rows: (props) => ({
    paddingLeft: '8px',
    [theme.breakpoints.down('1380')]: {
      paddingLeft: '5px',
    },
  }),
  dateRows: (props) => ({
    paddingLeft: '8px',
    [theme.breakpoints.down('1380')]: {
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('950')]: {
      paddingLeft: props.tradeAccountsLength ? '0px' : '',
      paddingRight: props.tradeAccountsLength ? '0px' : '',
      fontSize: props.tradeAccountsLength > 1 ? 'var(--font-size-14)' : '',
    },
  }),
  tableContainerSeeAll: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    '& .MuiTableCell-root': {
      fontWeight: '600',
      fontSize: 'var(--font-size-18)',
      [theme.breakpoints.down('1400')]: {
        fontSize: 'var(--font-size-17)',
      },
      [theme.breakpoints.down('1000')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('950')]: {
        paddingLeft: '0px',
        paddingRight: '0px',
        fontSize: 'var(--font-size-14)',
      },
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      color: '#767676',
    },
  },
  tabsTitle: {
    minHeight: '7px',
    [theme.breakpoints.down('1200')]: {
      height: '2.5rem',
    },
  },
  date: {
    width: '10%',
  },
  transactionsLabel: {
    height: 20,
    fontSize: 'var(--font-size-18)',
    paddingLeft: '24px',
    fontWeight: '600',
    color: 'var(--black) !important',
    fontFamily: 'Poppins',
    [theme.breakpoints.down('1645')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('1465')]: {
      fontSize: 'var(--font-size-14)',
    },
    [theme.breakpoints.down('1290')]: {
      fontSize: 'var(--font-size-12)',
    },
  },
  transactionsLabelSeeAll: {
    height: 20,
    fontSize: 'var(--font-size-18)',
    paddingLeft: '24px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    color: 'var(--black) !important',
  },
  seeAll: {
    textDecoration: 'none',
    color: 'var(--black)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: '1rem',
    opacity: 1,
    fontSize: 'var(--font-size-14)',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1465')]: {
      fontSize: 'var(--font-size-10)',
    },
  },
  headerDivider: {
    position: 'absolute',
    width: '100%',
  },
  paginationRoot: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingBottom: '3%',
    '& .MuiPagination-ul': {
      color: 'white',
      flexWrap: 'nowrap',
      '& li:first-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > button::after': {
          marginLeft: '2px',
          content: "'Prev'",
        },
      },
      '& li:last-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > button::before': {
          marginRight: '2px',
          content: "'Next'",
        },
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'var(--black)',
      color: 'var(--white)',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },

  storeCell: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  btn: {
    textAlign: 'left',
    width: '12rem',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  icon: {
    maxWidth: '0.9rem',
    maxHeight: '0.9rem',
  },
}));
