import { Button } from '@mui/material';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAppContext } from '../../../../context/DataContext';
import currencyFormatter from '../../../../helpers/currencyFormatter/currencyFormatter';
import { ReactComponent as CartIcon } from '../../../../assets/images/cart-icon-new.svg';
import { ReactComponent as ProductNotFoundIcon } from '../../../../assets/images/product-not-found-icon.svg';
import { useEffect, useState } from 'react';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import clsx from 'clsx';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';

export const ListView = ({
  products,
  isLoading,
  handleItemClick,
  handleAddToCard,
  setIsAddToCardTriggered,
  listWrapperRef,
}) => {
  const classes = useStyles();
  const { isAppView, isMobile } = useAppContext();
  const [data, setData] = useState(products);

  const isWeb = !isAppView && !isMobile;

  const isDemo = sessionStorage.getItem('isDemo');

  useEffect(() => {
    setData(products);
  }, [products]);

  return (
    <div
      className={clsx(isAppView || isMobile ? classes.listItemsWrapperNative : classes.listItemsWrapper)}
      ref={listWrapperRef}
    >
      {/* <SkeletonTemplate isMobile={isMobile} /> */}
      {isLoading && <LoadingBar open={isLoading} />}

      {!isLoading && !data?.length && <NotFound />}

      {data &&
        data?.map((prod) =>
          isWeb ? (
            <ListItem
              item={prod}
              isWeb={isWeb}
              key={prod?.id}
              handleAddToCard={handleAddToCard}
              onClick={(e) => {
                handleItemClick(prod);
              }}
              setIsAddToCardTriggered={setIsAddToCardTriggered}
            />
          ) : (
            <>
              <ListItemMobile
                item={prod}
                isWeb={isWeb}
                key={prod?.id}
                handleAddToCard={handleAddToCard}
                onClick={(e) => {
                  handleItemClick(prod);
                }}
                setIsAddToCardTriggered={setIsAddToCardTriggered}
              />
            </>
          ),
        )}
    </div>
  );
};

const NotFound = () => {
  const classes = useStyles();
  const { isAppView } = useAppContext();

  return (
    <div className={classes.emptyCartWrapper}>
      <span className={classes.descriptionEmptyScreen}>No products found</span>

      <ProductNotFoundIcon />
    </div>
  );
};

const SkeletonTemplate = ({ isMobile }) => {
  const classes = useStyles();
  let arr = Array.from(Array(6).keys());

  return (
    <>
      {arr.map((idx) => {
        return (
          <div key={idx} className={classes.skeletonItem}>
            <Skeleton width="100%" variant="rounded" height={isMobile ? 128 : 166} />
          </div>
        );
      })}
    </>
  );
};

const ListItem = ({ item, isWeb, onClick, handleAddToCard, setIsAddToCardTriggered }) => {
  const classes = useStyles();
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const addToCard = (event) => {
    event.stopPropagation(); // Prevents event bubbling

    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);

    if (isDemo) {
      return;
    }

    event.preventDefault();

    handleAddToCard(item, 1);

    setIsAddToCardTriggered(true);
  };

  let currencyInLocalStorage = localStorage.getItem('currency');

  return (
    <div className={classes.listItemWrapper} onClick={onClick}>
      <div className={classes.listItemWrapperLeft}>
        <img src={item?.images?.[0]} alt={item?.name} />
      </div>

      <div className={classes.listItemWrapperMiddle}>
        <div className={classes.details}>
          <p className={classes.name}>{item?.name}</p>

          <p className={classes.weight}>Weight {item?.weight}gr</p>

          <span className={classes.availability}>{!isWeb && `${item?.quantity} Available in stock`}</span>
        </div>

        <div className={classes.price}>
          <span>{currencyFormatter(item?.prices?.[currencyInLocalStorage] || 0)}</span>
        </div>
      </div>

      <div className={classes.right}>
        <span />

        <div className={classes.cartBtnWrapper}>
          <Button className={classes.blackButton} onClick={addToCard}>
            {isWeb ? 'ADD TO CART' : <CartIcon className={classes.cartIcon} />}
          </Button>
        </div>

        <span className={classes.availability}>{isWeb && `${item?.quantity} Available in stock`}</span>
      </div>
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
    </div>
  );
};

const ListItemMobile = ({ item, isWeb, onClick, handleAddToCard, setIsAddToCardTriggered }) => {
  const classes = useStyles();
  const currencyInLocalStorage = localStorage.getItem('currency');

  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const addToCard = (event) => {
    event.stopPropagation(); // Prevents event bubbling
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);

    if (isDemo) {
      return;
    }

    handleAddToCard(item, 1);

    setIsAddToCardTriggered(true);
  };

  return (
    <div className={classes.listItemWrapperMobile} onClick={onClick}>
      <div className={classes.listItemWrapperLeft}>
        <img src={item?.images?.[0]} alt={item?.name} />
      </div>
      <div className={classes.listItemWrapperRight}>
        <div className={classes.upperPart}>
          <p className={classes.nameMobile}>{item?.name}</p>

          <span className={classes.availability}>{item?.quantity} Available in stock</span>
        </div>

        <div className={classes.footerItem}>
          <div className={classes.priceMobile}>
            <span>{currencyFormatter(item?.prices?.[currencyInLocalStorage] || 0, 'USD')}</span>
          </div>
          <Button className={classes.blackButton} onClick={addToCard}>
            {isWeb ? 'ADD TO CART' : <CartIcon className={classes.cartIcon} />}
          </Button>
        </div>
      </div>
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  listItemsWrapper: {
    overflowY: 'auto',
    height: 'calc(100dvh - 326px)',

    [theme.breakpoints.down('768')]: {
      height: 'calc(100dvh - 150px)',
    },
  },

  listItemsWrapperNative: {
    overflowY: 'auto',
    height: 'calc(100dvh - 222px)',
    overflowAnchor: 'none',
  },

  listItemWrapper: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr 1fr',

    height: '150px',
    cursor: 'pointer',
    paddingBlock: '8px',
    borderBottom: '1px solid grey',

    '& img': {
      width: '100%',
      height: '150px !important',
      objectFit: 'contain',
    },
  },

  blackButton: {
    backgroundColor: 'var(--black)',
    borderRadius: '40px',
    fontSize: '14px',
    width: '180px',
    color: 'var(--white)',
    border: 0,
    cursor: 'pointer',
    textAlign: 'left',
    boxShadow: '0px 4.9726881980896px 0px 0px #76767633',
    '&:hover': {
      backgroundColor: 'var(--black)',
    },
    [theme.breakpoints.down(900)]: {
      '& .MuiTypography-button': {
        fontSize: '12px !important',
      },
      width: '150px',
    },
    [theme.breakpoints.down(768)]: {
      width: 'auto',
    },
    '&.Mui-disabled': {
      background: '#eaeaea',
      color: '#c0c0c0',
    },
    opacity: 1,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },

  listItemWrapperMobile: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',

    height: '120px',
    cursor: 'pointer',
    paddingInline: '8px',
    paddingBlock: '4px',
    borderBottom: '1px solid grey',
  },

  upperPart: {
    textAlign: 'left',
  },

  listItemWrapperLeft: {
    cursor: 'pointer',
    '& img': {
      width: '100%',
      height: '120px',
      objectFit: 'contain',
      cursor: 'pointer',
    },
  },

  listItemWrapperRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    paddingLeft: '8px',
    paddingTop: '4px',
    paddingBottom: '20px',
  },

  footerItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    width: '100%',
    paddingTop: '4px',
  },

  listItemWrapperMiddle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    textAlign: 'left',
    paddingTop: '8px',
    paddingBottom: '20px',

    '& p': {
      margin: 0,
    },
  },

  name: {
    margin: 0,
    color: 'gray',
    fontWeight: 500,
    fontSize: '16px',
    lineClamp: 1,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  nameMobile: {
    margin: 0,
    color: 'gray',
    fontSize: '14px',
    paddingBottom: '4px',
    // display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '16px', // Adjust as needed
    maxHeight: '32px', // Approximately 2 lines of text
    display: '-webkit-box',
  },

  price: {
    margin: 0,
    fontWeight: 600,
  },

  weight: {
    color: 'gray',
    fontSize: '14px',
  },

  priceMobile: {
    margin: 0,
    fontWeight: 600,
    paddingRight: '6px',
    fontSize: 'clamp(0.75, 30px, 1rem)',
  },

  right: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridTemplateRows: '1fr 1fr 1fr',
  },

  availability: {
    fontWeight: 500,
    color: '#48D0A4',
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    lineHeight: '16px', // Adjust as needed
    maxHeight: '16px', // Approximately 2 lines of text
    display: '-webkit-box',
  },

  cartBtnWrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'flex-end',

    paddingRight: 20,
  },

  cartIcon: {
    stroke: 'white',
    '& path': {
      stroke: 'white',
    },
  },

  skeletonItem: {
    // paddingInline: 14,
    borderBottom: '1px solid grey',
  },

  emptyCartWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 40,

    [theme.breakpoints.down('678')]: {
      gap: 24,
    },
  },

  descriptionEmptyScreen: {
    fontSize: '32px',
    paddingTop: '20px',

    [theme.breakpoints.down('678')]: {
      fontSize: '22px',
    },
  },
}));
