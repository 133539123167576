import React from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SeeAll from '../SeeAll/SeeAll';
import { makeStyles } from '@mui/styles';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import { useState } from 'react';
import clsx from 'clsx';
import { handleGetNewsFeedAccessToken } from '../../../helpers/handleGetAccessTokenNewsFeed';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import CustomButton from '../../common/CustomButton/CustomButton';
import { useAppContext } from '../../../context/DataContext';

const intrument = 'GC:FUTURES,ETHUSD:CRYPTO,BTCUSD:CRYPTO';

function SeeAllNews() {
  const isMobileView = window.innerWidth < 768;
  const appView = sessionStorage.getItem('isNative');
  const { isMobile } = useAppContext();

  const navigate = useNavigate();
  const classes = useStyles({ isMobileView });
  const [loading, setLoading] = useState(false);
  const [newsToken, setNewsToken] = useState('');
  // State to track the selected tab
  const [selectedTab, setSelectedTab] = useState('news');
  const [error, setError] = useState(false);
  const script = document.createElement('script');
  script.src = 'https://global.tradingcentral.com/widgets/client/607/latest/package.js';
  script.async = true;

  useEffect(() => {
    function handleResize() {
      handleGetNewsFeedAccessToken(setNewsToken, setLoading, setError);
      document.body.appendChild(script);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!newsToken) {
    return <div></div>;
  }

  const tabsSection1Conf = [
    {
      label: 'Overall Mentions',
      value: 0,
    },

    {
      label: 'Sources',
      value: 1,
    },
  ];

  const tabsSection3Conf = [
    {
      label: 'Sentiment',
      value: 2,
    },

    {
      label: 'Subjectivity',
      value: 3,
    },

    {
      label: 'Confidence',
      value: 4,
    },
  ];

  function navigateToDashboard() {
    navigate(routes.dashboard);
    window.scrollTo(0, 0);
  }

  return (
    <SeeAll>
      <Box className={classes.container}>
        {isMobileView || appView ? (
          <TwoTabSelector
            newsToken={newsToken}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabsSection1Conf={tabsSection1Conf}
            tabsSection3Conf={tabsSection3Conf}
            isMobile={isMobile}
          />
        ) : (
          <>
            <div className={classes.heading}>
              <span className={classes.subTitle}>Market news</span>
              <span className={classes.subTitle}>Market buzz</span>
            </div>

            <Box className={classes.main}>
              <div className={isMobileView ? classes.mobileLeft : classes.left}>
                {newsToken && (
                  <tc-news-feed
                    token={newsToken}
                    lang="en"
                    height="1000px"
                    instrument={intrument}
                    base-color="#333333"
                    background-color="#FFFFFF"
                    surface-color="#FAFAFA"
                    primary-color="#0252AB"
                    bearish-color="#D10000"
                    bullish-color="#12823E"
                    neutral-color="#2775CE"
                    article-lang="”en”"
                    news-color="#0360DD"
                    social-color="#13CA60"
                    display-inside="true"
                  />
                )}
              </div>

              <div className={isMobileView ? classes.mobileRight : classes.right}>
                <div className={clsx(classes.section, classes.section4)}>
                  <p className={classes.title}>History</p>

                  <div className={classes.widgetWrapper}>
                    <tc-sentiment-history
                      chart-color="#2775CE"
                      token={newsToken}
                      lang="en"
                      width="150px"
                      instrument="GC:FUTURES"
                      base-color="#333333"
                      background-color="#FFFFFF"
                      surface-color="#FAFAFA"
                      primary-color="#0252AB"
                      bearish-color="#D10000"
                      bullish-color="#12823E"
                      neutral-color="#2775CE"
                      article-lang="”en”"
                      news-color="#0360DD"
                      social-color="#13CA60"
                      display-inside="true"
                    />
                  </div>
                </div>

                <div className={clsx(classes.section, classes.section1)}>
                  <TabsSelector data={tabsSection1Conf} defaultValue={0} token={newsToken} />
                </div>

                <div className={clsx(classes.section, classes.section2)}>
                  <p className={classes.title}>Sentiment Signal</p>

                  <div className={classes.widgetWrapper}>
                    <tc-sentiment-trend
                      token={newsToken}
                      lang="en"
                      instrument="GC:FUTURES"
                      base-color="#333333"
                      background-color="#FFFFFF"
                      surface-color="#FAFAFA"
                      primary-color="#0252AB"
                      bearish-color="#D10000"
                      bullish-color="#12823E"
                      neutral-color="#2775CE"
                      article-lang="”en”"
                      news-color="#0360DD"
                      social-color="#13CA60"
                      display-inside="true"
                    />
                  </div>
                </div>

                <div className={clsx(classes.section, classes.section3)}>
                  <TabsSelector data={tabsSection3Conf} defaultValue={0} token={newsToken} />
                </div>
              </div>
              <LoadingBar open={loading} />
            </Box>
          </>
        )}

        {!isMobileView && !appView && (
          <Box className={classes.backBtnWrapper}>
            <CustomButton
              type="black"
              width="100%"
              height="24px"
              onClick={navigateToDashboard}
              buttonText="&#9664;&nbsp;&nbsp;Back to Dashboard"
            ></CustomButton>
          </Box>
        )}
      </Box>
    </SeeAll>
  );
}

export default SeeAllNews;

const TabsSelector = ({ data, defaultValue = '', token }) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const handleChange = (elementValue) => {
    setValue(elementValue);
  };

  const showComponentDependingOnValue = (val, token) => {
    switch (val) {
      case 0:
        return (
          <tc-news-volume
            token={token}
            lang="en"
            height="1000px"
            instrument="GC:FUTURES"
            base-color="#333333"
            background-color="#FFFFFF"
            surface-color="#FAFAFA"
            primary-color="#0252AB"
            bearish-color="#D10000"
            bullish-color="#12823E"
            neutral-color="#2775CE"
            article-lang="”en”"
            news-color="#0360DD"
            social-color="#13CA60"
            display-inside="true"
          />
        );

      case 1:
        return (
          <tc-news-split
            token={token}
            lang="en"
            height="1000px"
            instrument="GC:FUTURES"
            base-color="#333333"
            background-color="#FFFFFF"
            surface-color="#FAFAFA"
            primary-color="#0252AB"
            bearish-color="#D10000"
            bullish-color="#12823E"
            neutral-color="#2775CE"
            article-lang="”en”"
            news-color="#0360DD"
            social-color="#13CA60"
          />
        );

      case 2:
        return (
          <tc-sentiment-score
            token={token}
            lang="en"
            height="1000px"
            instrument="GC:FUTURES"
            base-color="#333333"
            background-color="#FFFFFF"
            surface-color="#FAFAFA"
            primary-color="#0252AB"
            bearish-color="#D10000"
            bullish-color="#12823E"
            neutral-color="#2775CE"
            article-lang="”en”"
            news-color="#0360DD"
            social-color="#13CA60"
            display-inside="true"
          />
        );

      case 3:
        return (
          <tc-sentiment-subjectivity
            token={token}
            lang="en"
            height="1000px"
            instrument="GC:FUTURES"
            base-color="#333333"
            background-color="#FFFFFF"
            surface-color="#FAFAFA"
            primary-color="#0252AB"
            bearish-color="#D10000"
            bullish-color="#12823E"
            neutral-color="#2775CE"
            article-lang="”en”"
            news-color="#0360DD"
            social-color="#13CA60"
            display-inside="true"
          />
        );

      case 4:
        return (
          <tc-sentiment-confidence
            token={token}
            lang="en"
            height="1000px"
            instrument="GC:FUTURES"
            base-color="#333333"
            background-color="#FFFFFF"
            surface-color="#FAFAFA"
            primary-color="#0252AB"
            bearish-color="#D10000"
            bullish-color="#12823E"
            neutral-color="#2775CE"
            article-lang="”en”"
            news-color="#0360DD"
            social-color="#13CA60"
            display-inside="true"
          />
        );

      default:
        return <h1>Undefined</h1>;
    }
  };

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.tabsWrapper}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {data?.map((elm) => {
              const isSelected = value === elm?.value;
              return (
                <Tab
                  key={elm.label}
                  className={clsx(classes.tab, isSelected && classes.activeTab)}
                  onClick={() => handleChange(elm.value)}
                  label={elm.label}
                  disableRipple
                  disableTouchRipple
                />
              );
            })}
          </Tabs>
        </Box>

        {showComponentDependingOnValue(value, token)}
      </Box>
    </div>
  );
};

const TwoTabSelector = ({ selectedTab, setSelectedTab, newsToken, isMobile }) => {
  const classes = useStyles();

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className={classes.mobileWrapper}>
      <div className={classes.headingTabsWrapper}>
        <span
          className={clsx(classes.headingTab, selectedTab === 'news' && classes.headingTabActive)}
          onClick={() => handleTabClick('news')}
        >
          MARKET NEWS
        </span>
        <span
          className={clsx(classes.headingTab, selectedTab === 'buzz' && classes.headingTabActive)}
          onClick={() => handleTabClick('buzz')}
        >
          MARKET BUZZ
        </span>
      </div>

      <div className={classes.tabsDivider} />

      <>
        {selectedTab === 'news' && (
          <div className={classes.mobileRight}>
            {newsToken && (
              <tc-news-feed
                token={newsToken}
                lang="en"
                height="1000px"
                instrument={intrument}
                base-color="#333333"
                background-color="#FFFFFF"
                surface-color="#FAFAFA"
                primary-color="#0252AB"
                bearish-color="#D10000"
                bullish-color="#12823E"
                neutral-color="#2775CE"
                article-lang="”en”"
                news-color="#0360DD"
                social-color="#13CA60"
                display-inside="true"
              />
            )}
          </div>
        )}
        {selectedTab === 'buzz' && (
          <div className={classes.mobileRight}>
            <div className={clsx(classes.section, classes.section4)}>
              <p className={classes.title}>History</p>

              <div className={classes.widgetWrapper}>
                <tc-sentiment-history
                  chart-color="#2775CE"
                  token={newsToken}
                  lang="en"
                  width="150px"
                  instrument="GC:FUTURES"
                  base-color="#333333"
                  background-color="#FFFFFF"
                  surface-color="#FAFAFA"
                  primary-color="#0252AB"
                  bearish-color="#D10000"
                  bullish-color="#12823E"
                  neutral-color="#2775CE"
                  article-lang="”en”"
                  news-color="#0360DD"
                  social-color="#13CA60"
                  display-inside="true"
                />
              </div>
            </div>

            <div className={clsx(classes.section, classes.section1)}>
              <p className={classes.title}>Overall Methods</p>

              <div className={classes.widgetWrapper}>
                <tc-news-volume
                  token={newsToken}
                  lang="en"
                  height="1000px"
                  instrument="GC:FUTURES"
                  base-color="#333333"
                  background-color="#FFFFFF"
                  surface-color="#FAFAFA"
                  primary-color="#0252AB"
                  bearish-color="#D10000"
                  bullish-color="#12823E"
                  neutral-color="#2775CE"
                  article-lang="”en”"
                  news-color="#0360DD"
                  social-color="#13CA60"
                  display-inside="true"
                />
              </div>
            </div>

            <div className={clsx(classes.section, classes.section1)}>
              <p className={classes.title}>Mention Sources</p>

              <div className={classes.widgetWrapper}>
                <tc-news-split
                  token={newsToken}
                  lang="en"
                  height="1000px"
                  instrument="GC:FUTURES"
                  base-color="#333333"
                  background-color="#FFFFFF"
                  surface-color="#FAFAFA"
                  primary-color="#0252AB"
                  bearish-color="#D10000"
                  bullish-color="#12823E"
                  neutral-color="#2775CE"
                  article-lang="”en”"
                  news-color="#0360DD"
                  social-color="#13CA60"
                />
              </div>
            </div>

            <div className={clsx(classes.section, classes.section2)}>
              <p className={classes.title}>Sentiment Signal</p>

              <div className={classes.widgetWrapper}>
                <tc-sentiment-trend
                  token={newsToken}
                  lang="en"
                  instrument="GC:FUTURES"
                  base-color="#333333"
                  background-color="#FFFFFF"
                  surface-color="#FAFAFA"
                  primary-color="#0252AB"
                  bearish-color="#D10000"
                  bullish-color="#12823E"
                  neutral-color="#2775CE"
                  article-lang="”en”"
                  news-color="#0360DD"
                  social-color="#13CA60"
                  display-inside="true"
                />
              </div>
            </div>

            <div className={clsx(classes.section, classes.section1)}>
              <p className={classes.title}>Sentiment</p>

              <div className={classes.widgetWrapper}>
                <tc-sentiment-score
                  token={newsToken}
                  lang="en"
                  height="1000px"
                  instrument="GC:FUTURES"
                  base-color="#333333"
                  background-color="#FFFFFF"
                  surface-color="#FAFAFA"
                  primary-color="#0252AB"
                  bearish-color="#D10000"
                  bullish-color="#12823E"
                  neutral-color="#2775CE"
                  article-lang="”en”"
                  news-color="#0360DD"
                  social-color="#13CA60"
                  display-inside="true"
                />
              </div>
            </div>

            <div className={clsx(classes.section, classes.section1)}>
              <p className={classes.title}>Subjectivity</p>

              <div className={classes.widgetWrapper}>
                <tc-sentiment-subjectivity
                  token={newsToken}
                  lang="en"
                  height="1000px"
                  instrument="GC:FUTURES"
                  base-color="#333333"
                  background-color="#FFFFFF"
                  surface-color="#FAFAFA"
                  primary-color="#0252AB"
                  bearish-color="#D10000"
                  bullish-color="#12823E"
                  neutral-color="#2775CE"
                  article-lang="”en”"
                  news-color="#0360DD"
                  social-color="#13CA60"
                  display-inside="true"
                />
              </div>
            </div>

            <div className={clsx(classes.section, classes.section1)}>
              <p className={classes.title}>Confidence Index</p>

              <div className={classes.widgetWrapper}>
                <tc-sentiment-confidence
                  token={newsToken}
                  lang="en"
                  height="1000px"
                  instrument="GC:FUTURES"
                  base-color="#333333"
                  background-color="#FFFFFF"
                  surface-color="#FAFAFA"
                  primary-color="#0252AB"
                  bearish-color="#D10000"
                  bullish-color="#12823E"
                  neutral-color="#2775CE"
                  article-lang="”en”"
                  news-color="#0360DD"
                  social-color="#13CA60"
                  display-inside="true"
                />
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#fff',
    paddingBottom: '16px',
    minHeight: theme.appView ? 'calc(100vh - 300px)' : 'calc(100vh - 240px)',
  },

  heading: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
    textAlign: 'left',
    paddingBottom: '6px',
  },

  subTitle: {
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '46px',
    paddingLeft: '24px',
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamily,
  },

  main: {
    paddingBottom: '16px',
    display: 'flex',
  },

  left: {
    height: '1090px',
    background: '#fff',
    borderRadius: '12px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    width: '50%',
    marginLeft: '8px',
    marginRight: '4px',
    '@media (max-width: 900px)': {
      width: '48%',
    },
  },

  mobileLeft: {
    height: '1090px',
    background: '#fff',
    borderRadius: '12px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    width: '95%',
    marginLeft: '8px',
    marginRight: '4px',
  },

  mobileRight: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    height: '1090px',
    marginRight: '8px',
    marginLeft: '4px',
    maxHeight: '68vh !important',
  },

  right: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '1090px',
    marginRight: '8px',
    marginLeft: '4px',
    '@media (max-width: 800px)': {
      width: '49%',
    },
  },

  section: {
    padding: '4px',
    background: '#fff',
    borderRadius: '12px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    height: '90%',
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: 0,
    },
    '@media (max-width: 390px)': {
      width: '90vw',
    },
  },

  tabsWrapper: {
    display: 'flex',
    '& button': {
      '& .MuiButtonBase-root-MuiTab-root.Mui-selected': {
        color: 'orange',
      },
    },
  },

  tab: {
    fontWeight: 600,
    color: '#D2D5D8',
    minHeight: '26px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '12px 14px 0px 14px',

    '@media (max-width:1000px)': {
      fontSize: '12px',
    },
  },

  activeTab: {
    color: 'black !important',
    borderBottom: '2px solid black',
  },

  title: {
    margin: '0',
    height: '14%',
    color: 'black',
    fontWeight: 600,
    maxHeight: '10%',
    textAlign: 'left',
    lineHeight: '24px',
    paddingLeft: '24px',
    letterSpacing: '1px',
    paddingBottom: '6px',
    textTransform: 'uppercase',
  },

  widgetWrapper: {
    height: '80%',
  },

  headingTab: {
    margin: 0,
    color: '#333',
    fontWeight: 600,
    fontSize: '16x',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: '#f3f3f3',
    padding: '10px 20px 0 20px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width:600px)': {
      fontSize: '14px',
    },

    // "&:hover": {
    //   backgroundColor: "#ddd",
    // },
  },

  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  headingTabActive: {
    backgroundColor: '#fff',
    padding: '10px 20px 0 20px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },

  headingTabsWrapper: {
    display: 'flex',

    width: '100%',
    paddingTop: '14px',
    background: '#f3f3f3',
    borderTop: '1px solid #E8E8E8',
  },

  noRipple: {
    '&:before': {
      transition: 'none',
    },
  },

  tabsDivider: {
    height: '24px',
    backgroundColor: '#fff',
  },

  backBtnWrapper: {
    textAlign: 'left',
    marginBottom: 16,
    marginLeft: '16px',
    width: '15rem',
    [theme.breakpoints.down('1750')]: {
      width: '12.5rem',
    },
    [theme.breakpoints.down('1280')]: {
      width: '10rem',
    },
    [theme.breakpoints.down('1000px')]: {
      display: 'none',
    },
    [theme.breakpoints.down('768')]: {
      display: 'none',
    },
  },
}));
