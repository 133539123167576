import { fetchWrapper } from "../../utils/fetchWrapper";

const newsUrl = "/notification/widgets";
const newsDemoUrl = "/notification/demo-widgets";

const getNewsFeedAccessToken = async () => {
  let authKey = sessionStorage.getItem("authKey");
  if (!authKey || isTokenExpired(authKey)) {
    try {
      const newToken = await fetchWrapper.post(newsUrl, {});
      authKey = newToken?.token;

      sessionStorage.setItem("authKey", newToken?.token);
      sessionStorage.setItem("authKeyExpiration", calculateExpirationTime());
    } catch (error) {
      console.log("No Access Token!");
    }
  }
  sessionStorage.removeItem("authKeyDemo");
  sessionStorage.removeItem("authKeyDemoExpiration");
  return authKey;
};

const getDemoNewsFeedAccessToken = async () => {
  let authKeyDemo = sessionStorage.getItem("authKeyDemo");
  sessionStorage.removeItem("authKey");
  sessionStorage.removeItem("authKeyExpiration");
  if (!authKeyDemo || isDemoTokenExpired(authKeyDemo)) {
    try {
      const newToken = await fetchWrapper.post(newsDemoUrl, {});
      authKeyDemo = newToken?.token;
      sessionStorage.setItem("authKeyDemo", newToken?.token);
      sessionStorage.setItem(
        "authKeyDemoExpiration",
        calculateExpirationTime()
      );
    } catch (error) {
      console.log("No Access Token!");
    }
  }
  return authKeyDemo;
};

// Function to check if the token has expired
const isTokenExpired = () => {
  const expirationTime = sessionStorage.getItem("authKeyExpiration");
  if (!expirationTime) {
    return true; // Token expiration time not found, treat as expired
  }

  const currentTime = new Date().getTime();
  return currentTime > parseInt(expirationTime);
};

const isDemoTokenExpired = () => {
  const demoExpirationTime = sessionStorage.getItem("authKeyDemoExpiration");
  if (!demoExpirationTime) {
    return true;
  }

  const currentTime = new Date().getTime();
  return currentTime > parseInt(demoExpirationTime);
};

// Function to calculate the expiration time (12 hours in milliseconds)
const calculateExpirationTime = () => {
  const twelveHoursInMillis = 12 * 60 * 60 * 1000;
  const currentTime = new Date().getTime();
  return currentTime + twelveHoursInMillis;
};

export const newsService = {
  getNewsFeedAccessToken,
  getDemoNewsFeedAccessToken,
};
