import * as React from "react";
import { makeStyles } from "@mui/styles";

const CustomSpinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.spinner}>
      <div className={classes.spinnerItem}></div>
      <div className={classes.spinnerItem}></div>
      <div className={classes.spinnerItem}></div>
      <div className={classes.spinnerItem}></div>
    </div>
  );
};

export default CustomSpinner;

const useStyles = makeStyles((theme) => ({
  spinner: {
    width: "20px",
    height: "20px",
    paddingLeft: "10px",
    position: "relative",
  },
  spinnerItem: {
    boxSizing: "border-box",
    display: "block",
    position: "absolute",
    width: "20px",
    height: "20px",
    border: "8px solid #fff",
    borderRadius: "50%",
    animation: "$spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
    borderColor: "#1AC88D transparent transparent transparent",
  },
  "@keyframes spinner": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
