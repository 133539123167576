import { fetchWrapper } from "../../utils/fetchWrapper";

export const getFees = async () => {
  try {
    const data = await fetchWrapper.get(`/fee`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
