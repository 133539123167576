import clsx from "clsx";
import * as React from "react";
import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAppContext } from "../../../../context/DataContext";
import currencyFormatter from "../../../../helpers/currencyFormatter/currencyFormatter";

import "../../../../styles/global.fonts.css";
import "../../../../styles/global.styles.css";

function calculateTotalPrice(data) {
  let totalPrice = 0;

  for (const productId in data) {
    const { product, quantity } = data[productId];
    totalPrice += product.price * quantity;
  }

  return totalPrice;
}

const ThirdStepContentWeb = ({
  chosenAddress,
  cartFees,
  isUseBTXG,
  setIsUseBTXG,
  showTBC,
}) => {
  const classes = useStyles();
  const { productsBucket, isMobile, isAppView, goldAskPrice } = useAppContext();

  const addressArr = [
    chosenAddress.addressLine,
    chosenAddress.city,
    chosenAddress.shippingState,
    chosenAddress.countryName,
    chosenAddress.zipCode,
  ];

  const isDemoMode = sessionStorage.getItem("isDemo") === "true";

  const serviceFeeFiat = cartFees?.serviceFeeFiat || 0;
  const serviceFeeCoins = cartFees?.serviceFeeCoins || 0;
  const deliveryFee = cartFees?.deliveryFee || 0;
  const totalGrams = cartFees?.totalGrams || 0;
  const totalCartPrice = cartFees?.totalCartPrice || 0;
  const usedCoins = cartFees?.usedCoins || 0;
  let totalSum = cartFees?.totalSum || 0;
  let totalSumWithCoins = cartFees?.totalSumWithCoins || 0;
  const fiatPriceWithCoins = cartFees?.fiatPriceWithCoins || 0;

  const totalCheckoutPrice = calculateTotalPrice(productsBucket || []);

  let price = isUseBTXG
    ? usedCoins === totalGrams
      ? `<span class='goldBtxg, goldBtxgRegular'>${usedCoins}gr BTXG </span>`
      : `<span class='goldBtxg, goldBtxgRegular'>${usedCoins}gr BTXG </span> + ` +
        currencyFormatter(fiatPriceWithCoins, "USD")
    : currencyFormatter(totalCartPrice, "USD");

  if (isDemoMode) {
    totalSum =
      totalCheckoutPrice + cartFees?.deliveryFee + cartFees?.serviceFeeFiat;
    totalSumWithCoins =
      totalCheckoutPrice + cartFees?.deliveryFee + cartFees?.serviceFeeCoins;
    price = isUseBTXG
      ? usedCoins === totalGrams
        ? `<span class='goldBtxg, goldBtxgRegular'>${usedCoins}gr BTXG </span>`
        : `<span class='goldBtxg, goldBtxgRegular'>${usedCoins}gr BTXG </span> + ` +
          currencyFormatter(
            totalCheckoutPrice - usedCoins * goldAskPrice,
            "USD"
          )
      : currencyFormatter(totalCheckoutPrice, "USD");
  }

  let serviceFee = isUseBTXG
    ? usedCoins === totalGrams
      ? currencyFormatter(serviceFeeCoins, "USD")
      : currencyFormatter(serviceFeeFiat, "USD")
    : currencyFormatter(serviceFeeFiat, "USD");

  let total = isUseBTXG
    ? `<span class='goldBtxg'>${usedCoins}gr BTXG </span> <span class='blackText'>+</span> ` +
      currencyFormatter(totalSumWithCoins, "USD")
    : currencyFormatter(totalSum, "USD");

  if (isDemoMode) {
    total = isUseBTXG
      ? `<span class='goldBtxg'>${usedCoins}gr BTXG </span> <span class='blackText'>+</span> ` +
        currencyFormatter(totalSumWithCoins - usedCoins * goldAskPrice, "USD")
      : currencyFormatter(totalSum, "USD");
  }

  const productList =
    productsBucket &&
    Object.keys(productsBucket).map((productId) => ({
      id: productId,
      ...(productsBucket[productId] || {}), // Added a check for null or undefined
    }));

  const addressString = addressArr
    .filter((item) => item)
    .join(", ")
    .trim();

  const handleCheckboxClicked = () => {
    if (usedCoins !== 0) {
      setIsUseBTXG((old) => !old);
    }
  };

  const handleViewProductDetails = (partNumber) => {
    const isDemo = sessionStorage.getItem("isDemo");
    if (isDemo) {
      return;
    }
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        `{"Event": "Store", "Action": "${partNumber}"}`
      );
    } else {
      window.open(`${window.location.origin}/store?id=${partNumber}`);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.orderTitle}>Your Order</div>

      <div className={classes.bucketList}>
        {productList &&
          productList?.map((elm) => {
            return (
              <div key={elm?.id} className={classes.cartItemWrapper}>
                {isMobile ? (
                  <>
                    <div
                      className={classes.imgWrapper}
                      onClick={() =>
                        handleViewProductDetails(elm?.product?.part_number)
                      }
                    >
                      <img
                        src={elm?.product?.images?.[0]}
                        className={classes.imageGoldenBar}
                      />
                    </div>

                    <div className={classes.mobileCartProdWrapper}>
                      <span
                        className={classes.productName}
                        onClick={() =>
                          handleViewProductDetails(elm?.product?.part_number)
                        }
                      >
                        {elm?.product?.name}
                      </span>

                      <span className={classes.quantityWrapper}>
                        {elm?.quantity}x {elm?.product?.weight}gr
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={classes.imgWrapper}
                      onClick={() =>
                        handleViewProductDetails(elm?.product?.part_number)
                      }
                    >
                      <img src={elm?.product?.images?.[0]} />

                      <span
                        className={classes.productName}
                        onClick={() =>
                          handleViewProductDetails(elm?.product?.part_number)
                        }
                      >
                        {elm?.product?.name}
                      </span>
                    </div>
                    <span className={classes.quantityWrapper}>
                      {elm?.quantity}x {elm?.product?.weight}gr
                    </span>
                  </>
                )}
              </div>
            );
          })}
      </div>

      <div className={classes.userDetails}>
        <div className={classes.item}>
          <span>Address </span>
          <span>{addressString}</span>
        </div>

        <div className={classes.item}>
          <span>Price </span>
          {showTBC ? (
            <span>TBC</span>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: price }}></span>
          )}
        </div>

        <div className={classes.item}>
          <span>Service Fee </span>
          <span>{showTBC ? "TBC" : serviceFee}</span>
        </div>

        <div className={classes.item}>
          <span>Delivery Fee </span>
          <span>
            {" "}
            {showTBC ? "TBC" : currencyFormatter(deliveryFee, "USD")}
          </span>
        </div>

        <div
          className={clsx(
            classes.useBTXG,
            usedCoins === 0 && classes.emptyBTXG
          )}
        >
          <div className={classes.left}></div>
          {usedCoins !== 0 && (
            <div className={classes.right} onClick={handleCheckboxClicked}>
              <>
                <Checkbox checked={isUseBTXG} disabled={usedCoins === 0} />

                <span style={{ cursor: "pointer" }}>Use my BTXG Ballance</span>
              </>
            </div>
          )}
        </div>

        <div className={classes.total}>
          <span>Total </span>
          {showTBC ? (
            <span className={classes.totalValue}>TBC</span>
          ) : (
            <span
              className={classes.totalValue}
              dangerouslySetInnerHTML={{ __html: total }}
            ></span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThirdStepContentWeb;

const useStyles = makeStyles((theme) => ({
  bucketList: {
    height: "170px",
    overflowY: "auto",
  },

  cartItemWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",

    padding: "8px",
    marginBottom: "6px",
    borderRadius: "8px",
    border: "1px solid #D2D5D8",
    "& img": {
      width: "60px",
      height: "60px",
      objectFit: "contain",
    },
    [theme.breakpoints.down("768")]: {},
  },

  imageGoldenBar: {
    cursor: "pointer",
  },

  orderTitle: {
    fontSize: "28px",
    textAlign: "center",
    paddingBottom: "14px",
  },

  imgWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    fontWeight: 600,
    "& span": {
      cursor: "pointer",
    },
    "& img": {
      cursor: "pointer",
    },
  },

  userDetails: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "flex-end",
  },

  item: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 8,

    "& span:first-child": {
      fontWeight: 600,
    },

    "& span:last-child": {
      textAlign: "end",
    },
  },

  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,

    fontWeight: 600,
  },

  productName: {
    fontWeight: 600,
  },

  totalValue: {
    fontSize: "clamp(16px, 2vw, 23px)",
    color: "#347362",
    fontWeight: "700",
  },

  useBTXG: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "3fr minmax(220px, 1fr)",

    color: "#A37903",

    "& div:first-child": {
      height: "5px",
      background: "linear-gradient(90deg, rgba(61, 45, 1, 0) 0%, #A37903 100%)",
    },
  },

  emptyBTXG: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "1fr",

    width: "100%",
    height: "30px",
    paddingBlock: "6px",
  },

  quantityWrapper: {
    fontWeight: 500,
    whiteSpace: "nowrap",
  },

  mobileCartProdWrapper: {
    display: "flex",
    flexDirection: "column",

    paddingLeft: "8px",
    "& > span:first-child": {
      cursor: "pointer",
    },
    "& img": {
      cursor: "pointer",
    },
  },
}));
