import React from "react";
import PaytikoDeposit from "../../../components/WebComponents/PaytikoDeposit/PaytikoDeposit";
import AppLayout from "../../../components/common/Layout/AppLayout";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import { routes } from "../../../routes";

function MobileDeposit({ isMobile }) {
  return (
    <AppLayout>
      <MobileNavigationBack page={"Deposit"} route={routes.dashboard} />
      <PaytikoDeposit isMobile={isMobile} />
    </AppLayout>
  );
}

export default MobileDeposit;
