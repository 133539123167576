import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import BullionzCertificates from "../BullionzCertificates/BullionzCertificates";
import WebLayout from "../../common/Layout/WebLayout";
import SeeAll from "../SeeAll/SeeAll";

function WebCertificatesHistorySeeAll() {
  const classes = useStyles();
  return (
    <WebLayout>
      <SeeAll>
        <Box className={classes.container}>
          <BullionzCertificates
            isSeeAll={false}
            containerClassName={classes.containerCertificates}
            pageSize={10}
          />
        </Box>
      </SeeAll>
    </WebLayout>
  );
}

export default WebCertificatesHistorySeeAll;

const useStyles = makeStyles((theme) => ({
  containerCertificates: {
    width: "100%",
    boxShadow: "0px 21px 43px 0px #0000001A;",
    height: "43rem",
    background: "var(--floral-white)",
    border: "2px",
    borderRadius: "24px",
    "& .MuiTabs-indicator": {
      backgroundColor: "black",
      height: "3px",
      width: "182px !important",
      left: "22px !important",
    },
  },
  container: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "1rem",
  },
}));
