import { fetchWrapper } from "../../utils/fetchWrapper";

const autoInvestUrl = "/autoinvest-transaction";

const getAllAutoInvest = async (page, pageSize = 10) => {
  try {
    if (page == 0) {
      const response = await fetchWrapper.get(`${autoInvestUrl}/`);
      return response;
    }

    const response = await fetchWrapper.get(
      `${autoInvestUrl}?page=${page}&pageSize=${pageSize}`
    );
    return response;
  } catch (e) {
    throw new Error();
  }
};

const getAllActiveAutoInvest = async () => {
  try {
    const response = await fetchWrapper.get(`${autoInvestUrl}/active`);
    return response;
  } catch (e) {
    throw new Error(JSON.stringify(e));
  }
};

const getAllAutoInvestUpcomingCoinsTillEndOfCurrentMonth = async () => {
  try {
    const response = await fetchWrapper.get(`${autoInvestUrl}/upcoming-coins`);
    return response;
  } catch (e) {
    throw new Error(JSON.stringify(e));
  }
};

const createAutoInvest = async (autoInvest) => {
  try {
    return await fetchWrapper.post(`${autoInvestUrl}/create`, autoInvest);
  } catch (e) {
    throw e;
  }
};

const cancelAutoInvest = async (id) => {
  try {
    return await fetchWrapper.post(`/orders/autoinvest/cancel`, id);
  } catch (e) {
    throw e;
  }
};

const getAutoInvest = async () => {
  try {
    return await fetchWrapper.get(``);
  } catch (error) {
    throw new Error();
  }
};

const updateAutoInvest = async () => {
  try {
    return await fetchWrapper.put();
  } catch (e) {
    throw e;
  }
};

const deleteAutoInvest = async () => {
  try {
    return await fetchWrapper.delete(``);
  } catch (e) {
    throw e;
  }
};

const serviceAutoInvest = {
  getAllAutoInvest,
  getAutoInvest,
  createAutoInvest,
  updateAutoInvest,
  deleteAutoInvest,
  cancelAutoInvest,
  getAllActiveAutoInvest,
  getAllAutoInvestUpcomingCoinsTillEndOfCurrentMonth,
};

export default serviceAutoInvest;
