import { fetchWrapper } from "../../utils/fetchWrapper";

const getUserNotifications = async ({ page, pageSize }) => {
  try {
    const notifications = await fetchWrapper.get(
      `/user/notifications?page=${page}&pageSize=${pageSize}`
    );
    return notifications;
  } catch (error) {
    console.error("Error:", error);
    throw new Error(error);
  }
};

const updateUserNotifications = async (notificationId) => {
  try {
    const readNotification = await fetchWrapper.put(
      `/notification/user-notification`,
      notificationId
    );
    return readNotification;
  } catch (error) {
    console.error("Error:", error);
    throw new Error(error);
  }
};

export const userNotifications = {
  getUserNotifications,
  updateUserNotifications,
};
