import { Navigate } from 'react-router-dom';
import { routes } from '../../../routes';

const forbiddenValues = [
  routes.certificatesHistory,
  routes.autoInvestHistory,
  routes.sumsub,
  routes.withdraw,
  routes.forgotPassword,
  routes.yieldingHistory,
  routes.vaultingHistory,
  routes.transactions,
  routes.deposit,
];

const mobileForbiddenValues = [routes.sumsub, routes.withdraw, routes.forgotPassword, routes.deposit];

export const ProtectedRoute = ({ isAuthenticated, children, path, isDemo, setIsPleaseLoginPopupOpen, isMobile }) => {
  const appView = sessionStorage.getItem('isNative');

  function handleOpenLoginPop() {
    setIsPleaseLoginPopupOpen(true);
  }

  if (!isAuthenticated && !isDemo) {
    return <Navigate to="/login" replace />;
  }
  if (isMobile) {
    if (!isAuthenticated && isDemo && mobileForbiddenValues.some((value) => value === path)) {
      if (path === '/deposit' && appView && isDemo) {
        handleOpenLoginPop();
      }
      return <Navigate to="/" replace />;
    }
  } else {
    if (!isAuthenticated && isDemo && forbiddenValues.some((value) => value === path)) {
      if (path === '/deposit' && appView && isDemo) {
        handleOpenLoginPop();
      }
      return <Navigate to="/" replace />;
    }
  }
  return children;
};
