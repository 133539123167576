export function isMarketOpen() {
  const now = new Date();

  // Convert to New York Time (GMT-4)
  const newYorkTime = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));

  const dayOfWeek = newYorkTime.getDay();
  const hours = newYorkTime.getHours();
  const minutes = newYorkTime.getMinutes();

  // Check if it's the weekend break (Friday 16:59 - Sunday 18:00)
  if (dayOfWeek === 5 && (hours > 16 || (hours === 16 && minutes >= 59))) {
    return false; // Friday after 16:59
  }

  if (dayOfWeek === 6) {
    return false; // Saturday is a full day off
  }

  if (dayOfWeek === 0 && hours < 18) {
    return false; // Sunday before 18:00
  }

  // Check if it's the daily break (16:59 - 18:00)
  if ((hours === 16 && minutes >= 59) || hours === 17) {
    return false; // Daily break
  }

  return true;
}
