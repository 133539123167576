import { useEffect, useState } from 'react';
import { useCryptoContext } from '../../../../context/CryptoContext';

export const useCryptoAssets = () => {
  const [search, setSearch] = useState('');
  const [assets, setAssets] = useState([]);

  const { userMarkets, isUserMarketsLoading, watchlist, resetSearch } = useCryptoContext();

  useEffect(() => {
    if (userMarkets?.length) {
      setAssets(userMarkets);
    }
  }, [userMarkets]);

  useEffect(() => {
    setSearch('');
  }, [resetSearch]);

  useEffect(() => {
    if (search) {
      const filteredAssets = userMarkets.filter((asset) => {
        const assetIdx = asset?.displayName?.split('/')?.[0]?.toLowerCase();

        return asset.name.toLowerCase().includes(search.toLowerCase()) || assetIdx.includes(search.toLowerCase());
      });
      setAssets(filteredAssets);
    } else {
      setAssets(userMarkets);
    }
  }, [search]);

  return { search, setSearch, resetSearch, userMarkets: assets, isUserMarketsLoading, watchlist };
};
