import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";

import { useAppContext } from "../../../context/DataContext";

import CloseIcon from "@mui/icons-material/Close";
import { userService } from "../../../service/user";
import { useRef } from "react";

const getTooltipText = (step) => {
  switch (step) {
    case 1:
      return "Understand a short financial summary of your Bullionz account.";

    case 2:
      return "Inject funds through traditional payment options to increase the account’s “Cash Balance”.";

    case 3:
      return `Conveniently purchase real physical gold through the Bullionz store.`;

    case 4:
      return `Convert digital BTXG tokens for real physical gold and have it shipped. \n <br>`;

    case 5:
      return `Set aside BTXG tokens within our Yielding Programs to earn passive rewards. \n <br>`;

    case 6:
      return "Store gold assets within our secure Vaults. <br>  \n <br>";

    case 7:
      return "Monthly returns distributed through our Yielding Programs, based on cumulative fees from all users' actions on the platform.";

    case 8:
      return "Exchange traditional FIAT funds for our gold-backed digital token (BTXG) in weight amounts (grams).";

    case 9:
      return "Price history of our BTXG tokens across various timeframes. <br>  \n";
    case 10:
      return "The account’s portfolio breakdown of how much and where BTXG tokens are distributed . <br>  \n";

    case 11:
      return "The account’s token activities history including Buy and Close orders and Redemptions.";

    case 12:
      return "Market trends, gold price drivers and other news. <br>  \n";

    case 13:
      return "Thank you for joining us!";
    case 14:
      return "Thank you for joining us!";

    default:
      return "";
  }
};

const TutorialTooltipCustom = styled("div")(
  ({
    isFakeTooltip,
    tutorialStep,
    tutorialElmOffset,
    tooltipDimensions,
    theme,
  }) => {
    let positionStyle = {
      top: 0,
      left: 0,
    };
    positionStyle.top = tutorialElmOffset.top;
    positionStyle.left = tutorialElmOffset.left;

    if (isFakeTooltip) {
      positionStyle.top = -9999;
      positionStyle.left = 0;
      positionStyle.opacity = 0.5;
      positionStyle.background = "green";
    }

    const tooltipHeight =
      tooltipDimensions?.height < 170 ? 170 : tooltipDimensions?.height || 0;

    let heightProp = tooltipHeight - 32;

    if (isFakeTooltip) heightProp = "auto";
    else if (tutorialStep > 8) heightProp = 165;

    return {
      position: "absolute",
      background: "white",
      ...positionStyle,
      zIndex: 10001,
      padding: "16px",
      maxWidth: "368px",
      textAlign: "left",
      margin: "20px",
      borderRadius: "12px",
      height: heightProp,
      transition: " height 0.3s ease",
      overflow: "hidden",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",

      [theme.breakpoints.down("440")]: {
        maxWidth: "calc(100vw - 72px)",
      },
    };
  }
);

const TutorialArrow = styled("div")(
  ({ position, type, positionProps, tutorialStep, theme }) => {
    const arrowWidth = 28;
    const arrowHeight = 20;

    let chevronStyle = {};
    let rotationDeg = 0;
    let arrowPosition = {
      top: 0,
      left: 0,
    };

    switch (position) {
      case "bottom":
        rotationDeg = 0;
        arrowPosition.top = positionProps.top;
        arrowPosition.left = positionProps.left - arrowWidth / 2;
        break;
      case "right":
        rotationDeg = 270;
        arrowPosition.top = positionProps.top - arrowWidth / 2;
        arrowPosition.left = positionProps.left - arrowHeight;
        break;
      case "top":
        rotationDeg = 180;
        arrowPosition.top = positionProps.top - arrowHeight;
        arrowPosition.left = positionProps.left - arrowWidth / 2;
        break;
      case "left":
        rotationDeg = 90;
        arrowPosition.top = positionProps.top - arrowWidth / 2;
        arrowPosition.left = positionProps.left - arrowHeight - 20;
        break;
    }

    if (type === "slim") {
      chevronStyle = {
        top: arrowPosition?.top,
        left: arrowPosition?.left,
        width: "0",
        height: "0",
        transform: "scaleY(-1) rotate(45deg)",
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderBottom: "20px solid white",
      };

      if (tutorialStep === 2) {
        chevronStyle.top = arrowPosition?.top + 10;
        chevronStyle.left = arrowPosition?.left - 14;
        chevronStyle.transform = "scaleY(-1) rotate(45deg)";
      } else if (tutorialStep === 7) {
        chevronStyle.top = arrowPosition?.top - 12;
        chevronStyle.left = arrowPosition?.left - 18;
        chevronStyle.transform = "scaleY(-1) rotate(135deg)";
      }
    }

    return {
      position: "absolute",

      top: arrowPosition.top,
      left: arrowPosition.left,
      transform: `rotate(${rotationDeg}deg)`,
      borderBottom: `${arrowHeight}px solid white`,
      borderLeft: `${arrowWidth / 2}px solid transparent`,
      borderRight: `${arrowWidth / 2}px solid transparent`,
      zIndex: 9999999,
      ...chevronStyle,
    };
  }
);

const TutorialTooltipMobile = ({ isFakeTooltip }) => {
  const {
    isMobile,
    isAppView,
    arrowProps,
    tutorialStep,
    tooltipHeight,
    setTutorialStep,
    setIsTextUpdated,
    tooltipDimensions,
    tutorialElmOffset,
    setTooltipDimensions,
    setIsAccountMenuOpened,
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
    setArrowPositionUpdated,
    setTooltipPositionUpdated,
    setTooltipText,
    tooltipText,
    isHeightUpdated,
    setIsHeightUpdated,
    arrowPositionUpdated,
    tooltipPositionUpdated,
    isBuySellLoading,
  } = useAppContext();
  const classes = useStyles(tutorialStep);
  const [fakeTooltipText, setFakeTooltipText] = useState();
  const [isComponentUpdated, setIsComponentUpdated] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const tooltipRef = useRef(null);

  const mutationObserver = new MutationObserver(() => {
    const tooltipWidth = tooltipRef?.current?.offsetWidth;
    const tooltipHeight = tooltipRef?.current?.offsetHeight;

    // setTooltipDimensions({
    //   width: tooltipWidth,
    //   height: tooltipHeight,
    // });

    setTimeout(() => {
      setIsHeightUpdated(true);
    }, 500);
  });

  const resizeObserver = new ResizeObserver(() => {
    setTooltipDimensions({
      width: 0,
      height: 0,
    });

    // setTimeout(() => {
    const tooltipWidth = document.getElementById(
      "fake-tutorial-tooltip"
    )?.offsetWidth;
    const tooltipHeight = document.getElementById(
      "fake-tutorial-tooltip"
    )?.offsetHeight;

    setTooltipDimensions({
      width: tooltipWidth,
      height: tooltipHeight,
    });

    setTimeout(() => {
      setIsHeightUpdated(true);
    }, 160);
    // }, 1000);
  });

  useEffect(() => {
    if (tooltipRef.current) {
      resizeObserver.observe(tooltipRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [tutorialStep]);

  useEffect(() => {
    if (tooltipRef.current) {
      mutationObserver.observe(tooltipRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    }
  }, [tutorialStep, tutorialElmOffset]);

  const handlePrevStep = () => {
    setIsHeightUpdated(false);
    setIsTextUpdated(false);
    setArrowPositionUpdated(false);
    setTooltipPositionUpdated(false);

    if (tutorialStep === 5) {
      setTutorialStep(3);
      sessionStorage.setItem("tutorialStep", 3);
    } else {
      sessionStorage.setItem("tutorialStep", tutorialStep - 1);
      setTutorialStep((prev) => prev - 1);
    }
  };

  const handleNextStep = (e) => {
    setIsHeightUpdated(false);
    setIsTextUpdated(false);
    setArrowPositionUpdated(false);
    setTooltipPositionUpdated(false);

    if (tutorialStep === 3) {
      setTutorialStep(5);
      sessionStorage.setItem("tutorialStep", 5);
    } else {
      sessionStorage.setItem("tutorialStep", tutorialStep + 1);
      setTutorialStep((prev) => prev + 1);
    }
  };

  const BTXG = document.getElementById("btxg-container");
  const buyGoldIcon = document.getElementById("buy-gold-icon");
  const newsContainer = document.getElementById("news-container");
  const portfolio = document.getElementById("portfolio-container");
  const transactions = document.getElementById("transactions-container");

  const balanceContainer = document.getElementById("balance-container");
  const goldHistory = document.getElementById("mobile-gold-history");
  const depositBtn = document.getElementById("deposit-btn");
  const redeemIcon = document.getElementById("redeem-icon");
  const yieldingIcon = document.getElementById("yielding-icon");
  const vaultingIcon = document.getElementById("vaulting-icon");
  const monthlyPool = document.getElementById("monthly-pool");
  const buyCloseContainer = useMemo(() =>
    document.getElementById("buyclose-container")
  );

  const blurAllItems = () => {
    setIsAccountMenuOpened(false);

    balanceContainer?.classList?.remove("except");
    depositBtn?.classList?.remove("except");
    buyGoldIcon?.classList?.remove("except");
    redeemIcon?.classList?.remove("except");
    yieldingIcon?.classList?.remove("except");
    vaultingIcon?.classList?.remove("except");
    monthlyPool?.classList?.remove("except");
    buyCloseContainer?.classList?.remove("except");
    BTXG?.classList?.remove("except");
    portfolio?.classList?.remove("except");
    transactions?.classList?.remove("except");
    newsContainer?.classList?.remove("except");
    goldHistory?.classList?.remove("except");
  };

  const handleCancelTutorial = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    blurAllItems();

    if (params.get("tutorial") === "true") {
      params.delete("tutorial");
      url.search = params.toString();
      window.history.replaceState({}, "", url.toString());
    }

    setTutorialStep(15);

    userService.updateTutorialStatus("WEB", "CLOSED");

    // sessionStorage.removeItem("tutorialStep");
    sessionStorage.setItem("tutorialStep", 15);
  };

  const handleKeyDown = (event) => {
    if (!isFakeTooltip) {
      switch (event.key) {
        case "ArrowLeft":
          event.preventDefault();
          handlePrevStep();
          break;
        case "ArrowRight":
          event.preventDefault();
          handleNextStep();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array to run effect only once

  useEffect(() => {
    const text = getTooltipText(tutorialStep);
    // if (text !== tooltipText) {
    // }
    setTooltipText(text);
    setFakeTooltipText(text);

    // setTimeout(() => {
    setIsTextUpdated(true);
    // }, 300);
  }, [tutorialStep]);

  let tooltipId = "tutorial-tooltip";

  if (arrowProps?.position === "top") {
    tooltipId = "tutorial-tooltip-top";
  } else if (arrowProps?.position === "left") {
    tooltipId = "tutorial-tooltip-left";
  } else if (arrowProps?.position === "right") {
    tooltipId = "tutorial-tooltip-right";
  }

  return (
    <>
      {isHeightUpdated &&
        arrowPositionUpdated &&
        !proceedSellOrderClicked &&
        !proceedBuyOrderClicked &&
        tooltipPositionUpdated &&
        !isBuySellLoading &&
        tutorialStep > 0 &&
        tutorialStep < 14 && (
          <TutorialArrow
            type={arrowProps?.type}
            id="tutorial-tooltip-arrow"
            tutorialStep={tutorialStep}
            position={arrowProps?.position}
            positionProps={arrowProps?.positionProps}
          />
        )}

      <TutorialTooltipCustom
        ref={isFakeTooltip ? tooltipRef : null}
        isComponentUpdated={isComponentUpdated}
        id={isFakeTooltip ? "fake-tutorial-tooltip" : tooltipId}
        isAppView={isAppView}
        windowWidth={windowWidth}
        tutorialStep={tutorialStep}
        tooltipHeight={tooltipHeight}
        isFakeTooltip={isFakeTooltip}
        tooltipDimensions={tooltipDimensions}
        tutorialElmOffset={tutorialElmOffset}
      >
        <div className={classes.header}>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={handleCancelTutorial}
          />
        </div>

        {!isFakeTooltip && (
          <p
            id="tooltip-text"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: tooltipText }}
          />
        )}

        {isFakeTooltip && (
          <>
            <p
              id="next-tooltip-text"
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: fakeTooltipText }}
            />
          </>
        )}

        <div className={classes.btnWrapper}>
          <button className={classes.backBtn} onClick={handlePrevStep}>
            Back
          </button>
          <button className={classes.nextBtn} onClick={handleNextStep}>
            {tutorialStep < 14 && "Next"}
          </button>
        </div>
      </TutorialTooltipCustom>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const styles = {
    top: "0px",
  };

  const rightTopChevron = {
    top: "56px",
    right: "-14px",
    width: "0",
    height: "0",
    transform: "rotate(90deg)",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid white",
  };

  return {
    wrapper: {
      position: "absolute",
      top: 0,
      background: "white",

      zIndex: 99999,
      padding: "16px",
      borderRadius: "12px",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      ...styles,

      "&::before": {
        content: '""',
        position: "absolute",
        ...rightTopChevron,
      },
    },

    header: {
      display: "flex",
      justifyContent: "flex-end",
    },

    btnWrapper: {
      display: "grid",
      justifyItems: "center",
      gridTemplateColumns: "1fr 1fr",

      marginTop: "16px",

      // [theme.breakpoints.down("1100")]: {
      //   gridTemplateColumns: "1fr",
      //   justifyItems: "center",
      //   gap: "10px",
      // },
    },

    description: {
      whiteSpace: "pre-wrap",
      WebkitUserSelect: "none" /* Safari */,
      MsUserSelect: "none" /* IE 10 and IE 11 */,
      userSelect: "none" /* Standard syntax */,
      animation: `$myEffectExit 3000ms ${theme.transitions.easing.easeInOut}`,
    },

    "@keyframes myEffect": {
      "0%": {
        opacity: 0,
        transform: "translateY(-200%)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },

    backBtn: {
      cursor: "pointer",

      background: "white",
      color: "black",
      border: "1px solid black",
      width: "80%",
      borderRadius: "28px",
      height: "34px",
      maxWidth: "150px",
    },

    nextBtn: {
      cursor: "pointer",

      width: "80%",
      background: "#48D0A4",
      color: "white",
      border: "1px solid #48D0A4",
      padding: "8px 32px",
      borderRadius: "28px",
      height: "34px",
      maxWidth: "150px",
    },

    bolderText: {
      fontWeight: "bold",
    },
  };
});

export default TutorialTooltipMobile;
