import { Box } from "@mui/material";
import React from "react";
import MobileStakingTotalMonthlyCharts from "../../../../../common/Charts/MobileStakingTotalMonthlyCharts";
import { makeStyles } from "@mui/styles";
import MobileStakingTotalMonthlyCharts2 from "../../../../../common/Charts/MobileStakingTotalMonthlyCharts2";
import MobileTotalMonthlyPoolInStaking from "../../../MobileTotalMonthlyPool/MobileTotalMonthlyPoolInStaking";

function MobileStakingTotalMonthlyPool({ pool }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {pool && (
        <MobileTotalMonthlyPoolInStaking
          chart1={<MobileStakingTotalMonthlyCharts pool={pool} />}
          chart2={<MobileStakingTotalMonthlyCharts2 pool={pool} />}
          classTitle={classes.title}
          classTab={classes.tab}
        />
      )}
    </Box>
  );
}

export default MobileStakingTotalMonthlyPool;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
    height: "40%",
    //height: "auto",
    minHeight: "190px",
    backgroundColor: "var(--white)",
    borderBottomLeftRadius: "24px",
    borderBottomRightRadius: "24px",
    boxShadow: "0px 21px 43px 0px #0000001A",
    "@media (orientation: landscape)": {
      height: "auto",
    },
  },
  title: {
    textAlign: "left",
    width: "80%",
    fontSize:
      "clamp(var(--font-size-10), var(--font-size-11), var(--font-size-12))",
    fontWeight: "600",
    marginBottom: "4px",
    marginLeft: "0.4rem",
  },
  tab: {
    minWidth: "18px",
    "& .MuiTab-root": {
      minWidth: "20px",
      minHeight: "18px",
      width: "4.75rem",
      height: "100%",
    },
    "& .MuiTabs-root": {
      minHeight: "20px !important",
      overflow: "hidden",
      height: "20px",
    },
    "& .MuiTabs-flexContainer": {
      paddingLeft: "0rem",
    },
    "& button": {
      borderRadius: "8px",
      color: "var(--black)",
      fontWeight: 500,
      paddingLeft: "0.15rem",
      paddingRight: "0.15rem",
      lineHeight: "1px",
      paddingTop: "0.6rem",
      paddingBottom: "0.6rem",
      fontSize: "var(--font-size-9)",
      fontFamily: theme.typography.fontFamily,
    },
    "& button.Mui-selected": {
      backgroundColor: "var(--beige) !important",
      color: "var(--white)",
      fontWeight: "bold",
      fontFamily: theme.typography.fontFamily,
    },
  },
}));
