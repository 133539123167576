import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import MobilePorfolioAllocationChart from "../../../common/Charts/MobilePortfolioAllocationChart";
import { useAppContext } from "../../../../context/DataContext";
import clsx from "clsx";

function MobilePortfolioAllocation({ tutorialStep }) {
  const classes = useStyles();
  const { nameAndValueInPA } = useAppContext();
  const { name, value } = nameAndValueInPA;

  const isActiveTutorial = tutorialStep === 9;

  useEffect(() => {
    console.log();
  }, []);

  return (
    <Box className={classes.container} id="portfolio-allocation">
      <Box
        className={clsx(
          classes.title,
          isActiveTutorial && classes.tutorialTitle
        )}
      >
        PORTFOLIO ALLOCATION
      </Box>
      <Box className={classes.staking}>
        <Box>
          <Box className={classes.bold}>{name}</Box>
        </Box>
        <Box className={classes.bold}>{value}gr</Box>
      </Box>
      <Box>
        <MobilePorfolioAllocationChart isActiveTutorial={isActiveTutorial} />
      </Box>
    </Box>
  );
}

export default MobilePortfolioAllocation;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    // paddingTop: "0.8rem",
    width: "100%",
    "@media (orientation: landscape)": {
      alignItems: "center",
      textAlign: "center",
    },
  },
  title: {
    textAlign: "left",
    width: "80%",
    height: "36px",
    fontSize:
      "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-15))",
    fontWeight: "600",
    paddingLeft: "0.3rem",
    "@media (orientation: landscape)": {
      margin: "auto",
      marginBottom: "0.175rem",
    },
  },
  tutorialTitle: {
    color: "white",
  },
  staking: {
    fontSize:
      "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-15))",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    backgroundColor: "var(--yellow-pale)",
    borderRadius: "8px",
    marginTop: "0.25rem",
    minHeight: "28px",
    height: "28px",
    "@media (orientation: landscape)": {
      width: "78%",
      margin: "auto",
    },
  },
  coinName: {
    fontSize:
      "clamp(var(--font-size-8), var(--font-size-9), var(--font-size-10))",
    textAlign: "left",
  },
  bold: {
    fontWeight: "bold",
  },
}));
