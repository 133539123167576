import React from "react";
import { makeStyles } from "@mui/styles";

import CartPage from "../../../components/common/CartPage/CartPage";
import AppLayout from "../../../components/common/Layout/AppLayout";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";

import { routes } from "../../../routes";
import { useAppContext } from "../../../context/DataContext";

function MobileCartPage() {
  const { isAppView } = useAppContext();

  return (
    <AppLayout>
      <MobileNavigationBack
        page={"Cart"}
        route={routes.dashboard}
        appView={true}
      />
      <CartPage />
    </AppLayout>
  );
}

export default MobileCartPage;
