import ReactApexChart from 'react-apexcharts';
import { useAppContext } from '../../../context/DataContext';

export const InstrumentChart = ({ data, decimals }) => {
  const { isAppView } = useAppContext();

  const effectiveDecimals = decimals || 0;

  const options = {
    chart: {
      type: 'candlestick',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: !isAppView,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(effectiveDecimals);
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart key={effectiveDecimals} height={350} options={options} type="candlestick" series={[{ data }]} />
    </div>
  );
};
