import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../context/DataContext";
import { handleGetUserDetails } from "../../../helpers/handleGetUserDetails";
import { getElementMiddlePosition } from "../../../utils/get-element-position";

const getOrientation = () => window.screen.orientation.type;

export const useTutorialWebDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [oldActiveStep, setOldActiveStep] = useState(0);
  const [isTutorialLoading, setIsTutorialLoading] = useState(true);
  const [isUserDataFetched, setIsUserDataFetched] = useState(false);
  const [windowProps, setWindowProps] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [orientation, setOrientation] = useState(getOrientation());
  const { userDetails } = useAppContext();

  const {
    isMobile,
    isAppView,
    tutorialStep,
    setTutorialStep,
    setTutorialElmOffset,
    isRedeemGoldPopupOpen,
    proceedBuyOrderClicked,
    proceedSellOrderClicked,

    setArrowProps,
    tooltipDimensions,
    setTooltipDimensions,
    setIsAccountMenuOpened,
    setArrowPositionUpdated,
    isHeightUpdated,
    setTooltipPositionUpdated,
  } = useAppContext();

  const BTXG = document.getElementById("btxg-container");
  const newsContainer = document.getElementById("news-container");
  const portfolio = document.getElementById("portfolio-container");
  const transactions = document.getElementById("transactions-container");

  const balanceContainer = document.getElementById("balance-container");
  const depositBtn = document.getElementById("deposit-btn");
  const buyGoldIcon = document.getElementById("buy-icon");
  const redeemIcon = document.getElementById("redeem-icon");
  const yieldingIcon = document.getElementById("yielding-icon");
  const vaultingIcon = document.getElementById("vaulting-icon");
  const monthlyPool = document.getElementById("monthly-pool");
  const buyCloseContainer = useMemo(() =>
    document.getElementById("buyclose-container")
  );

  const blurAllItems = () => {
    try {
      balanceContainer?.classList?.remove("except");
      buyGoldIcon?.classList?.remove("except");
      depositBtn?.classList?.remove("except");
      redeemIcon?.classList?.remove("except");
      yieldingIcon?.classList?.remove("except");
      vaultingIcon?.classList?.remove("except");
      monthlyPool?.classList?.remove("except");
      buyCloseContainer?.classList?.remove("except");
      BTXG?.classList?.remove("except");
      portfolio?.classList?.remove("except");
      transactions?.classList?.remove("except");
      newsContainer?.classList?.remove("except");
    } catch (e) {
      console.error(e);
    }
  };


  const reportWindowSize = () => {
    setWindowProps({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const getUserDetails = async () => {
    setIsTutorialLoading(true);
    setTimeout(() => setIsTutorialLoading(false), 380);
  };

  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, []);

  const updateOrientation = (event) => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  useEffect(() => {
    if (!isUserDataFetched) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === "tutorialStep") {
        setTutorialStep(Number(e.newValue));
      }
    });

    return () => {
      window.removeEventListener("storage", () => { });
    };
  }, []);

  useEffect(() => {
    let isWebTutorialPassed =
      userDetails?.webTutorialPassed === "PASSED" ||
      userDetails?.webTutorialPassed === "SKIPPED" ||
      userDetails?.webTutorialPassed === "CLOSED";
    const isMobileTutorialPassed = userDetails?.mobileTutorialPassed;
    const isNativeView = isAppView;
    const isWeb = !isNativeView && !isMobile;
    const urlString = window.location.href;
    const url = new URL(urlString);
    const searchParams = url.searchParams;
    const isTutorialEnabled =
      searchParams.has("tutorial") && searchParams.get("tutorial") === "true";
    const activeStep = Number(sessionStorage.getItem("tutorialStep"));

    if (isTutorialEnabled) {
      isWebTutorialPassed = false;
    }

    if (sessionStorage.getItem("tutorialStep") && activeStep < 15) {
      isWebTutorialPassed = false;
    }

    if (userDetails) {
      if (isWeb && isWebTutorialPassed) {
        setTutorialStep(15);

        sessionStorage.removeItem("tutorialStep");
      } else if (isWeb && !isWebTutorialPassed && tutorialStep < 1) {
        setTutorialStep(0);

        sessionStorage.setItem("tutorialStep", 0);
      } else if ((isMobile || isNativeView) && isMobileTutorialPassed) {
        setTutorialStep(15);

        sessionStorage.removeItem("tutorialStep");
        window?.webkit?.messageHandlers?.notifyNative?.postMessage(
          '{"Event": "Tutorial", "Action": "passed"}'
        );
      }

      setIsUserDataFetched(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (isRedeemGoldPopupOpen) {
      blurAllItems();
    }
  }, [isRedeemGoldPopupOpen]);

  const getModalPosition = (
    elm,
    scrollable = false,
    position = "top",
    type = "normal",
    isCustomElm = false,
    secondElm
  ) => {
    let classList = elm?.classList;

    blurAllItems();

    let positionProps;

    if (isCustomElm) {
      positionProps = elm;
    } else if (secondElm) {
      positionProps = getElementMiddlePosition(secondElm, position);
    } else {
      positionProps = getElementMiddlePosition(elm, position);
    }

    let modalPosition = {
      top: 0.0,
      left: 0.0,
    };

    const tooltipWidth = 400;
    const tooltipHeight =
      tutorialStep > 8 ? 197 : tooltipDimensions?.height || 197;

    switch (position) {
      case "top":
        modalPosition.top = positionProps?.top - 223 - 14;
        modalPosition.left = positionProps?.left - tooltipWidth / 2 - 20;
        break;
      case "bottom":
        modalPosition.top = positionProps?.top;
        modalPosition.left = positionProps?.left - tooltipWidth / 2 - 20;
        positionProps?.left - tooltipWidth / 2 - 20;
        break;
      case "left":
        modalPosition.top = positionProps?.top - tooltipHeight / 2 - 20;
        modalPosition.left = positionProps?.left - tooltipWidth - 55;
        break;
      case "right":
        modalPosition.top = positionProps?.top - tooltipHeight / 2 - 20;
        modalPosition.left = positionProps?.left - 17;
        break;
    }

    if (modalPosition?.left > window.innerWidth - tooltipWidth) {
      const diff = window.innerWidth - tooltipWidth;
      modalPosition.left = window.innerWidth - tooltipWidth - 40;
    }

    if (modalPosition?.left < 0) {
      modalPosition.left = 0;
    }

    setTooltipPositionUpdated(true);
    setTutorialElmOffset({
      top: Number(modalPosition.top?.toFixed(2)),
      left: Number(modalPosition?.left?.toFixed(2)),
    });
    setArrowPositionUpdated(true);

    setArrowProps({ positionProps, position, type: type });

    if (scrollable === true) {
      const rect = elm.getBoundingClientRect();
      if (position === "top") {
        if (!(tutorialStep === 12 && window.innerWidth < 1200)) {
          elm.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      } else if (position === "bottom" && tutorialStep > 6) {
        window.scrollTo(0, rect.top - 30 + window.scrollY);
      } else if (position === "left" || position === "right") {
        window.scrollTo(0, rect.top - 30 + window.scrollY);
      } else {
        elm.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }

    if (classList) {
      classList.add("except");
    }
  };

  useEffect(() => {
    if (buyCloseContainer) {
      if (tutorialStep === 1) {
        getModalPosition(balanceContainer, true, "bottom");
      } else if (tutorialStep === 2) {
        getModalPosition(depositBtn, true, "bottom");
      } else if (tutorialStep === 3 && !isRedeemGoldPopupOpen) {
        getModalPosition(buyGoldIcon, true, "bottom");
      } else if (tutorialStep === 4) {
        getModalPosition(redeemIcon, true, "bottom");
      } else if (tutorialStep === 5) {
        getModalPosition(yieldingIcon, true, "bottom");
      } else if (tutorialStep === 6) {
        getModalPosition(vaultingIcon, true, "bottom");
      } else if (tutorialStep === 7) {
        getModalPosition(monthlyPool, true, "right");
      } else if (
        tutorialStep === 8 &&
        !proceedBuyOrderClicked &&
        !proceedSellOrderClicked
      ) {
        getModalPosition(buyCloseContainer, true, "bottom");
      } else if (tutorialStep === 9) {
        getModalPosition(BTXG, true, "top");
      } else if (tutorialStep === 10) {
        getModalPosition(portfolio, true, "left");
      } else if (tutorialStep === 11) {
        if (window.innerWidth > 1200) {
          getModalPosition(transactions, true, "left");
        } else {
          getModalPosition(transactions, true, "top");
        }
      } else if (tutorialStep === 12) {
        if (window.innerWidth > 1200) {
          getModalPosition(newsContainer, true, "left");
        } else {
          getModalPosition(newsContainer, true, "top");
        }
      } else if (tutorialStep === 13) {
        blurAllItems();
      }
      setOldActiveStep(tutorialStep);
    }
  }, [
    isLoading,
    tutorialStep,
    buyCloseContainer,
    orientation,
    windowProps,
    isHeightUpdated,
    isRedeemGoldPopupOpen,
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
  ]);

  useEffect(() => {
    if (proceedBuyOrderClicked || proceedSellOrderClicked) {
      blurAllItems();
    }
  }, [proceedBuyOrderClicked, proceedSellOrderClicked]);

  return {
    isLoading,
    tutorialStep,
    isTutorialLoading,
  };
};
