import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import '../../../../styles/global.fonts.css';
import '../../../../styles/global.styles.css';
import BullionzLogo from '../../../../assets/images/bullionz-logo-coin.svg';
import TextField from '@mui/material/TextField';
import VaultBackgroundLayer from '../../../../assets/images/vault-background-layer.svg';
import { coinName, CURRENCY_SYMBOL, NON_VERIFIED } from '../../../../constants/globalconstants';
import { GoldToMoneyConverter } from '../../../../helpers/goldToMoneyConverter';
import { useAppContext } from '../../../../context/DataContext';
import CustomButton from '../../../common/CustomButton/CustomButton';
import clsx from 'clsx';
import serviceAutoInvest from '../../../../service/autoInvest';
import { handleInputKeyUp, handleInputKeyDown } from '../../../../helpers/inputValidator';
import IButton from '../../../common/IButton/IButton';
import ArrowUp from '../../../../assets/images/arrow-up-icon.svg';
import ArrowDown from '../../../../assets/images/arrow-down-icon.svg';
import NotificationMessagePopup from '../../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';
import buyServices from '../../../../service/buyService';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import { messages } from '../../../../constants/popupMessages';
import { isMarketOpen } from '../../../../helpers/isMarketOpen';
import NonVerifiedUserPopUp from '../../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import PleaseLoginPopUp from '../../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import SpecialOfferIconWhite from '../../../../assets/images/special-offer-icon-white.png';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import { generateCurrencySymbol } from '../../../../helpers/generateCurrencySymbol';
import isVerified from '../../../../helpers/isVerified';

const btnValues = [1, 2, 3]; // months
const orderVolumeValues = [1, 5, 10, 20, 100];

const AutoInvestCard = () => {
  const classes = useStyles();
  const [autoInvestQuantity, setAutoInvestQuantity] = useState(0);
  const [autoInvestPeriod, setAutoInvestPeriod] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    goldAskPrice,
    goldAskPriceForGroup,
    proceedAutoInvestOrderClicked,
    setProceedAutoInvestOrderClicked,
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
    nonVerifiedPopUpOpen,
    setNonVerifiedPopUpOpen,
  } = useAppContext();

  const showSpecialValueField = goldAskPrice !== goldAskPriceForGroup;

  // standard
  const autoInvestValue = GoldToMoneyConverter(autoInvestQuantity, goldAskPrice);

  // special
  const autoInvestValueForGroup = GoldToMoneyConverter(autoInvestQuantity, goldAskPriceForGroup);

  const handleIncrement = () => {
    if (autoInvestQuantity === '') {
      setAutoInvestQuantity(0);
    }
    if (autoInvestQuantity < 99999) {
      setAutoInvestQuantity((prevQuantity) => prevQuantity + 1);
    }
  };
  const handleDecrement = () => {
    if (autoInvestQuantity === '') {
      setAutoInvestQuantity(0);
    }
    if (autoInvestQuantity > 0) {
      setAutoInvestQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const handleQuantityInput = (value) => {
    setAutoInvestQuantity(value);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    var intValue = parseInt(value);
    if (isNaN(intValue)) {
      intValue = 0;
    }
    handleQuantityInput(intValue);
  };

  const handleChosenMonths = (months) => {
    setAutoInvestPeriod(months);
  };

  const isAutoInvestButtonDisabled = () => {
    return (
      autoInvestQuantity === '' ||
      autoInvestQuantity === '0' ||
      autoInvestQuantity === 0 ||
      isNaN(autoInvestQuantity) ||
      autoInvestPeriod === 0
    );
  };

  const handleAutoInvestClicked = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const autoInvestData = {
        volume: autoInvestQuantity,
        periodMonths: autoInvestPeriod,
      };

      setLoading(true);
      try {
        const autoInvest = await serviceAutoInvest.createAutoInvest(autoInvestData);
        const newBuy = {
          volume: autoInvestQuantity,
          clientPricePerAsset: goldAskPrice,
          autoInvestmentId: autoInvest.id,
        };
        await buyServices.createBuyRequest(newBuy);
        setAutoInvestPeriod(0);
        setAutoInvestQuantity(0);
        setProceedAutoInvestOrderClicked(true);
        setPopUpMessage(messages.orderSuccessful);
        setNotificationPopupType('success');
        setLoading(false);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setPopUpMessage(customError?.message);
        setNotificationPopupType('error');
        setProceedAutoInvestOrderClicked(true);
        setAutoInvestPeriod(0);
        setAutoInvestQuantity(0);
      } finally {
        setLoading(false);
      }
    } else {
      setPopUpMessage(messages.marketClosed);
      setNotificationPopupType('error');
      setProceedAutoInvestOrderClicked(true);
      setAutoInvestPeriod(0);
      setAutoInvestQuantity(0);
    }
  };
  const handleInputFocus = () => {
    if (autoInvestQuantity === 0) {
      setAutoInvestQuantity('');
    }
  };
  const handleChosenQuantity = (value) => {
    setAutoInvestQuantity(value);
  };
  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: 'var(--blue)',
      }}
    >
      <IButton tooltipTitle={tooltipContent.autoInvest} />
      <div className={classes.backgroundImage}>
        <img src={VaultBackgroundLayer} alt="Background Layer" className={classes.image} />
      </div>
      <Box className={clsx(classes.titleWrapper, classes.helperDiv)}>
        <Box className={classes.fieldTitle}>{'Auto Invest'}</Box>
        <Box className={classes.buttonContainer}>
          {btnValues.map((value) => (
            <div key={value} className={classes.monthButtons}>
              <CustomButton
                type={autoInvestPeriod === value ? 'monthBtnDarkBlue' : 'monthBtnBlue'}
                buttonText={value + ' M'}
                width="100%"
                height="100%"
                onClick={() => handleChosenMonths(value)}
              />
            </div>
          ))}
        </Box>
      </Box>

      <div className={classes.helperDiv}>
        <Box style={{ backgroundColor: 'var(--blue)' }} className={classes.field}>
          <div className={classes.bullionzGoldContainer}>
            <div className={classes.billionzTitleContainer}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={BullionzLogo} alt="Bullionz logo" />
              </div>
              <div className={classes.title}>Bullionz Gold </div>
              <div className={classes.coinName}>{coinName} </div>
            </div>
            <div
              className={classes.input}
              style={{
                backgroundColor: '#6493AD80',
              }}
            >
              <div className={classes.arrowButtonContainer}>
                <img className={classes.arrowButton} src={ArrowUp} alt="Arrow Up" onClick={handleIncrement} />
                <img className={classes.arrowButton} src={ArrowDown} alt="Arrow Down" onClick={handleDecrement} />
              </div>
              <div className={classes.inputField}>
                <TextField
                  onFocus={handleInputFocus}
                  className={classes.customInput}
                  autoComplete="off"
                  id="auto-invest-input"
                  value={autoInvestQuantity}
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  onKeyUp={handleInputKeyUp}
                  onKeyDown={handleInputKeyDown}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className={classes.grams}>gr</div>
          </div>
        </Box>
        <div className={classes.investBtn}>
          <CustomButton
            type="green"
            buttonText={'Invest'}
            disabled={isAutoInvestButtonDisabled()}
            width="100%"
            height="100%"
            onClick={handleAutoInvestClicked}
          />
        </div>
      </div>
      <div className={classes.totalFieldContainer}>
        <div className={classes.orderValueBtnContainer}>
          {orderVolumeValues.map((value) => (
            <div key={value} className={classes.quantityButton}>
              <CustomButton
                type={autoInvestQuantity === value ? 'monthBtnDarkBlue' : 'monthBtnBlue'}
                buttonText={value + ' gr'}
                width="100%"
                height="100%"
                onClick={() => handleChosenQuantity(value)}
              />
            </div>
          ))}
        </div>
        <div className={classes.totalField}>
          <div className={showSpecialValueField ? classes.value : classes.yourValue}>
            Value {generateCurrencySymbol() + autoInvestValue.toFixed(2)}
          </div>
          {showSpecialValueField && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '7px',
              }}
              className={classes.yourValue}
            >
              <img src={SpecialOfferIconWhite} alt="Special Offer" />
              Your Value {generateCurrencySymbol() + autoInvestValueForGroup.toFixed(2)}
              <div className={classes.tooltipContainer}>
                <IButton tooltipTitle={tooltipContent.autoInvestOrderSpecialValue} />
              </div>
            </div>
          )}
        </div>
      </div>
      {proceedAutoInvestOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedAutoInvestOrderClicked}
          setIsPopupOpen={setProceedAutoInvestOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
          handleCloseAutoInvestPopup={closeNonVerifiedPopUp}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={loading} />
    </div>
  );
};

export default AutoInvestCard;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    minHeight: '188px',
    width: '100%',
    border: '2px',
    borderRadius: '24px',
    left: 0,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
  backgroundImage: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '0',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
    borderRadius: '24px',
  },
  titleWrapper: {
    width: '65%',
    marginBottom: '1rem',
    paddingTop: '1rem',
    paddingLeft: '1rem',
    [theme.breakpoints.down('1280')]: {
      width: '63%',
    },
    [theme.breakpoints.down('780')]: {
      width: '64%',
    },
  },
  fieldTitle: {
    position: 'relative',
    display: 'flex',
    paddingLeft: '3%',
    color: 'var(--white)',
    fontSize: 'var(--font-size-24)',
    fontWeight: '600',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('1800')]: {
      fontSize: 'var(--font-size-22)',
    },
    [theme.breakpoints.down('1150')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('1000')]: {
      fontSize: 'var(--font-size-16)',
    },
  },
  helperDiv: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  investBtn: {
    width: '18%',
    height: '40px',
    position: 'relative',
    right: '7%',
    [theme.breakpoints.down('1300')]: {
      width: '15%',
      height: '30px',
    },
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-18) !important',
      fontWeight: '600',
      [theme.breakpoints.down('1300')]: {
        fontSize: 'var(--font-size-15) !important',
      },
      [theme.breakpoints.down('1000')]: {
        fontSize: 'var(--font-size-13) !important',
      },
      [theme.breakpoints.down('900')]: {
        fontSize: 'var(--font-size-10) !important',
      },
    },
  },
  coinName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    paddingLeft: '6%',
    fontSize: 'var(--font-size-19) !important',
    color: 'white',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('955')]: {
      fontSize: 'var(--font-size-16) !important',
    },
    [theme.breakpoints.down('865')]: {
      fontSize: 'var(--font-size-13) !important',
    },
  },
  input: {
    flex: '20%',
    display: 'flex',
    borderLeft: '1px solid #FFFFFF80',
    [theme.breakpoints.down('1610')]: {
      flex: '22%',
    },
    [theme.breakpoints.down('1480')]: {
      flex: '27%',
    },
    [theme.breakpoints.down('1180')]: {
      flex: '33%',
    },
    [theme.breakpoints.down('932')]: {
      flex: '39%',
    },
    [theme.breakpoints.down('785')]: {
      flex: '42%',
    },
  },
  arrowButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5%',
  },
  arrowButton: {
    margin: '58%',
    [theme.breakpoints.down('980')]: {
      width: '10px',
    },
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    border: '0.5px solid #D2D5D8',
    borderRadius: '5px',
    bottom: '3px',
    display: 'flex',
    width: '63%',
    minHeight: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'var(--font-size-16)',
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginLeft: '2rem',
  },
  bullionzGoldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  billionzTitleContainer: {
    flex: '75%',
    display: 'flex',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '2%',
  },
  logo: {
    width: '1.8rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    color: 'white',
    fontSize: 'var(--font-size-18)',
    fontFamily: theme.typography.fontFamily,
    marginLeft: '2%',
    [theme.breakpoints.down('973')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('955')]: {
      fontSize: 'var(--font-size-14)',
    },
    [theme.breakpoints.down('865')]: {
      fontSize: 'var(--font-size-12)',
    },
  },
  customInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    color: 'white',
    '& .MuiInputBase-input': {
      fontSize: 'var(--font-size-22)',
      fontWeight: '400',
      textAlign: 'right',
      color: 'white',
      [theme.breakpoints.down('1280')]: {
        fontSize: 'var(--font-size-19)',
      },
      [theme.breakpoints.down('1058')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('840')]: {
        fontSize: 'var(--font-size-14)',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
  },
  grams: {
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF80',
    justifyContent: 'center',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    width: '30px',
    color: '#345173',
  },
  totalFieldContainer: {
    top: '10px',
    display: 'flex',
    marginLeft: '2rem',
    justifyContent: 'space-between',
    width: '64%',
    position: 'relative',
    marginBottom: '29px',
    [theme.breakpoints.down('1987')]: {
      width: '90%',
    },
    [theme.breakpoints.down('950')]: {
      flexDirection: 'column',
    },
  },
  orderValueBtnContainer: {
    display: 'flex',
    gap: '4%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  quantityButton: {
    width: '96px',
    height: '36px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-18)',
      [theme.breakpoints.down('1450')]: {
        fontSize: 'var(--font-size-14)',
      },
      [theme.breakpoints.down('1000')]: {
        fontSize: 'var(--font-size-12)',
      },
    },
    [theme.breakpoints.down('1450')]: {
      width: '70px',
      height: '28px',
    },
    [theme.breakpoints.down('1350')]: {
      height: '32px',
    },
    [theme.breakpoints.down('1280')]: {
      height: '23px',
      width: '60px',
    },
    [theme.breakpoints.down('1000')]: {
      height: '20px',
      width: '57px',
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: '4%',
  },
  monthButtons: {
    width: '96px',
    height: '36px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-18)',
      [theme.breakpoints.down('1150')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('1000')]: {
        fontSize: 'var(--font-size-14)',
      },
    },
    [theme.breakpoints.down('1450')]: {
      width: '77px',
      height: '32px',
    },
    [theme.breakpoints.down('1350')]: {
      height: '32px',
    },
    [theme.breakpoints.down('1280')]: {
      height: '23px',
      width: '60px',
    },
    [theme.breakpoints.down('1000')]: {
      height: '20px',
      width: '57px',
    },
  },
  totalField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('950')]: {
      position: 'relative',
      alignItems: 'center',
      top: '10px',
    },
    color: 'white',
  },
  value: {
    fontSize: 'var(--font-size-15)',
    fontWeight: '400',
    [theme.breakpoints.down('1850')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('1700')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1390')]: {
      fontSize: 'var(--font-size-10)',
    },
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-9)',
    },
    [theme.breakpoints.down('1165')]: {
      fontSize: 'var(--font-size-8)',
    },
    [theme.breakpoints.down('1070')]: {
      fontSize: 'var(--font-size-7)',
    },
  },
  yourValue: {
    fontWeight: '700',
    fontSize: 'var(--font-size-18)',
    [theme.breakpoints.down('1850')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('1700')]: {
      fontSize: 'var(--font-size-15)',
    },
    [theme.breakpoints.down('1390')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1165')]: {
      fontSize: 'var(--font-size-11)',
    },
    [theme.breakpoints.down('1070')]: {
      fontSize: 'var(--font-size-9)',
    },
  },
  tooltipContainer: {
    position: 'relative',
    bottom: '19px',
    left: '27px',
  },
}));
