import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import clsx from "clsx";
import CustomButton from "../../../../../common/CustomButton/CustomButton";
import StakePopupMobile from "../../../../popups/StakePopup/StakePopupMobile";
import VaultPopupMobile from "../../../../popups/VaultPopup/VaultPopupMobile";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { useAppContext } from "../../../../../../context/DataContext";
import AutoInvestPopupMobile from "../../../../popups/AutoInvestPopup/AutoInvestPopupMobile";

function MobileStakingButtons({ firstButtonName, category, secondButtonText }) {
  const { isInHistory, setIsInHistory } = useAppContext();
  const classes = useStyles(isInHistory);
  const [isStakePopupOpen, setIsStakePopupOpen] = useState(false);
  const [isVaultPopupOpen, setIsVaultPopupOpen] = useState(false);
  const [isAutoInvestPopupOpen, setIsAutoInvestPopupOpen] = useState(false);
  const navigate = useNavigate();

  const handleCustomButtonClicked = () => {
    if (category === "stake") {
      setIsStakePopupOpen(true);
    } else if (category === "vault") {
      setIsVaultPopupOpen(true);
    } else if (category === "invest") {
      setIsAutoInvestPopupOpen(true);
    } else {
      console.log("there was some error");
    }
  };
  function navigateToStakingHistoryTable() {
    setIsInHistory(true);
    if (category === "stake") {
      navigate(routes.yieldingHistory);
    } else if (category === "invest") {
      navigate(routes.autoInvestHistory);
    } else {
      console.log("there was error with navigation");
    }
  }

  useEffect(() => {}, [isInHistory]);
  return (
    <Box>
      <Box className={clsx(classes.buttonContainer)}>
        <CustomButton
          type="green"
          onClick={handleCustomButtonClicked}
          buttonText={firstButtonName}
          width="100%"
          category={category}
        ></CustomButton>
      </Box>
      {!isInHistory && (
        <Box className={clsx(classes.buttonContainer, classes.stakingHistory)}>
          <Button
            category={category}
            onClick={navigateToStakingHistoryTable}
            variant="outlined"
          >
            {secondButtonText}
          </Button>
        </Box>
      )}
      {isStakePopupOpen && (
        <StakePopupMobile
          isPopupOpen={isStakePopupOpen}
          setIsPopupOpen={setIsStakePopupOpen}
        />
      )}
      {isVaultPopupOpen && (
        <VaultPopupMobile
          isPopupOpen={isVaultPopupOpen}
          setIsPopupOpen={setIsVaultPopupOpen}
        />
      )}
      {isAutoInvestPopupOpen && (
        <AutoInvestPopupMobile
          isPopupOpen={isAutoInvestPopupOpen}
          setIsPopupOpen={setIsAutoInvestPopupOpen}
        />
      )}
    </Box>
  );
}

export default MobileStakingButtons;

const useStyles = makeStyles(() => ({
  containerInHistory: {},
  buttonContainer: {
    marginTop: "0.5rem",
    width: "17rem",
    height: "2.5rem",
    textAlign: "center",
    "& button": {
      marginTop: "0px !important",
      fontWeight: "bold !important",
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  stakingHistory: {
    "& button": {
      color: "var(--black)",
      width: "100%",
      borderRadius: "24px",
      border: "1px solid var(--month-button-dark-green)",
      backgroundColor: "var(--white)",
    },
  },
}));
