import React from 'react';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const DepositFundsButtonMemoized = ({ classes, navigateToDeposit }) => {
  return (
    <div className={classes.depositBtnContainer} id="deposit-btn">
      <Button className={classes.depositFundsBtnReal} onClick={navigateToDeposit} startIcon={<AddCircleOutlineIcon />}>
        DEPOSIT FUNDS
      </Button>
    </div>
  );
};

export default React.memo(DepositFundsButtonMemoized);
