import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import VaultingHistory from "../Vaulting/VaultingHistory/VaultingHistory";
import WebLayout from "../../common/Layout/WebLayout";
import SeeAll from "../SeeAll/SeeAll";

function WebVaultingHistorySeeAll() {
  const classes = useStyles();
  return (
    <WebLayout>
      <SeeAll>
        <Box className={classes.containerVaultingHistory}>
          <VaultingHistory
            isSeeAll={false}
            containerClassName={classes.container}
            pageSize={10}
          />
        </Box>
      </SeeAll>
    </WebLayout>
  );
}

export default WebVaultingHistorySeeAll;

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: theme.typography.fontFamily,
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    display: "flex",
    marginBottom: "3%",
    backgroundColor: "white",
    width: "100%",
    height: "45.5rem",
    paddingTop: "1%",
    border: "0.5px #D2D5D8",
    borderRadius: "24px",
    justifyContent: "space-between",
    position: "relative",
    "& .MuiTabs-indicator": {
      backgroundColor: "black",
      height: "3px",
      width: "170px !important",
      left: "26px !important",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "bold",
    },
    "& .MuiTabs-root": {
      width: "500px",
    },
  },
  containerVaultingHistory: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "1rem",
  },
}));
