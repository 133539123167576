import * as React from 'react';
import { makeStyles } from '@mui/styles';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import { Box, Pagination, Tab, Tabs, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/index.js';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter.js';
import CustomButton from '../../common/CustomButton/CustomButton.js';
import BuyArrows from '../../../assets/images/TransactionsSell.svg';
import SellArrows from '../../../assets/images/sellArrows.svg';
import { formatDateWrapper } from '../../../helpers/dateFormatter.js';
import { useEffect } from 'react';
import { useAppContext } from '../../../context/DataContext.js';
import IButton from '../../common/IButton/IButton.js';
import { tooltipContent } from '../../../constants/tooltipTexts.js';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup.js';
import InvoiceGoldPopup from '../../common/Popups/InvoiceGoldPopup/InvoiceGoldPopup.jsx';
import clsx from 'clsx';
import { handleGetWebCryptoTransactions } from '../../../helpers/handleGetCryptoTransactions.js';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="span" component="span">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const TransactionsCryptoCard = ({ isSeeAll = true, containerClassName, pageSize }) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [dashBoardTransactionsHistory, setDashBoardTransactionsHistory] = useState([]);
  const [allPages, setAllPages] = useState();
  const [page, setPage] = useState(1);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const { refetch, selectedCryptoAccount } = useAppContext();

  const traderAccountId = selectedCryptoAccount?.brokerAccountId;

  const classes = useStyles({ isSeeAll });
  const [error, setError] = useState(false);

  const isDemoValue = sessionStorage.getItem('isDemo');
  const isDemo = isDemoValue === 'true';

  useEffect(() => {
    if (traderAccountId) {
      if (!isSeeAll) {
        handleGetWebCryptoTransactions(
          setDashBoardTransactionsHistory,
          traderAccountId,
          setAllPages,
          setError,
          pageSize,
          page,
        );
      } else {
        handleGetWebCryptoTransactions(
          setDashBoardTransactionsHistory,
          traderAccountId,
          setAllPages,
          setError,
          pageSize,
          page,
        );
      }
    }
  }, [page, traderAccountId, refetch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function navigateToTransactions(e) {
    e.preventDefault();
    navigate(routes.cryptoTransactions);
    window.scrollTo(0, 0);
  }

  function navigateToDashboard() {
    setPage(0);
    navigate(routes.crypto);
    window.scrollTo(0, 0);
  }

  function lengthAfterDecimal(number) {
    let numStr = number.toString();
    let decimalPos = numStr.indexOf('.');

    if (decimalPos === -1) {
      return 0;
    }

    return numStr.length - decimalPos - 1;
  }

  function getNumberWithLongerDecimal(price, requestedAmount) {
    let priceLength = lengthAfterDecimal(price);
    let requestedAmountLength = lengthAfterDecimal(requestedAmount);

    let longerLength = priceLength >= requestedAmountLength ? priceLength : requestedAmountLength;

    if (longerLength === 1) {
      return 2;
    }

    return longerLength;
  }

  return (
    <Box className={classes.transactionsContainerWrapper}>
      <Box className={containerClassName}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="secondary"
              className={isSeeAll ? classes.tabsTitle : undefined}
            >
              <Tab
                className={isSeeAll ? classes.transactionsLabel : classes.transactionsLabelSeeAll}
                label={'CRYPTO TRANSACTIONS'}
              />

              {isSeeAll && (
                <Tab
                  className={classes.seeAll}
                  href="/crypto-transactions-history"
                  onClick={navigateToTransactions}
                  label="See All"
                  disabled={isDemo}
                ></Tab>
              )}
              {isSeeAll && <IButton tooltipTitle={tooltipContent.cryptoTransactions} />}
            </Tabs>
            {!isSeeAll && <IButton tooltipTitle={tooltipContent.cryptoTransactions} />}
            <Divider className={classes.headerDivider} />
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className={isSeeAll ? classes.tableContainer : classes.tableContainerSeeAll}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={isSeeAll ? classes.rows : classes.columnHeader}></TableCell>
                      <TableCell className={isSeeAll ? classes.rows : classes.columnHeader} align="left">
                        Asset
                      </TableCell>
                      {!isSeeAll && (
                        <TableCell align="left">
                          <div className={isSeeAll ? classes.rows : classes.account}>Account</div>
                        </TableCell>
                      )}
                      {!isSeeAll && (
                        <TableCell align="left">
                          <div className={isSeeAll ? classes.rows : classes.order}>Order</div>
                        </TableCell>
                      )}
                      <TableCell className={isSeeAll ? classes.rows : classes.date} align="left">
                        Date
                      </TableCell>
                      <TableCell className={isSeeAll ? classes.rows : classes.quantity} align="left">
                        Quantity
                      </TableCell>
                      {!isSeeAll && (
                        <TableCell className={isSeeAll ? classes.rows : classes.columnHeader} align="left">
                          Amount
                        </TableCell>
                      )}
                      <TableCell className={isSeeAll ? classes.rows : classes.columnHeader} align="left">
                        Price
                      </TableCell>
                      {!isSeeAll && (
                        <TableCell className={isSeeAll ? classes.rows : classes.columnHeader} align="left">
                          Commission
                        </TableCell>
                      )}
                      <TableCell className={isSeeAll ? classes.rows : classes.test} align="left">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {dashBoardTransactionsHistory.length > 0 ? (
                      dashBoardTransactionsHistory.map((row) => {
                        const toFixedLength = getNumberWithLongerDecimal(row.requestedAmount, row.price);
                        const hasRealOrder = row?.order_id;
                        const isStoreTransaction = row?.use_fiat === true;
                        const formattedPrice = (row?.price || 0) * (row?.requestedAmount || 0);
                        return (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            key={row.orderId}
                          >
                            <TableCell
                              className={isSeeAll ? classes.rows : undefined}
                              align="left"
                              component="th"
                              scope="row"
                            >
                              {row.side === 'Buy' ? (
                                <img src={BuyArrows} alt="Buy Arrow Icon" className={classes.icon} />
                              ) : (
                                <img src={SellArrows} alt="Sell Arrow Icon" className={classes.icon} />
                              )}
                            </TableCell>

                            <TableCell
                              className={clsx(
                                isStoreTransaction && hasRealOrder && classes.storeCell,
                                isSeeAll && classes.rows,
                              )}
                              align="left"
                            >
                              {row?.marketDisplayName?.split('/')?.[0]?.toUpperCase()}
                            </TableCell>

                            {!isSeeAll && (
                              <TableCell align="left">
                                <div className={classes.accountTextOverflow}>
                                  <Tooltip title={row.orderId} placement="top-start">
                                    {row.brokerAccountId}
                                  </Tooltip>
                                </div>
                              </TableCell>
                            )}

                            {!isSeeAll && (
                              <TableCell align="left">
                                <div className={classes.orderTextOverflow}>
                                  <Tooltip title={row.orderId} placement="top-start">
                                    {row.orderId}
                                  </Tooltip>
                                </div>
                              </TableCell>
                            )}

                            <TableCell className={isSeeAll ? classes.dateRows : classes.dateRows} align="left">
                              {!isSeeAll
                                ? formatDateWrapper(new Date(row.date).getTime(), 'long')
                                : formatDateWrapper(new Date(row.date).getTime(), 'short')}
                            </TableCell>
                            <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                              {row?.requestedAmount}
                            </TableCell>

                            {!isSeeAll && (
                              <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                                {`$${(row.price * row.volume).toFixed(toFixedLength)}`}
                              </TableCell>
                            )}
                            <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                              {Number.isInteger(Number(row?.price))
                                ? `$${row.price}`
                                : currencyFormatter(
                                    Number(row?.price || 0),
                                    'transactions',
                                    'USD',
                                    false,
                                    false,
                                    toFixedLength,
                                  )}
                            </TableCell>

                            {!isSeeAll && (
                              <TableCell className={isSeeAll ? classes.rows : undefined} align="center">
                                {Number(row?.commission).toFixed(2)}
                              </TableCell>
                            )}

                            <TableCell className={isSeeAll ? classes.rows : undefined} align="left">
                              {row?.status?.toUpperCase()}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          No Recent Transactions
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
        </Box>
      </Box>

      <InvoiceGoldPopup />

      {!isSeeAll && (
        <Box className={classes.buttonsWrapper}>
          <Box>
            <Box className={classes.btn}>
              <CustomButton
                type="black"
                width="100%"
                height="24px"
                onClick={navigateToDashboard}
                buttonText="&#9664;&nbsp;&nbsp;Back to Crypto"
              ></CustomButton>
            </Box>
          </Box>
          {dashBoardTransactionsHistory.length > 0 && (
            <Box>
              <Box className={classes.paginationRoot}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={allPages}
                  page={page}
                  onChange={(event, newPage) => setPage(newPage)}
                />
              </Box>
            </Box>
          )}
          <Box></Box>
          <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
        </Box>
      )}
    </Box>
  );
};

export default TransactionsCryptoCard;

const useStyles = makeStyles((theme) => ({
  transactionsContainerWrapper: {
    height: '100%',
  },
  tableContainer: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    '& .MuiTableCell-root': {
      fontWeight: '600',
      fontSize: '16px',
      [theme.breakpoints.down('1680')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('1520')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down('1380')]: {
        fontSize: '10px',
      },
    },
    '& .MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },
  rows: (props) => ({
    paddingLeft: '8px',
    [theme.breakpoints.down('1380')]: {
      paddingLeft: '5px',
    },
  }),
  dateRows: (props) => ({
    paddingLeft: '8px',
    [theme.breakpoints.down('1380')]: {
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('950')]: {
      paddingLeft: props.tradeAccountsLength ? '0px' : '',
      paddingRight: props.tradeAccountsLength ? '0px' : '',
      fontSize: props.tradeAccountsLength > 1 ? 'var(--font-size-14)' : '',
    },
  }),
  tableContainerSeeAll: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    '& .MuiTableCell-root': {
      fontWeight: '600',
      fontSize: 'var(--font-size-18)',
      [theme.breakpoints.down('1400')]: {
        fontSize: 'var(--font-size-17)',
      },
      [theme.breakpoints.down('1300')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('1250')]: {
        paddingLeft: '6px',
        paddingRight: '6px',
        fontSize: 'var(--font-size-15)',
      },
      [theme.breakpoints.down('950')]: {
        paddingLeft: '6px',
        paddingRight: '6px',
        fontSize: 'var(--font-size-13)',
      },
      [theme.breakpoints.down('850')]: {
        paddingLeft: '6px',
        paddingRight: '6px',
        fontSize: 'var(--font-size-12)',
      },
    },
  },
  accountTextOverflow: {
    display: 'inline-block',
    verticalAlign: 'middle',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '10vw',
    [theme.breakpoints.down('850')]: {
      width: '8vw',
    },
  },
  orderTextOverflow: {
    display: 'inline-block',
    verticalAlign: 'middle',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '10vw',
    [theme.breakpoints.down('850')]: {
      width: '8vw',
    },
  },
  columnHeader: {
    width: '10vw',
    [theme.breakpoints.down('850')]: {
      width: '8vw',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      color: '#767676',
    },
  },
  tabsTitle: {
    minHeight: '7px',
    [theme.breakpoints.down('1200')]: {
      height: '2.5rem',
    },
  },
  date: {
    width: '10vw',
  },
  account: {
    display: 'inline-block',
    verticalAlign: 'middle',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '10vw',
    [theme.breakpoints.down('850')]: {
      width: '8vw',
    },
  },
  order: {
    display: 'inline-block',
    verticalAlign: 'middle',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '10vw',
    [theme.breakpoints.down('850')]: {
      width: '8vw',
    },
  },
  transactionsLabel: {
    height: 20,
    fontSize: 'var(--font-size-18)',
    paddingLeft: '24px',
    fontWeight: '600',
    color: 'var(--black) !important',
    fontFamily: 'Poppins',
    [theme.breakpoints.down('1645')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('1465')]: {
      fontSize: 'var(--font-size-14)',
    },
    [theme.breakpoints.down('1290')]: {
      fontSize: 'var(--font-size-12)',
    },
  },
  transactionsLabelSeeAll: {
    height: 20,
    fontSize: 'var(--font-size-18)',
    paddingLeft: '24px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    color: 'var(--black) !important',
  },
  seeAll: {
    textDecoration: 'none',
    color: 'var(--black)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: '1rem',
    opacity: 1,
    fontSize: 'var(--font-size-14)',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1465')]: {
      fontSize: 'var(--font-size-10)',
    },
  },
  headerDivider: {
    position: 'absolute',
    width: '100%',
  },
  paginationRoot: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingBottom: '3%',
    '& .MuiPagination-ul': {
      color: 'white',
      flexWrap: 'nowrap',
      '& li:first-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > button::after': {
          marginLeft: '2px',
          content: "'Prev'",
        },
      },
      '& li:last-child': {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > button::before': {
          marginRight: '2px',
          content: "'Next'",
        },
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'var(--black)',
      color: 'var(--white)',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },

  storeCell: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  btn: {
    textAlign: 'left',
    width: '12rem',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  icon: {
    maxWidth: '0.9rem',
    maxHeight: '0.9rem',
  },
}));
