import { Box, CircularProgress, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useAppContext } from '../../../../context/DataContext';
import currencyFormatter from '../../../../helpers/currencyFormatter/currencyFormatter';
import PositiveArrowIcon from '../../../../assets/images/arrow-up.svg';
import NegativeArrowIcon from '../../../../assets/images/arrow-down.svg';
import EqualArrowIcon from '../../../../assets/images/Exclude.svg';

import { Chart as ChartJs, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { CURRENCY_TYPE, GOLD_PRICE_DENSITY } from '../../../../constants/globalconstants';
import coinNameFormatter from '../../../../helpers/currencyFormatter/coinNameFormatter';
import { createLabel } from '../../../../helpers/createLabel';
import { getUniformDataSet } from '../../../../helpers/getUniformDistribution';
import { useEffect } from 'react';
import IButton from '../../../common/IButton/IButton';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import { generateCurrencySymbol } from '../../../../helpers/generateCurrencySymbol';

ChartJs.register(LineElement, CategoryScale, LinearScale, PointElement);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getUniformTimeForPeriod(uniformGoldPriceForPeriod) {
  return uniformGoldPriceForPeriod.map((uniformTime) => uniformTime.time);
}

function getUniformPriceForPeriod(uniformGoldPriceForPeriod) {
  return uniformGoldPriceForPeriod.map((uniformPrice) => uniformPrice.price);
}

function getPriceDifference(lastPrice, currentPrice) {
  let priceDifference = 0;
  const percentageDifference = ((currentPrice - lastPrice) / lastPrice) * 100;

  if (!isNaN(percentageDifference) && isFinite(percentageDifference)) {
    priceDifference = Number(percentageDifference).toFixed(2);
  } else {
    priceDifference = '';
  }

  return priceDifference;
}

function getAbsValueOfPriceDifference(lastPrice, currentPrice) {
  let priceDifference = Math.abs(currentPrice - lastPrice);

  if (!isNaN(priceDifference) && isFinite(priceDifference)) {
    priceDifference = Number(priceDifference).toFixed(2);
    priceDifference = priceDifference.toString().padStart(2, '0');
    priceDifference = generateCurrencySymbol() + priceDifference;
  } else {
    priceDifference = '';
  }

  return priceDifference;
}

function AutoInvestGoldHistory({ title, isInStaking }) {
  const [value, setValue] = useState(0);
  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  const [arrowDirection, setArrowDirection] = useState('equal');
  const {
    goldPriceForPeriod,
    setGoldHistoryPeriod,
    chosenPeriod,
    setChosenPeriod,
    goldAskPrice,
    smooth,
    setSmooth,
    currency,
  } = useAppContext();

  const classes = useStyles();

  const periods = ['1H', '1D', '3D', '1W', '1M'];

  const handleChange = (event, newValue) => {
    setSmooth(false);
    setValue(newValue);
    const selectedPeriod = periods[newValue];
    setGoldHistoryPeriod(selectedPeriod);
    setChosenPeriod(selectedPeriod);
  };

  let startDate = new Date(goldPriceForPeriod[0]?.time);
  startDate.setSeconds(0, 0);

  let uniformGoldPriceForPeriod = getUniformDataSet(goldPriceForPeriod, chosenPeriod, startDate, GOLD_PRICE_DENSITY);

  if (isDemo) {
    if (currency === 'EUR') {
      uniformGoldPriceForPeriod = uniformGoldPriceForPeriod.map((price) => {
        return {
          time: price.time,
          price: price.price / 1.08,
        };
      });
    }
  }

  const uniformTimeForPeriod = getUniformTimeForPeriod(uniformGoldPriceForPeriod);
  const uniformPriceForPeriod = getUniformPriceForPeriod(uniformGoldPriceForPeriod);

  let min = Math.min(...uniformPriceForPeriod);
  let max = Math.max(...uniformPriceForPeriod);
  const difference = Number((max - min).toFixed(2));
  let minValue = difference ? (min = min - difference * 0.1) : (min = min - 0.1 * min);
  let maxValue = difference ? (max = max + difference * 0.1) : (max = max + 0.1 * max);

  if (minValue < 0) {
    minValue = 0;
  }
  const firstPriceForThePeriod = uniformPriceForPeriod[0];

  const priceDifference = Number(((goldAskPrice - firstPriceForThePeriod) / firstPriceForThePeriod) * 100).toFixed(2);

  const data = {
    labels: createLabel(chosenPeriod, uniformTimeForPeriod),
    datasets: [
      {
        data: uniformPriceForPeriod,
        backgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointBorderWidth: 0,
        tension: 0.5,
        borderSkipped: false,
      },
    ],
  };
  const options = {
    responsive: true,
    animation: false,
    spanGaps: true,
    hover: {
      animationDuration: 0,
    },
    plugins: {
      legend: false,
      tooltip: {
        enabled: true,
        yAlign: 'bottom',
        displayColors: false,
        backgroundColor: '#E0C38B',
        font: (context) => {
          const containerHeight = context.chart.height;
          const fontSize = Math.floor(containerHeight / 15);
          return {
            size: fontSize,
          };
        },
        bodyFont: { weight: 'bold' },
        callbacks: {
          beforeLabel: (context) => {
            const dataIndex = context.dataIndex;
            const timeForPoint = uniformTimeForPeriod[dataIndex];
            let formattedTime;
            if (chosenPeriod === '1H' || chosenPeriod === '1D') {
              formattedTime = `${timeForPoint.getHours().toString().padStart(2, '0')}:${timeForPoint
                .getMinutes()
                .toString()
                .padStart(2, '0')}`;
            } else {
              formattedTime = `${timeForPoint.getDate().toString().padStart(2, '0')}.${(timeForPoint.getMonth() + 1)
                .toString()
                .padStart(2, '0')}.${timeForPoint.getFullYear()} ${timeForPoint
                .getHours()
                .toString()
                .padStart(2, '0')}:${timeForPoint.getMinutes().toString().padStart(2, '0')}`;
            }

            return `${formattedTime}`;
          },
          label: (context) => {
            // const datasetLabel = context.dataset.label || "";
            const value = context.parsed.y || 0;
            let currencySymbol = generateCurrencySymbol();
            return `${currencySymbol}${value}`;
          },
          title: () => {
            return ``;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: '#A3B8C2',
          maxRotation: 0,
          minRotation: 0,
          autoSkip: true,
          maxTicksLimit: 7,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
        position: 'right',
        min: minValue.toFixed(2),
        max: maxValue.toFixed(2),
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5,
          stepSize: 0.5,
          callback: function (value) {
            let currencySymbol = generateCurrencySymbol();
            const stringedValue = (currencySymbol + value).slice(0, 6);
            return stringedValue;
          },
        },
        font: (context) => {
          const containerHeight = context.chart.height;
          const fontSize = Math.floor(containerHeight / 12);
          return {
            size: fontSize,
          };
        },
      },
    },
    elements: {
      line: {
        backgroundColor: '#D7D7D7',
        borderColor: '#D7D7D7',
      },
    },
    bezierCurve: false,
  };
  const history = [data, data, data, data, data];

  useEffect(() => {
    if (priceDifference == 0) {
      setArrowDirection('equal');
    } else if (priceDifference > 0) {
      setArrowDirection('positive');
    } else if (priceDifference < 0) {
      setArrowDirection('negative');
    } else {
      setArrowDirection('empty');
    }
    if (!goldPriceForPeriod.length) {
      setArrowDirection('empty');
    }
  }, [priceDifference, window.location.pathname]);

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.leftWidgits}>
          <Box className={classes.coin}>{coinNameFormatter(title, 3, classes.goldenChars)}</Box>
          <IButton tooltipTitle={tooltipContent.goldPriceHistory} />
          <Box className={classes.price}>{currencyFormatter(goldAskPrice, CURRENCY_TYPE)}</Box>
          <Box className={classes.priceDirection}>
            {arrowDirection === 'equal' && <img className={classes.arrowImg} src={EqualArrowIcon} alt="Equal Arrow" />}
            {arrowDirection === 'positive' && (
              <img className={classes.arrowImg} src={PositiveArrowIcon} alt="Positive Arrow" />
            )}
            {arrowDirection === 'negative' && (
              <img className={classes.arrowImg} src={NegativeArrowIcon} alt="Negative Arrow" />
            )}
            {arrowDirection === 'empty' && null}
            {arrowDirection === 'empty' ? null : (
              <span className={classes.percentage}>
                {getPriceDifference(firstPriceForThePeriod, goldAskPrice)}% (
                {getAbsValueOfPriceDifference(firstPriceForThePeriod, goldAskPrice)})
              </span>
            )}
          </Box>
        </Box>
        <Box className={classes.tabs}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.tab}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            <Tab label="1 H" {...a11yProps(0)} />
            <Tab label="1 D" {...a11yProps(1)} />
            <Tab label="3 D" {...a11yProps(2)} />
            <Tab label="1 W" {...a11yProps(3)} />
            <Tab label="1 M" {...a11yProps(4)} />
          </Tabs>
        </Box>
      </Box>
      {goldPriceForPeriod.length ? (
        smooth ? (
          <Box className={classes.chartHolder}>
            {history.map((h, index) => (
              <TabPanel key={index} value={value} index={index}>
                <Box className={!isInStaking ? classes.chartWrapper : classes.chartWrapperInStaking}>
                  <Line width={'1080px'} height={'300px'} data={h} options={options}></Line>
                </Box>
              </TabPanel>
            ))}
          </Box>
        ) : (
          <Box className={classes.loader}>
            <CircularProgress
              style={{
                borderRadius: '50%',
                color: 'grey',
                animation: 'spin 1s linear infinite',
              }}
            />
          </Box>
        )
      ) : (
        <Box className={classes.noDataAvailable}>{smooth ? 'Currently no data available' : null}</Box>
      )}
    </Box>
  );
}

export default AutoInvestGoldHistory;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      backgroundColor: '#F9FAFD',
      borderRadius: '24px',
      height: '100%',
      fontFamily: theme.typography.fontFamily,
      padding: '1rem',
      background: 'linear-gradient(to bottom, white, #F9FAFD)',
    },
    chartHolder: {
      [theme.breakpoints.up('1300')]: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      },
      width: '100%',
    },
    tab: {
      minWidth: '7px',
      paddingTop: '10px',
      [theme.breakpoints.down('900')]: {
        marginBottom: '1rem',
      },
      '& .MuiTab-root': {
        minWidth: '30px',
        width: 'auto',
        minHeight: '22px',
        height: '100%',
        [theme.breakpoints.down('900')]: {
          minHeight: '16px',
          marginBottom: '1rem',
        },
      },
      '& .MuiTabs-flexContainer': {
        paddingLeft: '1rem',
      },
      '& button': {
        borderRadius: '8px',
        color: 'var(--black)',
        fontWeight: 500,
        lineHeight: '3px',
        [theme.breakpoints.down('1600')]: {
          fontSize: 'var(--font-size-14)',
        },
        [theme.breakpoints.down('1500')]: {
          fontSize: 'var(--font-size-13)',
        },
        [theme.breakpoints.down('1300')]: {
          fontSize: 'var(--font-size-12)',
        },
        [theme.breakpoints.down('1150')]: {
          fontSize: 'var(--font-size-12)',
          paddingBottom: '0.2rem',
          paddingTop: '0.2rem',
        },
        fontFamily: theme.typography.fontFamily,
      },
      '& button.Mui-selected': {
        backgroundColor: 'var(--beige) !important',
        color: 'var(--white)',
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
        boxShadow: '2.1235246658325195px 2.1235246658325195px 0px 0px #0000000D;',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      width: '100%',
    },
    priceDirection: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      width: '100%',
    },
    coin: {
      marginBottom: '3px',
      fontSize: 'var(--font-size-14)',
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-12)',
      },
    },
    price: {
      marginBottom: '3px',
      fontSize: 'var(--font-size-34)',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-22)',
      },
      [theme.breakpoints.down('1200')]: {
        fontSize: 'var(--font-size-16)',
      },
    },
    leftWidgits: {
      textAlign: 'left',
    },
    tabs: {
      overflowX: 'hidden',
    },
    chartWrapper: {
      width: '97%',
      height: '70%',
      position: 'absolute',
      bottom: '25px',
      paddingBottom: '0.5rem',
      aspectRatio: 2.2,
      right: '20px',
      [theme.breakpoints.down('1500')]: {
        bottom: '10px',
      },
      [theme.breakpoints.down('1300')]: {
        top: '150px',
      },
      [theme.breakpoints.down('1300')]: {
        top: '200px',
      },
    },
    percentage: {
      marginBottom: '3px',
      fontSize: 'var(--font-size-14)',
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('1800')]: {
        fontSize: 'var(--font-size-12)',
      },
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-9)',
        marginBottom: '0px',
        marginTop: '3px',
      },
    },
    goldenChars: {
      color: 'black',
    },
    arrowImg: {
      width: '19%',
      height: '19%',
      paddingRight: '8px',
      bottom: '2px',
      position: 'relative',
    },
    chartWrapperInStaking: {},
    noDataAvailable: {
      fontFamily: theme.typography.fontFamily,
      height: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: '25px',
    },
  }),
);
