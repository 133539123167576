import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import '../../../../styles/global.fonts.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import HorizontalStepperWeb from './HorizontalStepperWeb';
import SecondStepContentWeb from './SecondStepContentWeb';
import ThirdStepContentWeb from './ThirdStepContentWeb';
import MessageContentWeb from './MessageContentWeb';
import LoadingBar from '../../LoadingBar/LoadingBar';
import { redeemService } from '../../../../service/redeem';
import { useAppContext } from '../../../../context/DataContext';
import { isMarketOpen } from '../../../../helpers/isMarketOpen';
import { messages } from '../../../../constants/popupMessages';
import { NON_VERIFIED } from '../../../../constants/globalconstants';
import { routes } from '../../../../routes';
import NotificationMessagePopup from '../NotificationMessagePopup/NotificationMessagePopup';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../PleaseLoginPopup/PleaseLoginPopup';
import { userService } from '../../../../service/user';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import isVerified from '../../../../helpers/isVerified';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StoreGoldPopup = ({ activeStep, userDetails, isPopupOpen, setIsPopupOpen, setActiveStep }) => {
  const {
    shippingCity,
    shippingAddress,
    shippingCountry,
    country,
    shippingPostalCode,
    address,
    shippingState,
    city,
    state,
    postalCode,
  } = userDetails;
  const addresses = [
    {
      ID: shippingCountry ? shippingCountry?.id : country.id,
      addressLine: shippingCountry ? shippingAddress : address,
      city: shippingCountry ? shippingCity : city,
      countryName: shippingCountry ? shippingCountry?.name : country?.name,
      countryCode: shippingCountry ? shippingCountry?.countryCode : country?.countryCode,
      shippingState: shippingCountry ? shippingState : state,
      zipCode: shippingCountry ? shippingPostalCode : postalCode,
    },
  ];
  const classes = useStyles();
  const { isAppView, setProductsBucket, setNonVerifiedPopUpOpen, setProceedRedeemOrderClicked } = useAppContext();
  const [cartQuantity, setQuantity] = useState(0);
  const [cartFees, setCartFees] = useState();
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(addresses[0]?.ID);
  const [chosenAddress, setChosenAddress] = useState(addresses[0]);
  const [serviceFee, setServiceFee] = useState();
  const [isUseBTXG, setIsUseBTXG] = useState(false);
  const [partNumber, setPartNumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFees, setLoadingFees] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showTBC, setShowTBC] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const navigate = useNavigate();

  let quantityCounter = 0;
  const total = serviceFee + 0;
  const localStorageData = JSON.parse(localStorage.getItem('bucket'));

  const products = Object.keys(localStorageData).map((key) => {
    const { quantity } = localStorageData[key];
    const { part_number, weight } = localStorageData[key].product;

    quantityCounter += quantity * weight;
    return { quantity, partNumber: part_number };
  });

  const stepCounter = activeStep < 2 ? 'Step ' + (activeStep + 1) : message === 'success' ? 'Done' : '';

  const handleClose = () => {
    setActiveStep(0);
    setIsPopupOpen(false);
    if (message === 'success') {
      if (isAppView) {
        window?.webkit?.messageHandlers?.notifyNative?.postMessage('{"Event": "Dashboard"}');
      } else {
        navigate(routes.dashboard);
      }
    }
  };

  const handleChosenAddress = (address) => {
    setChosenAddress(address);
  };

  const calculateCartFees = async () => {
    try {
      setLoadingFees(true);
      const fees = await redeemService.calculateFees(
        {
          shippingInformation: {
            city: chosenAddress.city,
            address: chosenAddress.addressLine,
            zip: chosenAddress.zipCode,
            countryCode: chosenAddress.countryCode,
          },
          products,
        },
        true,
      );
      if (fees) {
        setCartFees(fees);
      }
    } catch (error) {
      let customError = null;

      customError = errorHandlingWrapper(error, customError);

      if (!products?.length) {
        setErrorPopup(true);
        setErrorMessage(`Your cart is empty due to changes you have made.`);
        setShowTBC(true);
      } else if (customError?.statusCode === 400) {
        setErrorPopup(true);
        if (customError?.message === 'Trading in Euro is not available at this time. Please try again later.') {
          setErrorMessage(customError.message);
        } else {
          setErrorMessage(messages.deliveryToAddressNotAvailable);
        }
        setShowTBC(true);
      } else {
        setShowTBC(false);
      }
      console.error('Error calculating cart fees:', error);
    } finally {
      setLoadingFees(false);
    }
  };

  useEffect(() => {
    if (activeStep === 1) {
      calculateCartFees();
    }
  }, [activeStep]);

  const handleClearCart = async () => {
    localStorage.setItem('bucket', JSON.stringify({}));
    setProductsBucket({});
    await userService.updateShoppingCart({});
  };

  const handleSubmit = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      setMessage('demo');
      return;
    }
    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      let totalPrice = isUseBTXG ? cartFees?.totalSumWithCoins : cartFees?.totalSum;

      const data = {
        totalPrice,
        products,
        useCoins: isUseBTXG,
        useFiat: true,
        fromStore: true,
        shippingInformation: {
          city: chosenAddress.city,
          address: chosenAddress.addressLine,
          zip: chosenAddress.zipCode,
          countryCode: chosenAddress.countryCode,
          ...(chosenAddress.countryCode === 'CA' && {
            state: chosenAddress.shippingState,
          }),
        },
        currency: localStorage.getItem('currency'),
      };

      setLoading(true);
      try {
        await redeemService.createRedeem(data);
        setMessage('success');
        setProceedRedeemOrderClicked(true);
        handleClearCart();

        if (isAppView) {
          window?.webkit?.messageHandlers?.notifyNative?.postMessage(`{"Event": "CartBadge", "Action": "0"}`);
        }
      } catch (error) {
        let customError = null;

        customError = errorHandlingWrapper(error, customError);

        setError(customError?.message);
        setMessage('error');
        setProceedRedeemOrderClicked(true);
      } finally {
        setIsAddNewAddress(false);
        setSelectedAddressId(addresses?.[0]?.ID || userDetails.shippingCountry?.id);
        setChosenAddress(addresses[0]);
        setServiceFee();
        setPartNumber('');
        setLoading(false);
      }
    } else {
      setIsAddNewAddress(false);
      setSelectedAddressId(addresses[0].ID || userDetails.shippingCountry?.id);
      setChosenAddress(addresses[0]);
      setServiceFee();
      setPartNumber('');
      setMessage('error');
      setError(messages.marketClosedHTML);
      setProceedRedeemOrderClicked(true);
    }
  };

  const handleCloseErrorPopup = () => {
    setErrorPopup(false);
    setErrorMessage('');
    setActiveStep(0);
    setShowTBC(false);
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.popupTitle}>
            <div className={classes.stepTitle}>BUY GOLD</div>
            {<Divider sx={{ height: '28px' }} orientation="vertical" flexItem />}
            <div className={classes.activeStep}>{stepCounter}</div>
          </BootstrapDialogTitle>
          <DialogContent>
            <div className={classes.stepperContainer}>
              {activeStep < 2 ? (
                <HorizontalStepperWeb
                  isStoreStepper
                  stepsComponents={[
                    <SecondStepContentWeb
                      key={1}
                      addresses={addresses}
                      isAddNewAddress={isAddNewAddress}
                      selectedAddressId={selectedAddressId}
                      setIsAddNewAddress={setIsAddNewAddress}
                      handleChosenAddress={handleChosenAddress}
                      setSelectedAddressId={setSelectedAddressId}
                      products={products}
                    />,
                    <ThirdStepContentWeb
                      key={2}
                      cartFees={cartFees}
                      isUseBTXG={isUseBTXG}
                      setIsUseBTXG={setIsUseBTXG}
                      chosenAddress={chosenAddress}
                      showTBC={showTBC}
                    />,
                  ]}
                  quantity={cartQuantity}
                  activeStep={activeStep}
                  partNumber={partNumber}
                  deliveryFee={0}
                  setActiveStep={setActiveStep}
                  isAddNewAddress={isAddNewAddress}
                  getDeliveryFee={() => {}}
                  handleSubmit={handleSubmit}
                  isRedeemFeeLoading={false}
                  setIsAddNewAddress={setIsAddNewAddress}
                  chosenAddress={chosenAddress}
                />
              ) : loading ? (
                <LoadingBar open={loading} />
              ) : (
                message !== 'demo' && <MessageContentWeb type={message} error={error} handleClose={handleClose} />
              )}
            </div>
          </DialogContent>
        </div>
        {errorPopup && (
          <NotificationMessagePopup
            type={'error'}
            isPopupOpen={errorPopup}
            setIsPopupOpen={handleCloseErrorPopup}
            message={errorMessage}
          />
        )}
        <PleaseLoginPopUp
          open={isPleaseLoginPopupOpen}
          setOpen={setIsPleaseLoginPopupOpen}
          handleClosePopups={handleCloseErrorPopup}
        />
        <LoadingBar open={loadingFees} />
      </BootstrapDialog>
    </div>
  );
};

export default StoreGoldPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      maxWidth: '700px',
      width: '100%',
      minWidth: '302px',
    },
  },
  popupContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    minHeight: '360px',
  },
  popupTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  stepTitle: {
    fontSize: 'clamp(16px, 2vw, 20px)',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '10px',
  },
  activeStep: {
    display: 'flex',
    fontSize: 'clamp(12px, 2vw, 16px)',
    fontWeight: '400',
    fontFamily: theme.typography.fontFamily,
    color: '#BFBFBF',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '10px',
  },

  stepperContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mainContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
