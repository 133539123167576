import { Box, createStyles } from '@mui/material';
import React from 'react';
import CustomButton from '../../common/CustomButton/CustomButton';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '../../../context/DataContext';
import { useState } from 'react';
import TransferFundsPopup from '../popups/TransferFundsPopup/TransferFundsPopup';
import CryptoIcon from '../../../../src/assets/images/crypto-icon-yellow.svg';
import GoldIcon from '../../../../src/assets/images/goldIcon.svg';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';

function MobileTradingAccounts() {
  const classes = useStyles();
  const {
    activeTradeAccountId,
    setActiveTradeAccountId,
    setActiveTradeAccountCurrency,
    setActiveTradeAccountBalance,
    setCurrency,
    userTradeAccounts,
    userBrokerAccounts,
  } = useAppContext();
  const [isTransferFundsPopUpOpen, setIsTransferFundsPopUpOpen] = useState(false);

  const handleTradeAccountClicked = (account) => {
    setCurrency(account.currency);
    setActiveTradeAccountBalance(account.balance);
    setActiveTradeAccountId(account.tradeAccountId);
    setActiveTradeAccountCurrency(account.currency);

    localStorage.setItem('taid', account.tradeAccountId);
    localStorage.setItem('currency', account.currency);
  };

  const handleTransferFunds = () => {
    setIsTransferFundsPopUpOpen(true);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>Wallets</Box>
      <Box className={classes.accounts}>
        {userTradeAccounts.length > 0 ? (
          userTradeAccounts.map((account, index) => (
            <Box
              key={index}
              className={activeTradeAccountId === account.tradeAccountId ? classes.account : classes.accountInactive}
              onClick={() => handleTradeAccountClicked(account)}
            >
              <div className={classes.leftAccount}>
                <Box className={classes.accountIcon}>
                  <img src={GoldIcon} alt="gold icon" className={classes.goldIcon} />
                </Box>
                <Box className={classes.infoHolder}>
                  <Box>
                    <span className={classes.label}>Gold Wallet</span>
                  </Box>

                  <Box>
                    {/* <span className={classes.label}>ID :</span> */}
                    <span className={classes.value}>{account.tradeAccountId}</span>
                  </Box>

                  <Box>
                    <span className={classes.balanceLabel}>Balance </span>
                    <span className={classes.balanceValue}>
                      {currencyFormatter(account?.balance, '', account?.currency, true)}
                    </span>
                  </Box>
                </Box>
              </div>

              <Box className={classes.radioButtonWrapper}>
                <input
                  type="radio"
                  style={{
                    accentColor: '#347362',
                    cursor: 'pointer',
                  }}
                  id={`tradeAccount${index}`}
                  name="tradeAccount4"
                  value={account.tradeAccountId}
                  checked={activeTradeAccountId === account.tradeAccountId}
                  onClick={() => handleTradeAccountClicked(account)}
                />
              </Box>
            </Box>
          ))
        ) : (
          <></>
        )}

        {userBrokerAccounts && userBrokerAccounts?.length > 0 ? (
          userBrokerAccounts.map((account, index) => {
            const price = account?.assets?.find((a) => a?.assetId === 'usdt')?.amount || 0;

            return (
              <Box key={index} className={classes.accountCrypto}>
                <div className={classes.leftAccount}>
                  <Box className={classes.accountIcon}>
                    <img src={CryptoIcon} alt="Crypto Icon" className={classes.goldIcon} />
                  </Box>
                  <Box className={classes.infoHolder}>
                    <Box>
                      {index > 0 ? (
                        <span className={classes.label}>Crypto Wallet {index + 1}</span>
                      ) : (
                        <span className={classes.label}>Crypto Wallet</span>
                      )}
                    </Box>

                    <Box>
                      {/* <span className={classes.label}>ID :</span> */}
                      <span className={classes.value}>{account?.brokerAccountId}</span>
                    </Box>

                    <Box>
                      <span className={classes.balanceLabel}>Balance </span>
                      <span className={classes.balanceValue}>{currencyFormatter(price, '', 'USDT', true)}</span>
                    </Box>
                  </Box>
                </div>
                <Box className={classes.radioButtonWrapper}></Box>
              </Box>
            );
          })
        ) : (
          <></>
        )}
      </Box>
      <Box className={classes.buttonWrapper}>
        <CustomButton
          type="black"
          buttonText="Transfer Funds"
          width={window.innerWidth > window.innerHeight ? '20%' : '45%'}
          height="100%"
          fontSize="10px"
          onClick={handleTransferFunds}
        ></CustomButton>
      </Box>
      {isTransferFundsPopUpOpen && (userTradeAccounts?.length || userBrokerAccounts?.length) && (
        <TransferFundsPopup
          isPopupOpen={isTransferFundsPopUpOpen}
          setIsPopupOpen={setIsTransferFundsPopUpOpen}
          userTradeAccounts={userTradeAccounts}
          userBrokerAccounts={userBrokerAccounts}
        />
      )}
    </Box>
  );
}

export default MobileTradingAccounts;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    buttonWrapper: {
      marginTop: '1rem',
      maxHeight: '2rem',
      height: '3rem',
    },
    tradingAccountWrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '3rem',
    },
    tradingAccounts: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      width: '80%',
      height: '100%',
      backgroundColor: '#F7F7F7',
      border: '1px solid #D2D5D8',
      borderRadius: '4px',
      gap: '15px',
      fontSize: '14px',
    },
    tradeAccountContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '80%',
    },
    divider: {
      color: '#767676',
      border: '1px solid',
    },
    accountId: {
      fontWeight: 600,
      color: '#767676',
    },

    title: {
      fontSize: 'clamp(var(--font-size-20), var(--font-size-18), var(--font-size-16)) !important',
      fontWeight: 600,
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    accounts: {
      width: '95%',
      margin: '0 auto',
    },
    account: {
      maxWidth: '100%',
      height: '100%',
      border: '1px solid #B0B0B0',
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      justifyContent: 'space-between',
      backgroundColor: 'var(--white)',
      boxShadow: 'inset 0rem 3.1852872371673584px #76767633',
      marginBottom: '0.5rem',
      paddingLeft: '20px',
    },
    accountInactive: {
      maxWidth: '100%',
      height: '100%',
      border: '1px solid #B0B0B0',
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '8px',
      backgroundColor: '#FBFBFB',
      boxShadow: 'inset 0rem 3.1852872371673584px #76767633',
      marginBottom: '0.5rem',
      paddingLeft: '20px',
    },
    accountCrypto: {
      maxWidth: '100%',
      height: '100%',
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'var(--white)',
      paddingLeft: '20px',
      borderTop: '1px solid #B0B0B0',
    },
    balanceLabel: {
      fontWeight: 600,
      color: '#767676',
      fontSize: '14px',
    },
    balanceValue: {
      fontSize: '14px',
      color: '#767676',
    },
    radioButtonWrapper: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    accountIcon: {},
    infoHolder: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'left',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
    goldIcon: {
      maxWidth: '33px',
      maxHeight: '33px',
    },
    leftAccount: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
    },

    label: {
      textAlign: 'left',
      fontWeight: 600,
      color: '#767676',
    },
    value: {
      fontSize: '12px',
      color: '#767676',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
);
