import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import CustomButton from '../CustomButton/CustomButton';
import BullionzLogo from '../../../assets/images/bullionz-logo-coin.svg';
import TextField from '@mui/material/TextField';
import StakeBackgroundLayer from '../../../assets/images/stake-background-layer.svg';
import VaultBackgroundLayer from '../../../assets/images/vault-background-layer.svg';
import { coinName } from '../../../constants/globalconstants';
import { CURRENCY_SYMBOL, NON_VERIFIED } from '../../../constants/globalconstants';
import serviceVaulting from '../../../service/vaulting';
import { useAppContext } from '../../../context/DataContext';
import IButton from '../IButton/IButton';
import { handleInputKeyUp, handleInputKeyDown } from '../../../helpers/inputValidator';
import ArrowUp from '../../../assets/images/arrow-up-icon.svg';
import ArrowDown from '../../../assets/images/arrow-down-icon.svg';
import SpecialOfferIconWhite from '../../../assets/images/special-offer-icon-white.png';
import LoadingBar from '../LoadingBar/LoadingBar';
import NotificationMessagePopup from '../Popups/NotificationMessagePopup/NotificationMessagePopup';
import serviceStaking from '../../../service/staking';
import { tooltipContent } from '../../../constants/tooltipTexts';
import { messages } from '../../../constants/popupMessages';
import { isMarketOpen } from '../../../helpers/isMarketOpen';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../Popups/PleaseLoginPopup/PleaseLoginPopup';
import { useUserFees } from './user-fees';
import { errorHandlingWrapper } from '../../../helpers/errorHandlingWrapper';
import { generateCurrencySymbol } from '../../../helpers/generateCurrencySymbol';
import NonVerifiedUserPopUp from '../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import isVerified from '../../../helpers/isVerified';

const monthBtnValues = [3, 6, 9, 12]; // months
const orderVolumeValues = [1, 5, 10, 20, 100];

const StakeVaultCard = ({ type }) => {
  const classes = useStyles();
  const [stakingQuantity, setStakingQuantity] = useState(0);
  const [vaultingQuantity, setVaultingQuantity] = useState(0);
  const [stakingPeriod, setStakingPeriod] = useState(0);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { stakeFee, stakeFeeForGroup, vaultFee, vaultFeeFlat, vaultFeeForGroup, vaultFeeForGroupFlat } = useUserFees(); // pass userGroupId

  const {
    goldAskPrice,
    goldBidPrice,
    goldAskPriceForGroup,
    goldBidPriceForGroup,
    proceedVaultOrderClicked,
    nonVerifiedPopUpOpen,
    setProceedVaultOrderClicked,
    proceedStakeOrderClicked,
    setProceedStakeOrderClicked,
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
    setNonVerifiedPopUpOpen,
  } = useAppContext();

  const isDemoMode = sessionStorage.getItem('isDemo') === 'true';

  const showSpecialFeeField = goldAskPrice !== goldAskPriceForGroup;
  const showSpecialProfitField = goldBidPrice !== goldBidPriceForGroup;

  // YIELD
  let expectedRewardPerCoin = (stakeFee * goldBidPrice).toFixed(2);
  const expectedRewardForGroupPerCoin = (stakeFeeForGroup * goldBidPriceForGroup).toFixed(2);

  const expectedRewardForGroup = expectedRewardForGroupPerCoin * stakingQuantity * stakingPeriod || 0; // special

  // VAULT

  let monthlyFee = vaultingQuantity * goldAskPrice * vaultFee + vaultFeeFlat;

  if (isDemoMode) {
    monthlyFee = 0.01 * goldAskPrice * vaultingQuantity;
    expectedRewardPerCoin = (0.01 * goldBidPrice).toFixed(2);
  }

  const expectedReward = expectedRewardPerCoin * stakingQuantity * stakingPeriod || 0; // standard

  const monthlyFeeForGroup = vaultingQuantity * goldAskPriceForGroup * vaultFeeForGroup + vaultFeeForGroupFlat;

  const handleIncrement = () => {
    if (type === 'stake' && stakingQuantity < 99999) {
      if (stakingQuantity === '') {
        setStakingQuantity(0);
      }
      setStakingQuantity((prevQuantity) => prevQuantity + 1);
    } else if (type === 'vault' && vaultingQuantity < 99999) {
      if (vaultingQuantity === '') {
        setVaultingQuantity(0);
      }
      setVaultingQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    if (type === 'stake' && stakingQuantity > 0) {
      if (stakingQuantity === '') {
        setStakingQuantity(0);
      }
      setStakingQuantity((prevQuantity) => prevQuantity - 1);
    } else if (type === 'vault' && vaultingQuantity > 0) {
      if (vaultingQuantity === '') {
        setVaultingQuantity(0);
      }
      setVaultingQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleQuantityInput = (value) => {
    if (type === 'stake') {
      setStakingQuantity(value);
    } else {
      setVaultingQuantity(value);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    var intValue = parseInt(value); // parsing for preventing entering 0 at the front
    if (isNaN(intValue)) {
      intValue = 0;
    }
    handleQuantityInput(intValue);
  };

  const handleChosenMonths = (months) => {
    setStakingPeriod(months);
  };

  const handleStakeOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const stakeData = {
        volume: stakingQuantity,
        stakePeriodMonths: stakingPeriod,
      };
      setLoading(true);
      try {
        await serviceStaking.createStake(stakeData);
        setPopUpMessage(messages.orderSuccessful);
        setLoading(false);
        setProceedStakeOrderClicked(true);
        setNotificationPopupType('success');
        setStakingQuantity(0);
        setStakingPeriod(0);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setPopUpMessage(customError?.message);
        setNotificationPopupType('success');
        setProceedStakeOrderClicked(true);
        setNotificationPopupType('error');
        setStakingQuantity(0);
        setStakingPeriod(0);
      } finally {
        setLoading(false);
      }
    } else {
      setPopUpMessage(messages.marketClosed);
      setProceedStakeOrderClicked(true);
      setNotificationPopupType('error');
      setStakingQuantity(0);
      setStakingPeriod(0);
    }
  };

  const handleVaultOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const vaultData = {
        volume: vaultingQuantity,
      };
      setLoading(true);
      try {
        await serviceVaulting.createVault(vaultData);
        setPopUpMessage(messages.orderSuccessful);
        setNotificationPopupType('success');
        setProceedVaultOrderClicked(true);

        setVaultingQuantity(0);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setPopUpMessage(customError?.message);
        setProceedVaultOrderClicked(true);
        setNotificationPopupType('error');
        setVaultingQuantity(0);
      } finally {
        setLoading(false);
      }
    } else {
      setPopUpMessage(messages.marketClosed);
      setProceedVaultOrderClicked(true);
      setNotificationPopupType('error');
      setVaultingQuantity(0);
    }
  };

  const isStakeButtonDisabled = () => {
    return (
      stakingQuantity === '' ||
      stakingQuantity === '0' ||
      stakingQuantity === 0 ||
      isNaN(stakingQuantity) ||
      stakingPeriod === 0
    );
  };

  const isVaultButtonDisabled = () => {
    return vaultingQuantity === '' || vaultingQuantity === '0' || vaultingQuantity === 0 || isNaN(vaultingQuantity);
  };

  const handleInputFocus = () => {
    if (type === 'stake' && stakingQuantity === 0) {
      setStakingQuantity('');
    } else if (type === 'vault' && vaultingQuantity === 0) {
      setVaultingQuantity('');
    }
  };

  const handleChosenQuantity = (value) => {
    if (type === 'stake') {
      setStakingQuantity(value);
    } else if (type === 'vault') {
      setVaultingQuantity(value);
    }
  };
  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: type === 'stake' ? '#347362' : '#666666',
      }}
    >
      <IButton tooltipTitle={type === 'stake' ? tooltipContent.staking : tooltipContent.vaulting} />
      <div className={classes.backgroundImage}>
        <img
          src={type === 'stake' ? StakeBackgroundLayer : VaultBackgroundLayer}
          alt="Background Layer"
          className={classes.image}
        />
      </div>
      <Box className={classes.fieldTitle}>
        {type === 'stake' ? 'Yield' : 'Vaulting'}
        <div
          style={{ justifyContent: type === 'stake' ? 'space-between' : 'end' }}
          className={type === 'stake' ? classes.monthButtons : classes.totalFieldContainer}
        >
          {type === 'stake' ? (
            <div className={classes.buttonContainer}>
              {monthBtnValues.map((value) => (
                <div key={value} className={classes.greenButton}>
                  <CustomButton
                    type={stakingPeriod === value ? 'monthBtnGreener' : 'monthBtnDarkGreen'}
                    buttonText={value + ' M'}
                    width="100%"
                    height="100%"
                    onClick={() => handleChosenMonths(value)}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </Box>
      <div className={classes.helperDiv}>
        <Box style={{ backgroundColor: type === 'stake' ? '#347362' : '#666666' }} className={classes.field}>
          <div className={classes.bullionzGoldContainer}>
            <div className={classes.billionzTitleContainer}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={BullionzLogo} alt="Bullionz logo" />
              </div>
              <div className={classes.title}>Bullionz Gold </div>
              <div className={classes.coinName}>{coinName} </div>
            </div>
            <div
              className={classes.input}
              style={{
                backgroundColor: type === 'stake' ? '#64AD9480' : '#92929280',
              }}
            >
              <div className={classes.arrowButtonContainer}>
                <img className={classes.arrowButton} src={ArrowUp} alt="Arrow Up" onClick={handleIncrement} />
                <img className={classes.arrowButton} src={ArrowDown} alt="Arrow Down" onClick={handleDecrement} />
              </div>
              <div className={classes.inputField}>
                <TextField
                  className={classes.customInput}
                  autoComplete="off"
                  onFocus={handleInputFocus}
                  id="stake-vault-input"
                  value={type === 'stake' ? stakingQuantity : vaultingQuantity}
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  onKeyUp={handleInputKeyUp}
                  onKeyDown={handleInputKeyDown}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div
              className={classes.grams}
              style={{
                color: type === 'stake' ? '#347362' : '#666666',
              }}
            >
              gr
            </div>
          </div>
        </Box>
        <div className={classes.button}>
          <CustomButton
            type="green"
            buttonText={type === 'stake' ? 'Yield Gold' : 'Vaulting'}
            width="100%"
            height="100%"
            disabled={type === 'stake' ? isStakeButtonDisabled() : isVaultButtonDisabled()}
            onClick={type === 'stake' ? handleStakeOrder : handleVaultOrder}
          />
        </div>
      </div>
      <div className={classes.totalContainer}>
        <div className={classes.orderValueBtnContainer}>
          {orderVolumeValues.map((value) => (
            <div key={value} className={classes.greenButton}>
              <CustomButton
                type={
                  type === 'stake'
                    ? stakingQuantity === value
                      ? 'monthBtnGreener'
                      : 'monthBtnDarkGreen'
                    : vaultingQuantity === value
                    ? 'volumeBtnDarkGrey'
                    : 'volumeBtnGrey'
                }
                buttonText={value + ' gr'}
                width="100%"
                height="100%"
                onClick={() => handleChosenQuantity(value)}
              />
            </div>
          ))}
        </div>
        <div>
          {type === 'stake' ? (
            <div className={classes.totalField}>
              <div className={showSpecialProfitField ? classes.estimatedProfit : classes.yourProfit}>
                Estimated Profit {generateCurrencySymbol() + expectedReward.toFixed(2)}
              </div>
              {showSpecialProfitField && !isDemoMode && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '7px',
                  }}
                  className={classes.yourProfit}
                >
                  <img src={SpecialOfferIconWhite} alt="Special Offer" />
                  Your Profit {generateCurrencySymbol() + expectedRewardForGroup.toFixed(2)}
                  <div className={classes.tooltipContainer}>
                    <IButton tooltipTitle={tooltipContent.yieldingYourProfit} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={classes.totalField}>
              <div className={showSpecialFeeField ? classes.estimatedProfit : classes.yourProfit}>
                Monthly Fee {generateCurrencySymbol() + monthlyFee.toFixed(2)}
              </div>
              {showSpecialFeeField && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '7px',
                  }}
                  className={classes.yourProfit}
                >
                  <img src={SpecialOfferIconWhite} alt="Special Offer" />
                  Your Fee {generateCurrencySymbol() + monthlyFeeForGroup.toFixed(2)}
                  <div className={classes.tooltipContainer}>
                    <IButton tooltipTitle={tooltipContent.vaultingYourFee} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {proceedVaultOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedVaultOrderClicked}
          setIsPopupOpen={setProceedVaultOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      {proceedStakeOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedStakeOrderClicked}
          setIsPopupOpen={setProceedStakeOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
          handleCloseAutoInvestPopup={closeNonVerifiedPopUp}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={loading} />
    </div>
  );
};

export default StakeVaultCard;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    minHeight: '188px',
    width: '100%',
    border: '2px',
    borderRadius: '24px',
    left: 0,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
  backgroundImage: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '0',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
    borderRadius: '24px',
  },
  fieldTitle: {
    position: 'relative',
    display: 'flex',
    paddingLeft: '3.4%',
    paddingTop: '1rem',
    paddingBottom: '0px',
    top: '2px',
    color: 'var(--white)',
    fontSize: 'var(--font-size-24)',
    fontWeight: '600',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('1800')]: {
      fontSize: 'var(--font-size-22)',
    },
    [theme.breakpoints.down('1150')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('1000')]: {
      fontSize: 'var(--font-size-16)',
    },
  },
  helperDiv: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    width: '18%',
    height: '40px',
    position: 'relative',
    right: '7%',
    [theme.breakpoints.down('1300')]: {
      width: '15%',
      height: '30px',
    },
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-18) !important',
      fontWeight: '600',
      [theme.breakpoints.down('1300')]: {
        fontSize: 'var(--font-size-12) !important',
      },
      [theme.breakpoints.down('1000')]: {
        fontSize: 'var(--font-size-10) !important',
      },
      [theme.breakpoints.down('900')]: {
        fontSize: 'var(--font-size-8) !important',
      },
    },
  },
  field: {
    border: '0.5px solid #D2D5D8',
    borderRadius: '5px',
    bottom: '3px',
    display: 'flex',
    width: '63%',
    minHeight: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'var(--font-size-16)',
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginLeft: '2rem',
  },
  bullionzGoldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  billionzTitleContainer: {
    flex: '75%',
    display: 'flex',
  },
  input: {
    flex: '20%',
    display: 'flex',
    borderLeft: '1px solid #FFFFFF80',
    [theme.breakpoints.down('1610')]: {
      flex: '22%',
    },
    [theme.breakpoints.down('1480')]: {
      flex: '27%',
    },
    [theme.breakpoints.down('1180')]: {
      flex: '33%',
    },
    [theme.breakpoints.down('932')]: {
      flex: '39%',
    },
    [theme.breakpoints.down('785')]: {
      flex: '42%',
    },
  },
  grams: {
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF80',
    justifyContent: 'center',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    width: '30px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '2%',
  },
  logo: {
    width: '1.8rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    color: 'white',
    fontSize: 'var(--font-size-18)',
    fontFamily: theme.typography.fontFamily,
    marginLeft: '2%',
    [theme.breakpoints.down('973')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('955')]: {
      fontSize: 'var(--font-size-14)',
    },
    [theme.breakpoints.down('865')]: {
      fontSize: 'var(--font-size-12)',
    },
  },
  coinName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    paddingLeft: '6%',
    fontSize: 'var(--font-size-19) !important',
    color: 'white',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('955')]: {
      fontSize: 'var(--font-size-16) !important',
    },
    [theme.breakpoints.down('865')]: {
      fontSize: 'var(--font-size-13) !important',
    },
  },
  arrowButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5%',
  },
  arrowButton: {
    margin: '58%',
    [theme.breakpoints.down('980')]: {
      width: '10px',
    },
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
  },
  customInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    color: 'white',
    '& .MuiInputBase-input': {
      fontSize: 'var(--font-size-22)',
      fontWeight: '400',
      textAlign: 'right',
      color: 'white',
      [theme.breakpoints.down('1280')]: {
        fontSize: 'var(--font-size-19)',
      },
      [theme.breakpoints.down('1058')]: {
        fontSize: 'var(--font-size-16)',
      },
      [theme.breakpoints.down('840')]: {
        fontSize: 'var(--font-size-14)',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
  },
  monthButtons: {
    display: 'flex',
    position: 'relative',
    top: '1px',
    overflow: 'hidden',
    paddingLeft: '4.5rem',
    marginBottom: '1.5rem',
    width: '63%',
    [theme.breakpoints.down('1000')]: {
      width: '88%',
    },
  },
  totalFieldContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '66%',
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '3.438rem',
    [theme.breakpoints.down('1360')]: {
      width: '73%',
    },
    [theme.breakpoints.down('1024')]: {
      width: '85%',
    },
    [theme.breakpoints.down('850')]: {
      width: '92%',
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: '4%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orderValueBtnContainer: {
    display: 'flex',
    gap: '4%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  greenButton: {
    width: '79px',
    height: '31px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-15)',
      [theme.breakpoints.down('1450')]: {
        fontSize: 'var(--font-size-14)',
      },
      [theme.breakpoints.down('1000')]: {
        fontSize: 'var(--font-size-12)',
      },
    },
    [theme.breakpoints.down('1450')]: {
      width: '70px',
      height: '28px',
    },
    [theme.breakpoints.down('1350')]: {
      height: '32px',
    },
    [theme.breakpoints.down('1280')]: {
      height: '23px',
      width: '60px',
    },
    [theme.breakpoints.down('1000')]: {
      height: '20px',
      width: '57px',
    },
  },
  totalContainer: {
    top: '10px',
    display: 'flex',
    marginLeft: '2rem',
    justifyContent: 'space-between',
    width: '64%',
    position: 'relative',
    marginBottom: '29px',
    [theme.breakpoints.down('1660')]: {
      width: '90%',
    },
    [theme.breakpoints.down('950')]: {
      flexDirection: 'column',
    },
  },
  totalField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('950')]: {
      position: 'relative',
      alignItems: 'center',
      top: '10px',
    },
    color: 'white',
  },
  estimatedProfit: {
    fontSize: 'var(--font-size-15)',
    fontWeight: '400',
    [theme.breakpoints.down('1850')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('1700')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1390')]: {
      fontSize: 'var(--font-size-10)',
    },
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-9)',
    },
    [theme.breakpoints.down('1165')]: {
      fontSize: 'var(--font-size-8)',
    },
    [theme.breakpoints.down('1070')]: {
      fontSize: 'var(--font-size-7)',
    },
  },
  yourProfit: {
    position: 'relative',
    fontWeight: '700',
    fontSize: 'var(--font-size-18)',
    [theme.breakpoints.down('1850')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('1700')]: {
      fontSize: 'var(--font-size-15)',
    },
    [theme.breakpoints.down('1390')]: {
      fontSize: 'var(--font-size-13)',
    },
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-12)',
    },
    [theme.breakpoints.down('1165')]: {
      fontSize: 'var(--font-size-11)',
    },
    [theme.breakpoints.down('1070')]: {
      fontSize: 'var(--font-size-9)',
    },
  },
  tooltipContainer: {
    position: 'relative',
    bottom: '19px',
    left: '27px',
  },
}));
