import React from "react";
import { Box, Grid } from "@mui/material";

function SeeAll({ children }) {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
          <Box>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SeeAll;

