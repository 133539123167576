export const transformInputToOutput = (input) => {
  const output = {};

  for (const productId in input) {
    if (Object.prototype.hasOwnProperty.call(input, productId)) {
      const product = input[productId].product;
      output[product.id.toString()] = {
        product: {
          id: product.id,
          name: product.name,
          price: product.price,
          weight: product.weight,
          quantity: product.quantity,
          part_number: product.part_number,
          images: [product?.images?.[0] || product?.image_url],
          image_url: product?.images?.[0] || product?.image_url,
        },
        quantity: input[productId].quantity,
      };
    }
  }

  return output;
};
