import React from "react";
import AppLayout from "../../../components/common/Layout/AppLayout";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import { routes } from "../../../routes";
import SeeAllNews from "../../../components/WebComponents/SeeAllNews/SeeAllNews";

function MobileNewsPage() {
  return (
    <AppLayout>
      <MobileNavigationBack page={"News"} route={routes.dashboard} />
      <SeeAllNews />
    </AppLayout>
  );
}

export default MobileNewsPage;

