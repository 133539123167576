import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '../../../assets/images/settings-icon.svg';
import DepositIcon from '../../../assets/images/deposit-icon.svg';
import WithdrawalIcon from '../../../assets/images/withdrawal-icon.svg';
import TransferFundsIcon from '../../../assets/images/transfer-funds.svg';
import LogoutIcon from '../../../assets/images/logout-icon.svg';
import replayTutorialIcon from '../../../assets/images/reply-tutorial-icon.svg';
import PrivacyPolicyIcon from '../../../assets/images/privacy-policy-icon.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import { refresh } from '../../../helpers/refresh';
import { userService } from '../../../service/user';
import { useAppContext } from '../../../context/DataContext';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../Popups/PleaseLoginPopup/PleaseLoginPopup';
import { useState } from 'react';
import { Divider } from '@mui/material';
import VerifiedAccountIcon from '../../../assets/images/verified-account-icon.svg';
import NonVerifiedAccountIcon from '../../../assets/images/non-verified-account-icon.svg';
import VerifyAccountBtn from '../../../assets/images/verify-account-btn.png';
import { generateCurrencySymbol } from '../../../helpers/generateCurrencySymbol';
import CryptoAccountIcon from '../../../assets/images/crypto-account-icon.svg';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import { ReactComponent as GoldIcon } from '../../../assets/images/goldIcon.svg';
import { ReactComponent as NotificaitonsIcon } from '../../../assets/images/notification-icon-empty.svg';

export default function AccountMenu({ anchorEl, open, handleClose, setIsTransferFundsPopUpOpen }) {
  const classes = useStyles();
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [isLogoutDisabled, setIsLogoutDisabled] = useState(false);

  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  const menuIcon = document.getElementById('accountMenu');

  const {
    setIsLoggedIn,
    setTutorialStep,
    isAppView,
    isMobile,
    userDetails,
    activeTradeAccountId,
    setActiveTradeAccountId,
    activeTradeAccountCurrency,
    setActiveTradeAccountCurrency,
    activeTradeAccountBalance,
    setActiveTradeAccountBalance,
    setCurrency,
    isUserVerified,
    userTradeAccounts,
    userBrokerAccounts,
    setSelectedCryptoAccount,
  } = useAppContext();

  const navigate = useNavigate();

  const showTransferFundsBtn = userTradeAccounts?.length + userBrokerAccounts?.length > 1;

  const firstName = userDetails?.firstName || '';
  const lastName = userDetails?.lastName || '';

  function navigateToDeposit() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    if (window.location.pathname.includes('/deposit')) {
      refresh();
    }
    navigate(routes.deposit);
    handleClose();
    window.scrollTo(0, 0);
  }

  function navigateToProfile() {
    if (window.location.pathname.includes('/profile')) {
      refresh();
    }
    navigate(routes.profile);
    handleClose();
    window.scrollTo(0, 0);
  }
  
  function handleNotifications() {
    if (window.location.pathname.includes('/notifications')) {
      refresh();
    }
    navigate(routes.notifications);
    handleClose();
    window.scrollTo(0, 0);
  }

  const handleWithdrawal = () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    if (window.location.pathname.includes('/withdraw')) {
      refresh();
    }
    navigate(routes.withdraw);
    handleClose();
    window.scrollTo(0, 0);
  };

  const handleTransferFunds = () => {
    setIsTransferFundsPopUpOpen(true);
    handleClose();
  };

  const handleReplyTutorial = async () => {
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage('{"Event": "Tutorial", "Action": "replay"}');
    } else if (isMobile) {
      await userService.updateTutorialStatus('MOBILE', 'ACTIVE');
    } else {
      await userService.updateTutorialStatus('WEB', 'ACTIVE');
    }

    if (!isAppView) {
      sessionStorage.setItem('tutorialStep', '0');
      setTutorialStep(0);
    }
    handleClose();
    if (window.location.pathname !== '/' && !isAppView) {
      window.location.href = '/';
    }
  };

  const handleLogout = async () => {
    setIsLogoutDisabled(true);
    try {
      await userService.logout();
      localStorage.clear();
      sessionStorage.clear();
      setSelectedCryptoAccount();
      setIsLoggedIn(false);
      navigate(routes.login);
      handleClose();
    } catch (e) {
      console.log(e);
      setIsLogoutDisabled(false);
    }
  };

  const handleHelp = () => {
    window.open(`${process.env.REACT_APP_CRM_BASE_URL}/faqs`, '_blank');
  };

  const handlePrivacyPolicy = () => {
    window.open(`${process.env.REACT_APP_CRM_BASE_URL}/privacy-policy`, '_blank');
  };

  const balance = isNaN(Number(activeTradeAccountBalance)) ? 0 : Number(activeTradeAccountBalance);

  const handleTradeAccountClicked = (account) => {
    setCurrency(account.currency);
    setActiveTradeAccountBalance(account.balance);
    setActiveTradeAccountId(account.tradeAccountId);
    setActiveTradeAccountCurrency(account.currency);

    localStorage.setItem('taid', account.tradeAccountId);
    localStorage.setItem('currency', account.currency);
  };

  function handleVerifyAccount() {
    navigate(routes.sumsub);
    handleClose();
  }

  const handleClick = (e) => {
    if (window.location.href?.includes('verification')) {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Menu
        className={classes.menuContainer}
        anchorEl={menuIcon || anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClick}
        PaperProps={{
          elevation: 0,
          sx: {
            border: '1px solid #A4A6AA',
            borderRadius: '8px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 2.3,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#303030',
              border: '1px solid #A4A6AA',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isUserVerified === 'false' && (
          <div>
            <div className={classes.verifyAccountBtnContainer}>
              <img
                src={VerifyAccountBtn}
                alt="Account Icon"
                style={{ cursor: 'pointer' }}
                onClick={handleVerifyAccount}
              />
            </div>
            <Divider variant="middle" component="li" className={classes.divider} />
          </div>
        )}

        <MenuItem className={classes.userInfoContainer}>
          <ListItemIcon>
            <img
              style={{ width: '33px' }}
              src={isUserVerified === 'false' ? NonVerifiedAccountIcon : VerifiedAccountIcon}
              alt="Account Icon"
            />
          </ListItemIcon>
          <ListItemText className={classes.userInfo}>
            <div className={classes.username}>{firstName + ' ' + lastName}</div>
            <div className={classes.email}>{userDetails?.email}</div>
          </ListItemText>
        </MenuItem>

        <Divider variant="middle" component="li" className={classes.divider} />

        {userBrokerAccounts?.length > 0 && (
          <div>
            {userBrokerAccounts?.map((account, index) => {
              const usdtBalance = account?.assets?.find((asset) => asset.assetId === 'usdt')?.amount || 0;
              return (
                <div className={classes.brokerAccountsListContainer} key={index}>
                  <img src={CryptoAccountIcon} alt="Crypto icon" className={classes.cryptoIcon} />
                  <div>
                    {index > 0 ? <b>Crypto Wallet {index + 1}</b> : <b>Crypto Wallet</b>}
                    <div>{currencyFormatter(usdtBalance, '', 'USDT', true)}</div>
                  </div>
                </div>
              );
            })}
            <Divider variant="middle" component="li" className={classes.divider} />
          </div>
        )}

        {userTradeAccounts?.length > 0 && (
          <div>
            {userTradeAccounts?.map((account, index) => (
              <div
                className={classes.tradeAccountsListContainer}
                key={index}
                style={{
                  color: activeTradeAccountId === account.tradeAccountId ? '#FFFFFF' : '#A8A8A8',
                }}
              >
                <div className={classes.left}>
                  <GoldIcon className={classes.goldIcon} />

                  <div htmlFor={`tradeAccount${index}`}>
                    <b>Gold Wallet</b>
                    <div>{currencyFormatter(account?.balance, '', account?.currency, true)}</div>
                  </div>
                </div>

                <input
                  type="radio"
                  style={{ accentColor: '#347362', cursor: 'pointer' }}
                  id={`tradeAccount${index}`}
                  name="tradeAccount1"
                  value={account.tradeAccountId}
                  defaultChecked={activeTradeAccountId === account.tradeAccountId}
                  onClick={() => handleTradeAccountClicked(account)}
                />
              </div>
            ))}
            <Divider variant="middle" component="li" className={classes.divider} />
          </div>
        )}

        <MenuItem onClick={navigateToProfile}>
          <ListItemIcon>
            <img src={SettingsIcon} alt="Settings" />
          </ListItemIcon>
          <ListItemText className={classes.text}>Profile Settings</ListItemText>
        </MenuItem>

        <MenuItem onClick={navigateToDeposit}>
          <ListItemIcon>
            <img src={DepositIcon} alt="Deposit" />
          </ListItemIcon>
          <ListItemText className={classes.text}>Deposit</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleWithdrawal}>
          <ListItemIcon>
            <img src={WithdrawalIcon} alt="Withdrawal" />
          </ListItemIcon>
          <ListItemText className={classes.text}>Withdrawal</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleNotifications}>
          <ListItemIcon>
            <NotificaitonsIcon style={{ color: '#989898' }} />
          </ListItemIcon>
          <ListItemText className={classes.text}>Notifications</ListItemText>
        </MenuItem>

        {showTransferFundsBtn && (
          <MenuItem onClick={handleTransferFunds}>
            <ListItemIcon>
              <img src={TransferFundsIcon} alt="Transfer Funds" />
            </ListItemIcon>
            <ListItemText className={classes.text}>Transfer Funds</ListItemText>
          </MenuItem>
        )}

        {!isDemo && (
          <MenuItem onClick={handleReplyTutorial}>
            <ListItemIcon>
              <img src={replayTutorialIcon} alt="Replay Tutorial" />
            </ListItemIcon>
            <ListItemText className={classes.text}>Replay Tutorial</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handleHelp}>
          <ListItemIcon>
            <HelpOutlineIcon style={{ color: '#989898' }} />
          </ListItemIcon>
          <ListItemText className={classes.text}>Help</ListItemText>
        </MenuItem>

        <MenuItem onClick={handlePrivacyPolicy}>
          <ListItemIcon>
            <img src={PrivacyPolicyIcon} alt="Privacy Policy" />
          </ListItemIcon>
          <ListItemText className={classes.text}>Privacy policy</ListItemText>
        </MenuItem>

        <MenuItem disabled={isLogoutDisabled} onClick={handleLogout}>
          <ListItemIcon>
            <img src={LogoutIcon} alt="Logout" />
          </ListItemIcon>
          <ListItemText className={classes.text}>Logout</ListItemText>
        </MenuItem>
      </Menu>
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    '& .MuiListItemText-root': {
      color: '#DDDDDD',
    },
    '& .MuiMenu-list': {
      backgroundColor: '#303030',
      borderRadius: '5px',
    },
    '& .MuiMenuItem-root': {
      paddingBottom: '10px',
    },
  },
  verifyAccountBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px 10px 10px 10px',
  },
  userInfoContainer: {
    paddingBottom: 'unset !important',
    gap: '10px',
    cursor: 'unset',
  },
  userInfo: {
    minWidth: '150px',
  },
  username: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#FFFFFF',
  },
  email: {
    fontSize: '10px',
  },
  account: {
    fontSize: '13px',
    fontWeight: 600,
    color: '#DDDDDD',
  },
  brokerAccountsListContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 400,
    padding: '7px',
    gap: '5px',
    marginLeft: '10px',
    color: 'white',
  },
  tradeAccountsListContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '13px',
    fontWeight: 400,
    padding: '7px',
    gap: '5px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  divider: {
    height: '2px',
    borderColor: 'gray',
  },
  text: {
    '& .MuiTypography-root': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '14px',
      fontWeight: '400',
    },
  },

  left: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },

  goldIcon: {
    width: '24px',
    height: '24px',
    paddingRight: '5px',

    '& > path': {
      fill: '#989898',
    },
  },

  cryptoIcon: {
    paddingRight: '8px',
  },
}));
