export const routes = {
  dashboard: "/",
  yielding: "/yielding",
  transactions: "/transactions-history",
  cryptoTransactions: "/crypto-transactions-history",
  yieldingHistory: "/yielding-history",
  vaulting: "/vaulting",
  vaultingHistory: "/vaulting-history",
  certificatesHistory: "/certificates-history",
  news: "/news",
  autoInvest: "/auto-invest",
  autoInvestHistory: "/auto-invest-history",
  notifications: "/notifications",
  deposit: "/deposit",
  profile: "/profile",
  withdraw: "/withdraw",
  login: "/login",
  forgotPassword: "/forgot-password",
  register: "/register",
  sumsub: "/verification",
  signUp: "/signup",
  store: "/store",
  cart: "/cart",
  crypto: "/crypto",
};
