import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";

import { useAppContext } from "../../../context/DataContext";

import CloseIcon from "@mui/icons-material/Close";
import { userService } from "../../../service/user";
import { useRef } from "react";

const getTooltipText = (step) => {
  switch (step) {
    case 1:
      return "Understand a short financial summary of your Bullionz account.";

    case 2:
      return "Manage the account’s Profile Settings, conduct Deposits and Withdrawals, see Notifications, request Help, view our Privacy Policy, and Logout.";

    case 3:
      return `Inject funds through traditional payment options to increase the account’s “Cash Balance”.`;

    case 4:
      return `Conveniently purchase real physical gold through the Bullionz store.`;

    case 5:
      return `Convert digital BTXG tokens for real physical gold and have it shipped.`;

    case 6:
      return "Set aside BTXG tokens within our Yielding Programs to earn passive rewards.";

    case 7:
      return "Store gold assets within our secure Vaults. <br> \n";

    case 8:
      return "Monthly returns distributed through our Yielding Programs, based on cumulative fees from all users' actions on the platform.";

    case 9:
      return "The account’s portfolio breakdown of how much and where BTXG tokens are distributed.";

    case 10:
      return "Push the button to see Buy and Close order popup.  <br> \n";

    case 11:
      return "Price history of our BTXG tokens across various timeframes.";

    case 12:
      return "The account’s token activities history including Buy and Close orders and Redemptions.";

    case 13:
      return "Market trends, gold price drivers and other news.";
    case 14:
      return "Thank you for joining us!";

    default:
      return "";
  }
};

const TutorialTooltipCustom = styled("div")(
  ({
    isFakeTooltip,
    tutorialElmOffset,
    tooltipDimensions,
    theme,
    tutorialStep,
  }) => {
    let positionStyle = {
      top: 0,
      left: 0,
    };
    positionStyle.top = tutorialElmOffset.top;
    positionStyle.left = tutorialElmOffset.left;

    if (isFakeTooltip) {
      positionStyle.top = -9999;
      positionStyle.left = 0;
      positionStyle.opacity = 0.5;
      positionStyle.background = "green";
    }

    return {
      position: "absolute",
      background: "white",
      ...positionStyle,
      zIndex: 10002,
      padding: "16px",
      width: "368px",
      textAlign: "left",
      margin: "20px",
      borderRadius: "12px",
      height: isFakeTooltip ? "auto" : tooltipDimensions?.height - 32,
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",

      [theme.breakpoints.down("440")]: {
        width: "calc(100vw - 72px)",
      },
    };
  }
);

const TutorialArrow = styled("div")(
  ({ position, type, positionProps, tutorialStep, theme }) => {
    const arrowWidth = 28;
    const arrowHeight = 20;

    let chevronStyle = {};
    let rotationDeg = 0;
    let arrowPosition = {
      top: 0,
      left: 0,
    };

    switch (position) {
      case "bottom":
        rotationDeg = 0;
        arrowPosition.top = positionProps.top + 6;
        arrowPosition.left = positionProps.left - arrowWidth / 2;
        break;
      case "right":
        rotationDeg = 90;
        arrowPosition.top = positionProps.top - arrowWidth / 2;
        arrowPosition.left = positionProps.left - arrowHeight;
        break;
      case "top":
        rotationDeg = 180;
        arrowPosition.top = positionProps.top - arrowHeight;
        arrowPosition.left = positionProps.left - arrowWidth / 2;
        break;
      case "left":
        rotationDeg = 270;
        arrowPosition.top = positionProps.top - arrowWidth / 2;
        arrowPosition.left = positionProps.left;
        break;
    }

    if (type === "slim") {
      chevronStyle = {
        top: arrowPosition?.top,
        left: arrowPosition?.left,
        width: "0",
        height: "0",
        transform: "scaleY(-1) rotate(45deg)",
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderBottom: "20px solid white",
      };

      if (tutorialStep === 2) {
        chevronStyle.top = arrowPosition?.top + (arrowWidth - arrowHeight);
        chevronStyle.left = arrowPosition?.left - arrowWidth / 2;
        chevronStyle.transform = "scaleY(-1) rotate(45deg)";
      } else if (tutorialStep === 7) {
        chevronStyle.top = arrowPosition?.top - (arrowWidth - arrowHeight);
        chevronStyle.left = arrowPosition?.left - arrowWidth / 2;
        chevronStyle.transform = "scaleY(-1) rotate(135deg)";
      }
    }

    return {
      position: "absolute",

      top: arrowPosition.top,
      left: arrowPosition.left,
      transform: `rotate(${rotationDeg}deg)`,
      borderBottom: `${arrowHeight}px solid white`,
      borderLeft: `${arrowWidth / 2}px solid transparent`,
      borderRight: `${arrowWidth / 2}px solid transparent`,
      zIndex: 9999999,
      ...chevronStyle,
    };
  }
);

const TutorialTooltipMobile = ({ isFakeTooltip }) => {
  const {
    isMobile,
    isAppView,
    arrowProps,
    tutorialStep,
    tooltipHeight,
    setTutorialStep,
    setIsTextUpdated,
    tooltipDimensions,
    tutorialElmOffset,
    setTooltipDimensions,
    setIsAccountMenuOpened,
    setArrowPositionUpdated,
    setTooltipPositionUpdated,
    setTooltipText,
    isRedeemGoldPopupOpen,
    tooltipText,
    isHeightUpdated,
    setIsHeightUpdated,
    arrowPositionUpdated,
    tooltipPositionUpdated,
    isBuySellPopupOpen,
  } = useAppContext();
  const classes = useStyles(tutorialStep);
  const [fakeTooltipText, setFakeTooltipText] = useState();
  const [isComponentUpdated, setIsComponentUpdated] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const tooltipRef = useRef(null);
  const isOnHomePage = window.location.pathname === "/";

  const mutationObserver = new MutationObserver(() => {
    const tooltipWidth = tooltipRef?.current?.offsetWidth;
    const tooltipHeight = tooltipRef?.current?.offsetHeight;
    setTooltipDimensions({
      width: tooltipWidth,
      height: tooltipHeight,
    });

    setTimeout(() => {
      setIsHeightUpdated(true);
    }, 130);
  });

  const resizeObserver = new ResizeObserver(() => {
    const tooltipWidth = tooltipRef?.current?.offsetWidth;
    const tooltipHeight = tooltipRef?.current?.offsetHeight;
    setTooltipDimensions({
      width: tooltipWidth,
      height: tooltipHeight,
    });

    setTimeout(() => {
      const fakeTooltipHeight = document.getElementById(
        "fake-tutorial-tooltip"
      )?.offsetHeight;
      setIsHeightUpdated(true);
    }, 100);
  });

  useEffect(() => {
    if (tooltipRef.current) {
      resizeObserver.observe(tooltipRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [isHeightUpdated]);

  useEffect(() => {
    if (tooltipRef.current) {
      mutationObserver.observe(tooltipRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    }
  }, [tutorialStep, tutorialElmOffset]);

  const handlePrevStep = () => {
    setIsHeightUpdated(false);
    setIsTextUpdated(false);
    setArrowPositionUpdated(false);
    setTooltipPositionUpdated(false);

    if (isAppView && tutorialStep === 6) {
      sessionStorage.setItem("tutorialStep", 3);
      setTutorialStep(3);
    } else if (!isAppView && tutorialStep === 6) {
      sessionStorage.setItem("tutorialStep", 4);
      setTutorialStep(4);
    } else if (isAppView && tutorialStep === 11) {
      sessionStorage.setItem("tutorialStep", 9);
      setTutorialStep(9);
    } else {
      sessionStorage.setItem("tutorialStep", tutorialStep - 1);
      setTutorialStep((prev) => prev - 1);
    }
  };

  const handleNextStep = () => {
    setIsHeightUpdated(false);
    setIsTextUpdated(false);
    setArrowPositionUpdated(false);
    setTooltipPositionUpdated(false);

    if (isAppView && tutorialStep === 3) {
      sessionStorage.setItem("tutorialStep", 6);
      setTutorialStep(6);
    } else if (!isAppView && tutorialStep === 4) {
      sessionStorage.setItem("tutorialStep", 6);
      setTutorialStep(6);
    } else if (isAppView && tutorialStep === 9) {
      sessionStorage.setItem("tutorialStep", 11);
      setTutorialStep(11);
    } else {
      sessionStorage.setItem("tutorialStep", tutorialStep + 1);
      setTutorialStep((prev) => prev + 1);
    }
  };

  const userHeader = document.getElementById("mobile-header");
  const buyCloseContainer = document.getElementById("buyclose-container");
  const BTXGCoins = document.getElementById("header-btxg-coins");
  const buyGoldIcon = document.getElementById("buy-gold-icon");
  const cashBalance = document.getElementById("header-cash-balance");
  const portfolioPL = document.getElementById("portfolio-pl");
  const accountIcon = document.getElementById("account-icon");
  const goldHistory = document.getElementById("mobile-gold-history");
  const accountMenu = document.getElementById("account-side-menu");
  const transactionsCard = document.getElementById("transactions-card");
  const newsCard = document.getElementById("news-card");
  const accountBalance = document.getElementById("account-balance");
  const goldHoldingsLabel = document.getElementById(
    "header-gold-holdings-label"
  );
  const goldHoldingsValue = document.getElementById(
    "header-gold-holdings-value"
  );
  const redeemGoldIcon = document.getElementById("redeem-gold-icon");
  const stakingIcon = document.getElementById("stake-icon");
  const yieldingIcon = document.getElementById("yield-icon");
  const autoInvestBtn = document.getElementById("autoinvest-btn");
  const tradeGoldBtn = document.getElementById("trade-gold-btn");
  const tradeGoldBtnTrigger = document.getElementById("trade-gold-btn-trigger");
  const portfolioAllocation = document.getElementById("portfolio-allocation");
  const portfolioAllocationDoughnut = document.getElementById(
    "portfolio-allocation-doughnut"
  );
  const totalMonthly = document.getElementById("total-monthly-pool");
  const totalMonthlyDoughnut = document.getElementById(
    "total-monthly-pool-doughnut"
  );
  const monthlyPoolCanvas = document.getElementById("chartCanvas-2");

  const depositIcon = document.getElementById("deposit-icon");
  const notificationsIcon = document.getElementById("notifications-icon");

  const blurAllItems = () => {
    setIsAccountMenuOpened(false);

    BTXGCoins?.classList?.remove("except");
    userHeader?.classList?.remove("except");
    buyGoldIcon?.classList?.remove("except");
    cashBalance?.classList?.remove("except");
    goldHoldingsLabel?.classList?.remove("except");
    goldHoldingsValue?.classList?.remove("except");
    portfolioPL?.classList?.remove("except");
    accountIcon?.classList?.remove("except");
    accountMenu?.classList?.remove("except");
    accountBalance?.classList?.remove("except");
    redeemGoldIcon?.classList?.remove("except");
    stakingIcon?.classList?.remove("except");
    yieldingIcon?.classList?.remove("except");
    autoInvestBtn?.classList?.remove("except");
    tradeGoldBtn?.classList?.remove("except");
    portfolioAllocation?.classList?.remove("except");
    portfolioAllocationDoughnut?.classList?.remove("except");
    totalMonthly?.classList?.remove("except");
    depositIcon?.classList?.remove("except");
    notificationsIcon?.classList.remove("except");
    newsCard?.classList?.remove("except");
    transactionsCard?.classList?.remove("except");
    goldHistory?.classList?.remove("except");
    tradeGoldBtnTrigger?.classList?.remove("except");
    monthlyPoolCanvas?.classList?.remove("except");
  };

  const handleCancelTutorial = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    blurAllItems();

    if (params.get("tutorial") === "true") {
      params.delete("tutorial");
      url.search = params.toString();
      window.history.replaceState({}, "", url.toString());
    }

    setTutorialStep(15);

    if (!isAppView) {
      userService.updateTutorialStatus("MOBILE", "CLOSED");

      const depositIconCopy = document.getElementById("deposit-icon-copy");

      if (depositIconCopy) {
        depositIconCopy.remove();
      }

      sessionStorage.setItem("tutorialStep", 15);
    } else if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage(
        '{"Event": "Tutorial", "Action": "passed"}'
      );

      // sessionStorage.setItem("tutorialStep", 14);
      sessionStorage.removeItem("tutorialStep");
      setTutorialStep(15);
    }

    const depositIconCopy = document.getElementById("deposit-icon-copy");

    if (depositIconCopy) {
      depositIconCopy.remove();

      setIsAccountMenuOpened(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isFakeTooltip) {
        switch (event.key) {
          case "ArrowLeft":
            handlePrevStep();
            break;
          case "ArrowRight":
            handleNextStep();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [tutorialStep]); // Empty dependency array to run effect only once

  useEffect(() => {
    let text = getTooltipText(tutorialStep);

    if (isAppView && tutorialStep === 4) {
      text = getTooltipText(5);
    } else if (isAppView && tutorialStep === 5) {
      text = getTooltipText(4);
    }

    setTooltipText(text);
    setFakeTooltipText(text);

    setIsTextUpdated(true);
  }, [tutorialStep]);

  let tooltipId = "tutorial-tooltip";

  if (arrowProps?.position === "top") {
    tooltipId = "tutorial-tooltip-top";
  } else if (arrowProps?.position === "left") {
    tooltipId = "tutorial-tooltip-left";
  } else if (arrowProps?.position === "right") {
    tooltipId = "tutorial-tooltip-right";
  }

  return (
    <>
      {isHeightUpdated &&
        arrowPositionUpdated &&
        tooltipPositionUpdated &&
        isOnHomePage &&
        tutorialStep > 0 &&
        sessionStorage?.getItem("tutorialStep") &&
        tutorialStep < 15 &&
        !isBuySellPopupOpen &&
        !isRedeemGoldPopupOpen && (
          <TutorialArrow
            type={arrowProps?.type}
            id="tutorial-tooltip-arrow"
            tutorialStep={tutorialStep}
            position={arrowProps?.position}
            positionProps={arrowProps?.positionProps}
          />
        )}

      <TutorialTooltipCustom
        ref={isFakeTooltip ? tooltipRef : null}
        isComponentUpdated={isComponentUpdated}
        id={isFakeTooltip ? "fake-tutorial-tooltip" : tooltipId}
        isAppView={isAppView}
        windowWidth={windowWidth}
        tutorialStep={tutorialStep}
        tooltipHeight={tooltipHeight}
        isFakeTooltip={isFakeTooltip}
        tooltipDimensions={tooltipDimensions}
        tutorialElmOffset={tutorialElmOffset}
      >
        <div className={classes.header}>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={handleCancelTutorial}
            onTouchStart={handleCancelTutorial}
          />
        </div>

        {!isFakeTooltip && (
          <p
            id="tooltip-text"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: tooltipText }}
          />
        )}

        {isFakeTooltip && (
          <>
            <p
              id="next-tooltip-text"
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: fakeTooltipText }}
            />
          </>
        )}

        <div className={classes.btnWrapper}>
          <button
            onClick={handlePrevStep}
            className={classes.backBtn}
            onTouchStart={handlePrevStep}
          >
            Back
          </button>
          <button
            onClick={handleNextStep}
            className={classes.nextBtn}
            onTouchStart={handleNextStep}
          >
            {tutorialStep < 15 && "Next"}
          </button>
        </div>
      </TutorialTooltipCustom>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const styles = {
    top: "0px",
  };

  const rightTopChevron = {
    top: "56px",
    right: "-14px",
    width: "0",
    height: "0",
    transform: "rotate(90deg)",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid white",
  };

  return {
    wrapper: {
      position: "absolute",
      top: 0,
      background: "white",

      zIndex: 10002,
      padding: "16px",
      borderRadius: "12px",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      ...styles,

      "&::before": {
        content: '""',
        position: "absolute",
        ...rightTopChevron,
      },
    },

    header: {
      display: "flex",
      justifyContent: "flex-end",
    },

    btnWrapper: {
      display: "grid",
      justifyItems: "center",
      gridTemplateColumns: "1fr 1fr",

      marginTop: "16px",

      // [theme.breakpoints.down("1100")]: {
      //   gridTemplateColumns: "1fr",
      //   justifyItems: "center",
      //   gap: "10px",
      // },
    },

    description: {
      whiteSpace: "pre-wrap",
      WebkitUserSelect: "none" /* Safari */,
      MsUserSelect: "none" /* IE 10 and IE 11 */,
      userSelect: "none" /* Standard syntax */,
      animation: `$myEffectExit 3000ms ${theme.transitions.easing.easeInOut}`,
    },

    "@keyframes myEffect": {
      "0%": {
        opacity: 0,
        transform: "translateY(-200%)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },

    backBtn: {
      cursor: "pointer",

      background: "white",
      color: "black",
      border: "1px solid black",
      // padding: "8px 32px",
      width: "80%",
      marginRight: "8px",
      borderRadius: "28px",
      height: "34px",
      maxWidth: "150px",
    },

    nextBtn: {
      cursor: "pointer",

      width: "80%",
      background: "#48D0A4",
      color: "white",
      border: "1px solid #48D0A4",
      padding: "8px 32px",
      borderRadius: "28px",
      marginRight: "8px",
      height: "34px",
      maxWidth: "150px",
    },

    bolderText: {
      fontWeight: "bold",
    },
  };
});

export default TutorialTooltipMobile;
