import { useCallback, useEffect, useState } from 'react';
import { storeService } from '../../../../service/store';
import { useAppContext } from '../../../../context/DataContext';
import { useSearchParams } from 'react-router-dom';
import { transformInputToOutput } from '../../../../helpers/userCartInputTransform';
import { userService } from '../../../../service/user';

export const useStorePage = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [productsData, setProductsData] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [priceRange, setPriceRange] = useState([]);
  const [sort, setSort] = useState({ name: 'Name: A-Z' });
  const [productsView, setProductsView] = useState(localStorage?.getItem('storeView') || 'grid');
  const [categoriesData, setCategoriesData] = useState([]);
  const [priceFilterValue, setPriceFilterValue] = useState();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isProductLoading, setIsProductLoading] = useState(true);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
  const [isAddToCardTriggered, setIsAddToCardTriggered] = useState(false);
  const [isPriceFilterApplied, setIsPriceFilterApplied] = useState(false);

  const [searchParams] = useSearchParams();
  const { isAppView, setProductsBucket, isMobile, currency, isNative } = useAppContext();

  const getCategories = useCallback(async () => {
    const categoriesResp = await storeService.getStoreCategories();

    const categories = categoriesResp?.categories;
    const lowestPrice = categoriesResp?.lowestPrice;
    const highestPrice = categoriesResp?.highestPrice;

    if (categories) {
      setCategoriesData(categories);

      setTimeout(() => {
        setIsCategoriesLoading(false);
      }, 220);
    }
  }, []);

  const handleAddToCard = async (product, quantity) => {
    // Retrieve the existing bucket from sessionStorage
    let bucket = JSON.parse(localStorage.getItem('bucket')) || {};

    // If the product already exists in the bucket, increment its quantity
    if (bucket[product.id]) {
      bucket[product.id].quantity += quantity;
    } else {
      // If the product doesn't exist in the bucket, add it with the specified quantity
      bucket[product.id] = {
        product,
        quantity,
      };
    }

    // Save the updated bucket to sessionStorage
    localStorage.setItem('bucket', JSON.stringify(bucket));
    setProductsBucket(bucket);

    const updateObj = transformInputToOutput(bucket);
    await userService.updateShoppingCart(updateObj);

    if (isAppView) {
      let counter = 0;

      if (bucket) {
        let totalQuantity = 0;

        for (const productId in bucket) {
          totalQuantity += bucket[productId].quantity;
        }
        counter = totalQuantity;
      }

      window?.webkit?.messageHandlers?.notifyNative?.postMessage(`{"Event": "CartBadge", "Action": "${counter}"}`);
    }
  };

  const getProducts = useCallback(async () => {
    const body = {};
    let filters = [];
    let sortFilter = [];
    setSelectedItem(false);

    setIsLoading(true);

    if (!isMobile) {
      window.scrollTo({
        top: 0,
      });
    }

    if (page > 1) {
      body['page'] = page;
    }

    if (page === 0) {
      setPage(1);
    }

    if (selectedCategory) {
      filters.push({
        key: 'type',
        value: [selectedCategory],
      });

      body['filters'] = [...filters];
    }

    if (priceFilterValue) {
      filters.push({
        key: 'price',
        value: [priceFilterValue?.[0], priceFilterValue?.[1]],
      });

      body['filters'] = [...filters];
    }

    if (sort && sort?.name) {
      let key = '';
      let value = '';

      switch (sort?.name) {
        case 'Name: A-Z':
          key = 'name';
          value = 'ASC';
          break;

        case 'Name: Z-A':
          key = 'name';
          value = 'DESC';
          break;

        case 'Price: Low to High':
          key = 'price';
          value = 'ASC';
          break;

        case 'Price: High to Low':
          key = 'price';
          value = 'DESC';
          break;

        case 'Fine Weight: Low to High':
          key = 'weight';
          value = 'ASC';
          break;

        case 'Fine Weight: High to Low':
          key = 'weight';
          value = 'DESC';
          break;

        default:
          key = 'name';
          value = 'ASC';
          break;
      }

      sortFilter.push({
        key,
        value,
      });

      body['sort'] = [...sortFilter];
    }
    const productsResp = await storeService.getStoreProducts({
      ...body,
      currency,
    });

    const data = productsResp?.data;
    const totalPages = productsResp?.pages;
    const productsCount = productsResp?.totalProducts;

    if (productsResp?.minPrice && productsResp?.maxPrice && !isPriceFilterApplied) {
      setPriceRange((prev) => [productsResp?.minPrice, productsResp?.maxPrice]);
    }

    if (isMobile || isAppView) {
      setProductsData((prev) => {
        const uniqueIds = new Set(prev.map((item) => item.id));
        const newData = data.filter((item) => !uniqueIds.has(item.id));
        return [...prev, ...newData];
      });
    } else {
      setProductsData(data || []);
    }

    setTotalPages(totalPages || 0);
    setTotalProducts(productsCount || 0);

    setTimeout(() => {
      setIsLoading(false);
    }, 200);
    setIsFetchingProducts(false);
  }, [page, sort, selectedCategory, currency, priceRange, priceFilterValue]);

  const getElementById = useCallback(async (id) => {
    const productResp = await storeService.getElementById(id);

    setSelectedItem(productResp?.data);
    setIsProductLoading(false);
  }, []);

  useEffect(() => {
    setProductsData([]);
  }, [priceFilterValue, selectedCategory, sort]);

  useEffect(() => {
    if (!isFetchingProducts) {
      setIsFetchingProducts(true);
      getProducts();
    }
  }, [page, currency, sort, priceFilterValue, selectedCategory]);

  useEffect(() => {
    setIsCategoriesLoading(true);
    getCategories();
  }, []);

  useEffect(() => {
    if (searchParams.has('id')) {
      setIsProductLoading(true);
      const productId = searchParams?.get('id');

      const resp = getElementById(productId);
    } else {
      setSelectedItem({});
    }
  }, [searchParams, currency]);

  useEffect(() => {
    if (isAddToCardTriggered) {
      setTimeout(() => {
        setIsAddToCardTriggered(false);
      }, 2500);
    }
  }, [isAddToCardTriggered]);

  return {
    setPage,
    setSort,
    setIsLoading,
    setPriceRange,
    setProductsView,
    setSelectedItem,
    handleAddToCard,
    setPriceFilterValue,
    setSelectedCategory,
    setIsAddToCardTriggered,
    setIsPriceFilterApplied,
    sort,
    page,
    isLoading,
    totalPages,
    priceRange,
    productsView,
    productsData,
    selectedItem,
    totalProducts,
    selectedCategory,
    isProductLoading,
    priceFilterValue,
    isCategoriesLoading,
    isPriceFilterApplied,
    isAddToCardTriggered,
    categories: categoriesData,
    isPreview: selectedItem?.name || searchParams?.get('id'),
  };
};
