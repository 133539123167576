import React from 'react';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/material';
import PaytikoDeposit from '../../../components/WebComponents/PaytikoDeposit/PaytikoDeposit';
import AppLayout from '../../../components/common/Layout/AppLayout';
import MobileNavigationBack from '../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack';
import { routes } from '../../../routes';

function AppDeposit({ isMobile }) {
  const classes = useStyles();

  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileNavigationBack page={'Deposit'} route={routes.dashboard} />
        <PaytikoDeposit isMobile={isMobile} />
      </Box>
    </AppLayout>
  );
}

export default AppDeposit;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
  }),
);
