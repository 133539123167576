import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import Divider from "@mui/material/Divider";
import clsx from "clsx";
import IButton from "../../../common/IButton/IButton";
import { tooltipContent } from "../../../../constants/tooltipTexts";
import LoadingBar from "../../../common/LoadingBar/LoadingBar";
import { handleGetNewsFeedAccessToken } from "../../../../helpers/handleGetAccessTokenNewsFeed";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";

const instrument = "GC:FUTURES,ETHUSD:CRYPTO,BTCUSD:CRYPTO";

function News({ isAutoInvest = false }) {
  const classes = useStyles({ isAutoInvest });
  const [newsToken, setNewsToken] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const script = document.createElement("script");

  const isDemoValue = sessionStorage.getItem("isDemo");
  const isDemo1 = isDemoValue === "true";

  script.src =
    "https://global.tradingcentral.com/widgets/client/607/latest/package.js";
  script.async = true;

  useEffect(() => {
    function handleResize() {
      handleGetNewsFeedAccessToken(setNewsToken, setLoading, setError);
      document.body.appendChild(script);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function navigateToSeeAllNews(e) {
    e.preventDefault();
    navigate(routes.news);
    window.scrollTo(0, 0);
  }

  return (
    <Box className={classes.container} id="news-container">
      <Box className={classes.header}>
        <Box className={classes.latestNews}>Latest News</Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box className={clsx(classes.latestNews, classes.seeAll)}>
          <a
            className={classes.a}
            href="/news"
            onClick={navigateToSeeAllNews}
            disabled={isDemo1}
          >
            See All
          </a>
        </Box>
        <IButton tooltipTitle={tooltipContent.news} />
      </Box>
      <Divider className={classes.headerDivider} />
      <div
        id="trading-central-widget"
        className={
          isAutoInvest ? classes.newsFeedAutoInvest : classes.newsFeedDashboard
        }
      >
        {newsToken && (
          <tc-news-feed
            token={newsToken}
            lang="en"
            fontSize="14px"
            instrument={instrument}
            base-color="#333333"
            background-color="#FFFFFF"
            surface-color="#FAFAFA"
            primary-color="#0252AB"
            bearish-color="#D10000"
            bullish-color="#12823E"
            neutral-color="#2775CE"
            article-lang="”en”"
            news-color="#0360DD"
            social-color="#13CA60"
            display-inside="true"
          ></tc-news-feed>
        )}
      </div>
      <LoadingBar open={loading} />
    </Box>
  );
}

export default News;

const useStyles = makeStyles((theme) =>
  createStyles({
    newsFeedDashboard: {
      overflow: "hidden",
      height: "calc(100% - 32px)",
    },
    newsFeedAutoInvest: {
      overflow: "hidden",
      height: "440px",
    },
    container: {
      paddingTop: "1rem",
      borderRadius: "24px",
      backgroundColor: "var(--white)",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      height: "100%",
      position: "relative",
      overflow: "hidden",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: "0.2rem",
    },
    divider: {
      "& .MuiDivider-root": {
        marginLeft: "1rem",
        marginRight: "1rem",
        color: "var(--black)",
      },
      marginLeft: "10px",
      marginRight: "10px",
      width: "1px",
    },
    cardDivider: {
      "& .MuiDivider-root": {
        color: "var(--black)",
      },
      marginTop: "10px !important",
      marginBottom: "10px !important",
      width: "100%",
      marginLeft: "1rem !important",
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    latestNews: {
      marginLeft: "1rem",
      marginRight: "1rem",
      fontSize: "1rem",
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
    },
    seeAll: {
      color: "var(--green-dark)",
      cursor: "pointer",
    },
    headerDivider: {
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    a: {
      textDecoration: "none",
      color: "var(--black)",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      position: "relative",
      minWidth: "1rem",
      opacity: 1,
      fontSize: "var(--font-size-14)",
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down("1280")]: {
        fontSize: "var(--font-size-12)",
      },
      [theme.breakpoints.down("1465")]: {
        fontSize: "var(--font-size-10)",
      },
    },
  })
);
