import * as React from "react";
import { makeStyles } from "@mui/styles";
import "../../../styles/global.styles.css";
import "../../../styles/global.fonts.css";
import { Box, Pagination, Tab, Tabs, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CustomButton from "../../common/CustomButton/CustomButton";
import { useAppContext } from "../../../context/DataContext";
import LoadingBar from "../../common/LoadingBar/LoadingBar";
import certificatesService from "../../../service/certificates";
import { formatDateWrapper } from "../../../helpers/dateFormatter";
import IButton from "../../common/IButton/IButton";
import { tooltipContent } from "../../../constants/tooltipTexts";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="span" component="span">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const BullionzCertificates = ({ isSeeAll = true, containerClassName }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [certificates, setCertificates] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [allPages, setAllPages] = useState();
  const [error, setError] = useState(false);
  const { proceedVaultOrderClicked } = useAppContext();

  const isDemoValue = sessionStorage.getItem("isDemo");
  const isDemo1 = isDemoValue === "true";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function navigateToCertificatesHistory(e) {
    e.preventDefault();
    navigate(routes.certificatesHistory);
  }

  function navigateBackToVaulting() {
    navigate(routes.vaulting);
    window.scrollTo(0, 0);
  }

  const getCertificatesPerClient = async (fn, fn2, page, pageSize) => {
    try {
      const response = await certificatesService.getAllCertificatesPerClient(
        page,
        pageSize
      );

      if (!response.data.length) {
        setError(true);
      }
      fn2(response.pages);
      fn(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isSeeAll) {
      getCertificatesPerClient(setCertificates, setAllPages, page, 10);
      setLoading(false);
    } else {
      getCertificatesPerClient(setCertificates, setAllPages);
      setLoading(false);
    }
  }, [page, proceedVaultOrderClicked]);

  useEffect(() => {
    if (certificates.length || error) {
      setLoading(false);
    }
  }, [certificates, error]);

  return (
    <Box className={classes.wrapper}>
      <Box className={containerClassName}>
        <Box className={classes.container}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="secondary"
              textColor="inherit"
              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            >
              <Tab className={classes.title} label="BULLIONZ CERTIFICATES" />
              {isSeeAll && (
                <Tab
                  className={classes.seeAll}
                  href="/vaulting-history"
                  onClick={navigateToCertificatesHistory}
                  label="See All"
                  disabled={isDemo1}
                ></Tab>
              )}
              {<IButton tooltipTitle={tooltipContent.certificates} />}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className={classes.tableContainer}>
              <TableContainer>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.headerCell}
                        style={{
                          // width: "25%",
                          whiteSpace: "nowrap",
                          "& th": {
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                          },
                        }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        className={classes.headerCell}
                        // style={{
                        //   width: "15%",
                        // }}
                        align="left"
                      >
                        Size
                      </TableCell>
                      <TableCell className={classes.headerCell} align="left">
                        Certificate ID
                      </TableCell>
                      <TableCell className={classes.headerCell} align="left">
                        Locker Number
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {certificates.length > 0 ? (
                      certificates.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor:
                              index % 2 === 0
                                ? "var(--orange-light)"
                                : "var(--floral-white)",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {formatDateWrapper(
                              new Date(row.date).getTime(),
                              "long"
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.volume}
                          </TableCell>
                          <TableCell align="left">
                            {row.certificateId}
                          </TableCell>
                          <TableCell align="left">{row.lockerNumber}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow className={classes.noData}>
                        <TableCell>No certificates History</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
        </Box>
      </Box>
      {!isSeeAll && (
        <Box className={classes.buttonsWrapper}>
          <Box>
            <Box className={classes.btn}>
              <CustomButton
                type="black"
                width="100%"
                height="24px"
                onClick={navigateBackToVaulting}
                buttonText="&#9664;&nbsp;&nbsp;Back to Vaulting"
              ></CustomButton>
            </Box>
          </Box>
          {certificates.length > 0 && (
            <Box>
              <Box className={classes.paginationRoot}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={allPages}
                  page={page}
                  onChange={(event, newPage) => setPage(newPage)}
                />
              </Box>
            </Box>
          )}
          <Box></Box>
        </Box>
      )}
      <LoadingBar open={loading} />
    </Box>
  );
};

export default BullionzCertificates;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    height: "100%",
  },
  container: {
    paddingTop: "1rem",
    borderRadius: "24px",
  },
  title: {
    paddingLeft: "1rem",
    paddingBottom: "2px",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-18)",
    [theme.breakpoints.down("1600")]: {
      fontSize: "var(--font-size-16)",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-14)",
    },
    [theme.breakpoints.down("900")]: {
      fontSize: "var(--font-size-12)",
    },
  },
  tableContainer: {
    fontFamily: theme.typography.fontFamily,
    height: "100%",
    maxHeight: "43rem !important",
    "& .MuiTableContainer-root": {
      overflowX: "hidden",
      borderRadius: "24px",
    },
    "& .MuiTableCell-root": {
      fontSize: "var(--font-size-18)",
      fontWeight: "400",
      color: "#767676",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-15)",
      },
      [theme.breakpoints.down("1280")]: {
        fontSize: "var(--font-size-13)",
      },
      [theme.breakpoints.down("1040")]: {
        fontSize: "var(--font-size-11)",
      },
      [theme.breakpoints.down("900")]: {
        fontSize: "var(--font-size-10)",
      },
    },
  },
  headerCell: {
    fontWeight: "600 !important",
    fontSize: "var(--font-size-18) !important",
    color: "black !important",
    [theme.breakpoints.down("1680")]: {
      width: "20%",
      fontSize: "var(--font-size-16) !important",
    },
    [theme.breakpoints.down("1475")]: {
      fontSize: "var(--font-size-15) !important",
    },
    [theme.breakpoints.down("1313")]: {
      fontSize: "var(--font-size-14) !important",
    },
    [theme.breakpoints.down("900")]: {
      fontSize: "var(--font-size-11) !important",
    },
  },
  noData: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: "var(--grey-secondary)",
    fontFamily: theme.typography.fontFamily,
    "& td": {
      border: "none",
      color: "var(--grey-secondary)",
      fontFamily: theme.typography.fontFamily,
      fontSize: "var(--font-size-16)",
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-14)",
      },
      [theme.breakpoints.down("1280")]: {
        fontSize: "var(--font-size-13)",
      },
    },
  },
  seeAll: {
    textDecoration: "none",
    color: "var(--black)",
    display: "flex",
    alignItems: "center",
    position: "relative",
    minWidth: "1rem",
    opacity: 1,
    paddingBottom: "0.175rem",
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-14)",
    fontWeight: "400",
    paddingTop: "13px",
    [theme.breakpoints.down("1600")]: {
      fontSize: "var(--font-size-13)",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-12)",
    },
    [theme.breakpoints.down("900")]: {
      fontSize: "var(--font-size-11)",
    },
  },
  paginationRoot: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    marginTop: "3%",
    paddingBottom: "3%",
    "& .MuiPagination-ul": {
      color: "white",
      flexWrap: "nowrap",
      "& li:first-child": {
        flexBasis: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& > button::after": {
          marginLeft: "2px",
          content: "'Prev'",
        },
      },
      "& li:last-child": {
        flexBasis: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "& > button::before": {
          marginRight: "2px",
          content: "'Next'",
        },
      },
    },
    "& .Mui-selected": {
      backgroundColor: "var(--black)",
      color: "var(--white)",
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
  btn: {
    textAlign: "left",
    width: "12rem",
    [theme.breakpoints.down("1750")]: {
      width: "10rem",
    },
    [theme.breakpoints.down("1280")]: {
      width: "8rem",
    },
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0.5rem",
    marginTop: "2rem",
  },
}));
