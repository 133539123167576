import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import TotalMonthlyPoolTop from "../TotalMonthlyPoolInDashboard/TotalMonthlyPoolTop/TotalMonthlyPoolTop";
import StakingTotalMonthlyCharts1 from "../../common/Charts/StakingTotalMonthlyCharts1";
import IButton from "../../common/IButton/IButton";
import { tooltipContent } from "../../../constants/tooltipTexts";
import StakingTotalMonthlyCharts2 from "../../common/Charts/StakingTotalMonthlyCharts2";

function TotalMonthlyInStaking({ isInStaking, pool }) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <IButton tooltipTitle={tooltipContent.totalMonthlyPool}></IButton>
      {pool && (
        <TotalMonthlyPoolTop
          chart1={<StakingTotalMonthlyCharts1 pool={pool} />}
          chart2={<StakingTotalMonthlyCharts2 pool={pool} />}
          containerClass={classes.containerOfChart}
          tabClass={classes.tab}
          isInStaking={isInStaking}
        />
      )}
    </Box>
  );
}

export default TotalMonthlyInStaking;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: "relative",
      background: "var(--white)",
      borderRadius: "24px",
      letterSpacing: "normal",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      height: "100% !important",
      paddingBottom: "2.5rem",
    },
    containerOfChart: {
      position: "relative",
      fontFamily: theme.typography.fontFamily,
    },
    tab: {
      minWidth: "10px",
      paddingTop: "10px",
      "& .MuiTab-root": {
        minWidth: "30px",
        width: "15%",
        minHeight: "25px",
        height: "100%",
        [theme.breakpoints.down("760")]: {
          lineHeight: "7px",
        },
      },
      "& .MuiTabs-flexContainer": {
        paddingLeft: "16px",
      },
      "& button": {
        borderRadius: "8px",
        color: "var(--black)",
        fontWeight: 500,
        paddingLeft: "2px",
        paddingRight: "2px",
        lineHeight: "3px",
        fontSize: "var(--font-size-18)",
        [theme.breakpoints.down("1600")]: {
          fontSize: "var(--font-size-13)",
        },
        [theme.breakpoints.down("1500")]: {
          fontSize: "var(--font-size-12)",
        },
        [theme.breakpoints.down("1280")]: {
          fontSize: "var(--font-size-10)",
        },
        fontFamily: theme.typography.fontFamily,
      },
      "& button.Mui-selected": {
        backgroundColor: "var(--beige) !important",
        color: "var(--white)",
        fontWeight: "bold",
        fontFamily: theme.typography.fontFamily,
        paddingLeft: "2px",
        paddingRight: "2px",
      },
    },
  })
);
