import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import LoadingBar from "../../../components/common/LoadingBar/LoadingBar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ForgotPasswordPopup = ({ isPopupOpen, setIsPopupOpen }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const iframeUrl = `${process.env.REACT_APP_CRM_BASE_URL}/ProfileIframe/ForgotPassword`;

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
        // disableEscapeKeyDown
      >
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {loading && <LoadingBar open={loading} />}
        <iframe
          title="Forgot Password"
          src={iframeUrl}
          width="100%"
          height="387px"
          frameBorder="0"
          onLoad={() => setLoading(false)}
        />
      </BootstrapDialog>
    </div>
  );
};

export default ForgotPasswordPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backdropFilter: "blur(5px)",
    "& .MuiDialog-container": {
      width: "100%",
    },
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      width: "100%",
      boxShadow: "0px 5px 0px #80808033",
    },
  },
}));
