import serviceTransaction from "../service/transactions";

export const handleGetWebTransactions = async (
  fn,
  page,
  setAllPages,
  setError
) => {
  try {
    const response = await serviceTransaction.getAllTransactions(page);
    if (!response.data.length) {
      setError(true);
    }
    const filteredTransactions = response?.data.map(({ ...rest }) => {
      let changeId = (1000000000 + rest.id).toString();

      if (rest.product_item_code === "P001") {
        changeId = (1000000000 + rest.id).toString();
      } else if (rest.product_item_code === "P005") {
        changeId = (5000000000 + rest.id).toString();
      } else if (rest.product_item_code === "P004") {
        changeId = (4000000000 + rest.id).toString();
      } else {
        console.log("type of transactions should be checked");
        changeId = rest.id;
      }

      return {
        ...rest,
        id: changeId,
        productItemCode: rest.product_item_code,
        date: rest.transaction_date,
        status: rest.status,
        volume: rest.volume,
        tradeAccountId: rest?.trade_account_id,
        currency: rest.currency,
        price: rest.price,
      };
    });
    filteredTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));
    fn(filteredTransactions);
    setAllPages(response.pages);
  } catch (e) {
    //setError(false);
    console.log(e);
  }
};

export const handleGetMobileTransactions = async (
  fn,
  page,
  pageSize,
  setAllPages,
  setError,
  setLoading
) => {
  setLoading(true);
  try {
    const response = await serviceTransaction.getAllTransactions(
      page,
      pageSize
    );
    console.log('response', response);
    if (!response.data.length) {
      setError(true);
    }
    const newTransactions = response?.data.map(({ ...rest }) => {
      let changeId = (1000000000 + rest.id).toString();

      if (rest.product_item_code === "P001") {
        changeId = (1000000000 + rest.id).toString();
      } else if (rest.product_item_code === "P005") {
        changeId = (5000000000 + rest.id).toString();
      } else if (rest.product_item_code === "P004") {
        changeId = (4000000000 + rest.id).toString();
      } else {
        console.log("type of transactions should be checked");
        changeId = rest.id;
      }

      return {
        ...rest,
        id: changeId,
        productItemCode: rest.product_item_code,
        date: rest.transaction_date,
        status: rest.status,
        volume: rest.volume,
        tradeAccountId: rest?.trade_account_id,
        currency: rest.currency,
        price: rest.price,
      };
    });
    newTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));
    fn((prevTransactions) =>
      [...prevTransactions, ...newTransactions].filter(
        (transaction, index, self) =>
          self.findIndex((t) => t.id === transaction.id) === index
      )
    );
    setAllPages(response.pages);
  } catch (e) {
    console.log(e);
  } finally {
    setError(false);
    setLoading(false);
  }
};
