import * as React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.styles.css";
import "../../../../styles/global.fonts.css";
import CustomButton from "../../../common/CustomButton/CustomButton";
import GoldenBar from "../../../../assets/images/gold-bar.svg";
import TextField from "@mui/material/TextField";
import {
  handleInputKeyUp,
  handleInputKeyDown,
} from "../../../../helpers/inputValidator";
import { tooltipContent } from "../../../../constants/tooltipTexts";

const FirstStepContent = ({
  quantity,
  partNumber,
  setQuantity,
  setPartNumber,
  availableGoldVariants,
}) => {
  const classes = useStyles();
  const handleChosenQuantity = (quantity, partNumber) => {
    setQuantity(parseInt(quantity));
    setPartNumber(String(partNumber));
  };

  return (
    <div className={classes.container}>
      <div className={classes.tooltipContainer}>
        <CustomButton
          type="moreinfo"
          buttonText="i"
          tooltipTitle={tooltipContent.redeemGold}
        />
      </div>
      <Box className={classes.field}>
        <div className={classes.goldContainer}>
          <div className={classes.logoContainer}>
            <img src={GoldenBar} alt="Golden bar" />
          </div>

          <div className={classes.goldField}>
            <Box className={classes.title}>Physical PAMP Gold</Box>
          </div>
        </div>
        <div className={classes.dynamicField}>
          <TextField
            className={classes.customInput}
            type="number"
            id="filled-size-small"
            value={quantity}
            disabled
            size="small"
            inputProps={{ maxLength: 5 }}
            onKeyUp={handleInputKeyUp}
            onKeyDown={handleInputKeyDown}
            sx={{
              "& .MuiInputBase-input": {
                fontWeight: "400",
                textAlign: "right",
                paddingRight: "3px",
                fontSize: "var(--font-size-12)",
                color: "#767676",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
              "& .Mui-error": {
                paddingLeft: "42px",
              },
            }}
          />
          <Box className={classes.gr}> gr</Box>
        </div>
      </Box>
      <div className={classes.buttons}>
        <div className={classes.buttonContainer}>
          {availableGoldVariants &&
            Object.keys(availableGoldVariants).map((key) => (
              <div key={key} className={classes.greyButton}>
                <CustomButton
                  type={
                    partNumber && quantity === parseInt(key)
                      ? "secondaryBlack"
                      : "gramBtnDarkGrey"
                  }
                  buttonText={key + "gr"}
                  width="100%"
                  height="100%"
                  onClick={() =>
                    handleChosenQuantity(
                      key,
                      availableGoldVariants[key].partNumber
                    )
                  }
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FirstStepContent;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tooltipContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    bottom: "29px",
    left: "9px",
  },
  field: {
    border: "0.5px solid #D2D5D8",
    boxShadow: "0px 3px 0px 0px #F7F7F7 inset",
    borderRadius: "3px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    fontSize: "var(--font-size-16)",
    fontFamily: theme.typography.fontFamily,
  },
  goldContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "flex-start",
    left: "2%",
    width: "50%",
  },
  logoContainer: {
    marginRight: "0.5rem",
  },
  goldField: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: "var(--font-size-14)",
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    fontWeight: "600",
    fontSize: "var(--font-size-10)",
    fontFamily: theme.typography.fontFamily,
  },
  dynamicField: {
    color: "var(--grey-secondary)",
    position: "relative",
    marginLeft: "auto",
    right: "2%",
    display: "flex",
    fontSize: "var(--font-size-15)",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
  },
  customInput: {
    borderLeft: "1px solid #EAEAEA",
    "& input": {
      width: "30px",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    fontSize: "var(--font-size-16)",
    fontFamily: theme.typography.fontFamily,
    "& .Mui-disabled": {
      "-webkitTextFillColor": "#767676",
    },
  },
  gr: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-10)",
    fontWeight: "400",
    color: "#767676",
  },
  buttons: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    top: "10px",
  },
  buttonContainer: {
    display: "flex",
    flex: "1",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "12%",
    marginBottom: "2%",
    minHeight: "79px",
  },
  greyButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "25.2%",
    height: "25px",
    paddingBottom: "4%",
  },
}));
