import { createTheme } from "@mui/material/styles";

const appView = sessionStorage?.getItem("isNative");

export const theme = createTheme({
  appView,
  typography: {
    fontFamily: "'Poppins', sans-serif",
    button: {
      textTransform: "none",
      fontWeight: "none",
      fontFamily: "Poppins",
    },
    h1: {
      fontWeight: "bold",
      textAlign: "left",
      lineHeight: "54px",
      fontStyle: "normal",
    },
    h2: {
      fontWeight: "bold",
      textAlign: "left",
      lineHeight: "150%",
      fontStyle: "normal",
    },
    h3: {
      fontWeight: "bold",
      textAlign: "left",
      lineHeight: "150%",
      fontStyle: "normal",
    },
    h6: {
      fontWeight: "bold",
      textAlign: "left",
      lineHeight: "150%",
      fontStyle: "normal",
      paddingBottom: "8px",
      fontSize: "1rem",
      "@media (max-width: 850px)": {
        fontSize: "0.4rem",
      },
      "@media (min-width: 851px) and (max-width: 990px)": {
        fontSize: "0.6rem",
      },
      "@media (min-width: 991px) and (max-width: 1130px)": {
        fontSize: "0.7rem",
      },
      "@media (min-width: 1131px) and (max-width: 1260px)": {
        fontSize: "0.8rem",
      },
      "@media (min-width: 1261px) and (max-width: 1440px)": {
        fontSize: "0.9rem",
      },
    },
    body1: {
      fontWeight: 600,
    },
    body2: {
      fontSize: "8px",
    },
  },
  palette: {
    // primary: {
    //   main: "#00796B", // Replace with your custom primary color
    // },
    success: {
      main: "#48d0a4", // Replace with your custom success color
    },
    error: {
      main: "#c73535", // Replace with your custom success color
    },
    info: {
      main: "#303030", // Replace with your custom success color
    },
    // Add more custom colors if needed...
  },
  overrides: {
    MuiChip: {
      root: {
        "@media (max-width: 1600px)": {
          // maxWidth: "15px",
          height: "1.6rem",
        },
        "@media (max-width: 1300px)": {
          // maxWidth: "15px",
          height: "1.2rem",
        },
        "& .MuiChip-icon": {
          order: 2,
          fontSize: 14,
          color: "white",
        },
        "& .MuiChip-label": {
          color: "white",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center",
          paddingLeft: "1.2rem",
          "@media (min-width: 768px) and (max-width: 851px)": {
            fontSize: "7px",
            paddingLeft: "0.1rem",
            paddingRight: "0.1rem",
          },
          "@media (min-width: 852px) and (max-width: 990px)": {
            fontSize: "8px",
            paddingLeft: "0.15rem",
            paddingRight: "0.15rem",
          },
          "@media (min-width: 991px) and (max-width: 1130px)": {
            fontSize: "8px",
            paddingLeft: "0.2rem",
            paddingRight: "0.2rem",
          },
          "@media (min-width: 1131px) and (max-width: 1290px)": {
            fontSize: "7px",
            paddingLeft: "0.3rem",
            paddingRight: "0.3rem",
          },
          "@media (min-width: 1291px) and (max-width: 1398px)": {
            fontSize: "9px",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
          "@media (min-width: 1399px) and (max-width: 1535px)": {
            fontSize: "10px",
            paddingLeft: "0.6rem",
            paddingRight: "0.6rem",
          },
          "@media (min-width: 1536px) and (max-width: 1700px)": {
            fontSize: "11px",
            paddingLeft: "0.8rem",
          },
        },
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow: "none", // Remove the box-shadow
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      tabletDown: 1201,
      tableUp: 1280,
      xl: 1536,
      mobile: 768,
    },
  },
});
