import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BullionzLogo from '../../../assets/images/bullionz-logo.svg';
import BullionzLogoDemo from '../../../assets/images/bullionz-logo-demo.svg';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import Divider from '@mui/material/Divider';
import { useAppContext } from '../../../context/DataContext';
import IButton from '../IButton/IButton';
import { calculatePercentageDifference } from '../../../helpers/calculatePercentageDifference';
import { tooltipContent } from '../../../constants/tooltipTexts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NonVerifiedAccountIcon from '../../../assets/images/non-verified-account-icon.svg';
import VerifiedAccountIcon from '../../../assets/images/verified-account-icon.svg';
import AccountMenu from '../AccountMenu/AccountMenu';
import NotificationsMenu from '../NotificationsMenu/NotificationsMenu';
import { refresh } from '../../../helpers/refresh';
import { userNotifications } from '../../../service/notifications';
import CloseIcon from '@mui/icons-material/Close';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../Popups/PleaseLoginPopup/PleaseLoginPopup';
import DemoHeaderBackground from '../../../assets/images/header-demo-background-new-50.png';
import clsx from 'clsx';
import { useEffect } from 'react';
import TransferFundsPopup from '../../MobileComponents/popups/TransferFundsPopup/TransferFundsPopup';

import { ReactComponent as CartIcon } from '../../../assets/images/cart-icon-mobile.svg';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import TransferFundsButtonMemoized from './TransferFundsButtonMemoized';
import DepositFundsButtonMemoized from './DepositFundsButtonMemoized';
import { useCryptoContext } from '../../../context/CryptoContext';
import { handleGetUserCart } from '../../../helpers/handleGetUserCart';
import { updateBucket } from '../../../helpers/update-client-bucket';
import { userService } from '../../../service/user';

const Header = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('DASHBOARD');
  const [notificationsAnchorEl, setNotificationsAccountAnchorEl] = useState(null);
  const {
    isAppView,
    avgCoinPrice,
    productsBucket,
    setChosenPeriod,
    IsSocketReconnecting,
    goldBidPriceForGroup,
    setGoldHistoryPeriod,
    portfolioAllocationData,
    activeTradeAccountBalance,
    userTradeAccounts,
    nonVerifiedPopUpOpen,
    userBrokerAccounts,
    accountAnchorEl,
    setAccountAnchorEl,
    setIsHeightUpdated,
    setIsTextUpdated,
    setArrowPositionUpdated,
    setTooltipPositionUpdated,
    activeTradeAccountCurrency,
    isUserVerified,
    exchangeRate,
    setIsCartFetched,
    setProductsBucket,
  } = useAppContext();

  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [bucketCount, setBucketCount] = useState(0);
  const [isTransferFundsPopUpOpen, setIsTransferFundsPopUpOpen] = useState(false);
  const [marketPrices, setMarketPrices] = useState([]);
  const [cryptoBalance, setCryptoBalance] = useState(0);
  const [userCartData, setUserCartData] = useState(null);

  const cachedGoldBidPriceForGroup = sessionStorage.getItem('goldBidPriceForGroup');
  const cachedAvgCoinPrice = sessionStorage.getItem('avgCoinPrice');
  const isDemoValue = sessionStorage.getItem('isDemo');
  const isDemo = isDemoValue === 'true';

  const store = useStore();

  const totalCoins = portfolioAllocationData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  let averageCoinPrice = 0;
  let priceForGroup = 0;

  if (avgCoinPrice === undefined || avgCoinPrice === 'undefined' || avgCoinPrice === null || avgCoinPrice === 'null') {
    averageCoinPrice = cachedAvgCoinPrice;
  } else {
    averageCoinPrice = avgCoinPrice;
  }

  if (
    goldBidPriceForGroup === undefined ||
    goldBidPriceForGroup === 'undefined' ||
    goldBidPriceForGroup === null ||
    goldBidPriceForGroup === 'null'
  ) {
    priceForGroup = cachedGoldBidPriceForGroup;
  } else {
    priceForGroup = goldBidPriceForGroup;
  }

  const currentPl = calculatePercentageDifference(averageCoinPrice, priceForGroup);

  const statesOfChip = {
    success: `${currentPl.toFixed(1)} % ▲`,
    error: `${currentPl.toFixed(1)} % ▼`,
    info: `${currentPl.toFixed(1)} % ●`,
    default: '',
  };
  const goldHoldings = totalCoins * priceForGroup;
  const showTransferFundsBtn = userTradeAccounts?.length + userBrokerAccounts?.length > 1;
  const balance = isNaN(Number(activeTradeAccountBalance)) ? 0 : Number(activeTradeAccountBalance);
  const accountValue = balance + goldHoldings || 0 + cryptoBalance || 0;

  const fetchUserNotifications = async () => {
    setLoading(true);
    try {
      const fetchedNotifications = await userNotifications.getUserNotifications({ page, pageSize });
      setNotifications((prevNotifications) => {
        const uniqueNotifications = fetchedNotifications?.notifications?.filter(
          (notification) =>
            !prevNotifications.some((existingNotification) => existingNotification.id === notification.id),
        );
        return [...prevNotifications, ...uniqueNotifications];
      });
      setPage(Number(fetchedNotifications?.page) + 1);
      if (fetchedNotifications?.notifications?.length < pageSize) {
        setReachedEnd(true);
      }
      setError('');
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError('Error fetching user notifications');
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  function navigateToDashboard(tab) {
    setGoldHistoryPeriod('1H');
    setChosenPeriod('1H');
    navigate(routes.dashboard);
    handleTabClick(tab);
    window.scrollTo(0, 0);
  }

  const navigateToDeposit = useCallback(() => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    if (window.location.pathname.includes('/deposit')) {
      refresh();
    }
    navigate(routes.deposit);
    window.scrollTo(0, 0);
  }, [navigate, location.pathname, routes.deposit]);

  const BTXG = document.getElementById('btxg-container');
  const buyGoldIcon = document.getElementById('buy-gold-icon');
  const newsContainer = document.getElementById('news-container');
  const portfolio = document.getElementById('portfolio-container');
  const transactions = document.getElementById('transactions-container');

  const balanceContainer = document.getElementById('balance-container');
  const goldHistory = document.getElementById('mobile-gold-history');
  const depositBtn = document.getElementById('deposit-btn');
  const redeemIcon = document.getElementById('redeem-icon');
  const yieldingIcon = document.getElementById('yielding-icon');
  const vaultingIcon = document.getElementById('vaulting-icon');
  const monthlyPool = document.getElementById('monthly-pool');
  const buyCloseContainer = React.useMemo(() => document.getElementById('buyclose-container'));

  const blurAllItems = () => {
    balanceContainer?.classList?.remove('except');
    depositBtn?.classList?.remove('except');
    buyGoldIcon?.classList?.remove('except');
    redeemIcon?.classList?.remove('except');
    yieldingIcon?.classList?.remove('except');
    vaultingIcon?.classList?.remove('except');
    monthlyPool?.classList?.remove('except');
    buyCloseContainer?.classList?.remove('except');
    BTXG?.classList?.remove('except');
    portfolio?.classList?.remove('except');
    transactions?.classList?.remove('except');
    newsContainer?.classList?.remove('except');
    goldHistory?.classList?.remove('except');
  };
  const handleNotificationsMenuClose = () => {
    setNotificationsAccountAnchorEl(null);
  };
  const handleAccountMenuClicked = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleAccountMenuClose = () => {
    setAccountAnchorEl(null);
  };
  const handleNotificationClicked = (event) => {
    setNotificationsAccountAnchorEl(event.currentTarget);
    if (notifications.length === 0) {
      fetchUserNotifications();
    }
  };

  const handleExitDemo = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate(routes.login);
    location.reload();
  };

  const handleTransferFunds = useCallback(() => {
    setIsTransferFundsPopUpOpen(true);
  }, []);

  const navigateToCartsPage = () => {
    navigate(routes.cart);
  };

  useEffect(() => {
    if (nonVerifiedPopUpOpen) {
      blurAllItems();
    } else {
      setIsHeightUpdated(false);
      setIsTextUpdated(false);
      setArrowPositionUpdated(false);
      setTooltipPositionUpdated(false);
    }
  }, [nonVerifiedPopUpOpen]);

  useEffect(() => {
    if (productsBucket) {
      let totalQuantity = 0;

      for (const productId in productsBucket) {
        totalQuantity += productsBucket[productId].quantity;
      }
      setBucketCount(totalQuantity);
    }
    if (isDemo) {
      setBucketCount(4);
    }
  }, [productsBucket, isDemo]);

  let allBrokerAccountsBalance = 0;

  userBrokerAccounts?.map((account, index) => {
    const usdtBalance = account?.assets?.find((asset) => asset.assetId === 'usdt')?.amount || 0;
    allBrokerAccountsBalance += usdtBalance;
  });

  useEffect(() => {
    const fetchPriceFromRedux = () => {
      const prices = store.getState().crypto.instruments;
      setMarketPrices(prices);
    };

    fetchPriceFromRedux(); // Initial fetch
    const intervalId = setInterval(fetchPriceFromRedux, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [store]);

  const calculateCryptoBalance = useCallback(() => {
    let totalCryptoPrice = 0;

    userBrokerAccounts.forEach((account) => {
      const accountTotal = account?.assets?.reduce((acc, asset) => {
        const amount = asset?.amount || 0;
        const instrumentId = `${asset?.assetId}_usdt`;
        const bidPrice = Number(marketPrices?.[instrumentId]?.bid) || 0;
        const currAssetPrice = bidPrice * amount;

        return acc + currAssetPrice;
      }, 0);

      totalCryptoPrice += accountTotal;
    });

    if (isDemo) {
      const demoExchangeRate = activeTradeAccountCurrency === 'EUR' ? 1.09274 : 1;
      setCryptoBalance(
        Number((totalCryptoPrice / demoExchangeRate + allBrokerAccountsBalance / demoExchangeRate)?.toFixed(2)),
      );
    } else if (isExchangeRateValid()) {
      setCryptoBalance(Number((totalCryptoPrice / exchangeRate + allBrokerAccountsBalance / exchangeRate)?.toFixed(2)));
    } else {
      setCryptoBalance(0);
    }
  });

  useEffect(() => {
    if (marketPrices) {
      calculateCryptoBalance();
    }
  }, [userBrokerAccounts, exchangeRate, marketPrices, allBrokerAccountsBalance, activeTradeAccountCurrency]);

  useEffect(() => {
    handleGetUserCart(setUserCartData, () => {});
  }, []);

  useEffect(() => {
    updateBucket(isAppView, userCartData, setIsCartFetched, setProductsBucket, userService?.updateShoppingCart);
  }, [userCartData]);

  const isExchangeRateValid = () => {
    if (
      (activeTradeAccountCurrency == 'EUR' && exchangeRate === 1) ||
      (activeTradeAccountCurrency == 'USD' && exchangeRate !== 1)
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Box className={isDemo ? classes.demoHeader : classes.header}>
        <div
          className={clsx(classes.headerContainer, {
            [classes.marginLeft]: isDemo,
          })}
        >
          <div>
            <div className={classes.logoContainer}>
              <img
                src={isDemo ? BullionzLogoDemo : BullionzLogo}
                className={isDemo ? classes.demoLogo : classes.logo}
                alt="Bullionz logo"
                onClick={() => navigateToDashboard('DASHBOARD')}
              />
            </div>
          </div>

          <div className={classes.valuesContainer} id="balance-container">
            <div className={classes.fieldContainer}>
              <div className={classes.fieldTitle}>Total Balance</div>
              <div className={classes.fieldValue}>
                {/* {accountValue
                  ? currencyFormatter(accountValue, CURRENCY_TYPE)
                  : currencyFormatter(0, CURRENCY_TYPE)} */}
                {currencyFormatter(IsSocketReconnecting || !accountValue ? 0 : balance + goldHoldings + cryptoBalance)}
              </div>
              <div className={classes.infoBtn}>
                <IButton custom tooltipTitle={tooltipContent.totalBalance} />
              </div>
            </div>

            <div className={classes.fieldContainer}>
              <div className={classes.fieldTitle}>Cash Balance</div>
              <div className={classes.fieldValue}>
                {IsSocketReconnecting ? currencyFormatter(0) : currencyFormatter(balance)}
              </div>
              <div className={classes.infoBtn}>
                <IButton custom tooltipTitle={tooltipContent.cashBalance} />
              </div>
            </div>

            <div className={classes.goldHoldingsContainer}>
              <div className={classes.fieldContainer}>
                <div className={classes.fieldTitle}>Gold Holdings</div>
                <div className={classes.fieldValue}>
                  {IsSocketReconnecting || !goldHoldings ? currencyFormatter(0) : currencyFormatter(goldHoldings)}
                </div>
                <div className={classes.infoBtn}>
                  <IButton custom tooltipTitle={tooltipContent.goldHoldings} />
                </div>
              </div>

              <Divider className={classes.divider} orientation="vertical" flexItem />
              <div className={classes.fieldContainer}>
                <div className={classes.fieldTitle}>Crypto</div>
                <div className={classes.fieldValue}>{currencyFormatter(cryptoBalance || 0, '', '')}</div>
                <div className={classes.infoBtn}>
                  <IButton custom tooltipTitle={tooltipContent.cryptoBalance} />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.rightButtonsContainer}>
            {showTransferFundsBtn && (
              <TransferFundsButtonMemoized classes={classes} handleTransferFunds={handleTransferFunds} />
            )}

            <div className={classes.depositBtnContainer} id="deposit-btn">
              <DepositFundsButtonMemoized classes={classes} navigateToDeposit={navigateToDeposit} />

              {isDemo && (
                <Button
                  className={classes.quitDemoBtn}
                  onClick={handleExitDemo}
                  startIcon={<CloseIcon fontSize="large" />}
                >
                  QUIT DEMO
                </Button>
              )}
            </div>
            {/* <div>
              <div className={classes.portfolioPLContainer}>
                <div className={classes.text}>Portfolio P/L</div>
                <Box className={classes.buttonContainer}>
                  <Stack direction="row" textAlign="right">
                    <Chip
                      label={
                        statesOfChip[getColor(averageCoinPrice, priceForGroup)]
                      }
                      color={getColor(averageCoinPrice, priceForGroup)}
                    />
                  </Stack>
                </Box>
              </div>
            </div> */}

            <div>
              <div onClick={navigateToCartsPage} className={classes.cartIconWrapper}>
                <CartIcon className={classes.cartIcon} />
                {bucketCount > 0 && <span className={classes.cartCounter}>{bucketCount}</span>}
              </div>
            </div>

            {/* <div>
              {unreadNotificationsCounter > 0 ? (
                <div className={classes.notificationIcon}>
                  <img
                    src={NotificationIcon}
                    alt="Notification Icon"
                    onClick={handleNotificationClicked}
                  />
                </div>
              ) : (
                <div className={classes.notificationIcon}>
                  <img
                    src={NotificationIconEmpty}
                    alt="Notification Icon Empty"
                    onClick={handleNotificationClicked}
                  />
                </div>
              )}
            </div> */}

            <div>
              <div className={classes.accountIconContainer}>
                <img
                  id="accountMenu"
                  src={isUserVerified === 'false' ? NonVerifiedAccountIcon : VerifiedAccountIcon}
                  alt="Account Icon"
                  className={classes.accountIcon}
                  onClick={handleAccountMenuClicked}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>

      <AccountMenu
        anchorEl={accountAnchorEl}
        open={Boolean(accountAnchorEl)}
        handleClose={handleAccountMenuClose}
        setIsTransferFundsPopUpOpen={setIsTransferFundsPopUpOpen}
      />
      <NotificationsMenu
        anchorEl={notificationsAnchorEl}
        open={Boolean(notificationsAnchorEl)}
        handleClose={handleNotificationsMenuClose}
        setLoading={setLoading}
        notifications={notifications}
        setNotifications={setNotifications}
        setError={setError}
        loadingMore={loadingMore}
        setLoadingMore={setLoadingMore}
        reachedEnd={reachedEnd}
        setReachedEnd={setReachedEnd}
        error={error}
        loading={loading}
        setPage={setPage}
        pageSize={pageSize}
        fetchUserNotifications={fetchUserNotifications}
        page={page}
      />
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      {isTransferFundsPopUpOpen && (userTradeAccounts?.length || userBrokerAccounts?.length) && (
        <TransferFundsPopup
          isPopupOpen={isTransferFundsPopUpOpen}
          setIsPopupOpen={setIsTransferFundsPopUpOpen}
          userTradeAccounts={userTradeAccounts}
          userBrokerAccounts={userBrokerAccounts}
        />
      )}
    </>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'var(--black)',
    width: '100%',
    minHeight: '65px',
    height: '65px',
  },
  demoHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '65px',
    backgroundImage: `url(${DemoHeaderBackground})`,
    backgroundRepeat: 'round',
    backgroundColor: 'black',
    height: '65px',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    height: '65px',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '24px',
  },
  marginLeft: {
    marginLeft: '1%',
  },
  helperContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '60%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('1230')]: {
      width: '70%',
    },
    border: '2px solid white',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  logo: {
    width: '220px',
    [theme.breakpoints.down('1800')]: {
      width: '200px',
    },
    [theme.breakpoints.down('1600')]: {
      width: '200px',
    },
    [theme.breakpoints.down('1300')]: {
      width: '160px',
    },
  },
  demoLogo: {
    width: '150px',
    [theme.breakpoints.down('1800')]: {
      width: '130px',
    },
    [theme.breakpoints.down('1300')]: {
      width: '100px',
    },
    [theme.breakpoints.down('870')]: {
      width: '80px',
    },
  },
  valuesContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: theme.typography.fontFamily,
    gap: '24px',
    minHeight: '34px',
    height: '90%',
    [theme.breakpoints.down('1200')]: {
      gap: '15px',
    },
    [theme.breakpoints.down('1085')]: {
      gap: '13px',
    },
  },
  goldHoldingsContainer: {
    display: 'flex',
    position: 'relative',
  },
  fieldContainer: {
    display: 'flex',
    position: 'relative',
    gap: '7px',
    [theme.breakpoints.down('1080')]: {
      flexDirection: 'column',
      gap: '0',
    },
  },
  fieldTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 'clamp(8px, 2vw, 14px)',
    color: '#FFFFFF',
    fontWeight: '400',
    [theme.breakpoints.down('1400')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('1350')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.down('1100')]: {
      fontSize: '9px',
    },
    [theme.breakpoints.down('870')]: {
      fontSize: '8px',
    },
  },
  fieldValue: {
    display: 'flex',
    alignItems: 'center',
    color: 'var(--gold)',
    fontSize: 'clamp(10px, 2vw, 22px)',
    fontWeight: '700',
    [theme.breakpoints.down('1300')]: {
      fontSize: '17px',
    },
    [theme.breakpoints.down('870')]: {
      fontSize: '14px',
    },
  },
  infoBtn: {
    zIndex: '1',
    position: 'relative',
    left: '17px ',
    bottom: '15px',
    padding: '0px',
    [theme.breakpoints.down('1080')]: {
      left: '23px ',
      bottom: '60px',
    },
    [theme.breakpoints.down('870')]: {
      bottom: '54px',
    },
  },
  divider: {
    backgroundColor: '#D9D9D980',
    height: '34px',
    marginLeft: '11px',
    marginRight: '8px',
    [theme.breakpoints.down('1080')]: {
      position: 'relative',
      top: '10px',
    },
    [theme.breakpoints.down('870')]: {
      top: '4px',
    },
  },
  depositBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginLeft: '5px',
    marginRight: '5px',
  },

  depositFundsBtnReal: {
    width: '10rem',
    height: '36px',
    cursor: 'pointer',
    backgroundColor: 'var(--gold)',
    color: 'var(--white)',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '60px',
    '&:hover': {
      background: 'var(--gold) !important',
    },
    '& svg': {
      backgroundColor: 'var(--gold)',
      color: 'var(--white)',
    },
    [theme.breakpoints.down('1600')]: {
      width: '8rem',
      height: '31px',
      fontSize: '11px',
    },
    [theme.breakpoints.down('1400')]: {
      width: '7rem',
      height: '29px',
      fontSize: '9px',
    },
    [theme.breakpoints.down('1160')]: {
      width: '6rem',
      height: '25px',
      fontSize: '7px',
    },
    [theme.breakpoints.down('1000')]: {
      width: '5.5rem',
      height: '25px',
      fontSize: '6px',
    },
  },

  transferFundsBtnReal: {
    width: '12rem',
    height: '36px',
    cursor: 'pointer',
    border: '1px solid var(--gold)',
    color: 'var(--gold)',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '60px',
    '&:hover': {},
    '& svg': {
      color: 'var(--gold)',
    },
    [theme.breakpoints.down('1600')]: {
      width: '10rem',
      height: '31px',
      fontSize: '11px',
    },
    [theme.breakpoints.down('1400')]: {
      width: '9rem',
      height: '29px',
      fontSize: '10px',
    },
    [theme.breakpoints.down('1160')]: {
      width: '8rem',
      height: '25px',
      fontSize: '7px',
    },
    [theme.breakpoints.down('1000')]: {
      width: '7rem',
      height: '25px',
      fontSize: '7px',
    },
  },
  quitDemoBtn: {
    width: '10rem',
    height: '37px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    border: '1px solid white',
    borderRadius: '60px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    '& .MuiButton-startIcon': {
      marginLeft: '-15px !important',
    },
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'black !important',
    },
    [theme.breakpoints.down('1600')]: {
      width: '8rem',
      height: '32px',
      fontSize: '11px',
    },
    [theme.breakpoints.down('1400')]: {
      width: '7rem',
      height: '29px',
      fontSize: '10px',
    },
    [theme.breakpoints.down('1160')]: {
      width: '6rem',
      height: '26px',
      fontSize: '8px',
    },
  },
  rightButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    [theme.breakpoints.down('1440')]: {
       gap: '0px'
    },
  },
  portfolioPLContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('1600')]: {
      flexDirection: 'column',
    },
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '9px',
    fontWeight: '400',
    color: 'white',
    position: 'relative',
    right: '8%',
    [theme.breakpoints.down('1230')]: {
      fontSize: 'var(--font-size-8)',
    },
    [theme.breakpoints.down('1160')]: {
      fontSize: '7px',
    },
  },
  buttonContainer: {
    '& .MuiChip-root': {
      height: '22px',
      width: 'auto',
      minWidth: '50px',
      maxWidth: '120px',
      color: 'var(--white)',
      [theme.breakpoints.down('1230')]: {
        height: '18px',
      },
      [theme.breakpoints.down('1170')]: {
        height: '16px',
      },
    },
    '& .MuiChip-label': {
      fontSize: 'var(--font-size-12)',
      paddingLeft: '5px',
      paddingRight: '5px',
      fontWeight: '700',
      [theme.breakpoints.down('1230')]: {
        fontSize: 'var(--font-size-10)',
      },
      [theme.breakpoints.down('1170')]: {
        fontSize: 'var(--font-size-8)',
      },
    },
  },

  cartIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'fixed',
    bottom: '70px',
    right: '20px',

    width: '50px',
    height: '50px',
    borderRadius: '50%',
    background: 'black',
    boxShadow: '1px 1px 20px 0px rgba(0,0,0,0.75)',
    zIndex: 1000,
    cursor: 'pointer',
  },
  cartIcon: {
    width: '19px',
    height: '18px',
  },
  cartCounter: {
    position: 'absolute',
    top: -3,
    right: -5,
    display: 'flex',
    justifyContent: 'center',
    width: 12,
    height: 12,
    color: 'white',
    fontWeight: 600,
    fontSize: '8px',
    borderRadius: '50%',
    background: '#FF4228',
  },
  notificationIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  accountIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accountIcon: {
    width: '33px',
    cursor: 'pointer',
  },
}));
