import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@mui/styles';

import StoreGrid from './StoreGrid';
import StoreFooter from './StoreFooter';
import { StoreItem } from './StoreItem';
import StoreFilters from './StoreFilters';

import { useStorePage } from './hooks/use-store-page';
import { useAppContext } from '../../../context/DataContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import { useRef } from 'react';
import { useEffect } from 'react';

function StorePage() {
  const {
    setPage,
    setSort,
    setPriceRange,
    setSelectedItem,
    setProductsView,
    handleAddToCard,
    setPriceFilterValue,
    setSelectedCategory,
    setIsAddToCardTriggered,
    setIsPriceFilterApplied,

    sort,
    page,
    isPreview,
    isLoading,
    priceRange,
    totalPages,
    categories,

    productsView,
    productsData,
    selectedItem,
    totalProducts,
    isProductLoading,
    selectedCategory,
    priceFilterValue,
    isCategoriesLoading,
    isPriceFilterApplied,
    isAddToCardTriggered,
  } = useStorePage();

  const { isAppView, isMobile } = useAppContext();

  const hasIdParam = !!selectedItem;
  const isWeb = !isAppView && !isMobile;

  const classes = useStyles({
    isListView: productsView === 'list',
    hasIdParam,
  });

  const gridWrapperRef = useRef(null);
  const listWrapperRef = useRef(null);

  useEffect(() => {
    if (!isWeb && gridWrapperRef.current) {
      const handleScroll = () => {
        if (gridWrapperRef.current) {
          const { scrollTop, clientHeight, scrollHeight } = gridWrapperRef.current;
          if (scrollTop + clientHeight + 1 >= scrollHeight && !isLoading && page < totalPages) {
            setPage((prev) => prev + 1);
            // window.scroll({ top: scrollTop });
          }
        }
      };

      if (gridWrapperRef.current) {
        gridWrapperRef.current.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (gridWrapperRef.current) {
          gridWrapperRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [isLoading, page, setPage, totalPages, isWeb, productsView]);

  useEffect(() => {
    if (!isWeb && listWrapperRef.current) {
      const handleScroll = () => {
        if (listWrapperRef.current) {
          const { scrollTop, clientHeight, scrollHeight } = listWrapperRef.current;

          if (scrollTop + clientHeight + 1 >= scrollHeight && !isLoading && page < totalPages) {
            setPage((prev) => prev + 1);
            // window.scroll({ top: scrollTop });
          }
        }
      };
      if (listWrapperRef.current) {
        listWrapperRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (listWrapperRef.current) {
          listWrapperRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [isLoading, page, setPage, totalPages, isWeb, productsView]);

  return (
    <div className={clsx(isWeb ? classes.wrapper : classes.mobileWrapper)}>
      {isAddToCardTriggered && <AddToCardPopup isWeb={isWeb} />}

      {Boolean(isWeb && !isCategoriesLoading && priceRange && productsData) && (
        <StoreFilters
          setPage={setPage}
          setPriceRange={setPriceRange}
          isPreview={isPreview}
          categories={categories}
          priceRange={priceRange}
          priceFilterValue={priceFilterValue}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setPriceFilterValue={setPriceFilterValue}
          isPriceFilterApplied={isPriceFilterApplied}
          setIsPriceFilterApplied={setIsPriceFilterApplied}
        />
      )}

      {!isPreview && productsData && (
        <StoreGrid
          sort={sort}
          setSort={setSort}
          setPage={setPage}
          isLoading={isLoading}
          products={productsData}
          categories={categories}
          productsView={productsView}
          totalProducts={totalProducts}
          isProductLoading={isProductLoading}
          selectedCategory={selectedCategory}
          isCategoriesLoading={isCategoriesLoading}
          handleAddToCard={handleAddToCard}
          setProductsView={setProductsView}
          setSelectedItem={setSelectedItem}
          setSelectedCategory={setSelectedCategory}
          setIsAddToCardTriggered={setIsAddToCardTriggered}
          gridWrapperRef={gridWrapperRef}
          listWrapperRef={listWrapperRef}
        />
      )}

      {isPreview && !isLoading && !isProductLoading && !isCategoriesLoading && (
        <StoreItem
          item={selectedItem}
          products={productsData}
          isLoading={isProductLoading}
          handleAddToCard={handleAddToCard}
          isCategoriesLoading={isCategoriesLoading}
          setIsAddToCardTriggered={setIsAddToCardTriggered}
        />
      )}

      {!isCategoriesLoading && isWeb && (
        <StoreFooter
          page={page}
          setPage={setPage}
          isPreview={isPreview}
          totalPages={totalPages}
          totalProducts={totalProducts}
        />
      )}
    </div>
  );
}

const AddToCardPopup = ({ isWeb }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView } = useAppContext();

  const navigateToCard = () => {
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage('{"Event": "Cart"}');
    } else {
      navigate(routes.cart);
    }
  };
  return (
    <div className={isWeb ? classes.addToCardPopupWrapper : classes.addToCardPopupWrapperMobile}>
      <span className={classes.checkoutPopupTitle}>Product was added to your cart.</span>

      <span className={classes.checkoutBtnStyle} onClick={navigateToCard}>
        Checkout now
      </span>
    </div>
  );
};

export default StorePage;

const useStyles = makeStyles((theme) => ({
  wrapper: ({ isListView, hasIdParam }) => ({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '200px 1fr',
    gridTemplateRows: hasIdParam ? '1fr' : '1fr 64px',
    gridTemplateAreas: `
        "filter grid"
        "filter-footer footer"
        `,
    columnGap: 20,
    // height: isListView
    //   ? "calc(100dvh - 226px)"
    //   : hasIdParam
    //   ? "calc(100dvh - 130px)"
    //   : "calc(100dvh - 200px)",
    height: '98%',
    marginTop: '1rem',
    minHeight: 550,
    '@media max-width 768px': {
      fontSize: 14,
    },
  }),

  mobileWrapper: {
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
    "grid"
    "footer"
    `,
    height: 'calc(100dvh - 206px)',
    '@media (orientation: landscape)': {
      height: 'auto',
    },
  },

  addToCardPopupWrapper: {
    position: 'absolute',
    top: '65px',
    right: '50px',

    display: 'flex',
    gap: 50,

    zIndex: 30,
    width: '500px',
    padding: '20px',
    borderRadius: '6px',
    background: 'white',
    boxShadow: '0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
  },

  addToCardPopupWrapperMobile: {
    position: 'absolute',
    top: '136px',
    right: '50px',

    display: 'flex',
    flexDirection: 'column',
    gap: 10,

    zIndex: 30,
    maxWidth: '320px',
    padding: '20px',
    borderRadius: '6px',
    background: 'white',
    boxShadow: '0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
  },

  checkoutPopupTitle: {},

  checkoutBtnStyle: {
    cursor: 'pointer',
    color: 'var(--green-light)',
    textDecoration: 'underline',
  },
}));
