import serviceAutoInvest from "../service/autoInvest";

// transactions -  fetch again first 20 transactions and update old state with them
// 373 - 392 - page 1
// 372 - 353 - page 2
// new auto invest  - fetch again first 20 and update current state
// array[1, 2, 3, 4, 5, 6] replace first 20 transactons with filteredNewAutoInvestTransactions

export const handleGetAllAutoInvestMobileTransactions = async (
  fn,
  page,
  setAllPages,
  setError,
  pageSize,
  cancelledAutoInvestTransaction,
  isAutoInvestClicked,
  setLoading
) => {
  setLoading(true);
  try {
    const response = await serviceAutoInvest.getAllAutoInvest(page, pageSize);
    if (!response.data.length) {
      setError(true);
    }
    const autoInvestTransactions = response.data.map(({ ...rest }) => {
      return {
        id: rest.id,
        date: rest.date,
        volume: rest.volume,
        period: rest.period,
        status: rest.status,
        currency: rest.currency,
        tradeAccountId: rest.tradeAccountId,
      };
    });
    autoInvestTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));

    let filteredNewAutoInvestTransactions;
    if (isAutoInvestClicked) {
      const newAutoInvesTransactions = await serviceAutoInvest.getAllAutoInvest(
        1,
        pageSize
      );
      filteredNewAutoInvestTransactions = newAutoInvesTransactions.data.map(
        ({ ...rest }) => {
          return {
            id: rest.id,
            date: rest.date,
            volume: rest.volume,
            period: rest.period,
            status: rest.status,
            currency: rest.currency,
            tradeAccountId: rest.tradeAccountId,
          };
        }
      );
    }

    fn((prevTransactions) => {
      let combinedArray;
      if (isAutoInvestClicked) {
        combinedArray = [
          ...filteredNewAutoInvestTransactions,
          ...autoInvestTransactions,
        ].filter(
          (transaction, index, self) =>
            self.findIndex((t) => t.id === transaction.id) === index
        );
      } else {
        combinedArray = [...prevTransactions, ...autoInvestTransactions].filter(
          (transaction, index, self) =>
            self.findIndex((t) => t.id === transaction.id) === index
        );
      }
      const indexToReplace = combinedArray.findIndex(
        (transaction) => transaction.id === cancelledAutoInvestTransaction.id
      );
      if (indexToReplace !== -1) {
        combinedArray[indexToReplace].status =
          cancelledAutoInvestTransaction.status;
      }
      return combinedArray;
    });
    setAllPages(response.pages);
  } catch (e) {
    setError(true);
  } finally {
    setLoading(false);
  }
};
