import React from 'react';
import { Box } from '@mui/material';
import { createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack, Chip } from '@mui/material';
import { useAppContext } from '../../../context/DataContext';
import { getColor } from '../../../helpers/currencyFormatter/getColor';
import { calculatePercentageDifference } from '../../../helpers/calculatePercentageDifference';

const PLGold = () => {
  const { avgCoinPrice, goldBidPriceForGroup } = useAppContext();
  const cachedAvgCoinPrice = sessionStorage.getItem('avgCoinPrice');
  const cachedGoldBidPriceForGroup = sessionStorage.getItem('goldBidPriceForGroup');
  const currency = localStorage.getItem('currency');
  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  const classes = useStyles();

  let averageCoinPrice = 0;
  let priceForGroup = 0;

  if (avgCoinPrice === undefined || avgCoinPrice === 'undefined' || avgCoinPrice === null || avgCoinPrice === 'null') {
    averageCoinPrice = cachedAvgCoinPrice;
  } else {
    averageCoinPrice = avgCoinPrice;
  }

  if (
    goldBidPriceForGroup === undefined ||
    goldBidPriceForGroup === 'undefined' ||
    goldBidPriceForGroup === null ||
    goldBidPriceForGroup === 'null'
  ) {
    priceForGroup = cachedGoldBidPriceForGroup;
  } else {
    if (isDemo && currency == 'EUR') {
      priceForGroup = goldBidPriceForGroup + 0.06;
    } else {
      priceForGroup = goldBidPriceForGroup;
    }
  }

  const currentPl = calculatePercentageDifference(averageCoinPrice, priceForGroup);

  const statesOfChip = {
    success: `${currentPl.toFixed(1)} % ▲`,
    error: `${currentPl.toFixed(1)} % ▼`,
    info: `${currentPl.toFixed(1)} % ●`,
    default: '',
  };
  return (
    <Box className={classes.buttonContainer}>
      <Stack direction="row" textAlign="right">
        <Chip
          label={statesOfChip[getColor(averageCoinPrice, priceForGroup)]}
          color={getColor(averageCoinPrice, priceForGroup)}
        />
      </Stack>
    </Box>
  );
};

export default PLGold;

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonContainer: {
      '& .MuiChip-root': {
        height: '22px',
        width: 'auto',
        minWidth: '50px',
        maxWidth: '120px',
        color: 'var(--white)',
        [theme.breakpoints.down('1230')]: {
          height: '20px',
        },
        [theme.breakpoints.down('1170')]: {
          height: '18px',
        },
      },
      '& .MuiChip-label': {
        fontSize: 'var(--font-size-12)',
        paddingLeft: '5px',
        paddingRight: '5px',
        fontWeight: '700',
        [theme.breakpoints.down('1230')]: {
          fontSize: 'var(--font-size-11)',
        },
      },
    },
  }),
);
