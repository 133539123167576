import { fetchWrapper } from "../../utils/fetchWrapper";

const groupedTransactionsUrl = `/order-transaction`;

const getAllCertificatesPerClient = async (page = 0, pageSize = 4) => {
  try {
    if (page == 0) {
      const response = await fetchWrapper.get(
        `${groupedTransactionsUrl}/certificates`
      );
      return response;
    } else {
      const response = await fetchWrapper.get(
        `${groupedTransactionsUrl}/certificates?page=${page}&pageSize=${pageSize}`
      );
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};

const certificatesService = {
  getAllCertificatesPerClient,
};

export default certificatesService;
