import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import CustomButton from "../../../CustomButton/CustomButton";
import BullionzLogo from "../../../../../assets/images/bullionz-logo-coin.svg";
import StakeBackgroundLayer from "../../../../../assets/images/stake-background-layer.svg";
import VaultBackgroundLayer from "../../../../../assets/images/vault-background-layer.svg";
import currencyFormatter from "../../../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../../../constants/globalconstants";
import "../../../../../styles/global.fonts.css";

const btnValues = ["1m", "6m", "9m", "1y"]; // months

function AutoInvestCardPopup({
  type = "stake",
  quantity,
  handleSetQuantity,
  stakingPeriod,
  handleStakingPeriod,
  price,
  total,
  setQuantity,
}) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");


  const handleInputChange = (event) => {
    const qty = event.target.value;
    handleSetQuantity(qty);
  };

  const handleChosenMonths = (value) => {
    handleStakingPeriod(value);
  };

  const handleInputFocus = () => {
    quantity === 0 && setQuantity("");
  };

  return (
    <div style={{ backgroundColor: "#347362" }} className={classes.container}>
      <div className={classes.backgroundImage}>
        <img
          src={type === "stake" ? StakeBackgroundLayer : VaultBackgroundLayer}
          alt="Background Layer"
          className={classes.image}
        />
      </div>
      <p className={classes.fieldTitle}>Auto Invest</p>
      <div className={classes.helperDiv}>
        <Box style={{ backgroundColor: "#347362" }} className={classes.field}>
          <div className={classes.bullionzGoldContainer}>
            <div className={classes.logoContainer}>
              <img
                className={classes.logo}
                src={BullionzLogo}
                alt="Bullionz logo"
              />
            </div>
            <div className={classes.bullionzGoldField}>
              <Box className={classes.title}>Bullionz Gold</Box>
              <Box className={classes.btCoin}>BT Coin</Box>
            </div>
            <div className={classes.priceFieldInTextField}>
              {currencyFormatter(price, CURRENCY_TYPE)}
            </div>
            <div className={classes.qtyFieldInTextField}>Qty: {quantity}</div>
          </div>
          <div className={classes.dynamicField}>
            <TextField
              className={classes.customInput}
              onFocus={handleInputFocus}
              autoComplete="off"
              label={errorMessage}
              error={!!errorMessage}
              type="number"
              id="filled-size-small"
              value={quantity}
              size="small"
              inputProps={{
                inputMode: "numeric",
                type: "number",
                pattern: "[0-9]*",
                step: "1",
                min: "0",
                max: "99999",
              }}
              onKeyDown={(event) => {
                var value = event.target.value;
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "." ||
                  event?.key === "e" ||
                  event?.key === "," ||
                  (value === "" && event?.key === "0") ||
                  (value === "0" && event?.key === "0") ||
                  (value.length >= 5 &&
                    event?.key !== "Backspace" &&
                    event?.key !== "Delete" &&
                    event?.key !== "ArrowLeft" &&
                    event?.key !== "ArrowRight")
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => handleInputChange(e)}
            />
            <Box className={classes.gr}>gr</Box>
          </div>
        </Box>
      </div>
      <div
        style={{ justifyContent: type === "stake" ? "space-between" : "end" }}
        className={classes.monthButtons}
      >
        {type === "stake" ? (
          <div className={classes.buttonContainer}>
            {btnValues.map((value) => (
              <div key={value} className={classes.greenButton}>
                <CustomButton
                  type={
                    stakingPeriod === value
                      ? "monthBtnGreener"
                      : "monthBtnDarkGreen"
                  }
                  buttonText={value}
                  width="100%"
                  height="100%"
                  onClick={() => handleChosenMonths(value)}
                />
              </div>
            ))}
          </div>
        ) : null}
        <div className={classes.priceField}>
          Total: {currencyFormatter(total, CURRENCY_TYPE)}
        </div>
      </div>
    </div>
  );
}

export default AutoInvestCardPopup;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: "11rem",
    marginTop: "-21px",
    border: "2px",
    borderRadius: "24px",
  },
  backgroundImage: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  image: {
    height: "100%",
    objectFit: "cover",
    borderRadius: "24px",
  },
  fieldTitle: {
    position: "relative",
    display: "flex",
    paddingLeft: "3%",
    paddingTop: "1.5%",
    paddingBottom: "0px",
    bottom: "-9px",
    color: "var(--white)",
    fontSize: "var(--font-size-19)",
    fontWeight: "600",
    fontFamily: theme.typography.fontFamily,
  },
  helperDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  field: {
    border: "0.5px solid #D2D5D8",
    display: "flex",
    width: "93%",
    height: "68px",
    alignItems: "center",
    fontSize: "var(--font-size-16)",
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    left: "3.5%",
    [theme.breakpoints.down("1500")]: {
      height: "58px",
      left: "2%",
      width: "96%",
    },
    [theme.breakpoints.down("1400")]: {
      height: "48px",
    },
  },
  bullionzGoldContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "space-between",
    left: "2%",
  },
  logoContainer: {
    marginRight: "7px",
  },
  logo: {
    width: "25px",
    [theme.breakpoints.down("1500")]: {
      width: "23px",
    },
    [theme.breakpoints.down("1400")]: {
      width: "20px",
    },
  },
  bullionzGoldField: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: "var(--font-size-14)",
    color: "white",
    fontFamily: theme.typography.fontFamily,
    width: "50%",
    [theme.breakpoints.down("930")]: {
      width: "100px",
    },
  },
  title: {
    fontWeight: "bolder",
    fontSize: "var(--font-size-18)",
    letterSpacing: "normal",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("1500")]: {
      fontSize: "var(--font-size-16)",
    },
    [theme.breakpoints.down("1412")]: {
      fontSize: "var(--font-size-14)",
    },
  },
  btCoin: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-17)",

    [theme.breakpoints.down("1500")]: {
      fontSize: "var(--font-size-15)",
    },
    [theme.breakpoints.down("1412")]: {
      fontSize: "var(--font-size-12)",
    },
  },
  gr: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-19)",
    [theme.breakpoints.down("1500")]: {
      fontSize: "var(--font-size-17)",
    },
    [theme.breakpoints.down("1412")]: {
      fontSize: "var(--font-size-14)",
    },
    [theme.breakpoints.down("1366")]: {
      fontSize: "var(--font-size-12)",
    },
  },
  dynamicField: {
    color: "white",
    position: "relative",
    marginLeft: "auto",
    width: "17%",
    paddingRight: "3%",
    height: "100%",
    display: "flex",
    backgroundColor: "var(--month-button-dark-green)",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("1500")]: {
      fontSize: "var(--font-size-14)",
      width: "19%",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-13)",
      width: "30%",
    },
    [theme.breakpoints.down("1110")]: {
      fontSize: "var(--font-size-12)",
    },
    [theme.breakpoints.down("1060")]: {
      fontSize: "var(--font-size-11)",
    },
    [theme.breakpoints.down("930")]: {
      width: "17%",
    },
  },
  customInput: {
    width: "100%",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiInputBase-input": {
      fontSize: "var(--font-size-19)",
      textAlign: "right",
      paddingRight: "3px",
      color: "white",
      [theme.breakpoints.down("1800")]: {
        fontSize: "var(--font-size-18)",
      },
      [theme.breakpoints.down("1500")]: {
        fontSize: "var(--font-size-15)",
      },
      [theme.breakpoints.down("1300")]: {
        fontSize: "var(--font-size-14)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
    "& .Mui-error": {
      paddingLeft: "42px",
    },
  },
  monthButtons: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    top: "10px",
    overflow: "hidden",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  buttonContainer: {
    display: "flex",
    gap: "4%",
  },
  greenButton: {
    width: "55px",
    height: "28px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginRight: "3%",
    "& .MuiTypography-root": {
      fontSize: "var(--font-size-16)",
    },
  },
  priceField: {
    fontFamily: theme.typography.fontFamily,
    color: "white",
    fontSize: "var(--font-size-21)",
    [theme.breakpoints.down("1400")]: {
      alignSelf: "flex-start",
      fontSize: "var(--font-size-16)",
    },
  },
  priceFieldInTextField: {
    fontFamily: theme.typography.fontFamily,
    color: "white",
    paddingLeft: "1rem",
    width: "8rem",
    fontSize: "var(--font-size-19)",
    fontWeight: "500",
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-14)",
    },
    [theme.breakpoints.down("930")]: {
      width: "6rem",
    },
  },
  qtyFieldInTextField: {
    fontFamily: theme.typography.fontFamily,
    color: "white",
    paddingLeft: "2rem",
    width: "8.5rem",
    fontSize: "var(--font-size-19)",
    fontWeight: "500",
    [theme.breakpoints.down("1800")]: {
      paddingLeft: "0px",
      fontSize: "var(--font-size-18)",
    },
    [theme.breakpoints.down("1500")]: {
      fontSize: "var(--font-size-15)",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-14)",
      width: "7rem",
      paddingRight: "6px",
    },
    [theme.breakpoints.down("930")]: {
      width: "5rem",
    },
  },
}));
