import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
  instruments: {},
};

export const cryptoSlice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {
    addInstrument: (state, action) => {
      state.instruments[action.payload.instrument] = {
        ...state?.instruments?.[action.payload.instrument],
        ...action?.payload,
      };
    },

    addManyInstruments: (state, action) => {
      const data = action?.payload;

      data?.forEach((elm) => {
        const storeElement = state?.instruments?.[elm?.instrument];

        if (storeElement) {
          state.instruments[elm.instrument] = {
            ...storeElement,
            ...elm,
          };
        } else {
          state.instruments[elm.instrument] = {
            ...elm,
          };
        }
      });
    },
  },
});

export const { addInstrument, addManyInstruments } = cryptoSlice.actions;

export default cryptoSlice.reducer;
