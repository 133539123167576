import React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useCallback } from "react";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import CertificateIcon from "../../../assets/images/certificateIcon.svg";
import clsx from "clsx";
import { useEffect } from "react";
import { useAppContext } from "../../../context/DataContext";
import certificatesService from "../../../service/certificates";
import { formatDateWrapper } from "../../../helpers/dateFormatter";
import LoadingBar from "../../common/LoadingBar/LoadingBar";
import CustomButton from "../../common/CustomButton/CustomButton";
import { tooltipContent } from "../../../constants/tooltipTexts";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FCF5D6",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#FCF5D6",
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} className={classes.tableRow}>
        <StyledTableCell>
          <IconButton aria-label="date-calendar" size="small">
            <img src={CertificateIcon} alt="Certificates icon" style={{ width: 12, height: 12, padding: 1 }} />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {formatDateWrapper(new Date(row.date).getTime(), "long")}
        </StyledTableCell>
        <StyledTableCell>{row.volume}</StyledTableCell>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon style={{ color: "#347362" }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "#347362" }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <StyledTableRow className={classes.tableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell className={clsx(classes.firstStyledTableCell)}>Certificate</StyledTableCell>
                  <StyledTableCell align="center">Lock Number</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={row.certificateId} className={classes.tableRow}>
                  <StyledTableCell component="th" scope="row" className={classes.firstStyledTableCell}>
                    {row.certificateId}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.lockerNumber}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function MobileCertificatesHistoryTable() {
  const classes = useStyles();
  const tableEl = useRef();
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [allPages, setAllPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [hasMore, setHasMore] = useState(page <= allPages);

  const { proceedVaultOrderClicked, proceedStakeOrderClicked } = useAppContext();

  const getCertificatesPerClient = async (page, pageSize) => {
    try {
      const response = await certificatesService.getAllCertificatesPerClient(page, pageSize);

      if (!response.data.length) {
        setError(true);
      } else {
        setCertificates((prev) => [...prev, ...response.data]);
        setAllPages(response.pages);
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  useEffect(() => {
    const loadCertificates = async () => {
      setLoading(true);
      await getCertificatesPerClient(page, 20);
      setLoading(false);
    };

    loadCertificates();
  }, [page, proceedVaultOrderClicked, proceedStakeOrderClicked]);

  useEffect(() => {
    if (certificates.length || error) {
      setLoading(false);
    }
  }, [certificates, error]);

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          if (page < allPages) {
            setPage((prev) => prev + 1);
          }
          setLoading(false);
          setHasMore(page < allPages);
          resolve();
        }, 1500),
      );
    };
    setLoading(true);
    if (page === allPages) {
      setLoading(false);
      return;
    }
    loadItems();
  }, [certificates, page]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;

    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener("scroll", scrollListener);
    return () => {
      tableRef.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  return (
    <Box className={classes.container}>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ margin: "auto", maxHeight: "100%" }}
        ref={tableEl}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead className={classes.tableHead}>
            <StyledTableRow className={classes.tableRow}>
              <StyledTableCell component="th" className={classes.headerCell}></StyledTableCell>
              <StyledTableCell component="th" className={classes.headerCell}>
                Date
              </StyledTableCell>
              <StyledTableCell component="th" className={classes.headerCell}>
                Size
              </StyledTableCell>
              <StyledTableCell component="th" className={classes.headerCell}>
                <CustomButton type="moreinfo" buttonText="i" tooltipTitle={tooltipContent.certificates} />
              </StyledTableCell>
              <StyledTableCell component="th" className={classes.headerCell} />
            </StyledTableRow>
          </TableHead>
          <TableBody className={classes.containerTableBody}>
            {!certificates.length ? (
              <React.Fragment>
                <TableRow>
                  <TableCell colSpan={5} style={{ whiteSpace: "nowrap" }} align="center">
                    No recent transactions
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ) : page === allPages && !loading ? (
              <React.Fragment>
                {certificates.map((row, index) => (
                  <Row key={index} row={row} />
                ))}
                {[1, 2].map((value, subIndex) => (
                  <TableRow key={`sub-row-${value}-${subIndex}`} className={classes.lastRows}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ))}
              </React.Fragment>
            ) : (
              certificates.map((row, index) => <Row key={row.date + index} row={row} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingBar open={loading} />
    </Box>
  );
}

export default MobileCertificatesHistoryTable;

const useStyles = makeStyles((theme) => ({
  containerTableBody: {
    position: "relative",
    overflowY: "auto",
    backgroundColor: "#FFFCED",
  },
  headerCell: {
    zIndex: 2,
    height: "36px",
  },
  container: {
    "& .MuiTableCell-root.MuiTableCell-body": {
      fontSize: "clamp(var(--font-size-11), var(--font-size-12), var(--font-size-13)) !important",
    },
    height: "100%",
    "@media (orientation: landscape)": {
      maxHeight: "100%",
    },
  },
  tableHead: {
    position: "relative",
  },
  tableRow: {
    "& .MuiTableCell-root": {
      padding: "0px",
      maxHeight: "30px",
    },
    "& th": {
      fontSize: "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important",
    },
  },
  firstStyledTableCell: {
    textAlign: "center",
    [theme.breakpoints.up("500")]: {
      textAlign: "center",
    },
  },
  closeButton: {
    color: "var(--black)",
    borderRadius: "24px",
    border: "1px solid var(--red)",
    width: "70%",
    maxWidth: "5rem",
    minHeight: "15px",
    height: "1.7rem",
    marginBottom: "1.5rem",
    backgroundColor: "var(--white)",
    fontSize: "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important",
  },
}));
