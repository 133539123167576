import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import BullionzLogo from '../../../../assets/images/bullionz-logo-coin.svg';
import { GOLD_PRICE_DENSITY, coinName } from '../../../../constants/globalconstants';
import coinNameFormatter from '../../../../helpers/currencyFormatter/coinNameFormatter';
import currencyFormatter from '../../../../helpers/currencyFormatter/currencyFormatter';
import { useAppContext } from '../../../../context/DataContext';
import clsx from 'clsx';
import { getUniformDataSet } from '../../../../helpers/getUniformDistribution';
import { generateCurrencyType } from '../../../../helpers/generateCurrencyType';
import { generateCurrencySymbol } from '../../../../helpers/generateCurrencySymbol';

function getPriceDifference(lastPrice, currentPrice) {
  let priceDifference = 0;

  if (currentPrice === 0) {
    priceDifference = 0;
    return priceDifference.toFixed(2);
  }
  const percentageDifference = ((currentPrice - lastPrice) / lastPrice) * 100;

  if (!isNaN(percentageDifference) && isFinite(percentageDifference)) {
    priceDifference = Number(percentageDifference).toFixed(2);
  } else {
    priceDifference = (0).toFixed(2);
  }

  return priceDifference;
}

function getAbsValueOfPriceDifference(lastPrice, currentPrice) {
  let priceDifference = Math.abs(currentPrice - lastPrice);
  if (currentPrice === 0) {
    priceDifference = 0;
    return priceDifference.toFixed(2);
  }

  if (!isNaN(priceDifference) && isFinite(priceDifference)) {
    priceDifference = Number(priceDifference).toFixed(2);
    priceDifference = priceDifference.toString().padStart(2, '0');
    priceDifference = generateCurrencySymbol() + priceDifference;
  } else {
    priceDifference = '';
  }

  return priceDifference;
}

function getUniformPriceForPeriod(uniformGoldPriceForPeriod) {
  return uniformGoldPriceForPeriod.map((uniformPrice) => uniformPrice.price);
}

function MobileGoldHistory() {
  const classes = useStyles();
  const { goldPriceForPeriod, IsSocketReconnecting, goldAskPrice } = useAppContext();
  const goldPrice = goldAskPrice;

  let startDate = new Date(goldPriceForPeriod[0]?.time);
  startDate.setSeconds(0, 0);

  const uniformGoldPriceForPeriod = getUniformDataSet(goldPriceForPeriod, '1D', startDate, GOLD_PRICE_DENSITY);

  const uniformPriceForPeriod = getUniformPriceForPeriod(uniformGoldPriceForPeriod);

  const basePricePL = sessionStorage.getItem('basePricePL');
  const firstPriceForThePeriod = uniformPriceForPeriod[0] || basePricePL;

  const currency = generateCurrencyType();

  return (
    <Box className={classes.container} id="mobile-gold-history">
      <Box className={classes.field}>
        <Box className={classes.bullionzGoldContainer}>
          <Box className={classes.logoContainer}>
            <img src={BullionzLogo} alt="Bullionz logo" />
          </Box>
          <Box className={classes.bullionzGoldField}>
            <Box className={classes.coinName}>{coinNameFormatter(coinName, 1, classes.goldenChars)}</Box>
          </Box>
        </Box>
        <Box className={classes.goldPrice}>
          {IsSocketReconnecting ? currencyFormatter(0, currency) : currencyFormatter(Number(goldPrice), currency)}
        </Box>
        <Box
          sx={{
            color:
              getPriceDifference(firstPriceForThePeriod, Number(goldPrice)) > 0
                ? '#347362'
                : getPriceDifference(firstPriceForThePeriod, Number(goldPrice)) < 0
                ? 'red'
                : 'black',
          }}
          className={clsx(classes.percentage, classes.goldPrice)}
        >
          {getPriceDifference(firstPriceForThePeriod, Number(goldPrice)) + '% '}

          {'(' + getAbsValueOfPriceDifference(firstPriceForThePeriod, Number(goldPrice)) + ')'}
        </Box>
      </Box>
    </Box>
  );
}

export default MobileGoldHistory;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: 'var(--white)',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '50px',
    minHeight: '50px',
    maxHeight: '50px',
    flex: '0 1 auto',
  },
  coinName: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 'clamp(var(--font-size-12), var(--font-size-14), var(--font-size-19))',
    fontWeight: '600',
  },
  field: {
    borderTop: '0.5px solid #D2D5D8',
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'space-between',
    width: 'auto',
    height: '100%',
  },
  bullionzGoldContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    width: '30%',
    marginLeft: '1%',
    [theme.breakpoints.up('400')]: {
      marginLeft: '2%',
    },
    [theme.breakpoints.up('450')]: {
      marginLeft: '4%',
    },
    [theme.breakpoints.up('500')]: {
      marginLeft: '5%',
    },
    [theme.breakpoints.up('500')]: {
      marginLeft: '6%',
    },
    [theme.breakpoints.up('600')]: {
      marginLeft: '8.5%',
    },
    [theme.breakpoints.up('700')]: {
      marginLeft: '9%',
    },
  },
  logoContainer: {
    paddingTop: '0.3rem',
    paddingLeft: '1rem',
    marginRight: '0.3rem',
  },
  bullionzGoldField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: 'var(--font-size-15)',
    fontFamily: theme.typography.fontFamily,
  },
  goldenChars: {
    color: 'var(--gold)',
  },
  goldPrice: {
    fontSize: 'clamp(var(--font-size-13), var(--font-size-15), var(--font-size-19))',
    width: '30%',
  },
  percentage: {
    paddingRight: '0.5rem',
    width: '36%',
  },
}));
