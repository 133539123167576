import clsx from "clsx";
import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";

import ArrowUpRight from "../../../assets/images/arrow-up-right.png";

const PLCrypto = ({ previousPrice, lastPrice, percentage }) => {
  const classes = useStyles();
  let plPercentage = ((lastPrice - previousPrice) / previousPrice) * 100;

  if (percentage) {
    plPercentage = Number(percentage);
  }
  
  const isNegative = plPercentage < 0;

  return (
    <div
      className={clsx(classes.plWrapper, isNegative && classes.plWrapperRed)}
    >
      <div className={clsx(classes.icon, isNegative && classes.rotatedIcon)}>
        <img
          src={ArrowUpRight}
          alt="arrow-top-left"
          className={classes.arrowIcon}
        />
      </div>

      <span className={classes.pl}>{plPercentage.toFixed(2)}%</span>
    </div>
  );
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    plWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "3px",
      borderRadius: "4px",
      backgroundColor: "#48D0A4",
      gap: 2,
      maxWidth: "50px",
    },

    plWrapperRed: {
      backgroundColor: "#E93556",
    },

    pl: {
      color: "white",
      fontWeight: 600,
      fontSize: "9px",
      lineHeight: "16px",
    },

    icon: {
      display: "flex",
    },

    arrowIcon: {
      width: "6px",
      height: "6px",
      fontSize: "10px",
    },

    rotatedIcon: {
      transform: "rotate(180deg)",
    }
  })
);

export default PLCrypto;
