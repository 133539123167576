import React, { useEffect, useState } from 'react';
import WebLayout from '../../../components/common/Layout/WebLayout';
import { makeStyles } from '@mui/styles';
import { Box, Button, Divider, TextField, createStyles } from '@mui/material';
import clsx from 'clsx';
import CustomButton from '../../../components/common/CustomButton/CustomButton';
import LoadingBar from '../../../components/common/LoadingBar/LoadingBar';
import { useAppContext } from '../../../context/DataContext';
import AddAddressPopUp from '../../../components/MobileComponents/popups/AddAddressPopUp/AddAddressPopUp';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import ProfileTransactionHistoryTable from '../../../components/WebComponents/ProfileTransactionsTable/ProfileTransactionHistoryTable';
import { countryService } from '../../../service/countries';
import { handleGetUserDetails } from '../../../helpers/handleGetUserDetails';
import { handleChangeUserPassword } from '../../../helpers/handleChangeUserPassword';
import NotificationMessagePopup from '../../../components/common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import DeleteAccountPopup from '../../../components/common/Popups/DeleteAccountPopup/DeleteAccountPopup';
import PleaseLoginPopUp from '../../../components/common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import { updateBucket } from '../../../helpers/update-client-bucket';
import { userService } from '../../../service/user';
import { errorHandlingWrapper } from '../../../helpers/errorHandlingWrapper';
import UserSessionHistoryPopUp from '../../../components/MobileComponents/popups/UserSessionHistoryPopUp/UserSessionHistoryPopUp';
import TransferFundsPopup from '../../../components/MobileComponents/popups/TransferFundsPopup/TransferFundsPopup';
import VerifyAccountBtn from '../../../assets/images/verify-account-btn.png';
import { handleGetUserCart } from '../../../helpers/handleGetUserCart';
import CryptoIcon from '../../../../src/assets/images/crypto-icon-yellow.svg';

import GoldIcon from '../../../../src/assets/images/goldIcon.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';

function Profile() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [invalidate, setInvalidate] = useState(false);
  const [userCartData, setUserCartData] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userSessionsData, setUserSessionsData] = useState([]);
  const [isUserSessionsLoading, setIsUserSessionsLoading] = useState(false);
  const [issSessionHistoryPopUpOpen, setIssSessionHistoryPopUpOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    proceedAddNewAddressClicked,
    popUpMessage,
    setPopUpMessage,
    notificationPopupType,
    setNotificationPopupType,
    proceedChangePasswordClicked,
    setProceedChangePasswordClicked,
    setGoldHistoryPeriod,
    setChosenPeriod,
    isAppView,
    setIsCartFetched,
    setProductsBucket,
    activeTradeAccountId,
    setActiveTradeAccountId,
    setActiveTradeAccountCurrency,
    setActiveTradeAccountBalance,
    setCurrency,
    isUserVerified,
    userDetails,
    setUserDetails,
    userTradeAccounts,
    userBrokerAccounts,
  } = useAppContext();

  const isDemoMode = sessionStorage.getItem('isDemo') === 'true';

  const [isAddressPopUpOpen, setIsAddressPopUpOpen] = useState(false);
  const [isTransferFundsPopUpOpen, setIsTransferFundsPopUpOpen] = useState(false);

  const [isDeleteAccountPopupOpen, setIsDeleteAccountPopupOpen] = useState(false);

  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);

  const showTradingAccountsBox = userTradeAccounts?.length + userBrokerAccounts?.length > 1;

  const getAllCountries = async () => {
    try {
      const countries = await countryService.getAllCountries();
      setCountries(countries);
    } catch (error) {
      console.error('An error occurred while fetching countries:', error);
    }
  };

  const changeDeliveryAddress = () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    getAllCountries();
    setIsAddressPopUpOpen(true);
  };

  const handleTransferFunds = () => {
    setIsTransferFundsPopUpOpen(true);
  };

  useEffect(() => {
    handleGetUserDetails(setUserDetails, setLoading);
  }, [proceedAddNewAddressClicked]);

  useEffect(() => {
    handleGetUserCart(setUserCartData, () => {});
  }, []);

  useEffect(() => {
    updateBucket(isAppView, userCartData, setIsCartFetched, setProductsBucket, userService?.updateShoppingCart);
  }, [userCartData]);

  useEffect(() => {
    if (invalidate) {
      handleGetUserSessionsClicked();
      setInvalidate(false);
    }
  }, [invalidate]);

  const getWidthPercentage = (windowWidth) => {
    switch (true) {
      case windowWidth < 960:
        return '20%';
      case windowWidth < 1270:
        return '15%';
      case windowWidth < 1310:
        return '23%';
      case windowWidth < 1700:
        return '18%';
      default:
        return '15%';
    }
  };

  const {
    firstName,
    lastName,
    phoneNumber,
    shippingAddress,
    shippingCity,
    shippingCountry,
    shippingPostalCode,
    email,
    country,
    city,
    address,
    state,
    postalCode,
    shippingState,
  } = userDetails;

  const userAddressArr = [shippingAddress, shippingCity, shippingState, shippingCountry?.name, shippingPostalCode];

  if (!shippingAddress) {
    userAddressArr[0] = address;
    userAddressArr[1] = city;
    userAddressArr[2] = state;
    userAddressArr[3] = country?.name;
    userAddressArr[4] = postalCode;
  }

  for (let i = 0; i < userAddressArr.length; i++) {
    if (userAddressArr[i] === null || userAddressArr[i] === '' || !userAddressArr[i]) {
      userAddressArr.splice(i, 1);
      i--;
    }
  }

  const back = () => {
    setGoldHistoryPeriod('1H');
    setChosenPeriod('1H');
    navigate(routes.dashboard);
    window.scrollTo(0, 0);
  };

  async function handleSubmit() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    const changedPassword = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    if (newPassword !== confirmPassword) {
      setPopUpMessage('New Password and Confirm password do not match');
      setNotificationPopupType('error');
      setProceedChangePasswordClicked(true);
      return;
    }

    if (oldPassword === newPassword) {
      setPopUpMessage('New password must be different than the Old password');
      setNotificationPopupType('error');
      setProceedChangePasswordClicked(true);
      return;
    }

    try {
      const response = await handleChangeUserPassword(changedPassword, setLoading);
      if (response.IsSuccessed) {
        setOldPassword('');
        setConfirmPassword('');
        setNewPassword('');
        setPopUpMessage('You successfully changed the password');
        setNotificationPopupType('success');
        setProceedChangePasswordClicked(true);
      } else {
        setOldPassword('');
        setConfirmPassword('');
        setNewPassword('');
        setPopUpMessage('Error occured');
        setNotificationPopupType('error');
        setProceedChangePasswordClicked(true);
      }
    } catch (e) {
      setOldPassword('');
      setConfirmPassword('');
      setNewPassword('');
      let customError = null;
      customError = errorHandlingWrapper(e, customError);
      setPopUpMessage(customError?.message);
      setNotificationPopupType('error');
      setProceedChangePasswordClicked(true);
    }
    return 0;
  }

  const handleDeleteAccountClicked = () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    setIsDeleteAccountPopupOpen(true);
  };

  const handleGetUserSessionsClicked = async () => {
    try {
      setIsUserSessionsLoading(true);
      setIssSessionHistoryPopUpOpen(true);

      const response = await userService.getUserSessions();

      setUserSessionsData(response);

      setTimeout(() => {
        setIsUserSessionsLoading(false);
      }, 650);
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleTradeAccountClicked = (account) => {
    setCurrency(account.currency);
    setActiveTradeAccountBalance(account.balance);
    setActiveTradeAccountId(account.tradeAccountId);
    setActiveTradeAccountCurrency(account.currency);

    localStorage.setItem('taid', account.tradeAccountId);
    localStorage.setItem('currency', account.currency);
  };

  function handleVerifyAccount() {
    navigate(routes.sumsub);
  }

  return (
    <WebLayout>
      {isUserVerified === 'false' && (
        <div className={classes.verifyAccountInfoContainer}>
          <span>Before you continue we need to verify your account</span>
          <div className={classes.verifyAccountBtnContainer}>
            <img
              src={VerifyAccountBtn}
              alt="Account Icon"
              style={{ cursor: 'pointer' }}
              onClick={handleVerifyAccount}
            />
          </div>
        </div>
      )}
      <Box style={{ height: isUserVerified === 'false' ? '85%' : '96.5%' }} className={classes.container}>
        <Box className={classes.leftContainer}>
          <Box className={clsx(classes.marginProvider, classes.personalDetailsWrapper)}>
            <Box className={classes.title}>Personal Details</Box>
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>First Name</Box>
              <Box className={classes.value}>{firstName || ''}</Box>
            </Box>
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>Last Name</Box>
              <Box className={classes.value}>{lastName || ''}</Box>
            </Box>
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>Address</Box>
              <Box className={classes.value}>{address || ''}</Box>
            </Box>
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>City</Box>
              <Box className={classes.value}>{city || ''}</Box>
            </Box>
            {state !== null && (
              <Box className={classes.valueAndLabelHolder}>
                <Box className={classes.label}>State</Box>
                <Box className={classes.value}>{state || ''}</Box>
              </Box>
            )}
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>Country</Box>
              <Box className={classes.value}>{country?.name || ''}</Box>
            </Box>
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>Zip Code</Box>
              <Box className={classes.value}>{postalCode || ''}</Box>
            </Box>
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>Email</Box>
              <a className={classes.value} href={`mailto:${email}`}>
                <Box>{email || ''}</Box>
              </a>
            </Box>
            <Box className={classes.valueAndLabelHolder}>
              <Box className={classes.label}>Phone number</Box>
              <Box className={classes.value}>{phoneNumber || ''}</Box>
            </Box>
          </Box>
          <Box className={classes.btnsWrapper}>
            <CustomButton
              type="black"
              buttonText="Session History"
              width={140}
              height="30px"
              fontSize="10px"
              onClick={handleGetUserSessionsClicked}
            />

            <Button className={classes.deleteButton} variant="outlined" onClick={handleDeleteAccountClicked}>
              Delete my account
            </Button>
          </Box>
          <Box className={clsx(classes.marginProvider, classes.changePasswordWrapper)}>
            <Box className={classes.title}>Change Password</Box>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextField
                className={classes.passwordField}
                label="Old Password"
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                fullWidth
                autoComplete="off"
                required
              />
              <TextField
                className={classes.passwordField}
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                autoComplete="off"
                required
              />
              <PasswordStrengthBar password={newPassword} className={classes.passwordStrengthBar} />
              <TextField
                className={classes.passwordField}
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                autoComplete="off"
                required
              />
              <Box className={classes.buttonWrapper}>
                <CustomButton
                  type="black"
                  buttonText="Change"
                  width="25%"
                  height="100%"
                  actionType="submit"
                ></CustomButton>
              </Box>
            </form>
          </Box>
        </Box>
        <Box className={classes.rightContainer}>
          <Box className={clsx(classes.marginProvider, classes.deliveryAddressWrapper)}>
            <Box className={classes.title}>Delivery Address</Box>
            <Box className={clsx(classes.valueAndLabelHolder, classes.addressWrapper)}>
              <Box className={classes.address}>{userAddressArr?.length > 0 && userAddressArr?.join(', ')}</Box>
            </Box>
            <Box className={clsx(classes.buttonWrapper, classes.addNew)}>
              <CustomButton
                type="black"
                buttonText="Change"
                width={getWidthPercentage(windowWidth)}
                height="100%"
                fontSize="16px"
                onClick={changeDeliveryAddress}
              ></CustomButton>
            </Box>
          </Box>
          {(showTradingAccountsBox || isDemoMode) && (
            <Box className={clsx(classes.marginProvider, classes.deliveryAddressWrapper)}>
              <Box className={classes.title}>Wallets</Box>
              {userTradeAccounts?.length > 0 ? (
                userTradeAccounts?.map((account, index) => (
                  <Box
                    key={index}
                    className={
                      activeTradeAccountId === account.tradeAccountId
                        ? clsx(classes.cryptoValueAndLabelHolder, classes.addressWrapperClicked)
                        : clsx(classes.cryptoValueAndLabelHolder, classes.goldWalletWrapper)
                    }
                    onClick={() => handleTradeAccountClicked(account)}
                  >
                    <Box className={classes.tradingAccounts}>
                      <div className={classes.tradeAccountContainer} htmlFor={`tradeAccount${index}`}>
                        <Box className={classes.iconInfoHolder}>
                          <Box className={classes.accountIcon}>
                            <img src={GoldIcon} alt="gold icon" className={classes.goldIcon} />
                          </Box>
                          <div className={classes.goldWallet}>
                            <div className={classes.walletTitle}>
                              <span>Gold Wallet</span>
                            </div>
                            <div className={classes.accountValuesHolder}>
                              {/* <span className={classes.accountId}>ID </span> */}
                              <span className={classes.accountIdValue}>
                                {account?.tradeAccountId}
                                {' ' + ' '}
                              </span>
                              {/* <span className={classes.accountIdValue}>
                              <ContentCopyIcon fontSize="12px" />
                            </span> */}
                            </div>
                          </div>
                        </Box>
                        <div className={classes.currencyAmountWrapper}>
                          <span className={classes.balanceLabel}>Balance</span>
                          <span className={classes.balanceValue}>
                            {currencyFormatter(account.balance, '', account.currency, true)}
                          </span>
                        </div>
                        <div className={classes.radioButtonWrapper}>
                          <input
                            type="radio"
                            style={{
                              accentColor: '#347362',
                              left: '15px',
                              cursor: 'pointer',
                            }}
                            id={`tradeAccount${index}`}
                            name="tradeAccount2"
                            value={account.tradeAccountId}
                            checked={activeTradeAccountId === account.tradeAccountId}
                            onClick={() => handleTradeAccountClicked(account)}
                          />
                        </div>
                      </div>
                    </Box>
                  </Box>
                ))
              ) : (
                <></>
              )}

              {userBrokerAccounts?.length > 0 ? (
                userBrokerAccounts?.map((account, index) => {
                  const price = account?.assets?.find((a) => a?.assetId === 'usdt')?.amount || 0;
                  return (
                    <Box key={index} className={clsx(classes.nonCryptoValueAndLabelHolder, classes.addressWrapper)}>
                      <Box className={classes.cryptoTradingAccounts}>
                        <div className={classes.tradeAccountContainer} htmlFor={`tradeAccount${index}`}>
                          <Box className={classes.iconInfoHolder}>
                            <Box className={classes.accountIcon}>
                              <img src={CryptoIcon} alt="Crypto Icon" className={classes.goldIcon} />
                            </Box>
                            <div className={classes.goldWallet}>
                              <div className={classes.walletTitle}>
                                {index > 0 ? <span>Crypto Wallet {index + 1}</span> : <span>Crypto Wallet</span>}
                              </div>
                              <div className={classes.accountValuesHolder}>
                                <span className={classes.accountIdValue}>
                                  {account?.brokerAccountId}
                                  {' ' + ' '}
                                </span>
                              </div>
                            </div>
                          </Box>
                          <div className={classes.currencyAmountWrapper}>
                            <span className={classes.balanceLabel}>Balance </span>
                            <span className={classes.balanceValue}>
                              {currencyFormatter(Number(price), 'custom', '', true, true)}
                              {'USDT'}
                            </span>
                          </div>
                          <div className={classes.radioButtonWrapper}></div>
                        </div>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <></>
              )}

              <Box className={clsx(classes.buttonWrapper, classes.addNew)}>
                <CustomButton
                  type="black"
                  buttonText="Transfer Funds"
                  width="33%"
                  height="100%"
                  fontSize="16px"
                  onClick={handleTransferFunds}
                ></CustomButton>
              </Box>
            </Box>
          )}
          <Box className={clsx(classes.marginProvider, classes.transactionsTableWrapper)}>
            <Box className={clsx(classes.title, classes.transactionHistory)}>Transactions History</Box>
            <Box className={classes.transactionsTableContainer}>
              <ProfileTransactionHistoryTable />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.btn}>
        <CustomButton
          type="black"
          width="100%"
          height="24px"
          onClick={back}
          buttonText="&#9664;&nbsp;&nbsp;Back to Dashboard"
        ></CustomButton>
      </Box>
      {isAddressPopUpOpen && (
        <AddAddressPopUp
          isPopupOpen={isAddressPopUpOpen}
          setIsPopupOpen={setIsAddressPopUpOpen}
          countries={countries}
          userDetails={userDetails}
        />
      )}
      {isTransferFundsPopUpOpen && (userTradeAccounts?.length || userBrokerAccounts?.length) && (
        <TransferFundsPopup
          isPopupOpen={isTransferFundsPopUpOpen}
          setIsPopupOpen={setIsTransferFundsPopUpOpen}
          userTradeAccounts={userTradeAccounts}
          userBrokerAccounts={userBrokerAccounts}
        />
      )}

      {issSessionHistoryPopUpOpen && (
        <UserSessionHistoryPopUp
          setInvalidate={setInvalidate}
          userSessions={userSessionsData}
          isLoading={isUserSessionsLoading}
          isOpen={issSessionHistoryPopUpOpen}
          setIsOpen={setIssSessionHistoryPopUpOpen}
        />
      )}

      {isDeleteAccountPopupOpen && (
        <DeleteAccountPopup isPopupOpen={isDeleteAccountPopupOpen} setIsPopupOpen={setIsDeleteAccountPopupOpen} />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={loading} />
      {proceedChangePasswordClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedChangePasswordClicked}
          setIsPopupOpen={setProceedChangePasswordClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
    </WebLayout>
  );
}

export default Profile;

const useStyles = makeStyles((theme) =>
  createStyles({
    verifyAccountInfoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '86%',
      border: '1px solid #FFC1C1',
      borderRadius: '20px',
      backgroundColor: '#FFEFEF',
      color: '#D2492B',
      height: '80px',
      fontFamily: theme.typography.fontFamily,
      fontSize: '16px',
      fontWeight: '400',
      gap: '25px',
      marginTop: '20px',
      marginBottom: '20px',
      marginLeft: '59px',
    },
    verifyAccountBtnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: theme.typography.fontFamily,
      margin: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      lineHeight: '1.2rem',
      fontSize: 'clamp(var(--font-size-16), var(--font-size-13), var(--font-size-11)) !important',
      fontWeight: 'normal',
      [theme.breakpoints.down('930')]: {
        fontSize: 'var(--font-size-11) !important',
      },
      [theme.breakpoints.down('850')]: {
        fontSize: 'var(--font-size-10) !important',
      },
    },
    rightContainer: {
      height: '100%',
      padding: '1rem',
      width: '50%',
      [theme.breakpoints.down('1080')]: {
        width: '60%',
      },
      [theme.breakpoints.down('880')]: {
        width: '70%',
      },
    },
    leftContainer: {
      height: '100%',
      padding: '1rem',
    },
    valueAndLabelHolder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '1rem',
    },
    cryptoValueAndLabelHolder: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      paddingTop: '1rem',
      cursor: 'pointer',
    },
    nonCryptoValueAndLabelHolder: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      paddingTop: '1rem',
    },
    value: {
      display: 'flex',
      alignItems: 'center',
      width: '40%',
      textAlign: 'left',
      [theme.breakpoints.down('950')]: {
        width: 'auto',
        textAlign: 'right',
      },
      wordBreak: 'break-word',
      backgroundColor: 'var(--white)',
    },
    label: {
      color: 'var(--grey-secondary)',
    },
    title: {
      fontSize: 'clamp(var(--font-size-28), var(--font-size-24), var(--font-size-22)) !important',
      fontWeight: 600,
      paddingLeft: '2rem',
      paddingRight: '2rem',
      marginBottom: '2rem',
      [theme.breakpoints.down('1120')]: {
        fontSize: 'var(--font-size-20) !important',
      },
      [theme.breakpoints.down('950')]: {
        fontSize: 'var(--font-size-19) !important',
        paddingRight: '1rem',
        paddingLeft: '1rem',
      },
      [theme.breakpoints.down('920')]: {
        fontSize: 'var(--font-size-19) !important',
        paddingRight: '0rem',
        paddingLeft: '0rem',
      },
      [theme.breakpoints.down('865')]: {
        fontSize: 'var(--font-size-13) !important',
      },
      [theme.breakpoints.down('810')]: {
        fontSize: 'var(--font-size-14) !important',
      },
    },
    marginProvider: {
      marginTop: '2rem',
      marginBottom: '2rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.breakpoints.down('830')]: {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    deleteButton: {
      color: 'black',
      border: '1px solid black',
      borderRadius: '38px',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: theme.typography.fontFamily,
      boxShadow: '0px 3.1852872371673584px 0px 0px #76767633',
      '&:hover': {
        border: '1px solid black',
      },
      [theme.breakpoints.down('1120')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down('950')]: {
        fontSize: '10px',
      },
    },
    changePasswordWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '2rem',
      paddingBottom: '1rem',
      border: '1px solid #B0B0B0',
      borderRadius: '24px',
      backgroundColor: '#FBFBFB',
      boxShadow: '0px 3.1852872371673584px 0px 0px #76767633',
    },
    passwordField: {
      '& .MuiInputBase-input': {
        height: '1rem',
        [theme.breakpoints.down('1000')]: {
          height: '0.7rem',
        },
      },
      '& .MuiInputBase-root': {
        padding: 0,
        margin: 0,
      },
      marginTop: '1rem',
      backgroundColor: 'var(--white)',
      boxShadow: 'inset 0rem 3.1852872371673584px #76767633',
    },
    buttonWrapper: {
      marginTop: '1rem',
      maxHeight: '2rem',
      height: '2rem',

      '& :first-child': {
        '& span': {
          fontSize: '10px !important',
          fontWeight: '600 !important',
        },
      },
    },
    tradingAccounts: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      width: '100%',
      height: '100%',
      border: '1px solid #D2D5D8',
      borderRadius: '4px',
    },

    addressWrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '64px',
    },

    goldWalletWrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '64px',

      '& > :first-child': {
        backgroundColor: '#F7F7F7',
      },
    },

    addressWrapperClicked: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '64px',

      '& > .tradingAccounts': {
        backgroundColor: 'white',
      },
    },

    address: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%',
      height: '100%',
      border: '1px solid #D2D5D8',
      borderRadius: '4px',
      backgroundColor: 'var(--white)',
      boxShadow: 'inset 0rem 3.1852872371673584px #76767633',
    },
    addNew: {
      height: '4rem !important',

      '& :first-child': {
        '& span': {
          fontSize: '10px !important',
          fontWeight: '600 !important',
        },
      },
    },
    cryptoTradingAccounts: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      width: '100%',
      height: '100%',
      borderTop: '1px solid #D2D5D8',
    },
    tradeAccountContainer: {
      display: 'grid',
      gridTemplateColumns: 'minmax(220px, 4fr) 3fr 2fr',
      // display: "flex",
      alignItems: 'center',
      padding: '0.5rem',
      width: '100%',
    },
    divider: {
      color: '#767676',
      border: '1px solid',
    },
    accountId: {
      fontWeight: 600,
      color: '#767676',
      fontSize: '10px',
      textAlign: 'left',
      paddingRight: '4px',
    },
    balanceLabel: {
      fontWeight: 700,
      color: '#767676',
      fontSize: '16px',
    },
    balanceValue: {
      fontSize: '12px',
      color: '#767676',
    },
    transactionsTableContainer: {
      width: '100%',
      display: 'flex',
    },
    btn: {
      textAlign: 'left',
      width: '15rem',
      paddingLeft: '3rem',
      marginTop: '-1.5rem',
      [theme.breakpoints.down('1750')]: {
        width: '12.5rem',
      },
      [theme.breakpoints.down('1280')]: {
        width: '10rem',
      },
    },
    sessionHistoryBtn: {
      minWidth: '80px',
    },
    btnsWrapper: {
      display: 'flex',
      gap: 12,
      justifyContent: 'center',

      '& :first-child': {
        '& span': {
          fontSize: '14px !important',
          [theme.breakpoints.down('1120')]: {
            fontSize: '12px !important',
          },
          fontWeight: '600 !important',
        },
      },
    },

    passwordStrengthBar: {
      width: '100%',
      '& p': {
        display: 'none',
      },
    },
    transactionHistory: {
      marginTop: '3rem',
    },
    deliveryAddressWrapper: {
      border: '1px solid #B0B0B0',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderRadius: '24px',
      backgroundColor: '#FBFBFB',
      boxShadow: '0px 3.1852872371673584px 0px 0px #76767633',
    },
    personalDetailsWrapper: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderRadius: '24px',
    },
    walletTitle: {
      fontWeight: 600,
      color: '#767676',
      fontSize: '16px',
      textAlign: 'left',
    },
    accountIdValue: {
      fontSize: '10px',
      color: '#767676',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    accountValuesHolder: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      textAlign: 'left',
    },
    goldIcon: {
      maxWidth: '33px',
      maxHeight: '33px',
    },
    iconInfoHolder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: '10px',
      flex: '40%',
      gap: '16px',
      [theme.breakpoints.down('1285')]: {
        flex: 1,
      },
    },
    currencyAmountWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: '33%',
      [theme.breakpoints.down('1285')]: {
        flex: 1,
      },
    },
    radioButtonWrapper: {
      display: 'flex',
      textAlign: 'right',
      flex: '26%',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('1285')]: {
        flex: 1,
      },
    },
    accountIcon: {},
    goldWallet: {
      marginLeft: '4px',
    },
    depositFundsBtnReal: {
      width: '7rem',
      height: '32px',
      cursor: 'pointer',
      backgroundColor: 'var(--black)',
      color: 'var(--white)',
      fontWeight: 600,
      fontSize: '14px',
      borderRadius: '60px',
      '&:hover': {
        background: 'var(--black) !important',
      },
      '& svg': {
        backgroundColor: 'var(--black)',
        color: 'var(--white)',
      },
      [theme.breakpoints.down('1600')]: {
        width: '6.5rem',
        height: '28px',
        fontSize: '11px',
      },
      [theme.breakpoints.down('1160')]: {
        width: '5rem',
        height: '28px',
        fontSize: '7px',
      },
    },
  }),
);
