import { demoResponseHandler } from '../helpers/demoResponseHandler';

export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  patch,
};

function clearLocalStorageExceptBucket() {
  if (localStorage?.length > 0) {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== 'bucket' && key !== 'currency' && key !== 'taid') {
        localStorage.removeItem(key);
      }
    }
  } else {
    return;
  }
}

const handleUnauthorized = (error) => {
  if (error.message && error.message.includes('Unauthorized')) {
    const appView = sessionStorage.getItem('isNative');
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "Logout"}');
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessTokenExp');
      if (!window.location.href.includes('/login')) {
        window.location.href = '/login';
      } else {
        throw error;
      }
    }
  }
};

const handleTaidNotFound = (error) => {
  if (error.message && error.message.includes('Trade Account not found')) {
    const appView = sessionStorage.getItem('isNative');
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "Logout"}');
    } else {
      localStorage.clear();
      sessionStorage.clear();
      if (!window.location.href.includes('/login')) {
        window.location.href = '/login';
      } else {
        throw error;
      }
    }
  }
};

const baseUrl = process.env.REACT_APP_BULLIONZ_API;

async function request(method, url, body, includeAuthHeader = true) {
  if (!method) method = 'GET';

  let token = localStorage.getItem('accessToken');
  const accessTokenExp = localStorage.getItem('accessTokenExp');
  const isCurrentlyRefreshing = sessionStorage.getItem('isCurrentlyRefreshing');

  if (token != null && accessTokenExp != null && url !== '/user/refresh-token' && url !== '/user/logout') {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const tokenExpTimestamp = Math.floor(new Date(accessTokenExp).getTime() / 1000);

    if (tokenExpTimestamp < currentTimestamp) {
      await refreshToken(); // Refresh token if expired
      token = localStorage.getItem('accessToken'); // Update the current token with refreshed token
    }
  }
  const taid = localStorage.getItem('taid');
  const hasTaid = taid && taid !== 'undefined';

  if (token && !isCurrentlyRefreshing && url !== '/user' && url !== '/user/register' && !hasTaid) {
    await new Promise((resolve, reject) => {
      requestQueue.push({ resolve, reject });
    });
  }

  const requestOptions = {
    method,
    headers: includeAuthHeader ? authHeader(token) : {},
  };

  if (body) {
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.body = JSON.stringify(body);
  }
  try {
    const response = await fetch(baseUrl + url, requestOptions);
    let res;

    try {
      res = await response.json();
    } catch (error) {
      return;
    }

    if (!response.ok && res) {
      throw new Error(JSON.stringify(res));
    }

    if (response.ok && url === '/user/logout') {
      return null;
    } else if (response.ok && url === '/user') {
      if (!hasTaid) {
        localStorage.setItem('taid', res?.UserTradeAccount?.[0]?.tradeAccountId);
        localStorage.setItem('currency', res?.UserTradeAccount?.[0]?.currency);
      }

      processQueue(null, response);
    }
    return res;
  } catch (error) {
    handleUnauthorized(error);
    handleTaidNotFound(error);

    throw new Error(error.message);
  }
}

async function get(url) {
  const isDemo = sessionStorage.getItem('isDemo') === 'true';

  const taid = localStorage.getItem('taid');
  const hasTaid = taid && taid !== 'undefined';

  if (
    url === '/broker/demo-access-token' ||
    url === '/store/categories' ||
    url === '/store/top-products' ||
    url === '/store/top-products?currency=USD' ||
    url === '/store/top-products?currency=EUR' ||
    url.includes('/store/product/') ||
    url.includes('/broker/get-demo-market-history') ||
    url.includes('/broker/demo-get-markets')
  ) {
    return await request('GET', url);
  } else if (isDemo) {
    clearLocalStorageExceptBucket();
    if (!hasTaid) {
      const res = demoResponseHandler('/user');
      localStorage.setItem('taid', res?.UserTradeAccount?.[0]?.tradeAccountId);
      localStorage.setItem('currency', res?.UserTradeAccount?.[0]?.currency);
    }
    return demoResponseHandler(url);
  } else {
    return await request('GET', url);
  }
}

async function post(url, body) {
  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  if (url === '/notification/demo-widgets' || url === '/store/products') {
    return await request('POST', url, body);
  } else if (isDemo) {
    clearLocalStorageExceptBucket();
    return demoResponseHandler(url);
  } else if (url === '/user/login') {
    return await request('POST', url, body, false);
  } else {
    return await request('POST', url, body);
  }
}

async function put(url, body) {
  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  if (isDemo) {
    clearLocalStorageExceptBucket();
    return demoResponseHandler(url);
  }
  return await request('PUT', url, body);
}

async function patch(url, body) {
  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  if (isDemo) {
    clearLocalStorageExceptBucket();
    return demoResponseHandler(url);
  }
  return await request('PATCH', url, body);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url) {
  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  if (isDemo) {
    clearLocalStorageExceptBucket();
    return demoResponseHandler(url);
  }
  return await request('DELETE', url);
}

function authHeader(token) {
  if (token) {
    const taid = localStorage.getItem('taid');
    if (taid) {
      return {
        Authorization: `Bearer ${token}`,
        taid: localStorage.getItem('taid'),
      };
    } else {
      return {
        Authorization: `Bearer ${token}`,
      };
    }
  } else {
    return {};
  }
}

const requestQueue = [];

export const refreshToken = async () => {
  const isCurrentlyRefreshing = sessionStorage.getItem('isCurrentlyRefreshing');
  const appView = sessionStorage.getItem('isNative');

  if (isCurrentlyRefreshing === null || isCurrentlyRefreshing === 'false') {
    sessionStorage.setItem('isCurrentlyRefreshing', 'true');
    try {
      const response = await fetchWrapper.post(`/user/refresh-token`, {}); // only once
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('accessTokenExp', response.accessTokenExp);
      if (appView) {
        window.webkit?.messageHandlers.notifyNative.postMessage(
          `{"Event": "RefreshToken", "AccessToken": "${response.accessToken}", "AccessTokenExp": "${response.accessTokenExp}"}`,
        );
      }
      processQueue(null, response);
    } catch (error) {
      // remove access token and exp
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessTokenExp');
      // redirect to login
      if (!appView) {
        window.location.href = '/login';
      } else {
        window.webkit?.messageHandlers.notifyNative.postMessage(
          '{"Event": "RefreshToken", "AccessToken": "", "AccessTokenExp": ""}',
        );
      }
      console.error('Token refresh failed:', error);
    } finally {
      sessionStorage.removeItem('isCurrentlyRefreshing');
    }
  } else {
    await new Promise((resolve, reject) => {
      requestQueue.push({ resolve, reject });
    });
  }
};

const waitForTaid = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const taid = localStorage.getItem('taid');
      if (taid && taid !== 'undefined') {
        clearInterval(interval);
        resolve(taid);
      }
    }, 100); // check every 100ms
  });
};

const processQueue = async (error, response) => {
  await waitForTaid();
  while (requestQueue.length > 0) {
    const { resolve, reject } = requestQueue.shift();
    if (error) {
      reject(error);
    } else {
      resolve(response);
    }
  }
};
