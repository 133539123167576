import * as React from "react";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import UserNotificationPopup from "../Popups/UserNotificationPopup/UserNotificationPopup";
import { userNotifications } from "../../../service/notifications";
import LoadingBar from "../LoadingBar/LoadingBar";
import { formatDistanceToNowStrict } from "date-fns";
import NewsAnnouncementIcon from "../../../assets/images/news-announcement-icon.svg";
import SystemMessageIcon from "../../../assets/images/system-message-notification-icon.svg";
import { useEffect } from "react";
import { useRef } from "react";
import { useAppContext } from "../../../context/DataContext";

const NotificationsMenu = ({
  anchorEl,
  open,
  handleClose,
  notifications,
  setNotifications,
  setError,
  loading,
  setLoading,
  loadingMore,
  setLoadingMore,
  reachedEnd,
  fetchUserNotifications,
}) => {
  const classes = useStyles();
  const { unreadNotificationsCounter, setUnreadNotificationsCounter } =
    useAppContext();
  const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    notificationId: null,
    icon: "",
    title: "",
  });

  const notificationsMenuRef = useRef();

  const handleNotificationClicked = async (
    notificationId,
    type,
    title,
    message
  ) => {
    setLoading(true);
    try {
      await userNotifications.updateUserNotifications({
        notificationId,
      });
      const updatedNotifications = notifications.map((notification) => {
        if (notification.id === notificationId) {
          return {
            ...notification,
            isRead: true,
          };
        }
        return notification;
      });
      if (unreadNotificationsCounter > 0) {
        setUnreadNotificationsCounter((prevCounter) => prevCounter - 1);
      }
      setNotifications(updatedNotifications);
      setError("");
    } catch (error) {
      console.error("Error updating notification", error);
      setError("Error updating user notifications");
    } finally {
      setLoading(false);
    }
    setNotificationPopupOpen(true);
    setNotificationContent({
      notificationId: notificationId,
      icon: type === "NEWS" ? NewsAnnouncementIcon : SystemMessageIcon,
      title: title,
      message: message,
    });
  };

  const getTimeAgo = (date) => {
    return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
  };

  const loadMoreNotifications = async () => {
    try {
      await fetchUserNotifications();
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  function handleScroll() {
    const element = notificationsMenuRef.current;
    if (element) {
      const { scrollTop, clientHeight, scrollHeight } = element;
      if (
        scrollTop + 1 + clientHeight >= scrollHeight &&
        !loadingMore &&
        !reachedEnd
      ) {
        setLoadingMore(true);
        loadMoreNotifications().then(() => {
          setLoadingMore(false);
        });
      }
    }
  }

  useEffect(() => {
    const menuElement = notificationsMenuRef.current;
    if (menuElement) {
      menuElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (menuElement) {
        menuElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreNotifications, loadingMore]);

  return (
    <React.Fragment>
      <Menu
        className={classes.menuContainer}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2.9,
            ml: 7,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 57,
              width: 12,
              height: 13,
              bgcolor: "white",
              border: "1px solid #E0E0E0",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div
          ref={notificationsMenuRef}
          id="notificationList"
          className={classes.notificationList}
        >
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <li
                key={notification.id}
                className={classes.notificationItem}
                style={{
                  border: index % 2 === 1 ? "1px solid #F4F4F4" : "none",
                  backgroundColor: notification.isRead
                    ? "transparent"
                    : "#88888870",
                }}
                onClick={() =>
                  handleNotificationClicked(
                    notification.id,
                    notification.group.type,
                    notification.group.title,
                    notification.group.description
                  )
                }
              >
                <img
                  src={
                    notification.group.type === "NEWS"
                      ? NewsAnnouncementIcon
                      : SystemMessageIcon
                  }
                  alt={notification.title}
                  className={classes.icon}
                />
                <div className={classes.notificationContent}>
                  <div className={classes.title}>
                    {notification.group.title}
                  </div>
                  <div className={classes.message}>
                    {notification.group.shortDescription}
                  </div>
                  <div className={classes.time}>
                    {" "}
                    {getTimeAgo(notification.group.date)}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className={classes.noData}>No notifications</div>
          )}
          {loading && <LoadingBar open={loading} />}
        </div>
      </Menu>
      {notificationPopupOpen && (
        <UserNotificationPopup
          icon={notificationContent.icon}
          title={notificationContent.title}
          message={notificationContent.message}
          isPopupOpen={notificationPopupOpen}
          setIsPopupOpen={setNotificationPopupOpen}
        />
      )}
    </React.Fragment>
  );
};

export default NotificationsMenu;

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    boxShadow: "4px 4px 4px 0px #00000026",
    "& .MuiListItemText-root": {
      color: "#DDDDDD",
    },
    "& .MuiMenu-list": {
      maxHeight: "387px",
      maxWidth: "350px",
      width: "350px",
      minHeight: "100px",
      minWidth: "180px",
      overflowY: "hidden",
      backgroundColor: "white",
      borderRadius: "5px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .MuiMenuItem-root": {
      paddingBottom: "10px",
    },
  },
  notificationList: {
    height: "42.1vh",
    overflowY: "auto",
  },
  notificationItem: {
    display: "flex",
    alignItems: "center",
    padding: "5px 18px 5px 10px",
    textAlign: "start",
    cursor: "pointer",
  },
  icon: {
    marginRight: theme.spacing(2),
    width: 24,
    height: 24,
  },
  notificationContent: {
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    alignItems: "center",
  },
  title: {
    fontSize: "11px",
    fontWeight: "600",
    color: "#303030",
    marginBottom: "2px",
  },
  message: {
    width: "80%",
    fontSize: "8px",
    fontWeight: "400",
    color: "#949494",
  },
  time: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "8px",
    fontStyle: "italic",
    color: "#4A4A4A !important",
  },
  noData: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "11px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
