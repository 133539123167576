import clsx from 'clsx';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';

import QuantitySelector from './components/QuantitySelector';
import CustomButton from '../../common/CustomButton/CustomButton';

import { routes } from '../../../routes';
import { useAppContext } from '../../../context/DataContext';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ImgsViewer from 'react-images-viewer';
import { getLastWord } from '../../../helpers/getLastWord';
import { Button } from '@mui/material';

import { ReactComponent as ProductNotFoundIcon } from '../../../assets/images/product-not-found-icon.svg';
import { descriptions } from '../../../constants/globalconstants';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import NonVerifiedUserPopUp from '../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import { messages } from '../../../constants/popupMessages';
import isVerified from '../../../helpers/isVerified';
import LoadingBar from '../../common/LoadingBar/LoadingBar';

const getQuantityFromBucket = (id) => {
  // Get bucket object from session storage
  const bucketStr = localStorage.getItem('bucket');

  // Parse bucket object from JSON string
  const bucket = JSON.parse(bucketStr);

  // Check if bucket exists and has the requested id
  if (bucket && bucket[id]) {
    // Return the quantity for the requested id
    return bucket[id].quantity;
  } else {
    // Return 0 if the id doesn't exist in the bucket
    return 0;
  }
};

export const StoreItem = ({ item, isLoading, handleAddToCard, isCategoriesLoading, setIsAddToCardTriggered }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView, isMobile, nonVerifiedPopUpOpen, setNonVerifiedPopUpOpen } = useAppContext();

  const [error, setError] = useState('');
  const [currImg, setCurrImg] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const isWeb = !isAppView && !isMobile;

  let currencyInLocalStorage = localStorage.getItem('currency');

  const price = item?.prices?.[currencyInLocalStorage] || 0;
  const metal = item?.metal || '';
  const purity = item?.purity || '';
  const itemName = item?.name || '';
  const images = item?.images || [];

  const addToCard = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);

    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    let productCartQuantity = getQuantityFromBucket(item?.id);

    if (productCartQuantity + quantity > item?.quantity) {
      setError('Please do not surpass the available quantity.');

      setTimeout(() => {
        setError('');
      }, 3000);
    } else {
      handleAddToCard(item, quantity);

      setIsAddToCardTriggered(true);
    }
  };

  const buyNowHandler = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);

    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    let productCartQuantity = getQuantityFromBucket(item?.id);

    if (productCartQuantity + quantity > item?.quantity) {
      setError('Please do not surpass the available quantity.');

      setTimeout(() => {
        setError('');
      }, 3000);
    } else {
      await handleAddToCard(item, quantity);

      window?.webkit?.messageHandlers?.notifyNative?.postMessage('{"Event": "Cart"}');

      navigate(routes.cart);
    }
  };

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  const openViewer = () => {
    setViewerIsOpen(true);
  };

  const closeViewer = () => {
    setViewerIsOpen(false);
  };

  const gotoPrevious = () => {
    setCurrImg(currImg - 1);
  };

  const gotoNext = () => {
    setCurrImg(currImg + 1);
  };

  const generateImgSet = (item) => {
    if (!images || images.length === 0) {
      return [];
    }

    return images.map((image, index) => ({
      src: image,
      caption: `${item?.name}`,
      srcSet: [image],
    }));
  };

  const IMG_SET = generateImgSet(item);

  const design = getLastWord(itemName);

  function overrideDescription(mint) {
    let description = descriptions.pamp;

    if (!mint) {
      return descriptions.coin;
    }

    if (mint.includes('PAMP Suisse')) {
      description = descriptions.pamp;
    } else if (mint.includes('Argor Heraeus')) {
      description = descriptions.argorHeraeus;
    } else if (mint.includes('Valcambi')) {
      description = descriptions.valcambi;
    } else if (mint.includes('Fritz Ulysse')) {
      description = descriptions.fritz;
    } else {
      description = descriptions.pamp;
    }

    return description;
  }

  if (isLoading && isCategoriesLoading) {
    return;
  }

  if (!isLoading && !item && !isCategoriesLoading) {
    return <NotFound />;
  }

  return (
    <div className={clsx(classes.wrapper, !isWeb && classes.mobileWrapper)}>
      <div className={clsx(isWeb ? classes.itemWrapper : classes.mobileItemWrapper)}>
        <div className={classes.left}>
          <ImageCarousel images={images} isWeb={isWeb} openViewer={openViewer} />
          <div className={classes.fullScreen}>
            <FullscreenExitOutlinedIcon className={classes.icon} onClick={openViewer} />
          </div>
        </div>

        {images && viewerIsOpen && (
          <ImgsViewer
            imgs={IMG_SET}
            currImg={currImg}
            isOpen={viewerIsOpen}
            onClickPrev={gotoPrevious}
            onClickNext={gotoNext}
            onClose={closeViewer}
            width={isMobile ? 400 : 500}
          />
        )}

        <div className={classes.right}>
          <div className={classes.header}>
            <span className={classes.title}>
              {itemName}
              <span className={classes.price}>{currencyFormatter(price)}</span>
            </span>

            {!isWeb && (
              <>
                <div className={classes.availability}>
                  <span className={classes.availableQuantity}>{item?.quantity} Available in stock</span>

                  {error && <span className={classes.errorWrapper}>{error}</span>}
                </div>

                <div className={classes.divider} />
              </>
            )}
          </div>

          <div className={clsx(isWeb ? classes.contentWrapper : classes.mobileContentWrapper)}>
            <p className={classes.description}>{overrideDescription(item?.mint)}</p>

            <div className={classes.divider} />

            <div className={classes.details}>
              <div className={classes.row}>
                <span className={classes.label}>Metal</span>

                <span className={classes.value}>
                  {metal} {purity}
                </span>
              </div>
              <div className={classes.row}>
                <span className={classes.label}>Design</span>

                <span className={classes.value}>{design}</span>
              </div>
              <div className={classes.row}>
                <span className={classes.label}>Weight</span>

                <span className={classes.value}>{item?.weight}gr</span>
              </div>
              {item?.mint && (
                <div className={classes.row}>
                  <span className={classes.label}>Mint</span>

                  <span className={classes.value}>{item?.mint}</span>
                </div>
              )}
              <div className={classes.row}>
                <span className={classes.label}>Series</span>

                <span className={classes.value}>{item?.part_number}</span>
              </div>
            </div>
          </div>

          {isWeb && (
            <>
              <div className={classes.divider} />
              <div className={classes.availability}>
                <span className={classes.availableQuantity}>{item?.quantity} Available in stock</span>

                {error && <span className={classes.errorWrapper}>{error}</span>}
              </div>
            </>
          )}

          {isWeb && (
            <>
              <div className={classes.quantitySelector}>
                <QuantitySelector quantity={quantity} setQuantity={setQuantity} availableInStock={item?.quantity} />

                <Button className={classes.blackButton} onClick={addToCard}>
                  ADD TO CART
                </Button>
              </div>

              <div className={classes.buyNow}>
                <Button className={classes.greenBtn} onClick={buyNowHandler}>
                  BUY NOW
                </Button>
              </div>
            </>
          )}
          {nonVerifiedPopUpOpen && (
            <NonVerifiedUserPopUp
              type={'error'}
              isPopupOpen={nonVerifiedPopUpOpen}
              setIsPopupOpen={closeNonVerifiedPopUp}
              message={messages.accountNotVerified}
              handleCloseAutoInvestPopup={closeNonVerifiedPopUp}
            />
          )}
          <LoadingBar open={loading} />
        </div>
      </div>

      {!isWeb && (
        <div className={classes.fixedCTAMobileWrapper}>
          <CustomButton type="black" buttonText="ADD TO CART" height="40" width="100%" onClick={addToCard} />

          <CustomButton height="40" type="green" buttonText="BUY NOW" onClick={buyNowHandler} />
        </div>
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
    </div>
  );
};

const NotFound = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView } = useAppContext();

  function navigateToStore() {
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative.postMessage(`{"Event": "Store"}`);
    } else {
      navigate(routes.store);
      window.scrollTo(0, 0);
    }
  }

  return (
    <div className={classes.emptyCartWrapper}>
      <span className={classes.descriptionEmptyScreen}>This product is currently not available</span>

      <ProductNotFoundIcon />
    </div>
  );
};

const ImageCarousel = ({ images, isWeb, openViewer }) => {
  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };

  const nextSlide = () => {
    const newIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(newIndex);
  };

  const prevSlide = () => {
    const newIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(newIndex);
  };

  return (
    <>
      <div className={classes.imgCarouselWrapper}>
        <div className={classes.rootImg}>
          <div className={classes.slider}>
            {images.map((image, index) => (
              <div
                key={index}
                className={classes.slide}
                style={{
                  transform: `translateX(-${currentImageIndex * 100}%)`,
                }}
                onClick={openViewer}
              >
                <img src={image} alt={`Slide ${index}`} />
              </div>
            ))}
          </div>
        </div>

        {!isWeb && (
          <div className={classes.arrowWrapper}>
            <button onClick={prevSlide} className={classes.arrowButton}>
              <KeyboardArrowLeftIcon />
            </button>
            <button onClick={nextSlide} className={classes.arrowButton}>
              <KeyboardArrowRightIcon />
            </button>
          </div>
        )}

        {!isWeb && (
          <div className={classes.dotsContainer}>
            {images.map((image, index) => (
              <div
                key={index}
                className={clsx(classes.dot, {
                  [classes.activeDot]: index === currentImageIndex,
                })}
                onClick={() => goToSlide(index)}
              />
            ))}
          </div>
        )}
      </div>

      {isWeb && (
        <div className={classes.imagesWrapper}>
          {images?.map((imageUrl, idx) => (
            <img
              key={idx}
              src={imageUrl}
              onClick={() => goToSlide(idx)}
              className={clsx(classes.regularImg, currentImageIndex === idx && classes.activeImg)}
            />
          ))}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '80%',
    height: '100%',
    margin: '0 auto',
    overflowY: 'auto',
    marginBottom: '10px',
    paddingInline: '20px',
  },

  mobileWrapper: {
    height: 'calc(100dvh - 206px)',
  },

  itemWrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(120px, 1fr) minmax(240px,3fr)',
    gap: '30px',

    paddingTop: '22px',
  },

  mobileItemWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '30px',

    paddingTop: '22px',
  },

  left: {
    position: 'relative',
    cursor: 'pointer',
  },

  right: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },

  header: {
    textAlign: 'left',
  },

  title: {
    fontSize: '18px',
    textTransform: 'uppercase',
  },

  price: {
    fontWeight: 600,
    paddingLeft: 16,
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  mobileContentWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',

    margin: 0,

    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },

  description: {
    fontSize: '14px',
    textAlign: 'justify',

    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },

  divider: {
    borderBottom: '1px solid black',
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },

  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    textAlign: 'left',
  },

  value: {
    fontWeight: 500,
  },

  availability: {
    textAlign: 'left',
    fontSize: '14px',
  },

  availableQuantity: {
    color: '#48D0A4',
  },

  quantitySelector: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '20px',
  },

  buyNow: {
    display: 'flex',
    flexDirection: 'row',

    height: '40px',
    marginBottom: '20px',
  },

  imagesWrapper: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateRows: 'repeat(auto-fit, 100px)',
    gridTemplateColumns: 'repeat(auto-fit, 60px)',
    gap: 4,

    paddingTop: '16px',

    '& img': {
      width: '100%',
      maxWidth: '60px',
    },
  },

  imgCarouselWrapper: {
    position: 'relative',
  },

  rootImg: {
    position: 'relative',
    maxWidth: '100%',
    [theme.breakpoints.down('768')]: {
      width: '70%',
      maxWidth: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      zIndex: 2,
    },
    overflow: 'hidden',
  },
  slider: {
    display: 'flex',
    transition: 'transform 0.5s ease',
  },
  slide: {
    minWidth: '100%',
    overflow: 'hidden',
    position: 'relative',

    '& img': {
      position: 'relative',
      width: '100%',
      maxWidth: '240px',
      height: '100%',
    },
  },

  dotsContainer: {
    position: 'absolute',
    bottom: '-24px',
    left: '50%',
    zIndex: 20,
    transform: 'translateX(-50%)',
    display: 'flex',
    marginTop: '10px',
  },

  dot: {
    width: '12px',
    height: '12px',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#767676',
    margin: theme.spacing(0, 1),
    border: '1px solid transparent',
  },

  activeDot: {
    backgroundColor: '#E8E8E8',
    border: '1px solid #767676',
  },

  arrowWrapper: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 1,
  },

  arrowButton: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '10px',
    color: '#767676',
    outline: 'none',

    '& svg': {
      fontSize: '3.5rem',
    },
  },

  fixedCTAMobileWrapper: {
    position: 'fixed',
    left: 0,
    bottom: 0,

    zIndex: 100,

    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'minmax(140px, 280px) minmax(140px, 280px)',
    gap: 20,

    width: 'calc(100vw - 80px)',
    height: '56px',
    paddingInline: '40px',
    background: '#EFEFEF',
    borderTop: '1px solid #D9D9D9',

    '& button': {
      marginTop: '0px',
    },
  },

  regularImg: {
    border: '1px solid transparent',
  },

  activeImg: {
    border: '1px solid #48D0A4',
  },

  errorWrapper: {
    color: 'red',
    display: 'block',
    fontSize: '14px',
    paddingBlock: '6px',
  },
  fullScreen: {
    position: 'absolute',
    right: 'calc(20px + 0.5vw)',
    top: 0,
    zIndex: 3,
    [theme.breakpoints.down('1600')]: {
      right: 'calc(5px + 0.25vw)',
    },
    [theme.breakpoints.down('1300')]: {
      right: 'calc(2px + 0.1vw)',
    },
    [theme.breakpoints.down('768')]: {
      right: 'calc(90px + 0.1vw)',
    },
    [theme.breakpoints.down('600')]: {
      right: 'calc(60px + 0.1vw)',
    },
    [theme.breakpoints.down('450')]: {
      right: 'calc(50px + 0.1vw)',
    },
    [theme.breakpoints.down('400')]: {
      right: 'calc(40px + 0.1vw)',
    },
  },
  icon: {
    cursor: 'pointer',
  },
  blackButton: {
    backgroundColor: 'var(--black)',
    borderRadius: '40px',
    fontSize: '14px',
    width: '180px',
    [theme.breakpoints.down('768')]: {
      width: '48%',
    },
    color: 'var(--white)',
    border: 0,
    cursor: 'pointer',
    textAlign: 'left',
    boxShadow: '0px 4.9726881980896px 0px 0px #76767633',
    '&:hover': {
      backgroundColor: 'var(--black)',
    },
    [theme.breakpoints.down('900')]: {
      '& .MuiTypography-button': {
        fontSize: '12px !important',
      },
    },
    '&.Mui-disabled': {
      background: '#eaeaea',
      color: '#c0c0c0',
    },
    opacity: 1,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },
  greenBtn: {
    backgroundColor: 'var(--green-light)',
    borderRadius: '40px',
    fontSize: '14px',
    color: 'var(--white)',
    border: 0,
    textAlign: 'left',
    width: '345px',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0px 4.9726881980896px 0px 0px #76767633',
    '&:hover': {
      backgroundColor: 'var(--green-light)',
    },
    cursor: 'pointer',
    opacity: 1,
  },

  emptyCartWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 40,

    [theme.breakpoints.down('678')]: {
      gap: 24,
    },
  },

  descriptionEmptyScreen: {
    fontSize: '32px',
    paddingTop: '20px',

    [theme.breakpoints.down('678')]: {
      fontSize: '22px',
    },
  },
}));
