import { createContext, useState, useEffect } from 'react';
import socketWrapper from '../utils/socketWrapper';
import isMobileDevice from '../helpers/currencyFormatter/isMobileDevice';
import { useContext } from 'react';
import { userService } from '../service/user';
import { handleGetGoldPriceForAPeriod } from '../helpers/handleGetGoldPriceForAPeriod';
import { getToken } from '../helpers/getToken';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkIsDemo } from '../helpers/checkIsDemo';
import { handleGetUserDetails } from '../helpers/handleGetUserDetails';
import { getUserShoppingCartForDemo } from '../helpers/getUserShoppingCartForDemo';
import { serverCartDemo } from '../constants/demoConstants';
import { updateBucket } from '../helpers/update-client-bucket';
import { handleGetUserCart } from '../helpers/handleGetUserCart';
import { handleGetLatestAvailableEURPrice } from '../helpers/handleGetLatestAvailableEURPrice';

const DataContext = createContext({});

export function useAppContext() {
  return useContext(DataContext);
}

export const DataProvider = ({ children }) => {
  const isAppView = sessionStorage.getItem('isNative');
  // const isDemo =
  //   `${process.env.REACT_APP_DEMO_BASE_URL}` ===
  //   "https://www.demo-bullionz.com";

  const [quantity, setQuantity] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [goldAskPrice, setGoldAskPrice] = useState(0);
  const [goldAskPriceForGroup, setGoldAskPriceForGroup] = useState(0);
  const [goldBidPrice, setGoldBidPrice] = useState(0);
  const [productsBucket, setProductsBucket] = useState([]);
  const [goldBidPriceForGroup, setGoldBidPriceForGroup] = useState(0);
  const [eurToUsd, setEurToUsd] = useState(0);
  const [isGoldInvoiceModalOpen, setIsGoldInvoiceModalOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState();
  const [isBrokerOrderConfirmed, setIsBrokerOrderConfirmed] = useState(false);
  const [isBuySellLoading, setIsBuySellLoading] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [isPortfolioLoading, setIsPortfolioLoading] = useState(true);
  const [isTextUpdated, setIsTextUpdated] = useState(false);
  const [isHeightUpdated, setIsHeightUpdated] = useState(false);
  const [isBuySellPopupOpen, setIsBuySellPopupOpen] = useState(false);
  const [isStoreModalOpened, setIsStoreModalOpened] = useState(false);
  const [tooltipDimensions, setTooltipDimensions] = useState({});
  const [arrowProps, setArrowProps] = useState({});
  const [isRedeemGoldPopupOpen, setIsRedeemGoldPopupOpen] = useState(false);

  const [tutorialStep, setTutorialStep] = useState(Number(sessionStorage.getItem('tutorialStep')) || 0);
  const [isAccountMenuOpened, setIsAccountMenuOpened] = useState(false);
  const [proceedBuyOrderClicked, setProceedBuyOrderClicked] = useState(false);
  const [proceedSellOrderClicked, setProceedSellOrderClicked] = useState(false);
  const [socket, setSocket] = useState(null);
  const [isInHistory, setIsInHistory] = useState(false);
  const [nonVerifiedPopUpOpen, setNonVerifiedPopUpOpen] = useState(false);
  const [isInStaking, setIsInStaking] = useState(false);
  const [goldPriceError, setGoldPriceError] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [withdrawIframeUrl, setWithDrawIframeUrl] = useState('');
  const [notificationPopupType, setNotificationPopupType] = useState('');
  const [isCartFetched, setIsCartFetched] = useState(false);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [selectedCryptoAccount, setSelectedCryptoAccount] = useState(null);
  const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'USD');
  const [chosenPeriod, setChosenPeriod] = useState('1H');
  const [proceedVaultOrderClicked, setProceedVaultOrderClicked] = useState(false);
  const [proceedRedeemOrderClicked, setProceedRedeemOrderClicked] = useState(false);
  const [proceedStakeOrderClicked, setProceedStakeOrderClicked] = useState(false);
  const [proceedAutoInvestOrderClicked, setProceedAutoInvestOrderClicked] = useState(false);
  const [proceedAddNewAddressClicked, setProceedAddNewAddressClicked] = useState(false);
  const [proceedChangePasswordClicked, setProceedChangePasswordClicked] = useState(false);
  const [totalAllocatedCoins, setTotalAllocatedCoins] = useState(0);
  const [stakedCoins, setStakedCoins] = useState(0);
  const [vaultedCoins, setVaultedCoins] = useState(0);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [userCashBalance, setUserCashBalance] = useState();
  const [unreadNotificationsCounter, setUnreadNotificationsCounter] = useState(0);
  const [avgCoinPrice, setAvgCoinPrice] = useState(0);
  const [responseFromCancelledAutoInvestTransaction, setResponseFromCancelledAutoInvestTransaction] = useState({});
  const [goldPriceForPeriod, setGoldPriceForPeriod] = useState([]);
  const [goldHistoryPeriod, setGoldHistoryPeriod] = useState(isMobile || isAppView ? '1D' : '1H');
  const [onlyPriceHistoryForPeriod, setOnlyPriceHistoryForPeriod] = useState([]);
  const [goldTradingPool, setGoldTradingPool] = useState([]);
  const [onlyTimesForGoldHistory, setOnlyTimesForGoldHistory] = useState([]);
  const [smooth, setSmooth] = useState(false);

  const [isErrorRemovedPA, setIsErrorRemovedPA] = useState(false);
  const [isTutorialLoading, setIsTutorialLoading] = useState(false);
  const [IsSocketReconnecting, setIsSocketReconnecting] = useState(true);
  const [arrowPositionUpdated, setArrowPositionUpdated] = useState(false);
  const [tooltipPositionUpdated, setTooltipPositionUpdated] = useState(false);
  const [tutorialElmOffset, setTutorialElmOffset] = useState({
    left: 0,
    top: 0,
    right: 0,
  });
  const [userDetails, setUserDetails] = useState('');
  const [loading, setLoading] = useState(true);
  const [userCartData, setUserCartData] = useState(null);
  const [activeTradeAccountId, setActiveTradeAccountId] = useState();
  const [activeTradeAccountCurrency, setActiveTradeAccountCurrency] = useState('');
  const [activeTradeAccountBalance, setActiveTradeAccountBalance] = useState();
  const [refetch, setRefetch] = useState(false);
  const [userTradeAccounts, setUserTradeAccounts] = useState([]); // from /portfolio endpoint
  const [userBrokerAccounts, setUserBrokerAccounts] = useState([]);

  checkIsDemo();

  const navigate = useNavigate();

  let hasFetchedEurPrice = false;

  const token = localStorage.getItem('accessToken');
  const expToken = localStorage.getItem('accessTokenExp');
  const isDemo = sessionStorage.getItem('isDemo');
  const isUserVerified = sessionStorage.getItem('isVerified');

  const location = useLocation();
  let isOnHomePage = window.location.pathname === '/';

  useEffect(() => {
    location.pathname === '/' ? (isOnHomePage = true) : (isOnHomePage = false);
  }, [location]);

  getToken();

  useEffect(() => {
    if (location.pathname !== '/login') {
      handleGetUserDetails(setUserDetails, setLoading);
    }
  }, [location, refetch]);

  useEffect(() => {
    if (activeTradeAccountCurrency) {
      handleGetUserCart(setUserCartData, () => {});
    }
  }, [activeTradeAccountCurrency]);

  useEffect(() => {
    if (userDetails && userTradeAccounts?.length > 0) {
      const taid = localStorage.getItem('taid');
      const tradingAccounts = userTradeAccounts;

      if (taid) {
        setActiveTradeAccountId(Number(taid));
        let accBalance = tradingAccounts?.find((ta) => ta.tradeAccountId === Number(taid))?.balance;

        let accCurrency = tradingAccounts?.find((ta) => ta.tradeAccountId === Number(taid))?.currency;

        if (currency) {
          setCurrency(accCurrency);
        }

        setActiveTradeAccountCurrency(accCurrency);
        setActiveTradeAccountBalance(accBalance);
        setUserCashBalance(accBalance);
      } else {
        setActiveTradeAccountBalance(tradingAccounts[0]?.balance);
        setActiveTradeAccountId(tradingAccounts[0]?.tradeAccountId);
        setActiveTradeAccountCurrency(tradingAccounts[0]?.currency);
      }
    }
  }, [userTradeAccounts]);

  useEffect(() => {
    if (!isDemo) {
      updateBucket(isAppView, userCartData, setIsCartFetched, setProductsBucket, userService.updateShoppingCart);
    } else {
      getUserShoppingCartForDemo(setProductsBucket, serverCartDemo, isAppView);
    }
  }, [userCartData]);

  const handleGoldPriceUpdate = async (data) => {
    const integerValueOfAskPriceData = Number(data.askPrice);
    const integerValueOfAskPriceForGroup = Number(data.askPriceForGroup);
    const integerValueOfBidPriceData = Number(data.bidPrice);
    const integerValueOfBidPriceForGroup = Number(data.bidPriceForGroup);

    if (
      currency === 'EUR' &&
      (integerValueOfAskPriceData === 0 ||
        integerValueOfAskPriceForGroup === 0 ||
        integerValueOfBidPriceData === 0 ||
        integerValueOfBidPriceForGroup === 0)
    ) {
      if (!hasFetchedEurPrice) {
        hasFetchedEurPrice = true;
        const response = await handleGetLatestAvailableEURPrice();

        sessionStorage.setItem('goldAskPrice', response?.askPrice);
        sessionStorage.setItem('goldAskPriceForGroup', response?.askPriceForGroup);
        sessionStorage.setItem('goldBidPrice', response?.bidPrice);
        sessionStorage.setItem('goldBidPriceForGroup', response?.bidPriceForGroup);
        sessionStorage.setItem('eurToUsd', response?.eurToUsd);

        setGoldAskPrice(response?.askPrice);
        setGoldAskPriceForGroup(response?.askPriceForGroup);
        setGoldBidPrice(response?.bidPrice);
        setGoldBidPriceForGroup(response?.bidPriceForGroup);
        setEurToUsd(response?.eurToUsd);
      }
    } else {
      sessionStorage.setItem('goldAskPrice', integerValueOfAskPriceData);
      sessionStorage.setItem('goldAskPriceForGroup', integerValueOfAskPriceForGroup);
      sessionStorage.setItem('goldBidPrice', integerValueOfBidPriceData);
      sessionStorage.setItem('goldBidPriceForGroup', integerValueOfBidPriceForGroup);

      setGoldAskPrice(integerValueOfAskPriceData);
      setGoldAskPriceForGroup(integerValueOfAskPriceForGroup);
      setGoldBidPrice(integerValueOfBidPriceData);
      setGoldBidPriceForGroup(integerValueOfBidPriceForGroup);
    }
  };

  useEffect(() => {
    const updateMobileStatus = () => {
      const result = isMobileDevice();
      setIsMobile(result);
    };

    const orientationChangeHandler = () => {
      if (!isMobileDevice() && window.innerWidth > window.innerHeight) {
        return;
      }

      updateMobileStatus();
    };

    const resizeHandler = () => {
      updateMobileStatus();
    };

    window.addEventListener('orientationchange', orientationChangeHandler);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('orientationchange', orientationChangeHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if ((token && expToken) || isDemo) {
      if (userDetails && userDetails.userGroupId) {
        const newSocket = socketWrapper.connect(userDetails.userGroupId, currency);
        setSocket(newSocket);

        return () => {
          socketWrapper.disconnect(newSocket);
          setIsSocketReconnecting(true);
        };
      }
    }
  }, [token, expToken, userDetails?.userGroupId, isDemo, currency]);

  useEffect(() => {
    if (socket) {
      setGoldBidPriceForGroup(0);
      socketWrapper.on(socket, 'goldPriceUpdate', handleGoldPriceUpdate);
    }

    return () => {
      if (socket) {
        setGoldBidPriceForGroup(0);
        socketWrapper.off(socket, 'goldPriceUpdate', handleGoldPriceUpdate);
      }
    };
  }, [socket, currency, activeTradeAccountId]);

  useEffect(() => {
    if (socket) {
      socketWrapper.on(socket, 'connect', () => {
        setIsSocketReconnecting(false);
      });
    }
  }, [socket]);

  const getUserPortfolioInfo = async (fn, fn2, removeError) => {
    if ((token && expToken) || isDemo) {
      try {
        setIsPortfolioLoading(true);
        const userPortfolio = await userService.getUserPortfolio();
        if (userPortfolio !== 'undefined') {
          removeError(true);
        }
        sessionStorage.setItem('stakedCoins', userPortfolio?.stakedCoins);
        sessionStorage.setItem('vaultedCoins', userPortfolio?.vaultedCoins);
        sessionStorage.setItem('totalAllocatedCoins', userPortfolio?.coins);
        sessionStorage.setItem('avgCoinPrice', userPortfolio?.avgCoinPrice);

        setTotalAllocatedCoins(userPortfolio?.coins);
        setStakedCoins(userPortfolio?.stakedCoins);
        setVaultedCoins(userPortfolio?.vaultedCoins);
        setAvgCoinPrice(userPortfolio?.averageCoinPrice?.[currency] || userPortfolio?.avgCoinPrice);
        setUnreadNotificationsCounter(userPortfolio?.unreadNotificationsCount);
        setUserTradeAccounts(userPortfolio?.tradeAccounts);
        setUserBrokerAccounts(userPortfolio?.brokerAccounts);
        setExchangeRate(userPortfolio?.exchangeRate);

        if (!userPortfolio?.brokerAccounts || !userPortfolio?.brokerAccounts?.length) {
          navigate('/');
        }

        if (!selectedCryptoAccount?.brokerAccountId) {
          setSelectedCryptoAccount(userPortfolio?.brokerAccounts?.[0]);
        } else {
          const newData = userPortfolio?.brokerAccounts?.find(
            (ba) => ba.brokerAccountId === selectedCryptoAccount?.brokerAccountId,
          );

          setSelectedCryptoAccount(newData);
        }

        fn([userPortfolio?.stakedCoins, userPortfolio?.coins, userPortfolio?.vaultedCoins]);
        fn2({
          name: 'TOTAL',
          value: userPortfolio?.stakedCoins + userPortfolio?.vaultedCoins + userPortfolio?.coins,
        });
        setIsPortfolioLoading(false);
      } catch (error) {
        console.error('An error occurred while fetching user portfolio:', error);
      }
    }
  };

  const [portfolioAllocationData, setPortfolioAllocationData] = useState([
    stakedCoins,
    vaultedCoins,
    totalAllocatedCoins,
  ]);

  useEffect(() => {
    setAvgCoinPrice(0);
    getUserPortfolioInfo(setPortfolioAllocationData, setNameAndValueInPA, setIsErrorRemovedPA);
  }, [
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
    proceedStakeOrderClicked,
    proceedVaultOrderClicked,
    proceedRedeemOrderClicked,
    proceedAutoInvestOrderClicked,
    isLoggedIn,
    refetch,
    currency,
  ]);

  useEffect(() => {
    if (token && expToken) {
      handleGetGoldPriceForAPeriod(
        goldHistoryPeriod,
        setGoldPriceForPeriod,
        setGoldPriceError,
        setOnlyPriceHistoryForPeriod,
        setOnlyTimesForGoldHistory,
        setSmooth,
      );
    } else if (isDemo) {
      handleGetGoldPriceForAPeriod(
        goldHistoryPeriod,
        setGoldPriceForPeriod,
        setGoldPriceError,
        setOnlyPriceHistoryForPeriod,
        setOnlyTimesForGoldHistory,
        setSmooth,
      );
    }
  }, [goldHistoryPeriod, chosenPeriod, isLoggedIn]);

  // useEffect(() => {
  //   getGoldTradingPool(setGoldTradingPool, setErrorDuringFetchingGoldPool);
  // }, [isLoggedIn]);

  const fetchGoldTradingPool = async () => {
    getUserPortfolioInfo(setPortfolioAllocationData, setNameAndValueInPA, setIsErrorRemovedPA);
    if (token && expToken) {
      handleGetGoldPriceForAPeriod(
        goldHistoryPeriod,
        setGoldPriceForPeriod,
        setGoldPriceError,
        setOnlyPriceHistoryForPeriod,
        setOnlyTimesForGoldHistory,
        setSmooth,
      );
    }
    //getGoldTradingPool(setGoldTradingPool, setErrorDuringFetchingGoldPool);
  };
  const [nameAndValueInPA, setNameAndValueInPA] = useState({
    name: 'TOTAL',
    value: 0,
  });

  const defaultValues = {
    goldAskPrice: 0,
    goldBidPrice: 0,
    goldAskPriceForGroup: 0,
    goldBidPriceForGroup: 0,
    eurToUsd: 0,
    isMobile: isMobileDevice(),
    setIsMobile: () => {},
    isInHistory: false,
    setIsInHistory: () => {},
    nameAndValueInPA: {
      name: 'TOTAL',
      value: 0,
    },
    setNameAndValueInPA: () => {},
    proceedBuyOrderClicked: false,
    setProceedBuyOrderClicked: () => {},
    proceedSellOrderClicked: false,
    setProceedSellOrderClicked: () => {},
    proceedVaultOrderClicked: false,
    setProceedVaultOrderClicked: () => {},
    proceedStakeOrderClicked: false,
    setProceedStakeOrderClicked: () => {},
    totalAllocatedCoins: 0,
    stakedCoins: 0,
    vaultedCoins: 0,
    notificationPopupType: '',
    setNotificationPopupType: () => {},
    popUpMessage: '',
    setPopUpMessage: () => {},
    portfolioAllocationData: [0, 0, 0],
    setPortfolioAllocationData: () => {},
    userCashBalance: undefined,
    proceedAutoInvestOrderClicked: false,
    setProceedAutoInvestOrderClicked: () => {},
    responseFromCancelledAutoInvestTransaction: {},
    setResponseFromCancelledAutoInvestTransaction: () => {},
    avgCoinPrice: 0,
    goldPriceForPeriod: [],
    goldPriceError: false,
    setGoldHistoryPeriod: () => {},
    onlyPriceHistoryForPeriod: [],
    onlyTimesForGoldHistory: [],
    isInStaking: false,
    setIsInStaking: () => {},
    setChosenPeriod: () => {},
    chosenPeriod: '1H',
    smooth: false,
    setSmooth: () => {},
    goldTradingPool: [],
    setGoldTradingPool: () => {},
    isErrorRemovedPA: false,
    setIsErrorRemovedPA: () => {},
    fetchGoldTradingPool: () => {},
    proceedRedeemOrderClicked: false,
    setProceedRedeemOrderClicked: () => {},
    proceedAddNewAddressClicked: false,
    setProceedAddNewAddressClicked: () => {},
    withdrawIframeUrl: '',
    setWithDrawIframeUrl: () => {},
    proceedChangePasswordClicked: false,
    setProceedChangePasswordClicked: () => {},
    setIsLoggedIn: () => {},
    unreadNotificationsCounter: 0,
    setUnreadNotificationsCounter: () => {},
    nonVerifiedPopUpOpen: false,
    setNonVerifiedPopUpOpen: () => {},
    isLoggedIn: false,
  };

  useEffect(() => {
    setGoldHistoryPeriod(isMobile || isAppView ? '1D' : '1H');
    setChosenPeriod(isMobile || isAppView ? '1D' : '1H');
    return () => {
      setGoldHistoryPeriod('1D');
      setChosenPeriod('1D');
    };
  }, [isMobile, isAppView]);

  return (
    <DataContext.Provider
      value={{
        ...defaultValues,
        goldAskPrice,
        goldBidPrice,
        goldAskPriceForGroup,
        goldBidPriceForGroup,
        eurToUsd,
        isAppView,
        isMobile,
        setIsMobile,
        isInHistory,
        setIsInHistory,
        nameAndValueInPA,
        setNameAndValueInPA,
        proceedBuyOrderClicked,
        setProceedBuyOrderClicked,
        proceedSellOrderClicked,
        setProceedSellOrderClicked,
        proceedVaultOrderClicked,
        setProceedVaultOrderClicked,
        proceedStakeOrderClicked,
        setProceedStakeOrderClicked,
        totalAllocatedCoins,
        stakedCoins,
        vaultedCoins,
        notificationPopupType,
        setNotificationPopupType,
        popUpMessage,
        setPopUpMessage,
        portfolioAllocationData,
        setPortfolioAllocationData,
        userCashBalance,
        proceedAutoInvestOrderClicked,
        setProceedAutoInvestOrderClicked,
        responseFromCancelledAutoInvestTransaction,
        setResponseFromCancelledAutoInvestTransaction,
        avgCoinPrice,
        goldPriceForPeriod,
        goldPriceError,
        setGoldHistoryPeriod,
        onlyPriceHistoryForPeriod,
        onlyTimesForGoldHistory,
        isInStaking,
        setIsInStaking,
        setChosenPeriod,
        chosenPeriod,
        smooth,
        setSmooth,
        goldTradingPool,
        setGoldTradingPool,
        isErrorRemovedPA,
        setIsErrorRemovedPA,
        fetchGoldTradingPool,
        proceedRedeemOrderClicked,
        setProceedRedeemOrderClicked,
        proceedAddNewAddressClicked,
        setProceedAddNewAddressClicked,
        withdrawIframeUrl,
        setWithDrawIframeUrl,
        proceedChangePasswordClicked,
        setProceedChangePasswordClicked,
        setIsLoggedIn,
        unreadNotificationsCounter,
        setUnreadNotificationsCounter,
        nonVerifiedPopUpOpen,
        setNonVerifiedPopUpOpen,
        isLoggedIn,
        tutorialStep,
        setTutorialStep,
        tutorialElmOffset,
        setTutorialElmOffset,
        isOnHomePage,
        setIsAccountMenuOpened,
        isAccountMenuOpened,
        setIsTutorialLoading,
        isTutorialLoading,
        isRedeemGoldPopupOpen,
        setIsRedeemGoldPopupOpen,
        setTooltipHeight,
        tooltipHeight,
        tooltipDimensions,
        setTooltipDimensions,
        arrowProps,
        setArrowProps,
        arrowPositionUpdated,
        setArrowPositionUpdated,
        tooltipPositionUpdated,
        setTooltipPositionUpdated,
        isTextUpdated,
        setIsTextUpdated,
        tooltipText,
        setTooltipText,
        isHeightUpdated,
        setIsHeightUpdated,
        isBuySellPopupOpen,
        setIsBuySellPopupOpen,
        isBuySellLoading,
        setIsBuySellLoading,
        productsBucket,
        setProductsBucket,
        isStoreModalOpened,
        setIsStoreModalOpened,
        isActiveTutorial: tutorialStep >= 0 && tutorialStep < 15,
        isGoldInvoiceModalOpen,
        setIsGoldInvoiceModalOpen,
        quantity,
        setQuantity,
        isCartFetched,
        setIsCartFetched,
        isPortfolioLoading,
        userDetails,
        setUserDetails,
        activeTradeAccountId,
        setActiveTradeAccountId,
        activeTradeAccountCurrency,
        setActiveTradeAccountCurrency,
        activeTradeAccountBalance,
        setActiveTradeAccountBalance,
        currency,
        setCurrency,
        isUserVerified,
        IsSocketReconnecting,
        refetch,
        setRefetch,
        userCartData,
        userTradeAccounts,
        userBrokerAccounts,
        accountAnchorEl,
        setAccountAnchorEl,
        selectedCryptoAccount,
        setSelectedCryptoAccount,
        isBrokerOrderConfirmed,
        setIsBrokerOrderConfirmed,
        exchangeRate,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
