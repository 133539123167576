import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import ECommerceCard from '../../../components/WebComponents/E-Commerce/E-CommerceCard';
import News from '../../../components/WebComponents/News/CardNews/News';
import StakingNavigation from "../../../components/WebComponents/StakingNavigation/StakingNavigation";
import TransactionsCryptoCard from '../../../components/WebComponents/CryptoTransactions/CryptoTransactionsCard';
import { makeStyles } from '@mui/styles';
import { coinName } from '../../../constants/globalconstants';
import { useAppContext } from '../../../context/DataContext';
import DashboardWebLayout from '../../../components/common/Layout/DashboardWebLayout';
import PortfolioAssets from '../../../components/WebComponents/PortfolioAssets/PortfolioAssets';
import CryptoAssets from '../../../components/WebComponents/CryptoAssets/CryptoAssets';
import BuySellCardCrypto from '../../../components/WebComponents/BuySellCardCrypto/BuySellCardCrypto';
import CryptoAccounts from '../../../components/WebComponents/CryptoAccounts/CryptoAccounts';
import CryptoGoldHistory from '../../../components/WebComponents/CryptoGoldHistory/CryptoGoldHistory';

function WebCryptoDashboard() {
  const classes = useStyles();
  const { isInStaking, setIsInStaking } = useAppContext();

  useEffect(() => {
    setIsInStaking(false);
  }, []);

  return (
    <DashboardWebLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1920px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Box
          sx={{
            marginLeft: 1,
            marginRight: 1,
            height: '100%',
            '@media screen and (max-width: 1200px)': {
              marginRight: 0,
            },
            '@media screen and (max-width: 850px)': {
              overflowX: 'clip',
            },
          }}
        >
          <Grid container alignItems="stretch" direction="row" className={classes.customContainer}>
            <Grid item xs={11.9} md={11.9} sm={11.9} xl={7.8} lg={7.8} className={classes.customInnerContainer}>
              <Grid container alignItems="stretch" direction="row" sx={{ height: '100%' }}>
                <Grid item xs={9.4} md={9.4} sm={9.4} xl={9.4} lg={9.4} sx={{ height: '100%' }}>
                  <Grid container alignItems="stretch" direction="row">
                    <Grid item xs={4} md={4} sm={4} xl={4} lg={4}>
                      <Box style={{ height: '100%' }} id="monthly-pool">
                        <CryptoAssets />
                      </Box>
                    </Grid>

                    <Grid item xs={8} md={8} sm={8} xl={8} lg={8} sx={{ height: '100%' }}>
                      <Box
                        sx={{
                          height: '62px',
                          padding: 2,
                          paddingTop: 0,
                        }}
                        id="buyclose-container"
                      >
                        <CryptoAccounts />
                      </Box>

                      <Box
                        sx={{
                          padding: '16px',
                          paddingTop: 0,

                          height: '333px',
                        }}
                        id="buyclose-container"
                      >
                        <BuySellCardCrypto />
                      </Box>
                      <Box
                        style={{
                          padding: '1rem',
                          paddingTop: '0px',
                          height: '463px',
                        }}
                        id="btxg-container"
                      >
                        <CryptoGoldHistory title={coinName} isInStaking={isInStaking} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2.6} md={2.6} sm={2.6} xl={2.6} lg={2.6}>
                  <Box sx={{ height: '100%' }}>
                    <Box sx={{ height: '598px', marginTop: '8px' }} id="portfolio-container">
                      <PortfolioAssets />
                    </Box>
                    <Box sx={{ height: '306px' }} id="ecom-container">
                      <ECommerceCard />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11.9} md={11.9} sm={11.9} xl={4.2} lg={4.2}>
              <Box className={classes.wrapperLeftSide}>
                <Box className={classes.tableTransactionsContainer} id="transactions-container">
                  <TransactionsCryptoCard containerClassName={classes.container} />
                </Box>
                <Box className={classes.latestNewsContainer}>
                  <News isAutoInvest={false} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ marginTop: 0, marginBottom: 1, marginLeft: 2, marginRight: 2 }}
        >
          <Grid container>
            <Grid item xs={6} md={6} sm={6} xl={6} lg={6}>
              <StakingNavigation />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardWebLayout>
  );
}

export default WebCryptoDashboard;

const useStyles = makeStyles((theme) => ({
  customContainer: {
    height: '100%',
  },
  customInnerContainer: {
    height: '100%',
  },
  container: {
    height: '96%',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'hidden',
    border: '0.5px #D2D5D8',
    borderRadius: '24px',
    justifyContent: 'space-between',
    position: 'relative',
    '& .MuiTabs-indicator': {
      backgroundColor: 'black',
      height: '2px',
      width: '220px !important',
      left: '21px !important',
      [theme.breakpoints.down('1820')]: {
        width: '140px !important',
      },
      [theme.breakpoints.down('1645')]: {
        width: '126px !important',
      },
      [theme.breakpoints.down('1465')]: {
        width: '170px !important',
        height: '1px',
      },
      [theme.breakpoints.down('1290')]: {
        width: '96px !important',
      },
    },
    '& .MuiTab-wrapper': {
      fontWeight: 'bold',
    },
  },
  tableTransactionsContainer: {
    marginLeft: '1rem',
    height: '458px',
    [theme.breakpoints.down('1680')]: {
      height: '440px',
    },
    [theme.breakpoints.down('1520')]: {
      height: '427px',
    },
    [theme.breakpoints.down('1200')]: {
      width: '70%',
      maxWidth: '400px',
      minWidth: '400px',
      height: '420px',
      marginLeft: '0rem',
    },
    [theme.breakpoints.down('820')]: {
      width: '58%',
      marginLeft: '0rem',
    },
    [theme.breakpoints.down('790')]: {
      width: '55%',
      marginLeft: '0rem',
    },
  },
  latestNewsContainer: {
    marginLeft: '1rem',
    height: '429px',
    [theme.breakpoints.down('1680')]: {
      height: '447px',
    },
    [theme.breakpoints.down('1520')]: {
      height: '459px',
    },
    [theme.breakpoints.down('1380')]: {
      height: '458px',
    },
    [theme.breakpoints.down('1200')]: {
      height: '387px',
      width: '100%',
    },
    [theme.breakpoints.down('910')]: {
      width: '100%',
    },
    [theme.breakpoints.down('859')]: {
      width: '49%',
    },
    [theme.breakpoints.down('835')]: {
      width: '47%',
    },
    [theme.breakpoints.down('800')]: {
      width: '45%',
    },
  },
  wrapperLeftSide: {
    height: '100%',
    marginTop: '8px',
    [theme.breakpoints.down('1200')]: {
      display: 'flex',
      width: '100%',
      marginTop: '1rem',
    },
  },
}));
