import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip disableTouchListener {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
  backgroundColor: "var(--beige)",
  color: "var(--white)",
  fontSize: "0.8rem",
  borderRadius: "24px",
  padding: "1rem",
  maxWidth: "12rem",
  boxShadow: "3px 4px 0px 0px #0000000D;",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--beige)",
    "&::before": {
      backgroundColor: "var(--beige)",
      border: "1px solid #E0C38B",
    },
    display: "flex",
    justifyContent: "center",
  },
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down("1500")]: {
    fontSize: "0.7rem",
  },
  [theme.breakpoints.down("1300")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.down("1200")]: {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.down("1100")]: {
    fontSize: "0.4rem",
  },
}));

export default CustomTooltip;
