import { userService } from '../service/user';

async function isVerified(setNonVerifiedPopUpOpen, setLoading) {
  // const params = new URLSearchParams(window.location.search);
  //const isNativeParam = params.get("isNative");
  let verified;

  const isVerifiedAlready = sessionStorage.getItem('isVerified') === 'true';
  if (isVerifiedAlready) {
    setNonVerifiedPopUpOpen(false);
    verified = true;
    return verified;
  }

  try {
    const isVerified = await userService.isUserVerified(setLoading);
    if (isVerified?.isVerified) {
      setNonVerifiedPopUpOpen(false);
      sessionStorage.setItem('isVerified', isVerified?.isVerified);
    } else {
      sessionStorage.setItem('isVerified', isVerified?.isVerified);
      sessionStorage.setItem('levelName', isVerified?.levelName);
      sessionStorage.setItem('externalUserId', isVerified?.externalUserId);
      setNonVerifiedPopUpOpen(true);
    }
    verified = isVerified?.isVerified;
    return verified;
  } catch (error) {
    console.error(error);
  }
  return verified;
}

export default isVerified;
