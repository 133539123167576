import { createStyles, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useAppContext } from "../../../../context/DataContext";

function PortFolioFooter() {
  const classes = useStyles();
  const {
    nameAndValueInPA,
    stakedCoins,
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
    proceedStakeOrderClicked,
    proceedVaultOrderClicked,
  } = useAppContext();
  const { name, value } = nameAndValueInPA;
  useEffect(() => {
    console.log();
  }, [
    proceedBuyOrderClicked,
    proceedSellOrderClicked,
    proceedStakeOrderClicked,
    proceedVaultOrderClicked,
    stakedCoins,
  ]);
  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Box className={classes.footer}>
          <Box>{name}</Box>
          <Box className={classes.value}>
            <Box className={classes.stakingValue}>{value}gr</Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default PortFolioFooter;

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      fontWeight: "bold",
      fontSize: "var(--font-size-18)",
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-16)",
      },
      [theme.breakpoints.down("1300")]: {
        fontSize: "var(--font-size-14)",
      },
    },
    container: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      lineHeight: "0.6rem",
      width: "auto",
      backgroundColor: "var(--yellow-pale)",
      borderBottomLeftRadius: "24px",
      borderBottomRightRadius: "24px",
      height: "8%",
      textAlign: "right",
      padding: "1rem",
    },
    value: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    stakingValue: {
      fontSize: "var(--font-size-20)",
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-16)",
      },
      [theme.breakpoints.down("1300")]: {
        fontSize: "var(--font-size-14)",
      },
    },
    bullionzText: {
      fontSize: "var(--font-size-16)",
      marginTop: "1rem",
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down("1600")]: {
        fontSize: "var(--font-size-14)",
        marginTop: "0.5rem",
      },
      [theme.breakpoints.down("1400")]: {
        fontSize: "var(--font-size-12)",
        marginTop: "0.3rem",
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: "var(--font-size-10)",
        marginTop: "0.2rem",
      },
      fontWeight: 400,
    },
  })
);
