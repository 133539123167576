import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, createStyles } from '@mui/material';

import Wallet from '../../../assets/images/wallet.svg';
import Asset from './components/Asset';
import { useAppContext } from '../../../context/DataContext';
import IButton from '../../common/IButton/IButton';
import { tooltipContent } from '../../../constants/tooltipTexts';

const PortfolioAssets = () => {
  const classes = useStyles();
  const { selectedCryptoAccount } = useAppContext();
  const assets = selectedCryptoAccount?.assets?.filter((asset) => {
    return asset?.amount > 0 && asset?.assetId !== 'usdt';
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <PortoflioHeader />
      </Box>

      <div className={classes.assetsList}>
        {assets && assets?.length > 0 ? (
          assets?.map((asset) => {
            return <Asset key={asset.id} asset={asset} />;
          })
        ) : (
          <div className={classes.noAssets}>No assets</div>
        )}
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      position: 'relative',
      backgroundColor: 'var(--white)',
      borderRadius: '24px',
      fontFamily: theme.typography.fontFamily,
      height: '98%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
    },

    title: {
      letterSpacing: '0.5px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 4,
      height: '40px',
      fontFamily: theme.typography.fontFamily,
    },

    assetsList: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      padding: '8px',
      overflowY: 'auto',
      marginBottom: '8px',
    },

    noAssets: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '105px',
    },
  }),
);

const PortoflioHeader = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.title}>
        <IButton tooltipTitle={tooltipContent.cryptoPortfolio} />
        <Box>
          <img className={classes.imageWrapper} src={Wallet} alt="Wallet"></img>
        </Box>
        <Box>
          <span className={classes.portfolioText}>Portfolio</span>
        </Box>
      </Box>
    </>
  );
};

export default PortfolioAssets;
