export const tooltipContent = {
  crypto: 'Trade with Crypto assets through the Bullionz platform.',
  storeBuyGold: 'Conveniently purchase real physical gold through the Bullionz store.',
  goldHoldings: 'Total balance of the account’s BTXG tokens in fiat cash currency value.',
  btxgCoins: 'Total number of the account’s BTXG tokens.',
  totalBalance: 'Total balance of all the account’s available funds (FIAT + BTXG tokens + Crypto assets) in fiat cash currency value.',
  cashBalance: 'Total balance of the account’s available fiat currency cash funds.',
  cryptoBalance: 'Total balance of the account’s Crypto assets in fiat cash currency value.',
  headerMobile:
    "Total balance of all the account's available funds (Monetary value of gold with BullionZ + Total balance of the account's available fiat currency cash funds) in fiat cash currency value.",
  totalMonthlyPool:
    "Current monthly fee pool (Gold trading fees + Card transaction fees) of all users' activities on the Bullionz system, from which rewards are distributed to users within the Yielding program.",
  cardTransactionsPool:
    "Current monthly Card transaction fee pool of all users' card activities through Bullionz Cards, from which rewards are distributed to users within the Yielding program.",
  goldTradingPool:
    "Current monthly Gold trading fees of all users' activities on the Bullionz system, from which rewards are distributed to users within the Yielding program.",
  redeemGold: 'Convert BTXG digital gold tokens to real physical gold or traditional monetary fiat currencies.',
  staking:
    "Hold funds via Bullionz's digitalized gold to support the security and operations of the BTXG blockchain network and receive rewards.",
  stakedCoins: 'Monetary (traditional FIAT) value of yielded gold with BullionZ.',
  stakingHistory:
    "A comprehensive history of the account's assets and amounts set aside through Bullionz' Yielding program.",
  vaulting:
    'Store physical gold with Bullionz in secure vaults across various locations, where they can be converted to digital assets.',
  vaultedCoins: 'Monetary (traditional FIAT) value of vaulted/stored gold with BullionZ.',
  vaultingHistory: "A comprehensive history of the account's assets and amounts stored in Bullionz's Vaults.",
  certificates:
    "The account's certificates of physical gold held within Bullionz's vaults, including numerical identification number, amount/size of gold held, locker number, location, and more.",
  autoInvest: 'Automatically invest BTXG digital gold tokens on a schedule.',
  autoInvestOrdersAndMonthlyCoins:
    'All active auto invest orders and number of BTXG digital gold tokens scheduled to invest in till the end of current month.',
  autoInvestHistory: "A comprehensive history of all the account's BTXG coin Auto invest transactions.",
  buy: 'Buy BTXG digital gold tokens at a predetermined market price.',
  sell: 'Close BTXG digital gold tokens order(s) at a predetermined market price.',
  transactionsTable:
    "A comprehensive history of all the account's BTXG coin transactions performed including buy/close order(s), redeem and auto invest.",
  goldPriceHistory: "A comprehensive history of Bullionz's gold price offerings.",
  portfolioAllocation:
    "A graphical percentage representation of the account's available coins, vaulted coins, and yielded coins.",
  eCommerce: "Buy physical gold bullion bars and coins through Bullionz's Shop.",
  news: "Bullionz's latest news and blog articles.",
  buyOrderSpecialPrice:
    'This reduced price is offered based on your current account type. Deposit more to enjoy additional discounts.',
  closeOrderSpecialPrice:
    'This increased price is offered based on your current account type. Deposit more to enjoy additional discounts.',
  yieldingYourProfit:
    'This increased reward is offered based on your current account type. Deposit more to enjoy additional discounts.',
  vaultingYourFee:
    'This reduced fee is offered based on your current account type. Deposit more to enjoy additional discounts.',
  autoInvestOrderSpecialValue:
    'This reduced price is offered based on your current account type. Deposit more to enjoy additional discounts.',
  cryptoAssets: 'A list with Crypto assets available for users to invest in through the Bullionz platform.',
  cryptoWalletAccounts:
    "Balance of the account’s available USDT funds. Click on the account to see all the users' crypto accounts available.",
  cryptoPortfolio: 'A presentation of the user’s Crypto assets portfolio.',
  cryptoBuy: 'Buy Crypto assets at a predetermined market price.',
  cryptoSell: 'Sell Crypto assets at a predetermined market price.',
  cryptoTransactions: 'A comprehensive history of all the user’s crypto assets Buy and Sell transactions performed.',
  cryptoAssetsPriceChart: 'A comprehensive history of a Crypto asset’s price offerings.',
};
