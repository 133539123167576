import clsx from "clsx";
import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAppContext } from "../../../../context/DataContext";
import currencyFormatter from "../../../../helpers/currencyFormatter/currencyFormatter";
import { ReactComponent as ProductNotFoundIcon } from "../../../../assets/images/product-not-found-icon.svg";
import { useState } from "react";
import { useEffect } from "react";
import LoadingBar from "../../../common/LoadingBar/LoadingBar";

export const GridView = ({
  products,
  isLoading,
  handleItemClick,
  gridWrapperRef,
}) => {
  const classes = useStyles();
  const [data, setData] = useState(products);
  const { isAppView, isMobile } = useAppContext();

  useEffect(() => {
    setData(products);
  }, [products]);

  if (!products?.length && !isLoading) {
    return <NotFound />;
  }

  {
    !isLoading && !data?.length && !isLoading && <NotFound />;
  }

  return (
    <>
      <div
        className={clsx(
          isMobile || isAppView
            ? classes.gridWrapperMobile
            : classes.gridWrapper
        )}
        ref={gridWrapperRef}
      >
        {data &&
          data?.map((product) => (
            <CardItem
              item={product}
              key={product?.id}
              onClick={() => handleItemClick(product)}
            />
          ))}
      </div>
      <LoadingBar open={isLoading} />
    </>
  );
};

const CardItem = ({ item, onClick }) => {
  const classes = useStyles();
  
  let currencyInLocalStorage = localStorage.getItem("currency") || "USD";

  return (
    <div className={classes.cardWrapper} onClick={onClick}>
      <img
        className={classes.imgWrapper}
        src={item?.images?.[0]}
        alt={item?.name}
      />

      <p className={classes.name}>{item?.name}</p>

      {/* <p className={classes.price}>{item?.weight?.toFixed(2)}g</p> */}

      <p className={classes.price}>
        {currencyFormatter(item?.prices?.[currencyInLocalStorage] || 0)}
      </p>
    </div>
  );
};

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.emptyCartWrapper}>
      <span className={classes.descriptionEmptyScreen}>No products found</span>

      <ProductNotFoundIcon />
    </div>
  );
};

const SkeletonTemplate = () => {
  const classes = useStyles();
  let arr = Array.from(Array(20).keys());

  return (
    <>
      {arr.map((idx) => {
        return (
          <div key={idx} className={classes.skeletonItem}>
            <Skeleton variant="circular" width="160px" height={160} />
            <Skeleton variant="rounded" width="80%" height={30} />
            <Skeleton variant="rounded" width="60%" height={26} />
          </div>
        );
      })}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    gap: "16px",
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
    padding: 8,

    flex: "1 1 auto",

    height: "calc(100dvh - 320px)",
    overflowY: "auto",
  },

  gridWrapperMobile: {
    gap: "16px",
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
    padding: 8,
    //overflowAnchor: "none",

    flex: "1 1 auto",
    overflowY: "auto",
    height: "calc(100dvh - 236px)",
  },

  cardWrapper: {
    display: "grid",
    gridTemplateRows: "170px 32px 1fr",
    width: "100%",
    height: "230px",

    cursor: "pointer",

    "@media (max-width: 768px)": {
      height: "224px",
      gridTemplateRows: "170px 32px 1fr",
      gap: 4,
    },
  },

  imgWrapper: {
    width: "90%",
    height: "100%",
    margin: "auto",
    objectFit: "contain",
    "& span": {
      cursor: "pointer",
    },
    "& img": {
      cursor: "pointer",
    },
  },

  name: {
    margin: 0,
    color: "gray",
    fontSize: "14px",
    // display: 'block',
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    lineHeight: "16px", // Adjust as needed
    maxHeight: "32px", // Approximately 2 lines of text
    display: "-webkit-box",
  },

  price: {
    margin: 0,
    fontWeight: 500,
  },

  skeletonWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10,
  },

  skeletonItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: 4,
  },

  emptyCartWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: 40,

    [theme.breakpoints.down("678")]: {
      gap: 24,
    },
  },

  descriptionEmptyScreen: {
    fontSize: "32px",
    paddingTop: "20px",

    [theme.breakpoints.down("678")]: {
      fontSize: "22px",
    },
  },
}));
