import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import MobileGoldHistory from '../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory';
import NavigationBar from '../../../components/MobileComponents/MobileDashboard/NavigationBar/NavigationBar';
import MobileTotalAndPortfolio from '../../../components/MobileComponents/MobileDashboard/MobileTotalAndPortfolio/MobileTotalAndPortfolio';
import TransactionsNews from '../../../components/MobileComponents/MobileDashboard/TransactionsNews/TransactionsNews';
import TradeGoldAutoInvestButtons from '../../../components/MobileComponents/MobileDashboard/TradeGoldAutoInvest/TradeGoldAutoInvestButtons';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../../components/common/Layout/AppLayout';
import { useAppContext } from '../../../context/DataContext';
import { useTutorialMobileDashboard } from './use-tutorial-mobile-dashboard';

function MobileDashboard({ pool }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { elementProps, isLoading } = useTutorialMobileDashboard();

  const { tutorialStep } = useAppContext();

  function handleTransactionNavigation() {
    navigate(routes.transactions);
  }
  function handleNewsNavigation() {
    navigate(routes.news);
  }

  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileGoldHistory />
        <NavigationBar />
        <MobileTotalAndPortfolio pool={pool} />
        <TradeGoldAutoInvestButtons />
        <TransactionsNews
          leftName={'TRANSACTIONS'}
          rightName={'NEWS'}
          handleLeftPanelNavigation={handleTransactionNavigation}
          handleRightPanelNavigation={handleNewsNavigation}
        />
      </Box>
    </AppLayout>
  );
}

export default MobileDashboard;

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'var(--linear-gradient-grey-background-mobile)',
    '@media (orientation: landscape)': {
      background: 'none',
    },
  },
}));
