import goldPriceService from "../service/goldPrice";

export const handleGetGoldPriceForAPeriod = async (
  period,
  fn,
  setError,
  setPrices,
  setTimes,
  setSmooth
) => {
  try {
    const response = await goldPriceService.getGoldHistoryPriceForPeriod(
      period
    );
    const sortedResponse = response.map((res) => ({
      ...res,
      sortTime: new Date(res.time),
    }));
    sortedResponse.sort((a, b) => a.sortTime - b.sortTime);
    const onlyPrices = response.map((res) => res.price);
    const onlyTimes = response.map((res) => new Date(res.time));
    if (!response?.length) {
      setError(true);
    }
    sessionStorage.setItem("basePricePL", sortedResponse[0]?.price);
    fn(sortedResponse);
    setPrices(onlyPrices);
    setTimes(onlyTimes);
    setSmooth(true);
  } catch (e) {
    setSmooth(true);
    console.error(e);
  }
};
