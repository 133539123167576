import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import '../../../../styles/global.fonts.css';
import { useState } from 'react';
import Divider from '@mui/material/Divider';
import HorizontalStepperWeb from './HorizontalStepperWeb';
import FirstStepContentWeb from './FirstStepContentWeb';
import SecondStepContentWeb from './SecondStepContentWeb';
import ThirdStepContentWeb from './ThirdStepContentWeb';
import MessageContentWeb from './MessageContentWeb';
import LoadingBar from '../../../common/LoadingBar/LoadingBar';
import { redeemService } from '../../../../service/redeem';
import { useAppContext } from '../../../../context/DataContext';
import { isMarketOpen } from '../../../../helpers/isMarketOpen';
import { messages } from '../../../../constants/popupMessages';
import { NON_VERIFIED } from '../../../../constants/globalconstants';
import { handlePleaseLogin } from '../../../../helpers/pleaseLoginOrRegisterForDemo';
import { errorHandlingWrapper } from '../../../../helpers/errorHandlingWrapper';
import isVerified from '../../../../helpers/isVerified';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const RedeemGoldPopup = ({
  isPopupOpen,
  setIsPopupOpen,
  availableGoldVariants,
  getStoreFees,
  deliveryFee,
  serviceFee,
  total,
  userDetails,
  setIsPleaseLoginPopupOpen,
  isRedeemFeeLoading,
  activeStep,
  setActiveStep,
  showTBC,
  setServiceFee,
}) => {
  const {
    shippingCity,
    shippingAddress,
    shippingCountry,
    country,
    shippingPostalCode,
    address,
    shippingState,
    city,
    state,
    postalCode,
  } = userDetails;
  const addresses = [
    {
      ID: shippingCountry ? shippingCountry?.id : country?.id,
      addressLine: shippingCountry ? shippingAddress : address,
      city: shippingCountry ? shippingCity : city,
      countryName: shippingCountry ? shippingCountry?.name : country?.name,
      countryCode: shippingCountry ? shippingCountry?.countryCode : country?.countryCode,
      shippingState: shippingCountry ? shippingState : state,
      zipCode: shippingCountry ? shippingPostalCode : postalCode,
    },
  ];
  const classes = useStyles();
  const { setNonVerifiedPopUpOpen, setProceedRedeemOrderClicked } = useAppContext();
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [quantity, setQuantity] = useState();
  const [selectedAddressId, setSelectedAddressId] = useState(addresses[0]?.ID);
  const [chosenAddress, setChosenAddress] = useState(addresses[0]);
  const [partNumber, setPartNumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const stepCounter = activeStep < 3 ? 'Step ' + (activeStep + 1) : message === 'success' ? 'Done' : '';

  const handleClose = () => {
    setServiceFee(0);
    setActiveStep(0);
    setIsPopupOpen(false);
  };
  const handleChosenAddress = (address) => {
    setChosenAddress(address);
  };

  const handleSubmit = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      handleClose();
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const redeemData = {
        totalPrice: total,
        products: [
          {
            quantity: 1,
            partNumber: partNumber,
          },
        ],
        useCoins: true,
        useFiat: false,
        shippingInformation: {
          city: chosenAddress.city,
          address: chosenAddress.addressLine,
          zip: chosenAddress.zipCode,
          countryCode: chosenAddress.countryCode,
          ...(chosenAddress.countryCode === 'CA' && {
            state: chosenAddress.shippingState,
          }),
        },
      };
      setLoading(true);
      try {
        await redeemService.createRedeem(redeemData);
        setMessage('success');
        setProceedRedeemOrderClicked(true);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setError(customError?.message);
        setMessage('error');
        setProceedRedeemOrderClicked(true);
      } finally {
        setIsAddNewAddress(false);
        setSelectedAddressId(addresses?.[0]?.ID || userDetails.shippingCountry?.id);
        setChosenAddress(addresses[0]);
        setPartNumber('');
        setLoading(false);
      }
    } else {
      setIsAddNewAddress(false);
      setSelectedAddressId(addresses[0].ID || userDetails.shippingCountry?.id);
      setChosenAddress(addresses[0]);
      setPartNumber('');
      setMessage('error');
      setError(messages.marketClosedHTML);
      setProceedRedeemOrderClicked(true);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.popupTitle}>
            <div className={classes.stepTitle}>REDEEM GOLD</div>
            {<Divider sx={{ height: '28px' }} orientation="vertical" flexItem />}
            <div className={classes.activeStep}>{stepCounter}</div>
          </BootstrapDialogTitle>
          <DialogContent>
            <div className={classes.stepperContainer}>
              {activeStep < 3 ? (
                <HorizontalStepperWeb
                  stepsComponents={[
                    <FirstStepContentWeb
                      key={quantity}
                      quantity={quantity}
                      partNumber={partNumber}
                      setQuantity={setQuantity}
                      availableGoldVariants={availableGoldVariants}
                      setPartNumber={setPartNumber}
                    />,
                    <SecondStepContentWeb
                      key={quantity}
                      selectedAddressId={selectedAddressId}
                      setSelectedAddressId={setSelectedAddressId}
                      isAddNewAddress={isAddNewAddress}
                      setIsAddNewAddress={setIsAddNewAddress}
                      handleChosenAddress={handleChosenAddress}
                      addresses={addresses}
                      partNumber={partNumber}
                    />,
                    <ThirdStepContentWeb
                      key={quantity}
                      chosenAddress={chosenAddress}
                      quantity={quantity}
                      serviceFee={serviceFee}
                      deliveryFee={deliveryFee}
                      total={total}
                      isRedeemFeeLoading={isRedeemFeeLoading}
                      showTBC={showTBC}
                    />,
                  ]}
                  quantity={quantity}
                  activeStep={activeStep}
                  partNumber={partNumber}
                  deliveryFee={deliveryFee}
                  setActiveStep={setActiveStep}
                  isAddNewAddress={isAddNewAddress}
                  getStoreFees={getStoreFees}
                  handleSubmit={handleSubmit}
                  isRedeemFeeLoading={isRedeemFeeLoading}
                  setIsAddNewAddress={setIsAddNewAddress}
                  chosenAddress={chosenAddress}
                  setServiceFee={setServiceFee}
                />
              ) : loading ? (
                <LoadingBar open={loading} />
              ) : (
                <MessageContentWeb type={message} error={error} handleClose={handleClose} />
              )}
            </div>
          </DialogContent>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default RedeemGoldPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '24px',
      maxWidth: '700px',
      width: '100%',
      minWidth: '302px',
    },
  },
  popupContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    minHeight: '360px',
  },
  popupTitle: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3%',
  },
  stepTitle: {
    fontSize: 'clamp(16px, 2vw, 20px)',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '10px',
  },
  activeStep: {
    display: 'flex',
    fontSize: 'clamp(12px, 2vw, 16px)',
    fontWeight: '400',
    fontFamily: theme.typography.fontFamily,
    color: '#BFBFBF',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '10px',
  },
  stepperContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
