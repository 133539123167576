import serviceTransaction from "../service/transactions";

export const handleGetOrderWebTransactions = async (
  fn,
  page,
  setAllPages,
  type,
  setError
) => {
  let pageSize;
  try {
    const response = await serviceTransaction.getAllOrderTransactions(
      page,
      pageSize,
      type
    );
    if (!response.data.length) {
      setError(true);
    }

    let filteredTransactions = response?.data.map(({ ...rest }) => {
      let changeId = (2000000000 + rest.id).toString();

      if (rest?.productItemCode === "P002") {
        changeId = (2000000000 + rest.id).toString();
      } else if (rest?.productItemCode === "P003") {
        changeId = (3000000000 + rest.id).toString();
      } else {
        console.log("type of transactions should be checked");
        changeId = rest.id;
      }
      if (type === "stake") {
        return {
          transactionId: rest.transactionId,
          id: changeId,
          date: rest.date,
          volume: rest.volume,
          productItemCode: rest.productItemCode,
          status: rest.status,
          expDate: rest.expDate,
          price: rest.price,
          currency: rest.currency,
          tradeAccountId: rest.tradeAccountId,
          paidReward: rest.paidReward.toFixed(2),
          upcomingReward: rest.upcomingReward.toFixed(2),
        };
      } else if (type === "vault") {
        return {
          transactionId: rest.transactionId,
          id: changeId,
          date: rest.date,
          volume: rest.volume,
          productItemCode: rest.productItemCode,
          status: rest.status,
          currency: rest.currency,
          tradeAccountId: rest.tradeAccountId,
          price: rest.price,
          fee: rest.paidReward.toFixed(2),
        };
      } else {
        console.log("The type of this transaction is unknown or incorrect");
        return 0;
      }
    });

    filteredTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));

    if (page === 0) {
      filteredTransactions = filteredTransactions.slice(0, 3);
    }

    fn(filteredTransactions);
    setAllPages(response.pages);
  } catch (e) {
    setError(true);
    console.log(e);
  }
};

export const handleGetOrderMobileTransactions = async (
  fn,
  page,
  pageSize,
  setAllPages,
  type,
  setError,
  isProceedOrderClicked
) => {
  try {
    const response = await serviceTransaction.getAllOrderTransactions(
      page,
      pageSize,
      type
    );
    if (!response.data.length) {
      setError(true);
    }
    const newTransactions = response?.data.map(({ ...rest }) => {
      let changeId = (2000000000 + rest.id).toString();

      if (rest?.productItemCode === "P002") {
        changeId = (2000000000 + rest.id).toString();
      } else if (rest?.productItemCode === "P003") {
        changeId = (3000000000 + rest.id).toString();
      } else {
        console.log("type of transactions should be checked");
        changeId = rest.id;
      }

      if (type === "stake") {
        return {
          transactionId: rest.transactionId,
          id: changeId,
          date: rest.date,
          volume: rest.volume,
          productItemCode: rest.productItemCode,
          status: rest.status,
          expDate: rest.expDate,
          price: rest.price,
          currency: rest.currency,
          tradeAccountId: rest.tradeAccountId,
          paidReward: rest.paidReward.toFixed(2),
          upcomingReward: rest.upcomingReward.toFixed(2),
        };
      } else if (type === "vault") {
        return {
          transactionId: rest.transactionId,
          id: changeId,
          date: rest.date,
          volume: rest.volume,
          productItemCode: rest.productItemCode,
          status: rest.status,
          currency: rest.currency,
          tradeAccountId: rest.tradeAccountId,
          price: rest.price,
          fee: rest.paidReward.toFixed(2),
        };
      } else {
        console.log("The type of this transaction is unknown or incorrect");
        return 0;
      }
    });
    newTransactions.sort((b, a) => new Date(a.date) - new Date(b.date));

    let filteredNewTransactions;

    if (type === "vault") {
      if (isProceedOrderClicked) {
        const newVaultTransactions =
          await serviceTransaction.getAllOrderTransactions(
            1,
            pageSize,
            "vault"
          );
        filteredNewTransactions = newVaultTransactions.data.map(
          ({ ...rest }) => {
            return {
              transactionId: rest.transactionId,
              id: (rest.id + 2000000000).toString(),
              date: rest.date,
              volume: rest.volume,
              productItemCode: rest.productItemCode,
              status: rest.status,
              currency: rest.currency,
              tradeAccountId: rest.tradeAccountId,
              price: rest.price,
              fee: rest.paidReward.toFixed(2),
            };
          }
        );
      }
    } else if (type === "stake") {
      if (isProceedOrderClicked) {
        const newStakeTransactions =
          await serviceTransaction.getAllOrderTransactions(
            1,
            pageSize,
            "stake"
          );
        filteredNewTransactions = newStakeTransactions.data.map(
          ({ ...rest }) => {
            return {
              transactionId: rest.transactionId,
              id: (rest.id + 3000000000).toString(),
              date: rest.date,
              volume: rest.volume,
              productItemCode: rest.productItemCode,
              status: rest.status,
              expDate: rest.expDate,
              currency: rest.currency,
              price: rest.price,
              tradeAccountId: rest.tradeAccountId,
              paidReward: rest.paidReward.toFixed(2),
              upcomingReward: rest.upcomingReward.toFixed(2),
            };
          }
        );
      }
    }
    fn((prevTransactions) => {
      let combinedArray;
      if (isProceedOrderClicked) {
        combinedArray = [...filteredNewTransactions, ...newTransactions].filter(
          (transaction, index, self) =>
            self.findIndex((t) => t.id === transaction.id) === index
        );
      } else {
        combinedArray = [...prevTransactions, ...newTransactions].filter(
          (transaction, index, self) =>
            self.findIndex((t) => t.id === transaction.id) === index
        );
      }
      return combinedArray;
    });
    setAllPages(response.pages);
  } catch (e) {
    console.log(e);
  }
};
