import React, { useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";

import { GridView } from "./components/GridView";
import { ListView } from "./components/ListView";
import { ViewSelector } from "./components/ViewSelector";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { useAppContext } from "../../../context/DataContext";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { handlePleaseLogin } from "../../../helpers/pleaseLoginOrRegisterForDemo";
import PleaseLoginPopUp from "../../common/Popups/PleaseLoginPopup/PleaseLoginPopup";

function StoreGrid({
  setSort,
  setPage,
  setSelectedItem,
  setProductsView,
  setSelectedCategory,

  sort,
  products,
  isLoading,
  gridWrapperRef,
  listWrapperRef,
  categories,
  productsView,
  totalProducts,
  handleAddToCard,
  selectedCategory,
  isProductLoading,
  isCategoriesLoading,
  setIsAddToCardTriggered,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAppView, isMobile } = useAppContext();
  const [isCatOpen, setIsCatOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const isWeb = !isAppView && !isMobile;
  const isListView = productsView === "list";
  const isGridView = productsView === "grid";

  const handleItemClick = (product) => {
    setSelectedItem(product);

    if (searchParams.has("id")) {
      searchParams.delete("id");
    }

    searchParams.append("id", product?.part_number);

    navigate({ search: `?${searchParams.toString()}` });
  };

  return (
    <div style={{ gridArea: "grid" }} className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.left}>
          {isWeb && !isCategoriesLoading && !isLoading && products && (
            <span className={classes.productsFound}>
              {totalProducts} products
            </span>
          )}

          {(isAppView || (!isWeb && !isCategoriesLoading && products)) && (
            <CategoriesDropdown
              open={isCatOpen}
              setPage={setPage}
              setOpen={setIsCatOpen}
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          )}
        </div>

        <div className={classes.right}>
          <SortDropdown
            sort={sort}
            open={isSortOpen}
            setSort={setSort}
            setOpen={setIsSortOpen}
            setPage={setPage}
          />

          <ViewSelector
            isWeb={isWeb}
            type={productsView}
            setProductsView={setProductsView}
          />
        </div>
      </div>

      {isGridView && (
        <GridView
          products={products}
          isLoading={isLoading}
          handleItemClick={handleItemClick}
          gridWrapperRef={gridWrapperRef}
        />
      )}

      {isListView && (
        <ListView
          products={products}
          isLoading={isLoading}
          handleAddToCard={handleAddToCard}
          handleItemClick={handleItemClick}
          setIsAddToCardTriggered={setIsAddToCardTriggered}
          listWrapperRef={listWrapperRef}
        />
      )}
      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
    </div>
  );
}

const SortDropdown = ({ open, setOpen, sort, setSort, setPage }) => {
  const classes = useStyles();
  const autocompleteRef = useRef(null);

  const sortVariants = [
    {
      name: "Name: A-Z",
      label: "Name A-Z",
      icon: <ArrowUpwardIcon />,
      arrow: " ↑",
    },
    {
      name: "Name: Z-A",
      label: "Name Z-A",
      icon: <ArrowDownwardIcon />,
      arrow: " ↓",
    },
    {
      name: "Price: Low to High",
      label: "Price Low",
      icon: <ArrowUpwardIcon />,
      arrow: " ↑",
    },
    {
      name: "Price: High to Low",
      label: "Price High",
      icon: <ArrowDownwardIcon />,
      arrow: " ↓",
    },
    {
      name: "Fine Weight: Low to High",
      label: "Weight Low",
      icon: <ArrowUpwardIcon />,
      arrow: " ↑",
    },
    {
      name: "Fine Weight: High to Low",
      label: "Weight High",
      icon: <ArrowDownwardIcon />,
      arrow: " ↓",
    },
  ];

  const defaultOptionLabel = {
    name: "Name: A-Z",
    label: "Name A-Z",
    icon: <ArrowUpwardIcon />,
    arrow: " ↑",
  };

  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  const handleTouchStart = () => {
    if (!open && autocompleteRef.current) {
      setOpen(true); // Open Autocomplete on touch start if it's closed
    }
  };

  return (
    <Autocomplete
      id="combox-box-demo"
      options={sortVariants}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params?.inputProps,
            onBlur: (e) => e.target.blur(),
            onFocus: (e) => e.target.blur(),
          }}
          label="Sort"
        />
      )}
      value={sort}
      className={classes.textField}
      onChange={(event, newValue) => {
        setSort({
          name: newValue?.name,
          label: newValue?.label,
          arrow: newValue?.arrow,
        });
        setPage(0);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => {
        if (option.name === "Name ASC") {
          return defaultOptionLabel;
        } else {
          return option.label || defaultOptionLabel?.label;
        }
      }}
      renderOption={(props, option) => {
        return <li {...props}>{option.label}</li>;
      }}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      disableClearable
    />
  );
};

const CategoriesDropdown = ({
  open,
  setPage,
  setOpen,
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const classes = useStyles();
  const defaultCategory = { name: "" };

  const handleClickAway = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    if (open) {
      setOpen(false);
    }
  };

  return (
    <Autocomplete
      id="combefefeo-box-demo"
      className={classes.textField}
      options={[defaultCategory, ...categories]}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name || "Bullions"}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="Category"
          inputProps={{
            ...params.inputProps,
            readOnly: true,
            onBlur: (e) => e.target.blur(),
            onFocus: (e) => e.target.blur(),
          }}
          readOnly
        />
      )}
      value={selectedCategory ? { name: selectedCategory } : defaultCategory}
      onChange={(event, newValue) => {
        setPage(0);
        setSelectedCategory(newValue?.name);
      }}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      autoHighlight
      disableClearable
      disableInput
      clearOnEscape={false}
    />
  );
};

export default StoreGrid;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    flex: "0 1",

    paddingTop: "10px",
    paddingInline: "40px",

    [theme.breakpoints.down("768")]: {
      display: "grid",
      gap: 10,
      paddingInline: "10px",
      gridTemplateColumns: "124px 1fr",
    },
  },

  left: {
    fontWeight: 600,
  },

  productsFound: {
    textTransform: "uppercase",
  },

  textField: {
    width: "200px",
    [theme.breakpoints.down("768")]: {
      width: "100%",
    },

    "& .MuiInputLabel-root": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
      fontSize: "var(--font-size-16)",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      marginBottom: "10px",
      color: "#A0A0A0",
      maxHeight: "50px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CFCFCF",
    },
  },

  right: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: 20,

    [theme.breakpoints.down("768")]: {
      justifyContent: "space-between",
      gap: 8,

      "& .MuiAutocomplete-root": {
        maxWidth: "170px",
      },
    },
  },
}));
