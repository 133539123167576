import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import MobileGoldHistory from "../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory";
import StakeInfo from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/StakeInfo/StakeInfo";
import MobileStakingTotalMonthlyPool from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileStakingTotalMonthlyPool/MobileStakingTotalMonthlyPool";
import MobileStakingButtons from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileStakingButtons/MobileStakingButtons";
import { routes } from "../../../routes";
import { useState } from "react";
import { useEffect } from "react";
import { useAppContext } from "../../../context/DataContext";
import AppLayout from "../../../components/common/Layout/AppLayout";

function MobileStaking({ pool }) {
  const classes = useStyles();
  const { setIsInHistory } = useAppContext();
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    setIsInHistory(false);
    setShowComponent(true);
  }, []);
  return (
    <AppLayout>
      {showComponent && (
        <Box className={classes.container}>
          <MobileNavigationBack page={"Yielding"} route={routes.dashboard} />
          <MobileGoldHistory />
          <StakeInfo />
          <MobileStakingTotalMonthlyPool pool={pool} />
          <Box className={classes.actionsButtons}>
            <MobileStakingButtons
              firstButtonName={"YIELD GOLD"}
              category="stake"
              secondButtonText="SHOW YIELDING HISTORY"
            />
          </Box>
        </Box>
      )}
    </AppLayout>
  );
}

export default MobileStaking;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    background: "var(--linear-gradient-grey-background-mobile)",
  },
  actionsButtons: {
    "@media (orientation: landscape)": {
      paddingBottom: "1rem",
    },
  },
}));
