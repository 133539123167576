import { userService } from "../service/user";

export async function handleGetUserCrmTransactions(fn, setLoading) {
  try {
    const transactions = await userService.getUserCrmTransactions(setLoading);
    const refactoredTransactions = transactions.map((t) => {
      return {
        ...t,
        date: new Date(t.date).getTime(),
      };
    });
    fn(refactoredTransactions);
    return transactions;
  } catch (error) {
    console.error(error);
  }
}
