import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import { createStyles } from "@mui/material";
import IButton from "../../../common/IButton/IButton";
import { tooltipContent } from "../../../../constants/tooltipTexts";

function TotalBalanceInAutoInvest({
  rightText,
  leftText,
  rightValue,
  leftValue,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <IButton tooltipTitle={tooltipContent.autoInvestOrdersAndMonthlyCoins} />
      <Box>
        <Grid container>
          <Grid item xs={6} md={6} sm={6} xl={6} lg={6}>
            <Box className={classes.contentAligner}>
              <Box className={classes.text}>{leftText}</Box>
              <Box className={classes.price}>{rightValue}</Box>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sm={6} xl={6} lg={6}>
            <Box className={classes.contentAligner}>
              <Box className={classes.text}>{rightText}</Box>
              <Box className={classes.price}>{leftValue}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TotalBalanceInAutoInvest;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: "relative",
      backgroundColor: "#F1F1F1",
      borderRadius: "24px",
      letterSpacing: "normal",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      height: "100%",
    },
    contentAligner: {
      width: "auto",
      alignItems: "center",
      justifyContent: "center",
      letterSpacing: "normal",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingTop: "2rem",
    },
    price: {
      fontFamily: theme.typography.fontFamily,
      color: "var(--gold)",
      fontSize: "2.4rem",
      fontWeight: 700,
      alignItems: "left",
      textAlign: "left",
      width: "100%",
      height: "100%",
      letterSpacing: "normal",
      [theme.breakpoints.down("1600")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: "var(--font-size-18)",
      },
    },
    buttonContainer: {
      fontFamily: theme.typography.fontFamily,
      textAlign: "right",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingLeft: "2rem",
      mingWidth: "10px !important",
      letterSpacing: "normal",
      paddingBottom: "1rem",
      [theme.breakpoints.down("1050")]: {
        "& button": {
          padding: 0,
          margin: 0,
          mingWidth: "10px !important",
        },
        mingWidth: "10px !important",
      },

      [theme.breakpoints.down("950")]: {
        "& button": {
          padding: 1,
          fontSize: "3px",
          "& span": {
            padding: 0,
            mingWidth: "10px !important",
            // width:"2px"
          },
        },
        width: "auto",
      },
      [theme.breakpoints.down("920")]: {
        "& button": {
          padding: 1,
          fontSize: "2px",
          "& span": {
            padding: 0,
            mingWidth: "10px !important",
            // width:"2px"
          },
        },
        width: "auto",
      },
      [theme.breakpoints.down("900")]: {
        "& button": {
          fontSize: "2px",
          marginTop: "-5px",
          "& span": {
            padding: 0,
            //mingWidth: "10px !important",
          },
        },
        width: "auto",
      },
    },
    text: {
      fontFamily: theme.typography.fontFamily,
      letterSpacing: "normal",
      width: "100%",
      fontSize: "var(--font-size-16)",
      textAlign: "left",
      [theme.breakpoints.down("1400")]: {
        fontSize: "var(--font-size-11)",
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: "0.5rem",
      },
    },
    righText: {
      color: "#747474",
      paddingLeft: "5px",
      letterSpacing: "normal",
    },
    contentDivider: {
      marginTop: "1%",
    },
    btnLnController: {
      width: "auto",
    },
  })
);
