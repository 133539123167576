export function generateCurrencySymbol(type) {
  let currencyType = localStorage.getItem("currency") || "USD";
  let currencySymbol = "$";
  if (type) {
    currencyType = type;
  }
  if (currencyType === "EUR") {
    currencySymbol = "€";
  } else if (currencyType === "USD") {
    currencySymbol = "$";
  }
  return currencySymbol;
}
