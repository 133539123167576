import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import currencyFormatter from "../../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../../constants/globalconstants";
import { useAppContext } from "../../../../context/DataContext";
ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

function Gauge1() {
  const classes = useStyles();
  const {
    goldBidPriceForGroup,
    IsSocketReconnecting,
    portfolioAllocationData,
    activeTradeAccountBalance,
  } = useAppContext();

  const balance = isNaN(Number(activeTradeAccountBalance))
    ? 0
    : Number(activeTradeAccountBalance);

  const totalCoins = portfolioAllocationData.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const getTotalBalance = () => {
    const goldHoldings = totalCoins * goldBidPriceForGroup;
    const acountValue = balance ? balance + goldHoldings : 0;
    return acountValue;
  };

  const totalBalance = getTotalBalance();

  const data1 = {
    datasets: [
      {
        label: "# of Votes",
        data: [totalBalance, 3, 75, IsSocketReconnecting],
        backgroundColor: [
          "rgba(0, 0, 0, 1)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 0)",
          "rgba(255, 255, 255, 0)",
          "rgba(255, 255, 255, 0)",
        ],
        borderWidth: 4,
        circumference: 180,
        rotation: 270,
      },
    ],
  };
  const textInDoughnut = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();

      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      const chartHeight = chart.chartArea.height;
      const fontSize = Math.floor(chartHeight * 0.09);
      const smallerFontSize = Math.floor(chartHeight * 0.07);

      ctx.font = `${fontSize}px sans-serif`;
      const gradient = ctx.createLinearGradient(
        xCoor,
        yCoor + 80,
        xCoor,
        yCoor - 80
      );
      gradient.addColorStop(0, "rgba(255, 240, 164, 1)");
      gradient.addColorStop(1, "rgba(255, 240, 164, 0)");

      ctx.beginPath();
      //GREAT CODE !!!!
      ctx.arc(xCoor, yCoor, chartHeight * 0.44, Math.PI, 2 * Math.PI);
      ctx.fillStyle = gradient;
      ctx.fill();

      ctx.beginPath();
      ctx.fillStyle = "#8AC007";
      ctx.textBaseline = "middle";
      ctx.fill();

      const yPosition1 = yCoor - chartHeight * 0.2;
      ctx.font = `${smallerFontSize}px sans-serif`;
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.fillText(`Total Balance`, xCoor, yPosition1);

      // chartHeight
      const yPosition2 = yCoor - chartHeight * 0.08;
      ctx.font = `bolder ${fontSize}px sans-serif`;
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.fillText(
        `${
          data.datasets[0].data?.[3]
            ? currencyFormatter(0)
            : currencyFormatter(data.datasets[0].data[0] || 0)
        }`,
        xCoor,
        yPosition2
      );
    },
  };
  const options1 = {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    cutout: "97%",
    responsive: true,
    events: null,
  };

  const data2 = {
    datasets: [
      {
        data: [23, 1, 76],
        backgroundColor: ["rgba(0,0,0,0)", "rgba(0, 0, 0, 1)", "rgba(0,0,0,0)"],
        borderColor: [
          "rgba(0, 0, 0 ,0)",
          "rgba(0, 0, 0, 1)",
          "rgba(0, 0, 0 ,0)",
        ],
        borderWidth: 4,
        circumference: 180,
        rotation: 270,
        borderRadius: 100,
      },
    ],
  };

  const options2 = {
    cutout: "97%",
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    responsive: true,
    events: null,
  };

  return (
    <div className={classes.outer}>
      <Doughnut
        className={classes.canvas}
        data={data1}
        options={options1}
        plugins={[textInDoughnut]}
      />
      <Doughnut className={classes.canvas} data={data2} options={options2} />
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    outer: {
      position: "relative",
      width: "auto",
      height: "auto",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
    canvas: {
      position: "absolute",
      marginTop: "-19%",
    },
  })
);

export default Gauge1;
