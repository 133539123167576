import React from 'react';
import { makeStyles } from '@mui/styles';
import { useStore } from 'react-redux';

import PLCrypto from '../../../common/PLCrypto/PLCrypto';
import { useCryptoContext } from '../../../../context/CryptoContext';
import { getCryptoIndexDetails } from '../../../../helpers/getCryptoIndexDetails';
import currencyFormatter from '../../../../helpers/currencyFormatter/currencyFormatter';
import { useEffect } from 'react';
import { useState } from 'react';
import InstrumentNameTooltip from '../../../WebComponents/PortfolioAssets/components/InstrumentNameTooltip';

const Asset = ({ asset }) => {
  const classes = useStyles();
  const [instrumentDataBidPrice, setInstrumentDataBidPrice] = useState(0);
  const { userMarkets, setIsBuySellOpened, setActiveInstrument } = useCryptoContext();

  const store = useStore();

  const amount = asset?.amount;
  const assetId = asset?.assetId?.toUpperCase();
  const instrument = `${asset?.assetId}_usdt`;
  const avgPrice = asset?.avgPrice;
  const indexDetails = getCryptoIndexDetails(assetId);
  const price = instrumentDataBidPrice;
  const decimals = (price && price?.split('.')?.[1]?.length) || 0;

  useEffect(() => {
    const fetchPriceFromRedux = () => {
      const price = store.getState().crypto.instruments[instrument]?.bid || 0;

      setInstrumentDataBidPrice(price);
    };

    fetchPriceFromRedux(); // Initial fetch
    const intervalId = setInterval(fetchPriceFromRedux, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [store, instrument]);

  const handleSelectInstrument = () => {
    userMarkets?.forEach((market) => {
      if (market?.marketId === `${asset?.assetId}_usdt`) {
        setActiveInstrument(market);
        setIsBuySellOpened(true);
      }
    });
  };

  return (
    <div className={classes.assetWrapper}>
      <div className={classes.top}>
        <div className={classes.left} onClick={handleSelectInstrument}>
          <div className={classes.imageWrapper}>
            <img width="18" height="18" src={indexDetails?.url} alt={indexDetails?.name} />
          </div>

          <div className={classes.indexes}>
            <span className={classes.insturmentIndex}>{assetId}</span>
            <InstrumentNameTooltip name={indexDetails?.name} className={classes.instrumentName} />
          </div>
        </div>

        <div className={classes.pl}>
          {price && <PLCrypto previousPrice={Number(avgPrice || 1000)} lastPrice={Number(price || 0)} />}
        </div>
      </div>

      <div className={classes.middle}></div>

      <div className={classes.bottom}>
        <div className={classes.bottomLeft}>
          <span className={classes.portfolioLabel}>Portfolio</span>

          <span className={classes.portfolioPrice}>
            {price
              ? currencyFormatter(Number(price || 0) * amount, 'transactions', 'USD', false, false, decimals)
              : '-'}
          </span>
        </div>

        <div className={classes.bottomRight}>
          <span className={classes.assetVolume}>
            {amount} {assetId}
          </span>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  assetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '8px',
    borderRadius: 4,
    backgroundColor: 'var(--white)',
    background: 'linear-gradient(to top, #FFFAFA, #F2F2F2)',
    border: '1px solid #ECECEC',
    minWidth: 150,
  },

  top: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '2fr 60px',
    gap: 4,
  },

  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: 2,
    width: 23,
    height: 23,
    background: 'white',
    borderRadius: '6px',
  },

  left: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '27px 1fr',

    cursor: 'pointer',
  },

  indexes: {
    display: 'flex',
    flexDirection: 'column',

    paddingLeft: 4,
    overflow: 'hidden',
  },

  insturmentIndex: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'left',
  },
  instrumentName: {
    fontSize: 10,
    color: '#5D5C5D',
    textAlign: 'left',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  middle: {
    height: '1px',
    width: '100%',
    backgroundColor: '#BEBEBE',
  },

  bottom: {
    display: 'grid',
    alignItems: 'flex-end',
    gridTemplateColumns: '1fr 1fr',
  },

  bottomLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 4,
  },

  portfolioLabel: {
    fontSize: 10,
    color: '#BEBEBE',
    textAlign: 'left',
  },

  portfolioPrice: {
    fontSize: 12,
    fontWeight: 700,
    textAlign: 'left',
  },

  bottomRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  assetVolume: {
    fontSize: 10,
    color: '#5D5C5D',
    lineHeight: '18px',
    textAlign: 'right',
  },
}));

export default Asset;
