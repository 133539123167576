import { userService } from "../service/user";

export async function getWithDrawIframe(fn, setLoading) {
  try {
    const url = await userService.getWithDraw(setLoading);
    fn(url);
  } catch (error) {
    console.error(error);
  }
}
