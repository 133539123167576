import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider } from "@mui/material";
import clsx from "clsx";
import CustomButton from "../../../common/CustomButton/CustomButton";
import { tooltipContent } from "../../../../constants/tooltipTexts";
import { useAppContext } from "../../../../context/DataContext";

function TransactionsNews({
  leftName,
  rightName,
  handleLeftPanelNavigation,
  handleRightPanelNavigation,
}) {
  const classes = useStyles();
  const { tutorialStep } = useAppContext();

  return (
    <Box className={classes.container}>
      <Box
        id="transactions-card"
        className={classes.cardContainer}
        onClick={handleLeftPanelNavigation}
      >
        <div className={classes.buttonContainer}>
          <CustomButton
            type="moreinfo"
            buttonText="i"
            tooltipTitle={
              leftName === "TRANSACTIONS"
                ? tooltipContent.transactionsTable
                : tooltipContent.vaultingHistory
            }
            isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <Box
          className={
            leftName === "VAULTING HISTORY"
              ? clsx(classes.vaultingTitle, classes.text)
              : clsx(classes.title, classes.text)
          }
        >
          {leftName}
        </Box>
        <Divider className={classes.divider}></Divider>
        <Box
          id="see-all-label"
          className={clsx(classes.text, classes.textFontSize)}
        >
          See All
        </Box>
      </Box>
      <Box
        id="news-card"
        className={classes.cardContainer}
        onClick={handleRightPanelNavigation}
      >
        <div className={classes.buttonContainer}>
          <CustomButton
            type="moreinfo"
            buttonText="i"
            tooltipTitle={
              rightName === "NEWS"
                ? tooltipContent.news
                : tooltipContent.certificates
            }
            isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <Box className={clsx(classes.title, classes.text)}>{rightName}</Box>
        <Divider className={classes.divider}></Divider>
        <Box
          className={clsx(classes.text, classes.textFontSize)}
          id="view-all-label"
        >
          View All
        </Box>
      </Box>
    </Box>
  );
}

export default TransactionsNews;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: "1 1 auto",
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (orientation: landscape)": {
      paddingBottom: "1rem",
    },
  },
  cardContainer: {
    position: "relative",
    borderRadius: "16px",
    backgroundColor: "var(--white)",
    width: "42%",
    minHeight: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("350")]: {
      width: "48.5%",
    },
    paddingTop: "0.25rem",
    paddingBottom: "0.45rem",
    cursor: "pointer",
    border: "1px solid var(--grey-light)",
  },
  buttonContainer: {
    position: "relative",
    bottom: "9px",
    left: "3px",
  },
  divider: {
    marginLeft: "0.8rem",
    marginRight: "0.8rem",
    marginTop: "0.3rem",
    marginBottom: "0.3rem",
    borderBottomWidth: 2,
    borderColor: "var(--black)",
  },
  title: {
    fontSize:
      "clamp(var(--font-size-13), var(--font-size-15), var(--font-size-16))",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  vaultingTitle: {
    width: "100% !important",
    fontSize:
      "clamp(var(--font-size-13), var(--font-size-15), var(--font-size-16))",
    fontWeight: "bold",
    textTransform: "uppercase",
    [theme.breakpoints.down("416")]: {
      paddingTop: "4px",
      fontSize: "12.5px",
    },
    [theme.breakpoints.down("370")]: {
      fontSize: "11.5px",
    },
  },
  text: {
    textAlign: "left",
    paddingLeft: "0.8rem",
    width: "50%",
  },
  textFontSize: {
    fontSize:
      "clamp(var(--font-size-13), var(--font-size-14), var(--font-size-15))",
  },
}));
