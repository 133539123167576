export const handleInputKeyUp = (event) => {
  // double parsing for preventing entering 0 at the front of the value
  const value = String(event.target.value);
  const intValue = parseInt(value);
  if (isNaN(intValue) || value !== String(intValue)) {
    // Prevent updating the input value if it results in NaN or mismatch
    event.preventDefault();
    event.target.value = intValue ? String(intValue) : ''; // Set value to intValue if it's a number, otherwise, set it to an empty string
  }
};

export const handleInputKeyDown = (event) => {
  const { key } = event;
  if (
    !/^[0-9]$/.test(key) && // Allow only digits
    key !== 'Backspace' &&
    key !== 'Delete' &&
    key !== 'ArrowLeft' &&
    key !== 'ArrowRight' &&
    !((event.ctrlKey || event.metaKey) && key === 'a') &&
    !((event.ctrlKey || event.metaKey) && key === 'r')
  ) {
    event.preventDefault();
  }
};

export const customValidation = (event) => {
  const { key, target } = event;
  const value = target?.value;
  const hasTwoDecimals = value.split('.')?.[1]?.length > 1;

  if (key === '-' || value.includes('-')) {
    event.preventDefault();
    return;
  }

  if (!value && key === '.') {
    event.preventDefault();
  }

  if (value.length > 11 && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
    event.preventDefault();
  }

  if (
    ((hasTwoDecimals || !/^[0-9.]$/.test(key)) && // Allow digits and decimal point
      key !== 'Backspace' &&
      key !== 'Delete' &&
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight' &&
      !((event.ctrlKey || event.metaKey) && key === 'a') &&
      !((event.ctrlKey || event.metaKey) && key === 'r')) ||
    (key === '0' && value === '0.00')
  ) {
    event.preventDefault();
  }
};
