import React, { lazy } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, createStyles } from '@mui/material';

const AssetItem = lazy(() => import('./components/AssetItem'));
import AssetSearch from './components/AssetSearch';
import AssetsHeader from './components/AssetsHeader';

import { useCryptoAssets } from './hooks/use-crypto-assets';
import AssetsTabs from './components/AssetsTabs';
import { useState } from 'react';

const CryptoAssets = () => {
  const classes = useStyles();
  const { search, setSearch, userMarkets, isUserMarketsLoading, watchlist } = useCryptoAssets();
  const [tab, setTab] = useState('assets');

  const filterUserMarkets = (markets) => {
    const watchlistArray = watchlist?.map((item) => item.assetId.toLowerCase());

    if (tab === 'assets') {
      return markets.map((market) => {
        const [baseAsset] = market.displayName.split('/').map((part) => part.toLowerCase());
        return { ...market, isFavorite: watchlistArray?.includes(baseAsset) };
      });
    }

    return markets
      .filter((market) => {
        const [baseAsset] = market.displayName.split('/').map((part) => part.toLowerCase());
        return watchlistArray?.includes(baseAsset);
      })
      .map((market) => ({ ...market, isFavorite: true }));
  };

  const filteredMarkets = filterUserMarkets(userMarkets);

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.topSection}>
          <AssetsHeader />

          <AssetsTabs tab={tab} setTab={setTab} />

          <AssetSearch search={search} setSearch={setSearch} />
        </Box>
        <div className={classes.assetsWrapper}>
          {filteredMarkets &&
            filteredMarkets.map((item, idx) => (
              <>
                <AssetItem item={item} idx={idx} key={idx} isFavorite={item.isFavorite} />
              </>
            ))}

          {(!filteredMarkets || filteredMarkets.length === 0) && !isUserMarketsLoading && (
            <div>No {tab === 'watchlist' ? 'favorite ' : ''}assets</div>
          )}
        </div>
      </Box>
    </Box>
  );
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      position: 'relative',
      backgroundColor: 'var(--white)',
      borderRadius: '24px',
      fontFamily: theme.typography.fontFamily,
      height: '56.52rem',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '8px',
      overflowY: 'auto',
    },

    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '0 8px',
    },

    topSection: {
      position: 'sticky',
      top: '0',
      backgroundColor: 'var(--white)',
      zIndex: 1,
    },

    title: {
      letterSpacing: '0.5px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 4,
      height: '40px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      paddingBottom: '20px',
    },

    heading: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    assetsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: '8px',
      overflow: 'hidden',
      height: '100%',
    },
  }),
);
export default CryptoAssets;
