import clsx from 'clsx';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotificationsIcon from '../../../assets/images/notifications-icon.svg';
import NotificationIconEmpty from '../../../assets/images/notification-icon-empty.svg';
import SettingsIcon from '../../../assets/images/settings-icon.svg';
import DepositIcon from '../../../assets/images/deposit-icon.svg';
import WithdrawalIcon from '../../../assets/images/withdrawal-icon.svg';
import TransferFundsIcon from '../../../assets/images/transfer-funds.svg';
import replayTutorialIcon from '../../../assets/images/reply-tutorial-icon.svg';
import LogoutIcon from '../../../assets/images/logout-icon.svg';
import PrivacyPolicyIcon from '../../../assets/images/privacy-policy-icon.svg';
import AccountIcon from '../../../assets/images/account-icon.svg';
import CryptoAccountIcon from '../../../assets/images/crypto-account-icon.svg';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import { refresh } from '../../../helpers/refresh';
import { userService } from '../../../service/user';
import { useAppContext } from '../../../context/DataContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEffect } from 'react';
import { Button, Divider, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../Popups/PleaseLoginPopup/PleaseLoginPopup';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { updateBucket } from '../../../helpers/update-client-bucket';
import VerifyAccountBtn from '../../../assets/images/verify-account-btn.png';
import NonVerifiedAccountIcon from '../../../assets/images/non-verified-account-icon.svg';
import VerifiedAccountIcon from '../../../assets/images/verified-account-icon.svg';
import { useState } from 'react';
import { generateCurrencySymbol } from '../../../helpers/generateCurrencySymbol';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import { ReactComponent as GoldIcon } from '../../../assets/images/goldIcon.svg';

export default function SlideMenu({ isOpen, toggleMenu, setIsPleaseLoginPopupOpen, setIsTransferFundsPopUpOpen }) {
  const classes = useStyles();
  const {
    unreadNotificationsCounter,
    isMobile,
    isAppView,
    setCurrency,
    isOnHomePage,
    tutorialStep,
    setTutorialStep,
    setIsCartFetched,
    setProductsBucket,
    activeTradeAccountId,
    setActiveTradeAccountId,
    activeTradeAccountCurrency,
    setActiveTradeAccountCurrency,
    activeTradeAccountBalance,
    setActiveTradeAccountBalance,
    userDetails,
    userCartData,
    isUserVerified,
    userTradeAccounts,
    userBrokerAccounts,
  } = useAppContext();

  const anchor = 'right';
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAppContext();
  const isDemo = sessionStorage.getItem('isDemo');
  const token = localStorage.getItem('accessToken');
  const expToken = localStorage.getItem('accessTokenExp');

  const [isLogoutDisabled, setIsLogoutDisabled] = useState(false);

  const showTransferFundsBtn = userTradeAccounts?.length + userBrokerAccounts?.length > 1;

  useEffect(() => {
    updateBucket(isAppView, userCartData, setIsCartFetched, setProductsBucket, userService?.updateShoppingCart);
  }, [userCartData]);

  const firstName = userDetails ? userDetails.firstName : '';
  const lastName = userDetails ? userDetails.lastName : '';

  function navigateToSumSubPage() {
    navigate(routes.sumsub);
  }

  function navigateToSumSub() {
    const appView = sessionStorage.getItem('isNative');
    const externalUserId = sessionStorage.getItem('externalUserId');
    const levelName = sessionStorage.getItem('levelName');
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        `{"Event": "NotVerified", "ExternalUserId": "${externalUserId}", "VerificationLevel": "${levelName}"}`,
      );
    } else {
      navigateToSumSubPage();
    }
    toggleMenu();
  }

  const balance = isNaN(Number(activeTradeAccountBalance)) ? 0 : Number(activeTradeAccountBalance);

  const handleTradeAccountClicked = (account) => {
    setCurrency(account.currency);
    setActiveTradeAccountId(account.tradeAccountId);
    setActiveTradeAccountCurrency(account.currency);
    setActiveTradeAccountBalance(account.balance);

    localStorage.setItem('taid', account.tradeAccountId);
    localStorage.setItem('currency', account.currency);
  };

  const handleListItemClicked = (handler) => {
    if (handler && typeof handler === 'function') {
      handler();
    }
  };

  function navigateToDeposit() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);

    if (isDemo) {
      toggleMenu();
      return;
    }
    const appView = sessionStorage.getItem('isNative');
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "Deposit"}');
      toggleMenu();
    } else {
      if (window.location.pathname.includes('/deposit')) {
        refresh();
      }
      toggleMenu();
      navigate(routes.deposit);
    }
  }

  function navigateToProfile() {
    const appView = isAppView;
    const isDemo = sessionStorage.getItem('isDemo') === 'true';

    if (appView && !isDemo) {
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "UserProfile"}');
      toggleMenu();
    } else {
      if (window.location.pathname.includes('/profile')) {
        refresh();
      }
      toggleMenu();
      navigate(routes.profile);
    }
  }

  function navigateToWithdraw() {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      toggleMenu();
      return;
    }
    if (window.location.pathname.includes('/withdraw')) {
      refresh();
    }

    toggleMenu();
    navigate(routes.withdraw);
  }

  const handleTransferFunds = () => {
    setIsTransferFundsPopUpOpen(true);
    toggleMenu();
  };

  async function handleReplyTutorial() {
    if (isAppView) {
      window?.webkit?.messageHandlers?.notifyNative?.postMessage('{"Event": "Tutorial", "Action": "replay"}');
    } else if (isMobile) {
      await userService.updateTutorialStatus('MOBILE', 'ACTIVE');
    } else {
      await userService.updateTutorialStatus('WEB', 'ACTIVE');
    }

    if (!isAppView) {
      sessionStorage.setItem('tutorialStep', '0');
      setTutorialStep(0);
    }

    toggleMenu();

    if (window.location.pathname !== '/' && !isAppView) {
      window.location.href = '/';
    }
  }

  function helpHandler() {
    const appView = sessionStorage.getItem('isNative');
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        `{"Event": "Help", "URL": "${process.env.REACT_APP_CRM_BASE_URL}/faqs"}`,
      );
    } else {
      window.open(`${process.env.REACT_APP_CRM_BASE_URL}/faqs`, '_blank');
    }
    toggleMenu();
  }
  function handlePrivacyPolicy() {
    const appView = sessionStorage.getItem('isNative');
    if (appView) {
      window.webkit?.messageHandlers.notifyNative.postMessage(
        `{"Event": "Help", "URL": "${process.env.REACT_APP_CRM_BASE_URL}/privacy-policy"}`,
      );
    } else {
      window.open(`${process.env.REACT_APP_CRM_BASE_URL}/privacy-policy`, '_blank');
    }
    toggleMenu();
  }

  function clearSessionStorageExceptKeys(keysToKeep) {
    const keys = Object.keys(sessionStorage);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!keysToKeep.includes(key)) {
        sessionStorage.removeItem(key);
      }
    }
  }

  const logoutHandler = async () => {
    setIsLogoutDisabled(true);
    const appView = sessionStorage.getItem('isNative');
    const isDemo = sessionStorage.getItem('isDemo');

    if (appView && isDemo) {
      localStorage.clear();
      clearSessionStorageExceptKeys(['isNative', 'isDemo']);
      setIsLoggedIn(false);
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "Logout"}');
      return;
    }
    if (appView) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoggedIn(false);
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "Logout"}');
    } else {
      try {
        await userService.logout();
        toggleMenu();
        localStorage.clear();
        sessionStorage.clear();
        setIsLoggedIn(false);
        navigate(routes.login);
      } catch (e) {
        console.error(e);
        setIsLogoutDisabled(false);
      }
    }
  };

  const handleExitDemo = () => {
    const appView = sessionStorage.getItem('isNative');
    if (appView) {
      localStorage.clear();
      clearSessionStorageExceptKeys(['isNative', 'isDemo']);
      setIsLoggedIn(false);
      window.webkit?.messageHandlers.notifyNative.postMessage('{"Event": "Logout"}');
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate(routes.login);
      setIsLoggedIn(false);
      location.reload();
    }
  };

  let itemList = [
    {
      id: 1,
      text: 'Notifications',
      icon: <img src={unreadNotificationsCounter > 0 ? NotificationsIcon : NotificationIconEmpty} alt="Notification" />,
      handler: () => {
        navigate(routes.notifications);
        toggleMenu();
      },
      elementId: 'notifications-icon',
    },
    {
      id: 2,
      text: 'Profile Settings',
      icon: <img src={SettingsIcon} alt="Settings" />,
      handler: navigateToProfile,
    },
    {
      id: 3,
      text: 'Deposit',
      icon: <img id="deposit-icon-img" src={DepositIcon} alt="Deposit" />,
      handler: navigateToDeposit,
      elementId: 'deposit-icon',
    },
    {
      id: 4,
      text: 'Withdrawal',
      icon: <img src={WithdrawalIcon} alt="Withdrawal" />,
      handler: navigateToWithdraw,
    },
    {
      id: 6,
      text: 'Replay Tutorial',
      icon: <img src={replayTutorialIcon} alt="Replay Tutorial" />,
      handler: handleReplyTutorial,
    },
    {
      id: 7,
      text: 'Help',
      icon: <HelpOutlineIcon style={{ color: '#989898' }} />,
      handler: helpHandler,
    },
    {
      id: 8,
      text: 'Privacy policy',
      icon: <img src={PrivacyPolicyIcon} alt="Privacy Policy" />,
      handler: handlePrivacyPolicy,
    },
    {
      id: 9,
      text: 'Logout',
      icon: <img src={LogoutIcon} alt="Logout" />,
      handler: logoutHandler,
      disabled: isLogoutDisabled,
    },
  ];

  if (showTransferFundsBtn) {
    itemList.splice(4, 0, {
      id: 5,
      text: 'Transfer Funds',
      icon: <img src={TransferFundsIcon} alt="Transfer Funds" />,
      handler: handleTransferFunds,
    });
  }

  if (isDemo) {
    itemList = itemList?.filter((item) => item.text !== 'Replay Tutorial');
  }

  const list = (
    <Box sx={{ minWidth: '236px' }} role="presentation" onKeyDown={toggleMenu}>
      <List
        sx={{
          '&.MuiList-root': {
            paddingTop: '0px',
          },
        }}
      >
        {isUserVerified === 'false' && (
          <>
            <div className={classes.verifyAccountBtnContainer}>
              <img src={VerifyAccountBtn} alt="Account Icon" style={{ cursor: 'pointer' }} onClick={navigateToSumSub} />
            </div>
            <Divider className={classes.divider} variant="middle" component="li" />
          </>
        )}
        <div className={classes.menuTitleContainer} style={{ justifyContent: isDemo ? 'center' : 'space-between' }}>
          {isDemo ? (
            <Button
              className={classes.quitDemoBtn}
              onClick={handleExitDemo}
              startIcon={<CloseIcon fontSize="large" />}
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'black',
                },
              }}
            >
              QUIT DEMO
            </Button>
          ) : (
            <>
              <MenuItem className={classes.userInfoContainer}>
                <ListItemIcon>
                  <img
                    style={{ width: '33px' }}
                    src={isUserVerified === 'false' ? NonVerifiedAccountIcon : VerifiedAccountIcon}
                    alt="Account Icon"
                  />
                </ListItemIcon>
                <ListItemText className={classes.userInfo}>
                  <div className={classes.username}>{firstName + ' ' + lastName}</div>
                  <div className={classes.email}>{userDetails?.email}</div>
                </ListItemText>
              </MenuItem>
            </>
          )}
        </div>

        <Divider variant="middle" component="li" className={classes.divider} />

        {userBrokerAccounts?.length > 0 && (
          <>
            {userBrokerAccounts?.map((account, index) => {
              const usdtBalance = account?.assets?.find((asset) => asset.assetId === 'usdt')?.amount || 0;

              return (
                <div className={classes.brokerAccountsListContainer} key={index}>
                  <img src={CryptoAccountIcon} alt="Crypto icon" className={classes.cryptoIcon} />
                  <div className={classes.cryptoAccount}>
                    {index > 0 ? <b>Crypto Wallet {index + 1}</b> : <b>Crypto Wallet</b>}
                    <div>{currencyFormatter(usdtBalance, '', 'USDT', true)}</div>
                  </div>
                </div>
              );
            })}
            <Divider variant="middle" component="li" className={classes.divider} />
          </>
        )}

        {userTradeAccounts?.length > 0 && (
          <>
            {userTradeAccounts?.map((account, index) => (
              <div
                className={classes.tradeAccountsListContainer}
                key={index}
                style={{
                  color: activeTradeAccountId === account.tradeAccountId ? '#FFFFFF' : '#A8A8A8',
                }}
              >
                <div className={classes.left}>
                  <div>
                    <GoldIcon className={classes.goldIcon} />
                  </div>

                  <div className={classes.tradeAccount} htmlFor={`tradeAccount${index}`}>
                    <b>Gold Wallet</b>
                    <div>{currencyFormatter(account.balance, '', account.currency, true)}</div>
                  </div>
                </div>

                <input
                  type="radio"
                  style={{ accentColor: '#347362', cursor: 'pointer' }}
                  id={`tradeAccount${index}`}
                  name="tradeAccount3"
                  value={account.tradeAccountId}
                  defaultChecked={activeTradeAccountId === account.tradeAccountId}
                  onClick={() => handleTradeAccountClicked(account)}
                />
              </div>
            ))}
            <Divider className={classes.divider} variant="middle" component="li" />
          </>
        )}
        {itemList.map((item) => (
          <ListItem className={classes.menuItem} id={item.elementId} key={item.id} disablePadding>
            <ListItemButton onClick={() => handleListItemClicked(item.handler)} disabled={item.disabled}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText className={classes.listItem} primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handleClickAway = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(tutorialStep === 3 && isOnHomePage) && isOpen) {
      toggleMenu();
    }
  };

  return (
    <div>
      {((tutorialStep === 3 && isOnHomePage) || isOpen) && <div className="backdrop" />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Drawer
          id="side-menu-drawer"
          className={clsx(classes.menuContainer, isOpen && !(tutorialStep === 3 && isOnHomePage) ? 'except' : '')}
          anchor={anchor}
          open={isOpen}
          onClose={!(tutorialStep === 3 && isOnHomePage) && toggleMenu}
          variant="persistent"
          ModalProps={{
            container: document.getElementById('drawer-container'),
            style: { position: 'absolute' },
          }}
        >
          {list}
        </Drawer>
      </ClickAwayListener>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    '& .MuiDrawer-paper': {
      backgroundColor: '#000000 ',
      color: '#DDDDDD',
      borderLeft: '1px solid #A4A6AA',
      borderTopLeftRadius: '5px',
    },
    '& .MuiModal-backdrop': {
      backdropFilter: 'blur(1.8px)',
    },
  },
  menuTitleContainer: {
    display: 'flex',
    paddingBottom: '10px',
    paddingTop: '0px',
    alignItems: 'center',
    height: '60px',
  },
  verifyAccountBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
  },
  userInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    marginTop: '15px',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    minWidth: '133px',
    '& .MuiTypography-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  username: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#FFFFFF',
  },
  email: {
    fontSize: '10px',
  },
  account: {
    fontSize: '13px',
    fontWeight: 600,
    color: '#DDDDDD',
  },
  brokerAccountsListContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 400,
    padding: '7px',
    gap: '5px',
    marginLeft: '10px',
  },
  cryptoAccount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  tradeAccountsListContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '13px',
    fontWeight: 400,
    padding: '7px',
    gap: '5px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  divider: {
    height: '2px',
    borderColor: 'gray',
  },
  tradeAccount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  quitDemoBtn: {
    width: '10rem',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    border: '1px solid white',
    borderRadius: '60px',
    color: 'white',
    fontSize: '0.8rem',
    fontWeight: 600,
    maxHeight: '35px',
    left: '5px',
    '& .MuiButton-startIcon': {
      marginLeft: '-15px !important',
    },
  },
  menuItem: {
    paddingBottom: '10px !important',
    maxWidth: '100% !important',
    '& .MuiListItemIcon-root': {
      justifyContent: 'center !important',
    },
  },

  left: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },

  listItem: {
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: '400',
    },
  },

  goldIcon: {
    width: '24px',
    height: '24px',
    paddingRight: '5px',

    '& > path': {
      fill: '#989898',
    },
  },

  cryptoIcon: {
    paddingRight: '8px',
  },
}));
