import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import serviceAutoInvest from "../../../service/autoInvest";
import { useAppContext } from "../../../context/DataContext";
import NotificationMessagePopup from "../../common/Popups/NotificationMessagePopup/NotificationMessagePopup";

function AutoInvestInfo() {
  const classes = useStyles();
  const [activeTransactions, setActiveTransactions] = useState(0);
  const [upcomingCoins, setUpcomingCoins] = useState(0);
  const { proceedAutoInvestOrderClicked } = useAppContext();
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getAllActiveAutoInvestTransactions = useCallback(async () => {
    try {
      const response = await serviceAutoInvest.getAllActiveAutoInvest();
      const allAutoInvestedCoins = response.activeTransaction;
      if (!allAutoInvestedCoins) {
        setActiveTransactions(0);
      }
      setActiveTransactions(allAutoInvestedCoins);
    } catch (error) {
      const customError = JSON.parse(error?.message);

      setErrorPopup(true);
      setErrorMessage(customError?.message);
    }
  }, []);

  const getAllUpcomingCoins = useCallback(async () => {
    try {
      const response =
        await serviceAutoInvest.getAllAutoInvestUpcomingCoinsTillEndOfCurrentMonth();
      const upcomingCoins = response.upcomingCoins;
      if (!upcomingCoins) {
        setUpcomingCoins(0);
      }
      setUpcomingCoins(upcomingCoins);
    } catch (error) {
      setErrorPopup(true);
      setErrorMessage(error.message);
    }
  }, []);

  const closePopups = () => {
    setErrorPopup(false);
  };

  useEffect(() => {
    getAllUpcomingCoins(setUpcomingCoins);
    getAllActiveAutoInvestTransactions(setActiveTransactions);
  }, [
    proceedAutoInvestOrderClicked,
    getAllActiveAutoInvestTransactions,
    getAllUpcomingCoins,
  ]);

  return (
    <Box className={classes.container}>
      {errorPopup && (
        <NotificationMessagePopup
          type={"error"}
          isPopupOpen={errorPopup}
          setIsPopupOpen={closePopups}
          message={errorMessage}
          handleCloseAutoInvestPopup={closePopups}
        />
      )}

      <Box className={classes.innerContainer}>
        <Box className={classes.info}>
          <Typography variant="body2">ACTIVE AUTO INVEST ORDERS</Typography>
          <Box className={classes.fieldValue}>{activeTransactions}</Box>
        </Box>
        <Box className={classes.info}>
          <Typography variant="body2">UPCOMING MONTHLY COINS</Typography>
          <Box className={classes.fieldValue}>{upcomingCoins}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AutoInvestInfo;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "var(--white)",
    paddingTop: "0.2rem",
    height: "50px",
    minHeight: "50px",
    maxHeight: "50px",
    borderTop: "1px solid #D9D9D9",
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "var( --font-size-8)",
  },
  info: {
    display: "block",
    width: "49%",
  },
  fieldValue: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "var(--gold)",
    fontSize: "var(--font-size-14)",
    fontWeight: "700",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
  },
  buttonContainer: {
    marginTop: "3px",
    justifyContent: "center",
    display: "flex",
    "& .MuiChip-root": {
      height: "16px",
    },
    "& .MuiChip-label": {
      fontSize: "var(--font-size-10)",
      paddingLeft: "5px",
      paddingRight: "5px",
      fontWeight: "700",
    },
  },
  divider: {
    marginLeft: "1.4rem",
    marginRight: "1.4rem",
    marginTop: "0.5rem",
  },
}));
