import * as React from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomButton from '../../common/CustomButton/CustomButton';
import TransactionPanel from './TransactionPanel';
import { useAppContext } from '../../../context/DataContext';
import { GoldToMoneyConverter } from '../../../helpers/goldToMoneyConverter';
import NotificationMessagePopup from '../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import buyServices from '../../../service/buyService';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import sellServices from '../../../service/sellService';
import { messages } from '../../../constants/popupMessages';
import { isMarketOpen } from '../../../helpers/isMarketOpen';
import NonVerifiedUserPopUp from '../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import { errorHandlingWrapper } from '../../../helpers/errorHandlingWrapper';
import isVerified from '../../../helpers/isVerified';

const BuySellCard = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('1');
  const [buyingQuantity, setBuyingQuantity] = useState(0);
  const [sellingQuantity, setSellingQuantity] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [notificationPopupType, setNotificationPopupType] = useState('');
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const {
    goldAskPrice,
    goldBidPrice,
    goldAskPriceForGroup,
    goldBidPriceForGroup,
    proceedBuyOrderClicked,
    setProceedBuyOrderClicked,
    proceedSellOrderClicked,
    setProceedSellOrderClicked,
    nonVerifiedPopUpOpen,
    setNonVerifiedPopUpOpen,
    isBuySellLoading,
    setIsBuySellLoading,
  } = useAppContext();
  const [message, setMessage] = useState('');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setBuyingQuantity(0);
    setSellingQuantity(0);
    setErrorMessage('');
  };

  const handleIncrement = () => {
    if (tabValue === '1' && buyingQuantity < 99999) {
      if (buyingQuantity === '') {
        setBuyingQuantity(0);
      }
      setBuyingQuantity((prevQuantity) => prevQuantity + 1);
    } else if (tabValue === '2' && sellingQuantity < 99999) {
      if (sellingQuantity === '') {
        setSellingQuantity(0);
      }
      setSellingQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    if (tabValue === '1' && buyingQuantity > 0) {
      if (buyingQuantity === '') {
        setBuyingQuantity(0);
      }
      setBuyingQuantity((prevQuantity) => prevQuantity - 1);
    } else if (tabValue === '2' && sellingQuantity > 0) {
      if (sellingQuantity === '') {
        setSellingQuantity(0);
      }
      setSellingQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const handleChosenGramsToBuy = (value) => {
    setBuyingQuantity(value);
  };
  const handleChosenGramsToSell = (value) => {
    setSellingQuantity(value);
  };

  const handleBuyInputChange = (event) => {
    const value = event.target.value;
    var intValue = parseInt(value); //  parsing for preventing entering 0 at the front
    if (isNaN(intValue)) {
      intValue = 0;
    }
    handleChosenGramsToBuy(intValue);
  };

  const handleSellInputChange = (event) => {
    const value = event.target.value;
    let intValue = parseInt(value);
    if (isNaN(intValue)) {
      intValue = 0;
    }
    handleChosenGramsToSell(intValue);
  };

  const handlePlaceBuyOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setIsBuySellLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const newBuy = {
        volume: buyingQuantity,
        clientPricePerAsset: goldAskPriceForGroup,
      };
      setIsBuySellLoading(true);
      try {
        await buyServices.createBuyRequest(newBuy);
        setMessage(messages.orderSuccessful);
        setProceedBuyOrderClicked(true);
        setNotificationPopupType('success');
        setBuyingQuantity(0);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setMessage(customError?.message);
        setProceedBuyOrderClicked(true);
        setNotificationPopupType('error');
        setBuyingQuantity(0);
      } finally {
        setIsBuySellLoading(false);
      }
    } else {
      setMessage(messages.marketClosed);
      setProceedBuyOrderClicked(true);
      setNotificationPopupType('error');
      setBuyingQuantity(0);
    }
  };
  const handlePlaceSellOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    const result = await isVerified(setNonVerifiedPopUpOpen, setIsBuySellLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const newSell = {
        volume: sellingQuantity,
        clientPricePerAsset: goldBidPriceForGroup,
      };
      setIsBuySellLoading(true);
      try {
        await sellServices.createSellRequest(newSell);
        setProceedSellOrderClicked(true);
        setMessage(messages.orderSuccessful);
        setNotificationPopupType('success');
        setSellingQuantity(0);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setMessage(customError?.message);
        setProceedSellOrderClicked(true);
        setNotificationPopupType('error');
        setSellingQuantity(0);
      } finally {
        setIsBuySellLoading(false);
      }
    } else {
      setMessage(messages.marketClosed);
      setProceedSellOrderClicked(true);
      setNotificationPopupType('error');
      setSellingQuantity(0);
    }
  };

  const totalBuy = GoldToMoneyConverter(buyingQuantity, goldAskPrice); // standard group
  const totalBuyForGroup = GoldToMoneyConverter(buyingQuantity, goldAskPriceForGroup);

  const totalSell = GoldToMoneyConverter(sellingQuantity, goldBidPrice); // standard group
  const totalSellForGroup = GoldToMoneyConverter(sellingQuantity, goldBidPriceForGroup);

  const showSpecialPriceFieldForBuy = goldAskPrice !== goldAskPriceForGroup;
  const showSpecialPriceFieldForSell = goldBidPrice !== goldBidPriceForGroup;

  const isBuyButtonDisabled = () => {
    return buyingQuantity === '' || buyingQuantity === '0' || buyingQuantity === 0 || isNaN(buyingQuantity);
  };
  const isSellButtonDisabled = () => {
    return sellingQuantity === '' || sellingQuantity === '0' || sellingQuantity === 0 || isNaN(sellingQuantity);
  };

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <Box
      sx={{
        background: 'white',
        '& .MuiTabs-indicator': {
          backgroundColor: tabValue === '1' ? '#589E7D' : '#D14949',
          height: '4px',
        },
      }}
      className={classes.container}
    >
      <TabContext value={tabValue}>
        <Box className={classes.tabContainer}>
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              '& .MuiTab-root.Mui-selected': {
                color: 'black',
                fontWeight: '600',
              },
            }}
          >
            <Tab label="BUY" className={classes.tab} value="1" />
            <Tab label="CLOSE ORDER" className={classes.tab} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TransactionPanel
            type="buy"
            errorMessage={errorMessage}
            quantity={buyingQuantity}
            handleInputChange={handleBuyInputChange}
            handleChosenGrams={handleChosenGramsToBuy}
            totalBuy={totalBuy}
            totalBuyForGroup={totalBuyForGroup}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            setBuyingQuantity={setBuyingQuantity}
            showSpecialPriceFieldForBuy={showSpecialPriceFieldForBuy}
          />
        </TabPanel>
        <TabPanel value="2">
          <TransactionPanel
            type="sell"
            errorMessage={errorMessage}
            quantity={sellingQuantity}
            handleInputChange={handleSellInputChange}
            handleChosenGrams={handleChosenGramsToSell}
            totalSell={totalSell}
            totalSellForGroup={totalSellForGroup}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            setSellingQuantity={setSellingQuantity}
            showSpecialPriceFieldForSell={showSpecialPriceFieldForSell}
          />
        </TabPanel>
      </TabContext>
      <div className={classes.buttonContainer}>
        <div className={classes.helperContainer}>
          <div className={classes.blackButton}>
            <CustomButton
              type="black"
              disabled={tabValue === '1' ? isBuyButtonDisabled() : isSellButtonDisabled()}
              buttonText={tabValue === '1' ? 'Place Buy Order' : 'Place Close Order'}
              width="100%"
              height="100%"
              onClick={tabValue === '1' ? handlePlaceBuyOrder : handlePlaceSellOrder}
            />
          </div>
        </div>
      </div>
      {proceedBuyOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedBuyOrderClicked}
          setIsPopupOpen={setProceedBuyOrderClicked}
          message={message}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      {proceedSellOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedSellOrderClicked}
          setIsPopupOpen={setProceedSellOrderClicked}
          message={message}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
          handleCloseAutoInvestPopup={closeNonVerifiedPopUp}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={isBuySellLoading} />
    </Box>
  );
};

export default BuySellCard;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    width: '100%',
    margin: 0,
    height: '100%',
    border: '0.5px #D2D5D8',
    borderRadius: '24px',
    '& .MuiTabPanel-root': {
      paddingTop: '7px',
      [theme.breakpoints.down('1500')]: {
        paddingTop: '5px',
      },
      [theme.breakpoints.down('1200')]: {
        paddingTop: 0,
      },
      [theme.breakpoints.down('1600')]: {
        paddingBottom: '0.5rem',
        paddingLeft: '4%',
        paddingRight: '4%',
      },
    },
  },
  tabContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    borderTopRightRadius: '24px',
    borderTopLeftRadius: '24px',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
  },
  helperContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '10px',
    [theme.breakpoints.down('768')]: {
      paddingLeft: '9%',
      paddingRight: '9%',
    },
  },
  blackButton: {
    width: '10rem',
    height: '10%',
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'inline-block',
    fontSize: '20px',
    [theme.breakpoints.down('1200')]: {
      width: '9rem',
      height: '10%',
    },
    [theme.breakpoints.down('768')]: {
      paddingTop: '0.8rem',
      width: '10.4rem',
      height: '32px',
      marginBottom: '14%',
    },
  },
  tab: {
    minWidth: '30px',
    width: '50%',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'var(--font-size-20)',
    [theme.breakpoints.down('1600')]: {
      fontSize: 'var(--font-size-19)',
    },
    [theme.breakpoints.down('1440')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('830')]: {
      fontSize: 'var(--font-size-14)',
      fontWeight: '600',
    },
    '& .css-n9z7pb-MuiButtonBase-root-MuiTab-root.Mui-selected': {
      color: 'black',
    },
    '& .MuiTab-root': {
      minWidth: '30px',
    },
    '& .MuiTabs-flexContainer': {
      paddingLeft: '1rem',
    },
    '& button': {
      fontFamily: 'Poppins',
    },
  },
}));
