import React from "react";
import { makeStyles } from "@mui/styles";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import { CURRENCY_TYPE } from "../../../constants/globalconstants";
import { Box } from "@mui/material";
import { createStyles } from "@mui/material";
import IButton from "../../common/IButton/IButton";
import { useAppContext } from "../../../context/DataContext";
import { tooltipContent } from "../../../constants/tooltipTexts";

function TotalBalanceInStaking({ rightText, leftText, type }) {
  const classes = useStyles();
  const { goldBidPriceForGroup, stakedCoins, vaultedCoins } = useAppContext();

  const valueOfStakedCoins = stakedCoins
    ? stakedCoins * goldBidPriceForGroup
    : 0;

  const valueOfVaultedCoins = vaultedCoins
    ? vaultedCoins * goldBidPriceForGroup
    : 0;

  return (
    <Box className={classes.container}>
      <IButton
        className={classes.iButton}
        tooltipTitle={
          type === "staking"
            ? tooltipContent.stakedCoins
            : tooltipContent.vaultedCoins
        }
      ></IButton>
      <div className={classes.topContent}>
        <div className={classes.coins}>
          <div className={classes.text}>{leftText}</div>
          <div className={classes.price}>
            {type === "staking" ? stakedCoins : vaultedCoins}
          </div>
        </div>
        <div className={classes.value}>
          <div className={classes.text}>{rightText}</div>
          <div className={classes.price}>
            {currencyFormatter(
              type === "staking" ? valueOfStakedCoins : valueOfVaultedCoins,
              CURRENCY_TYPE
            )}
          </div>
        </div>
      </div>
      <div className={classes.bottomContent}>
        <div style={{ display: "flex", width: "50%" }}></div>
        <div
          style={{ display: "flex", width: "50%", alignItems: "center" }}
        ></div>
      </div>
    </Box>
  );
}

export default TotalBalanceInStaking;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      backgroundColor: "#F1F1F1",
      borderRadius: "24px",
      height: "100%",
      paddingLeft: "3%",
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    },
    topContent: {
      display: "flex",
      width: "100%",
      height: "50%",
      paddingTop: "35px",
    },
    bottomContent: {
      display: "flex",
      width: "100%",
      height: "50%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    coins: {
      display: "flex",
      width: "50%",
      flexDirection: "column",
      alignItems: "start",
    },
    text: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
      fontSize: "var(--font-size-16)",
      textAlign: "left",
      [theme.breakpoints.down("1100")]: {
        fontSize: "var(--font-size-13)",
      },
      [theme.breakpoints.down("900")]: {
        fontSize: "var(--font-size-11)",
      },
    },
    price: {
      fontFamily: theme.typography.fontFamily,
      color: "var(--gold)",
      fontSize: "1.8rem",
      fontWeight: 700,
      [theme.breakpoints.down("1100")]: {
        fontSize: "var(--font-size-18)",
      },
      [theme.breakpoints.down("950")]: {
        fontSize: "var(--font-size-16)",
      },
    },
    value: {
      display: "flex",
      width: "50%",
      flexDirection: "column",
      alignItems: "start",
    },
    portfolioPL: {
      paddingRight: "2%",
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
      fontSize: "var(--font-size-16)",
      color: "#747474",
      [theme.breakpoints.down("1100")]: {
        fontSize: "var(--font-size-13)",
      },
      [theme.breakpoints.down("950")]: {
        fontSize: "var(--font-size-11)",
      },
    },
  })
);
