import React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InvestIcon from '../../../assets/images/investment-icon.svg';
import { formatDateWrapper } from '../../../helpers/dateFormatter';
import { useEffect } from 'react';
import { useAppContext } from '../../../context/DataContext';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import NotificationMessagePopup from '../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import ConfirmPopUp from '../../common/Popups/ConfirmPopUp/ConfirmPopUp';
import { handleGetAllAutoInvestMobileTransactions } from '../../../helpers/handleGetAutoInvestTransactionMobile';
import serviceAutoInvest from '../../../service/autoInvest';
import CustomButton from '../../common/CustomButton/CustomButton';
import { tooltipContent } from '../../../constants/tooltipTexts';
import { messages } from '../../../constants/popupMessages';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--mobile-grey)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row(props) {
  const { row, handlePopUpOpen } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { userTradeAccounts } = useAppContext();

  const tradeAccountsLength = userTradeAccounts?.length;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.tableRow}>
        <StyledTableCell>
          <IconButton
            aria-label="auto-invest"
            size="small"
            disableRipple
            sx={{
              cursor: 'default',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
          >
            <img src={InvestIcon} alt="Invest Icon" style={{ width: 12, height: 12, padding: 1 }} />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {formatDateWrapper(new Date(row.date).getTime(), 'long')}
        </StyledTableCell>

        <StyledTableCell>{row.volume}gr</StyledTableCell>

        <StyledTableCell>
          {row.period} {row.period === 1 ? 'month' : 'months'}
        </StyledTableCell>

        <StyledTableCell>{row.status}</StyledTableCell>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon style={{ color: '#347362' }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: '#347362' }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <StyledTableRow className={classes.tableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow className={classes.tableRow}>
                  {tradeAccountsLength > 1 && (
                    <StyledTableCell
                      align="left"
                      sx={{
                        width: {
                          xs: '100%',
                        },
                        paddingLeft: {
                          xs: '2.5rem !important',
                          sm: '5rem !important',
                          md: '5.5rem !important',
                        },
                      }}
                    >
                      Account
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    align="left"
                    sx={{
                      display: 'flex',
                      paddingLeft: '8px',
                      width: '50%',
                    }}
                  ></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow
                  className={classes.tableRow}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    paddingRight: '20px',
                    height: '46px',
                    width: {
                      xs: '100%',
                    },
                    gap: {
                      xs: '5rem',
                      md: '27rem',
                      sm: '19rem',
                    },
                  }}
                >
                  {tradeAccountsLength > 1 && <StyledTableCell align="right">{row.tradeAccountId}</StyledTableCell>}
                  <StyledTableCell align="right">
                    <Button
                      className={classes.closeButton}
                      variant="outlined"
                      endIcon={<CloseIcon style={{ fontSize: 14 }} />}
                      onClick={() => handlePopUpOpen(row.id)}
                      disabled={row.status !== 'ACTIVE'}
                    >
                      Stop
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function MobileAutoInvestHistoryTable({ isInHistory }) {
  const classes = useStyles();
  const tableEl = useRef();
  const [loading, setLoading] = useState(true);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [allPages, setAllPages] = useState(1);
  const [page, setPage] = useState(1);
  const [mobileAutoInvests, setMobileAutoInvests] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState('');
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    popUpMessage,
    setPopUpMessage,
    notificationPopupType,
    setNotificationPopupType,
    proceedAutoInvestOrderClicked,
    setProceedAutoInvestOrderClicked,
    responseFromCancelledAutoInvestTransaction,
    setResponseFromCancelledAutoInvestTransaction,
  } = useAppContext();
  const [hasMore, setHasMore] = useState(page <= allPages);

  useEffect(() => {
    handleGetAllAutoInvestMobileTransactions(
      setMobileAutoInvests,
      page,
      setAllPages,
      setError,
      20,
      responseFromCancelledAutoInvestTransaction,
      proceedAutoInvestOrderClicked,
      setLoading,
    );
  }, [
    page,
    proceedAutoInvestOrderClicked,
    cancelOrderId,
    mobileAutoInvests.length,
    responseFromCancelledAutoInvestTransaction,
  ]);

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          if (page < allPages) {
            setPage((prev) => prev + 1);
          }
          setLoading(false);
          setHasMore(page < allPages);
          resolve();
        }, 1500),
      );
    };
    setLoading(true);
    if (page === allPages) {
      setLoading(false);
      return;
    }
    loadItems();
  }, [mobileAutoInvests, page]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (proceedAutoInvestOrderClicked) {
      tableEl.current.scrollTop = 0;
    }
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);
  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener('scroll', scrollListener);
    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [hasMore, loadMore, loading, distanceBottom]);

  function handlePopUpOpen(id) {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    setCancelOrderId(id);
    setOpen(true);
  }

  function handlePopUpClosed() {
    setOpen(false);
  }

  const handleCancelAutoInvestOrder = async (orderId) => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    const closedAutoInvest = {
      orderId: orderId,
    };
    handlePopUpClosed();
    setLoading(true);
    try {
      const closeTransaction = await serviceAutoInvest.cancelAutoInvest(closedAutoInvest);
      setResponseFromCancelledAutoInvestTransaction(closeTransaction);
      setPopUpMessage(messages.orderClosed);
      setProceedAutoInvestOrderClicked(true);
      setNotificationPopupType('success');
    } catch (error) {
      let customError = null;
      try {
        customError = JSON.parse(error.message);
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
      }
      setPopUpMessage(customError?.message);
      setProceedAutoInvestOrderClicked(true);
      setNotificationPopupType('error');
    } finally {
      handlePopUpClosed();
      setLoading(false);
    }
  };

  return (
    <Box className={classes.container}>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ margin: 'auto', maxHeight: '100%' }}
        ref={tableEl}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead className={classes.tableHead}>
            <StyledTableRow className={classes.tableRow}>
              <StyledTableCell component="th" className={classes.headerCell}></StyledTableCell>
              <StyledTableCell component="th" className={classes.headerCell}>
                Date
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Qty
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Period
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Status
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                <CustomButton type="moreinfo" buttonText="i" tooltipTitle={tooltipContent.autoInvestHistory} />
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell} />
            </StyledTableRow>
          </TableHead>
          <TableBody className={classes.containerTableBody}>
            {!mobileAutoInvests.length && !loading ? (
              <React.Fragment>
                <TableRow>
                  <TableCell colSpan={5} style={{ whiteSpace: 'nowrap' }} align="center">
                    No recent transactions
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ) : page === allPages && !loading && !isInHistory ? (
              <React.Fragment>
                {mobileAutoInvests.map((row) => (
                  <Row key={row.id} row={row} handlePopUpOpen={handlePopUpOpen} />
                ))}
                {[1, 2, 3].map((value, subIndex) => (
                  <TableRow key={`sub-row-${value}-${subIndex}`}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {mobileAutoInvests.map((row) => (
                  <Row key={row.id} row={row} handlePopUpOpen={handlePopUpOpen} />
                ))}
                {[1, 2].map((value, subIndex) => (
                  <TableRow key={`sub-row-${value}-${subIndex}`}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingBar open={loading} />
      {proceedAutoInvestOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedAutoInvestOrderClicked}
          setIsPopupOpen={setProceedAutoInvestOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      <ConfirmPopUp
        open={open}
        setOpen={setOpen}
        orderId={cancelOrderId}
        message={'Please confirm closing'}
        save={handleCancelAutoInvestOrder}
      />
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
    </Box>
  );
}

export default MobileAutoInvestHistoryTable;

const useStyles = makeStyles((theme) => ({
  containerTableBody: {
    position: 'relative',
    overflowY: 'auto',
  },
  headerCell: {
    backgroundColor: 'var(--mobile-grey)',
    zIndex: 2,
    height: '36px',
  },
  container: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      fontSize: 'clamp(var(--font-size-11), var(--font-size-12), var(--font-size-13)) !important',
    },
    height: '100%',
    '@media (orientation: landscape)': {
      height: '100%',
      maxHeight: '100%',
    },
  },
  tableHead: {
    position: 'relative',
  },
  tableRow: {
    '& .MuiTableCell-root': {
      padding: '0px',
      maxHeight: '30px',
    },
    '& th': {
      fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
    },
  },
  firstStyledTableCell: {
    textAlign: 'right',
    [theme.breakpoints.up('500')]: {
      textAlign: 'center',
    },
  },
  closeButton: {
    color: 'var(--black)',
    borderRadius: '24px',
    border: '1px solid var(--red)',
    width: '70%',
    maxWidth: '5rem',
    minHeight: '15px',
    height: '1.7rem',
    backgroundColor: 'var(--white)',
    fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
  },

  closeButtonHolder: {
    marginRight: '10px',
    padding: '10px',
  },
}));
