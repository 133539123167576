import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import AppLayout from "../../../components/common/Layout/AppLayout";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import { routes } from "../../../routes";
import { userNotifications } from "../../../service/notifications";
import UserNotificationPopup from "../../../components/common/Popups/UserNotificationPopup/UserNotificationPopup";
import SystemMessageIcon from "../../../assets/images/system-message-notification-icon.svg";
import NewsAnnouncementIcon from "../../../assets/images/news-announcement-icon.svg";
import LoadingBar from "../../../components/common/LoadingBar/LoadingBar";
import { formatDistanceToNowStrict } from "date-fns";
import { useNotificationsContext } from "../../../context/NotificationsContext";
import { useRef } from "react";
import { useAppContext } from "../../../context/DataContext";

function Notifications() {
  const classes = useStyles();
  const { notifications, setNotifications, page, setPage } =
    useNotificationsContext();
  const { unreadNotificationsCounter, setUnreadNotificationsCounter } =
    useAppContext();
  const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    notificationId: null,
    icon: "",
    title: "",
  });

  const notificationsRef = useRef();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const fetchUserNotifications = async () => {
    setLoading(true);
    try {
      const fetchedNotifications = await userNotifications.getUserNotifications(
        { page, pageSize }
      );
      setNotifications((prevNotifications) => {
        const uniqueNotifications = fetchedNotifications?.notifications?.filter(
          (notification) =>
            !prevNotifications.some(
              (existingNotification) =>
                existingNotification.id === notification.id
            )
        );

        return [...prevNotifications, ...uniqueNotifications];
      });
      setPage(Number(fetchedNotifications?.page) + 1);

      if (fetchedNotifications.notifications.length < pageSize) {
        setReachedEnd(true);
      }
      setError("");
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setError("Error fetching user notifications");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (notifications.length === 0) {
      fetchUserNotifications();
    }
  }, []);

  const handleNotificationClicked = async (
    notificationId,
    type,
    title,
    message
  ) => {
    setLoading(true);
    try {
      await userNotifications.updateUserNotifications({
        notificationId,
      });
      const updatedNotifications = notifications.map((notification) => {
        if (notification.id === notificationId) {
          return {
            ...notification,
            isRead: true,
          };
        }
        return notification;
      });
      if (unreadNotificationsCounter > 0) {
        setUnreadNotificationsCounter((prevCounter) => prevCounter - 1);
      }
      setNotifications(updatedNotifications);
      setError("");
    } catch (error) {
      console.error("Error updating notification", error);
      setError("Error updating user notifications");
    } finally {
      setLoading(false);
    }
    setNotificationPopupOpen(true);
    setNotificationContent({
      notificationId: notificationId,
      icon: type === "NEWS" ? NewsAnnouncementIcon : SystemMessageIcon,
      title: title,
      message: message,
    });
  };

  const getTimeAgo = (date) => {
    return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
  };
  const loadMoreNotifications = async () => {
    try {
      await fetchUserNotifications();
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  function handleScroll() {
    const element = notificationsRef.current;
    if (element) {
      const { scrollTop, clientHeight, scrollHeight } = element;
      if (
        scrollTop + clientHeight >= scrollHeight - 80 &&
        !loadingMore &&
        !reachedEnd
      ) {
        setLoadingMore(true);
        loadMoreNotifications().then(() => {
          setLoadingMore(false);
        });
      }
    }
  }
  useEffect(() => {
    const menuElement = notificationsRef.current;
    if (menuElement) {
      menuElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (menuElement) {
        menuElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreNotifications, loadingMore]);

  return (
    <AppLayout isInNotificationsComponent={true}>
      <div>
        <MobileNavigationBack page={"Notifications"} route={routes.dashboard} />
        <ul ref={notificationsRef} className={classes.container}>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <li
                key={notification.id}
                className={classes.notificationItem}
                style={{
                  border: index % 2 === 1 ? "1px solid #F4F4F4" : "none",
                  backgroundColor: notification.isRead
                    ? "transparent"
                    : "#88888870",
                }}
                onClick={() =>
                  handleNotificationClicked(
                    notification.id,
                    notification.group.type,
                    notification.group.title,
                    notification.group.description
                  )
                }
              >
                <img
                  src={
                    notification.group.type === "NEWS"
                      ? NewsAnnouncementIcon
                      : SystemMessageIcon
                  }
                  alt={notification.title}
                  className={classes.icon}
                />
                <div className={classes.notificationContent}>
                  <div className={classes.title}>
                    {notification.group.title}
                  </div>
                  <div className={classes.message}>
                    {notification.group.shortDescription}
                  </div>
                  <div className={classes.time}>
                    {getTimeAgo(notification.group.date)}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className={classes.noData}>No notifications</div>
          )}
          {error && <div>{error}</div>}
        </ul>
      </div>
      {loading && <LoadingBar open={loading} />}
      {notificationPopupOpen && (
        <UserNotificationPopup
          icon={notificationContent.icon}
          title={notificationContent.title}
          message={notificationContent.message}
          isPopupOpen={notificationPopupOpen}
          setIsPopupOpen={setNotificationPopupOpen}
        />
      )}
    </AppLayout>
  );
}

export default Notifications;

const useStyles = makeStyles((theme) => ({
  container: {
    listStyleType: "none",
    padding: 0,
    height: "80vh",
    overflowY: "auto",
    marginTop: 0,
  },
  notificationItem: {
    display: "flex",
    alignItems: "center",
    padding: "5px 14px 5px 10px",
    textAlign: "start",
  },
  icon: {
    marginRight: theme.spacing(2),
    width: 24,
    height: 24,
  },
  notificationContent: {
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    alignItems: "center",
  },
  title: {
    fontSize: "11px",
    fontWeight: "600",
    color: "#303030",
    marginBottom: "2px",
  },
  message: {
    fontSize: "8px",
    fontWeight: "400",
    color: "#949494",
  },
  time: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "8px",
    fontStyle: "italic",
    color: "#4A4A4A !important",
  },
  noData: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    fontFamily: theme.typography.fontFamily,
    fontSize: "13px",
  },
}));
