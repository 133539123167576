export const serverCartDemo = {
  3040: {
    product: {
      id: 2234,
      name: "1g Gold Bar | PAMP Fortuna",
      price: 88.32,
      prices: {
        EUR: 88.32,
        USD: 94.68
      },
      metal: "Gold",
      weight: 1,
      images: [
        "https://stonexbullion.com/product/image/3002099/1g-gold-bar-pamp-fortuna-1.png",
        "https://stonexbullion.com/product/image/3002099/1g-gold-bar-pamp-fortuna-2.png",
      ],
      purity: "999.9/1000",
      quantity: 77,
      part_number: "3002099",
      mint: "PAMP Suisse",
    },
    quantity: 2,
  },
  1748: {
    product: {
      id: 1748,
      name: "1 oz Gold Bar | Argor-Heraeus",
      price: 2353.88,
      prices: {
        EUR: 2367.68,
        USD: 2537.68
      },
      weight: 31.1035,
      quantity: 551,
      part_number: "3002064",
      images: [
        "https://stonexbullion.com/product/image/3002064/1-oz-gold-bar-argor-heraeus-1.png",
      ],
      image_url:
        "https://stonexbullion.com/product/image/3002064/1-oz-gold-bar-argor-heraeus-1.png",
    },
    quantity: 2,
  },
};
