import { makeStyles } from '@mui/styles';
import { createStyles, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Tab, Tabs } from '@mui/material';

import { useAppContext } from '../../../context/DataContext';
import EqualArrowIcon from '../../../assets/images/Exclude.svg';
import PositiveArrowIcon from '../../../assets/images/arrow-up.svg';
import NegativeArrowIcon from '../../../assets/images/arrow-down.svg';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';

import IButton from '../../common/IButton/IButton';
import { tooltipContent } from '../../../constants/tooltipTexts';
import { InstrumentChart } from './Chart';
import brokerService from '../../../service/broker';
import { useCryptoContext } from '../../../context/CryptoContext';

import CloseIcon from '@mui/icons-material/Close';
import { useStore } from 'react-redux';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getPriceDifference(lastPrice, currentPrice) {
  let priceDifference = 0;
  const percentageDifference = ((currentPrice - lastPrice) / lastPrice) * 100;

  if (!isNaN(percentageDifference) && isFinite(percentageDifference)) {
    priceDifference = Number(percentageDifference).toFixed(2);
  } else {
    priceDifference = '';
  }

  return priceDifference;
}

function getAbsValueOfPriceDifference(lastPrice, currentPrice) {
  let priceDifference = Math.abs(currentPrice - lastPrice);

  if (!isNaN(priceDifference) && isFinite(priceDifference)) {
    priceDifference = Number(priceDifference).toFixed(2);
    priceDifference = priceDifference.toString().padStart(2, '0');
    priceDifference = '$' + priceDifference;
  } else {
    priceDifference = '';
  }

  return priceDifference;
}

function CryptoGoldHistory({ isInStaking, isInVaulting, onClose }) {
  const [value, setValue] = useState(0);
  const [chartData, setChartData] = useState([]);
  const isDemo = sessionStorage.getItem('isDemo') === 'true';
  const [price, setPrice] = useState();
  const { smooth, isMobile, isAppView, setSmooth, goldAskPrice, goldPriceForPeriod, setGoldHistoryPeriod } =
    useAppContext();
  const { activeInstrument } = useCryptoContext();
  const instrument = activeInstrument?.displayName?.split('/')?.[0];
  const decimals = price?.split('.')?.[1]?.length || 0;
  const [chosenPeriod, setChosenPeriod] = useState('1H');
  const isMobileScreen = isMobile || isAppView;
  const store = useStore();

  useEffect(() => {
    const fetchPriceFromRedux = () => {
      const price = store.getState().crypto.instruments?.[activeInstrument?.marketId]?.last;
      setPrice(price);
    };

    fetchPriceFromRedux(); // Initial fetch
    const intervalId = setInterval(fetchPriceFromRedux, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [store, activeInstrument?.marketId]);

  const getHistoryData = async (instrument, period) => {
    setChartData([]);

    let response;
    if (isDemo) {
      response = await brokerService.getDemoMarketHistory(instrument, period);
    } else {
      response = await brokerService.getMarketHistory(instrument, period);
    }

    if (response?.data?.length) {
      const modifiedData = response.data.map((item) => {
        return {
          x: new Date(item.start),
          y: [item.open, item.high, item.low, item.close],
        };
      });

      setChartData(modifiedData);
    }
  };

  useEffect(() => {
    getHistoryData(activeInstrument?.marketId, chosenPeriod);
  }, [chosenPeriod, activeInstrument, isDemo]);

  const classes = useStyles();
  const periods = ['1H', '1D', '3D', '1W', '1M'];
  const goldPrice = goldAskPrice;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedPeriod = periods[newValue];
    setChosenPeriod(selectedPeriod);
  };

  if (isDemo) {
    //TODO demo logic
  }

  const priceDiffPercentage = getPriceDifference(chartData?.[0]?.y?.[3], price);
  const absoluteValuePriceDifference = getAbsValueOfPriceDifference(chartData?.[0]?.y?.[3], price);

  const decimalPlaces = price?.split('.')?.[1]?.length;

  return (
    <Box sx={{ padding: (isInStaking || isInVaulting) && '1rem' }} className={classes.container}>
      {isMobileScreen && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            height: '10px',
            paddingRight: '34px',
            paddingTop: '4px',
          }}
        >
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      )}

      <Box className={classes.stakingHeader}>
        <Box className={classes.leftWidgets}>
          <Box className={classes.coin}>{instrument}</Box>
          <Box className={classes.price}>
            {price > 0 ? currencyFormatter(Number(price), 'transactions', 'USD', false, true, decimals) : '-'}
          </Box>
          {price > 0 && (
            <Box className={classes.priceDirection}>
              <>
                {priceDiffPercentage === 0 ||
                  (priceDiffPercentage === '0.00' && chartData.length > 0 && (
                    <img className={classes.arrowImg} src={EqualArrowIcon} alt="Equal Arrow" />
                  ))}
                {priceDiffPercentage > 0 && chartData.length > 0 && (
                  <img className={classes.arrowImg} src={PositiveArrowIcon} alt="Positive Arrow" />
                )}
                {priceDiffPercentage < 0 && chartData.length > 0 && (
                  <img className={classes.arrowImg} src={NegativeArrowIcon} alt="Negative Arrow" />
                )}
                {chartData.length === 0 ? null : (
                  <span className={classes.percentage}>
                    {priceDiffPercentage}% ({absoluteValuePriceDifference})
                  </span>
                )}
              </>
            </Box>
          )}
        </Box>
        <Box className={classes.tabs}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={isInStaking || isInVaulting ? classes.tabInStaking : classes.tab}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            <Tab label="1 H" {...a11yProps(0)} />
            <Tab label="1 D" {...a11yProps(1)} />
            <Tab label="3 D" {...a11yProps(2)} />
            <Tab label="1 W" {...a11yProps(3)} />
            <Tab label="1 M" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <IButton tooltipTitle={tooltipContent.cryptoAssetsPriceChart} />
      </Box>
      {goldPriceForPeriod?.length ? (
        smooth ? (
          <Box className={classes.chartContainerWrapper}>
            <InstrumentChart data={chartData} decimals={decimalPlaces} />
          </Box>
        ) : (
          <Box className={classes.loader}>
            <CircularProgress
              style={{
                borderRadius: '50%',
                color: 'grey',
                animation: 'spin 1s linear infinite',
              }}
            />
          </Box>
        )
      ) : (
        <Box className={classes.noDataAvailable}>{smooth ? 'Currently no data available' : null}</Box>
      )}
    </Box>
  );
}

export default CryptoGoldHistory;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      backgroundColor: '#F9FAFD',
      borderRadius: '24px',
      height: '100%',
      fontFamily: theme.typography.fontFamily,
      background: 'linear-gradient(to bottom, white, #F9FAFD)',
      padding: '6px',
    },
    tab: {
      minWidth: '7px',
      paddingTop: '16px',
      [theme.breakpoints.down('900')]: {
        marginBottom: '1rem',
      },
      '& .MuiTab-root': {
        minWidth: '30px',
        width: 'auto',
        minHeight: '22px',
        height: '100%',
        [theme.breakpoints.down('900')]: {
          minHeight: '16px',
          marginBottom: '1rem',
        },
      },
      '& .MuiTabs-flexContainer': {
        paddingLeft: '1rem',
        [theme.breakpoints.down('1300')]: {
          paddingLeft: '0rem',
        },
      },
      '& button': {
        borderRadius: '8px',
        color: 'var(--black)',
        fontWeight: 500,
        lineHeight: '3px',
        zIndex: 3,
        [theme.breakpoints.down('1600')]: {
          fontSize: 'var(--font-size-14)',
        },
        [theme.breakpoints.down('1500')]: {
          fontSize: 'var(--font-size-13)',
        },
        [theme.breakpoints.down('1300')]: {
          fontSize: 'var(--font-size-10)',
        },
        [theme.breakpoints.down('1200')]: {
          fontSize: 'var(--font-size-12)',
        },
        [theme.breakpoints.down('1150')]: {
          fontSize: 'var(--font-size-12)',
          paddingBottom: '0.2rem',
          paddingTop: '0.2rem',
        },
        [theme.breakpoints.down('900')]: {
          fontSize: 'var(--font-size-10)',
          paddingLeft: '0.3rem',
          paddingRight: '0.3rem',
          paddingBottom: '0.1rem',
          paddingTop: '0.1rem',
          minHeight: '16px',
        },
        fontFamily: theme.typography.fontFamily,
      },
      '& button.Mui-selected': {
        backgroundColor: 'var(--beige) !important',
        color: 'var(--white)',
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
        boxShadow: '2.1235246658325195px 2.1235246658325195px 0px 0px #0000000D;',
      },
    },
    tabInStaking: {
      minWidth: '6px',
      paddingTop: '10px',
      [theme.breakpoints.down('1200')]: {
        width: '300px',
      },
      [theme.breakpoints.down('1050')]: {
        width: '200px',
      },
      [theme.breakpoints.down('900')]: {
        marginBottom: '1rem',
        width: '200px',
        marginRight: '2rem',
      },
      [theme.breakpoints.down('850')]: {
        marginBottom: '1rem',
        width: '250px',
        marginRight: '3rem',
      },
      [theme.breakpoints.down('800')]: {
        marginBottom: '1rem',
        width: '300px',
        marginRight: '3rem',
      },
      '& .MuiTab-root': {
        minWidth: '30px',
        width: 'auto',
        minHeight: '22px',
        height: '100%',
        [theme.breakpoints.down('900')]: {
          minHeight: '16px',
          marginBottom: '1rem',
        },
      },
      '& .MuiTabs-flexContainer': {
        paddingLeft: '1rem',
        [theme.breakpoints.down('1300')]: {
          paddingLeft: '0rem',
        },
        [theme.breakpoints.down('1000')]: {
          marginRight: '1rem',
        },
        [theme.breakpoints.down('850')]: {
          marginRight: '3rem',
        },
        [theme.breakpoints.down('800')]: {
          marginRight: '5rem',
        },
      },
      '& button': {
        borderRadius: '8px',
        color: 'var(--black)',
        fontWeight: 500,
        lineHeight: '3px',
        zIndex: 3,
        [theme.breakpoints.down('1600')]: {
          fontSize: 'var(--font-size-14)',
        },
        [theme.breakpoints.down('1500')]: {
          fontSize: 'var(--font-size-13)',
        },
        [theme.breakpoints.down('1300')]: {
          fontSize: 'var(--font-size-10)',
        },
        [theme.breakpoints.down('1200')]: {
          fontSize: 'var(--font-size-10)',
          paddingBottom: '0.2rem',
          paddingTop: '0.2rem',
        },
        [theme.breakpoints.down('1150')]: {
          fontSize: 'var(--font-size-9)',
          paddingLeft: '0.3rem',
          paddingRight: '0.3rem',
          paddingBottom: '0.1rem',
          paddingTop: '0.1rem',
          minHeight: '16px',
        },
        [theme.breakpoints.down('900')]: {
          fontSize: 'var(--font-size-7)',
          paddingLeft: '0.2rem',
          paddingRight: '0.2rem',
          paddingBottom: '0.1rem',
          paddingTop: '0.1rem',
          minHeight: '12px',
        },
        fontFamily: theme.typography.fontFamily,
      },
      '& button.Mui-selected': {
        backgroundColor: 'var(--beige) !important',
        color: 'var(--white)',
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
        boxShadow: '2.1235246658325195px 2.1235246658325195px 0px 0px #0000000D;',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      width: '92%',
      padding: '14px',
    },
    stakingHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      width: '100%',
    },
    priceDirection: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      width: '100%',
      minHeight: '37px',
    },
    coin: {
      marginBottom: '3px',
      fontSize: 'var(--font-size-16)',
      fontWeight: '600',
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-12)',
      },
    },
    price: {
      marginBottom: '3px',
      fontSize: 'var(--font-size-34)',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-20)',
      },
      [theme.breakpoints.down('1200')]: {
        fontSize: 'var(--font-size-16)',
      },
    },
    leftWidgets: {
      textAlign: 'left',
    },
    tabs: {
      overflowX: 'hidden',
      zIndex: 1,
      marginTop: '7px',
      [theme.breakpoints.down('1250')]: {
        minWidth: '72%',
        width: '72%',
      },
      [theme.breakpoints.down('1200')]: {
        minWidth: 'auto',
        width: 'auto',
      },
    },
    chartWrapper: {
      position: 'relative',
      maxWidth: '90%',
      width: '90%',
      height: '253px',
      bottom: '22px',
      left: '77px',
      paddingBottom: '0.5rem',
      aspectRatio: 2.2,
      [theme.breakpoints.down('1840')]: {
        left: '30px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('1700')]: {
        height: '220px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('1600')]: {
        bottom: '0px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('1500')]: {
        height: '197px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('1380')]: {
        height: '184px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('1290')]: {
        height: '170px',
        top: '35px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('1230')]: {
        height: '170px',
        maxWidth: '90%',
        width: '90%',
        top: '35px',
        '& canvas': {
          paddingRight: '10px',
        },
      },
      [theme.breakpoints.down('1200')]: {
        height: '253px',
        maxWidth: '90%',
        width: '90%',
        top: 'unset',
        '& canvas': {
          paddingRight: '0px',
        },
      },
      [theme.breakpoints.down('1130')]: {
        height: '233px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('1050')]: {
        height: '200px',
        maxWidth: '90%',
        width: '90%',
        top: '25px',
      },
      [theme.breakpoints.down('920')]: {
        height: '180px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('846')]: {
        height: '163px',
        maxWidth: '90%',
        width: '90%',
      },
      [theme.breakpoints.down('787')]: {
        height: '154px',
        maxWidth: '90%',
        width: '90%',
      },
    },
    chartWrapperInStaking: {
      width: '97%',
      height: '70%',
      position: 'absolute',
      bottom: '25px',
      paddingBottom: '0.5rem',
      aspectRatio: 2.2,
      [theme.breakpoints.up('1900')]: {
        left: '30px',
      },
      [theme.breakpoints.down('1500')]: {
        width: '95%',
        bottom: '9px',
      },
      [theme.breakpoints.down('1300')]: {
        bottom: '0px',
      },
      [theme.breakpoints.down('1000')]: {
        top: '135px',
      },
    },
    chartWrapperInVaulting: {
      position: 'relative',
      bottom: '25px',
      paddingBottom: '0.5rem',
      aspectRatio: 2.2,
      [theme.breakpoints.down('1600')]: {
        top: '15px',
      },
      [theme.breakpoints.down('1200')]: {
        top: '30px',
      },
      [theme.breakpoints.down('1148')]: {
        marginBottom: '2px',
      },
    },
    percentage: {
      marginBottom: '3px',
      fontSize: 'var(--font-size-14)',
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('1800')]: {
        fontSize: 'var(--font-size-12)',
      },
      [theme.breakpoints.down('1600')]: {
        fontSize: 'var(--font-size-9)',
        marginBottom: '0px',
        marginTop: '3px',
      },
    },
    arrowImg: {
      width: '19%',
      height: '19%',
      paddingRight: '8px',
      bottom: '2px',
      position: 'relative',
      [theme.breakpoints.down('1400')]: {
        width: '16%',
        height: '16%',
      },
    },
    loader: {
      marginTop: '5rem',
      zIndex: 10,
    },
    noDataAvailable: {
      fontFamily: theme.typography.fontFamily,
      position: 'relative',
      paddingTop: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: '25px',
      aspectRatio: 2.2,
    },

    chartContainerWrapper: {
      padding: '8px',
    },
  }),
);
