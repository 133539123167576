export function createLabel(period, dates) {
  let label = [];
  if (period === "1H") {
    label = dates.map((date) => {
      const minutes = date.getMinutes().toString();
      return minutes;
    });
  } else if (period === "1D") {
    label = dates.map((date) => {
      const hours = date.getHours().toString();
      return hours;
    });
  } else if (period === "3D") {
    label = dates.map((date) => {
      const hours = date.getHours().toString();
      return hours;
    });
  } else if (period === "1W") {
    label = dates.map((date) => {
      const day = date.getDate().toString();
      return day;
    });
  } else if (period === "1M") {
    label = dates.map((date) => {
      const day = date.getDate().toString();
      return day;
    });
  }
  return label;
}
