import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/index.js";
import { useAppContext } from "../../../context/DataContext.js";

function StakingNavigation() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setGoldHistoryPeriod, setChosenPeriod } = useAppContext();
  function navigateToDashboard() {
    setGoldHistoryPeriod("1H");
    setChosenPeriod("1H");
    navigate(routes.dashboard);
    window.scrollTo(0, 0);
  }
  return (
    <Box className={classes.btn}>
      <CustomButton
        type="black"
        width="100%"
        height="24px"
        onClick={navigateToDashboard}
        buttonText="&#9664;&nbsp;&nbsp;Back to Dashboard"
      ></CustomButton>
    </Box>
  );
}

export default StakingNavigation;

const useStyles = makeStyles((theme) => ({
  btn: {
    textAlign: "left",
    marginBottom: 24,
    marginTop: 25,
    width: "15rem",
    [theme.breakpoints.down("1750")]: {
      width: "12.5rem",
    },
    [theme.breakpoints.down("1280")]: {
      width: "10rem",
    },
  },
}));
