import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import LoadingBar from "../../../common/LoadingBar/LoadingBar";
import CustomButton from "../../../common/CustomButton/CustomButton";
import { useEffect } from "react";
import { userService } from "../../../../service/user";

import "../../../../styles/global.fonts.css";
import { formatDate } from "../../../../helpers/wordsDateFormatter";
import CustomSpinner from "../../../common/CustomSpinner/CustomSpinner";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";
import { useAppContext } from "../../../../context/DataContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UserSessionHistoryPopUp = ({
  userSessions,
  isOpen,
  isLoading,
  setIsOpen,
  setInvalidate,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);

  useEffect(() => {
    if (userSessions && userSessions.length > 0) {
      setCurrentSession(userSessions[0]);
    }
  }, [userSessions]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogoutSession = async (sessionId, isCurrent) => {
    setLoading(true);

    if (isCurrent) {
      await userService.logout();
      localStorage.clear();
      sessionStorage.clear();
      setIsLoggedIn(false);
      navigate(routes.login);
    } else {
      await userService.logoutUserSession(sessionId);
      setInvalidate(true);
    }

    setLoading(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.popupTitle}
          >
            ACCOUNT LOGIN ACTIVITY
          </BootstrapDialogTitle>
          <DialogContent>
            {isLoading ? (
              <div className={classes.centeredElm}>
                <CustomSpinner />
              </div>
            ) : (
              <>
                <div className={classes.currentSessionsWrapper}>
                  <p className={classes.subHeading}>Current Sessions</p>

                  {currentSession && (
                    <ListItem
                      item={currentSession}
                      key={currentSession?.id}
                      handleLogoutSession={handleLogoutSession}
                    />
                  )}
                </div>

                <div className={classes.sessions}>
                  <p className={classes.subHeading}>Login on other devices</p>

                  <div className={classes.itemsWrapper}>
                    {userSessions &&
                      userSessions.length > 0 &&
                      userSessions
                        .slice(1)
                        .map((elm) => (
                          <ListItem
                            item={elm}
                            key={elm?.id}
                            handleLogoutSession={handleLogoutSession}
                          />
                        ))}
                  </div>
                </div>
              </>
            )}
          </DialogContent>
        </div>
      </BootstrapDialog>
      <LoadingBar open={loading} />
    </div>
  );
};

const ListItem = ({ item, handleLogoutSession }) => {
  const classes = useStyles();
  const isActiveSession = item.activeSession;
  const isCurrentSession = item.currentSession;
  const showLogoutButton = isActiveSession || isCurrentSession;

  const handleLogoutClick = () => {
    if (isCurrentSession) {
      handleLogoutSession(item.id, true);
    } else {
      handleLogoutSession(item.id);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.itemWrapper}>
        <p className={classes.os}>{item.os}</p>

        <p>{item.ip}</p>

        {!isCurrentSession && <p>{formatDate(item?.createdAt)}</p>}

        {isCurrentSession && (
          <span className={classes.greenText}>This device</span>
        )}
      </div>

      {showLogoutButton && (
        <CustomButton
          onClick={handleLogoutClick}
          width={80}
          height={40}
          variant="text"
          buttonText="Logout"
          type={isCurrentSession ? "green" : "black"}
        />
      )}
    </div>
  );
};

export default UserSessionHistoryPopUp;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "777px",
      maxHeight: "450px",
      height: "100%",
      width: "100%",
      minWidth: "300px",
    },
    fontFamily: theme.typography.fontFamily,
  },

  popupContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  popupTitle: {
    fontSize: "var(--font-size-22)",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginTop: "1rem",
    paddingLeft: "2rem",
    [theme.breakpoints.down("400")]: {
      fontSize: "var(--font-size-17)",
      paddingLeft: "1rem",
    },
  },

  wrapper: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",

    padding: "6px",
    borderRadius: "8px",
    gridTemplateColumns: "1fr 80px",
    borderBottom: "1px solid #E0E0E0",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",

    "& .MuiTypography-button": {
      fontSize: "14px !important",
      fontWeight: "400 !important",
    },
  },

  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: "10px 0",
    "& p": {
      margin: 0,
      minWidth: "70px",
    },
  },

  os: {
    fontWeight: "600",
  },

  itemsWrapper: {
    display: "grid",
    gap: "10px",
  },

  subHeading: {
    fontSize: "18px",
    fontWeight: "bold",
    display: "inline-block",
  },

  greenText: {
    color: "var(--green-light)",
  },

  centeredElm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
