import { fetchWrapper } from "../../utils/fetchWrapper";

const getAllStakedGold = async () => {
  return await fetchWrapper.get(``);
};

const createStake = async (stake) => {
  try {
    return await fetchWrapper.post(`/orders/stake/create`, stake);
  } catch (e) {
    throw e;
  }
};

const cancelStake = async (id) => {
  try {
    return await fetchWrapper.post(`/orders/stake/cancel`, id);
  } catch (e) {
    throw e;
  }
};

const serviceStaking = {
  getAllStakedGold,
  createStake,
  cancelStake,
};

export default serviceStaking;
