import * as React from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TransactionPanel from '../../WebComponents/BuySellCard/TransactionPanel';
import { useAppContext } from '../../../context/DataContext';
import { GoldToMoneyConverter } from '../../../helpers/goldToMoneyConverter';
import NotificationMessagePopup from '../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import buyServices from '../../../service/buyService';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import sellServices from '../../../service/sellService';
import { messages } from '../../../constants/popupMessages';
import { isMarketOpen } from '../../../helpers/isMarketOpen';
import { NON_VERIFIED } from '../../../constants/globalconstants';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { Button } from '@mui/material';
import { errorHandlingWrapper } from '../../../helpers/errorHandlingWrapper';
import isVerified from '../../../helpers/isVerified';

const BuySellCardMobile = ({ handleClose }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('1');
  const [buyingQuantity, setBuyingQuantity] = useState(0);
  const [sellingQuantity, setSellingQuantity] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [notificationPopupType, setNotificationPopupType] = useState('');
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    goldAskPrice,
    goldBidPrice,
    goldAskPriceForGroup,
    goldBidPriceForGroup,
    proceedBuyOrderClicked,
    setProceedBuyOrderClicked,
    proceedSellOrderClicked,
    setProceedSellOrderClicked,
    setNonVerifiedPopUpOpen,
  } = useAppContext();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setBuyingQuantity(0);
    setSellingQuantity(0);
    setErrorMessage('');
  };

  const handleChosenGramsToBuy = (value) => {
    setBuyingQuantity(value);
  };
  const handleChosenGramsToSell = (value) => {
    setSellingQuantity(value);
  };

  const handleBuyInputChange = (event) => {
    const value = event.target.value;
    var intValue = parseInt(value); // parsing for preventing entering 0 at the front
    if (isNaN(intValue)) {
      intValue = 0;
    }
    handleChosenGramsToBuy(intValue);
  };

  const handleSellInputChange = (event) => {
    const value = event.target.value;
    var intValue = parseInt(value); // parsing for preventing entering 0 at the front
    if (isNaN(intValue)) {
      intValue = 0;
    }
    handleChosenGramsToSell(intValue);
  };

  const handlePlaceBuyOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const newBuy = {
        volume: buyingQuantity,
        clientPricePerAsset: goldAskPriceForGroup,
      };
      setLoading(true);
      try {
        await buyServices.createBuyRequest(newBuy);
        setMessage(messages.orderSuccessful);
        setProceedBuyOrderClicked(true);
        setNotificationPopupType('success');
        setBuyingQuantity(0);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setMessage(customError?.message);
        setProceedBuyOrderClicked(true);
        setNotificationPopupType('error');
        setBuyingQuantity(0);
      } finally {
        setLoading(false);
      }
    } else {
      setMessage(messages.marketClosed);
      setProceedBuyOrderClicked(true);
      setNotificationPopupType('error');
      setBuyingQuantity(0);
    }
  };

  const handlePlaceSellOrder = async () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }

    const result = await isVerified(setNonVerifiedPopUpOpen, setLoading);

    if (result === false) {
      return;
    }

    const marketOpen = isMarketOpen();
    if (marketOpen) {
      const newSell = {
        volume: sellingQuantity,
        clientPricePerAsset: goldBidPriceForGroup,
      };
      setLoading(true);
      try {
        await sellServices.createSellRequest(newSell);
        setMessage(messages.orderClosed);
        setProceedSellOrderClicked(true);
        setNotificationPopupType('success');
        setSellingQuantity(0);
      } catch (error) {
        let customError = null;
        customError = errorHandlingWrapper(error, customError);

        setMessage(customError?.message);
        setProceedSellOrderClicked(true);
        setNotificationPopupType('error');
        setSellingQuantity(0);
      } finally {
        setLoading(false);
      }
    } else {
      setMessage(messages.marketClosed);
      setProceedSellOrderClicked(true);
      setNotificationPopupType('error');
      setSellingQuantity(0);
    }
  };

  const totalBuy = GoldToMoneyConverter(buyingQuantity, goldAskPrice); // standard group
  const totalBuyForGroup = GoldToMoneyConverter(buyingQuantity, goldAskPriceForGroup);

  const totalSell = GoldToMoneyConverter(sellingQuantity, goldBidPrice); // standard group
  const totalSellForGroup = GoldToMoneyConverter(sellingQuantity, goldBidPriceForGroup);

  const showSpecialPriceFieldForBuy = goldAskPrice !== goldAskPriceForGroup;
  const showSpecialPriceFieldForSell = goldBidPrice !== goldBidPriceForGroup;

  const isBuyButtonDisabled = () => {
    return buyingQuantity === '' || buyingQuantity === '0' || buyingQuantity === 0 || isNaN(buyingQuantity);
  };
  const isSellButtonDisabled = () => {
    return sellingQuantity === '' || sellingQuantity === '0' || sellingQuantity === 0 || isNaN(sellingQuantity);
  };

  return (
    <Box
      sx={{
        background: 'white',
        overflow: 'hidden',
        '& .css-1aquho2-MuiTabs-indicator': {
          backgroundColor: tabValue === '1' ? '#589E7D' : '#D14949',
          height: '3px',
        },
      }}
      className={classes.container}
    >
      <TabContext value={tabValue}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            borderTopRightRadius: '24px',
            borderTopLeftRadius: '24px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            background: '#ffffff',
          }}
        >
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              '& .MuiTab-root': {
                // paddingTop: "33px",
              },
              '& .MuiTabs-flexContainer': {
                marginRight: '30px',
                marginLeft: '20px',
              },
              '& .MuiTab-root.Mui-selected': {
                color: 'black',
                fontWeight: '600',
              },
            }}
          >
            <Tab label="BUY" className={classes.tab} value="1" />
            <Tab label="CLOSE ORDER" className={classes.tab} value="2" />
          </TabList>
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div className={classes.contentContainer}>
          <TabPanel value="1">
            <TransactionPanel
              type="buy"
              errorMessage={errorMessage}
              quantity={buyingQuantity}
              handleInputChange={handleBuyInputChange}
              handleChosenGrams={handleChosenGramsToBuy}
              totalBuy={totalBuy}
              totalBuyForGroup={totalBuyForGroup}
              setBuyingQuantity={setBuyingQuantity}
              showSpecialPriceFieldForBuy={showSpecialPriceFieldForBuy}
            />
          </TabPanel>
          <TabPanel value="2">
            <TransactionPanel
              type="sell"
              errorMessage={errorMessage}
              quantity={sellingQuantity}
              handleInputChange={handleSellInputChange}
              handleChosenGrams={handleChosenGramsToSell}
              totalSell={totalSell}
              totalSellForGroup={totalSellForGroup}
              setSellingQuantity={setSellingQuantity}
              showSpecialPriceFieldForSell={showSpecialPriceFieldForSell}
            />
          </TabPanel>
          <div className={classes.helperContainer}>
            <div className={classes.blackButton}>
              <Button
                variant="outlined"
                disabled={tabValue === '1' ? isBuyButtonDisabled() : isSellButtonDisabled()}
                onClick={tabValue === '1' ? handlePlaceBuyOrder : handlePlaceSellOrder}
                className={classes.orderBtn}
              >
                Proceed with order
              </Button>
            </div>
          </div>
        </div>
      </TabContext>
      {proceedBuyOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedBuyOrderClicked}
          setIsPopupOpen={setProceedBuyOrderClicked}
          message={message}
          handleCloseAutoInvestPopup={handleClose}
        />
      )}
      {proceedSellOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === 'success' ? 'success' : 'error'}
          isPopupOpen={proceedSellOrderClicked}
          setIsPopupOpen={setProceedSellOrderClicked}
          message={message}
          handleCloseAutoInvestPopup={handleClose}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      <LoadingBar open={loading} />
    </Box>
  );
};

export default BuySellCardMobile;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    width: '100%',
    margin: 0,
    height: '100%',
    border: '0.5px #D2D5D8',
    borderRadius: '24px',
    '& .MuiTabPanel-root': {
      paddingTop: 0,
      [theme.breakpoints.down('1600')]: {
        paddingBottom: '0.5rem',
        paddingLeft: '4%',
        paddingRight: '4%',
      },
    },
  },
  contentContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
  },
  helperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('768')]: {
      paddingLeft: '9%',
      paddingRight: '9%',
    },
    '@media (orientation: landscape)': {
      [theme.breakpoints.down('768')]: {
        position: 'relative',
        bottom: '10px',
      },
    },
  },
  blackButton: {
    marginBottom: '30px',
  },
  orderBtn: {
    backgroundColor: 'black',
    fontSize: '12px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '60px',
    boxShadow: '0px 4.9726881980896px 0px 0px #76767633',
    cursor: 'pointer',
    border: 0,
    '&:hover': {
      backgroundColor: 'black',
      border: 0,
    },
    '&.Mui-disabled': {
      background: '#eaeaea',
      color: '#c0c0c0',
      border: 0,
    },
  },
  tab: {
    minWidth: '30px',
    width: '50%',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'var(--font-size-20)',
    [theme.breakpoints.down('1600')]: {
      fontSize: 'var(--font-size-19)',
    },
    [theme.breakpoints.down('1440')]: {
      fontSize: 'var(--font-size-17)',
    },
    [theme.breakpoints.down('1280')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('830')]: {
      fontSize: 'var(--font-size-14)',
      fontWeight: '600',
    },
    // [theme.breakpoints.down("400")]: {
    //   fontSize: "var(--font-size-12)",
    //   fontWeight: "600",
    // },
    '& .css-n9z7pb-MuiButtonBase-root-MuiTab-root.Mui-selected': {
      color: 'black',
    },
    '& .MuiTab-root': {
      minWidth: '30px',
    },
    '& .MuiTabs-flexContainer': {
      paddingLeft: '1rem',
    },
    '& button': {
      fontFamily: 'Poppins',
    },
  },
  iconButton: {
    position: 'absolute',
    zIndex: 2,
    right: 1,
    top: 1,
    color: 'black',
  },
}));
