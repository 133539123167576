import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Chip } from '@mui/material';
import { CURRENCY_TYPE } from '../../../constants/globalconstants';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import { getColor } from '../../../helpers/currencyFormatter/getColor';
import { makeStyles } from '@mui/styles';
import BullionzLogo from '../../../assets/images/bullionz-logo.svg';
import BullionzLogoDemo from '../../../assets/images/bullionz-mobile-logo-demo.svg';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import NonVerifiedAccountIcon from '../../../assets/images/non-verified-account-icon.svg';
import VerifiedAccountIcon from '../../../assets/images/verified-account-icon.svg';
import { coinName } from '../../../constants/globalconstants';
import CustomButton from '../CustomButton/CustomButton';
import { useAppContext } from '../../../context/DataContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import { calculatePercentageDifference } from '../../../helpers/calculatePercentageDifference';
import { tooltipContent } from '../../../constants/tooltipTexts';
import SlideMenu from '../SlideMenu/SlideMenu';
import DemoHeaderBackground from '../../../assets/images/header-demo-background-new-50.png';
import PleaseLoginPopUp from '../Popups/PleaseLoginPopup/PleaseLoginPopup';
import TransferFundsPopup from '../../MobileComponents/popups/TransferFundsPopup/TransferFundsPopup';
import { useStore } from 'react-redux';

function AppHeader() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [isTransferFundsPopUpOpen, setIsTransferFundsPopUpOpen] = useState(false);

  const {
    goldBidPriceForGroup,
    avgCoinPrice,
    tutorialStep,
    isOnHomePage,
    isPortfolioLoading,
    isAccountMenuOpened,
    IsSocketReconnecting,
    setIsAccountMenuOpened,
    portfolioAllocationData,
    activeTradeAccountBalance,
    userTradeAccounts,
    userBrokerAccounts,
    activeTradeAccountCurrency,
    isUserVerified,
    exchangeRate,
  } = useAppContext();
  const [marketPrices, setMarketPrices] = useState([]);
  const [cryptoBalance, setCryptoBalance] = useState(0);

  const store = useStore();

  const balance = isNaN(Number(activeTradeAccountBalance)) ? 0 : Number(activeTradeAccountBalance);

  const cachedUserCashBalance = sessionStorage.getItem('cashBalance');
  const cachedStakedCoins = sessionStorage.getItem('stakedCoins');
  const cachedVaultedCoins = sessionStorage.getItem('vaultedCoins');
  const cachedTotalAllocatedCoins = sessionStorage.getItem('totalAllocatedCoins');
  const cachedGoldBidPriceForGroup = sessionStorage.getItem('goldBidPriceForGroup');
  const cachedAvgCoinPrice = sessionStorage.getItem('avgCoinPrice');
  const isDemo = sessionStorage.getItem('isDemo');

  const totalCoins = portfolioAllocationData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const cachedTotalCoins =
    parseFloat(cachedStakedCoins) + parseFloat(cachedVaultedCoins) + parseFloat(cachedTotalAllocatedCoins);

  let averageCoinPrice = 0;
  let priceForGroup = 0;

  if (avgCoinPrice === undefined || avgCoinPrice === 'undefined' || avgCoinPrice === null || avgCoinPrice === 'null') {
    averageCoinPrice = cachedAvgCoinPrice;
  } else {
    averageCoinPrice = avgCoinPrice;
  }

  if (
    goldBidPriceForGroup === undefined ||
    goldBidPriceForGroup === 'undefined' ||
    goldBidPriceForGroup === null ||
    goldBidPriceForGroup === 'null'
  ) {
    priceForGroup = cachedGoldBidPriceForGroup;
  } else {
    priceForGroup = goldBidPriceForGroup;
  }

  const currentPl = calculatePercentageDifference(averageCoinPrice, priceForGroup);

  const statesOfChip = {
    success: `${currentPl.toFixed(1)} % ▲`,
    error: `${currentPl.toFixed(1)} % ▼`,
    info: `${currentPl.toFixed(1)} % ●`,
    default: '',
  };
  let accountValue = 0;
  const goldHoldings = totalCoins * goldBidPriceForGroup;
  const cachedGoldHoldings = cachedTotalCoins * cachedGoldBidPriceForGroup;

  if (portfolioAllocationData && !isPortfolioLoading && goldBidPriceForGroup) {
    accountValue = balance ? balance + goldHoldings : parseFloat(cachedUserCashBalance) + cachedGoldHoldings;
  } else {
    accountValue = parseFloat(cachedUserCashBalance) + cachedGoldHoldings;
  }

  function navigateToDashboard() {
    const appView = sessionStorage.getItem('isNative');
    if (!appView) {
      navigate(routes.dashboard);
    } else {
      return;
    }
  }

  const BTXG = document.getElementById('btxg-container');
  const newsContainer = document.getElementById('news-container');
  const buyGoldIcon = document.getElementById('buy-gold-icon');
  const portfolio = document.getElementById('portfolio-container');
  const transactions = document.getElementById('transactions-container');

  const balanceContainer = document.getElementById('balance-container');
  const goldHistory = document.getElementById('mobile-gold-history');
  const depositBtn = document.getElementById('deposit-btn');
  const redeemIcon = document.getElementById('redeem-icon');
  const redeemGoldIcon = document.getElementById('redeem-gold-icon');
  const tradeGoldBtn = document.getElementById('trade-gold-btn');
  const yieldingIcon = document.getElementById('yielding-icon');
  const vaultingIcon = document.getElementById('vaulting-icon');
  const monthlyPool = document.getElementById('monthly-pool');
  const buyCloseContainer = useMemo(() => document.getElementById('buyclose-container'));

  const blurAllItems = () => {
    balanceContainer?.classList?.remove('except');
    buyGoldIcon?.classList?.remove('except');
    depositBtn?.classList?.remove('except');
    redeemIcon?.classList?.remove('except');
    yieldingIcon?.classList?.remove('except');
    vaultingIcon?.classList?.remove('except');
    monthlyPool?.classList?.remove('except');
    buyCloseContainer?.classList?.remove('except');
    BTXG?.classList?.remove('except');
    portfolio?.classList?.remove('except');
    transactions?.classList?.remove('except');
    newsContainer?.classList?.remove('except');
    goldHistory?.classList?.remove('except');
    redeemGoldIcon?.classList?.remove('except');
    tradeGoldBtn?.classList?.remove('except');
  };

  const handleAccountIconClicked = () => {
    if (!isAccountMenuOpened) {
      blurAllItems();
    }
    setIsAccountMenuOpened((prevState) => !prevState);
  };

  let allBrokerAccountsBalance = 0;

  userBrokerAccounts?.map((account, index) => {
    const usdtBalance = account?.assets?.find((asset) => asset.assetId === 'usdt')?.amount || 0;
    allBrokerAccountsBalance += usdtBalance;
  });

  useEffect(() => {
    const fetchPriceFromRedux = () => {
      const prices = store.getState().crypto.instruments;
      setMarketPrices(prices);
    };

    fetchPriceFromRedux(); // Initial fetch
    const intervalId = setInterval(fetchPriceFromRedux, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [store]);

  const calculateCryptoBalance = useCallback(() => {
    let totalCryptoPrice = 0;

    userBrokerAccounts.forEach((account) => {
      const accountTotal = account?.assets?.reduce((acc, asset) => {
        const amount = asset?.amount || 0;
        const instrumentId = `${asset?.assetId}_usdt`;
        const bidPrice = Number(marketPrices?.[instrumentId]?.bid) || 0;
        const currAssetPrice = bidPrice * amount;

        return acc + currAssetPrice;
      }, 0);

      totalCryptoPrice += accountTotal;
    });

    if (isDemo) {
      const demoExchangeRate = activeTradeAccountCurrency === 'EUR' ? 1.09274 : 1;
      setCryptoBalance(
        Number((totalCryptoPrice / demoExchangeRate + allBrokerAccountsBalance / demoExchangeRate)?.toFixed(2)),
      );
    } else if (isExchangeRateValid()) {
      setCryptoBalance(Number((totalCryptoPrice / exchangeRate + allBrokerAccountsBalance / exchangeRate)?.toFixed(2)));
    } else {
      setCryptoBalance(0);
    }
  });

  const isExchangeRateValid = () => {
    if (
      (activeTradeAccountCurrency == 'EUR' && exchangeRate === 1) ||
      (activeTradeAccountCurrency == 'USD' && exchangeRate !== 1)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (marketPrices) {
      calculateCryptoBalance();
    }
  }, [marketPrices]);

  return (
    <Box className={classes.container}>
      <Box
        className={classes.header}
        style={
          isDemo
            ? {
                backgroundImage: `url(${DemoHeaderBackground})`,
                backgroundRepeat: 'repeat',
              }
            : {}
        }
      >
        <div className={classes.contentTop}>
          <div className={classes.logoContainer}>
            <img
              src={isDemo ? BullionzLogoDemo : BullionzLogo}
              className={isDemo ? classes.demoLogo : classes.logo}
              alt="Bullionz logo"
              onClick={() => navigateToDashboard()}
            />
          </div>
          <div className={classes.portfolioAndLoginContainer}>
            {/* <div className={classes.portfolioPLContainer} id="portfolio-pl">
              <div className={classes.text}>Portfolio P/L:</div>
              <Box className={classes.buttonContainer}>
                <Stack direction="row" textAlign="right">
                  <Chip
                    label={statesOfChip[getColor(averageCoinPrice, priceForGroup)]}
                    color={getColor(averageCoinPrice, priceForGroup)}
                  />
                </Stack>
              </Box>
            </div> */}
            <div className={classes.accountIconContainer} id="account-icon">
              <img
                id="account-side-menu"
                src={isUserVerified === 'false' ? NonVerifiedAccountIcon : VerifiedAccountIcon}
                className={classes.accountIcon}
                alt="Account Icon"
                onClick={tutorialStep === 2 && isOnHomePage ? null : handleAccountIconClicked}
              />
            </div>
          </div>
        </div>
        <div className={classes.contentBottom} id="mobile-header">
          <div className={classes.accountValueContainer} id="account-balance">
            <div className={classes.fieldTitle}>
              Total Balance
              <div className={classes.tooltip}>
                <CustomButton
                  type="moreinfo"
                  buttonText="i"
                  tooltipTitle={tooltipContent.totalBalance}
                  isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
                  inHeader
                ></CustomButton>
              </div>
            </div>
            <div className={classes.fieldValue}>
              {currencyFormatter(IsSocketReconnecting || !accountValue ? 0 : balance + goldHoldings + cryptoBalance)}
            </div>
          </div>
          <div className={classes.cashBalanceContainer} id="header-cash-balance">
            <div className={classes.fieldTitle}>
              Cash Balance
              <div className={classes.tooltip}>
                <CustomButton
                  type="moreinfo"
                  buttonText="i"
                  tooltipTitle={tooltipContent.cashBalance}
                  isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
                  inHeader
                ></CustomButton>
              </div>
            </div>
            <div className={classes.fieldValue}>
              {IsSocketReconnecting ? currencyFormatter(0, CURRENCY_TYPE) : currencyFormatter(balance, CURRENCY_TYPE)}
            </div>
          </div>
          <div className={classes.goldHoldingsContainer}>
            <div className={classes.fieldTitle} id="header-gold-holdings-label">
              Gold Holdings
              <div className={classes.tooltip}>
                <CustomButton
                  type="moreinfo"
                  buttonText="i"
                  tooltipTitle={tooltipContent.goldHoldings}
                  isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
                  inHeader
                ></CustomButton>
              </div>
            </div>
            <div className={classes.goldHoldingsValue} id="header-gold-holdings-value">
              {IsSocketReconnecting
                ? currencyFormatter(0, CURRENCY_TYPE)
                : currencyFormatter(goldHoldings || 0, CURRENCY_TYPE)}
            </div>
          </div>
          <div className={classes.btxgContainer}>
            <div className={classes.fieldTitle} id="header-gold-holdings-label">
              Crypto
              <div className={classes.tooltip}>
                <CustomButton
                  type="moreinfo"
                  buttonText="i"
                  tooltipTitle={tooltipContent.cryptoBalance}
                  isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
                  inHeader
                ></CustomButton>
              </div>
            </div>
            <div className={classes.goldHoldingsValue} id="header-gold-holdings-value">
              {activeTradeAccountCurrency === 'EUR'
                ? currencyFormatter(cryptoBalance || 0, '', '')
                : currencyFormatter(cryptoBalance || 0, 'crypto', 'USDT')}
            </div>
          </div>
        </div>
      </Box>
      {isAccountMenuOpened && (
        <SlideMenu
          isOpen={isAccountMenuOpened}
          toggleMenu={handleAccountIconClicked}
          setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
          setIsTransferFundsPopUpOpen={setIsTransferFundsPopUpOpen}
        />
      )}
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      {isTransferFundsPopUpOpen && (userTradeAccounts?.length || userBrokerAccounts?.length) && (
        <TransferFundsPopup
          isPopupOpen={isTransferFundsPopUpOpen}
          setIsPopupOpen={setIsTransferFundsPopUpOpen}
          userTradeAccounts={userTradeAccounts}
          userBrokerAccounts={userBrokerAccounts}
        />
      )}
    </Box>
  );
}

export default AppHeader;

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    maxHeight: '100%',
    '@media (orientation: landscape)': {
      maxHeight: '100%',
      // height: "48px",
    },
  },
  header: {
    fontSize: 'clamp(var(--font-size-12), var(--font-size-14), var(--font-size-19))',
    height: '100%',
    overflow: 'hidden',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxHeight: '110px',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    background: 'black',
    // "@media (orientation: landscape)": {
    //   height: "48px",
    // },
  },
  contentTop: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
    minHeight: '50px',
    height: '50px',
    maxHeight: '50px',
    // "@media (orientation: landscape)": {
    //   height: "48px",
    // },
  },
  logoContainer: {
    display: 'flex',
  },
  portfolioAndLoginContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50%',
    color: 'white',
    flex: '1',
    minHeight: '50px',
  },
  logo: {
    paddingBlock: '5px',
    height: '48px',
    minWidth: '80px',
  },
  demoLogo: {
    paddingBlock: '5px',
    height: '21px',
    minWidth: '80px',
  },
  portfolioPLContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    marginRight: '15px',
  },
  accountIconContainer: {
    display: 'flex',
  },
  accountIcon: {
    width: '32px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 'var(--font-size-8)',
    fontWeight: '400',
    color: '#BCBCBC',
    position: 'relative',
    right: '8%',
  },
  buttonContainer: {
    '& .MuiChip-root': {
      height: '16px',
      minWidth: '40px',
    },
    '& .MuiChip-label': {
      color: 'var(--white)',
      fontSize: 'var(--font-size-10)',
      paddingLeft: '5px',
      paddingRight: '5px',
      fontWeight: '700',
    },
  },
  contentBottom: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '92%',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontWeight: '400',
    paddingLeft: '8px',
  },
  accountValueContainer: {
    flex: '1',
    textAlign: 'center',
  },
  fieldTitle: {
    fontSize: 'var(--font-size-9)',
    color: '#FFFFFF',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  fieldValue: {
    display: 'flex',
    alignItems: 'flex-start',
    color: 'var(--gold)',
    fontSize: 'var(--font-size-14)',
    fontWeight: '700',
  },
  tooltip: {
    zIndex: '1',
    position: 'relative',
    left: '30px',
    bottom: '28px',
    [theme.breakpoints.down(360)]: {
      bottom: '39px',
      left: '22px',
    },
  },
  cashBalanceContainer: {
    flex: '1',
    textAlign: 'center',
  },
  goldHoldingsContainer: {
    flex: '1',
    textAlign: 'center',
  },
  btxgContainer: {
    textAlign: 'center',
  },
  goldHoldingsValue: {
    display: 'flex',
    color: 'var(--gold)',
    fontSize: 'var(--font-size-14)',
    fontWeight: '700',
  },
  coinName: {
    fontSize: 'var(--font-size-10)',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    color: '#D9D9D9',
    right: '2px',
  },
}));
