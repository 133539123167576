import { Box } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import MobileTotalMonthlyPool from '../MobileTotalMonthlyPool/MobileTotalMonthlyPool';
import MobileDashBoardTotalMonthlyCharts1 from '../../../common/Charts/MobileDashBoardTotalMonthlyCharts1';
import MobilePortfolioAllocation from '../MobilePortfolioAllocation/MobilePortfolioAllocation';
import CustomButton from '../../../common/CustomButton/CustomButton';
import MobileDashBoardTotalMonthlyCharts2 from '../../../common/Charts/MobileDashBoardTotalMonthlyCharts2';
import { tooltipContent } from '../../../../constants/tooltipTexts';
import { useAppContext } from '../../../../context/DataContext';
import PLGold from '../../../common/PLGold/PLGold';

function MobileTotalAndPortfolio({ pool }) {
  const classes = useStyles();
  const { tutorialStep } = useAppContext();

  return (
    <Box className={classes.mainContainer}>
      {/* <Box className={classes.PLContainer}>
        <div className={classes.text}>Portfolio P/L:</div>
        <PLGold />  
      </Box> */}
      <Box className={classes.container}>
        <Box className={classes.orientationLandscape}>
          <Box className={classes.chart} id="total-monthly-pool">
            <CustomButton
              type="moreinfo"
              onClick={() => {}}
              buttonText="i"
              tooltipTitle={tooltipContent.totalMonthlyPool}
              isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 13)}
            ></CustomButton>

            {pool && (
              <MobileTotalMonthlyPool
                chart1={<MobileDashBoardTotalMonthlyCharts1 pool={pool} />}
                chart2={<MobileDashBoardTotalMonthlyCharts2 pool={pool} />}
                classTitle={classes.title}
                classTab={classes.tab}
              />
            )}
          </Box>

          <Box className={classes.chart}>
            <CustomButton
              type="moreinfo"
              onClick={() => {}}
              buttonText="i"
              tooltipTitle={tooltipContent.portfolioAllocation}
              isTutorialTooltip={!(tutorialStep >= 1 && tutorialStep <= 14)}
            ></CustomButton>
            <MobilePortfolioAllocation tutorialStep={tutorialStep} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MobileTotalAndPortfolio;

const useStyles = makeStyles((theme) => ({


  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    padding: '10px 10px 0px 10px',
    paddingTop: '5.5vh',
  },
  PLContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '46.5%',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    flex: '1 1 auto',
    // paddingBottom: "60px",
    '@media (max-height:550px)': {
      height: '70%',
    },
    '@media (max-height:500px)': {
      height: '70%',
    },
    '@media (orientation: landscape)': {
      // height: "75%",
    },
    '@media (max-height: 370px) and (orientation: landscape)': {
      height: '93%',
      marginBottom: '22px',
    },
  },
  orientationLandscape: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'flex-start',
    width: 'var (100%)',
    position: 'relative',
    margin: '0 auto',
    gap: '20px',
    '@media (orientation: landscape)': {
      width: '75%',
      margin: '0 auto',
    },
    '@media (min-width: 768px) and (orientation: landscape)': {
      width: '65%',
      margin: 'auto',
    },
    '@media (max-height: 816px) and (orientation: landscape)': {
      paddingTop: '6px',
    },
    '@media (max-height: 450px) and (orientation: landscape)': {
      height: '100%',
    },
    '@media (min-width: 418px) and (orientation: portrait)': {
      width: '90%',
      display: 'flex',
      alignItems: 'flex-start',
      margin: '0 auto',
    },
  },
  chart: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    '@media (min-width: 900px) and (orientation: portrait)': {
      width: '80%',
      margin: '0 auto',
    },
    '@media (min-width: 600px) and (orientation: portrait)': {
      width: '43%',
      margin: '0 auto',
    },
    '@media (min-width: 620px) and (orientation: portrait)': {
      width: '38%',
      margin: '0 auto',
    },
  },
  title: {
    textAlign: 'left',
    width: '80%',
    fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-15))',
    fontWeight: '600',
    height: '36px',
  },
  tab: {
    minWidth: '18px',
    paddingTop: '0.5rem',
    '& .MuiTab-root': {
      minWidth: '20px',
      minHeight: '22px',
      width: '4.75rem',
      height: '100%',
    },
    '& .MuiTabs-root': {
      minHeight: '20px !important',
      overflow: 'hidden',
      height: '20px',
    },
    '& .MuiTabs-flexContainer': {
      paddingLeft: '0rem',
    },
    '& button': {
      borderRadius: '8px',
      color: 'var(--black)',
      fontWeight: 500,
      paddingLeft: '0.15rem',
      paddingRight: '0.15rem',
      lineHeight: '1px',
      paddingTop: '0.6rem',
      paddingBottom: '0.6rem',
      fontSize: 'var(--font-size-9)',
      fontFamily: theme.typography.fontFamily,
    },
    '& button.Mui-selected': {
      backgroundColor: 'var(--beige) !important',
      color: 'var(--white)',
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
    },
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontWeight: '500',
    color: 'rgba(188, 188, 188, 1)',
  },
}));
