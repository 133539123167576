import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import WebLayout from "../../../components/common/Layout/WebLayout";
import WithdrawComponent from "../../../components/WebComponents/WithrawComponent/WithdrawComponent";
import StakingNavigation from "../../../components/WebComponents/StakingNavigation/StakingNavigation";

function Withdraw() {
  const classes = useStyles();
  return (
    <WebLayout>
      <div className={classes.container}>
        <div>
          <WithdrawComponent />
        </div>
        <div className={classes.backButton}>
          <StakingNavigation />
        </div>
      </div>
    </WebLayout>
  );
}

export default Withdraw;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "98%",
    },
    backButton: {
      paddingLeft: "2rem",
    },
  })
);
