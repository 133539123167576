import { fetchWrapper } from "../../utils/fetchWrapper";

const getUserToken = async (loginCredentials) => {
  try {
    const token = await fetchWrapper.post(`/user/login`, loginCredentials);

    return token;
  } catch (error) {
    console.error("Error token:", error);
    throw new Error(error);
  }
};

export const loginService = {
  getUserToken,
};
