export function getUniformDataSet(arr, period, startTime, density) {
  let newArray = [];
  if (period === "1H") {
    let startTimeHour = new Date(arr[arr.length - 1]?.time);
    startTimeHour.setHours(startTimeHour.getHours() - 1);
    newArray[0] = { time: startTimeHour, price: arr[0]?.price };
    startTime = startTimeHour;
  } else {
    newArray[0] = { time: startTime, price: arr[0]?.price };
  }
  let j = 1;
  let tempDate;
  let step = ((60 * 60) / density) * 1000;
  if (period === "1H") {
    step = ((60 * 60) / density) * 1000;
  } else if (period === "1D") {
    step = ((24 * 60) / density) * 60000;
  } else if (period === "3D") {
    step = ((24 * 60 * 3) / density) * 60000;
  } else if (period === "1W") {
    // skipping weekend
    step = ((24 * 60 * 5) / density) * 60000;
  } else if (period === "1M") {
    // skipping weekends
    step = ((24 * 60 * 20) / density) * 60000;
  }
  let weekendSeconds = 48 * 60 * 60 * 1000;
  let offset = 0;
  let lastAccumulatedOffsetIx = -1;
  for (let i = 1; i < density; i++) {
    let temp1 = new Date(arr[j]?.time).getTime();
    let temp2 = new Date(arr[j - 1]?.time).getTime() + weekendSeconds;

    if (temp1 >= temp2 && lastAccumulatedOffsetIx !== j) {
      lastAccumulatedOffsetIx = j;
      offset += weekendSeconds;
    }

    tempDate = new Date(startTime.getTime() + i * step + offset);
    if (tempDate > new Date(arr[arr.length - 1]?.time)) {
      break;
    }
    while (new Date(arr[j]?.time) < tempDate && j < arr.length) {
      j++;
    }

    newArray[i] = { time: tempDate, price: arr[j - 1]?.price };
  }
  return newArray;
}

