import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import React, { useRef, useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Box, Button, Checkbox, InputAdornment, TextField } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import LoadingBar from '../../common/LoadingBar/LoadingBar';
import CustomButton from '../../common/CustomButton/CustomButton';
import { useCryptoContext } from '../../../context/CryptoContext';
import InvoiceGoldPopup from '../../common/Popups/InvoiceGoldPopup/InvoiceGoldPopup';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import { useCryptoAssets } from '../../WebComponents/CryptoAssets/hooks/use-crypto-assets';
import BuySellCryptoPopupMobile from '../popups/BuySellCryptoPopup/BuySellCryptoPopupMobile';

import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import clsx from 'clsx';
import ChartCryptoPopupMobile from '../popups/ChartPopup/ChartPopup';
import { useStore } from 'react-redux';
import brokerService from '../../../service/broker';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--mobile-grey)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row({ row = {}, handleBuySellClick, handleChartClick, setLoading, isFavorite }) {
  const classes = useStyles();
  const store = useStore();
  const [open, setOpen] = useState(false);
  const [marketPercentage, setMarketPercentage] = useState(0);
  const [marketOpenPrice, setMarketOpenPrice] = useState(0);
  const [marketLastPrice, setMarketLastPrice] = useState(0);

  const { setRefetchAssets } = useCryptoContext();

  useEffect(() => {
    const fetchPriceFromRedux = () => {
      const percentage = store.getState().crypto.instruments?.[row?.marketId]?.perc || 0;
      const open = store.getState().crypto.instruments?.[row?.marketId]?.open || 0;
      const last = store.getState().crypto.instruments[row?.marketId]?.last;

      setMarketPercentage(percentage);
      setMarketOpenPrice(open);
      setMarketLastPrice(last);
    };

    fetchPriceFromRedux(); // Initial fetch
    const intervalId = setInterval(fetchPriceFromRedux, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [store, row?.marketId]);

  const code = row?.displayName && row?.displayName?.split('/')?.[0];
  const price = marketLastPrice;
  const percentage = marketPercentage || 0;
  const decimals = (price && price?.split('.')?.[1]?.length) || 0;
  const priceFormatted = currencyFormatter(Number(price), 'transactions', 'USD', false, true, decimals);

  const openPrice = marketOpenPrice || 0;
  const plPercentage = (((price || 0) - openPrice) / openPrice) * 100;
  const isNegative = plPercentage < 0;

  const handleOpenRow = () => {
    setOpen(!open);
  };

  const handleAddFavorite = async (event) => {
    event.stopPropagation();
    const assetId = {
      assetId: code.toLowerCase(),
    };
    setLoading(true);
    try {
      if (!isFavorite) {
        await brokerService.addAssetToWatchlist(assetId);
      } else {
        await brokerService.deleteAssetFromWatchlist(assetId?.assetId);
      }
      setRefetchAssets((prev) => !prev);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.tableRow} onClick={handleOpenRow}>
        <StyledTableCell sx={{ width: '10%' }}>
          <IconButton
            disableRipple
            sx={{
              cursor: 'default',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            aria-label="transaction-table-arrow"
            size="small"
          >
            <img src={row?.url} alt="Sell Arrow Icon" className={classes.sellArrows} />
          </IconButton>
        </StyledTableCell>

        <StyledTableCell component="th" scope="row" sx={{ width: '20%' }}>
          <div className={classes.indexes}>
            <span>{row?.name}</span>

            <span>{row?.displayName && row?.displayName?.split('/')?.[0]}</span>
          </div>
        </StyledTableCell>

        <StyledTableCell align="center">{price ? priceFormatted : '-'}</StyledTableCell>

        <StyledTableCell align="right" sx={{ width: '20%' }}>
          {price > 0 && (
            <span className={clsx(isNegative ? classes.negativePL : classes.positivePL)}>
              {Number(percentage)?.toFixed(2)}
              {'%'}
            </span>
          )}
        </StyledTableCell>

        <StyledTableCell sx={{ width: '10%' }}>
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon style={{ color: '#347362' }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: '#347362' }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <StyledTableRow className={classes.tableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell className={classes.firstStyledTableCell}></StyledTableCell>

                  <StyledTableCell align="center"></StyledTableCell>

                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody className={classes.tableRowHidden}>
                <StyledTableRow>
                  <StyledTableCell width="33%" align="center">
                    <div className={classes.whiteButton}>
                      <CustomButton
                        type="black"
                        width="80px"
                        height="30px"
                        buttonText="Chart"
                        onClick={handleChartClick}
                      />
                    </div>
                  </StyledTableCell>

                  <StyledTableCell width="33%" scope="row" align="center" component="th">
                    <div className={classes.blackButton}>
                      <CustomButton
                        type="black"
                        width="80px"
                        height="30px"
                        buttonText="Trade"
                        onClick={handleBuySellClick}
                      />
                    </div>
                  </StyledTableCell>

                  <StyledTableCell width="33%">
                    <div className={classes.favoriteCheckbox}>
                      <Checkbox
                        icon={<StarIcon className={classes.star} />}
                        checkedIcon={<StarIcon className={classes.star} style={{ color: '#E0C38B' }} />}
                        onClick={handleAddFavorite}
                        checked={isFavorite}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function MobileCryptoDashboardTable() {
  const tableEl = useRef();
  const classes = useStyles();
  const { search, setSearch, userMarkets, isUserMarketsLoading, watchlist } = useCryptoAssets();

  const [allPages, setAllPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('assets');

  const { isBuySellOpened, setIsBuySellOpened, isChartOpened, setIsChartOpened, setActiveInstrument } =
    useCryptoContext();

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [userMarkets]);

  const handleCryptoAssetsClicked = () => {
    setTab('assets');
  };

  const handleWatchlistClicked = () => {
    setTab('watchlist');
  };

  const handleBuySellClick = (instrument) => {
    setActiveInstrument(instrument);
    setIsBuySellOpened(true);
  };

  const handleChartClick = (instrument) => {
    setActiveInstrument(instrument);
    setIsChartOpened(true);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filterUserMarkets = (markets) => {
    const watchlistArray = watchlist?.map((item) => item.assetId.toLowerCase());

    if (tab === 'assets') {
      return markets.map((market) => {
        const [baseAsset] = market.displayName.split('/').map((part) => part.toLowerCase());
        return { ...market, isFavorite: watchlistArray?.includes(baseAsset) };
      });
    }

    return markets
      .filter((market) => {
        const [baseAsset] = market.displayName.split('/').map((part) => part.toLowerCase());
        return watchlistArray?.includes(baseAsset);
      })
      .map((market) => ({ ...market, isFavorite: true }));
  };

  const filteredMarkets = filterUserMarkets(userMarkets);

  return (
    <Box className={classes.container}>
      {setIsBuySellOpened && (
        <BuySellCryptoPopupMobile isPopupOpen={isBuySellOpened} setIsPopupOpen={setIsBuySellOpened} />
      )}

      {isChartOpened && <ChartCryptoPopupMobile isPopupOpen={isChartOpened} setIsPopupOpen={setIsChartOpened} />}

      <div className={classes.tableHeaderWrapper}>
        <div className={classes.tabsContainer}>
          <Button
            className={clsx(classes.allAssetsTab, tab === 'assets' ? classes.selectedTab : classes.unselectedTab)}
            onClick={handleCryptoAssetsClicked}
          >
            crypto assets
          </Button>
          <Button
            className={clsx(classes.allAssetsTab, tab === 'watchlist' ? classes.selectedTab : classes.unselectedTab)}
            onClick={handleWatchlistClicked}
          >
            watchlist
          </Button>
        </div>

        <div className={classes.searchField}>
          <TextField
            value={search}
            variant="outlined"
            id="outlined-basic"
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ margin: 'auto', maxHeight: '100%' }}
        ref={tableEl}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead className={classes.tableHead}>
            <StyledTableRow className={classes.tableRow}>
              <StyledTableCell component="th" className={classes.headerCell} sx={{ width: '10%' }}></StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell} sx={{ width: '30%' }}></StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}></StyledTableCell>

              <StyledTableCell
                component="th"
                className={classes.headerCell}
                sx={{ width: '30%' }}
                align="center"
              ></StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody className={classes.containerTableBody}>
            {!filteredMarkets?.length && !isUserMarketsLoading ? (
              <React.Fragment>
                <TableRow>
                  <TableCell colSpan={5} style={{ whiteSpace: 'nowrap' }} align="center">
                    No {tab === 'watchlist' ? 'favorite ' : ''} assets
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ) : 1 === allPages && !loading ? (
              <React.Fragment>
                {filteredMarkets?.map((row) => {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      handleBuySellClick={() => handleBuySellClick(row)}
                      handleChartClick={() => handleChartClick(row)}
                      setLoading={setLoading}
                      isFavorite={row.isFavorite}
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              filteredMarkets?.map((row) => (
                <Row
                  row={row}
                  key={row?.marketId}
                  handleBuySellClick={() => handleBuySellClick(row)}
                  handleChartClick={() => handleChartClick(row)}
                  setLoading={setLoading}
                  isFavorite={row.isFavorite}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InvoiceGoldPopup />
      <LoadingBar open={loading} />
    </Box>
  );
}

export default MobileCryptoDashboardTable;

const useStyles = makeStyles((theme) => ({
  containerTableBody: {
    position: 'relative',
    overflowY: 'auto',
  },

  headerCell: {
    backgroundColor: 'var(--mobile-grey)',
    zIndex: 2,
    height: '36px',
  },

  container: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      fontSize: 'clamp(var(--font-size-11), var(--font-size-12), var(--font-size-13)) !important',
    },
    height: '100%',
    '@media (orientation: landscape)': {
      height: '300px',
      maxHeight: '100%',
    },
  },

  tableHead: {
    position: 'relative',
  },

  tableRow: {
    '& .MuiTableCell-root': {
      padding: '0px',
      maxHeight: '30px',
      height: 0,
    },
    '& th': {
      fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
    },
  },

  firstStyledTableCell: {
    textAlign: 'center',
    [theme.breakpoints.up('500')]: {
      textAlign: 'center',
    },
  },

  closeButton: {
    color: 'var(--black)',
    borderRadius: '24px',
    border: '1px solid var(--red)',
    width: '70%',
    maxWidth: '5rem',
    minHeight: '15px',
    height: '1.7rem',
    marginBottom: '1.5rem',
    backgroundColor: 'var(--white)',
    fontSize: 'clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important',
  },

  actionButtons: {
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    width: '100%',
    top: '65%',
  },

  sellArrows: {
    maxWidth: '20px',
    maxHeight: '20px',
    paddingRight: '6px',
  },

  storeCell: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  tableHeaderWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',

    height: '34px',
    background: '#F3F3F3',
  },

  tabsContainer: {
    display: 'flex',
  },

  allAssetsTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: '32px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',

    fontWeight: 600,
    fontSize: '12px',
    paddingInline: '10px',
    textTransform: 'uppercase',
    textWrap: 'nowrap',
  },

  selectedTab: {
    background: '#E0C38B',
    color: 'white',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#E0C38B',
    },
  },

  unselectedTab: {
    background: 'transparent',
    color: '#2D2D49',
    fontWeight: '400',
  },

  watchlistTab: {},

  search: {},

  searchField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '180px',
    height: '32px',

    '& .MuiOutlinedInput-root': {
      background: 'white',
      paddingRight: '4px',

      '& input': {
        padding: '2px',
      },
    },
  },

  tableRowHidden: {
    '& .MuiTableRow-root': {
      height: '50px',
      background: '#F7F7F7',
    },
  },

  negativePL: {
    color: '#E93556',
  },

  positivePL: {
    color: '#48D0A4',
  },

  indexes: {
    display: 'flex',
    flexDirection: 'column',

    width: '100%',
    // height: '40px !important',

    '& > :first-child': {
      fontWeight: 600,
    },
  },

  whiteButton: {
    '& button': {
      color: 'black',
      height: '33px',
      background: 'white',
      border: '1px solid black',
      boxShadow: '0px 3.19px 0px 0px #76767633',

      '&:hover': {
        background: 'white',
        color: 'black',
      },
    },
  },

  favoriteCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  star: {
    color: '#CDCDCD',
  },
}));
