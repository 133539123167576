// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-size-34: 1.9375rem;
  --font-size-30: 1.875rem;
  --font-size-28: 1.75rem;
  --font-size-27: 1.688rem;
  --font-size-26: 1.625rem;
  --font-size-24: 1.5rem;
  --font-size-23: 1.4375rem;
  --font-size-22: 1.375rem;
  --font-size-21: 1.3125rem;
  --font-size-20: 1.3rem;
  --font-size-19: 1.1875rem;
  --font-size-18: 1.125rem;
  --font-size-17: 1.0625rem;
  --font-size-16: 1rem;
  --font-size-15: 0.9rem;
  --font-size-14: 0.875rem;
  --font-size-13: 0.8125rem;
  --font-size-12: 0.75rem;
  --font-size-11: 0.6875rem;
  --font-size-10: 0.6rem;
  --font-size-9: 0.5625rem;
  --font-size-8: 0.5rem;
  --font-size-7: 0.4375rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.fonts.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,uBAAuB;EACvB,wBAAwB;EACxB,wBAAwB;EACxB,sBAAsB;EACtB,yBAAyB;EACzB,wBAAwB;EACxB,yBAAyB;EACzB,sBAAsB;EACtB,yBAAyB;EACzB,wBAAwB;EACxB,yBAAyB;EACzB,oBAAoB;EACpB,sBAAsB;EACtB,wBAAwB;EACxB,yBAAyB;EACzB,uBAAuB;EACvB,yBAAyB;EACzB,sBAAsB;EACtB,wBAAwB;EACxB,qBAAqB;EACrB,wBAAwB;AAC1B","sourcesContent":[":root {\n  --font-size-34: 1.9375rem;\n  --font-size-30: 1.875rem;\n  --font-size-28: 1.75rem;\n  --font-size-27: 1.688rem;\n  --font-size-26: 1.625rem;\n  --font-size-24: 1.5rem;\n  --font-size-23: 1.4375rem;\n  --font-size-22: 1.375rem;\n  --font-size-21: 1.3125rem;\n  --font-size-20: 1.3rem;\n  --font-size-19: 1.1875rem;\n  --font-size-18: 1.125rem;\n  --font-size-17: 1.0625rem;\n  --font-size-16: 1rem;\n  --font-size-15: 0.9rem;\n  --font-size-14: 0.875rem;\n  --font-size-13: 0.8125rem;\n  --font-size-12: 0.75rem;\n  --font-size-11: 0.6875rem;\n  --font-size-10: 0.6rem;\n  --font-size-9: 0.5625rem;\n  --font-size-8: 0.5rem;\n  --font-size-7: 0.4375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
