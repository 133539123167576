import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.fonts.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UserNotificationPopup = ({
  icon,
  title,
  message,
  isPopupOpen,
  setIsPopupOpen,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.popupTitleContainer}
          >
            <div className={classes.checkIcon}>
              <img
                style={{ borderRadius: "24px", width: "25px" }}
                src={icon}
                alt="Notification"
              />
            </div>
            <div className={classes.popupTitle}>{title}</div>
          </BootstrapDialogTitle>
          <DialogContent className={classes.contentContainer}>
            <div className={classes.message}>
              <Typography className={classes.popupSubtitle} gutterBottom>
                {message}
              </Typography>
            </div>
          </DialogContent>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default UserNotificationPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "900px",
      width: "100%",
      height: "50%",

      [theme.breakpoints.down("1800")]: {
        width: "50%",
      },
      [theme.breakpoints.down("768")]: {
        width: "unset",
        height: "unset",
        minWidth: "302px",
      },
    },
  },
  popupContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    [theme.breakpoints.down("768")]: {
      height: "309px",
    },
  },
  message: {
    position: "relative",
    display: "flex",
    backgroundColor: "#F4F4F4",
    zIndex: "1",
    padding: "17px",
  },
  popupTitleContainer: {
    display: "flex",
    borderRadius: "24px",
    fontSize: "var(--font-size-19)",
    paddingLeft: "3%",
    position: "relative",
    paddingBottom: "0px",
    height: "73px",
    alignItems: "center",
    [theme.breakpoints.down("1500")]: {
      fontSize: "var(--font-size-18)",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-17)",
    },
    [theme.breakpoints.down("768")]: {
      fontSize: "var(--font-size-14)",
      fontWeight: "600",
    },
  },
  popupTitle: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
    width: "86%",
    top: "5px",
  },
  checkIcon: {
    marginRight: "7px",
    display: "flex",
    alignItems: "baseline",
  },
  popupSubtitle: {
    color: "#767676",
    fontWeight: 400,
    fontSize: "var(--font-size-14)",
    [theme.breakpoints.down("1300")]: {
      fontSize: "var(--font-size-13)",
    },
    [theme.breakpoints.down("768")]: {
      fontSize: "var(--font-size-16)",
      fontWeight: "400",
    },
  },
  contentContainer: {
    position: "relative",
  },
}));
