import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import PortfolioGauge from "./PortfolioGauge/PortfolioGauge";
import PortfolioAllocationMiddle from "./PortfolioAllocationMiddle/PortfolioAllocationMiddle";
import PortFolioFooter from "./PortfolioFooter/PortFolioFooter";

function TotalBalance() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <PortfolioGauge />
        <PortfolioAllocationMiddle />
      </Box>
      <PortFolioFooter />
    </Box>
  );
}

export default TotalBalance;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      position: "relative",
      backgroundColor: "var(--white)",
      borderRadius: "24px",
      fontFamily: theme.typography.fontFamily,
      height: "98%",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  })
);
