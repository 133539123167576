import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MobileGoldHistory from "../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory";
import MobileTotalAndPortfolio from "../../../components/MobileComponents/MobileDashboard/MobileTotalAndPortfolio/MobileTotalAndPortfolio";
import TransactionsNews from "../../../components/MobileComponents/MobileDashboard/TransactionsNews/TransactionsNews";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import AppLayout from "../../../components/common/Layout/AppLayout";
import PleaseLoginPopUp from "../../../components/common/Popups/PleaseLoginPopup/PleaseLoginPopup";
import { useState } from "react";
import AppActionButtons from "../../../components/AppComponents/AppTradeGoldRedeemGoldButtons.js/AppActionButtons";

function AppDashboard({
  pool,
  isPleaseLoginPopupOpen,
  setIsPleaseLoginPopupOpen,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  function handleTransactionNavigation() {
    navigate(routes.transactions);
  }
  function handleNewsNavigation() {
    navigate(routes.news);
  }

  return (
    <AppLayout>
      <Box className={classes.container}>
        <MobileGoldHistory />
        <MobileTotalAndPortfolio pool={pool} />
        <TransactionsNews
          leftName={"TRANSACTIONS"}
          rightName={"NEWS"}
          handleLeftPanelNavigation={handleTransactionNavigation}
          handleRightPanelNavigation={handleNewsNavigation}
        />
        <AppActionButtons />
      </Box>
      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
    </AppLayout>
  );
}

export default AppDashboard;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "var(--linear-gradient-grey-background-mobile)",
    "@media (orientation: landscape)": {
      background: "none",
    },
  },
}));
