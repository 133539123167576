import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.fonts.css";
import { useState } from "react";
import DeleteAccountImage from "../../../../assets/images/delete-account-icon.svg";
import { Button } from "@mui/material";
import { useAppContext } from "../../../../context/DataContext";
import { userService } from "../../../../service/user";
import { routes } from "../../../../routes";
import { useNavigate } from "react-router-dom";
import ErrorPopup from "./ErrorPopup";
import LoadingBar from "../../LoadingBar/LoadingBar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeleteAccountPopup = ({ isPopupOpen, setIsPopupOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isMobile, setIsLoggedIn } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleDeleteAccount = () => {
    const appView = sessionStorage.getItem("isNative");
    if (appView) {
      localStorage.clear();
      sessionStorage.clear();
      window.webkit?.messageHandlers.notifyNative.postMessage(
        '{"Event": "Logout"}'
      );
    } else {
      try {
        localStorage.clear();
        sessionStorage.clear();
        setIsLoggedIn(false);
        navigate(routes.login);
      } catch (e) {
        console.error(e);
      }
    }
  };
  const handleYesClicked = async () => {
    setLoading(true);
    try {
      const response = await userService.deleteUserAccount();
      if (response?.deleted) {
        handleDeleteAccount();
      }
    } catch (error) {
      console.error(error);
      setIsErrorPopupOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const closePopups = () => {
    setIsPopupOpen(false);
    setIsErrorPopupOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.popupTitle}
        >
          DELETE YOUR ACCOUNT
        </BootstrapDialogTitle>
        <DialogContent>
          <Box className={classes.popupContent}>
            {!isMobile && (
              <div className={classes.subtitle}>
                Are you sure you want to close your account and delete all your
                data?
              </div>
            )}
            <div className={classes.imageContainer}>
              <img
                src={DeleteAccountImage}
                alt="Delete Account"
                className={classes.image}
              />
            </div>
            <div
              style={{
                flexDirection: isMobile ? "column" : "row",
                height: isMobile && "84px",
              }}
              className={classes.buttonContainer}
            >
              <Button
                className={classes.button}
                sx={{ width: isMobile && "85%" }}
                variant="outlined"
                onClick={handleYesClicked}
              >
                Yes
              </Button>
              <Button
                className={classes.button}
                sx={{
                  color: "white !important",
                  backgroundColor: "black",
                  width: isMobile && "85%",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                variant="contained"
                onClick={handleClose}
              >
                No
              </Button>
            </div>
          </Box>
        </DialogContent>
        {isErrorPopupOpen && (
          <ErrorPopup
            isOpen={isErrorPopupOpen}
            setIsOpen={() => closePopups()}
          />
        )}
      </BootstrapDialog>
      <LoadingBar open={loading} />
    </div>
  );
};

export default DeleteAccountPopup;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "777px",
      maxHeight: "600px",
      height: "100%",
      width: "100%",
      minWidth: "300px",
      [theme.breakpoints.down("768")]: {
        maxHeight: "577px",
      },
    },
    fontFamily: theme.typography.fontFamily,
  },
  popupTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: "600",
    justifyContent: "left",
    [theme.breakpoints.down("768")]: {
      fontSize: "19px",
    },
  },
  popupContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
    height: "88%",
    marginTop: "54px",
    [theme.breakpoints.down("768")]: {
      "@media (orientation: landscape)": {
        marginTop: "0",
        height: "unset",
      },
    },
  },
  subtitle: {
    display: "flex",
    width: "100%",
    fontSize: "18px",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    [theme.breakpoints.down("415")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    color: "black",
    border: "2px solid black",
    borderRadius: "60px",
    fontSize: "18px",
    fontWeight: "600",
    minWidth: "150px",
    maxHeight: "36px",
    fontFamily: theme.typography.fontFamily,
    boxShadow: "0px 3.1852872371673584px 0px 0px #76767633",
    "&:hover": {
      border: "1px solid black",
    },
  },
}));
