import React from 'react';
import { makeStyles } from '@mui/styles';
import { InputAdornment, TextField, createStyles } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

const AssetSearch = ({ search, setSearch }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <TextField
        className={classes.textField}
        id="outlined-basic"
        variant="outlined"
        value={search}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      '& .MuiOutlinedInput-root': {
        '& input': {
          padding: '6px',
        },
      },
    },
  }),
);

export default AssetSearch;
