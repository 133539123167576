import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import { useEffect } from "react";
import { getUserDepositTokenFromOurBe } from "../../../helpers/getUserDepositToken";
import { useState, useRef } from "react";
import LoadingBar from "../../common/LoadingBar/LoadingBar";
import { useAppContext } from "../../../context/DataContext";

function PaytikoDeposit({ isMobile }) {
  const [currentToken, setCurrentToken] = useState();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const isFirstRun = useRef(true);
  const { activeTradeAccountId } = useAppContext();
  const [paytikoIframeReload, setPaytikoIframeReload] = useState(false);

  useEffect(() => {
    isFirstRun.current = true;
    setIsDataFetched(false);
    setPaytikoIframeReload((prev) => !prev);
  }, [activeTradeAccountId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getUserDepositTokenFromOurBe();
        setCurrentToken(token);
        setIsDataFetched(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    if (!isDataFetched) {
      fetchData();
    } else {
      if (isFirstRun.current && window.PaytikoSdk && currentToken) {
        const SESSION_TOKEN = currentToken.cashierSessionToken;
        const IFRAME_CONTAINER_SELECTOR = "div#cashier-iframe-container";
        const DISPLAY_MODE = window.PAYTIKO_CASHIER_DISPLAY_MODE.IFRAME;
        const LOCALE = "en-US";
        const ORDER_ID = "3aa1e912-6ff3-4925-82dc-66bd7927676c";

        window.PaytikoSdk.cashier.invoke({
          //amount: 12.58,  not required for deposit / required for purchase
          orderId: ORDER_ID,
          environment: process.env.REACT_APP_PAYTIKO_ENVIRONMENT,
          sessionToken: SESSION_TOKEN,
          iframeContainerSelector: IFRAME_CONTAINER_SELECTOR,
          displayMode: DISPLAY_MODE,
          locale: LOCALE,
        });

        isFirstRun.current = false;
      }
    }
  }, [isDataFetched, currentToken, paytikoIframeReload]);

  return (
    <Box className={!isMobile ? classes.container : classes.mobileContainer}>
      <div
        id="cashier-iframe-container"
        className={!isMobile ? classes.paytiko : classes.mobilePaytiko}
      ></div>
      <LoadingBar open={loading} />
    </Box>
  );
}

export default PaytikoDeposit;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      backgroundColor: "var(--white)",
      margin: 0,
      height: "100%",
    },
    mobileContainer: {
      display: "flex",
      height: "93.5%",
      backgroundColor: "var(--white)",
      margin: 0,
    },
    paytiko: {
      paddingTop: "1rem",
      width: "100%",
      height: "100%",
      backgroundColor: "var(--white)",
      "& iframe": {
        width: "100%",
        height: "100%",
        border: "none !important",
      },
    },
    mobilePaytiko: {
      width: "100%",
      height: "100%",
      backgroundColor: "var(--white)",
      "& iframe": {
        width: "100%",
        height: "99%",
        border: "none !important",
      },
    },
  })
);
