import React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useCallback } from "react";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CalendarIcon from "../../../assets/images/stacks.svg";
import clsx from "clsx";
import { formatDateWrapper } from "../../../helpers/dateFormatter";
import serviceStaking from "../../../service/staking";
import { useEffect } from "react";
import { handleGetOrderMobileTransactions } from "../../../helpers/handleGetOrderTransactions";
import LoadingBar from "../../common/LoadingBar/LoadingBar";
import { useAppContext } from "../../../context/DataContext";
import NotificationMessagePopup from "../../common/Popups/NotificationMessagePopup/NotificationMessagePopup";
import ConfirmPopUp from "../../common/Popups/ConfirmPopUp/ConfirmPopUp";
import CustomButton from "../../common/CustomButton/CustomButton";
import { tooltipContent } from "../../../constants/tooltipTexts";
import { isMarketOpen } from "../../../helpers/isMarketOpen";
import { messages } from "../../../constants/popupMessages";
import PleaseLoginPopUp from "../../common/Popups/PleaseLoginPopup/PleaseLoginPopup";
import { handlePleaseLogin } from "../../../helpers/pleaseLoginOrRegisterForDemo";
import { errorHandlingWrapper } from "../../../helpers/errorHandlingWrapper";
import { generateCurrencySymbol } from "../../../helpers/generateCurrencySymbol";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--mobile-grey)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Row(props) {
  const { row, handlePopUpOpen } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { userTradeAccounts } = useAppContext();

  const tradeAccountsLength = userTradeAccounts?.length;

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={classes.tableRow}
      >
        <StyledTableCell>
          <IconButton
            disableRipple
            sx={{
              cursor: "default",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
            aria-label="staking-transactions"
            size="small"
          >
            <img
              src={CalendarIcon}
              alt="Buy Arrows"
              style={{ width: 12, height: 12, padding: 1 }}
            />
          </IconButton>
        </StyledTableCell>

        <StyledTableCell>
          {formatDateWrapper(new Date(row.date).getTime(), "long")}
        </StyledTableCell>

        <StyledTableCell>{row.volume}gr</StyledTableCell>

        <StyledTableCell>
          {formatDateWrapper(new Date(row.expDate).getTime(), "short")}
        </StyledTableCell>

        <StyledTableCell>{row.status}</StyledTableCell>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon style={{ color: "#347362" }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "#347362" }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <StyledTableRow className={classes.tableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow className={classes.tableRow}>
                  <StyledTableCell align="center">Transaction</StyledTableCell>
                  {tradeAccountsLength > 1 && (
                    <StyledTableCell align="center">Account</StyledTableCell>
                  )}
                  <StyledTableCell
                    className={clsx(classes.firstStyledTableCell)}
                    align="center"
                    width={"26%"}
                  >
                    {tradeAccountsLength > 1 ? "Upcom" : "Upcoming"}
                  </StyledTableCell>

                  <StyledTableCell
                    className={clsx(classes.firstStyledTableCell)}
                    align="center"
                  >
                    Paid
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={row.id} className={classes.tableRow}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.firstStyledTableCell}
                  >
                    {row.id}
                  </StyledTableCell>
                  {tradeAccountsLength > 1 && (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.firstStyledTableCell}
                    >
                      {row.tradeAccountId}
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.firstStyledTableCell}
                  >
                    {generateCurrencySymbol(row?.currency)}
                    {row.upcomingReward}
                  </StyledTableCell>

                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.firstStyledTableCell}
                  >
                    {generateCurrencySymbol(row?.currency)}
                    {row.paidReward}
                  </StyledTableCell>

                  {/* <StyledTableCell align="center">
                    {formatDateWrapper(
                      new Date(row.expDate).getTime(),
                      "short"
                    )}
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="center">{row.status}</StyledTableCell> */}
                  <StyledTableCell
                    className={classes.closeButtonHolder}
                    align="center"
                  >
                    <Button
                      className={classes.closeButton}
                      variant="outlined"
                      endIcon={<CloseIcon style={{ fontSize: 14 }} />}
                      onClick={() => handlePopUpOpen(row.transactionId)}
                      disabled={row.status !== "ACTIVE"}
                    >
                      Close
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function MobileStakingHistoryTable() {
  const classes = useStyles();
  const tableEl = useRef();
  const [loading, setLoading] = useState(true);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [allPages, setAllPages] = useState(1);
  const [page, setPage] = useState(1);
  const [stakeTransactions, setStakeTransactions] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState("");
  const [marketInfoMessagePopupOpen, setMarketInfoMessagePopupOpen] =
    useState(false);
  const [marketInfoMessage, setMarketInfoMessage] = useState("");
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const {
    proceedVaultOrderClicked,
    proceedStakeOrderClicked,
    setProceedStakeOrderClicked,
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
  } = useAppContext();

  const [hasMore, setHasMore] = useState(page <= allPages);

  useEffect(() => {
    handleGetOrderMobileTransactions(
      setStakeTransactions,
      page,
      20,
      setAllPages,
      "stake",
      setError,
      proceedStakeOrderClicked
    );
    if (stakeTransactions.length === 0) {
      setLoading(true);
    }
  }, [
    page,
    proceedVaultOrderClicked,
    proceedStakeOrderClicked,
    stakeTransactions.length,
    cancelOrderId,
  ]);

  useEffect(() => {
    if (stakeTransactions.length || error) {
      setLoading(false);
    }
  }, [stakeTransactions, error]);

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          if (page < allPages) {
            setPage((prev) => prev + 1);
          }
          setLoading(false);
          setHasMore(page < allPages);
          resolve();
        }, 1500)
      );
    };
    setLoading(true);
    if (page === allPages) {
      setLoading(false);
      return;
    }
    loadItems();
  }, [stakeTransactions, page]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (proceedStakeOrderClicked) {
      tableEl.current.scrollTop = 0;
    }
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener("scroll", scrollListener);
    return () => {
      tableRef.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  function handlePopUpOpen(id) {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    const marketOpen = isMarketOpen();
    if (marketOpen) {
      setCancelOrderId(id);
      setOpen(true);
    } else {
      setMarketInfoMessagePopupOpen(true);
      setMarketInfoMessage(messages.marketClosed);
    }
  }

  function handlePopUpClosed() {
    setOpen(false);
  }

  async function handleCancelStakeOrder(orderId) {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    const cancelledStake = {
      orderId: orderId,
    };
    handlePopUpClosed();
    setLoading(true);
    try {
      await serviceStaking.cancelStake(cancelledStake);
      setPopUpMessage(messages.orderClosed);
      setProceedStakeOrderClicked(true);
      setNotificationPopupType("success");
    } catch (e) {
      let customError = null;
      customError = errorHandlingWrapper(e, customError);
      setPopUpMessage(customError?.message);
      setProceedStakeOrderClicked(true);
      setNotificationPopupType("error");
      console.error("Error creating cancel stake:", customError?.message);
    } finally {
      handlePopUpClosed();
      setLoading(false);
    }
  }

  return (
    <Box className={classes.container}>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ margin: "auto", maxHeight: "100%" }}
        ref={tableEl}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead className={classes.tableHead}>
            <StyledTableRow className={classes.tableRow}>
              <StyledTableCell
                component="th"
                className={classes.headerCell}
              ></StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Date
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Qty
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Exp date
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                Status
              </StyledTableCell>

              <StyledTableCell component="th" className={classes.headerCell}>
                <CustomButton
                  type="moreinfo"
                  buttonText="i"
                  tooltipTitle={tooltipContent.stakingHistory}
                />
              </StyledTableCell>
              <StyledTableCell component="th" className={classes.headerCell} />
            </StyledTableRow>
          </TableHead>
          <TableBody className={classes.containerTableBody}>
            {!stakeTransactions.length && !loading ? (
              <React.Fragment>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{ whiteSpace: "nowrap" }}
                    align="center"
                  >
                    No recent transactions
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ) : page === allPages && !loading ? (
              <React.Fragment>
                {stakeTransactions.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    handlePopUpOpen={handlePopUpOpen}
                  />
                ))}
                {[1, 2].map((value, subIndex) => (
                  <TableRow key={`sub-row-${value}-${subIndex}`}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ))}
              </React.Fragment>
            ) : (
              stakeTransactions.map((row) => (
                <Row key={row.id} row={row} handlePopUpOpen={handlePopUpOpen} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingBar open={loading} />
      {proceedStakeOrderClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === "success" ? "success" : "error"}
          isPopupOpen={proceedStakeOrderClicked}
          setIsPopupOpen={setProceedStakeOrderClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={() => {}}
        />
      )}
      {marketInfoMessagePopupOpen && (
        <NotificationMessagePopup
          type={"error"}
          isPopupOpen={marketInfoMessagePopupOpen}
          setIsPopupOpen={setMarketInfoMessagePopupOpen}
          message={marketInfoMessage}
        />
      )}
      <ConfirmPopUp
        open={open}
        setOpen={setOpen}
        orderId={cancelOrderId}
        message={"Please confirm closing"}
        save={handleCancelStakeOrder}
      />
      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
    </Box>
  );
}

export default MobileStakingHistoryTable;

const useStyles = makeStyles((theme) => ({
  containerTableBody: {
    position: "relative",
    overflowY: "auto",
  },
  headerCell: {
    backgroundColor: "var(--mobile-grey)",
    zIndex: 2,
    height: "36px",
  },
  container: {
    "& .MuiTableCell-root.MuiTableCell-body": {
      fontSize:
        "clamp(var(--font-size-11), var(--font-size-12), var(--font-size-13)) !important",
    },
    height: "100%",
    "@media (orientation: landscape)": {
      maxHeight: "100%",
    },
  },
  tableHead: {
    position: "relative",
  },
  tableRow: {
    "& .MuiTableCell-root": {
      padding: "0px",
      maxHeight: "30px",
    },
    "& th": {
      fontSize:
        "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important",
    },
  },
  firstStyledTableCell: {
    textAlign: "center",
    [theme.breakpoints.up("500")]: {
      textAlign: "center",
    },
  },
  closeButton: {
    color: "var(--black)",
    borderRadius: "24px",
    border: "1px solid var(--red)",
    width: "70%",
    maxWidth: "5rem",
    minHeight: "15px",
    height: "1.7rem",
    marginBottom: "1.5rem",
    backgroundColor: "var(--white)",
    fontSize:
      "clamp(var(--font-size-12), var(--font-size-13), var(--font-size-14)) !important",
  },
}));
