import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import BackgroundLayer from '../../../assets/images/background-layer1.svg';
import '../../../styles/global.styles.css';
import '../../../styles/global.fonts.css';
import CustomButton from '../../common/CustomButton/CustomButton';
import BuyGoldCoin from '../../../assets/images/buy-gold-coin.svg';
import CryptoGoldCoin from '../../../assets/images/button_crypto.svg';
import StakingGoldCoin from '../../../assets/images/staking-gold-coin.svg';
import VaultingGoldCoin from '../../../assets/images/vaulting-gold-coin.svg';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';
import { CURRENCY_TYPE, euro } from '../../../constants/globalconstants';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import RedeemGoldPopup from '../../common/Popups/RedeemGoldPopup/RedeemGoldPopup';
import AutoInvestPopup from '../../common/Popups/AutoInvestPopup/AutoInvestPopup';
import { GoldToMoneyConverter } from '../../../helpers/goldToMoneyConverter';
import { useAppContext } from '../../../context/DataContext';
import IButton from '../../common/IButton/IButton';
import { redeemService } from '../../../service/redeem';
import NotificationMessagePopup from '../../common/Popups/NotificationMessagePopup/NotificationMessagePopup';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import { tooltipContent } from '../../../constants/tooltipTexts';
import { useEffect } from 'react';
import PleaseLoginPopUp from '../../common/Popups/PleaseLoginPopup/PleaseLoginPopup';
import { messages } from '../../../constants/popupMessages';
import { updateBucket } from '../../../helpers/update-client-bucket';
import { userService } from '../../../service/user';
import { errorHandlingWrapper } from '../../../helpers/errorHandlingWrapper';
import CryptoPopup from '../../common/Popups/CryptoPopup/CryptoPopup';
import { handlePleaseLogin } from '../../../helpers/pleaseLoginOrRegisterForDemo';
import NonVerifiedUserPopUp from '../../common/Popups/NonVerifiedUser/NonVerifiedUser';
import isVerified from '../../../helpers/isVerified';

const unavailableProductMessage = 'The selected product is unavailable.';

const NavigationBar = () => {
  const classes = useStyles();
  const [isAutoInvestPopupOpen, setIsAutoInvestPopupOpen] = useState(false);
  const [dollarsSpent, setDollarsSpent] = useState(0);
  const [availableGoldVariants, setAvailableGoldVariants] = useState(null);
  const [redeemGoldDeliveryFee, setRedeemGoldDeliveryFee] = useState(0);
  const [isRedeemFeeLoading, setIsRedeemFeeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userDetails, setUserDetails] = useState([]);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [showTBC, setShowTBC] = useState(false);
  const [openCryptoPopup, setOpenCryptoPopup] = useState(false);
  const [cryptoPopupMessage, setCryptoPopupMessage] = useState(false);
  const [cryptoPopupStep, setCryptoPopupStep] = useState(0);

  const {
    quantity,
    isAppView,
    stakedCoins,
    userCartData,
    vaultedCoins,
    setChosenPeriod,
    setTutorialStep,
    setIsCartFetched,
    setProductsBucket,
    setGoldHistoryPeriod,
    goldBidPriceForGroup,
    setIsTutorialLoading,
    isRedeemGoldPopupOpen,
    setIsRedeemGoldPopupOpen,
    userBrokerAccounts,
    isPortfolioLoading,
    nonVerifiedPopUpOpen,
    setNonVerifiedPopUpOpen,
  } = useAppContext();

  const navigate = useNavigate();

  const stakingMoney = GoldToMoneyConverter(stakedCoins, goldBidPriceForGroup);
  const vaultingMoney = GoldToMoneyConverter(vaultedCoins, goldBidPriceForGroup);

  // useEffect(() => {
  //   console.log('=====')
  //   handleGetUserDetails(setUserDetails, setIsTutorialLoading);
  // }, [isRedeemGoldPopupOpen]); // Redeem gold popup is not used anymore

  // useEffect(() => {
  //   handleGetUserCart(setUserCartData, () => {});
  // }, []);

  useEffect(() => {
    updateBucket(isAppView, userCartData, setIsCartFetched, setProductsBucket, userService?.updateShoppingCart);
  }, [userCartData]);

  function navigateToStaking() {
    setGoldHistoryPeriod('1H');
    setChosenPeriod('1H');
    navigate(routes.yielding);
  }

  function navigateToVaulting() {
    setGoldHistoryPeriod('1H');
    setChosenPeriod('1H');
    navigate(routes.vaulting);
  }

  function navigateToAutoInvestpage() {
    setGoldHistoryPeriod('1H');
    setChosenPeriod('1H');
    navigate(routes.autoInvest);
  }

  const getStoreFees = async (feesPayload) => {
    try {
      setRedeemGoldDeliveryFee(0);
      setIsRedeemFeeLoading(true);

      const storeFees = await redeemService.calculateFees(feesPayload);

      const isDemo = sessionStorage.getItem('isDemo') === 'true';
      const isFeeGreaterThanZero = storeFees?.deliveryFee > 0;

      if (storeFees?.error) {
        setErrorPopup(true);
        setIsRedeemFeeLoading(false);
        setErrorMessage(`Delivering to your country is still not available.
        Go one step back and use the "Add New Address" button to change the delivery address.`);
        return;
      } else if (!isFeeGreaterThanZero && !isDemo) {
        setErrorPopup(true);
        setIsRedeemFeeLoading(false);
        setErrorMessage(`Delivery to this address is not available at this time.
        Go one step back and use the "Add New Address" button to change the delivery address.`);
        return;
      } else if (storeFees?.usedCoins !== storeFees?.totalGrams && !isDemo) {
        setErrorPopup(true);
        setIsRedeemFeeLoading(false);
        setErrorMessage(messages.insufficientFunds);
      } else {
        setIsRedeemFeeLoading(false);
        setServiceFee(storeFees?.serviceFeeCoins);
        setRedeemGoldDeliveryFee(storeFees?.deliveryFee);
        setTotal(storeFees?.totalSumWithCoins);
      }
    } catch (error) {
      let customError = null;
      customError = errorHandlingWrapper(error, customError);
      if (customError?.statusCode === 400) {
        setErrorPopup(true);

        if (customError?.message === unavailableProductMessage) {
          setErrorMessage(customError?.message);
        } else {
          setErrorMessage(`Delivery to this address is not available at this time.
          Use the "Add New Address" button on the previous step to change the delivery address.`);
        }

        setShowTBC(true);
      }
      console.error('An error occurred while fetching fees:', error);
    }
  };

  const getAvailableGold = async () => {
    setLoading(true);
    setAvailableGoldVariants(null);
    try {
      const availableGold = await redeemService.getAvailableGoldVariants();
      if (availableGold === null) {
        setErrorPopup(true);
        setErrorMessage('An error occurred while fetching available gold variants');
      }
      setAvailableGoldVariants(availableGold);
    } catch (error) {
      console.error('An error occurred while fetching available gold variants:', error);
      setErrorPopup(true);
      setErrorMessage('An error occurred while fetching available gold variants');
    } finally {
      setLoading(false);
    }
  };

  const handleCryptoClicked = async () => {
    const isDemo = sessionStorage.getItem('isDemo') === 'true';

    if (isDemo) {
      navigate(routes.crypto);
      return;
    }

    if (!isPortfolioLoading) {
      if (userBrokerAccounts?.length > 0) {
        navigate(routes.crypto);
      } else {
        setCryptoPopupMessage(messages.createCryptoAccount);
        setOpenCryptoPopup(true);
        setCryptoPopupStep(1);
      }
    }
  };

  const handleBuyGoldClicked = () => {
    navigate(routes.store);
  };

  const handleRedeemGoldClicked = () => {
    setIsRedeemGoldPopupOpen(true);
    getAvailableGold();
  };

  const closePopups = () => {
    setErrorPopup(false);

    if (errorMessage?.includes('Delivering to your country') || errorMessage?.includes('Delivery to this address')) {
      setActiveStep(1);
    } else if (
      errorMessage?.includes(
        'Your order can not be executed due to insufficient funds. Please top-up the account and try again.',
      )
    ) {
      setActiveStep(0);
    } else {
      setServiceFee(0);
      setIsRedeemGoldPopupOpen(false);
    }
    setShowTBC(false);
  };

  const closeAfterDepositBtnClick = () => {
    setServiceFee(0);
    setIsRedeemGoldPopupOpen(false);
    setActiveStep(0);
  };

  const closeNonVerifiedPopUp = () => {
    setNonVerifiedPopUpOpen(false);
  };

  return (
    <Box className={classes.gradient}>
      <div className={classes.mainContent}>
        <div className={classes.backgroundImage}>
          <img src={BackgroundLayer} alt="Background" className={classes.image} />
        </div>
      </div>
      <div className={classes.positionAbsolute}>
        <div className={classes.mainContent}>
          <div className={classes.goldenButtons}>
            <div className={classes.buttonWrapper} id="crypto-icon">
              <div className={classes.buttonContainer}>
                <div className={classes.redeemInfoBtn}>
                  <IButton tooltipTitle={tooltipContent.crypto} />
                </div>
                <div className={classes.button} onClick={handleCryptoClicked}>
                  <img src={CryptoGoldCoin} alt="Crypto gold icon" className={classes.buyGoldBtn} />
                  <div className={classes.buyGoldTitleContainer}>
                    <div className={classes.title}>CRYPTO</div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.buttonWrapper} id="buy-icon">
              <div className={classes.buttonContainer}>
                <div className={classes.redeemInfoBtn}>
                  <IButton tooltipTitle={tooltipContent.storeBuyGold} />
                </div>
                <div className={classes.button} onClick={handleBuyGoldClicked}>
                  <img src={BuyGoldCoin} alt="Buy gold icon" className={classes.buyGoldBtn} />
                  <div className={classes.buyGoldTitleContainer}>
                    <div className={classes.title}>BUY GOLD</div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.buttonWrapper2} id="yielding-icon">
              <div className={classes.buttonContainer}>
                <div className={classes.infoBtn}>
                  <IButton tooltipTitle={tooltipContent.staking} />
                </div>
                <div className={classes.button} onClick={navigateToStaking}>
                  <img src={StakingGoldCoin} alt="Staking icon" className={classes.btn} />
                  <div className={classes.stateContainer}>
                    <div className={classes.title}>YIELDING</div>
                    <span className={classes.state}>
                      {currencyFormatter(stakedCoins || 0, 'gr')} /
                      <strong>{currencyFormatter(stakingMoney, CURRENCY_TYPE)}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.buttonWrapper2} id="vaulting-icon">
              <div className={classes.buttonContainer}>
                <div className={classes.infoBtn}>
                  <IButton tooltipTitle={tooltipContent.vaulting} />
                </div>
                <div className={classes.button} onClick={navigateToVaulting}>
                  <img src={VaultingGoldCoin} alt="Vaulting icon" className={classes.btn} />
                  <div className={classes.stateContainer}>
                    <div className={classes.title}>VAULTING</div>
                    <span className={classes.state}>
                      {currencyFormatter(vaultedCoins || 0, 'gr')} /
                      <strong>{currencyFormatter(vaultingMoney, CURRENCY_TYPE)}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box className={classes.autoInvestButton}>
        <CustomButton
          type="green"
          onClick={navigateToAutoInvestpage}
          buttonText="Auto Invest"
          width="100%"
          height="100%"
        ></CustomButton>
      </Box>
      {isRedeemGoldPopupOpen && (
        <RedeemGoldPopup
          isPopupOpen={isRedeemGoldPopupOpen}
          setIsPopupOpen={setIsRedeemGoldPopupOpen}
          availableGoldVariants={availableGoldVariants}
          getStoreFees={getStoreFees}
          deliveryFee={redeemGoldDeliveryFee}
          serviceFee={serviceFee}
          total={total}
          isRedeemFeeLoading={isRedeemFeeLoading}
          userDetails={userDetails}
          setIsPleaseLoginPopupOpen={setIsPleaseLoginPopupOpen}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          showTBC={showTBC}
          setServiceFee={setServiceFee}
        />
      )}
      {errorPopup && (
        <NotificationMessagePopup
          type={'error'}
          isPopupOpen={errorPopup}
          setIsPopupOpen={closePopups}
          message={errorMessage}
          handleCloseAutoInvestPopup={() => {
            setActiveStep(0);
          }}
          closeAfterDepositBtnClick={closeAfterDepositBtnClick}
        />
      )}
      {isAutoInvestPopupOpen && (
        <AutoInvestPopup
          type="buy"
          isPopupOpen={isAutoInvestPopupOpen}
          setIsPopupOpen={setIsAutoInvestPopupOpen}
          price={euro}
          quantity={10}
          total={dollarsSpent}
        />
      )}
      <div className={classes.infoBtnAutoInvest}>
        <IButton tooltipTitle={tooltipContent.autoInvest} />
      </div>
      <LoadingBar open={loading} />
      <PleaseLoginPopUp open={isPleaseLoginPopupOpen} setOpen={setIsPleaseLoginPopupOpen} />
      {openCryptoPopup && (
        <CryptoPopup
          isPopupOpen={openCryptoPopup}
          setIsPopupOpen={setOpenCryptoPopup}
          message={cryptoPopupMessage}
          setCryptoPopupMessage={setCryptoPopupMessage}
          cryptoPopupStep={cryptoPopupStep}
          setCryptoPopupStep={setCryptoPopupStep}
        />
      )}
      {nonVerifiedPopUpOpen && (
        <NonVerifiedUserPopUp
          type={'error'}
          isPopupOpen={nonVerifiedPopUpOpen}
          setIsPopupOpen={closeNonVerifiedPopUp}
          message={messages.accountNotVerified}
        />
      )}
    </Box>
  );
};

export default NavigationBar;

const useStyles = makeStyles((theme) => ({
  gradient: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    margin: 0,
    background: 'linear-gradient(#F1F1F1, #f1f1f10f)',
    marginBottom: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContent: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '8.25rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('1800')]: {
      width: '95%',
    },
  },
  positionAbsolute: {
    position: 'absolute',
  },
  backgroundImage: {
    position: 'relative',
    flex: '1',
    height: '100%',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    width: '22rem',
    height: '95%',
    top: '50%',
    right: '87%',
    transform: 'translate(50%, -50%)',
  },
  goldenCoinButton: {
    position: 'relative',
    display: 'flex',
  },
  buttonContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    position: 'absolute',
    top: '46%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 'var(--font-size-18)',
    color: '#FFEAC2',
    fontFamily: theme.typography.fontFamily,
    textShadow: '0px 1px 0px #CEAB70',
    lineHeight: '19.22px',
    [theme.breakpoints.down('1800')]: {
      fontSize: 'var(--font-size-16)',
    },
    [theme.breakpoints.down('1300')]: {
      fontSize: 'var(--font-size-12)',
      lineHeight: '15px',
    },
  },
  goldenButtons: {
    height: '100%',
    gap: '10%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    top: '0px',
    overflow: 'hidden',
    borderRadius: '60px',
    backgroundColor: 'white',
    paddingLeft: '6rem',
    paddingRight: '6rem',
    paddingBottom: '3px',
    right: '1%',
    width: '69%',
    [theme.breakpoints.down('1300')]: {
      height: '98%',
      paddingLeft: '4.5rem',
      paddingRight: '5rem',
    },
    '& button:hover': {
      background: 'none',
    },
  },
  stateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    top: '18px',
    [theme.breakpoints.down('1300')]: {
      top: '23px',
    },
  },
  buyGoldTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    top: '6.7px',
    width: 'max-content',
    left: '3px',
    [theme.breakpoints.down('1300')]: {
      top: '15px',
    },
  },
  redeemTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    top: '8px',
    width: 'max-content',
    left: '4px',
    [theme.breakpoints.down('1300')]: {
      top: '16px',
    },
  },
  title: {
    fontSize: 'var(--font-size-12)',
    fontWeight: '500',
    color: 'black',
    fontFamily: theme.typography.fontFamily,
    lineHeight: '14px',
    [theme.breakpoints.down('1537')]: {
      fontSize: 'var(--font-size-9)',
      lineHeight: '7px',
    },
  },
  state: {
    position: 'relative',
    fontSize: 'var(--font-size-8)',
    fontFamily: theme.typography.fontFamily,
    color: '#303030',
    textTransform: 'none',
    width: 'max-content',
    [theme.breakpoints.down('1537')]: {
      fontSize: 'var(--font-size-7)',
    },
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buyGoldBtn: {
    padding: '0px',
    border: 'none',
    background: 'none',
    width: '75px',
    marginBottom: '-22px',
    bottom: '20.5px',
    position: 'relative',
    left: '4px',
    [theme.breakpoints.down('1300')]: {
      width: '55px',
      position: 'relative',
      bottom: '9.5px',
    },
  },
  redeemGoldBtn: {
    padding: '0px',
    border: 'none',
    background: 'none',
    width: '75px',
    marginBottom: '-22px',
    bottom: '19px',
    position: 'relative',
    left: '4px',
    [theme.breakpoints.down('1300')]: {
      width: '55px',
      position: 'relative',
      bottom: '8px',
    },
  },
  btn: {
    padding: '0px',
    border: 'none',
    background: 'none',
    width: '75px',
    marginBottom: '-22px',
    bottom: '10px',
    position: 'relative',
    [theme.breakpoints.down('1300')]: {
      width: '55px',
      position: 'relative',
      bottom: '2px',
    },
  },
  infoBtn: {
    zIndex: '1',
    position: 'relative',
    left: '97px ',
    bottom: '64px',
    [theme.breakpoints.down('1800')]: {
      left: '97px ',
    },
    [theme.breakpoints.down('1300')]: {
      left: '80px ',
      bottom: '51px',
    },
  },
  redeemInfoBtn: {
    zIndex: '1',
    position: 'relative',
    left: '104px ',
    bottom: '67px',
    [theme.breakpoints.down('1800')]: {
      left: '104px ',
    },
    [theme.breakpoints.down('1537')]: {
      bottom: '68px',
    },
    [theme.breakpoints.down('1300')]: {
      left: '80px ',
      bottom: '53px',
    },
  },
  infoBtnAutoInvest: {
    zIndex: '1',
    position: 'relative',
    left: '3px ',
    bottom: '65px',
    [theme.breakpoints.down('1300')]: {
      left: '3px ',
      bottom: '52px',
    },
  },
  autoInvestButton: {
    width: '200px',
    height: '40px',
    right: '5%',
    '& .MuiTypography-root': {
      fontSize: 'var(--font-size-20) !important',
      [theme.breakpoints.down('1800')]: {
        fontSize: 'var(--font-size-14) !important',
      },
      [theme.breakpoints.down('1300')]: {
        fontSize: 'var(--font-size-10) !important',
      },
    },
    [theme.breakpoints.down('1800')]: {
      width: '150px',
      height: '30px',
    },
    [theme.breakpoints.down('1520')]: {
      right: '1%',
    },
    [theme.breakpoints.down('1400')]: {
      width: '110px',
      height: '25px',
    },
    [theme.breakpoints.down('1200')]: {
      right: '7%',
    },
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    width: 'auto',
    height: 'auto',
    marginTop: '2rem',
    cursor: 'pointer',
    [theme.breakpoints.down('1300')]: {
      marginTop: '2.5rem',
    },
  },
  buttonWrapper2: {
    width: 'auto',
    height: 'auto',
    marginTop: '1.5rem',
    cursor: 'pointer',
    [theme.breakpoints.down('1300')]: {
      marginTop: '2.1rem',
    },
  },
}));
