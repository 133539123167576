export function calculatePercentageDifference(number1, number2) {
  const difference = number2 - number1;
  if (number1 === 0 || number2 === 0) {
    return 0;
  }
  const percentageDifference = ((difference / number1) * 100).toFixed(1);
  if (percentageDifference === "Infinity") {
    return 0;
  }
  return Number(percentageDifference);
}
