import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "../../../styles/global.fonts.css";
import { useAppContext } from "../../../context/DataContext";

const LoadingBar = ({ open }) => {
  const classes = useStyles();
  const { isMobile } = useAppContext();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress
        style={{
          width: isMobile ? "40px" : "70px",
          height: isMobile ? "40px" : "70px",
          borderRadius: "50%",
          animation: "spin 1s linear infinite",
        }}
        color="inherit"
      />
      <span className={classes.loading}>Loading...</span>
    </Backdrop>
  );
};

export default LoadingBar;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    display: "flex",
    flexDirection: "column",
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  loading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "var(--font-size-19)",
  },
}));
