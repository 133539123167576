import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  Tooltip,
} from '@mui/material';
import { formatDateWrapper } from '../../../helpers/dateFormatter';
import { handleGetUserCrmTransactions } from '../../../helpers/handleGetUserCrmTransactions';
import GreenArrowDown from '../../../assets/images/green-arrow-down.svg';
import RedArrowUp from '../../../assets/images/red-arrow-up.svg';
import { useAppContext } from '../../../context/DataContext';
import currencyFormatter from '../../../helpers/currencyFormatter/currencyFormatter';

const ProfileTransactionHistoryTable = () => {
  const [accountTransactions, setAccountTransactions] = useState([]);

  const classes = useStyles();
  const tableEl = useRef();
  const [loading, setLoading] = useState(false);
  const { refetch } = useAppContext();

  useEffect(() => {
    handleGetUserCrmTransactions(setAccountTransactions, setLoading);
  }, [refetch]);

  return accountTransactions ? (
    <TableContainer
      style={{
        maxWidth: '660px',
        margin: 'auto',
        maxHeight: '390px',
        borderRadius: '12px',
        border: '1px solid #E0E0E0',
      }}
      ref={tableEl}
    >
      {loading && <CircularProgress style={{ position: 'absolute', top: '500px' }} />}
      <Table className={classes.table} stickyHeader>
        <TableHead className={classes.theadTh}>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: '#F3F3F3',
              }}
            ></TableCell>

            <TableCell
              sx={{
                backgroundColor: '#F3F3F3',
              }}
            >
              Transaction
            </TableCell>

            <TableCell sx={{ backgroundColor: '#F3F3F3' }}>Account</TableCell>

            <TableCell sx={{ backgroundColor: '#F3F3F3' }}>Date</TableCell>

            <TableCell sx={{ backgroundColor: '#F3F3F3' }}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accountTransactions.length > 0 ? (
            accountTransactions.map(({ id, date, tradeAccountId, amount, currency, transactionType, comment }) => (
              <TableRow key={id} className={classes.tbodyTr}>
                <TableCell sx={{ textAlign: 'center' }}>
                  <Tooltip title={comment} placement="top" arrow>
                    <img src={transactionType === 'Deposit' ? GreenArrowDown : RedArrowUp} alt="Transaction type" />
                  </Tooltip>
                </TableCell>
                <TableCell>{id}</TableCell>

                <TableCell>{tradeAccountId}</TableCell>

                <TableCell>{formatDateWrapper(date, 'long')}</TableCell>

                <TableCell>{currencyFormatter(amount, 'transactions', currency, false, true)}</TableCell>
              </TableRow>
            ))
          ) : (
            <React.Fragment>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell colSpan={5} component="th" scope="row" align="center" className={classes.noTrans}>
                  No Transactions History
                </TableCell>
              </TableRow>
            </React.Fragment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <></>
  );
};
export default ProfileTransactionHistoryTable;

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      width: '100%',
      marginBottom: '1rem',
      color: '#767676',
      borderCollapse: 'collapse',
      textAlign: 'left',
      [theme.breakpoints.down('930')]: {
        fontSize: 'var(--font-size-11) !important',
      },
    },
    tbodyTr: {
      borderBottom: 'none',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        fontSize: '10px',
        [theme.breakpoints.down('1150')]: {
          fontSize: 'var(--font-size-9) !important',
        },
        [theme.breakpoints.down('1150')]: {
          fontSize: 'var(--font-size-8) !important',
        },
      },
    },
    theadTh: {
      verticalAlign: 'bottom',
      borderBottom: '1px solid #dee2e6',
      position: 'relative',
      backgroundColor: '#F3F3F3',
      color: 'var(--black)',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        fontSize: '12px',
        [theme.breakpoints.down('1180')]: {
          fontSize: 'var(--font-size-11) !important',
        },
        [theme.breakpoints.down('1119')]: {
          fontSize: 'var(--font-size-10) !important',
        },
        [theme.breakpoints.down('950')]: {
          fontSize: 'var(--font-size-9) !important',
        },
        [theme.breakpoints.down('880')]: {
          fontSize: 'var(--font-size-8) !important',
        },
      },
    },
    '@media (max-width: 767px)': {
      table: {
        display: 'block',
        width: '100%',
        overflowX: 'auto',
      },
      thead: {
        display: 'block',
      },
    },
    noTrans: {
      width: '100%',
      textAlign: 'center',
      fontSize: '12px',
      [theme.breakpoints.down('1180')]: {
        fontSize: 'var(--font-size-11) !important',
      },
      [theme.breakpoints.down('1119')]: {
        fontSize: 'var(--font-size-10) !important',
      },
      [theme.breakpoints.down('950')]: {
        fontSize: 'var(--font-size-9) !important',
      },
      [theme.breakpoints.down('880')]: {
        fontSize: 'var(--font-size-8) !important',
      },
    },
  }),
);
