import { newsService } from "../service/news";

export async function handleGetNewsFeedAccessToken(fn, setLoading, setError) {
  setLoading(true);
  const isDemoValue = sessionStorage.getItem("isDemo");
  const isDemo = isDemoValue === "true";
  let token;
  try {
    if (isDemo) {
      token = await newsService.getDemoNewsFeedAccessToken();
    } else {
      token = await newsService.getNewsFeedAccessToken();
    }
    if (token) {
      // Update state only when token is received
      fn(token);
      setError(false); // Reset error state
    } else {
      setError(true);
    }
  } catch (e) {
    console.error(e);
    setError(true);
  } finally {
    setLoading(false);
  }
}
