import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.fonts.css";
import { useAppContext } from "../../../../context/DataContext";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import LoadingBar from "../../../common/LoadingBar/LoadingBar";
import NotificationMessagePopup from "../../../common/Popups/NotificationMessagePopup/NotificationMessagePopup";
import CustomButton from "../../../common/CustomButton/CustomButton";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { handleUpdateUserDeliveryAddress } from "../../../../helpers/handleUpdateUserDeliveryAddress";
import Autocomplete from "@mui/material/Autocomplete";
import { CanadaStates } from "../../../../constants/CanadaStates";
import { useEffect } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const handleInputValidation = (e, fn) => {
  const inputValue = e.target.value;
  const isValid = /^[^;]*$/.test(inputValue);

  if (isValid) {
    fn(inputValue);
  } else {
    const error = "";
    fn(error);
    setTimeout(() => {
      fn("");
    }, 1500);
  }
};

function MobileAddAddressPopUp({
  isPopupOpen,
  setIsPopupOpen,
  countries,
  userDetails,
}) {
  const {
    shippingAddress,
    shippingCity,
    shippingCountry,
    shippingPostalCode,
    shippingState,
  } = userDetails;

  const nameOfShippingCountry = shippingCountry?.name;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [addressLine1, setAddressLine1] = useState(shippingAddress || "");
  const [city, setCity] = useState(shippingCity || "");
  const [country, setCountry] = useState(nameOfShippingCountry || "");
  const [zip, setZip] = useState(shippingPostalCode || "");
  const [stateName, setStateName] = useState("");
  const [stateCode, setStateCode] = useState(shippingState);

  useEffect(() => {
    const country = CanadaStates.find(
      (country) => country.code === shippingState
    );
    if (country) {
      setStateName(country.name);
    }
  }, [shippingState, CanadaStates]);

  const {
    notificationPopupType,
    setNotificationPopupType,
    popUpMessage,
    setPopUpMessage,
    proceedAddNewAddressClicked,
    setProceedAddNewAddressClicked,
  } = useAppContext();

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  function getDeliveryCountryCode(name) {
    const deliveryCountry = countries.filter(
      (country) => country.name === name
    );
    return deliveryCountry[0]?.countryCode || "";
  }

  function getDeliveryCountryId(name) {
    const deliveryCountry = countries.filter(
      (country) => country.name === name
    );
    return deliveryCountry[0]?.id || "";
  }

  const newAddress = {
    // ID: String(country?.id),
    deliveryCity: city,
    deliveryPostCode: zip,
    deliveryAddressLine: addressLine1,
    deliveryCountry: getDeliveryCountryCode(country),
    ...(country === "Canada" && { deliveryState: stateCode }),
  };

  async function handleSubmit() {
    const updatedAddress = await handleUpdateUserDeliveryAddress(
      newAddress,
      setLoading
    );
    if (updatedAddress) {
      let currAddressInSessionStorage = JSON.parse(
        sessionStorage.getItem("addedAddresses")
      );

      if (currAddressInSessionStorage?.length > 0) {
        currAddressInSessionStorage[0] = {
          city: city,
          zipCode: zip,
          ID: getDeliveryCountryId(country),
          addressLine: addressLine1,
          countryName: country,
          countryCode: getDeliveryCountryCode(country),
          ...(country === "Canada" && { shippingState: stateCode }),
        };
      } else {
        currAddressInSessionStorage = [
          {
            city: city,
            zipCode: zip,
            ID: getDeliveryCountryId(country),
            addressLine: addressLine1,
            countryName: country,
            countryCode: getDeliveryCountryCode(country),
            ...(country === "Canada" && { shippingState: stateCode }),
          },
        ];
      }

      sessionStorage.setItem(
        "addedAddresses",
        JSON.stringify(currAddressInSessionStorage)
      );

      setNotificationPopupType("success");
      setPopUpMessage("Address updated successfully");
      setProceedAddNewAddressClicked(true);
      return updatedAddress;
    } else {
      setNotificationPopupType("error");
      setPopUpMessage("Error during update of delivery address");
      setProceedAddNewAddressClicked(true);
      return updatedAddress;
    }
  }

  const handleStateChange = (value) => {
    if (value) {
      setStateName(value.name);
      setStateCode(value.code);
    } else {
      setStateName("");
      setStateCode("");
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <div className={classes.popupContent}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.popupTitle}
          >
            CHANGE DELIVERY ADDRESS
          </BootstrapDialogTitle>
          <DialogContent>
            <Box className={classes.fieldsWrapper}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <TextField
                  className={classes.addressLineField}
                  label="Address line 1"
                  type="text"
                  value={addressLine1}
                  onChange={(e) => handleInputValidation(e, setAddressLine1)}
                  fullWidth
                  InputProps={{
                    pattern: /abc/,
                    title: "Invalid input: semicolon (;) is not allowed",
                  }}
                  inputProps={{
                    maxLength: 120,
                  }}
                  required
                  autoComplete="off"
                />
                <TextField
                  className={classes.cityFiled}
                  label="City"
                  type="text"
                  value={city}
                  onChange={(e) => handleInputValidation(e, setCity)}
                  fullWidth
                  required
                  autoComplete="off"
                  InputProps={{
                    pattern: /abc/,
                    title: "Invalid input: semicolon (;) is not allowed",
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className={classes.countryLabel}
                  >
                    Country *
                  </InputLabel>
                  <Select
                    className={classes.countryField}
                    autoWidth
                    label="Country"
                    placeholder="Country"
                    value={country}
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    onChange={(e) => setCountry(e.target.value)}
                    name="Country"
                    required
                  >
                    {countries.map((option) => (
                      <MenuItem key={option.id} required value={option.name}>
                        {option.name + " " + "(" + option.countryCode + ")"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {country === "Canada" && (
                  <Autocomplete
                    id="state-select"
                    options={CanadaStates}
                    autoHighlight
                    className={classes.textField}
                    getOptionLabel={(option) =>
                      option.name && option.code
                        ? `${option.name} (${option.code})`
                        : ""
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name} {"(" + option.code + ")"}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="State"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                    value={{ name: stateName, code: stateCode }}
                    onChange={(event, newValue) => handleStateChange(newValue)}
                  />
                )}
                <TextField
                  className={classes.zipField}
                  label="ZIP / Postcode"
                  type="text"
                  value={zip}
                  onChange={(e) => handleInputValidation(e, setZip)}
                  fullWidth
                  required
                  autoComplete="off"
                  InputProps={{
                    pattern: /abc/,
                    title: "Invalid input: semicolon (;) is not allowed",
                  }}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
                <Box className={classes.saveAndExitBtn}>
                  <CustomButton
                    type="black"
                    buttonText="Save"
                    width="50%"
                    height="100%"
                    actionType="submit"
                  ></CustomButton>
                </Box>
              </form>
            </Box>
          </DialogContent>
        </div>
      </BootstrapDialog>
      <LoadingBar open={loading} />
      {proceedAddNewAddressClicked && (
        <NotificationMessagePopup
          type={notificationPopupType === "success" ? "success" : "error"}
          isPopupOpen={proceedAddNewAddressClicked}
          setIsPopupOpen={setProceedAddNewAddressClicked}
          message={popUpMessage}
          handleCloseAutoInvestPopup={handleClose}
        />
      )}
    </div>
  );
}

export default MobileAddAddressPopUp;

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      maxWidth: "777px",
      maxHeight: "450px",
      height: "100%",
      width: "100%",
      minWidth: "300px",
    },
    fontFamily: theme.typography.fontFamily,
  },
  fieldsWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    height: "auto",
    paddingTop: "1rem",
    paddingLeft: "10%",
    paddingRight: "10%",
    [theme.breakpoints.down("768")]: {
      paddingLeft: "1%",
      paddingRight: "1%",
    },
  },
  popupContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  popupTitle: {
    fontSize: "var(--font-size-19)",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginTop: "1rem",
    paddingLeft: "2rem",
    [theme.breakpoints.down("400")]: {
      fontSize: "var(--font-size-17)",
      paddingLeft: "1rem",
    },
  },
  addressLineField: {
    width: "100%",
    alignItems: "center",
    "& input": {
      padding: "12px 9px",
    },
    "& label": {
      fontWeight: "normal",
      color: theme.palette.grey[500],
      marginTop: "-0.25rem",
    },
    marginTop: "0.2rem",
  },
  cityFiled: {
    width: "100%",
    alignItems: "center",
    "& input": {
      padding: "12px 9px",
    },
    "& label": {
      fontWeight: "normal",
      color: theme.palette.grey[500],
      marginTop: "-0.25rem",
    },
    marginTop: "0.725rem",
  },
  countryField: {
    maxHeight: "3rem",
    width: "100%",
    "& input": {
      padding: "12px 9px",
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: "1rem",
      paddingRight: "0px",
      textAlign: "left",
      fontWeight: 600,
    },
    marginTop: "0.725rem",
  },
  countryLabel: {
    fontWeight: "normal",
    color: theme.palette.grey[500],
    marginTop: "0.5rem",
  },
  textField: {
    marginTop: "0.725rem",
    "& .MuiInputLabel-root": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
      fontSize: "var(--font-size-16)",
    },
    "& .MuiOutlinedInput-root": {
      maxHeight: "3rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CFCFCF",
    },
  },
  zipField: {
    display: "flex",
    width: "100%",
    alignItems: "right",
    justifyContent: "left",
    "& input": {
      padding: "12px 9px",
    },
    "& label": {
      fontWeight: "normal",
      color: theme.palette.grey[500],
      marginTop: "-0.25rem",
    },
    marginTop: "0.725rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "4%",
  },
  saveAndExitBtn: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2rem",
  },
}));
