import { Button, Fade } from "@mui/material";
import React from "react";
import CustomTooltip from "../Tooltip/CustomTooltip/CustomTooltip";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import { useAppContext } from "../../../context/DataContext";
import { useState } from "react";

function IButton({ tooltipTitle, onClick, custom }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { isMobile, isAppView } = useAppContext();
  const isWeb = !isMobile && !isAppView;
  const tutorialStep = sessionStorage.getItem("tutorialStep");

  let isInTutorialState = false;

  if (tutorialStep && Number(tutorialStep) >= 0 && tutorialStep < 13 && isWeb) {
    isInTutorialState = true;
  } else if (
    tutorialStep &&
    Number(tutorialStep) >= 0 &&
    tutorialStep < 14 &&
    !isWeb
  ) {
    isInTutorialState = true;
  }

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    !isInTutorialState && (
      <CustomTooltip
        placement="top"
        arrow
        TransitionComponent={Fade}
        title={tooltipTitle}
        open={open}
        onClick={handleTooltipOpen}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Button className={custom ? classes.customMoreInfo : classes.moreInfo}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            i
          </span>
        </Button>
      </CustomTooltip>
    )
  );
}

export default IButton;

const useStyles = makeStyles((theme) =>
  createStyles({
    moreInfo: {
      width: "17px",
      height: "17px",
      borderRadius: "50%",
      backgroundColor: "rgba(255, 255, 255, 0)",
      color: "var(--more-info-grey)",
      textAlign: "center",
      fontWeight: 400,
      cursor: "pointer",
      minWidth: "auto",
      border: "1px solid var(--more-info-grey)",
      position: "absolute",
      right: 0,
      top: 0,
      margin: "10px",
      zIndex: 1,
    },
    customMoreInfo: {
      width: "13px",
      height: "13px",
      borderRadius: "50%",
      backgroundColor: "rgba(255, 255, 255, 0)",
      color: "#888888",
      textAlign: "center",
      fontWeight: 400,
      cursor: "pointer",
      minWidth: "auto",
      border: "1px solid #888888",
      position: "absolute",
      right: 0,
      top: 0,
      margin: "10px",
      zIndex: 1,
      padding: "0px",
      fontSize: "10px",
      alignItems: "center",
    },
  })
);
