import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MobileGoldHistory from "../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import MobileStakingButtons from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileStakingButtons/MobileStakingButtons";
import { useAppContext } from "../../../context/DataContext";
import { useEffect } from "react";
import { routes } from "../../../routes";
import AutoInvestInfo from "../../../components/MobileComponents/MobileAutoInvest/MobileAutoInvestInfo";
import MobileAutoInvestHistoryTable from "../../../components/MobileComponents/MobileAutoInvestTable/MobileAutoInvestHistoryTable";
import AppLayout from "../../../components/common/Layout/AppLayout";

function AppAutoInvest() {
  const classes = useStyles();
  const { isInHistory, setIsInHistory } = useAppContext();
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    setIsInHistory(false);
    setShowComponent(true);
  }, []);
  return (
    <AppLayout>
      {showComponent && (
        <Box className={classes.container}>
          <MobileNavigationBack
            appView={false}
            page={"Auto Invest"}
            route={routes.dashboard}
          />
          <MobileGoldHistory />
          <AutoInvestInfo />
          <div className={classes.wrapper}>
            <Box className={classes.table}>
              <MobileAutoInvestHistoryTable isInHistory={isInHistory} />
            </Box>
            <div className={classes.helperContainer}>
              <Box className={classes.actionButtons}>
                <MobileStakingButtons
                  firstButtonName={"INVEST"}
                  category="invest"
                  secondButtonText="SHOW INVEST HISTORY"
                />
              </Box>
            </div>
          </div>
        </Box>
      )}
    </AppLayout>
  );
}

export default AppAutoInvest;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  wrapper: {
    height: "calc(100vh - 260px)",
  },
  table: {
    height: "calc(100vh - 283px)",
    minHeight: "200px",
  },
  helperContainer: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    bottom: "1px",
    height: "140px",
    "@media (orientation: landscape) and (max-height: 608px)": {
      position: "relative",
      bottom: "25px",
    },
  },
  actionButtons: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    left: 0,
    width: "100%",
    background: "var(--linear-gradient-white)",
  },
}));
