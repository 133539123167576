import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MobileGoldHistory from "../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import MobileStakingButtons from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileStakingButtons/MobileStakingButtons";
import { useAppContext } from "../../../context/DataContext";
import { useEffect } from "react";
import { routes } from "../../../routes";
import AutoInvestInfo from "../../../components/MobileComponents/MobileAutoInvest/MobileAutoInvestInfo";
import MobileAutoInvestHistoryTable from "../../../components/MobileComponents/MobileAutoInvestTable/MobileAutoInvestHistoryTable";
import AppLayout from "../../../components/common/Layout/AppLayout";

function MobileAutoInvest() {
  const classes = useStyles();
  const { isInHistory, setIsInHistory } = useAppContext();
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    setIsInHistory(false);
    setShowComponent(true);
  }, []);
  return (
    <AppLayout>
      {showComponent && (
        <Box className={classes.container}>
          <MobileNavigationBack page={"Auto Invest"} route={routes.dashboard} />
          <MobileGoldHistory />
          <AutoInvestInfo />
          <Box className={classes.table}>
            <MobileAutoInvestHistoryTable isInHistory={isInHistory} />
          </Box>
          <Box className={classes.actionButtons}>
            <MobileStakingButtons
              firstButtonName={"INVEST"}
              category="invest"
              secondButtonText="SHOW INVEST HISTORY"
            />
          </Box>
        </Box>
      )}
    </AppLayout>
  );
}

export default MobileAutoInvest;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  table: {
    height: "80%",
    "@media (orientation: landscape)": {
      height: "63%",
    },
  },
  actionButtons: {
    position: "absolute",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    left: 0,
    width: "100%",
    bottom: "0%",
    background: "var(--linear-gradient-white)",
    paddingBottom: "0.5rem",
    "@media (orientation: landscape)": {
      paddingBottom: "0.5rem",
    },
  },
}));
