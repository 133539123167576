import React, { useState } from "react";

import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { routes } from "../../../routes";
import { makeStyles } from "@mui/styles";
import { useAppContext } from "../../../context/DataContext";
import clsx from "clsx";

function StoreFooter({ page, setPage, isPreview, totalPages, totalProducts }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAppView, isMobile } = useAppContext();
  const isWeb = !isAppView && !isMobile;

  const hasQueryParams = () => {
    return location.search !== "";
  };

  function navigateToDashboard() {
    navigate(routes.dashboard);
    window.scrollTo(0, 0);
  }

  function navigateToStore() {
    navigate(routes.store);
    window.scrollTo(0, 0);
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const TriangleArrowLeftBackToStore = () => (
    <>
      <span style={{ marginRight: "8px" }}>&#x25C0;&#xFE0E;</span>{" "}
      <span>Back to Store</span>
    </>
  );

  const TriangleArrowLeftBackToDashboard = () => (
    <>
      <span style={{ marginRight: "8px" }}>&#x25C0;&#xFE0E;</span>{" "}
      <span>Back to Dashboard</span>
    </>
  );

  const isGetByIdItemPage = hasQueryParams();

  return (
    <>
      {isWeb && (
        <div style={{ gridArea: "filter-footer" }}>
          <Box
            className={
              !isGetByIdItemPage
                ? classes.backBtnWrapper
                : classes.backBtnWrapperWithId
            }
          >
            <Button
              className={classes.blackButton}
              onClick={
                isGetByIdItemPage ? navigateToStore : navigateToDashboard
              }
            >
              {isGetByIdItemPage ? (
                <TriangleArrowLeftBackToStore />
              ) : (
                <TriangleArrowLeftBackToDashboard />
              )}
            </Button>
          </Box>
        </div>
      )}

      {!isPreview && totalProducts > 0 && (
        <div style={{ gridArea: "footer" }}>
          <Box className={classes.paginationRoot}>
            <Pagination
              page={page}
              shape="rounded"
              count={totalPages}
              variant="outlined"
              onChange={handlePageChange}
              className={clsx(isMobile && classes.paginationMobile)}
            />
          </Box>
        </div>
      )}
    </>
  );
}

export default StoreFooter;

const useStyles = makeStyles((theme) => ({
  backBtnWrapper: {
    textAlign: "left",
    width: "12rem",
    marginLeft: "16px",
    marginBottom: "24px",
    [theme.breakpoints.down("768")]: {
      display: "none",
    },
  },
  backBtnWrapperWithId: {
    textAlign: "left",
    width: "12rem",
    marginLeft: "16px",
    marginTop: "-60px",
    [theme.breakpoints.down("768")]: {
      display: "none",
    },
  },
  icon: {
    "& icon": {
      marginRight: "0px !important",
    },
  },
  paginationRoot: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    paddingTop: "10px",
    "& .MuiPagination-ul": {
      color: "white",
      flexWrap: "nowrap",
      "& li:first-child": {
        flexBasis: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& > button::after": {
          marginLeft: "2px",
          content: "'Prev'",
        },
      },
      "& li:last-child": {
        flexBasis: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "& > button::before": {
          marginRight: "2px",
          content: "'Next'",
        },
      },
    },
    "& .Mui-selected": {
      backgroundColor: "var(--black)",
      color: "var(--white)",
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },

  blackButton: {
    backgroundColor: "var(--black)",
    borderRadius: "40px",
    fontSize: "14px",
    width: "180px",
    color: "var(--white)",
    border: 0,
    cursor: "pointer",
    textAlign: "left",
    boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
    "&:hover": {
      backgroundColor: "var(--black)",
    },
    [theme.breakpoints.down("900")]: {
      "& .MuiTypography-button": {
        fontSize: "12px !important",
      },
    },
    "&.Mui-disabled": {
      background: "#eaeaea",
      color: "#c0c0c0",
    },
    "& .MuiButton-startIcon": {
      marginRight: "0px",
    },
    opacity: 1,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },

  paginationMobile: {
    "& .MuiPaginationItem-root:hover": {
      backgroundColor: "white !important",
    },
    "& .MuiPaginationItem-root.Mui-selected:hover": {
      backgroundColor: "black !important",
    },
  },
}));
