import { Box, Grid } from "@mui/material";
import React from "react";
import StakingNavigation from "../../../components/WebComponents/StakingNavigation/StakingNavigation";
import TotalBalanceInStaking from "../../../components/WebComponents/TotalBalanceInStaking/TotalBalanceInStaking";
import StakeVaultCard from "../../../components/common/StakeVaultCard/StakeVaultCard";
import TotalMonthlyInStaking from "../../../components/WebComponents/TotalMonthlyInStaking/TotalMonthlyInStaking";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/material";
import StakingHistory from "../../../components/WebComponents/StakingHistory/StakingHistory";
import GoldHistory from "../../../components/WebComponents/GoldHistory/GoldHistory";
import { coinName } from "../../../constants/globalconstants";
import WebLayout from "../../../components/common/Layout/WebLayout";

function WebStaking({ pool }) {
  const classes = useStyles();

  const isInStaking = true;
  return (
    <WebLayout>
      <Box>
        <Box sx={{ marginLeft: 2, marginRight: 2 }}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
              <Grid container>
                <Grid
                  container
                  alignItems="stretch"
                  direction="row"
                  marginBottom="1rem"
                  marginTop="0px"
                  spacing="16px"
                >
                  <Grid item xs={5} md={5} sm={5} xl={5} lg={5}>
                    <TotalBalanceInStaking
                      leftText={"Coins in Yielding"}
                      rightText={"Value of Yielded Coins"}
                      type={"staking"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    md={7}
                    sm={7}
                    xl={7}
                    lg={7}
                    style={{
                      height: "100%",
                    }}
                  >
                    <StakeVaultCard type="stake" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
                    <StakingHistory containerClassName={classes.container} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="stretch"
                  direction="row"
                  marginBottom="1rem"
                  marginTop="1rem"
                >
                  <Grid
                    item
                    xs={7.5}
                    md={7.5}
                    sm={7.5}
                    xl={7}
                    lg={7.5}
                    style={{ height: "100%" }}
                  >
                    <Box style={{ height: "98%" }}>
                      {isInStaking && (
                        <TotalMonthlyInStaking
                          isInStaking={isInStaking}
                          pool={pool}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4.5}
                    md={4.5}
                    sm={4.5}
                    xl={5}
                    lg={4.5}
                    style={{ height: "100%" }}
                  >
                    <Box className={classes.historyBox}>
                      <GoldHistory title={coinName} isInStaking={isInStaking} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ marginTop: 1, marginBottom: 1, marginLeft: 2, marginRight: 2 }}
        >
          <Grid container>
            <Grid item xs={6} md={6} sm={6} xl={6} lg={6}>
              <StakingNavigation />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </WebLayout>
  );
}

export default WebStaking;

const useStyles = makeStyles((theme) =>
  createStyles({
    historyBox: {
      height: "100%",
      marginLeft: "1rem",
    },
    container: {
      width: "100%",
      backgroundColor: "white",
      border: "1.41px",
      height: "19.75rem",
      borderRadius: "24px",
      background: "linear-gradient(180deg, #E0E0E099, #FFFFFF,#FFFFFF,#FFFFFF)",
      boxShadow: "0px 20.772722244262695px 42.53462219238281px 0px #0000001A",
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
        height: "2px",
        width: "157px !important",
        left: "28px !important",
        [theme.breakpoints.down("1200")]: {
          width: "150px !important",
        },
        [theme.breakpoints.down("1150")]: {
          width: "140px !important",
        },
        [theme.breakpoints.down("1000")]: {
          width: "130px !important",
        },
        [theme.breakpoints.down("800")]: {
          width: "120px !important",
        },
      },
    },
  })
);
