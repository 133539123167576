import { makeStyles } from "@mui/styles";
import React from "react";

export const QuantitySelector = ({
  quantity,
  setQuantity,
  availableInStock,
}) => {
  const classes = useStyles();

  const handleIncrement = () => {
    const isDemo = sessionStorage.getItem("isDemo");
    if (isDemo) {
      return;
    }
    if (quantity < availableInStock) {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    const isDemo = sessionStorage.getItem("isDemo");
    if (isDemo) {
      return;
    }
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  return (
    <div className={classes.root}>
      <span onClick={handleDecrement} className={classes.incrementBtn}>
        -
      </span>

      <div className={classes.quantity}>{quantity}</div>

      <span onClick={handleIncrement} className={classes.decrementBtn}>
        +
      </span>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    height: 42,
    borderRadius: 6,
    userSelect: "none",
    MsUserSelect: "none",
    WebkitUserSelect: "none",
    [theme.breakpoints.down("sm")]: {
      height: 36,
    },
  },

  quantity: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    flexGrow: 1,
    width: "64px",
    height: "36px",
    textAlign: "center",
    fontWeight: "bold",
    border: `1px solid #D1D2D4`,

    [theme.breakpoints.down("sm")]: {
      height: 32,
      width: 36,
      fontSize: "14px",
    },
  },

  decrementBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    background: "#F7F8FA",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    border: `1px solid #D1D2D4`,
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      width: 32,
      height: 32,
    },
  },

  incrementBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    background: "#F7F8FA",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    border: `1px solid #D1D2D4`,
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      width: 32,
      height: 32,
    },
  },
}));

export default QuantitySelector;
