import React, { useEffect } from "react";
import { ListItem } from "@mui/material";
import Slider from "@mui/material/Slider";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import currencyFormatter from "../../../helpers/currencyFormatter/currencyFormatter";
import { useState } from "react";
import { useAppContext } from "../../../context/DataContext";

function StoreFilters({
  setPage,
  isPreview,
  categories,
  priceRange,
  selectedCategory,
  setPriceRange,
  setSelectedCategory,
  setPriceFilterValue,
  isPriceFilterApplied,
  setIsPriceFilterApplied,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { currency } = useAppContext();
  const [searchParams] = useSearchParams();
  const [priceValue, setPriceValue] = useState(priceRange);

  useEffect(() => {
    if (!priceValue?.length) {
      setPriceValue(priceRange);
    }
  }, [priceRange]);

  useEffect(() => {
    setPriceValue(priceRange);

    if (isPriceFilterApplied) {
      setIsPriceFilterApplied(false);
    }
  }, [currency, priceRange]);

  const handlePriceFilterChange = (event, newValue) => {
    setIsPriceFilterApplied(true);
    setPriceValue(newValue);
  };

  const filteredPriceRangeProducts = () => {
    setPriceFilterValue(priceValue);
  };

  const isCategorySelected = (catName) => catName === selectedCategory;

  const handleSelectCategory = (categoryName) => {
    setPage(0);

    if (searchParams.has("id")) {
      searchParams.delete("id");

      navigate({ search: `?${searchParams.toString()}` });
    }

    navigate({ search: `?${searchParams.toString()}` });

    if (categoryName !== selectedCategory) {
      setPage(1);
      setSelectedCategory(categoryName);

      setPriceFilterValue([0, Number.MAX_SAFE_INTEGER]);
    }
  };

  function valueLabelFormat(value) {
    return currencyFormatter(value, "USD");
  }

  return priceRange.length > 0 && categories ? (
    <div id="store-filters" style={{ gridArea: "filter" }}>
      <Accordion expanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Categories
        </AccordionSummary>
        <AccordionDetails>
          <ListItem
            button
            selected={!selectedCategory}
            onClick={() => handleSelectCategory()}
          >
            BULLIONS
          </ListItem>
          {priceRange.length > 0 &&
            categories &&
            categories?.map((cat, idx) => (
              <ListItem
                button
                key={idx}
                selected={isCategorySelected(cat?.name)}
                onClick={() => handleSelectCategory(cat?.name)}
              >
                {cat?.name?.toUpperCase()}
              </ListItem>
            ))}
        </AccordionDetails>
      </Accordion>
      {!isPreview && categories && (
        <Accordion expanded>
          <AccordionSummary
            id="panel2-header"
            aria-controls="panel2-content"
            expandIcon={<ExpandMoreIcon />}
          >
            Pricing Filter
          </AccordionSummary>

          <AccordionDetails>
            <div className={classes.sliderWrapper}>
              {priceRange.length > 0 && priceValue.length > 0 && (
                <>
                  <Slider
                    value={priceValue}
                    valueLabelDisplay="auto"
                    min={priceRange?.[0]}
                    max={priceRange?.[1]}
                    className={classes.slider}
                    onChange={handlePriceFilterChange}
                    getAriaValueText={valueLabelFormat}
                    valueLabelFormat={valueLabelFormat}
                    onChangeCommitted={filteredPriceRangeProducts}
                  />

                  <div className={classes.labelWrapper}>
                    <div className={classes.price1}>
                      {currencyFormatter(priceValue?.[0], "USD")}
                    </div>
                    <div className={classes.price2}>
                      {currencyFormatter(priceValue?.[1], "USD")}
                    </div>
                  </div>
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  ) : null;
}

export default StoreFilters;

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    display: "flex",
    flexDirection: "column",

    paddingLeft: "8px",

    "&.MuiSlider-root": {
      color: "rgba(0, 0, 0, 1)",
    },

    ".MuiAccordionSummary-expandIconWrapper": {
      background: "transparent",
    },
    slider: {
      color: "rgba(0, 0, 0, 1)",
    },
  },

  labelWrapper: {
    position: "relative",

    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",

    fontSize: "12px",
  },

  price1: {
    position: "absolute",
    left: -16,
  },

  price2: {
    position: "absolute",
    right: -16,
  },
}));
