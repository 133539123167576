import { fetchWrapper } from "../../utils/fetchWrapper";

const getAvailableGoldVariants = async () => {
  try {
    return await fetchWrapper.get(`/redeem-transaction/gold-variants`);
  } catch (error) {
    throw error;
  }
};

const getDeliveryFee = async (countryCode, zipCode, partNumber) => {
  try {
    return await fetchWrapper.post(`/redeem-transaction/delivery-fee`, {
      countryCode,
      zipCode,
      partNumber,
    });
  } catch (error) {
    throw error;
  }
};

const createRedeem = async (redeemData) => {
  try {
    return await fetchWrapper.post(`/redeem-transaction/create/redeem`, {
      ...redeemData,
    });
  } catch (error) {
    throw error;
  }
};

const calculateFees = async (data, useFiat = false) => {
  try {
    return await fetchWrapper.post(`/redeem-transaction/fees`, {
      ...data,
      useFiat,
    });
  } catch (error) {
    throw error;
  }
};

export const redeemService = {
  createRedeem,
  calculateFees,
  getDeliveryFee,
  getAvailableGoldVariants,
};
