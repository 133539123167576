export function getTheNamesOfPreviousMonths(currentDate, num) {
  if (!(currentDate instanceof Date) || isNaN(currentDate)) {
    throw new Error("Invalid date provided.");
  }

  if (typeof num !== "number" || num < 1) {
    throw new Error("Invalid value for num. Please provide a positive number.");
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const result = [];

  for (let i = 0; i < num; i++) {
    const previousMonth = new Date(currentDate);
    previousMonth.setMonth(currentDate.getMonth() - i - 1);

    const monthIndex = previousMonth.getMonth();
    result.push(months[monthIndex]);
  }
  const reversedArray = result.reverse();
  return reversedArray;
}
