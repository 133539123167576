import React from 'react';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const TransferFundsButtonMemoized = ({ classes, handleTransferFunds }) => {
  return (
    <div className={classes.depositBtnContainer} id="transfer-btn">
      <Button
        className={classes.transferFundsBtnReal}
        onClick={handleTransferFunds}
        startIcon={<AddCircleOutlineIcon />}
      >
        TRANSFER FUNDS
      </Button>
    </div>
  );
};

export default React.memo(TransferFundsButtonMemoized);
