import dayjs from "dayjs";

export function formatDate(date) {
  const parsedDate = dayjs(date);
  const today = dayjs().startOf("day");
  const yesterday = dayjs().subtract(1, "day").startOf("day");

  if (parsedDate.isSame(today, "day")) {
    return `Today at ${parsedDate.format("HH:mm")}`;
  } else if (parsedDate.isSame(yesterday, "day")) {
    return `Yesterday at ${parsedDate.format("HH:mm")}`;
  } else {
    return parsedDate.format("D MMM YYYY [at] HH:mm");
  }
}
