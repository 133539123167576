import { fetchWrapper } from "../../utils/fetchWrapper";
const createSellRequest = async (sellRequest) => {
  try {
    await fetchWrapper.post(`/orders/coins/sell`, sellRequest);
  } catch (e) {
    throw e;
  }
};
const sellServices = {
  createSellRequest,
};
export default sellServices;
