import React from "react";
import { Grid } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function WebStoreLayout({ children }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} md={12} xl={12} sm={12} lg={12}>
          <Header />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ alignSelf: "center", height: "100%" }}
      >
        <Grid item xs={12} md={12} xl={12} sm={12} lg={12}>
          <div style={{ height: "100%" }}>{children}</div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ alignSelf: "flex-end" }}
      >
        <Grid item xs={12} md={12} xl={12} sm={12} lg={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default WebStoreLayout;
