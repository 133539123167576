import THOUSANDSATS from '../assets/crypto-icons/1000sats.png';
import ONEINCH from '../assets/crypto-icons/1inch.png';
import BADGER from '../assets/crypto-icons/Badger.png';
import AAVE from '../assets/crypto-icons/aave.png';
import ACA from '../assets/crypto-icons/aca.png';
import ACE from '../assets/crypto-icons/ace.png';
import ACH from '../assets/crypto-icons/ach.png';
import ACM from '../assets/crypto-icons/acm.png';
import ADA from '../assets/crypto-icons/ada.png';
import ADX from '../assets/crypto-icons/adx.png';
import AERGO from '../assets/crypto-icons/aergo.png';
import AEUR from '../assets/crypto-icons/aeur.png';
import AEVO from '../assets/crypto-icons/aevo.png';
import AGIX from '../assets/crypto-icons/agix.png';
import AGLD from '../assets/crypto-icons/agld.png';
import AI from '../assets/crypto-icons/ai.png';
import AION from '../assets/crypto-icons/aion.png';
import AKRO from '../assets/crypto-icons/akro.png';
import ALCX from '../assets/crypto-icons/alcx.png';
import ALGO from '../assets/crypto-icons/algo.png';
import ALICE from '../assets/crypto-icons/alice.png';
import ALPACA from '../assets/crypto-icons/alpaca.png';
import ALPHA from '../assets/crypto-icons/alpha.png';
import ALPINE from '../assets/crypto-icons/alpine.png';
import ALT from '../assets/crypto-icons/alt.png';
import AMB from '../assets/crypto-icons/amb.png';
import AMP from '../assets/crypto-icons/amp.png';
import ANC from '../assets/crypto-icons/anc.png';
import ANKR from '../assets/crypto-icons/ankr.png';
import ANT from '../assets/crypto-icons/ant.png';
import APE from '../assets/crypto-icons/ape.png';
import API3 from '../assets/crypto-icons/api3.png';
import APT from '../assets/crypto-icons/apt.png';
import AR1 from '../assets/crypto-icons/ar-1.png';
import AR from '../assets/crypto-icons/ar.png';
import ARB from '../assets/crypto-icons/arb.png';
import ARDR from '../assets/crypto-icons/ardr.png';
import ARK from '../assets/crypto-icons/ark.png';
import ARKM from '../assets/crypto-icons/arkm.png';
import ARPA from '../assets/crypto-icons/arpa.png';
import ASR from '../assets/crypto-icons/asr.png';
import AST from '../assets/crypto-icons/ast.png';
import ASTR from '../assets/crypto-icons/astr.png';
import ATA from '../assets/crypto-icons/ata.png';
import ATM from '../assets/crypto-icons/atm.png';
import ATOM from '../assets/crypto-icons/atom.png';
import AUCTION from '../assets/crypto-icons/auction.png';
import AUD from '../assets/crypto-icons/aud.png';
import AUDIO from '../assets/crypto-icons/audio.png';
import AUTO from '../assets/crypto-icons/auto.png';
import AVA from '../assets/crypto-icons/ava.png';
import AVAX from '../assets/crypto-icons/avax.png';
import AXL from '../assets/crypto-icons/axl.png';
import AXS from '../assets/crypto-icons/axs.png';
import BAKE from '../assets/crypto-icons/bake.png';
import BAL from '../assets/crypto-icons/bal.png';
import BAND from '../assets/crypto-icons/band.png';
import BAR from '../assets/crypto-icons/bar.png';
import BAT from '../assets/crypto-icons/bat.png';
import BB from '../assets/crypto-icons/bb.png';
import BCH from '../assets/crypto-icons/bch.png';
import BEAM from '../assets/crypto-icons/beam.png';
import BEAMX from '../assets/crypto-icons/beamx.png';
import BEL from '../assets/crypto-icons/bel.png';
import BETA from '../assets/crypto-icons/beta.png';
import BICO from '../assets/crypto-icons/bico.png';
import BIFI from '../assets/crypto-icons/bifi.png';
import BLUR from '../assets/crypto-icons/blur.png';
import BLZ from '../assets/crypto-icons/blz.png';
import BNB from '../assets/crypto-icons/bnb.png';
import BNT from '../assets/crypto-icons/bnt.png';
import BNX from '../assets/crypto-icons/bnx.png';
import BOME from '../assets/crypto-icons/bome.png';
import BOND from '../assets/crypto-icons/bond.png';
import BONK from '../assets/crypto-icons/bonk.png';
import BSW from '../assets/crypto-icons/bsw.png';
import BTC1 from '../assets/crypto-icons/btc-1.png';
import BTC from '../assets/crypto-icons/btc.png';
import BTS from '../assets/crypto-icons/bts.png';
import BTTC from '../assets/crypto-icons/bttc.png';
import BURGER from '../assets/crypto-icons/burger.png';
import BUSD from '../assets/crypto-icons/busd.png';
import C98 from '../assets/crypto-icons/c98.png';
import CAKE from '../assets/crypto-icons/cake.png';
import CELER from '../assets/crypto-icons/celr.png';
import CELO from '../assets/crypto-icons/celo.png';
import CFX from '../assets/crypto-icons/cfx.png';
import CHESS from '../assets/crypto-icons/chess.png';
import CHR from '../assets/crypto-icons/chr.png';
import CHZ from '../assets/crypto-icons/chz.png';
import CITY from '../assets/crypto-icons/city.png';
import CKB from '../assets/crypto-icons/ckb.png';
import CLV from '../assets/crypto-icons/clv.png';
import COCOS from '../assets/crypto-icons/cocos.png';
import COMBO from '../assets/crypto-icons/combo.png';
import COMP1 from '../assets/crypto-icons/comp-1.png';
import COMP from '../assets/crypto-icons/comp.png';
import COS from '../assets/crypto-icons/cos.png';
import COTI from '../assets/crypto-icons/coti.png';
import CREAM from '../assets/crypto-icons/cream.png';
import CRV from '../assets/crypto-icons/crv.png';
import CTK from '../assets/crypto-icons/ctk.png';
import CTSI from '../assets/crypto-icons/ctsi.png';
import CTXC from '../assets/crypto-icons/ctxc.png';
import CVC from '../assets/crypto-icons/cvc.png';
import CVP from '../assets/crypto-icons/cvp.png';
import CVX from '../assets/crypto-icons/cvx.png';
import CYBER from '../assets/crypto-icons/cyber.png';
import DAI from '../assets/crypto-icons/dai.png';
import DAR from '../assets/crypto-icons/dar.png';
import DASH from '../assets/crypto-icons/dash.png';
import DATA from '../assets/crypto-icons/data.png';
import DCR from '../assets/crypto-icons/dcr.png';
import DEGO from '../assets/crypto-icons/dego.png';
import DENT from '../assets/crypto-icons/dent.png';
import DEXE from '../assets/crypto-icons/dexe.png';
import DF from '../assets/crypto-icons/df.png';
import DGB from '../assets/crypto-icons/dgb.png';
import DIA from '../assets/crypto-icons/dia.png';
import DOCK from '../assets/crypto-icons/dock.png';
import DODO from '../assets/crypto-icons/dodo.png';
import DOGE from '../assets/crypto-icons/doge.png';
import DOT from '../assets/crypto-icons/dot.png';
import DREP from '../assets/crypto-icons/drep.png';
import DUSK from '../assets/crypto-icons/dusk.png';
import DYDX from '../assets/crypto-icons/dydx.png';
import EDU from '../assets/crypto-icons/edu.png';
import EGLD from '../assets/crypto-icons/egld.png';
import ELF from '../assets/crypto-icons/elf.png';
import ENA from '../assets/crypto-icons/ena.png';
import ENJ from '../assets/crypto-icons/enj.png';
import ENS from '../assets/crypto-icons/ens.png';
import EOS from '../assets/crypto-icons/eos.png';
import EPX from '../assets/crypto-icons/epx.png';
import ETC from '../assets/crypto-icons/etc.png';
import ETH from '../assets/crypto-icons/eth.png';
import ETHFI from '../assets/crypto-icons/ethfi.png';
import EUR from '../assets/crypto-icons/eur.png';
import FARM from '../assets/crypto-icons/farm.png';
import FDUSD from '../assets/crypto-icons/fdusd.png';
import FET from '../assets/crypto-icons/fet.png';
import FIDA from '../assets/crypto-icons/fida.png';
import FIL from '../assets/crypto-icons/fil.png';
import FIO from '../assets/crypto-icons/fio.png';
import FIRO from '../assets/crypto-icons/firo.png';
import FIS from '../assets/crypto-icons/fis.png';
import FLM from '../assets/crypto-icons/flm.png';
import FLOKI from '../assets/crypto-icons/floki.png';
import FLOW from '../assets/crypto-icons/flow.png';
import FLUX from '../assets/crypto-icons/flux.png';
import FOR from '../assets/crypto-icons/for.png';
import FORTH from '../assets/crypto-icons/forth.png';
import FRONT from '../assets/crypto-icons/front.png';
import FTM from '../assets/crypto-icons/ftm.png';
import FTT from '../assets/crypto-icons/ftt.png';
import FUN from '../assets/crypto-icons/fun.png';
import FXS from '../assets/crypto-icons/fxs.png';
import GAL from '../assets/crypto-icons/gal.png';
import GALA from '../assets/crypto-icons/gala.png';
import GAS from '../assets/crypto-icons/gas.png';
import GBP from '../assets/crypto-icons/gbp.png';
import GFT from '../assets/crypto-icons/gft.png';
import GHST from '../assets/crypto-icons/ghst.png';
import GLM from '../assets/crypto-icons/glm.png';
import GLMR from '../assets/crypto-icons/glmr.png';
import GMT from '../assets/crypto-icons/gmt.png';
import GMX from '../assets/crypto-icons/gmx.png';
import GNO from '../assets/crypto-icons/gno.png';
import GNS from '../assets/crypto-icons/gns.png';
import GRT from '../assets/crypto-icons/grt.png';
import GTC from '../assets/crypto-icons/gtc.png';
import GTO from '../assets/crypto-icons/gto.png';
import HARD from '../assets/crypto-icons/hard.png';
import HBAR from '../assets/crypto-icons/hbar.png';
import HFT from '../assets/crypto-icons/hft.png';
import HIFI from '../assets/crypto-icons/hifi.png';
import HIGH from '../assets/crypto-icons/high.png';
import HIVE from '../assets/crypto-icons/hive.png';
import HOOK from '../assets/crypto-icons/hook.png';
import HOT from '../assets/crypto-icons/hot.png';
import ICP from '../assets/crypto-icons/icp.png';
import ICX from '../assets/crypto-icons/icx.png';
import ID from '../assets/crypto-icons/id.png';
import IDEX from '../assets/crypto-icons/idex.png';
import IDRT from '../assets/crypto-icons/idrt.png';
import ILV from '../assets/crypto-icons/ilv.png';
import IMX from '../assets/crypto-icons/imx.png';
import INJ from '../assets/crypto-icons/inj.png';
import IO from '../assets/crypto-icons/io.png';
import IOST from '../assets/crypto-icons/iost.png';
import IOTX from '../assets/crypto-icons/iotx.png';
import IQ from '../assets/crypto-icons/iq.png';
import IRIS from '../assets/crypto-icons/iris.png';
import JASMY from '../assets/crypto-icons/jasmy.png';
import JOE from '../assets/crypto-icons/joe.png';
import JST from '../assets/crypto-icons/jst.png';
import JTO from '../assets/crypto-icons/jto.png';
import JUP from '../assets/crypto-icons/jup.png';
import JUV from '../assets/crypto-icons/juv.png';
import KAVA from '../assets/crypto-icons/kava.png';
import KDA from '../assets/crypto-icons/kda.png';
import KEY from '../assets/crypto-icons/key.png';
import KLAY from '../assets/crypto-icons/klay.png';
import KMD from '../assets/crypto-icons/kmd.png';
import KNC from '../assets/crypto-icons/knc.png';
import KP3R from '../assets/crypto-icons/kp3r.png';
import KSM from '../assets/crypto-icons/ksm.png';
import LAZIO from '../assets/crypto-icons/lazio.png';
import LDO from '../assets/crypto-icons/ldo.png';
import LEVER from '../assets/crypto-icons/lever.png';
import LINA from '../assets/crypto-icons/lina.png';
import LINK from '../assets/crypto-icons/link.png';
import LISTA from '../assets/crypto-icons/lista.png';
import LIT from '../assets/crypto-icons/lit.png';
import LOKA from '../assets/crypto-icons/loka.png';
import LOOM from '../assets/crypto-icons/loom.png';
import LPT from '../assets/crypto-icons/lpt.png';
import LQTY from '../assets/crypto-icons/lqty.png';
import LRC from '../assets/crypto-icons/lrc.png';
import LSK from '../assets/crypto-icons/lsk.png';
import LTC from '../assets/crypto-icons/ltc.png';
import LTO from '../assets/crypto-icons/lto.png';
import LUNA from '../assets/crypto-icons/luna.png';
import LUNC from '../assets/crypto-icons/lunc.png';
import MAGIC from '../assets/crypto-icons/magic.png';
import MANA from '../assets/crypto-icons/mana.png';
import MANTA from '../assets/crypto-icons/manta.png';
import MASK from '../assets/crypto-icons/mask.png';
import MATIC from '../assets/crypto-icons/matic.png';
import MAV from '../assets/crypto-icons/mav.png';
import MBL from '../assets/crypto-icons/mbl.png';
import MBOX from '../assets/crypto-icons/mbox.png';
import MC from '../assets/crypto-icons/mc.png';
import MDT from '../assets/crypto-icons/mdt.png';
import MDX from '../assets/crypto-icons/mdx.png';
import MEME from '../assets/crypto-icons/meme.png';
import METIS from '../assets/crypto-icons/metis.png';
import MFT from '../assets/crypto-icons/mft.png';
import MINA from '../assets/crypto-icons/mina.png';
import MIOTA from '../assets/crypto-icons/miota.png';
import MITH from '../assets/crypto-icons/mith.png';
import MKR from '../assets/crypto-icons/mkr.png';
import MLN from '../assets/crypto-icons/mln.png';
import MOB from '../assets/crypto-icons/mob.png';
import MOVR from '../assets/crypto-icons/movr.png';
import MTL from '../assets/crypto-icons/mtl.png';
import MULTI from '../assets/crypto-icons/multi.png';
import NEAR from '../assets/crypto-icons/near.png';
import NEBL from '../assets/crypto-icons/nebl.png';
import NEO from '../assets/crypto-icons/neo.png';
import NEXO from '../assets/crypto-icons/nexo.png';
import NFP from '../assets/crypto-icons/nfp.png';
import NKN from '../assets/crypto-icons/nkn.png';
import NMR from '../assets/crypto-icons/nmr.png';
import NOT from '../assets/crypto-icons/not.png';
import NTRN from '../assets/crypto-icons/ntrn.png';
import NULS from '../assets/crypto-icons/nuls.png';
import OAX from '../assets/crypto-icons/oax.png';
import OCEAN from '../assets/crypto-icons/ocean.png';
import OG from '../assets/crypto-icons/og.png';
import OGN from '../assets/crypto-icons/ogn.png';
import OM from '../assets/crypto-icons/om.png';
import OMG from '../assets/crypto-icons/omg.png';
import OMNI from '../assets/crypto-icons/omni.png';
import ONE from '../assets/crypto-icons/one.png';
import ONG from '../assets/crypto-icons/ong.png';
import ONT from '../assets/crypto-icons/ont.png';
import OOKI from '../assets/crypto-icons/ooki.png';
import OP from '../assets/crypto-icons/op.png';
import ORDI from '../assets/crypto-icons/ordi.png';
import ORN from '../assets/crypto-icons/orn.png';
import OSMO from '../assets/crypto-icons/osmo.png';
import OXT from '../assets/crypto-icons/oxt.png';
import PAXG from '../assets/crypto-icons/paxg.png';
import PDA from '../assets/crypto-icons/pda.png';
import PENDLE from '../assets/crypto-icons/pendle.png';
import PEOPLE from '../assets/crypto-icons/people.png';
import PEPE from '../assets/crypto-icons/pepe.png';
import PERL from '../assets/crypto-icons/perl.png';
import PERP from '../assets/crypto-icons/perp.png';
import PHA from '../assets/crypto-icons/pha.png';
import PHB from '../assets/crypto-icons/phb.png';
import PIVX from '../assets/crypto-icons/pivx.png';
import PIXEL from '../assets/crypto-icons/pixel.png';
import PLA from '../assets/crypto-icons/pla.png';
import PNT from '../assets/crypto-icons/pnt.png';
import POLS from '../assets/crypto-icons/pols.png';
import POLYX from '../assets/crypto-icons/polyx.png';
import POND from '../assets/crypto-icons/pond.png';
import PORTAL from '../assets/crypto-icons/portal.png';
import PORTO from '../assets/crypto-icons/porto.png';
import POWR from '../assets/crypto-icons/powr.png';
import PROM from '../assets/crypto-icons/prom.png';
import PROS from '../assets/crypto-icons/pros.png';
import PSG from '../assets/crypto-icons/psg.png';
import PUNDIX from '../assets/crypto-icons/pundix.png';
import PYR from '../assets/crypto-icons/pyr.png';
import PYTH from '../assets/crypto-icons/pyth.png';
import QI from '../assets/crypto-icons/qi.png';
import QKC from '../assets/crypto-icons/qkc.png';
import QNT from '../assets/crypto-icons/qnt.png';
import QTUM from '../assets/crypto-icons/qtum.png';
import QUICK from '../assets/crypto-icons/quick.png';
import RAD from '../assets/crypto-icons/rad.png';
import RARE from '../assets/crypto-icons/rare.png';
import RAY from '../assets/crypto-icons/ray.png';
import RDNT from '../assets/crypto-icons/rdnt.png';
import REEF from '../assets/crypto-icons/reef.png';
import REI from '../assets/crypto-icons/rei.png';
import REN from '../assets/crypto-icons/ren.png';
import REP from '../assets/crypto-icons/rep.png';
import REQ from '../assets/crypto-icons/req.png';
import REZ from '../assets/crypto-icons/rez.png';
import RIF from '../assets/crypto-icons/rif.png';
import RLC from '../assets/crypto-icons/rlc.png';
import RNDR from '../assets/crypto-icons/rndr.png';
import RONIN from '../assets/crypto-icons/ronin.png';
import ROSE from '../assets/crypto-icons/rose.png';
import RPL from '../assets/crypto-icons/rpl.png';
import RSR from '../assets/crypto-icons/rsr.png';
import RUNE from '../assets/crypto-icons/rune.png';
import RVN from '../assets/crypto-icons/rvn.png';
import SAGA from '../assets/crypto-icons/saga.png';
import SAND from '../assets/crypto-icons/sand.png';
import SANTOS from '../assets/crypto-icons/santos.png';
import SC from '../assets/crypto-icons/sc.png';
import SCRT from '../assets/crypto-icons/scrt.png';
import SEI from '../assets/crypto-icons/sei.png';
import SFP from '../assets/crypto-icons/sfp.png';
import SHIB from '../assets/crypto-icons/shib.png';
import SKL from '../assets/crypto-icons/skl.png';
import SLP from '../assets/crypto-icons/slp.png';
import SNT from '../assets/crypto-icons/snt.png';
import SNX from '../assets/crypto-icons/snx.png';
import SOL from '../assets/crypto-icons/sol.png';
import SPELL from '../assets/crypto-icons/spell.png';
import SRM from '../assets/crypto-icons/srm.png';
import SSV from '../assets/crypto-icons/ssv.png';
import STEEM from '../assets/crypto-icons/steem.png';
import STG from '../assets/crypto-icons/stg.png';
import STMX from '../assets/crypto-icons/stmx.png';
import STORJ from '../assets/crypto-icons/storj.png';
import STPT from '../assets/crypto-icons/stpt.png';
import STRAX from '../assets/crypto-icons/strax.png';
import STRK from '../assets/crypto-icons/strk.png';
import STX from '../assets/crypto-icons/stx.png';
import SUI from '../assets/crypto-icons/sui.png';
import SUN from '../assets/crypto-icons/sun.png';
import SUPER from '../assets/crypto-icons/super.png';
import SUSHI from '../assets/crypto-icons/sushi.png';
import SXP from '../assets/crypto-icons/sxp.png';
import SYN from '../assets/crypto-icons/syn.png';
import SYS from '../assets/crypto-icons/sys.png';
import T1 from '../assets/crypto-icons/t-1.png';
import T from '../assets/crypto-icons/t.png';
import TAO from '../assets/crypto-icons/tao.png';
import TERN from '../assets/crypto-icons/tern.png';
import TFUEL from '../assets/crypto-icons/tfuel.png';
import THETA from '../assets/crypto-icons/theta.png';
import TIA from '../assets/crypto-icons/tia.png';
import TKO from '../assets/crypto-icons/tko.png';
import TLM from '../assets/crypto-icons/tlm.png';
import TNSR from '../assets/crypto-icons/tnsr.png';
import TOMO from '../assets/crypto-icons/tomo.png';
import TON from '../assets/crypto-icons/ton.png';
import TRB from '../assets/crypto-icons/trb.png';
import TROY from '../assets/crypto-icons/troy.png';
import TRU from '../assets/crypto-icons/tru.png';
import TRX from '../assets/crypto-icons/trx.png';
import TUSD from '../assets/crypto-icons/tusd.png';
import TVK from '../assets/crypto-icons/tvk.png';
import TWT from '../assets/crypto-icons/twt.png';
import UFT from '../assets/crypto-icons/uft.png';
import UMA from '../assets/crypto-icons/uma.png';
import UNFI from '../assets/crypto-icons/unfi.png';
import UNI from '../assets/crypto-icons/uni.png';
import USDC from '../assets/crypto-icons/usdc.png';
import USDP from '../assets/crypto-icons/usdp.png';
import USDT from '../assets/crypto-icons/usdt.png';
import USTC from '../assets/crypto-icons/ustc.png';
import UTK from '../assets/crypto-icons/utk.png';
import VANRY from '../assets/crypto-icons/vanry.png';
import VET from '../assets/crypto-icons/vet.png';
import VGX from '../assets/crypto-icons/vgx.png';
import VIB from '../assets/crypto-icons/vib.png';
import VIC from '../assets/crypto-icons/vic.png';
import VIDT from '../assets/crypto-icons/vidt.png';
import VITE from '../assets/crypto-icons/vite.png';
import VOXEL from '../assets/crypto-icons/voxel.png';
import VTHO from '../assets/crypto-icons/vtho.png';
import W from '../assets/crypto-icons/w.png';
import WAN from '../assets/crypto-icons/wan.png';
import WAXP from '../assets/crypto-icons/waxp.png';
import WBETH from '../assets/crypto-icons/wbeth.png';
import WBTC from '../assets/crypto-icons/wbtc.png';
import WIF from '../assets/crypto-icons/wif.png';
import WIN from '../assets/crypto-icons/win.png';
import WING from '../assets/crypto-icons/wing.png';
import WLD from '../assets/crypto-icons/wld.png';
import WNXM from '../assets/crypto-icons/wnxm.png';
import WOO from '../assets/crypto-icons/woo.png';
import WRX from '../assets/crypto-icons/wrx.png';
import WTC from '../assets/crypto-icons/wtc.png';
import XAI from '../assets/crypto-icons/xai.png';
import XEC from '../assets/crypto-icons/xec.png';
import XEM from '../assets/crypto-icons/xem.png';
import XLM from '../assets/crypto-icons/xlm.png';
import XMR from '../assets/crypto-icons/xmr.png';
import XNO from '../assets/crypto-icons/xno.png';
import XRP from '../assets/crypto-icons/xrp.png';
import XTZ from '../assets/crypto-icons/xtz.png';
import XVG from '../assets/crypto-icons/xvg.png';
import XVS from '../assets/crypto-icons/xvs.png';
import YFI from '../assets/crypto-icons/yfi.png';
import YGG from '../assets/crypto-icons/ygg.png';
import ZEC from '../assets/crypto-icons/zec.png';
import ZEN from '../assets/crypto-icons/zen.png';
import ZIL from '../assets/crypto-icons/zil.png';
import ZK from '../assets/crypto-icons/zk.png';
import ZRO from '../assets/crypto-icons/zro.png';
import ZRX from '../assets/crypto-icons/zrx.png';
import DYM from '../assets/crypto-icons/dym.png';
import ERN from '../assets/crypto-icons/ern.png';

import GENERIC from '../assets/crypto-icons/generic.png';

export const getCryptoIndexDetails = (code) => {
  let url = GENERIC;
  let name = '';

  switch (code) {
    case 'BTC':
      url = BTC;
      name = 'Bitcoin';
      break;
    case 'ETH':
      url = ETH;
      name = 'Ethereum';
      break;
    case 'BNB':
      url = BNB;
      name = 'BNB';
      break;
    case 'SOL':
      url = SOL;
      name = 'Solana';
      break;
    case 'USDC':
      url = USDC;
      name = 'USD Coin';
      break;
    case 'XRP':
      url = XRP;
      name = 'Ripple';
      break;
    case 'DOGE':
      url = DOGE;
      name = 'Dogecoin';
      break;
    case 'ADA':
      url = ADA;
      name = 'Cardano';
      break;
    case 'TRX':
      url = TRX;
      name = 'TRON';
      break;
    case 'AVAX':
      url = AVAX;
      name = 'Avalanche';
      break;
    case 'SHIB':
      url = SHIB;
      name = 'SHIBA INU';
      break;
    case 'WBTC':
      url = WBTC;
      name = 'Wrapped Bitcoin';
      break;
    case 'DOT':
      url = DOT;
      name = 'Polkadot';
      break;
    case 'LINK':
      url = LINK;
      name = 'ChainLink';
      break;
    case 'BCH':
      url = BCH;
      name = 'Bitcoin Cash';
      break;
    case 'DAI':
      url = DAI;
      name = 'Dai';
      break;
    case 'MATIC':
      url = MATIC;
      name = 'Polygon';
      break;
    case 'NEAR':
      url = NEAR;
      name = 'NEAR Protocol';
      break;
    case 'LTC':
      url = LTC;
      name = 'Litecoin';
      break;
    case 'UNI':
      url = UNI;
      name = 'Uniswap';
      break;
    case 'PEPE':
      url = PEPE;
      name = 'Pepe';
      break;
    case 'ICP':
      url = ICP;
      name = 'Internet Computer';
      break;
    case 'ETC':
      url = ETC;
      name = 'Ethereum Classic';
      break;
    case 'FET':
      url = FET;
      name = 'Artificial Superintelligence Alliance';
      break;
    case 'WBETH':
      url = WBETH;
      name = 'Wrapped Beacon ETH (WBETH)';
      break;
    case 'APT':
      url = APT;
      name = 'Aptos';
      break;
    case 'XLM':
      url = XLM;
      name = 'Stellar Lumens';
      break;
    case 'RNDR':
      url = RNDR;
      name = 'Render Token';
      break;
    case 'HBAR':
      url = HBAR;
      name = 'Hedera Hashgraph';
      break;
    case 'ATOM':
      url = ATOM;
      name = 'Cosmos';
      break;
    case 'FIL':
      url = FIL;
      name = 'Filecoin';
      break;
    case 'ARB':
      url = ARB;
      name = 'Arbitrum';
      break;
    case 'MKR':
      url = MKR;
      name = 'Maker';
      break;
    case 'VET':
      url = VET;
      name = 'VeChain';
      break;
    case 'STX':
      url = STX;
      name = 'Stacks';
      break;
    case 'FDUSD':
      url = FDUSD;
      name = 'First Digital USD';
      break;
    case 'IMX':
      url = IMX;
      name = 'ImmutableX';
      break;
    case 'INJ':
      url = INJ;
      name = 'Injective';
      break;
    case 'BONK':
      url = BONK;
      name = 'Bonk';
      break;
    case 'TAO':
      url = TAO;
      name = 'Bittensor';
      break;
    case 'SUI':
      url = SUI;
      name = 'Sui';
      break;
    case 'GRT':
      url = GRT;
      name = 'The Graph';
      break;
    case 'OP':
      url = OP;
      name = 'Optimism';
      break;
    case 'WIF':
      url = WIF;
      name = 'Dogwifhat';
      break;
    case 'NOT':
      url = NOT;
      name = 'Notcoin';
      break;
    case 'AR':
      url = AR;
      name = 'Arweave';
      break;
    case 'LDO':
      url = LDO;
      name = 'Lido DAO';
      break;
    case 'FLOKI':
      url = FLOKI;
      name = 'FLOKI';
      break;
    case 'THETA':
      url = THETA;
      name = 'Theta Token';
      break;
    case 'TIA':
      url = TIA;
      name = 'Celestia';
      break;
    case 'FTM':
      url = FTM;
      name = 'Fantom';
      break;
    case 'AAVE':
      url = AAVE;
      name = 'Aave';
      break;
    case 'RUNE':
      url = RUNE;
      name = 'THORChain';
      break;
    case 'JASMY':
      url = JASMY;
      name = 'JasmyCoin';
      break;
    case 'ALGO':
      url = ALGO;
      name = 'Algorand';
      break;
    case 'PYTH':
      url = PYTH;
      name = 'Pyth Network';
      break;
    case 'JUP':
      url = JUP;
      name = 'Jupiter';
      break;
    case 'EGLD':
      url = EGLD;
      name = 'MultiversX';
      break;
    case 'SEI':
      url = SEI;
      name = 'Sei';
      break;
    case 'QNT':
      url = QNT;
      name = 'Quant';
      break;
    case 'STRK':
      url = STRK;
      name = 'Starknet Token';
      break;
    case 'FLOW':
      url = FLOW;
      name = 'Flow';
      break;
    case 'EOS':
      url = EOS;
      name = 'EOS';
      break;
    case 'AXS':
      url = AXS;
      name = 'Axie Infinity';
      break;
    case 'OM':
      url = OM;
      name = 'MANTRA';
      break;
    case 'BTTC':
      url = BTTC;
      name = 'BitTorrent';
      break;
    case 'XTZ':
      url = XTZ;
      name = 'Tezos';
      break;
    case 'BEAMX':
      url = BEAMX;
      name = 'Beam (Formerly Merit Circle)';
      break;
    case 'GALA':
      url = GALA;
      name = 'Gala';
      break;
    case 'ENA':
      url = ENA;
      name = 'Ethena';
      break;
    case 'NEO':
      url = NEO;
      name = 'NEO';
      break;
    case 'SAND':
      url = SAND;
      name = 'The Sandbox';
      break;
    case 'GNO':
      url = GNO;
      name = 'Gnosis';
      break;
    case 'ORDI':
      url = ORDI;
      name = 'ORDI';
      break;
    case 'RONIN':
      url = RONIN;
      name = 'Ronin';
      break;
    case 'NEXO':
      url = NEXO;
      name = 'Nexo';
      break;
    case 'XEC':
      url = XEC;
      name = 'eCash';
      break;
    case 'CHZ':
      url = CHZ;
      name = 'Chiliz';
      break;
    case 'MANA':
      url = MANA;
      name = 'Decentraland';
      break;
    case 'CFX':
      url = CFX;
      name = 'Conflux Network';
      break;
    case 'ZK':
      url = ZK;
      name = 'zkSync';
      break;
    case 'KLAY':
      url = KLAY;
      name = 'Klaytn';
      break;
    case 'DEXE':
      url = DEXE;
      name = 'DeXe';
      break;
    case 'ROSE':
      url = ROSE;
      name = 'Oasis Network';
      break;
    case 'PENDLE':
      url = PENDLE;
      name = 'Pendle';
      break;
    case 'BOME':
      url = BOME;
      name = 'BOOK OF MEME';
      break;
    case 'MINA':
      url = MINA;
      name = 'Mina';
      break;
    case 'SNX':
      url = SNX;
      name = 'Synthetix Network Token';
      break;
    case 'W':
      url = W;
      name = 'Wormhole';
      break;
    case 'IOTA':
      url = MIOTA;
      name = 'MIOTA';
      break;
    case 'WLD':
      url = WLD;
      name = 'Worldcoin';
      break;
    case '1INCH':
      url = ONEINCH;
      name = '1inch';
      break;
    case 'TUSD':
      url = TUSD;
      name = 'TrueUSD';
      break;
    case 'CAKE':
      url = CAKE;
      name = 'PancakeSwap';
      break;
    case 'AXL':
      url = AXL;
      name = 'Axelar';
      break;
    case 'RAY':
      url = RAY;
      name = 'Raydium';
      break;
    case 'BNX':
      url = BNX;
      name = 'BinaryX';
      break;
    case 'LPT':
      url = LPT;
      name = 'Livepeer';
      break;
    case 'CKB':
      url = CKB;
      name = 'CKB';
      break;
    case 'APE':
      url = APE;
      name = 'ApeCoin';
      break;
    case 'PAXG':
      url = PAXG;
      name = 'PAX Gold';
      break;
    case 'TFUEL':
      url = TFUEL;
      name = 'Theta Fuel';
      break;
    case 'ZRO':
      url = ZRO;
      name = 'LayerZero';
      break;
    case 'TWT':
      url = TWT;
      name = 'Trust Wallet Token';
      break;
    case 'KAVA':
      url = KAVA;
      name = 'Kava';
      break;
    case 'COMP':
      url = COMP;
      name = 'Compound';
      break;
    case 'ASTR':
      url = ASTR;
      name = 'Astar';
      break;
    case 'FTT':
      url = FTT;
      name = 'FTX Token';
      break;
    case 'LUNC':
      url = LUNC;
      name = 'Terra Classic';
      break;
    case 'SFP':
      url = SFP;
      name = 'SafePal';
      break;
    case 'AEVO':
      url = AEVO;
      name = 'AEVO';
      break;
    case 'ZEC':
      url = ZEC;
      name = 'Zcash';
      break;
    case 'WOO':
      url = WOO;
      name = 'WOO Network';
      break;
    case 'IOTX':
      url = IOTX;
      name = 'IoTeX';
      break;
    case 'CRV':
      url = CRV;
      name = 'Curve';
      break;
    case '1000SATS':
      url = THOUSANDSATS;
      name = '1000*SATS (Ordinals)';
      break;
    case 'OSMO':
      url = OSMO;
      name = 'Osmosis';
      break;
    case 'SSV':
      url = SSV;
      name = 'SSV Token';
      break;
    case 'KSM':
      url = KSM;
      name = 'Kusama';
      break;
    case 'GLM':
      url = GLM;
      name = 'Golem';
      break;
    case 'MEME':
      url = MEME;
      name = 'Memecoin';
      break;
    case 'DYDX':
      url = DYDX;
      name = 'dYdX';
      break;
    case 'GMT':
      url = GMT;
      name = 'Green Metaverse Token';
      break;
    case 'LUNA':
      url = LUNA;
      name = 'Terra';
      break;
    case 'RPL':
      url = RPL;
      name = 'Rocket Pool';
      break;
    case 'ELF':
      url = ELF;
      name = 'aelf';
      break;
    case 'BLUR':
      url = BLUR;
      name = 'Blur';
      break;
    case 'PEOPLE':
      url = PEOPLE;
      name = 'ConstitutionDAO';
      break;
    case 'ARKM':
      url = ARKM;
      name = 'Arkham';
      break;
    case 'MANTA':
      url = MANTA;
      name = 'Manta';
      break;
    case 'ZIL':
      url = ZIL;
      name = 'Zilliqa';
      break;
    case 'JST':
      url = JST;
      name = 'JUST';
      break;
    case 'DASH':
      url = DASH;
      name = 'Dash';
      break;
    case 'HOT':
      url = HOT;
      name = 'Holo';
      break;
    case 'CELO':
      url = CELO;
      name = 'Celo';
      break;
    case 'ENJ':
      url = ENJ;
      name = 'Enjin Coin';
      break;
    case 'GAL':
      url = GAL;
      name = 'Galxe';
      break;
    case 'ANKR':
      url = ANKR;
      name = 'Ankr';
      break;
    case 'SUPER':
      url = SUPER;
      name = 'SuperVerse';
      break;
    case 'ILV':
      url = ILV;
      name = 'Illuvium';
      break;
    case 'ZRX':
      url = ZRX;
      name = '0x';
      break;
    case 'BAT':
      url = BAT;
      name = 'Basic Attention Token';
      break;
    case 'ETHFI':
      url = ETHFI;
      name = 'ether.fi';
      break;
    case 'JTO':
      url = JTO;
      name = 'JITO';
      break;
    case 'ID':
      url = ID;
      name = 'Space ID';
      break;
    case 'GMX':
      url = GMX;
      name = 'GMX';
      break;
    case 'RVN':
      url = RVN;
      name = 'Ravencoin';
      break;
    case 'DYM':
      url = DYM;
      name = 'Dymension';
      break;
    case 'BICO':
      url = BICO;
      name = 'Biconomy';
      break;
    case 'SC':
      url = SC;
      name = 'Siacoin';
      break;
    case 'QTUM':
      url = QTUM;
      name = 'Qtum';
      break;
    case 'SKL':
      url = SKL;
      name = 'SKALE Network';
      break;
    case 'METIS':
      url = METIS;
      name = 'MetisDAO';
      break;
    case 'RSR':
      url = RSR;
      name = 'Reserve Rights';
      break;
    case 'CHR':
      url = CHR;
      name = 'Chromia';
      break;
    case 'IO':
      url = IO;
      name = 'IO.NET';
      break;
    case 'EDU':
      url = EDU;
      name = 'Open Campus';
      break;
    case 'CVX':
      url = CVX;
      name = 'Convex Finance';
      break;
    case 'DCR':
      url = DCR;
      name = 'Decred';
      break;
    case 'GAS':
      url = GAS;
      name = 'NeoGas';
      break;
    case 'MASK':
      url = MASK;
      name = 'Mask Network';
      break;
    case 'YFI':
      url = YFI;
      name = 'yearn.finance';
      break;
    case 'POLYX':
      url = POLYX;
      name = 'Polymesh';
      break;
    case 'FXS':
      url = FXS;
      name = 'Frax Share';
      break;
    case 'LRC':
      url = LRC;
      name = 'Loopring';
      break;
    case 'T':
      url = T;
      name = 'Threshold';
      break;
    case 'TRB':
      url = TRB;
      name = 'Tellor Tributes';
      break;
    case 'FLUX':
      url = FLUX;
      name = 'Flux';
      break;
    case 'ONE':
      url = ONE;
      name = 'Harmony';
      break;
    case 'AMP':
      url = AMP;
      name = 'AMP';
      break;
    case 'VTHO':
      url = VTHO;
      name = 'VeThor Token';
      break;
    case 'SUSHI':
      url = SUSHI;
      name = 'Sushi';
      break;
    case 'ACH':
      url = ACH;
      name = 'Alchemy Pay';
      break;
    case 'GLMR':
      url = GLMR;
      name = 'Glimmer';
      break;
    case 'BB':
      url = BB;
      name = 'BounceBit';
      break;
    case 'UMA':
      url = UMA;
      name = 'UMA';
      break;
    case 'YGG':
      url = YGG;
      name = 'Yield Guild Games';
      break;
    case 'ONT':
      url = ONT;
      name = 'Ontology';
      break;
    case 'AUDIO':
      url = AUDIO;
      name = 'Audius';
      break;
    case 'VANRY':
      url = VANRY;
      name = 'Vanar';
      break;
    case 'ZEN':
      url = ZEN;
      name = 'Horizen';
      break;
    case 'API3':
      url = API3;
      name = 'API3';
      break;
    case 'BAND':
      url = BAND;
      name = 'BAND';
      break;
    case 'PIXEL':
      url = PIXEL;
      name = 'Pixels';
      break;
    case 'BAL':
      url = BAL;
      name = 'Balancer';
      break;
    case 'ALT':
      url = ALT;
      name = 'AltLayer';
      break;
    case 'KDA':
      url = KDA;
      name = 'Kadena';
      break;
    case 'ICX':
      url = ICX;
      name = 'ICON';
      break;
    case 'SXP':
      url = SXP;
      name = 'Solar';
      break;
    case 'POND':
      url = POND;
      name = 'Marlin';
      break;
    case 'STORJ':
      url = STORJ;
      name = 'Storj';
      break;
    case 'NTRN':
      url = NTRN;
      name = 'Neutron';
      break;
    case 'COTI':
      url = COTI;
      name = 'COTI';
      break;
    case 'LSK':
      url = LSK;
      name = 'Lisk';
      break;
    case 'RLC':
      url = RLC;
      name = 'iExecRLC';
      break;
    case 'C98':
      url = C98;
      name = 'Coin98';
      break;
    case 'MAGIC':
      url = MAGIC;
      name = 'Magic';
      break;
    case 'DGB':
      url = DGB;
      name = 'DigiByte';
      break;
    case 'SAGA':
      url = SAGA;
      name = 'Saga';
      break;
    case 'IOST':
      url = IOST;
      name = 'IOST';
      break;
    case 'USDP':
      url = USDP;
      name = 'Pax Dollar';
      break;
    case 'ONG':
      url = ONG;
      name = 'Ontology Gas';
      break;
    case 'WAXP':
      url = WAXP;
      name = 'WAX';
      break;
    case 'LISTA':
      url = LISTA;
      name = 'Lista';
      break;
    case 'TRU':
      url = TRU;
      name = 'TrueFi';
      break;
    case 'CTSI':
      url = CTSI;
      name = 'Cartesi';
      break;
    case 'XNO':
      url = XNO;
      name = 'Nano';
      break;
    case 'DUSK':
      url = DUSK;
      name = 'Dusk';
      break;
    case 'PROM':
      url = PROM;
      name = 'Prometeus';
      break;
    case 'JOE':
      url = JOE;
      name = 'JOE';
      break;
    case 'SUN':
      url = SUN;
      name = 'SUN';
      break;
    case 'SLP':
      url = SLP;
      name = 'Smooth Love Potion';
      break;
    case 'AI':
      url = AI;
      name = 'Sleepless AI';
      break;
    case 'CELR':
      url = CELER;
      name = 'Celer Network';
      break;
    case 'REQ':
      url = REQ;
      name = 'Request Network';
      break;
    case 'OMNI':
      url = OMNI;
      name = 'Omni Network';
      break;
    case 'HIVE':
      url = HIVE;
      name = 'Hive';
      break;
    case 'XAI':
      url = XAI;
      name = 'Xai';
      break;
    case 'HIGH':
      url = HIGH;
      name = 'Highstreet';
      break;
    case 'CYBER':
      url = CYBER;
      name = 'Cyber';
      break;
    case 'NMR':
      url = NMR;
      name = 'Numeraire';
      break;
    case 'IQ':
      url = IQ;
      name = 'IQ';
      break;
    case 'CVC':
      url = CVC;
      name = 'Civic';
      break;
    case 'POWR':
      url = POWR;
      name = 'Powerledger';
      break;
    case 'ACE':
      url = ACE;
      name = 'Fusionist';
      break;
    case 'PUNDIX':
      url = PUNDIX;
      name = 'Pundi X';
      break;
    case 'KNC':
      url = KNC;
      name = 'KyberNetwork';
      break;
    case 'XVS':
      url = XVS;
      name = 'Venus';
      break;
    case 'GNS':
      url = GNS;
      name = 'Gains Network';
      break;
    case 'SPELL':
      url = SPELL;
      name = 'Spell Token';
      break;
    case 'AUCTION':
      url = AUCTION;
      name = 'Auction';
      break;
    case 'PYR':
      url = PYR;
      name = 'Vulcan Forged PYR';
      break;
    case 'PHA':
      url = PHA;
      name = 'Phala.Network';
      break;
    case 'USTC':
      url = USTC;
      name = 'TerraClassicUSD';
      break;
    case 'CTK':
      url = CTK;
      name = 'Shentu';
      break;
    case 'WIN':
      url = WIN;
      name = 'WINkLink';
      break;
    case 'PORTAL':
      url = PORTAL;
      name = 'Portal';
      break;
    case 'SNT':
      url = SNT;
      name = 'Status';
      break;
    case 'DENT':
      url = DENT;
      name = 'DENT';
      break;
    case 'MOVR':
      url = MOVR;
      name = 'Moonriver';
      break;
    case 'STEEM':
      url = STEEM;
      name = 'Steem';
      break;
    case 'STPT':
      url = STPT;
      name = 'Standard Tokenization Protocol';
      break;
    case 'SYN':
      url = SYN;
      name = 'Synapse';
      break;
    case 'SYS':
      url = SYS;
      name = 'Syscoin';
      break;
    case 'CREAM':
      url = CREAM;
      name = 'Cream Finance';
      break;
    case 'PHB':
      url = PHB;
      name = 'Phoenix';
      break;
    case 'LEVER':
      url = LEVER;
      name = 'LeverFi';
      break;
    case 'HOOK':
      url = HOOK;
      name = 'Hooked Protocol';
      break;
    case 'REZ':
      url = REZ;
      name = 'Renzo';
      break;
    case 'HFT':
      url = HFT;
      name = 'Hashflow';
      break;
    case 'RIF':
      url = RIF;
      name = 'Rootstock Infrastructure Framework';
      break;
    case 'BAKE':
      url = BAKE;
      name = 'BakeryToken';
      break;
    case 'BLZ':
      url = BLZ;
      name = 'Bluzelle';
      break;
    case 'SCRT':
      url = SCRT;
      name = 'Secret';
      break;
    case 'LQTY':
      url = LQTY;
      name = 'Liquity';
      break;
    case 'FRONT':
      url = FRONT;
      name = 'Frontier';
      break;
    case 'STG':
      url = STG;
      name = 'Stargate Finance';
      break;
    case 'BNT':
      url = BNT;
      name = 'Bancor';
      break;
    case 'DODO':
      url = DODO;
      name = 'DODO';
      break;
    case 'DAR':
      url = DAR;
      name = 'Mines of Dalarnia';
      break;
    case 'MTL':
      url = MTL;
      name = 'Metal DAO';
      break;
    case 'OXT':
      url = OXT;
      name = 'Orchid';
      break;
    case 'AGLD':
      url = AGLD;
      name = 'Adventure Gold';
      break;
    case 'MBOX':
      url = MBOX;
      name = 'MOBOX';
      break;
    case 'QI':
      url = QI;
      name = 'BENQI';
      break;
    case 'ALICE':
      url = ALICE;
      name = 'My Neighbor Alice';
      break;
    case 'LOOM':
      url = LOOM;
      name = 'Loom Network';
      break;
    case 'NFP':
      url = NFP;
      name = 'NFPrompt Token';
      break;
    case 'XVG':
      url = XVG;
      name = 'Verge';
      break;
    case 'RAD':
      url = RAD;
      name = 'Radworks';
      break;
    case 'AEUR':
      url = AEUR;
      name = 'Anchored EUR';
      break;
    case 'ARDR':
      url = ARDR;
      name = 'Ardor';
      break;
    case 'RARE':
      url = RARE;
      name = 'SuperRare';
      break;
    case 'ACA':
      url = ACA;
      name = 'Acala Token';
      break;
    case 'ALPHA':
      url = ALPHA;
      name = 'Stella';
      break;
    case 'ARK':
      url = ARK;
      name = 'Ark';
      break;
    case 'GHST':
      url = GHST;
      name = 'Aavegotchi';
      break;
    case 'ARPA':
      url = ARPA;
      name = 'ARPA Network';
      break;
    case 'RDNT':
      url = RDNT;
      name = 'Radiant Capital';
      break;
    case 'BETA':
      url = BETA;
      name = 'Beta Finance';
      break;
    case 'NKN':
      url = NKN;
      name = 'NKN';
      break;
    case 'BADGER':
      url = BADGER;
      name = 'Badger DAO';
      break;
    case 'TNSR':
      url = TNSR;
      name = 'Tensor';
      break;
    case 'WRX':
      url = WRX;
      name = 'WazirX';
      break;
    case 'GTC':
      url = GTC;
      name = 'Gitcoin';
      break;
    case 'MLN':
      url = MLN;
      name = 'Enzyme';
      break;
    case 'STMX':
      url = STMX;
      name = 'StormX';
      break;
    case 'HIFI':
      url = HIFI;
      name = 'Hifi Finance';
      break;
    case 'OGN':
      url = OGN;
      name = 'OriginToken';
      break;
    case 'TKO':
      url = TKO;
      name = 'Tokocrypto';
      break;
    case 'MAV':
      url = MAV;
      name = 'Maverick Protocol';
      break;
    case 'QKC':
      url = QKC;
      name = 'QuarkChain';
      break;
    case 'LTO':
      url = LTO;
      name = 'LTO Network';
      break;
    case 'CLV':
      url = CLV;
      name = 'Clover Finance';
      break;
    case 'ORN':
      url = ORN;
      name = 'Orion Protocol';
      break;
    case 'REI':
      url = REI;
      name = 'REI Network';
      break;
    case 'TLM':
      url = TLM;
      name = 'Alien Worlds';
      break;
    case 'ATA':
      url = ATA;
      name = 'Automata';
      break;
    case 'FORTH':
      url = FORTH;
      name = 'Ampleforth Governance Token';
      break;
    case 'ERN':
      url = ERN;
      name = 'Ethernity Chain';
      break;
    case 'REN':
      url = REN;
      name = 'Ren';
      break;
    case 'DIA':
      url = DIA;
      name = 'DIA';
      break;
    case 'MBL':
      url = MBL;
      name = 'MovieBloc';
      break;
    case 'KMD':
      url = KMD;
      name = 'Komodo';
      break;
    case 'DATA':
      url = DATA;
      name = 'Streamr';
      break;
    case 'COS':
      url = COS;
      name = 'Contentos';
      break;
    case 'PERP':
      url = PERP;
      name = 'Perpetual Protocol';
      break;
    case 'AERGO':
      url = AERGO;
      name = 'Aergo';
      break;
    case 'FIS':
      url = FIS;
      name = 'Stafi';
      break;
    case 'FUN':
      url = FUN;
      name = 'FunToken';
      break;
    case 'LIT':
      url = LIT;
      name = 'Litentry';
      break;
    case 'COMBO':
      url = COMBO;
      name = 'COMBO';
      break;
    case 'POLS':
      url = POLS;
      name = 'Polkastarter';
      break;
    case 'ALCX':
      url = ALCX;
      name = 'Alchemix';
      break;
    case 'PDA':
      url = PDA;
      name = 'PlayDapp';
      break;
    case 'WAN':
      url = WAN;
      name = 'Wanchain';
      break;
    case 'DEGO':
      url = DEGO;
      name = 'Dego Finance';
      break;
    case 'UNFI':
      url = UNFI;
      name = 'Unifi Protocol DAO';
      break;
    case 'PSG':
      url = PSG;
      name = 'Paris Saint-Germain Fan Token';
      break;
    case 'FLM':
      url = FLM;
      name = 'Flamingo';
      break;
    case 'NULS':
      url = NULS;
      name = 'Nuls';
      break;
    case 'AVA':
      url = AVA;
      name = 'AVA';
      break;
    case 'LINA':
      url = LINA;
      name = 'Linear';
      break;
    case 'IDEX':
      url = IDEX;
      name = 'IDEX';
      break;
    case 'BEL':
      url = BEL;
      name = 'Bella Protocol';
      break;
    case 'FIDA':
      url = FIDA;
      name = 'Bonfida';
      break;
    case 'MDT':
      url = MDT;
      name = 'Measurable Data Token';
      break;
    case 'VIC':
      url = VIC;
      name = 'Viction';
      break;
    case 'CTXC':
      url = CTXC;
      name = 'Cortex';
      break;
    case 'BSW':
      url = BSW;
      name = 'Biswap';
      break;
    case 'REEF':
      url = REEF;
      name = 'Reef';
      break;
    case 'LOKA':
      url = LOKA;
      name = 'League of Kingdoms';
      break;
    case 'FARM':
      url = FARM;
      name = 'Harvest Finance';
      break;
    case 'QUICK':
      url = QUICK;
      name = 'QuickSwap';
      break;
    case 'MDX':
      url = MDX;
      name = 'Mdex';
      break;
    case 'KEY':
      url = KEY;
      name = 'Selfkey';
      break;
    case 'UTK':
      url = UTK;
      name = 'xMoney';
      break;
    case 'BIFI':
      url = BIFI;
      name = 'Beefy.Finance';
      break;
    case 'VOXEL':
      url = VOXEL;
      name = 'Voxies';
      break;
    case 'UFT':
      url = UFT;
      name = 'UniLend';
      break;
    case 'KP3R':
      url = KP3R;
      name = 'Keep3rV1';
      break;
    case 'IRIS':
      url = IRIS;
      name = 'IRISnet';
      break;
    case 'ADX':
      url = ADX;
      name = 'AdEx';
      break;
    case 'AKRO':
      url = AKRO;
      name = 'Akropolis';
      break;
    case 'WING':
      url = WING;
      name = 'Wing Token';
      break;
    case 'BURGER':
      url = BURGER;
      name = 'BurgerCities';
      break;
    case 'BAR':
      url = BAR;
      name = 'FC Barcelona Fan Token BAR';
      break;
    case 'CHESS':
      url = CHESS;
      name = 'Tranchess';
      break;
    case 'PIVX':
      url = PIVX;
      name = 'PIVX';
      break;
    case 'GFT':
      url = GFT;
      name = 'Gifto';
      break;
    case 'FIO':
      url = FIO;
      name = 'FIO Protocol';
      break;
    case 'AMB':
      url = AMB;
      name = 'AirDAO';
      break;
    case 'ALPACA':
      url = ALPACA;
      name = 'Alpaca Finance';
      break;
    case 'VIDT':
      url = VIDT;
      name = 'VIDT DAO';
      break;
    case 'CITY':
      url = CITY;
      name = 'Manchester City Fan Token';
      break;
    case 'FIRO':
      url = FIRO;
      name = 'Firo';
      break;
    case 'VITE':
      url = VITE;
      name = 'VITE';
      break;
    case 'AST':
      url = AST;
      name = 'AirSwap';
      break;
    case 'VGX':
      url = VGX;
      name = 'Voyager Token';
      break;
    case 'SANTOS':
      url = SANTOS;
      name = 'Santos FC Fan Token';
      break;
    case 'ALPINE':
      url = ALPINE;
      name = 'Alpine F1 Team Fan Token';
      break;
    case 'TROY':
      url = TROY;
      name = 'Troy';
      break;
    case 'OG':
      url = OG;
      name = 'OG Fan Token';
      break;
    case 'VIB':
      url = VIB;
      name = 'Viberate';
      break;
    case 'ASR':
      url = ASR;
      name = 'AS Roma Fan Token';
      break;
    case 'OOKI':
      url = OOKI;
      name = 'OokiDAO';
      break;
    case 'JUV':
      url = JUV;
      name = 'Juventus Fan Token';
      break;
    case 'DF':
      url = DF;
      name = 'dForce';
      break;
    case 'BOND':
      url = BOND;
      name = 'BarnBridge';
      break;
    case 'IDRT':
      url = IDRT;
      name = 'Rupiah Token';
      break;
    case 'LAZIO':
      url = LAZIO;
      name = 'Lazio Fan Token';
      break;
    case 'PORTO':
      url = PORTO;
      name = 'FC Porto Fan Token';
      break;
    case 'OAX':
      url = OAX;
      name = 'openANX';
      break;
    case 'HARD':
      url = HARD;
      name = 'Kava Lend';
      break;
    case 'PROS':
      url = PROS;
      name = 'Prosper';
      break;
    case 'ATM':
      url = ATM;
      name = 'Atlético de Madrid Fan Token';
      break;
    case 'ACM':
      url = ACM;
      name = 'AC Milan Fan Token';
      break;
    case 'FOR':
      url = FOR;
      name = 'ForTube';
      break;
    case 'EPX':
      url = EPX;
      name = 'Ellipsis';
      break;
    case 'DOCK':
      url = DOCK;
      name = 'DOCK';
      break;
    case 'CVP':
      url = CVP;
      name = 'PowerPool';
      break;
    case 'USDT':
      url = USDT;
      name = 'Tether';
      break;

    default:
      name = code;
      url = GENERIC;
      break;
  }

  return {
    url,
    name,
    code,
  };
};
