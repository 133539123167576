import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.fonts.css";
import BuySellCardMobile from "../../BuySellCardMobile/BuySellCardMobile";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BuySellPopupMobile = ({ isPopupOpen, setIsPopupOpen }) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        className={classes.popupContainer}
      >
        <BuySellCardMobile handleClose={handleClose} />
      </BootstrapDialog>
    </div>
  );
};

export default BuySellPopupMobile;

const useStyles = makeStyles(() => ({
  popupContainer: {
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      width: "100%",
      minWidth: "302px",
      overscrollBehaviorY: "none !important",
    },
  },
}));
