import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MobileGoldHistory from "../../../components/MobileComponents/MobileDashboard/MobileGoldHistory/MobileGoldHistory";
import MobileNavigationBack from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileNavigationBack";
import MobileStakingButtons from "../../../components/MobileComponents/MobileDashboard/MobileStaking/MobileStakingNavigation/MobileStakingButtons/MobileStakingButtons";
import { useAppContext } from "../../../context/DataContext";
import { useEffect } from "react";
import VaultInfo from "../../../components/MobileComponents/MobileVaulting/VaultInfo/VaultInfo";
import MobileVaultingHistoryTable from "../../../components/MobileComponents/MobileVaultingHistoryTable/MobileVaultingHistoryTable";
import TransactionsNews from "../../../components/MobileComponents/MobileDashboard/TransactionsNews/TransactionsNews";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AppLayout from "../../../components/common/Layout/AppLayout";

function MobileVaulting() {
  const classes = useStyles();
  const { setIsInHistory } = useAppContext();
  const navigate = useNavigate();
  const [showComponent, setShowComponent] = useState(false);
  function handleVaultingHistoryNavigation() {
    navigate(routes.vaultingHistory);
  }
  function handleCertificatesHistoryNavigation() {
    navigate(routes.certificatesHistory);
  }
  useEffect(() => {
    setIsInHistory(true);
    setShowComponent(true);
  }, []);
  return (
    <AppLayout>
      {showComponent && (
        <Box className={classes.container}>
          <MobileNavigationBack page={"Vaulting"} route={routes.dashboard} />
          <MobileGoldHistory />
          <VaultInfo />
          <div className={classes.wrapper}>
            <Box className={classes.table}>
              <MobileVaultingHistoryTable />
            </Box>
            <div className={classes.helperContainer}>
              <Box className={classes.actionButtons}>
                <MobileStakingButtons
                  firstButtonName={"VAULT"}
                  category="vault"
                />
              </Box>
              <Box className={classes.news}>
                <TransactionsNews
                  leftName={"VAULTING HISTORY"}
                  rightName={"CERTIFICATES"}
                  handleLeftPanelNavigation={handleVaultingHistoryNavigation}
                  handleRightPanelNavigation={
                    handleCertificatesHistoryNavigation
                  }
                />
              </Box>
            </div>
          </div>
        </Box>
      )}
    </AppLayout>
  );
}

export default MobileVaulting;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  wrapper: {
    height: "calc(100vh - 260px)",
  },
  table: {
    height: "calc(100vh - 410px)",
    minHeight: "200px",
  },
  helperContainer: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    bottom: "60px",
    height: "146px",
    "@media (orientation: landscape) and (max-height: 608px)": {
      position: "relative",
      bottom: "55px",
    },
  },
  actionButtons: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    left: 0,
    width: "100%",
    background: "var(--linear-gradient-white)",
  },
  news: {
    display: "flex",
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
  },
}));
