import { fetchWrapper } from "../../utils/fetchWrapper";

const groupedTransactionsUrl = `/order-transaction`;
const redeemGroupedTransactons = `/redeem-transaction`;

const getAllTransactions = async (page, pageSize = 10) => {
  try {
    if (page == 0) {
      const response = await fetchWrapper.get(`${redeemGroupedTransactons}`);
      return response;
    }
    const response = await fetchWrapper.get(
      `${redeemGroupedTransactons}?page=${page}&pageSize=${pageSize}`
    );
    return response;
  } catch (e) {
    throw new Error();
  }
};

const getAllOrderTransactions = async (page, pageSize = 10, type) => {
  try {
    if (page == 0) {
      const response = await fetchWrapper.get(
        `${groupedTransactionsUrl}/${type}`
      );
      return response;
    }
    const response = await fetchWrapper.get(
      `${groupedTransactionsUrl}/${type}?page=${page}&pageSize=${pageSize}`
    );
    return response;
  } catch (e) {
    throw new Error();
  }
};

const getTransaction = async (transactionId) => {
  try {
    await fetchWrapper.get(`/transactions/${transactionId}`);
  } catch (error) {
    throw new Error();
  }
};

const getGoldTradingPool = async () => {
  try {
    return await fetchWrapper.get(`${groupedTransactionsUrl}/gold-pool`);
  } catch (error) {
    throw new Error("Failed to fetch gold trading pool data.");
  }
};

const createTransaction = async (transaction) => {
  try {
    return await fetchWrapper.post(`/transactions`, transaction);
  } catch (e) {
    throw e;
  }
};

const updateTransaction = async (transaction, id) => {
  try {
    return await fetchWrapper.put(`/transactions/${id}`, transaction);
  } catch (e) {
    throw e;
  }
};

const deleteTransaction = async (id) => {
  try {
    return await fetchWrapper.delete(`/transactions/${id}`);
  } catch (e) {
    throw e;
  }
};

const serviceTransaction = {
  getAllTransactions,
  getAllOrderTransactions,
  getTransaction,
  createTransaction,
  updateTransaction,
  deleteTransaction,
  getGoldTradingPool,
};

export default serviceTransaction;
