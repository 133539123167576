import * as React from "react";
import { makeStyles } from "@mui/styles";
import "../../../../styles/global.styles.css";
import "../../../../styles/global.fonts.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useState, useEffect } from "react";
import AddressFormWeb from "./AddressFormWeb";
import { countryService } from "../../../../service/countries";
import { countries as demoCountries } from "../../../../constants/countries";
import { handlePleaseLogin } from "../../../../helpers/pleaseLoginOrRegisterForDemo";
import PleaseLoginPopUp from "../PleaseLoginPopup/PleaseLoginPopup";

function RadioButtonsGroup({
  selectedAddressId,
  setSelectedAddressId,
  addedAddresses,
  handleChosenAddress,
}) {
  const classes = useStyles();

  useEffect(() => {
    const address = addedAddresses.find(
      (address) => address.ID == selectedAddressId
    );
    handleChosenAddress(address);
  }, [selectedAddressId, addedAddresses, handleChosenAddress]);

  const handleAddressChange = (event) => {
    setSelectedAddressId(event.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={addedAddresses[0].ID}
        name="radio-buttons-group"
        onChange={handleAddressChange}
        sx={{ width: "100%" }}
      >
        {addedAddresses.map((address, idx) => {
          const isChecked = address.ID == selectedAddressId;

          const addressArr = [
            address.addressLine,
            address.city,
            address.shippingState,
            address.countryName,
            address.zipCode,
          ];

          const addressString = addressArr
            .filter((item) => item)
            .join(", ")
            .trim();

          return (
            <div className={classes.addressContainer} key={address.countryCode}>
              <FormControlLabel
                value={String(address.ID)}
                control={
                  <Radio checked={selectedAddressId ? isChecked : idx === 0} />
                }
                label={addressString}
                sx={{
                  paddingLeft: "15px",
                  "& .Mui-checked": {
                    color: "#347362",
                  },
                }}
              />
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

const SecondStepContentWeb = ({
  selectedAddressId,
  setSelectedAddressId,
  isAddNewAddress,
  setIsAddNewAddress,
  handleChosenAddress,
  addresses,
  partNumber,
}) => {
  const classes = useStyles();
  const [addedAddresses, setAddedAddresses] = useState(() => {
    const storedAddresses = sessionStorage.getItem("addedAddresses");
    return storedAddresses ? JSON.parse(storedAddresses) : [...addresses];
  });
  const [countries, setCountries] = useState([]);
  const [isPleaseLoginPopupOpen, setIsPleaseLoginPopupOpen] = useState(false);

  const getAllCountries = async () => {
    const isDemo = sessionStorage.getItem("isDemo") === "true";
    if (isDemo) {
      setCountries(demoCountries);
      return;
    }
    try {
      const countries = await countryService.getAllCountries();
      setCountries(countries);
    } catch (error) {
      console.error("An error occurred while fetching countries:", error);
    }
  };

  const handleAddNewAddress = () => {
    const isDemo = handlePleaseLogin(setIsPleaseLoginPopupOpen);
    if (isDemo) {
      return;
    }
    getAllCountries();
    setIsAddNewAddress(true);
  };

  const handleCancel = () => {
    setIsAddNewAddress(false);
  };

  const handleFormSubmit = (formData) => {
    const updatedAddresses = [
      ...addedAddresses,
      { ...formData, ID: String(Math.ceil(Math.random() * 1000000)) },
    ];
    setAddedAddresses(updatedAddresses);
    setIsAddNewAddress(false);
    sessionStorage.setItem(
      "addedAddresses",
      JSON.stringify([...updatedAddresses])
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.title}>Deliver to this address?</div>
        <RadioButtonsGroup
          selectedAddressId={selectedAddressId}
          setSelectedAddressId={setSelectedAddressId}
          addedAddresses={addedAddresses}
          handleChosenAddress={handleChosenAddress}
        />
      </div>
      {!isAddNewAddress && (
        <div className={classes.addButton}>
          <IconButton onClick={handleAddNewAddress} aria-label="add">
            <AddCircleOutlineRoundedIcon className={classes.addIcon} />
            <div className={classes.addAddressText}>Add New Address</div>
          </IconButton>
        </div>
      )}
      {isAddNewAddress && (
        <AddressFormWeb
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleCancel}
          countries={countries}
          partNumber={partNumber}
        />
      )}
      <PleaseLoginPopUp
        open={isPleaseLoginPopupOpen}
        setOpen={setIsPleaseLoginPopupOpen}
      />
    </div>
  );
};

export default SecondStepContentWeb;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "3%",
  },
  content: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "clamp(14px, 2vw, 16px)",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "400",
    color: "#303030",
    marginBottom: "2%",
  },
  addressContainer: {
    border: "0.5px solid #D2D5D8",
    width: "100%",
    height: "clamp(45px, 6vw, 50px)",
    display: "flex",
    backgroundColor: "#F7F7F7",
    marginBottom: "1%",
    "& .MuiTypography-root": {
      fontSize: "clamp(14px, 2vw, 16px)",
      fontFamily: theme.typography.fontFamily,
      fontWeight: "400",
    },
  },
  addButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: "3%",
  },
  addIcon: {
    fontSize: "clamp(16px, 3vw, 24px)",
    color: "#767676",
    marginRight: "4px",
  },
  addAddressText: {
    fontSize: "clamp(10px, 2vw, 14px)",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "600",
    color: "#767676",
  },
}));
