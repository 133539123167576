function getUserNameId(token) {
  let nameId;
  if (token) {
    const split = token.split(".");
    const decoded = JSON.parse(atob(split[1]));
    nameId = Number(decoded.nameid);
  }
  return nameId;
}

export function getToken() {
  const hash = window.location.hash;
  const appView = sessionStorage.getItem("isNative");
  const tokenPrefix = "#";
  const isDemo = sessionStorage.getItem("isDemo") === "true";
  if (hash.startsWith(tokenPrefix)) {
    try {
      const currentAccessToken = localStorage.getItem("accessToken");
      const nameId = getUserNameId(currentAccessToken);

      const token = hash.substr(tokenPrefix.length);
      const paramSplit = token.split("?");
      const accessToken = paramSplit[0];
      const split = accessToken.split(".");
      const decoded = JSON.parse(atob(split[1]));
      const decodedExp = new Date(decoded.exp * 1000).toISOString();
      const userId = Number(decoded.nameid);

      if (currentAccessToken && nameId !== userId) {
        localStorage.removeItem("taid");
      }

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("accessTokenExp", decodedExp);
      window.location.hash = "";
    } catch (error) {
      if (!isDemo) {
        if (!appView) {
          window.location.href = "/login";
        } else {
          window.webkit?.messageHandlers.notifyNative.postMessage(
            '{"Event": "RefreshToken", "AccessToken": "", "AccessTokenExp": ""}'
          );
        }
      }
    }
  }
}
