import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTooltip from "../Tooltip/CustomTooltip/CustomTooltip";
import Fade from "@mui/material/Fade";
import { useAppContext } from "../../../context/DataContext";

const CustomButton = ({
  type,
  onClick,
  buttonText,
  width,
  height,
  fontWeight,
  disabled,
  tooltipTitle,
  actionType,
  id = "",
  isTutorialTooltip = true,
  inHeader,
}) => {
  const [open, setOpen] = useState(false);
  const { isMobile, isAppView } = useAppContext();
  const classes = useStyles({ isMobile });

  let buttonClass = "greenButton";
  let icon = null;

  switch (type) {
    case "green":
      buttonClass = classes.greenButton;
      break;
    case "lightGreen":
      buttonClass = classes.lightGreenButton;
      break;
    case "black":
      buttonClass = classes.blackButton;
      break;
    case "delete":
      buttonClass = classes.deleteButton;
      break;
    case "moreinfo":
      buttonClass = classes.moreInfo;
      break;
    case "grey":
      buttonClass = classes.grey;
      break;
    case "gramBtnGrey":
      buttonClass = classes.gramBtnGrey;
      break;
    case "gramBtnDarkGrey":
      buttonClass = classes.gramBtnDarkGrey;
      break;
    case "monthBtnDarkGreen":
      buttonClass = classes.monthBtnDarkGreen;
      break;
    case "monthBtnGreener":
      buttonClass = classes.monthBtnGreener;
      break;
    case "monthBtnWhite":
      buttonClass = classes.monthBtnWhite;
      break;
    case "monthBtnSteelBlue":
      buttonClass = classes.monthBtnSteelBlue;
      break;
    case "monthBtnWhiteAutoInvest":
      buttonClass = classes.monthBtnWhiteAutoInvest;
      break;
    case "secondaryBlack":
      buttonClass = classes.blackButtonSecondary;
      break;
    case "monthBtnBlue":
      buttonClass = classes.monthBtnBlue;
      break;
    case "monthBtnDarkBlue":
      buttonClass = classes.monthBtnDarkBlue;
      break;
    case "emptyOutlined":
      buttonClass = classes.emptyOutlined;
      break;
    case "volumeBtnGrey":
      buttonClass = classes.volumeBtnGrey;
      break;
    case "volumeBtnDarkGrey":
      buttonClass = classes.volumeBtnDarkGrey;
      break;
    case "vaultQuantityBtnDark":
      buttonClass = classes.vaultQuantityBtnDark;
      break;
    case "vaultQuantityBtnLight":
      buttonClass = classes.vaultQuantityBtnLight;
      break;
    default:
      buttonClass = classes.greenButton;
  }

  const buttonStyle = {
    width:
      width && typeof width === "string" && width.endsWith("%")
        ? width
        : `${width}px`,
    height:
      height && typeof height === "string" && height.endsWith("%")
        ? height
        : `${height}px`,
    fontWeight:
      !isNaN(fontWeight) && typeof fontWeight === "number" ? fontWeight : 400,
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    isTutorialTooltip && (
      <Button
        id={id}
        className={buttonClass}
        onClick={onClick}
        startIcon={icon}
        style={buttonStyle}
        disabled={disabled}
        type={actionType}
      >
        {isMobile ? (
          <CustomTooltip
            open={open}
            onClick={handleTooltipOpen}
            onClose={handleTooltipClose}
            title={tooltipTitle}
            placement={inHeader ? "bottom" : "top"}
            arrow
            TransitionComponent={Fade}
          >
            <Typography variant="button">{buttonText}</Typography>
          </CustomTooltip>
        ) : (
          <CustomTooltip
            title={tooltipTitle}
            placement={inHeader ? "bottom" : "top"}
            arrow
            TransitionComponent={Fade}
          >
            <Typography variant="button">{buttonText}</Typography>
          </CustomTooltip>
        )}
      </Button>
    )
  );
};

export default CustomButton;

const useStyles = makeStyles((theme) =>
  createStyles({
    greenButton: {
      backgroundColor: "var(--green-light)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      border: 0,
      textAlign: "left",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      "&:hover": {
        backgroundColor: "var(--green-light)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      // [theme.breakpoints.down("1400")]: {
      //   "& .MuiTypography-button": {
      //     fontSize: (props) =>
      //       props.isMobile ? "12px !important" : "10px !important",
      //   },
      // },
      // [theme.breakpoints.down("1300")]: {
      //   "& .MuiTypography-button": {
      //     fontSize: (props) =>
      //       props.isMobile ? "12px !important" : "9px !important",
      //   },
      // },
      // [theme.breakpoints.down("1150")]: {
      //   "& .MuiTypography-button": {
      //     fontSize: (props) =>
      //       props.isMobile ? "12px !important" : "8px !important",
      //   },
      // },
      // [theme.breakpoints.down("900")]: {
      //   "& .MuiTypography-button": {
      //     fontSize: (props) =>
      //       props.isMobile ? "12px !important" : "7px !important",
      //   },
      // },
      "&.Mui-disabled": {
        background: "#48d0a473",
        boxShadow: "0px 3px 0px 0px #35675A66",
        color: "white",
      },
      opacity: 1,
    },
    lightGreenButton: {
      backgroundColor: "var(--green-light)",
      marginTop: 20,
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      border: 0,
      textAlign: "left",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      "&:hover": {
        backgroundColor: "var(--green-light)",
      },
      cursor: "pointer",
      [theme.breakpoints.down("1400")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "10px !important",
        },
      },
      [theme.breakpoints.down("1300")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "9px !important",
        },
      },
      [theme.breakpoints.down("1150")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "8px !important",
        },
      },
      [theme.breakpoints.down("900")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "7px !important",
        },
      },
      "&.Mui-disabled": {
        background: "#48d0a473",
        boxShadow: "0px 3px 0px 0px #35675A66",
        color: "white",
      },
      opacity: 1,
    },
    blackButton: {
      backgroundColor: "var(--black)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      border: 0,
      cursor: "pointer",
      textAlign: "left",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      "&:hover": {
        backgroundColor: "var(--black)",
      },
      [theme.breakpoints.down("1280")]: {
        "& .MuiTypography-button": {
          fontSize: "9px !important",
        },
      },
      [theme.breakpoints.down("1150")]: {
        "& .MuiTypography-button": {
          fontSize: "8px !important",
        },
      },
      [theme.breakpoints.down("900")]: {
        "& .MuiTypography-button": {
          fontSize: "7px !important",
        },
      },
      [theme.breakpoints.down("768")]: {
        "& .MuiTypography-button": {
          fontSize: "12px !important",
          fontWeight: "600",
        },
      },
      "&.Mui-disabled": {
        background: "#eaeaea",
        color: "#c0c0c0",
      },
      opacity: 1,
    },
    blackButtonSecondary: {
      backgroundColor: "var(--black)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      border: 0,
      cursor: "pointer",
      textAlign: "left",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      "&:hover": {
        backgroundColor: "var(--black)",
      },
      [theme.breakpoints.down("1550")]: {
        minWidth: "50px",
      },
      [theme.breakpoints.down("1400")]: {
        minWidth: "38px",
      },
      [theme.breakpoints.down("1320")]: {
        minWidth: "16px",
      },
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      [theme.breakpoints.down("1500")]: {
        "& .MuiTypography-button": {
          fontSize: "11px !important",
        },
      },
      [theme.breakpoints.down("1400")]: {
        "& .MuiTypography-button": {
          fontSize: "10px !important",
        },
      },
      [theme.breakpoints.down("1300")]: {
        "& .MuiTypography-button": {
          fontSize: "9px !important",
        },
      },
      [theme.breakpoints.down("1150")]: {
        "& .MuiTypography-button": {
          fontSize: "7px !important",
        },
      },
      "&.Mui-disabled": {
        background: "#eaeaea",
        color: "#c0c0c0",
      },
      [theme.breakpoints.down("768")]: {
        "& .MuiTypography-button": {
          fontSize: "10px !important",
          fontWeight: "600",
        },
      },
      opacity: 1,
    },
    deleteButton: {
      backgroundColor: "var(--red)",
      fontSize: "12px",
      color: "var(--white)",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "var(--red)",
      },
      borderRadius: "40px",
      opacity: 1,
      cursor: "pointer",
      textAlign: "left",
      [theme.breakpoints.down("1280")]: {
        "& .MuiTypography-button": {
          fontSize: "9px !important",
        },
      },
      [theme.breakpoints.down("1150")]: {
        "& .MuiTypography-button": {
          fontSize: "8px !important",
        },
      },
      [theme.breakpoints.down("900")]: {
        "& .MuiTypography-button": {
          fontSize: "7px !important",
        },
      },
    },
    moreInfo: {
      width: "17px",
      height: "17px",
      borderRadius: "50%",
      backgroundColor: "rgba(255, 255, 255, 0)",
      color: "var(--more-info-grey)",
      textAlign: "center",
      fontWeight: 700,
      cursor: "pointer",
      minWidth: "auto",
      border: "1px solid var(--more-info-grey)",
      position: "absolute",
      right: 0,
      top: 0,
      margin: "10px",
      zIndex: 1,
    },
    grey: {
      backgroundColor: "var(--grey)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      border: 0,
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--grey)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    gramBtnGrey: {
      backgroundColor: "var(--gr-button-grey)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      [theme.breakpoints.down("1550")]: {
        minWidth: "50px",
      },
      [theme.breakpoints.down("1400")]: {
        minWidth: "38px",
      },
      [theme.breakpoints.down("1320")]: {
        minWidth: "16px",
      },
      border: 0,
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--gr-button-grey)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      [theme.breakpoints.down("1500")]: {
        "& .MuiTypography-button": {
          fontSize: "11px !important",
        },
      },
      [theme.breakpoints.down("1400")]: {
        "& .MuiTypography-button": {
          fontSize: "10px !important",
        },
      },
      [theme.breakpoints.down("1300")]: {
        "& .MuiTypography-button": {
          fontSize: "9px !important",
        },
      },
      [theme.breakpoints.down("1150")]: {
        "& .MuiTypography-button": {
          fontSize: "7px !important",
        },
      },
      [theme.breakpoints.down("768")]: {
        "& .MuiTypography-button": {
          fontSize: "10px !important",
          fontWeight: "600",
        },
      },
      opacity: 1,
    },
    gramBtnDarkGrey: {
      backgroundColor: "var(--gr-button-dark-grey)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      border: 0,
      textAlign: "left",
      [theme.breakpoints.down("1550")]: {
        minWidth: "50px",
      },
      [theme.breakpoints.down("1400")]: {
        minWidth: "38px",
      },
      [theme.breakpoints.down("1320")]: {
        minWidth: "16px",
      },
      "&:hover": {
        backgroundColor: "var(--gr-button-dark-grey)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      [theme.breakpoints.down("1500")]: {
        "& .MuiTypography-button": {
          fontSize: "11px !important",
        },
      },
      [theme.breakpoints.down("1400")]: {
        "& .MuiTypography-button": {
          fontSize: "10px !important",
        },
      },
      [theme.breakpoints.down("1300")]: {
        "& .MuiTypography-button": {
          fontSize: "9px !important",
        },
      },
      [theme.breakpoints.down("1150")]: {
        "& .MuiTypography-button": {
          fontSize: "7px !important",
        },
      },
      [theme.breakpoints.down("768")]: {
        "& .MuiTypography-button": {
          fontSize: "10px !important",
          fontWeight: "600",
        },
      },
      opacity: 1,
    },
    monthBtnDarkGreen: {
      backgroundColor: "var(--month-button-dark-green)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      border: 0,
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--green-light)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    monthBtnGreener: {
      backgroundColor: "var(--green-dark)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      border: "3px solid #64ad94",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--green-dark)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    volumeBtnGrey: {
      backgroundColor: "#A0A0A0",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "#e0e0e099",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    volumeBtnDarkGrey: {
      backgroundColor: "#666666",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      textAlign: "left",
      border: "2.83px solid #A0A0A0",
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    monthBtnBlue: {
      backgroundColor: "var(--btn-blue)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      border: 0,
      textAlign: "left",
      "&:hover": {
        backgroundColor: "#517fa4",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    monthBtnDarkBlue: {
      backgroundColor: "var(--blue)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      border: "3px solid var(--btn-blue)",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--blue)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    monthBtnWhite: {
      backgroundColor: "var(--white)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "black",
      border: "3px solid #64ad94",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--white)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    monthBtnWhiteAutoInvest: {
      backgroundColor: "var(--white)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "black",
      border: "3px solid #4C7290",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--white)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    monthBtnSteelBlue: {
      backgroundColor: "#4C7290",
      borderRadius: "40px",
      fontSize: "12px",
      color: "var(--white)",
      boxShadow: "0px 4.9726881980896px 0px 0px #76767633",
      border: 0,
      textAlign: "left",
      "&:hover": {
        backgroundColor: "#517fa4",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    emptyOutlined: {
      backgroundColor: "white",
      borderRadius: "40px",
      fontSize: "12px",
      color: "black",
      border: "1px solid #C79B6C",
      textAlign: "left",
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      [theme.breakpoints.down("1400")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "10px !important",
        },
      },
      [theme.breakpoints.down("1300")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "9px !important",
        },
      },
      [theme.breakpoints.down("1150")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "8px !important",
        },
      },
      [theme.breakpoints.down("900")]: {
        "& .MuiTypography-button": {
          fontSize: (props) =>
            props.isMobile ? "12px !important" : "7px !important",
        },
      },
      "&.Mui-disabled": {
        background: "#48d0a473",
        color: "white",
      },
      opacity: 1,
    },
    vaultQuantityBtnDark: {
      backgroundColor: "#818181",
      borderRadius: "40px",
      fontSize: "12px",
      color: "white",
      border: "3px solid #818181",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "#9d9d9d",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
    vaultQuantityBtnLight: {
      backgroundColor: "var(--white)",
      borderRadius: "40px",
      fontSize: "12px",
      color: "black",
      border: "3px solid #818181",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "var(--white)",
      },
      cursor: "pointer",
      "@media (max-width:380px)": {
        marginTop: 20,
      },
      opacity: 1,
    },
  })
);
